<template>
  <svg class="icon-section-featured" xmlns="http://www.w3.org/2000/svg" width="19.964" height="19.201" viewBox="0 0 19.964 19.201">
    <path d="M12.652,7.721,8.881,11.49,7.312,9.92a.75.75,0,1,0-1.06,1.062h0l2.1,2.1a.75.75,0,0,0,1.061,0l4.3-4.3a.75.75,0,1,0-1.061-1.061" transform="translate(0 0)" fill="#939393"/><path d="M19.364,9.376h0Z" transform="translate(0 0)" fill="#939393"/><path d="M11.114.311h0Z" transform="translate(0 0)" fill="#939393"/><path d="M18.249,5.7,14.33,4.772a.724.724,0,0,1-.454-.335L11.892,1.089a2.22,2.22,0,0,0-3.821,0L6.088,4.434a.724.724,0,0,1-.455.336L1.713,5.7A2.234,2.234,0,0,0,.7,6.234,2.221,2.221,0,0,0,.6,9.374l2.6,2.792a.724.724,0,0,1,.2.493l0,.074-.417,4.018h0a1.928,1.928,0,0,0-.013.229,2.221,2.221,0,0,0,3.17,2.008L9.67,17.312a.737.737,0,0,1,.308-.069.745.745,0,0,1,.309.069l3.537,1.676a2.243,2.243,0,0,0,.951.213l.232-.011A2.224,2.224,0,0,0,17,16.982l-.011-.231-.417-4.019v-.008l0-.064a.718.718,0,0,1,.195-.492l2.605-2.791a2.218,2.218,0,0,0,.543-1.012V8.358A2.219,2.219,0,0,0,18.249,5.7m.2,2.326a.723.723,0,0,1-.175.327l0,0-2.6,2.79a2.219,2.219,0,0,0-.6,1.518l.012.227V12.9l.416,4.011,0,.076a.723.723,0,0,1-.646.716l-.074,0a.72.72,0,0,1-.31-.071l-3.536-1.675a2.237,2.237,0,0,0-1.9,0L5.494,17.632a.712.712,0,0,1-.307.069.722.722,0,0,1-.652-.413.733.733,0,0,1-.07-.308l0-.06,0-.014.417-4.019.011-.228a2.219,2.219,0,0,0-.6-1.517L1.7,8.351a.722.722,0,0,1-.2-.495.705.705,0,0,1,.227-.523.719.719,0,0,1,.331-.178l3.92-.926A2.222,2.222,0,0,0,7.379,5.2L9.362,1.854A.719.719,0,0,1,9.983,1.5a.7.7,0,0,1,.364.1l0,0a.718.718,0,0,1,.253.252L12.586,5.2a2.227,2.227,0,0,0,1.4,1.03l3.921.926a.72.72,0,0,1,.558.7Z" transform="translate(0 0)" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-section-featured'
  }
</script>
