var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 16.041 13.096"
      }
    },
    [
      _c("g", { attrs: { "data-name": "Group 1769" } }, [
        _c("path", {
          staticClass: "cl-2",
          attrs: {
            fill: "#919ae8",
            d:
              "M3.391 4.65h9.265a.75.75 0 0 0 0-1.5H3.391a.75.75 0 0 0 0 1.5M3.391 7.3h2.647a.75.75 0 0 0 0-1.5H3.391a.75.75 0 0 0 0 1.5M8.023 7.3h4.633a.75.75 0 0 0 0-1.5H8.023a.75.75 0 0 0 0 1.5M3.391 9.945h5.956a.75.75 0 0 0 0-1.5H3.391a.75.75 0 0 0 0 1.5"
          }
        }),
        _c("path", {
          attrs: {
            fill: "#293ad3",
            d:
              "M16.041 2.424v-.031A2.4 2.4 0 0 0 13.691 0H2.355A2.408 2.408 0 0 0 .002 2.407v8.28a2.412 2.412 0 0 0 2.354 2.409h11.337a2.413 2.413 0 0 0 2.35-2.409V2.424zm-1.5 8.263a.913.913 0 0 1-.889.909H2.39a.911.911 0 0 1-.89-.909V2.408a.909.909 0 0 1 .889-.908h11.266a.907.907 0 0 1 .886.906v8.282z",
            "data-name": "Path 621"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }