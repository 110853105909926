var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-auto-add",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "16.924",
        height: "18.074",
        viewBox: "0 0 16.924 18.074"
      }
    },
    [
      _c("g", [
        _c("path", { attrs: { d: "M8.4.582h0Z", fill: "#939393" } }),
        _c("path", {
          attrs: {
            d:
              "M14.9,6.511H9.724L11.6,2.988a2.074,2.074,0,0,0,.247-.966A2,2,0,0,0,11.217.58,2.073,2.073,0,0,0,9.8,0,1.993,1.993,0,0,0,8.4.581L.623,8.085A2,2,0,0,0,0,9.533a2.055,2.055,0,0,0,.551,1.4,2,2,0,0,0,1.476.635H7.2L5.329,15.091a2.042,2.042,0,0,0-.248.965,2,2,0,0,0,.627,1.44,2.069,2.069,0,0,0,1.42.579A2,2,0,0,0,8.519,17.5l0,0L16.3,9.992a2,2,0,0,0,.621-1.448,2.056,2.056,0,0,0-.551-1.4A2,2,0,0,0,14.9,6.511m.364,2.4-7.782,7.5,0,0a.493.493,0,0,1-.354.159.563.563,0,0,1-.383-.164.5.5,0,0,1-.165-.355.54.54,0,0,1,.073-.261l2.458-4.627a.749.749,0,0,0-.661-1.1H2.028a.5.5,0,0,1-.379-.158A.563.563,0,0,1,1.5,9.533a.491.491,0,0,1,.164-.368l7.782-7.5h0A.487.487,0,0,1,9.8,1.5a.572.572,0,0,1,.384.164.5.5,0,0,1,.166.358.574.574,0,0,1-.072.263L7.814,6.909a.751.751,0,0,0,.663,1.1H14.9a.493.493,0,0,1,.378.158.562.562,0,0,1,.148.375.491.491,0,0,1-.162.369",
            fill: "#939393"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }