<template>
    <svg height="24" width="24" viewBox="0 0 24 24"><text dominant-baseline="middle" text-anchor="middle" x="12" y="13" fill="currentColor">{{text}}</text></svg>
</template>
<script>
export default {
  name: 'Text',
  props: {
    text: { type: [String, Object]}, // text
  },
}
</script>
