<template>
  <div class="onboarding-finish">
    <h2 class="txt-title mb-0" >
      <transition appear name="fade">
        <b v-show="unlockedTitle">Registration Complete!</b>
      </transition>
    </h2>
    <div class="onboarding-finish-figure" :class="{unlocked:unlocked}">
      <svg width="164" height="165" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M74.3333 108.833L112.667 59" stroke="currentColor" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M51.3333 82L74.3333 108.833" stroke="currentColor" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

    </div>
  </div>
</template>

<script>

  export default {
    name: 'Finish',
    data() {
      return {
        unlocked: false,
        unlockedTitle: false,
      }
    },
    mounted() {
      setTimeout(()=>{
         this.unlocked = true;
      },800);
      setTimeout(()=>{
         this.unlockedTitle = true;
      },1400);
    }
  };
</script>
