var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-coin",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18.863",
        height: "18.861",
        viewBox: "0 0 18.863 18.861"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M756.657,1189.714a9.43,9.43,0,1,0,9.432,9.431A9.431,9.431,0,0,0,756.657,1189.714Zm5.609,15.038a7.929,7.929,0,1,1,2.323-5.607A7.906,7.906,0,0,1,762.266,1204.752Z",
          transform: "translate(-747.226 -1189.714)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M757.381,1198.392l.3-2.379q.916.059,1.995.177l.162-1.566-.251-.059q-.768-.147-1.684-.236l.266-2.127h-1.093l-.266,2.038h-.192a3.775,3.775,0,0,0-2.437.716,2.635,2.635,0,0,0-.887,2.18,2.427,2.427,0,0,0,.635,1.772,5.061,5.061,0,0,0,2.157,1.108l-.325,2.571q-.99-.06-2.157-.222l-.191,1.536.295.059a16.72,16.72,0,0,0,1.832.311l-.207,1.713a3.916,3.916,0,0,0,1.093.1l.222-1.743h.014a3.633,3.633,0,0,0,2.437-.791,2.879,2.879,0,0,0,.931-2.326,2.483,2.483,0,0,0-.554-1.766A5.066,5.066,0,0,0,757.381,1198.392Zm-1.049-.355a2.533,2.533,0,0,1-.857-.458.783.783,0,0,1-.221-.577q0-.96,1.329-1.019Zm.532,4.565.28-2.261a1.881,1.881,0,0,1,.717.436.856.856,0,0,1,.2.583A1.158,1.158,0,0,1,756.864,1202.6Z",
          transform: "translate(-747.226 -1189.714)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }