var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-back",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "40",
        height: "40",
        viewBox: "0 0 40 40"
      }
    },
    [
      _c("path", {
        staticClass: "cl-1 no-fill",
        attrs: {
          d: "M20 0A20 20 0 1 1 0 20 20 20 0 0 1 20 0z",
          fill: "#FAFAFA"
        }
      }),
      _vm._v(" "),
      _c("g", { attrs: { transform: "translate(10.978 12.784)" } }, [
        _c("path", {
          staticClass: "cl-2",
          attrs: {
            d:
              "M16.739 6.481H2.585l5.2-5.2A.75.75 0 0 0 6.724.221L.239 6.705a.75.75 0 0 0 0 1.061l6.485 6.485a.75.75 0 1 0 1.061-1.06L2.579 7.985h14.157a.75.75 0 0 0 0-1.5z",
            fill: "#000000"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }