<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.764" height="17.654" viewBox="0 0 17.764 17.654">
    <g id="Last_Month_Icon" data-name="Last Month Icon" transform="translate(0.499 0.5)">
      <path id="Union_24" data-name="Union 24" d="M11738.405,24210.654a4.261,4.261,0,0,1-4.26-4.26v-6.209a4.261,4.261,0,0,1,4.26-4.26h.376v-1.174a.75.75,0,1,1,1.5,0v1.174h4.49v-1.174a.75.75,0,1,1,1.5,0v1.174h.377a4.257,4.257,0,0,1,4.259,4.26v6.209a4.26,4.26,0,0,1-4.259,4.26Zm-2.759-10.469v6.209a2.759,2.759,0,0,0,2.759,2.758h8.245a2.759,2.759,0,0,0,2.759-2.758v-6.209a2.761,2.761,0,0,0-2.759-2.76h-.377v1.18a.751.751,0,0,1-1.5,0v-1.18h-4.49v1.18a.751.751,0,0,1-1.5,0v-1.18h-.376A2.761,2.761,0,0,0,11735.646,24200.186Zm9.643,5.584v-1.021a.75.75,0,0,1,1.5,0v1.021a.75.75,0,1,1-1.5,0Zm-3.512,0v-1.021a.75.75,0,0,1,1.5,0v1.021a.75.75,0,1,1-1.5,0Zm-3.512,0v-1.021a.75.75,0,0,1,1.5,0v1.021a.75.75,0,1,1-1.5,0Zm7.023-3.445v-1.016a.75.75,0,1,1,1.5,0v1.016a.75.75,0,1,1-1.5,0Zm-3.512,0v-1.016a.75.75,0,1,1,1.5,0v1.016a.75.75,0,1,1-1.5,0Zm-3.512,0v-1.016a.75.75,0,1,1,1.5,0v1.016a.75.75,0,1,1-1.5,0Z" transform="translate(-11734.146 -24194)" fill="#939393" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconLastMonthDate'
  }
</script>
