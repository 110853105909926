var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-send",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "15.359",
        height: "15.358",
        viewBox: "0 0 15.359 15.358"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M.191 5.501zM15.224 1.317A2.047 2.047 0 0013.311 0a2.013 2.013 0 00-.732.137L1.316 4.454a2.046 2.046 0 00-.132 3.767l3.881 1.808a.553.553 0 01.266.265l1.807 3.881a2.047 2.047 0 001.122 1.048 2.072 2.072 0 00.73.134 2.048 2.048 0 001.912-1.313L15.219 2.78a2.045 2.045 0 000-1.464zM1.5 6.363l.049-.225a.545.545 0 01.3-.28l10.692-4.1-6.89 6.89-3.836-1.786a.547.547 0 01-.315-.499zm8.006 7.145a.548.548 0 01-.512.35l-.2-.037a.544.544 0 01-.3-.279L6.71 9.709l6.889-6.889z",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }