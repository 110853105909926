<template>
  <svg class="icon-breadcrumb" xmlns="http://www.w3.org/2000/svg" width="4.515" height="7.531" viewBox="0 0 4.515 7.531">
    <g transform="translate(-605.12 -2674.771)">
      <path fill="#939393" d="M605.34,2676.052l2.484,2.485-2.484,2.485a.75.75,0,0,0,1.061,1.061l3.014-3.016a.749.749,0,0,0,0-1.06l-3.014-3.016a.75.75,0,0,0-1.061,1.061Z"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-breadcrumb'
  }
</script>
