var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-year",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.764",
        height: "17.651",
        viewBox: "0 0 17.764 17.651"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M4.76 17.151a4.259 4.259 0 01-4.259-4.259V6.686a4.26 4.26 0 014.26-4.26h.377V1.25a.75.75 0 011.5 0v1.176h4.491V1.25a.75.75 0 011.5 0v1.176h.372a4.26 4.26 0 014.261 4.26v6.206a4.26 4.26 0 01-4.261 4.259zM2.001 6.686v6.206a2.765 2.765 0 002.761 2.759h8.239a2.763 2.763 0 002.76-2.759V6.686a2.766 2.766 0 00-2.76-2.761h-.375v1.176a.75.75 0 11-1.5 0V3.925H6.637v1.176a.75.75 0 11-1.5 0V3.925H4.76a2.767 2.767 0 00-2.759 2.761zm7.185 7.507H7.807l.556-1.992h-.64L6.357 6.979h1.59l.886 3.4.885-3.4h1.6l-1.917 7.214z",
          fill: "currentColor",
          stroke: "rgba(0,0,0,0)",
          "stroke-miterlimit": "10"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }