var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "user-avatar",
      class: ((_obj = {}), (_obj["size-" + _vm.size] = true), _obj),
      style: { height: _vm.avatarSize + "px", width: _vm.avatarSize + "px" }
    },
    [
      _vm.model && !_vm.error
        ? _c("img", {
            attrs: { src: _vm.model },
            on: {
              error: function($event) {
                _vm.error = true
              }
            }
          })
        : _c(
            "b-avatar",
            {
              style: {
                height: _vm.avatarSize + "px",
                width: _vm.avatarSize + "px"
              },
              attrs: { variant: _vm.variant, src: _vm.model, rounded: "" },
              on: {
                "img-error": function($event) {
                  _vm.error = true
                }
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "m-auto w-100",
                  attrs: {
                    width: "148",
                    height: "148",
                    viewBox: "0 0 148 148",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M74.6178 81.4C88.922 81.4 100.518 69.8042 100.518 55.5C100.518 41.1958 88.922 29.6 74.6178 29.6C60.3137 29.6 48.7178 41.1958 48.7178 55.5C48.7178 69.8042 60.3137 81.4 74.6178 81.4Z",
                      stroke: "currentColor",
                      "stroke-width": "3",
                      "stroke-miterlimit": "10",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d:
                        "M74.6178 210.9C107.313 210.9 133.818 184.395 133.818 151.7C133.818 119.005 107.313 92.5 74.6178 92.5C41.9226 92.5 15.4178 119.005 15.4178 151.7C15.4178 184.395 41.9226 210.9 74.6178 210.9Z",
                      stroke: "currentColor",
                      "stroke-width": "3",
                      "stroke-miterlimit": "10",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round"
                    }
                  })
                ]
              )
            ]
          ),
      _vm._v(" "),
      _vm.editable
        ? _c("div", { staticClass: "user-avatar-edit" }, [
            _c("input", {
              ref: "file",
              attrs: { type: "file", multiple: false },
              on: { input: _vm.update }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "user-avatar-edit-icon" }, [
              _c(
                "svg",
                {
                  staticClass: "icon-xs",
                  attrs: { width: "24", viewBox: "0 0 24 24" }
                },
                [_c("use", { attrs: { "xlink:href": "#icon-pencil" } })]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }