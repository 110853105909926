import axios from "@app2/api/axios";
import get from "lodash/get";
import Page from "@app2/models/Page";
import router from '@app2/router';

const namespaced = true;

const state = {
    infiniteId: +new Date(),
    page: 1,
    total: null,
    fetching: false,
    complete: false,
};

const mutations = {
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    SET_TOTAL(state, payload) {
        state.total = payload;
    },
    SET_FETCHING(state, payload) {
        state.fetching = payload;
    },
    SET_COMPLETE(state, payload) {
        state.complete = payload;
    },
    RESET(state) {
        state.infiniteId = +new Date();
        state.page = 1;
        state.total = null;
        state.fetching = false;
        state.complete = false;
    },
};

const actions = {
    async infiniteHandler({ commit, state }, $state) {
        try {
            commit('SET_FETCHING', true);
            const { data } = await axios.get(`/api/internal/v2/hubs/${router.currentRoute.params.hash}/pages`, {
                params: {
                    page: state.page,
                    'filter[type]': 'page',
                }
            });
            if (data.data.length) {
                commit('SET_PAGE', state.page + 1);
                $state.loaded();
                await Page.insertOrUpdate({data: data.data});
            }
            if (!data.links.next || !data.data.length) {
                $state.complete();
                commit('SET_COMPLETE', true);
            }
            commit('SET_TOTAL', get(data,'meta.total', null));
        } catch (e) {
            $state.error(e);
            throw e;
        } finally {
            commit('SET_FETCHING', false);
        }
    },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
};