<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="icon-btn" width="26" height="26" viewBox="0 0 26 26">
    <circle class="cl-1" cx="13" cy="13" r="13" fill="rgba(199,203,255,0.5)"/>
    <g id="Close" transform="translate(8 8)">
      <path class="cl-3" d="M585.265,413.2l3.615-3.615a.749.749,0,0,0-1.06-1.06l-3.616,3.616-3.615-3.616a.749.749,0,0,0-1.06,1.06l3.616,3.615-3.616,3.616a.749.749,0,0,0,1.06,1.06l3.615-3.615,3.616,3.615a.749.749,0,0,0,1.06-1.06Z" transform="translate(-579.006 -407.956)" fill="#293ad3"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-close-circle'
  }
</script>
