import Vue from 'vue';
import Vuelidate from 'vuelidate';
import router from '@app2/modules/Library/Builder/playlist.router.js';
import store from "@app2/store";
import App from "@app2/modules/Library/Builder/App";

Vue.use(Vuelidate);
const vueConfig = {
  el: '#hub-playlist-form',
  store,
  router,
  render: h => h(App),
};
export default new Vue(vueConfig);
