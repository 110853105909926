var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-menu-audience",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "28",
        height: "23",
        viewBox: "0 0 28 23"
      }
    },
    [
      _c("rect", {
        attrs: { width: "28", height: "23", fill: "#fff", opacity: "0" }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M18.127,252.83a3.95,3.95,0,1,0-3.949-3.95A3.951,3.951,0,0,0,18.127,252.83Zm0-6.4a2.45,2.45,0,1,1-2.45,2.449A2.454,2.454,0,0,1,18.127,246.431Z",
          transform: "translate(-10.085 -244.931)",
          fill: "#293ad3"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M18.127,254.47h0a8.04,8.04,0,0,0-8.041,8.042v1.641h0a2.764,2.764,0,0,0,2.764,2.763H23.407a2.766,2.766,0,0,0,2.762-2.764V262.51A8.042,8.042,0,0,0,18.127,254.47Zm6.542,8.042v1.641a1.266,1.266,0,0,1-1.264,1.264H12.849a1.266,1.266,0,0,1-1.264-1.263h0v-1.641a6.542,6.542,0,0,1,6.541-6.543h0a6.542,6.542,0,0,1,6.542,6.541Z",
          transform: "translate(-10.085 -244.931)",
          fill: "#293ad3"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M28.361,250.994a3.032,3.032,0,1,0-3.031-3.031A3.032,3.032,0,0,0,28.361,250.994Zm0-4.563a1.532,1.532,0,1,1-1.532,1.532A1.535,1.535,0,0,1,28.361,246.431Z",
          transform: "translate(-10.085 -244.931)",
          fill: "#293ad3"
        }
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cl-3",
        attrs: {
          d:
            "M37.328,259.036H29.384a.75.75,0,0,0,0,1.5h7.944a.75.75,0,1,0,0-1.5Z",
          transform: "translate(-10.085 -244.931)",
          fill: "#7b88ff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cl-3",
        attrs: {
          d:
            "M33.323,263.036H29.384a.75.75,0,0,0,0,1.5h3.939a.75.75,0,0,0,0-1.5Z",
          transform: "translate(-10.085 -244.931)",
          fill: "#7b88ff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M28.364,254.075a4.449,4.449,0,0,1,4.186,2.942.75.75,0,0,0,1.412-.508,5.951,5.951,0,0,0-5.6-3.934h0a5.922,5.922,0,0,0-3.96,1.513.75.75,0,0,0,1,1.118h0a4.423,4.423,0,0,1,2.963-1.132h0Z",
          transform: "translate(-10.085 -244.931)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }