<template>
  <svg class="icon-visitor" xmlns="http://www.w3.org/2000/svg" width="17.529" height="12.563" viewBox="0 0 17.529 12.563">
    <path d="M8.772 3.103a3.187 3.187 0 103.187 3.187 3.187 3.187 0 00-3.187-3.187zm0 4.874a1.688 1.688 0 111.687-1.687 1.689 1.689 0 01-1.687 1.687z" fill="#293ad3"/>
    <path d="M16.647 8.773a3.965 3.965 0 00-.009-4.99 10.091 10.091 0 00-15.752 0 3.962 3.962 0 000 5 9.8 9.8 0 00.719.8 10.076 10.076 0 0014.237.05l.007-.006a11.037 11.037 0 00.782-.836c.016 0 .016-.01.016-.018zm-1.851-.2a8.578 8.578 0 01-12.127-.037 8.309 8.309 0 01-.612-.687v-.006a2.463 2.463 0 010-3.111 8.595 8.595 0 0113.417 0 2.47 2.47 0 010 3.115l-.005.012a9.662 9.662 0 01-.663.708z" fill="#293ad3"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconVisitor'
  }
</script>
