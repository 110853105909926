const namespaced = true;

const state = {
  isModalOpen: false,
  createForm: null,
  bgVariant: 'white',
  canCloseModal: true,
  createFormInitial: null
};

const mutations = {
  SET_FORM(state, payload) {
    state.createForm = payload;
  },
  SET_MODAL_STATE(state, payload) {
    state.isModalOpen = payload
  },
  SET_BG_VARIANT(state, payload) {
    state.bgVariant = payload
  },
  SET_CAN_CLOSE_MODAL(state, payload) {
    state.canCloseModal = payload
  },
  SET_FORM_INITIAL(state, payload) {
    state.createFormInitial = payload
  }
};

const actions = {
};

const getters = {
  getCreateForm: state => state.createForm,
  canCloseModal: state => state.canCloseModal,
  createFormInitial: state => state.createFormInitial
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
