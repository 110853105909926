import { get } from 'lodash';

export default (totalPlans, {extra = false, custom = false}={}) => {
  let plans = totalPlans.filter(plan => plan && plan.status === 'active' && (get(plan, 'item.enabled_in_portal') || custom)),
    buckets = [];

  plans.forEach(plan => {
    let type = get(plan, 'external_name');
    let order = get(plan, 'metadata.plan_information.order');
    let color = get(plan, 'metadata.plan_information.color');
    let recommended = get(plan, 'metadata.plan_information.recommended');
    if ( !type ) return;
    let index = buckets.findIndex(bucket => bucket && bucket.type === type);
    if (index !== -1) {
      if (buckets[index].hasOwnProperty('plans')) {
        buckets[index].plans.push(plan);
      } else {
        buckets[index].plans = [plan];
      }
    } else {
      buckets.push({
        type,
        order,
        color,
        recommended,
        plans: [plan]
      })
    }
  });
  buckets.forEach(bucket => {
    bucket.period_unit = ( bucket.plans && bucket.plans.length > 1 ) ? 'year' : bucket.plans[0].period_unit;
  });

  //sort buckets by order value
  buckets.sort((a, b) => a.order - b.order);

  return buckets;
}