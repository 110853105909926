var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "8.421",
        height: "12.326"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            "data-name": "Arrows both directions",
            fill: "none",
            stroke: "#939393",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1.5"
          }
        },
        [
          _c("path", {
            attrs: {
              "data-name": "Arrow Bign",
              d:
                "M1.065 3.897l3.15-3.15 3.15 3.15M7.36 8.426l-3.15 3.15-3.15-3.15"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }