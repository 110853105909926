import BaseLink from 'prosemirror-to-html-js/src/Marks/Link';
export default class Link extends BaseLink {
  tag () {
    return [
      {
        tag: "a",
        attrs: {
          href: this.mark.attrs.href,
          target: this.mark.attrs.target === '_self' ? '_top' : this.mark.attrs.target,
        }
      }
    ]
  }
}
