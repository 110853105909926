var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-person",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "12.522",
        height: "16.984",
        viewBox: "0 0 12.522 16.984"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M6.261,6.335A3.168,3.168,0,1,0,3.093,3.168,3.168,3.168,0,0,0,6.261,6.335m0-4.836A1.669,1.669,0,1,1,4.593,3.168,1.671,1.671,0,0,1,6.261,1.5",
          transform: "translate(0 0.001)",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M6.261,7.209h0A6.26,6.26,0,0,0,0,13.47v1.24H0a2.272,2.272,0,0,0,2.272,2.271h7.979a2.273,2.273,0,0,0,2.271-2.273V13.47h0a6.262,6.262,0,0,0-6.261-6.26m4.761,6.261v1.24a.774.774,0,0,1-.771.773H2.272a.773.773,0,0,1-.772-.772h0V13.47A4.761,4.761,0,0,1,6.26,8.708h0a4.762,4.762,0,0,1,4.761,4.761Z",
          transform: "translate(0 0.001)",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }