<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19.913" height="17.257" viewBox="0 0 19.913 17.257">
      <g id="Group_1789" data-name="Group 1789" transform="translate(-327 -382)">
        <path id="Path_665" data-name="Path 665" d="M338.264,382.09h0Z"/>
        <path id="Path_666" data-name="Path 666" d="M342.033,397.421h0Z"/>
        <path id="Path_667" data-name="Path 667" d="M344.308,394.889h0Z"/>
        <path id="Path_668" data-name="Path 668" d="M346.736,394.149h0l-1.4-2.8a.749.749,0,0,0-1.006-.336l-2.8,1.4a.75.75,0,1,0,.671,1.342l1.316-.659a7.154,7.154,0,0,1-.5,1.027v0a7.141,7.141,0,0,1-1.882,2.1l0,0a7.159,7.159,0,0,1-2.541,1.215h0a7.225,7.225,0,0,1-5.47-.787l0,0a7.145,7.145,0,0,1-2.1-1.882.75.75,0,0,0-1.2.9,8.634,8.634,0,0,0,2.534,2.277l0,0a8.632,8.632,0,0,0,3.212,1.135,8.773,8.773,0,0,0,1.246.09,8.63,8.63,0,0,0,2.154-.272h0a8.663,8.663,0,0,0,5.908-5.129l.528,1.057a.75.75,0,1,0,1.342-.67Z"/>
        <path id="Path_669" data-name="Path 669" d="M332.62,387.745a.748.748,0,0,0-1.005-.335l-1.3.65a7.055,7.055,0,0,1,.495-1.02v0a7.152,7.152,0,0,1,1.883-2.094l0,0a7.137,7.137,0,0,1,2.54-1.215h0a7.083,7.083,0,0,1,2.81-.15h0a7.13,7.13,0,0,1,2.655.939l0,0A7.125,7.125,0,0,1,342.8,386.4a.75.75,0,0,0,1.2-.9,8.668,8.668,0,0,0-2.535-2.278l0,0a8.621,8.621,0,0,0-3.211-1.135,8.348,8.348,0,0,0-1.249-.091,8.654,8.654,0,0,0-2.152.272h0a8.648,8.648,0,0,0-3.074,1.472l0,0a8.621,8.621,0,0,0-2.278,2.533v0a8.744,8.744,0,0,0-.565,1.144l-.535-1.07a.75.75,0,0,0-1.341.671h0l1.4,2.8a.749.749,0,0,0,1.006.335l2.8-1.4A.749.749,0,0,0,332.62,387.745Z"/>
        <path id="Path_670" data-name="Path 670" d="M336.742,395.488l.157-1.231h.01a2.568,2.568,0,0,0,1.722-.559,2.034,2.034,0,0,0,.658-1.644,1.753,1.753,0,0,0-.4-1.247,3.58,3.58,0,0,0-1.477-.756l.208-1.681q.646.042,1.409.125l.116-1.11-.178-.042q-.543-.1-1.19-.167l.188-1.5H337.2l-.188,1.441h-.135a2.667,2.667,0,0,0-1.722.507,1.863,1.863,0,0,0-.627,1.539,1.716,1.716,0,0,0,.449,1.253,3.585,3.585,0,0,0,1.524.782l-.229,1.817q-.7-.041-1.524-.157l-.136,1.086.208.041a11.672,11.672,0,0,0,1.3.22l-.147,1.21A2.75,2.75,0,0,0,336.742,395.488Zm.512-4.059a1.335,1.335,0,0,1,.507.307.606.606,0,0,1,.141.412h0l0,.1a.818.818,0,0,1-.849.786Zm-1.18-1.953a.55.55,0,0,1-.156-.407h0q0-.676.939-.719l-.177,1.451A1.776,1.776,0,0,1,336.074,389.476Z"/>
      </g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-settings-subscription'
  }
</script>
