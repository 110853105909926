import Base from '@app2/models/Base';
import { isDomain } from '@app2/utils/helpers';
import moment from '@app2/utils/moment';
import Folder from '@app2/models/Folder';
import Tag from '@app2/models/Tag';
import playerEmbedSettings from "@app2/utils/playerEmbedSettings";
import playerLogo from "@app2/utils/playerLogo";
import Playlist from "@app2/models/Playlist";
import FilePlaylist from "@app2/models/FilePlaylist";
import get from "lodash/get";

export default class File extends Base {
  static entity = 'App\\File';

  static apiConfig = {
    baseURL: '/api/internal/v2/files/',
    actions: {
      fetch: {
        url: '/',
        // append connection_type from the file to the nested folder
        dataTransformer: response => {
          response.data.data.forEach(d => d.folder && (d.folder.connection_type = d.connection_type));
          return response.data.data;
        }
      },
      fetchForFolder(folderId, config) {
        return this.get(`${folderId}/files`, {
          dataKey: 'data',
          baseURL: Folder.apiConfig.baseURL,
          ...config
        })
      },
      fetchForPlaylist(playlistId, config) {
        return this.get(`${playlistId}/files`, {
          baseURL: Playlist.apiConfig.baseURL,
          ...config,
        })
      },
      search(config) {
        return this.get(`/`, {
          dataKey: 'data',
          baseURL: '/api/internal/v2/search/files/',
          ...config
        })
      },
      bulkDelete(config) {
        return this.delete('bulk', {...config})
      }
    }
  };

  static primaryKey = 'hash';

  watchFields = ['title', 'description', 'meta', 'chapters', 'cards', 'privacy','thumbnail', 'tags'];
  requiredFields = ['title', 'folder_id'];

  static fields() {
    return {
      id: this.attr(null),
      hash: this.attr(null),
      user_id: this.attr(null),
      connection_type: this.attr(null),
      collection_name: this.attr(null),
      folder_id: this.attr(null),
      folder: this.belongsTo(Folder, 'folder_id'),
      cards: this.attr([]),
      chapters: this.attr([]),
      origin_folder_id: this.attr(null),
      origin_folder: this.belongsTo(Folder, 'origin_folder_id'),
      source_id: this.attr(null),
      title: this.attr(null),
      description: this.attr(null),
      document_preview_url: this.attr(null),
      document_download_url: this.attr(null),
      highlight: this.attr(null),
      source_url: this.attr(null),
      thumbnail: this.attr(null),
      thumb_url: this.attr(null),
      custom_logo_url: this.attr(null),
      meta: this.attr(null),
      has_transcription: this.attr(null),
      privacy: this.attr(null),
      status: this.attr(null),
      status_replace: this.attr(null),
      status_upload: this.attr(null),
      status_transcription: this.attr(null),
      status_transcode: this.attr(null),
      status_proofread: this.attr(null),
      status_suggestion: this.attr(null),
      transcriptions_count: this.attr(null),
      permissions: this.attr({}),
      player_settings: this.attr(null),
      player_logo: this.attr(null),
      posted_at: this.attr(null),
      posted_at_local: this.attr(null),
      thumbnail_previews: this.attr(null),
      can_generate_suggestion: this.attr({}),
      tags: this.attr(null),
      new_upload: this.attr(null),
      playlists: this.belongsToMany(Playlist, FilePlaylist, 'file_id', 'playlist_id'),
      file_playlists: this.hasMany(FilePlaylist, 'file_id', 'id'),
      index_playlists: this.attr(null),
      index_tags: this.attr(null),
      page: this.attr(null),
      attachments: this.attr([]),
      attachedFiles: this.attr([]),
      showAttachments: this.attr(null),
      is_selected: this.boolean(false),
      is_copilot_processed: this.boolean(false),
      copilot_started_at: this.attr(null),
      ...super.fields(),
    };
  }

  static beforeCreate(model) {
    if (!model.thumbnail && model.meta && model.meta.thumbnail) {
      model.thumbnail = model.meta.thumbnail;
      delete model.meta.thumbnail;
    }
    File.updateTime(model);
  }
  static beforeUpdate(model) {
    File.updateTime(model);
  }
  static updateTime(model) {
    if (!model) return;
    if (model.posted_at_local) return;
    model.posted_at_local = moment(model.posted_at || model.created_at).fromNow();
  }

  get lowerTitle() {
    return (this.title) ? this.title.toLowerCase() : '';
  }

  isInPlaylist(playlistId) {
    if (!this.file_playlists) return false;
    return this.file_playlists.map(p => p.playlist_id).includes(playlistId);
  }

  get isAudio() {
    return this.source_url && this.source_url.substring(this.source_url.lastIndexOf('.')+1, this.source_url.length) === 'mp3' ||
      this.collection_name === 'audio';
  }

  get noAudio () {
    return this.isPlayable && !this.status_transcription
  }

  get noAudioWarning () {
    return `File does not have any audio.`
  }

  get isNotProcessed() {
    return !this.status
      && !this.status_upload
      && !this.status_transcode
      && !this.status_transcription;
  }

  get isProcessed() {
    return (this.status_upload || '').includes('processed')
      && (this.status_transcode || '').includes('processed')
      && (this.status_transcription || '').includes('processed');
  }

  get isPlayable() {
    return (this.status_upload || '').includes('processed')
      && (this.status_transcode || '').includes('processed');
  }

  get isPending() {
    return (this.status || '').includes('pending')
      || (this.status_upload || '').includes('pending')
      || (this.status_transcode || '').includes('pending')
      || (this.status_transcription || '').includes('pending');
  }

  get isUploading() {
    return (this.status_upload || '').includes('pending')
      && !(this.status_transcode || '').includes('pending')
      && !(this.status_transcription || '').includes('pending')
  }

  get isReplacing() {
    return (this.status_replace || '').includes('pending');
  }

  get isTranscoding() {
    return (this.status_transcode || '').includes('pending');
  }

  get isTranscribing() {
    return (this.status_transcription || '').includes('pending');
  }

  get isFailed() {
    return (this.status || '').includes('failed')
      || (this.status_upload || '').includes('failed')
      || (this.status_transcode || '').includes('failed')
      || (this.status_transcription || '').includes('failed');
  }

  get isReplaceable() {
    return this.isLocal || this.connection_type === 'extension' || !this.connection_type
  }

  get isLocal() {
    return this.folder && this.folder.connection_type === 'local' || this.connection_type === 'local';
  }
  get isSample() {
    return this.source_url && this.source_url.includes('876159ff37cf5f57d5cee0320158064.m3u8');
  }

  get isMP4() {
    return this.source_url && this.source_url.substring(this.source_url.lastIndexOf('.')+1, this.source_url.length) === 'mp4';
  }

  get isM3U8() {
    return this.source_url && this.source_url.substring(this.source_url.lastIndexOf('.')+1, this.source_url.length) === 'm3u8';
  }

  get playerEmbed() {
    return playerEmbedSettings(this.player_settings);
  }

  get logo() {
    return playerLogo(this.player_logo);
  }

  static mutators() {
    return {
      player_settings(value) {
        if ( !value ) return {};
        Object.entries(value).forEach(level => {
          if ( ( Array.isArray(level[1]) || !level[1] ) ) {
            value[level[0]] = {};
          }
        });
        return value;
      }
    };
  }

  get thumb() {
    if (!this.thumbnail) return {};
    return {
      src: this.thumbnail.big,
      srcset: `${this.thumbnail.small} 400w, ${this.thumbnail.big} 1200w`,
      sizes: `(max-width: 400px) 400px, 1200px`
    }
  }

  get icon() {
    return File.getIcon(this);
  }

  static getIcon(model) {
    if ( model?.file_category === 'document' ) {
      return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 6H5C3.9 6 3 6.9 3 8V20C3 21.1 3.9 22 5 22H13C13.7 22 14.4 21.6 14.7 21M12 6H16M12 10H18M12 14H16M19 2H11C9.9 2 9 2.9 9 4V16C9 17.1 9.9 18 11 18H19C20.1 18 21 17.1 21 16V4C21 2.9 20.1 2 19 2Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>`;      
    } else if ( model?.isAudio || model?.file_category === 'audio' ) {
      return `<svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="none" viewBox="0 0 96 96">
                <path stroke="#707070" vector-effect="non-scaling-stroke" stroke-miterlimit="10" stroke-width="1.5" d="M23.2 52.6h-1.8c-4.4 0-8 3.6-8 8v21c0 4.4 3.6 8 8 8h1.8c2.2 0 4-1.8 4-4v-29c0-2.2-1.8-4-4-4ZM72.8 52.6h1.8c4.4 0 8 3.6 8 8v21c0 4.4-3.6 8-8 8h-1.8c-2.2 0-4-1.8-4-4v-29c0-2.2 1.8-4 4-4Z" />
                <path stroke="#707070" vector-effect="non-scaling-stroke" stroke-miterlimit="10" stroke-width="1.5" d="m23.6 14.2 5 5c-1.8 1.2-3.4 2.6-5 4.2-6.4 6.4-10.4 15-10.4 24.6v11.6H8.6c-.8 0-1.6.2-2.4.6V48c.2-13.8 7-26.2 17.4-33.8ZM89.6 48v12.2c-.6-.4-1.4-.6-2.4-.6h-4.6V48c0-12-6-22.4-15.2-28.8l5-5c1.8 1.2 3.4 2.6 5 4.2C85 26 89.6 36.4 89.6 48Z" />
                <path stroke="#707070" vector-effect="non-scaling-stroke" stroke-miterlimit="10" stroke-width="1.5" d="M13.2 59.6H8c-2.2 0-4 1.8-4 4v15.2c0 2.2 1.8 4 4 4h5.2V59.6ZM82.8 59.6H88c2.2 0 4 1.8 4 4v15.2c0 2.2-1.8 4-4 4h-5.2V59.6ZM48 6.4c-9.2 0-17.6 3-24.4 8l5 5 .2.2c1.8 1.8 4.4 2.2 6.8 1 3.8-1.8 8-2.8 12.4-2.8s8.6 1 12.4 2.8c2.2 1 5 .6 6.8-1l.2-.2 5-5c-6.8-5.2-15.2-8-24.4-8Z" />
                <path stroke="#707070" vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="M48 89.6v-37M41 82.8V59.6M34.2 75.8v-9.2M61.8 82.8V59.6M55 78.2v-14" />
              </svg>`;
    } else {
      return `<svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="none" viewBox="0 0 96 96">
                <path stroke="#707070" vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="M86.2 9.8C85.2 8.6 83.6 8 82 8H14c-3.4 0-6 2.6-6 6v68c0 1.6.6 3.2 1.8 4.2M88 20v62c0 3.4-2.6 6-6 6H20" />
                <path stroke="#707070" vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="m8 74.6 22.2-22.2c2.4-2.4 6.4-2.4 8.8 0l2.2 2.2M47.4 60.6l5 5.2 9 8.8" />
                <path stroke="#707070" vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="m52.4 65.8 9-9c2.4-2.4 6.4-2.4 8.8 0L88 74.6M63.2 32.8c-1.2-1.2-2-2.8-2-4.8 0-3.6 3-6.6 6.6-6.6 1.8 0 3.6.8 4.8 2M4 92 92 4" />
              </svg>`;
    }
  }

  get image() {
    return File.getImage(this);
  }

  static getImage(model) {
    let src;
    let srcset;
    let sizes;
    if (model.thumbnail?.big || model.thumbnail?.small) {
      src = model.thumbnail.big;
      srcset = `${model.thumbnail.small} 400w, ${model.thumbnail.big} 1200w`;
      sizes = `(max-width: 400px) 400px, 1200px`;
    } else if (model.thumb_url) {
      src = model.thumb_url;
      srcset = `${model.thumb_url} 400w, ${model.thumb_url} 1200w`;
      sizes = `(max-width: 400px) 400px, 1200px`;
    } else if (model.thumbnail_url) {
      src = model.thumbnail_url;
      srcset = `${model.thumbnail_url} 400w, ${model.thumbnail_url} 1200w`;
      sizes = `(max-width: 400px) 400px, 1200px`;
    } else if (['jpg', 'jpeg', 'png', 'gif'].includes(model.meta?.file_extension)) {
      src = model.source_url;
      srcset = `${model.source_url} 400w, ${model.source_url} 1200w`;
      sizes = `(max-width: 400px) 400px, 1200px`;
    }
    if ( src ) return `<img src="${src}" srcset="${srcset}" sizes="${sizes}" alt="${model.title}">`;
    return File.getIcon(model);
  }

  get canSaveChapters() {
    if ( !this.chapters ) return;
    return this.chapters.filter((ch, index) => {
      if ( !ch.title ) return false;
      if ( get(this, 'meta.length') && ch.start / 1000 >= get(this, 'meta.length') ) return false;
      return !(index !== 0 && (ch.start === 0 || ch.start === this.chapters[index - 1].start));
    }).length === this.chapters.length;
  }

  get chapterErrors() {
    let errors = {};
    this.chapters.forEach((ch, index) => {
      if ( !ch.title ) {
        errors.title = 'Chapter titles are required.';
      }
      if ( index !== 0 && (ch.start === 0 || ch.start === this.chapters[index - 1].start) ) {
        errors.start = 'Chapter start cannot be equal to another chapter start.';
      }
      if ( get(this, 'meta.length') && ch.start / 1000 >= get(this, 'meta.length') ) {
        errors.over = 'Chapter start cannot be bigger than the file length.';
      }
    });
    return Object.values(errors);
  }

  get canSaveCards() {
    const { cards } = this;
    if (!cards) return false;
    
    return cards.every((c) => {
      return !Object.values(File.getCardErrors(c, this)).length;
    });
  }

  static cardErrorMessages = {
    title: 'Card labels are required.',
    start: 'Cards cannot be within 6 seconds of another card.',
    over: 'Card start cannot be bigger than the file length.',
    url: 'Card URL is not valid.',
  };

  static getCardErrors(card, media) {
    const errors = {};
    
    if (!card.label) {
      errors.title = File.cardErrorMessages.title;
    }

    if (card.url && !isDomain(card.url)) {
      errors.url = File.cardErrorMessages.url;
    }

    // Iterate through all cards to check if the current card is within 6000ms of any other card
    media.cards.forEach((otherCard) => {
      if (otherCard.id !== card.id) { // Avoid comparing the card with itself
        if (Math.abs(card.start - otherCard.start) <= 6 * 1000) {
          errors.start = File.cardErrorMessages.start;
        }
      }
    });

    if (media.meta && card.start >= media.meta.length * 1000) {
      errors.over = File.cardErrorMessages.over;
    }

    return errors;
  }

  get cardErrors() {
    return this.cards.reduce((acc, card) => {
      const errors = File.getCardErrors(card, this);
      if ( !acc.includes(File.cardErrorMessages.title) && errors.title ) {
        acc.push(File.cardErrorMessages.title);
      }
      if ( !acc.includes(File.cardErrorMessages.start) && errors.start ) {
        acc.push(File.cardErrorMessages.start);
      }
      if ( !acc.includes(File.cardErrorMessages.over) && errors.over ) {
        acc.push(File.cardErrorMessages.over);
      }
      return acc;
    }, []);
  }
}
