<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19.021" viewBox="0 0 17.933 19.021">
    <path d="M152.352,128.226H140.227a2.9,2.9,0,0,0-2.9,2.9v7.214a2.9,2.9,0,0,0,2.9,2.9h12.125a2.905,2.905,0,0,0,2.9-2.9v-7.212A2.9,2.9,0,0,0,152.352,128.226Zm1.4,10.115a1.405,1.405,0,0,1-1.4,1.4H140.227a1.407,1.407,0,0,1-1.4-1.4v-7.214a1.406,1.406,0,0,1,1.4-1.4h12.125a1.407,1.407,0,0,1,1.4,1.4Z" transform="translate(-137.323 -122.224)" fill="currentColor"/><path d="M140.854,126.725h10.871a.75.75,0,1,0,0-1.5H140.854a.75.75,0,0,0,0,1.5Z" transform="translate(-137.323 -122.224)" fill="currentColor"/><path d="M142.748,123.724h7.082a.75.75,0,0,0,0-1.5h-7.082a.75.75,0,0,0,0,1.5Z" transform="translate(-137.323 -122.224)" fill="currentColor"/><path d="M148.162,133.26h0l-2.069-1.14.006,0a1.722,1.722,0,0,0-2.564,1.451v2.313a1.67,1.67,0,0,0,.231.839l.008.013a1.74,1.74,0,0,0,2.315.614h0l2.057-1.136h0l.01-.006h0a1.69,1.69,0,0,0,.655-.631l.015-.024.02-.036a1.679,1.679,0,0,0-.687-2.258Zm-.727,1.637-2.067,1.141-.113.029-.2-.11-.022-.08V133.6a.209.209,0,0,1,.218-.2l.112.028,0,0,2.069,1.14.01.005.094.157-.022.083h0l-.075.072Z" transform="translate(-137.323 -122.224)" fill="currentColor"/><path d="M148.815,135.577h0l0,0Z" transform="translate(-137.323 -122.224)" fill="currentColor"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconPlaylists',
  };
</script>
