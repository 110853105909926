<template>
  <svg class="icon-one-drive" xmlns="http://www.w3.org/2000/svg" width="28" height="17.939" viewBox="0 0 28 17.939">
    <path d="M16.916 12.106L22.7 6.578A8.75 8.75 0 006.91 3.941c.09-.002 10.006 8.165 10.006 8.165z" fill="#0364b8"/>
    <path d="M10.677 4.982A6.965 6.965 0 007 3.939h-.089a7 7 0 00-5.653 11l8.659-1.084 6.638-5.353z" fill="#0078d4"/>
    <path d="M22.695 6.58a6.102 6.102 0 00-.384-.014 5.666 5.666 0 00-2.254.465l-3.5 1.474 3.861 4.769 6.9 1.678a5.687 5.687 0 00-4.623-8.372z" fill="#1490df"/>
    <path d="M1.257 14.94a6.99 6.99 0 005.742 3h15.312a5.686 5.686 0 005.007-2.988L16.554 8.505z" fill="#28a8ea"/>
  </svg>
</template>
<script>
export default {
  name: 'IconGraph',
};
</script>
