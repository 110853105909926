var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 20 20"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(1 1)" } }, [
        _c("path", {
          attrs: {
            d: "M773,344a9,9,0,1,1-9-9,9,9,0,0,1,9,9Zm0,0",
            transform: "translate(-755 -335)",
            fill: "#f0f0f0"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M894.827,471.7a3.158,3.158,0,0,1-6.261,0,3.13,3.13,0,1,1,6.261,0Zm0,0",
            transform: "translate(-882.696 -462.696)",
            fill: "#d80027"
          }
        }),
        _c("path", {
          attrs: {
            d: "M894.827,539.8a3.13,3.13,0,0,1-6.261,0",
            transform: "translate(-882.696 -530.801)",
            fill: "#0052b4"
          }
        }),
        _c("path", {
          attrs: {
            d: "M1035.352,584.705l.83-.83.553.553-.83.83Zm0,0",
            transform: "translate(-1023.032 -572.938)"
          }
        }),
        _c("path", {
          attrs: {
            d: "M1003.867,616.189l.83-.83.553.553-.83.83Zm0,0",
            transform: "translate(-992.931 -603.039)"
          }
        }),
        _c("path", {
          attrs: {
            d: "M1073.137,622.475l.83-.83.553.553-.83.83Zm0,0",
            transform: "translate(-1059.156 -609.048)"
          }
        }),
        _c("path", {
          attrs: {
            d: "M1041.652,653.963l.83-.83.554.553-.83.83Zm0,0",
            transform: "translate(-1029.055 -639.153)"
          }
        }),
        _c("path", {
          attrs: {
            d: "M1054.242,603.592l.83-.83.553.553-.83.83Zm0,0",
            transform: "translate(-1041.092 -590.995)"
          }
        }),
        _c("path", {
          attrs: {
            d: "M1022.766,635.076l.83-.83.554.553-.83.83Zm0,0",
            transform: "translate(-1010.999 -621.096)"
          }
        }),
        _c("path", {
          attrs: {
            d: "M1024.986,416.634l-2.213-2.214.553-.553,2.214,2.214Zm0,0",
            transform: "translate(-1011.006 -410.401)"
          }
        }),
        _c("path", {
          attrs: {
            d: "M1004.713,434.149l-.83-.83.553-.553.83.83Zm0,0",
            transform: "translate(-992.946 -428.47)"
          }
        }),
        _c("path", {
          attrs: {
            d: "M1036.189,465.629l-.83-.83.553-.553.83.83Zm0,0",
            transform: "translate(-1023.039 -458.566)"
          }
        }),
        _c("path", {
          attrs: {
            d: "M1042.475,396.371l-.83-.83.553-.553.83.83Zm0,0",
            transform: "translate(-1029.048 -392.352)"
          }
        }),
        _c("path", {
          attrs: {
            d: "M1073.967,427.852l-.83-.83.553-.553.83.83Zm0,0",
            transform: "translate(-1059.156 -422.449)"
          }
        }),
        _c("path", {
          attrs: {
            d: "M815.537,621.652l2.214,2.214-.553.553-2.214-2.214Zm0,0",
            transform: "translate(-812.348 -609.055)"
          }
        }),
        _c("path", {
          attrs: {
            d: "M865.905,634.242l.83.83-.553.553-.83-.83Zm0,0",
            transform: "translate(-860.503 -621.092)"
          }
        }),
        _c("path", {
          attrs: {
            d: "M834.424,602.762l.83.83-.553.553-.83-.83Zm0,0",
            transform: "translate(-830.405 -590.995)"
          }
        }),
        _c("path", {
          attrs: {
            d: "M853.315,583.875l2.213,2.213-.553.553-2.214-2.214Zm0,0",
            transform: "translate(-848.466 -572.938)"
          }
        }),
        _c("path", {
          attrs: {
            d: "M817.751,395.537l-2.213,2.213-.553-.553,2.214-2.213Zm0,0",
            transform: "translate(-812.348 -392.348)"
          }
        }),
        _c("path", {
          attrs: {
            d: "M836.634,414.42l-2.213,2.214-.553-.553,2.214-2.214Zm0,0",
            transform: "translate(-830.401 -410.401)"
          }
        }),
        _c("path", {
          attrs: {
            d: "M855.537,433.319l-2.214,2.213-.553-.553,2.214-2.214Zm0,0",
            transform: "translate(-848.474 -428.47)"
          }
        }),
        _c(
          "g",
          { attrs: { fill: "none", stroke: "#e4e4e4", "stroke-width": "1" } },
          [
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9", stroke: "none" }
            }),
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9.5", fill: "none" }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }