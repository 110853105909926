<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22.714" height="17.534" viewBox="0 0 22.714 17.534">
    <g id="Group_4456" data-name="Group 4456" transform="translate(-1148.674 -583.744)">
      <path id="Rectangle_830" data-name="Rectangle 830" d="M0,0H11.915a2.04,2.04,0,0,1,2.04,2.04v0a2.04,2.04,0,0,1-2.04,2.04H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(1157.576 595.616) rotate(-45)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      <path id="Path_1167" data-name="Path 1167" d="M1153.544,600.528l4.912-2.031-2.881-2.881Z" transform="translate(2)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      <line id="Line_183" data-name="Line 183" x1="13.5" transform="translate(1149.424 584.494)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      <line id="Line_184" data-name="Line 184" x1="9.5" transform="translate(1149.424 588.517)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      <line id="Line_185" data-name="Line 185" x1="5.5" transform="translate(1149.424 592.539)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      <line id="Line_186" data-name="Line 186" x1="2.5" transform="translate(1149.424 596.539)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    </g>
  </svg>

</template>
<script>
  export default {
    name: 'icon-edit-script',
  };
</script>
