import { Node } from 'prosemirror-to-html-js';
import get from 'lodash/get';

export default class OrderedList extends Node {
  matching () {
    return this.node.type === "ordered_list" || this.node.type === "orderedList";
  }

  tag () {
    return [
      {
        tag: "ol",
        attrs: {
          start: get(this.node, 'attrs.start') - 1,
          style: `counter-reset: ol-counter ${get(this.node, 'attrs.start', 0) - 1}`
        }
      }
    ];
  }
}
