var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-draggable",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "15.514",
        height: "7.5",
        viewBox: "0 0 15.514 7.5"
      }
    },
    [
      _c("rect", {
        attrs: { width: "15.514", height: "1.5", rx: "0.75", fill: "#939393" }
      }),
      _c("rect", {
        attrs: {
          width: "15.514",
          height: "1.5",
          rx: "0.75",
          transform: "translate(0 6)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }