<template>
  <svg class="icon-sort-asc" xmlns="http://www.w3.org/2000/svg" width="10.512" height="10.994" viewBox="0 0 10.512 10.994">
    <path fill="#939393" d="M295.483,364.774a.75.75,0,0,0,1.061,0l4.506-4.506a.75.75,0,0,0-1.06-1.06h0l-3.225,3.225V354.75a.75.75,0,0,0-1.5,0v7.683l-3.226-3.226a.75.75,0,1,0-1.06,1.062Z" transform="translate(-290.757 -354)"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-sort-asc'
  }
</script>
