<template>
  <svg id="Captions_Icon" data-name="Captions Icon" xmlns="http://www.w3.org/2000/svg" width="19.164" height="15.587" viewBox="0 0 19.164 15.587">
    <path id="Path_7104" data-name="Path 7104" d="M263.063,1257.665v-.011h0v-.042a2.758,2.758,0,0,0-2.7-2.757H246.6a2.762,2.762,0,0,0-2.7,2.762v10.015h0v.046a2.765,2.765,0,0,0,2.7,2.764h13.766a2.77,2.77,0,0,0,2.7-2.766v-10.01Zm-2.737,11.276H246.634a1.265,1.265,0,0,1-1.235-1.264h0v-10.06a1.262,1.262,0,0,1,1.229-1.262h13.7a1.259,1.259,0,0,1,1.232,1.258v.042h0v.011h0v10.009A1.269,1.269,0,0,1,260.326,1268.941Z" transform="translate(-243.899 -1254.854)" fill="#939393"/>
    <path id="Path_7105" data-name="Path 7105" d="M251.135,1260.722q.516,0,1.587.11l.055-1.439-.367-.082a9.734,9.734,0,0,0-1.8-.217,2.653,2.653,0,0,0-2.187.832,4.3,4.3,0,0,0-.679,2.707,4.456,4.456,0,0,0,.658,2.72,2.571,2.571,0,0,0,2.164.848,12.565,12.565,0,0,0,2.211-.284l-.055-1.452-1.573.109a1.5,1.5,0,0,1-1.255-.414,2.718,2.718,0,0,1-.3-1.525,2.64,2.64,0,0,1,.3-1.512A1.509,1.509,0,0,1,251.135,1260.722Z" transform="translate(-243.899 -1254.854)" fill="#939393"/>
    <path id="Path_7106" data-name="Path 7106" d="M257.132,1260.722q.515,0,1.587.11l.054-1.439-.366-.082a9.818,9.818,0,0,0-1.8-.217,2.657,2.657,0,0,0-2.188.832,4.3,4.3,0,0,0-.679,2.707,4.457,4.457,0,0,0,.659,2.72,2.571,2.571,0,0,0,2.163.848,12.554,12.554,0,0,0,2.211-.284l-.054-1.452-1.575.109a1.5,1.5,0,0,1-1.254-.414,2.728,2.728,0,0,1-.307-1.525,2.657,2.657,0,0,1,.307-1.512A1.511,1.511,0,0,1,257.132,1260.722Z" transform="translate(-243.899 -1254.854)" fill="#939393"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconCaption'
  }
</script>
