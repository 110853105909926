<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 17.392 18.976">
      <path
        d="M14.644 0H6.963a2.75 2.75 0 0 0-2.75 2.75v1.92H2.984A2.983 2.983 0 0 0 .002 7.654v8.338a2.984 2.984 0 0 0 2.984 2.984h6.4a2.985 2.985 0 0 0 2.984-2.984v-.868h2.276a2.75 2.75 0 0 0 2.749-2.75V2.75A2.748 2.748 0 0 0 14.644 0zm-3.776 15.992a1.487 1.487 0 0 1-1.484 1.484h-6.4a1.488 1.488 0 0 1-1.485-1.484V7.654a1.489 1.489 0 0 1 1.485-1.485h1.229v6.2a2.75 2.75 0 0 0 2.75 2.75h3.9zm5.026-3.618a1.254 1.254 0 0 1-1.25 1.25H6.963a1.251 1.251 0 0 1-1.25-1.25V2.75a1.253 1.253 0 0 1 1.25-1.251h7.681a1.255 1.255 0 0 1 1.25 1.251z"/>
      <path
        d="M13.43 3.572H8.176a.75.75 0 0 0 0 1.5h5.254a.75.75 0 0 0 0-1.5zM13.43 6.517H8.176a.75.75 0 0 0 0 1.5h5.254a.75.75 0 0 0 0-1.5zM13.43 9.462H8.176a.75.75 0 0 0 0 1.5h5.254a.75.75 0 0 0 0-1.5z"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconCopy',
  };
</script>
