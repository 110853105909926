var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-open-outside",
      attrs: {
        id: "Open_Outside_tap_Icon",
        "data-name": "Open Outside tap Icon",
        xmlns: "http://www.w3.org/2000/svg",
        width: "13.312",
        height: "13.369",
        viewBox: "0 0 13.312 13.369"
      }
    },
    [
      _c("g", { attrs: { id: "Group_1827", "data-name": "Group 1827" } }, [
        _c("path", {
          attrs: {
            id: "Path_706",
            "data-name": "Path 706",
            d:
              "M1597,95.464a.749.749,0,0,0-.75-.75h-3.362a.75.75,0,0,0,0,1.5h1.55l-4.1,4.1a.75.75,0,0,0,1.061,1.061l4.1-4.1v1.553a.75.75,0,0,0,1.5,0Z",
            transform: "translate(-1583.686 -94.714)",
            fill: "#939393"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            id: "Path_707",
            "data-name": "Path 707",
            d:
              "M1593.588,102.312a.749.749,0,0,0-.749.749h0v1.658a2,2,0,0,1-2,2h-3.786a2,2,0,0,1-2-2v-3.787a2,2,0,0,1,2-2h1.658a.75.75,0,1,0,0-1.5h-1.658a3.5,3.5,0,0,0-3.5,3.5v3.787a3.5,3.5,0,0,0,3.5,3.5h3.786a3.5,3.5,0,0,0,3.5-3.5v-1.658A.749.749,0,0,0,1593.588,102.312Z",
            transform: "translate(-1583.55 -94.851)",
            fill: "#939393"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }