<template>
  <div class="hub-badge" :class="[{'hub-badge-disabled':disabled},`hub-badge-${size}`]" ref="badge">
    <hub-badge-shape :class="{'invisible':!shape}" :element="shape" :outline-color="outline" :base-color="base"/>
    <div class="hub-badge-icon" :style="iconStyles">
      <transition name="fade" mode="out-in">
        <svg key="badge-icon" v-if="iconType === 'icon'" :class="{'badge-emoji':innerIcon == 'emoji'}" :style="{backgroundPosition: innerPosition}" width="25" viewBox="0 0 25 25">
          <use :xlink:href="`#badge-${innerIcon}`"></use>
        </svg>
        <badge-text key="badge-text" v-else :text="inner"/>
      </transition> 
      <slot></slot>
    </div>
  </div>
</template>

<script>
import HubBadgeShape from "@utils/Badge/Shape.vue";
import BadgeText from "@utils/Badge/Text.vue";

export default {
  name: 'Badge',
  components: {
    HubBadgeShape,
    BadgeText
  },
  props: {
    outline: { type: String, default: '#5581F4'}, // hex color
    base: { type: String, default: '#273E79'}, // hex color
    size: { type: String}, // sm, md, lg
    shape: { type: String, default: 'shape-1'}, // shape-1 ... shape-12
    iconSize: { type: Number || String, default: 50}, // icon size percentage
    iconColor: { type: String, default: '#fff'}, // icon color,
    iconType: { type: String, default: 'icon'}, // icon type (icon / text)
    inner: {}, // illustration inner / text
    disabled: { type: Boolean, default: false}
  },
  computed: {
    iconStyles() {
      return {
        'width': this.iconSize + '%',
        'height': this.iconSize + '%',
        'color': this.iconColor
      }
    },
    innerIcon() {
      if(!this.inner || !this.inner.icon) {
        return;
      }
      return this.inner.icon;
    },
    innerPosition() {
      if(!this.inner || !this.inner.position) {
        return;
      }
      return this.inner.position;
    },

  }
}
</script>
