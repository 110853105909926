<template>
  <svg id="PDF_Document_icon" data-name="PDF Document icon" xmlns="http://www.w3.org/2000/svg" width="15.942" height="19.131" viewBox="0 0 15.942 19.131">
    <path id="Path_673" data-name="Path 673" d="M1227.388,96.372h6.977a.75.75,0,0,0,0-1.5h-6.977a.75.75,0,0,0,0,1.5Z" transform="translate(-1223.286 -90.962)" fill="#293ad3"/>
    <path id="Path_674" data-name="Path 674" d="M1231.66,98.117h-4.3a.75.75,0,0,0,0,1.5h4.3a.75.75,0,0,0,0-1.5Z" transform="translate(-1223.286 -90.962)" fill="#293ad3"/>
    <path id="Path_676" data-name="Path 676" d="M1235.728,90.962h-8.942a3.5,3.5,0,0,0-3.5,3.5v12.131a3.5,3.5,0,0,0,3.5,3.5h8.942a3.5,3.5,0,0,0,3.5-3.5V94.462A3.5,3.5,0,0,0,1235.728,90.962Zm2,15.631a2,2,0,0,1-2,2h-8.942a2,2,0,0,1-2-2V94.462a2.006,2.006,0,0,1,2-2h8.942a2,2,0,0,1,2,2Z" transform="translate(-1223.286 -90.962)" fill="#293ad3"/>
    <g id="Group_2886" data-name="Group 2886" transform="translate(3.676 12.024)">
      <g id="Group_2884" data-name="Group 2884">
        <path id="Path_835" data-name="Path 835" d="M1228.3,105.558h-.6v1.056h-.737v-3.628h1.334a1.127,1.127,0,0,1,1.291,1.259,1.344,1.344,0,0,1-.328.974A1.281,1.281,0,0,1,1228.3,105.558Zm-.6-.63h.591q.55,0,.55-.683a.71.71,0,0,0-.133-.483.542.542,0,0,0-.417-.146h-.591Z" transform="translate(-1226.962 -102.986)" fill="#293ad3"/>
        <path id="Path_836" data-name="Path 836" d="M1231.326,106.614H1230.1v-3.628h1.221a2.546,2.546,0,0,1,.774.1.929.929,0,0,1,.473.325,1.37,1.37,0,0,1,.232.534,4.1,4.1,0,0,1,.063.8,4.692,4.692,0,0,1-.058.813,1.567,1.567,0,0,1-.222.576.914.914,0,0,1-.472.368A2.29,2.29,0,0,1,1231.326,106.614Zm.774-1.44c.007-.114.011-.272.011-.472a4.1,4.1,0,0,0-.022-.483.9.9,0,0,0-.1-.331.415.415,0,0,0-.238-.205,1.274,1.274,0,0,0-.424-.057h-.485v2.348h.485a.742.742,0,0,0,.582-.2A1,1,0,0,0,1232.1,105.174Z" transform="translate(-1226.962 -102.986)" fill="#293ad3"/>
        <path id="Path_837" data-name="Path 837" d="M1233.509,106.614v-3.628h2.316v.64h-1.58v1.067h1.292v.641h-1.292v1.28Z" transform="translate(-1226.962 -102.986)" fill="#293ad3"/>
      </g>
      <g id="Group_2885" data-name="Group 2885">
        <path id="Path_838" data-name="Path 838" d="M1228.3,105.558h-.6v1.056h-.737v-3.628h1.334a1.127,1.127,0,0,1,1.291,1.259,1.344,1.344,0,0,1-.328.974A1.281,1.281,0,0,1,1228.3,105.558Zm-.6-.63h.591q.55,0,.55-.683a.71.71,0,0,0-.133-.483.542.542,0,0,0-.417-.146h-.591Z" transform="translate(-1226.962 -102.986)" fill="#293ad3"/>
        <path id="Path_839" data-name="Path 839" d="M1231.326,106.614H1230.1v-3.628h1.221a2.546,2.546,0,0,1,.774.1.929.929,0,0,1,.473.325,1.37,1.37,0,0,1,.232.534,4.1,4.1,0,0,1,.063.8,4.692,4.692,0,0,1-.058.813,1.567,1.567,0,0,1-.222.576.914.914,0,0,1-.472.368A2.29,2.29,0,0,1,1231.326,106.614Zm.774-1.44c.007-.114.011-.272.011-.472a4.1,4.1,0,0,0-.022-.483.9.9,0,0,0-.1-.331.415.415,0,0,0-.238-.205,1.274,1.274,0,0,0-.424-.057h-.485v2.348h.485a.742.742,0,0,0,.582-.2A1,1,0,0,0,1232.1,105.174Z" transform="translate(-1226.962 -102.986)" fill="#293ad3"/>
        <path id="Path_840" data-name="Path 840" d="M1233.509,106.614v-3.628h2.316v.64h-1.58v1.067h1.292v.641h-1.292v1.28Z" transform="translate(-1226.962 -102.986)" fill="#293ad3"/>
      </g>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-doc-pdf',
  };
</script>
