const namespaced = true;

const state = {

};

const mutations = {

};

const actions = {
};

const modules = {

};

const getters = {

};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
