var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20.02",
        height: "15.6"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M5.38 6.46a3.23 3.23 0 100-6.46 3.23 3.23 0 000 6.46zm0-4.96a1.73 1.73 0 11-1.73 1.73A1.73 1.73 0 015.38 1.5zM19.8 2.29a.75.75 0 00-.21-.14l-.03-.02a.75.75 0 00-.28-.06h-3.11a.75.75 0 000 1.5h1.3l-3.13 3.15-1.8-1.8a.75.75 0 00-1.06 0L7.81 8.6A5.32 5.32 0 005.39 8a5.08 5.08 0 00-.53.02h-.02A5.52 5.52 0 00.01 13.5v.1a2.02 2.02 0 002.02 2h6.72a2.03 2.03 0 002.02-2.02v-.2l-.02-.52v-.01a5.36 5.36 0 00-1.68-3.4L12 6.52l1.81 1.8a.75.75 0 001.06 0l3.64-3.66V5.9a.75.75 0 001.5 0V2.83a.75.75 0 00-.22-.54zM9.27 13.58a.53.53 0 01-.52.52H2.02a.52.52 0 01-.52-.52v-.08a4.02 4.02 0 013.52-3.98 3.63 3.63 0 01.37-.02A3.89 3.89 0 019.25 13l.02.38zM18.46 9.35a.75.75 0 00-.75.75v4.65a.75.75 0 001.5 0V10.1a.75.75 0 00-.75-.75z",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M14.46 10.77a.75.75 0 00-.75.75v3.23a.75.75 0 001.5 0v-3.23a.75.75 0 00-.75-.75z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }