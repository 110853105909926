var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-text-italic",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "8.994",
        height: "12.002",
        viewBox: "0 0 8.994 12.002"
      }
    },
    [
      _c("path", {
        attrs: {
          d: "M2.63,0V1.5H4.851L2.6,10.5H0V12H6.364V10.5H4.143L6.4,1.5h2.6V0Z",
          transform: "translate(0 0)",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }