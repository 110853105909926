var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-btn",
        {
          ref: "upgrade-btn",
          staticClass: "all-events",
          attrs: { variant: "primary", size: "sm" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.redirect($event)
            }
          }
        },
        [
          _c(
            "svg",
            { staticClass: "icon-sm", attrs: { viewBox: "0 0 24 24" } },
            [_c("use", { attrs: { "xlink:href": "#icon-lock-locked" } })]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-tooltip",
        {
          attrs: {
            target: function() {
              return _vm.$refs["upgrade-btn"]
            },
            placement: "top",
            boundary: _vm.customBoundary ? _vm.customBoundary : "window",
            triggers: "hover",
            "custom-class": "tooltip-no-wrap",
            container: _vm.container
          }
        },
        [
          _c(
            "button",
            {
              staticClass: "tooltip-cta txt-white",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.redirect($event)
                }
              }
            },
            [
              _c("span", { staticClass: "tooltip-cta-inner" }, [
                _vm._v(_vm._s(_vm.tooltip))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "btn-icon ml-2 p-0" }, [
                _c(
                  "svg",
                  {
                    staticClass: "icon-regular",
                    attrs: { viewBox: "0 0 24 24" }
                  },
                  [_c("use", { attrs: { "xlink:href": "#icon-arrow-right" } })]
                )
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }