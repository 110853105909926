<template>
	<svg class="icon-logo" xmlns="http://www.w3.org/2000/svg" width="19.346" height="19.345" viewBox="0 0 19.346 19.345">
	  <path d="M14.283 0H5.064A5.063 5.063 0 00.001 5.063v9.219a5.062 5.062 0 005.063 5.063h9.219a5.063 5.063 0 005.063-5.063V5.063A5.064 5.064 0 0014.283 0zm3.563 14.282a3.568 3.568 0 01-3.563 3.563H5.064a3.567 3.567 0 01-3.563-3.563V5.063A3.567 3.567 0 015.064 1.5h9.219a3.568 3.568 0 013.563 3.563z" fill="#939393"/>
	  <path d="M13.561 4.029l-.559-.2-.063-.021a9.715 9.715 0 00-1.218-.285 8.8 8.8 0 00-1.556-.145h-.015a4.555 4.555 0 00-3.3 1.291 4.426 4.426 0 00-1.3 3.228 3.726 3.726 0 00.737 2.36l.052.065-.131-.006a1.5 1.5 0 00-1.46 1.161l-.39 1.679a1.5 1.5 0 00.91 1.735l.551.217h.005l.071.026a8.957 8.957 0 001.239.311 8.233 8.233 0 001.557.156h.015a5.088 5.088 0 003.464-1.188 4.439 4.439 0 001.461-3.477 3.771 3.771 0 00-.674-2.327l-.008-.011-.014-.019a1.5 1.5 0 001.152-1.1l.425-1.67a1.5 1.5 0 00-.951-1.78zm-.928 3.083l-.233-.038a14.056 14.056 0 00-2.185-.22.934.934 0 00-.682.231.713.713 0 00-.236.539.578.578 0 00.12.427 4.771 4.771 0 001.014.537 3.3 3.3 0 011.32.916 2.364 2.364 0 01.379 1.435 2.971 2.971 0 01-.947 2.346 3.641 3.641 0 01-2.478.819h-.011a6.665 6.665 0 01-1.274-.128 7.855 7.855 0 01-1.038-.257h-.011l-.553-.218.39-1.68.241.044a13.307 13.307 0 002.151.244h.008a1.723 1.723 0 001.007-.228.858.858 0 00.267-.74.442.442 0 00-.129-.358 5.281 5.281 0 00-.993-.533 3.42 3.42 0 01-1.3-.917 2.277 2.277 0 01-.414-1.434 2.941 2.941 0 01.855-2.161 3.063 3.063 0 012.246-.855h.015a7.32 7.32 0 011.289.121 8.742 8.742 0 011.029.237h.008l.567.2z" fill="#939393"/>
	</svg>
</template>
<script>
  export default {
    name: 'IconLogo',
  };
</script>
