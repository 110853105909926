var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-seo",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20.01",
        height: "19.867",
        viewBox: "0 0 20.01 19.867"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M754.122,1234.453a.407.407,0,0,1-.405-.405,2.444,2.444,0,0,0-.753-1.775,2.477,2.477,0,0,0-1.712-.69,2.429,2.429,0,0,0-1.229.333l.005,0-.206.056a.406.406,0,0,1-.351-.2l-1.2-2.073-.055-.2a.4.4,0,0,1,.2-.35h0a2.463,2.463,0,0,0,0-4.265h0a.405.405,0,0,1-.205-.353l.055-.2,1.2-2.074a.4.4,0,0,1,.35-.2l.2.055h0a2.445,2.445,0,0,0,1.228.332,2.478,2.478,0,0,0,1.712-.691,2.437,2.437,0,0,0,.753-1.773.406.406,0,0,1,.405-.405h2.393a.405.405,0,0,1,.4.405,2.437,2.437,0,0,0,.753,1.772,2.48,2.48,0,0,0,1.712.692,2.438,2.438,0,0,0,1.229-.332l.005,0,.2-.053a.4.4,0,0,1,.351.2l1.2,2.073.054.2a.414.414,0,0,1-.117.287.75.75,0,1,0,1.066,1.056h0a1.906,1.906,0,0,0,.3-2.293l-1.2-2.072a1.9,1.9,0,0,0-1.65-.953,1.883,1.883,0,0,0-.955.259l0,0a.955.955,0,0,1-.479.131.986.986,0,0,1-.674-.274.933.933,0,0,1-.291-.69,1.9,1.9,0,0,0-1.9-1.905h-2.393a1.9,1.9,0,0,0-1.9,1.905.937.937,0,0,1-.292.69.987.987,0,0,1-.674.275.954.954,0,0,1-.479-.132h0a1.9,1.9,0,0,0-2.6.7l-1.2,2.072a1.9,1.9,0,0,0,.7,2.6h0a.963.963,0,0,1,0,1.668l-.005.005a1.9,1.9,0,0,0-.691,2.595l1.2,2.073a1.907,1.907,0,0,0,2.6.7l.005,0a.95.95,0,0,1,.478-.132.991.991,0,0,1,.674.274.94.94,0,0,1,.292.692,1.9,1.9,0,0,0,1.9,1.9.75.75,0,0,0,0-1.5Z",
          transform: "translate(-746.72 -1218.067)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M754.706,1228.027h0a1.187,1.187,0,1,1,1.507-1.8.75.75,0,0,0,1.131-.985,2.687,2.687,0,1,0-3.413,4.066.75.75,0,0,0,.775-1.284Z",
          transform: "translate(-746.72 -1218.067)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d: "M758.323,1226.957h0Z",
          transform: "translate(-746.72 -1218.067)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M766.493,1236.638l-2.015-1.894a5.266,5.266,0,1,0-1.052,1.069l2.04,1.918a.75.75,0,1,0,1.027-1.093Zm-6.186-1.249a3.664,3.664,0,0,1-1.406-7.048h0a3.617,3.617,0,0,1,1.4-.28,3.664,3.664,0,0,1,0,7.329Z",
          transform: "translate(-746.72 -1218.067)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }