<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22.121" height="22.077" viewBox="0 0 22.121 22.077">
    <g id="Add_Media_to_Playlist_outline_Icon" data-name="Add Media to Playlist outline Icon" transform="translate(1.17 5.845)">
      <g id="Group_20074" data-name="Group 20074" transform="translate(-230.922 -133.157)">
        <path id="Rectangle_2746" data-name="Rectangle 2746" d="M233.126,147.4H248.5a2.376,2.376,0,0,0,2.374-2.374h0v-8.394a2.374,2.374,0,0,0-2.374-2.374H233.126a2.372,2.372,0,0,0-2.374,2.371v0h0v8.394A2.374,2.374,0,0,0,233.126,147.4Z" transform="translate(0 0.987)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <line id="Line_490" data-name="Line 490" x2="13.991" transform="translate(233.817 131.787)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <line id="Line_491" data-name="Line 491" x2="9.815" transform="translate(235.905 128.312)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <g id="Group_20074-2" data-name="Group 20074" transform="translate(238.176 139.182)">
          <line id="Line_490-2" data-name="Line 490" x2="5.273" transform="translate(0 2.636)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          <line id="Line_491-2" data-name="Line 491" y1="5.273" transform="translate(2.636)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconPlaylistAddCta'
  }
</script>
