<template>
  <svg class="icon-arrow-right-small" xmlns="http://www.w3.org/2000/svg" width="4.515" height="7.531" viewBox="0 0 4.515 7.531">
    <path d="M.22 1.281l2.484 2.485L.22 6.251a.75.75 0 001.061 1.061l3.014-3.016a.749.749 0 000-1.06L1.281.22A.75.75 0 00.22 1.281z" fill="#fff"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-arrow-right-small',
  }
</script>
