<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="icon-filter" width="19.809" height="12.078" viewBox="0 0 19.809 12.078">
    <g transform="translate(-241.542 -996.907)"><path d="M256.852,1002.194H246.041a.75.75,0,0,0,0,1.5h10.811a.75.75,0,0,0,0-1.5Z" fill="currentColor"/><path d="M253.055,1007.485h-3.216a.75.75,0,1,0,0,1.5h3.216a.75.75,0,1,0,0-1.5Z" fill="currentColor"/><path d="M260.6,996.907H242.292a.75.75,0,1,0,0,1.5H260.6a.75.75,0,0,0,0-1.5Z" fill="currentColor"/></g>
  </svg>
</template>
<script>
  export default {
    name: 'IconFilter',
  };
</script>
