var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-devices",
      attrs: {
        "data-name": "Devices Icon",
        xmlns: "http://www.w3.org/2000/svg",
        width: "19.5",
        height: "22.501",
        viewBox: "0 0 19.5 22.501"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Path_3523",
          "data-name": "Path 3523",
          d:
            "M580.68,1081.365h-9v.009h0v-.01a3,3,0,0,0-3,3v4.5h-1.5a3,3,0,0,0-3,3v9a3,3,0,0,0,3,3h4a3,3,0,0,0,3-3v-1.5h6.5a3,3,0,0,0,3-3v-12A3,3,0,0,0,580.68,1081.365Zm-8.265.883a1.114,1.114,0,0,1-.041.135.793.793,0,0,0,.027-.137Zm-.059.173a.63.63,0,0,1-.027.062.731.731,0,0,1-.118.155A.731.731,0,0,0,572.356,1082.421Zm-.186.248a.7.7,0,0,1-.143.108c-.012.006-.026.007-.038.013A.725.725,0,0,0,572.17,1082.669Zm-.261.149a.708.708,0,0,1-.192.038A.764.764,0,0,0,571.909,1082.818Zm-.246.047h9.017a1.5,1.5,0,0,1,1.475,1.256H570.2A1.5,1.5,0,0,1,571.663,1082.865Zm-4.483,7.5h4a1.5,1.5,0,0,1,1.474,1.25h-6.949A1.5,1.5,0,0,1,567.18,1090.365Zm5.5,10.5a1.5,1.5,0,0,1-1.5,1.5h-4a1.5,1.5,0,0,1-1.5-1.5v-7.75h7Zm8-3h-6.5v-6a3,3,0,0,0-3-3h-1v-3.244h12v10.744A1.5,1.5,0,0,1,580.679,1097.865Z",
          transform: "translate(-564.18 -1081.364)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          id: "Ellipse_334",
          "data-name": "Ellipse 334",
          cx: "1",
          cy: "1",
          r: "1",
          transform: "translate(10.992 13.104)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          id: "Ellipse_335",
          "data-name": "Ellipse 335",
          cx: "1",
          cy: "1",
          r: "1",
          transform: "translate(3.992 18.105)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }