import builder from '@app2/modules/Library/Builder/playlist.store'
import transcriptEditor from '@app2/modules/Library/Media/TranscriptEditor/editor.store'
import filters from '@app2/modules/Library/Listing/Components/Filters/filters.store'
import Folder from "@app2/models/Folder";
import File from "@app2/models/File";
import FilePlaylist from "@app2/models/FilePlaylist";
import Playlist from "@app2/models/Playlist";
import { set } from '@app2/utils/helpers'
import eventBus from "@app2/core/eventBus";

const namespaced = true;

const state = {
  selectedMedia: [],
  processing: false,
  aiLoader: {
    title: false,
    description: false,
    'long-summary': false,
    'key-takeaway': false,
    tag: false,
    chapters: false,
  },
  importAllProcessing: false,
  importAllFiles: [],
};

const mutations = {
  SET_SELECTED_MEDIA(state, payload) {
    state.selectedMedia = payload;
  },
  SET_PROCESSING(state, payload) {
    state.processing = payload;
  },
  SET_AI_LOADER(state, payload) {
    state.aiLoader[payload.key] = payload.value
  },
  SET_IMPORT_ALL_PROCESSING(state, payload) {
    state.importAllProcessing = payload;
  },
  SET_IMPORT_ALL_FILES(state, payload) {
    state.importAllFiles.push(payload);
  },
};

const actions = {
  async moveMediaToFolder({ commit, state, dispatch }, folder) {
    commit('SET_PROCESSING', true);
    await Folder.api().attachFiles(folder, state.selectedMedia);
    state.selectedMedia.forEach(file => {
      file = File.query().where('id', file).first();
      File.update({
        data: {
          id: file.id,
          hash: file.hash,
          folder: folder,
          folder_id: folder.id,
        }
      })
    })
    commit('SET_SELECTED_MEDIA', []);
    commit('SET_PROCESSING', false);
  },
  async moveMediaToPlaylist({ commit, state, dispatch }, playlist) {
    commit('SET_PROCESSING', true);
    await FilePlaylist.api().attachFiles(playlist, state.selectedMedia);

    eventBus.$emit('refresh-playlists')

    // commit('SET_SELECTED_MEDIA', []);
    // dispatch('refreshSelectedMedia')
    commit('SET_PROCESSING', false);
  },
  // refreshSelectedMedia({commit, getters}) {
  //   const fresh = File.query().whereIdIn(getters.selectedMedia.map(m => m)).withAll().get();
  //   commit('SET_SELECTED_MEDIA', fresh);
  // },
};

const modules = {
  builder,
  transcriptEditor,
  filters,
};

const getters = {
  GET_SELECTED_MEDIA: state => state.selectedMedia,
  GET_PROCESSING_STATUS: state => state.processing,
  aiLoader: state => state.aiLoader
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
  modules,
};
