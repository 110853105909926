var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "148",
        height: "149",
        viewBox: "0 0 148 149",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M96.9021 143.094C99.8883 143.396 90.1676 143.731 87.9452 143.101C90.1765 141.59 87.4027 138.53 81.2863 138.531C81.3631 128.817 81.8585 120.459 82.0812 110.767",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M61.8369 110.753C61.0713 120.951 62.012 128.114 62.4812 137.817C53.1442 138.194 52.8996 144.525 61.8922 144.162C59.1438 144.51 53.12 144.998 51.0123 144.162",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M102.403 118.078C103.385 123.055 103.255 128.187 102.023 133.107",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M33.4136 141.214C32.1199 138.696 31.5405 135.873 31.7382 133.05",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M25.7256 141.086C26.8317 142.198 28.0806 143.158 29.4395 143.942",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M113.3 140.801C114.322 139.72 117.877 138.097 119.355 138.855C121.573 140.02 119.752 142.397 121.415 144.194",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M37.7273 143.5C39.4678 139.292 48.1116 140.423 50.3626 141.173C40.7129 132.133 49.8706 106.522 53.9772 86.3417C56.7195 72.8654 57.8884 59.6524 61.5672 46.3048C62.4854 43.0386 64.0336 41.09 65.2193 41.147C68.8113 41.2983 69.5798 53.8327 69.7051 53.8274L72.944 53.689C73.514 48.9856 75.0216 41.0509 77.5547 41.1758C80.8627 41.393 82.0006 47.888 82.7672 50.7237C83.4494 53.2947 85.3348 70.0206 89.9637 89.5548C93.8883 106.116 99.3115 128.937 92.7747 138.6C95.9125 138.286 106.859 138.203 108.2 141.173",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M71.9865 67.7919C70.7763 67.8485 69.615 68.2873 68.6697 69.0451C68.294 69.3613 68.0088 69.7716 67.8434 70.234C67.6781 70.6964 67.6383 71.1943 67.7282 71.6771C67.818 72.1599 68.0344 72.6102 68.3551 72.9821C68.6758 73.354 69.0895 73.6341 69.5538 73.794C72.3552 75.1623 77.1595 73.0568 77.0391 70.6596C76.9831 70.1645 76.8012 69.692 76.5109 69.287C76.2206 68.8821 75.8314 68.5582 75.3805 68.3461C74.3179 67.8651 73.147 67.6739 71.9865 67.7919Z",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M85.3304 90.2952C71.8726 97.1188 60.6314 92.4968 53.5083 88.8072",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M72.9733 93.9475C72.522 93.8052 72.0454 93.7619 71.5757 93.8203C71.1061 93.8787 70.6546 94.0376 70.2518 94.2861C69.8491 94.5347 69.5046 94.867 69.2418 95.2606C68.979 95.6542 68.804 96.0997 68.7288 96.567C68.6806 97.0998 68.7688 97.636 68.985 98.1253C69.2013 98.6147 69.5384 99.0409 69.9648 99.3639C70.3913 99.687 70.8929 99.8962 71.4225 99.9719C71.9521 100.048 72.4921 99.9873 72.992 99.7966C73.4918 99.6059 73.9349 99.2911 74.2795 98.882C74.6242 98.4728 74.859 97.9827 74.9619 97.4577C75.0648 96.9327 75.0325 96.3902 74.8679 95.8812C74.7032 95.3722 74.4117 94.9134 74.0209 94.5482C73.7464 94.2393 73.3786 94.0284 72.9733 93.9475V93.9475Z",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M77.0498 76.7383C75.8786 77.9134 74.3083 78.605 72.6507 78.6758C70.9931 78.7466 69.3695 78.1914 68.1023 77.1205",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M77.2013 76.9276C76.6092 79.0149 76.1243 82.3526 73.8891 83.3267C73.3022 83.5904 72.653 83.6841 72.0154 83.5973C71.3779 83.5104 70.7775 83.2464 70.2826 82.8352C68.7904 81.2475 67.9083 79.1827 67.7926 77.0068",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M68.5594 65.3267C68.5594 65.3267 68.221 62.549 68.2954 60.5543",
          stroke: "currentColor",
          "stroke-width": "3.4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M74.7038 65.065C74.7038 65.065 74.804 62.2686 74.5598 60.2875",
          stroke: "currentColor",
          "stroke-width": "3.4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M71.9592 111.008C71.634 119.331 71.1789 140.581 71.9592 143.39",
          stroke: "currentColor",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }