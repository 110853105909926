import playlistApp from '@app2/modules/Library/Builder/playlist.app'

const namespaced = true;

const state = {
  showModal: true,
  playlist: {
    title: '',
    description: '',
    sort: 'position',
    created_at: null
  },
  playlistMultiple: false,
  selectedFolders: [],
  selectModel: null,
  folderQuery: {
    list: [],
    page: 1,
    total: 0,
    lastPage: 1
  },
  playlistFormBackRoute: null,
  isPlaylistModalOpen: false,
  routeParams: {},
  backRouteMediaHash: null,
};

const mutations = {
  TOGGLE_MODAL(state, payload) {
    state.showModal = payload
  },
  SET_PLAYLIST(state, payload) {
    state.playlist = payload
  },
  SET_SELECT_MODEL(state, payload) {
    state.selectModel = payload
  },
  SET_PLAYLIST_MULTIPLE(state, payload) {
    state.playlistMultiple = payload
  },
  SET_SELECTED_FOLDERS(state, payload) {
    state.selectedFolders = payload
  },
  SET_PLAYLIST_FORM_BACK_ROUTE(state, payload) {
    state.playlistFormBackRoute = payload
  },
  SET_ROUTE_PARAMS(state, payload) {
    state.routeParams = payload
  },
  SET_BACK_ROUTE_MEDIA_HASH(state, payload) {
    state.backRouteMediaHash = payload
  },
  SET_IS_PLAYLIST_MODAL_OPEN(state, payload) {
    state.isPlaylistModalOpen = payload
  }
};

const actions = {
  resetPlaylist({ commit }) {
    commit('SET_PLAYLIST', {
      title: '',
      description: '',
      sort: 'position',
      created_at: null
    })
  },
  closeDialog({ commit }) {
    commit('SET_PLAYLIST', null)
    commit('SET_SELECT_MODEL', null)
    playlistApp.$bvModal.hide('playlist-form-dialog');
  },
  setPlaylist({ commit }, payload) {
    commit('SET_SELECT_MODEL', null)
    commit('SET_PLAYLIST', payload)
  },
  setSelectModel({ commit }, payload) {
    commit('SET_SELECT_MODEL', payload)
  },
  setSelectedFolders({ commit }, payload) {
    commit('SET_SELECTED_FOLDERS', payload)
  },
  setBackRouteMediaHash({ commit }, payload) {
    commit('SET_BACK_ROUTE_MEDIA_HASH', payload)
  }
};

const getters = {
  GET_BACK_ROUTE_MEDIA_HASH: state => state.backRouteMediaHash,
  GET_IS_PLAYLIST_MODAL_OPEN: state => state.isPlaylistModalOpen,
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
