<template>
  <svg class="icon-activity-details" xmlns="http://www.w3.org/2000/svg" width="17.913" height="13" viewBox="0 0 17.913 12.995">
    <path d="M7.845 3.505h9.318a.75.75 0 000-1.5H7.845a.75.75 0 000 1.5zM17.163 9.263H7.845a.75.75 0 000 1.5h9.318a.75.75 0 000-1.5zM5.509 2.755a2.756 2.756 0 10-3.505 2.64v2.2a2.755 2.755 0 101.5 0V5.389a2.748 2.748 0 002.005-2.634zm-4.012 0a1.256 1.256 0 111.256 1.256A1.258 1.258 0 011.5 2.755zm2.512 7.485a1.256 1.256 0 11-1.258-1.257 1.259 1.259 0 011.258 1.257z" fill="#939393"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconActivityDetails'
  }
</script>
