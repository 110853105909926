var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-breadcrumb",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "4.515",
        height: "7.531",
        viewBox: "0 0 4.515 7.531"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(-605.12 -2674.771)" } }, [
        _c("path", {
          attrs: {
            fill: "#939393",
            d:
              "M605.34,2676.052l2.484,2.485-2.484,2.485a.75.75,0,0,0,1.061,1.061l3.014-3.016a.749.749,0,0,0,0-1.06l-3.014-3.016a.75.75,0,0,0-1.061,1.061Z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }