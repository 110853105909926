<template>
	<svg class="icon-duration-long" xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.499" viewBox="0 0 17.5 17.499">
  	<path d="M4.41 11.522a.751.751 0 001.036.229l3.708-2.369A.753.753 0 009.5 8.75V4.301a.75.75 0 00-1.5 0v4.038l-3.362 2.147a.75.75 0 00-.228 1.036zM2.477 12.386A7.254 7.254 0 015.394 2.321a.75.75 0 10-.7-1.328A8.754 8.754 0 001.176 13.14a.751.751 0 001.3-.753z" fill="currentColor"/>
  	<path d="M12.069.651A8.709 8.709 0 008.75 0a.75.75 0 000 1.5 7.25 7.25 0 11-4.007 13.294.75.75 0 00-.83 1.25A8.752 8.752 0 1012.069.652z" fill="currentColor"/>
	</svg>
</template>
<script>
  export default {
    name: 'IconDurationLong',
  };
</script>
