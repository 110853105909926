<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="icon-view-list" width="13" height="9" viewBox="0 0 13 9">
    <g transform="translate(-11 -13)">
      <rect width="13" height="3" rx="1.5" transform="translate(11 13)" fill="currentColor"/>
      <rect width="13" height="3" rx="1.5" transform="translate(11 19)" fill="currentColor"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-view-list'
  }
</script>
