var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18.799",
        height: "18.8"
      }
    },
    [
      _c("path", {
        attrs: {
          "data-name": "Maximize Icon",
          d:
            "M12.214 17.3a1 1 0 011-1 3.093 3.093 0 003.086-3.086 1 1 0 112 0 5.086 5.086 0 01-5.087 5.086 1 1 0 01-.999-1zM.5 13.214a1 1 0 012 0A3.092 3.092 0 005.586 16.3a1 1 0 110 2A5.087 5.087 0 01.5 13.214zm15.8-7.628A3.093 3.093 0 0013.213 2.5a1 1 0 010-2A5.087 5.087 0 0118.3 5.586a1 1 0 11-2 0zm-15.8 0A5.087 5.087 0 015.586.5a1 1 0 010 2A3.091 3.091 0 002.5 5.586a1 1 0 01-2 0z",
          fill: "#fff",
          stroke: "rgba(0,0,0,0)"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }