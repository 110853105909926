import Router from '@plugins/VueRouter/index';

const router = new Router({
  mode: 'abstract',
  name: 'audience-bulk',
  routes: [
    {
      path: '/',
      name: 'BulkIndex',
      component: require('@app2/modules/Audience/Bulk/Pages/Index').default
    },
    {
      path: '/hubs/attach',
      name: 'HubsAttach',
      component: require('@app2/modules/Audience/Bulk/Pages/Access').default
    },
    {
      path: '/hubs/detach',
      name: 'HubsDetach',
      component: require('@app2/modules/Audience/Bulk/Pages/Access').default
    },
    {
      path: '/tags/attach',
      name: 'TagsAttach',
      component: require('@app2/modules/Audience/Bulk/Pages/Tags').default
    },
    {
      path: '/tags/detach',
      name: 'TagsDetach',
      component: require('@app2/modules/Audience/Bulk/Pages/Tags').default
    },
    {
      path: '/achievements/attach',
      name: 'AchievementsAttach',
      component: require('@app2/modules/Audience/Bulk/Pages/Achievements').default
    },
    {
      path: '/achievements/detach',
      name: 'AchievementsDetach',
      component: require('@app2/modules/Audience/Bulk/Pages/Achievements').default
    },
  ]
});

export default router;
