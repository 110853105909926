var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 43.96 43.96" },
      attrs: {
        version: "1.1",
        id: "Capa_1",
        height: "36px",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 43.96 43.96",
        "xml:space": "preserve"
      }
    },
    [
      _c("g", [
        _c("polygon", {
          attrs: { points: "5.023,39.023 5.027,39.029 5.027,39.027 \t\t" }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M5.205,36.547c-0.232,0-0.457-0.019-0.685-0.022v0.106h0.09c0.154,0,0.462,0.07,0.462,0.5l-0.008,1.912l-0.01,0.01\n\t\t\tL5.037,39.07l-2.25-2.544c-0.15,0-0.297,0.021-0.451,0.021c-0.184,0-0.374-0.019-0.558-0.021l-0.001,0.107h0.044\n\t\t\tc0.238,0,0.481,0.181,0.481,0.478v2.132c0,0.402-0.112,0.663-0.476,0.665l-0.048-0.002v0.108c0.218,0,0.444-0.021,0.666-0.021\n\t\t\tc0.211,0,0.421,0.019,0.63,0.021v-0.106H3.011c-0.399-0.003-0.498-0.176-0.498-0.616V37.15l2.626,2.938h0.167\n\t\t\tc-0.022-0.154-0.026-0.32-0.026-0.488v-2.303c0-0.605,0.254-0.664,0.473-0.664h0.052v-0.107\n\t\t\tC5.606,36.527,5.406,36.547,5.205,36.547z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M5.827,36.486C5.618,36.482,5.411,36.5,5.205,36.5c-0.236,0-0.47-0.02-0.708-0.02H4.475v0.174v0.021h0.134\n\t\t\tc0.146,0.004,0.416,0.053,0.418,0.455v1.86l-2.223-2.5c-0.171-0.008-0.317,0.01-0.468,0.01c-0.189,0-0.387-0.019-0.581-0.019\n\t\t\tH1.733v0.173v0.021h0.088c0.217,0.002,0.438,0.164,0.438,0.435v2.131c-0.003,0.401-0.099,0.617-0.433,0.619H1.755H1.733v0.174\n\t\t\tv0.021h0.022c0.229,0,0.462-0.02,0.688-0.02c0.216,0,0.434,0.02,0.652,0.02h0.023v-0.172v-0.021H3.009\n\t\t\tc-0.381-0.005-0.447-0.13-0.454-0.569v-2.026l2.567,2.86l0.209,0.009h0.025l-0.004-0.027c-0.023-0.152-0.029-0.328-0.029-0.506\n\t\t\tv-2.301c0.005-0.599,0.219-0.615,0.429-0.621h0.075h0.021v-0.174v-0.021H5.827z M5.83,36.657H5.828H5.753\n\t\t\tc-0.213,0-0.451,0.041-0.451,0.645v2.301c0,0.178,0.004,0.354,0.03,0.511H5.139L2.534,37.21v2.085\n\t\t\tc0,0.441,0.086,0.592,0.476,0.592h0.085v0.152c-0.217,0-0.434-0.02-0.651-0.02c-0.227,0-0.46,0.02-0.688,0.02v-0.152h0.07\n\t\t\tc0.349,0,0.457-0.237,0.457-0.641v-2.132c0-0.283-0.234-0.455-0.461-0.455H1.757v-0.151c0.192,0,0.388,0.021,0.582,0.021\n\t\t\tc0.151,0,0.298-0.021,0.451-0.021l2.251,2.534l0.009-0.01v-1.896c0-0.416-0.288-0.478-0.439-0.478H4.499v-0.151\n\t\t\tc0.238,0,0.47,0.021,0.708,0.021c0.208,0,0.415-0.021,0.623-0.021V36.657z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M12.397,39.219c-0.151,0.569-0.445,0.62-1.043,0.616c-0.319,0-0.638,0-0.638-0.352v-1.2h0.628\n\t\t\tc0.352-0.004,0.389,0.209,0.414,0.498l0.109-0.024c-0.01-0.186-0.015-0.373-0.015-0.559c0-0.189,0.005-0.379,0.015-0.57h-0.109\n\t\t\tc-0.028,0.261-0.11,0.448-0.419,0.445h-0.623v-1.37h0.724c0.526-0.003,0.615,0.267,0.637,0.585l0.109-0.027\n\t\t\tc-0.01-0.142-0.015-0.328-0.015-0.496c0-0.086,0.001-0.168,0.005-0.239c-0.343,0.001-0.856,0.021-1.271,0.021\n\t\t\tc-0.417,0-0.927-0.019-1.238-0.021v0.106h0.049c0.18,0,0.396,0.032,0.396,0.312v2.65c0,0.279-0.216,0.31-0.396,0.312H9.667v0.104\n\t\t\tc0.295,0,0.879-0.02,1.323-0.02c0.447,0,1.018,0.02,1.344,0.02c0.043-0.254,0.101-0.51,0.163-0.767L12.397,39.219z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M9.366,36.33l-0.023-0.008L9.34,36.344c-0.008,0.133-0.111,0.135-0.252,0.138H6.48H6.455\n\t\t\tc-0.129-0.003-0.223-0.005-0.258-0.132l-0.002-0.016h-0.14H6.034v0.021c0,0.146-0.009,0.287-0.031,0.427\n\t\t\tc-0.015,0.146-0.04,0.289-0.062,0.429L5.94,37.239h0.176h0.018l0.003-0.018c0.083-0.375,0.076-0.438,0.449-0.443h0.808v2.68\n\t\t\tc-0.006,0.393-0.119,0.396-0.396,0.402H6.901H6.88v0.174v0.021h0.021c0.198,0,0.553-0.021,0.855-0.021\n\t\t\tc0.271,0,0.625,0.021,0.825,0.021h0.021v-0.174V39.86H8.483c-0.241-0.006-0.393-0.031-0.397-0.413v-2.669h0.807\n\t\t\tc0.32,0,0.34,0.275,0.354,0.465l0.001,0.029l0.179-0.065l0.014-0.006v-0.015c0-0.137,0-0.272,0.009-0.406\n\t\t\tc0.015-0.137,0.035-0.271,0.056-0.408l0.003-0.021L9.366,36.33z M9.428,36.778c-0.009,0.138-0.009,0.273-0.009,0.408l-0.153,0.058\n\t\t\tc-0.011-0.187-0.035-0.485-0.374-0.485H8.063v2.69c0,0.392,0.177,0.437,0.418,0.437h0.098v0.151c-0.197,0-0.552-0.021-0.824-0.021\n\t\t\tc-0.304,0-0.658,0.021-0.855,0.021v-0.151h0.095c0.279,0,0.42-0.024,0.42-0.425v-2.702h-0.83c-0.379,0-0.394,0.092-0.47,0.461\n\t\t\tH5.963c0.021-0.144,0.046-0.283,0.061-0.433c0.02-0.142,0.03-0.282,0.03-0.43h0.123c0.041,0.151,0.167,0.146,0.303,0.146h2.61\n\t\t\tc0.137,0,0.263-0.005,0.274-0.156l0.12,0.021C9.463,36.506,9.444,36.639,9.428,36.778z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M9.38,36.373c-0.022,0.147-0.164,0.155-0.291,0.153H6.48H6.456c-0.119,0-0.25-0.004-0.295-0.145H6.078\n\t\t\tc-0.002,0.138-0.011,0.275-0.031,0.409c-0.014,0.137-0.037,0.27-0.057,0.405h0.109c0.066-0.352,0.111-0.467,0.487-0.461h0.852\n\t\t\tv2.725c0,0.407-0.164,0.448-0.441,0.449H6.921v0.104c0.2,0,0.542-0.019,0.834-0.019c0.264,0,0.601,0.019,0.802,0.019v-0.104H8.483\n\t\t\tc-0.244-0.001-0.443-0.063-0.443-0.459v-2.714h0.852c0.341,0.001,0.384,0.291,0.396,0.479l0.108-0.041\n\t\t\tc0-0.13,0.001-0.263,0.01-0.396c0.015-0.131,0.034-0.26,0.053-0.391L9.38,36.373z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M1.564,36.481C1.325,36.482,1.022,36.5,0.771,36.5c-0.247,0-0.55-0.02-0.75-0.02H0v0.174v0.021h0.092\n\t\t\tc0.183,0.002,0.351,0.021,0.352,0.269v2.649c-0.001,0.248-0.169,0.264-0.352,0.267H0.021H0v0.021v0.152v0.021h0.021\n\t\t\tc0.2,0,0.498-0.021,0.744-0.021c0.253,0,0.555,0.021,0.799,0.021h0.023V39.88v-0.02H1.494c-0.184-0.003-0.353-0.018-0.353-0.266\n\t\t\tv-2.65c0-0.248,0.169-0.266,0.353-0.268h0.07h0.023v-0.172v-0.023H1.564z M1.563,36.657h-0.07c-0.182,0-0.375,0.023-0.375,0.289\n\t\t\tv2.648c0,0.266,0.193,0.289,0.375,0.289h0.07v0.152c-0.243,0-0.546-0.02-0.799-0.02c-0.248,0-0.546,0.02-0.744,0.02v-0.152h0.071\n\t\t\tc0.182,0,0.374-0.022,0.374-0.289v-2.648c0-0.266-0.192-0.289-0.374-0.289H0.02v-0.15c0.198,0,0.501,0.02,0.75,0.02\n\t\t\tc0.252,0,0.555-0.02,0.793-0.02V36.657z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M24.331,3.825h-5.104C13.226,3.822,8.285,8.297,8.285,14.768c0,5.92,4.94,10.785,10.942,10.755h5.104\n\t\t\tc5.931,0.028,11.344-4.837,11.344-10.755C35.675,8.295,30.261,3.822,24.331,3.825z M19.251,24.589\n\t\t\tc-5.484-0.003-9.929-4.449-9.93-9.934c0.001-5.487,4.446-9.933,9.93-9.934c5.485,0.001,9.931,4.448,9.931,9.934\n\t\t\tC29.182,20.14,24.737,24.586,19.251,24.589z",
            fill: "#006ab0"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M34.141,34.237c-0.261,0-0.466,0-0.466-0.31v-6.422c0-0.076-0.016-0.133-0.075-0.133c-0.029,0-0.103,0.049-0.205,0.104\n\t\t\tc-0.195,0.113-0.584,0.281-0.957,0.399v0.148c0.354,0.103,0.494,0.121,0.494,0.726v5.178c0,0.309-0.186,0.309-0.448,0.309h-0.178\n\t\t\tv0.24c0.346-0.008,0.68-0.019,1.014-0.019c0.336,0,0.663,0.019,0.976,0.019v-0.239H34.141z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M21.503,8.778v11.753c2.362-0.909,4.039-3.193,4.043-5.877C25.543,11.972,23.865,9.684,21.503,8.778z",
            fill: "#006ab0"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M41.509,30.228c-0.51,0-1.012,0.354-1.383,0.669v-3.394c0-0.076-0.021-0.132-0.076-0.132\n\t\t\tc-0.028,0-0.103,0.046-0.204,0.103c-0.195,0.113-0.585,0.281-0.959,0.4v0.149c0.355,0.106,0.495,0.122,0.495,0.727v3.998\n\t\t\tc0,0.567-0.047,1.105-0.131,1.656l0.194,0.102l0.279-0.215c0.24,0.104,0.604,0.327,1.291,0.327c1.332,0,2.223-1.219,2.223-2.437\n\t\t\tC43.238,31.167,42.589,30.228,41.509,30.228z M41.083,34.377c-0.623,0-0.957-0.596-0.957-0.948v-2.26\n\t\t\tc0.25-0.216,0.539-0.438,0.883-0.438c0.726,0,1.486,0.838,1.486,1.841C42.495,33.399,42.079,34.377,41.083,34.377z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M38.536,34.146c-0.156,0-0.26-0.008-0.26-0.176v-3.433c0-0.105,0-0.167-0.074-0.167c-0.092,0-0.613,0.073-1.449,0.129\n\t\t\tv0.168c0.334,0.094,0.781,0.195,0.781,0.383v2.502c-0.391,0.321-0.811,0.563-1.125,0.563c-0.809,0-0.809-0.801-0.81-1.079v-2.395\n\t\t\tc0-0.206,0-0.269-0.104-0.269c-0.121,0-0.865,0.055-1.199,0.063v0.178c0.521,0.044,0.558,0.277,0.558,0.521v2.379\n\t\t\tc0,0.778,0.52,1.105,1.022,1.105c0.662,0,1.088-0.327,1.646-0.779v0.754l0.047,0.027c0.178-0.056,0.94-0.252,1.342-0.289v-0.193\n\t\t\tC38.788,34.138,38.649,34.146,38.536,34.146z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M21.42,31.139c0-0.397,0.353-0.612,0.724-0.612c0.598,0,0.922,0.335,1.016,0.92h0.215L23.311,30.5\n\t\t\tc-0.364-0.187-0.764-0.271-1.061-0.271c-1.021,0-1.44,0.669-1.44,1.19c0,1.495,2.024,1.139,2.024,2.239\n\t\t\tc0,0.327-0.206,0.662-0.755,0.665c-0.68,0-1.05-0.523-1.172-1.136l-0.167,0.048l0.103,1.069c0.325,0.184,0.761,0.315,1.134,0.315\n\t\t\tc1.106,0,1.6-0.652,1.6-1.267C23.577,31.893,21.42,32.229,21.42,31.139z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M2.736,27.987c-0.688,0-1.218,0.021-1.609,0.021c-0.363,0-0.733,0-1.096-0.021v0.241c0.418,0.01,0.855-0.047,0.855,0.789\n\t\t\tH0.885v4.512c-0.026,0.632-0.297,0.648-0.854,0.707v0.238c0.418-0.008,0.828-0.018,1.246-0.018\n\t\t\tc0.436,0.004,0.874,0.018,1.348,0.018c3.291,0,3.829-2.312,3.829-3.233C6.454,29.594,5.144,27.987,2.736,27.987z M2.764,34.178\n\t\t\tc-0.455,0-0.967-0.084-0.967-0.799l-0.001-5.056c0.168-0.013,0.344-0.037,0.726-0.037c1.794,0,2.91,1.255,2.91,2.993\n\t\t\tC5.432,32.665,4.772,34.178,2.764,34.178z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M1.493,36.633h0.049v-0.107c-0.233,0-0.526,0.021-0.771,0.021c-0.241,0-0.532-0.019-0.728-0.021v0.107h0.049\n\t\t\tc0.18,0,0.396,0.032,0.396,0.312v2.648c0,0.282-0.216,0.312-0.396,0.312H0.043v0.107c0.196,0,0.482-0.021,0.721-0.021\n\t\t\tc0.247,0,0.539,0.019,0.778,0.021v-0.107H1.493c-0.181,0-0.397-0.031-0.397-0.312v-2.648C1.096,36.664,1.312,36.633,1.493,36.633z\n\t\t\t",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M8.612,34.24c-0.26,0-0.465,0-0.465-0.309v-3.568c0-0.074-0.019-0.131-0.065-0.131c-0.047,0-0.103,0.009-0.169,0.046\n\t\t\tc-0.036,0.028-0.556,0.202-0.993,0.335v0.148c0.343,0.183,0.484,0.24,0.484,0.649v2.52c0,0.309-0.188,0.309-0.447,0.309H6.78v0.24\n\t\t\tc0.344-0.01,0.679-0.019,1.013-0.019c0.335,0,0.66,0.014,0.977,0.02v-0.24H8.612z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M19.973,30.23c-0.566,0-0.957,0.596-1.189,0.957h-0.02v-0.838c0-0.092-0.028-0.119-0.083-0.119\n\t\t\tc-0.084,0-0.456,0.24-1.135,0.529v0.14c0.14,0.073,0.483,0.131,0.483,0.419v2.609c0,0.311-0.186,0.311-0.445,0.311h-0.251v0.24\n\t\t\tc0.335-0.008,0.724-0.019,1.106-0.019c0.4,0,0.789,0.011,1.3,0.019v-0.239h-0.52c-0.26,0-0.446,0-0.446-0.31v-2.185\n\t\t\tc0-0.56,0.52-0.773,0.68-0.77c0.325,0,0.325,0.224,0.614,0.224c0.26,0,0.437-0.226,0.437-0.476\n\t\t\tC20.504,30.386,20.205,30.23,19.973,30.23z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M7.774,27.886c-0.232,0-0.454,0.215-0.454,0.447c0,0.242,0.213,0.466,0.454,0.467c0.252,0,0.457-0.217,0.457-0.467\n\t\t\tC8.231,28.081,8.016,27.886,7.774,27.886z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M17.032,33.435c-0.342,0.342-0.743,0.621-1.255,0.621c-0.742,0-1.311-0.724-1.365-1.607c-0.021-0.314-0.021-0.455,0-0.539\n\t\t\tl2.612,0.002l0.084-0.058c0.008-0.055,0.008-0.11,0.008-0.168c-0.008-0.984-0.818-1.459-1.571-1.459\n\t\t\tc-0.65,0-1.878,0.539-1.878,2.361c0,0.594,0.298,2.027,1.776,2.027c0.763,0,1.291-0.484,1.719-1.051L17.032,33.435z\n\t\t\t M15.508,30.527c0.521,0,0.81,0.381,0.81,0.854c0,0.11-0.027,0.232-0.26,0.232H14.44C14.552,30.943,14.942,30.527,15.508,30.527z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M12.958,14.654c0.004,2.681,1.677,4.966,4.039,5.874V8.778C14.636,9.687,12.963,11.973,12.958,14.654z",
            fill: "#006ab0"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M36.322,36.547c-0.231,0-0.455-0.019-0.68-0.02v0.105h0.09c0.154,0.002,0.461,0.071,0.461,0.5l-0.004,1.912l-0.012,0.01\n\t\t\tl-0.018,0.018l-2.25-2.545c-0.15,0-0.299,0.021-0.451,0.021c-0.186,0-0.375-0.019-0.561-0.021v0.105h0.045\n\t\t\tc0.235,0.001,0.481,0.182,0.481,0.479v2.132c0,0.402-0.115,0.663-0.478,0.665l-0.051-0.001v0.105c0.219,0,0.443-0.02,0.668-0.02\n\t\t\tc0.211,0,0.42,0.02,0.629,0.02v-0.104h-0.063c-0.397-0.002-0.498-0.176-0.498-0.616V37.15l2.626,2.938h0.166\n\t\t\tc-0.02-0.154-0.024-0.32-0.024-0.488v-2.303c0-0.608,0.254-0.664,0.471-0.666h0.055v-0.105\n\t\t\tC36.724,36.527,36.523,36.547,36.322,36.547z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M12.388,39.169h-0.022v0.003l-0.005,0.02c-0.152,0.568-0.397,0.598-1.005,0.602c-0.33-0.002-0.596,0-0.596-0.308v-1.159\n\t\t\th0.585c0.336,0.004,0.338,0.173,0.373,0.48l0.002,0.024l0.175-0.034l0.019-0.006l-0.001-0.018\n\t\t\tc-0.009-0.193-0.016-0.385-0.016-0.577c0-0.197,0.007-0.396,0.016-0.591l0.001-0.021h-0.175H11.72l-0.002,0.02\n\t\t\tc-0.034,0.271-0.086,0.423-0.378,0.426h-0.58v-1.281h0.681c0.522,0.006,0.565,0.242,0.595,0.571l0.002,0.024l0.178-0.045\n\t\t\tl0.016-0.006v-0.02c-0.011-0.145-0.016-0.334-0.016-0.51c0-0.097,0.002-0.187,0.005-0.263l0.001-0.021h-0.023\n\t\t\tc-0.344,0-0.868,0.019-1.294,0.019c-0.423,0-0.95-0.019-1.258-0.019H9.625v0.173v0.021h0.093c0.184,0.004,0.35,0.021,0.351,0.27\n\t\t\tv2.648c-0.001,0.248-0.167,0.265-0.351,0.268H9.647H9.625v0.021v0.151v0.021h0.022c0.288,0,0.89-0.02,1.344-0.02\n\t\t\tc0.455,0,1.037,0.02,1.361,0.02h0.018l0.004-0.018c0.046-0.268,0.106-0.535,0.171-0.803l0.005-0.021L12.388,39.169z\n\t\t\t M12.352,40.036c-0.325,0-0.906-0.021-1.36-0.021c-0.457,0-1.058,0.021-1.346,0.021v-0.15h0.071c0.183,0,0.374-0.024,0.374-0.289\n\t\t\tv-2.65c0-0.266-0.191-0.289-0.374-0.289H9.646v-0.152c0.308,0,0.835,0.021,1.26,0.021s0.949-0.021,1.293-0.021\n\t\t\tc-0.008,0.219-0.004,0.553,0.013,0.774l-0.154,0.04c-0.025-0.328-0.085-0.592-0.617-0.592h-0.702v1.326h0.603\n\t\t\tc0.301,0,0.368-0.172,0.397-0.447h0.152c-0.01,0.199-0.015,0.396-0.015,0.594c0,0.191,0.005,0.384,0.015,0.577l-0.152,0.03\n\t\t\tc-0.03-0.304-0.044-0.5-0.394-0.5h-0.606v1.176c0,0.33,0.292,0.33,0.617,0.33c0.607,0,0.874-0.041,1.026-0.616l0.142,0.034\n\t\t\tC12.458,39.5,12.397,39.766,12.352,40.036z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M36.947,36.485c-0.211,0-0.418,0.02-0.625,0.02c-0.234,0-0.467-0.02-0.707-0.02h-0.023v0.173v0.021h0.135\n\t\t\tc0.148,0.004,0.418,0.054,0.42,0.455v1.86l-2.225-2.502c-0.172-0.008-0.316,0.011-0.469,0.011c-0.189,0-0.387-0.019-0.58-0.019\n\t\t\tH32.85v0.173v0.021h0.088c0.217,0,0.438,0.164,0.438,0.433v2.132c-0.004,0.402-0.101,0.617-0.435,0.619h-0.069H32.85v0.174v0.021\n\t\t\th0.022c0.229,0,0.461-0.021,0.688-0.021c0.215,0,0.433,0.021,0.65,0.021h0.021v-0.174v-0.021h-0.107\n\t\t\tc-0.381-0.006-0.447-0.129-0.453-0.569v-2.026l2.564,2.861l0.209,0.006h0.025l-0.005-0.022c-0.024-0.155-0.028-0.332-0.028-0.509\n\t\t\tV37.3c0.004-0.596,0.221-0.615,0.428-0.621h0.076h0.028v-0.001v-0.172v-0.021H36.947z M36.953,36.658h-0.077\n\t\t\tc-0.218-0.001-0.457,0.039-0.457,0.641v2.303c0,0.177,0.004,0.353,0.031,0.51h-0.193l-2.604-2.904v2.085\n\t\t\tc0,0.441,0.086,0.593,0.477,0.593h0.086v0.151c-0.217,0-0.436-0.021-0.65-0.021c-0.229,0-0.461,0.021-0.688,0.021v-0.151h0.069\n\t\t\tc0.351,0,0.455-0.237,0.455-0.642v-2.132c0-0.283-0.229-0.455-0.459-0.455h-0.065v-0.152c0.19,0,0.39,0.021,0.58,0.021\n\t\t\tc0.153,0,0.299-0.021,0.45-0.021l2.252,2.535l0.007-0.007l0.004-0.003v-1.896c0-0.416-0.287-0.476-0.44-0.476h-0.107v-0.151\n\t\t\tc0.236,0,0.469,0.021,0.707,0.021c0.207,0,0.414-0.021,0.622-0.021V36.658z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M30.798,36.651c-0.885,0-1.146,0.791-1.146,1.467c0,0.905,0.414,1.771,1.233,1.771c0.982,0,1.152-0.866,1.152-1.604\n\t\t\tC32.039,37.547,31.641,36.651,30.798,36.651z M30.886,39.867c-0.801-0.001-1.211-0.849-1.213-1.75\n\t\t\tc0.004-0.674,0.259-1.442,1.125-1.444c0.824,0.002,1.22,0.879,1.22,1.611C32.016,39.023,31.85,39.867,30.886,39.867z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M30.811,36.45c-1.041,0-1.847,0.807-1.847,1.848c0.002,1.074,0.812,1.793,1.873,1.793c1.065,0,1.888-0.766,1.892-1.916\n\t\t\tC32.727,37.111,31.878,36.452,30.811,36.45z M30.886,39.911c-0.84-0.001-1.254-0.882-1.256-1.793\n\t\t\tc0.002-0.682,0.269-1.486,1.168-1.486c0.861,0,1.261,0.911,1.263,1.654C32.061,39.023,31.887,39.911,30.886,39.911z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M38.496,37.071h-0.008l-0.002,0.002l-0.469,1.432h0.946L38.496,37.071z M38.491,37.125l0.438,1.354h-0.883L38.491,37.125z\n\t\t\t",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M30.811,36.407c-1.062,0.001-1.888,0.825-1.89,1.891c0.002,1.1,0.832,1.834,1.914,1.838\n\t\t\tc1.086-0.004,1.935-0.785,1.935-1.961C32.768,37.083,31.896,36.408,30.811,36.407z M30.835,40.112\n\t\t\tc-1.073,0-1.892-0.729-1.892-1.815c0-1.054,0.812-1.867,1.865-1.867c1.078,0,1.938,0.668,1.938,1.744\n\t\t\tC32.748,39.34,31.914,40.112,30.835,40.112z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M43.341,39.156l-0.015-0.002l-0.006,0.019c-0.027,0.108-0.072,0.25-0.15,0.356c-0.088,0.121-0.266,0.239-0.473,0.263\n\t\t\tc-0.129,0.016-0.258,0.021-0.383,0.021c-0.099,0-0.189-0.004-0.279-0.01c-0.158-0.023-0.281-0.072-0.283-0.263v-2.608\n\t\t\tc0.004-0.256,0.127-0.246,0.322-0.252h0.127h0.021v-0.174v-0.021h-0.021c-0.26,0-0.514,0.02-0.77,0.02\n\t\t\tc-0.269,0-0.533-0.02-0.802-0.02h-0.021v0.173v0.021H40.7c0.186,0.002,0.357,0.021,0.359,0.267v2.603\n\t\t\tc-0.002,0.299-0.177,0.312-0.359,0.314h-0.07h-0.021v0.174v0.021h0.021c0.445,0,0.893-0.021,1.336-0.021\n\t\t\tc0.445,0,0.891,0.021,1.334,0.021h0.018l0.004-0.018c0.062-0.288,0.132-0.568,0.189-0.859l0.004-0.025H43.341z M43.3,40.036\n\t\t\tc-0.442,0-0.889-0.021-1.332-0.021c-0.446,0-0.891,0.021-1.336,0.021v-0.15H40.7c0.184,0,0.383-0.024,0.383-0.341v-2.6\n\t\t\tc0-0.267-0.199-0.289-0.383-0.289h-0.068v-0.151c0.269,0,0.531,0.02,0.8,0.02c0.258,0,0.51-0.02,0.77-0.02v0.151h-0.129\n\t\t\tc-0.189,0-0.342,0.005-0.342,0.272v2.609c0,0.203,0.141,0.264,0.303,0.284c0.207,0.015,0.436,0.015,0.668-0.011\n\t\t\tc0.213-0.025,0.395-0.146,0.484-0.272c0.08-0.11,0.125-0.254,0.155-0.364h0.146C43.434,39.465,43.363,39.747,43.3,40.036z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M40.425,39.908c-0.172,0-0.293-0.068-0.355-0.217c-0.064-0.154-0.127-0.343-0.187-0.521l-0.932-2.646\n\t\t\tc-0.017-0.039-0.029-0.087-0.043-0.12c-0.005-0.008-0.003-0.008-0.009-0.008h-0.008c-0.012,0-0.018,0.005-0.029,0.008\n\t\t\tc-0.098,0.062-0.301,0.164-0.45,0.203c-0.031,0.191-0.123,0.443-0.188,0.631l-0.812,2.322c-0.076,0.22-0.252,0.346-0.459,0.346\n\t\t\th-0.009v0.105c0.175-0.011,0.349-0.021,0.525-0.021c0.195,0,0.396,0.011,0.59,0.021v-0.105h-0.035\n\t\t\tc-0.164-0.003-0.395-0.021-0.399-0.201c0-0.1,0.063-0.229,0.11-0.4l0.166-0.543h1.181l0.205,0.604\n\t\t\tc0.055,0.163,0.103,0.297,0.103,0.371c-0.009,0.154-0.222,0.167-0.336,0.171H39.02v0.104h0.002c0.25-0.01,0.496-0.021,0.735-0.021\n\t\t\tc0.234,0,0.455,0.011,0.677,0.021v-0.104H40.425z M37.986,38.525l0.484-1.476h0.016h0.012h0.018l0.479,1.476H37.986z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M43.43,36.362c-0.306,0-0.533,0.232-0.533,0.533c0,0.299,0.229,0.529,0.533,0.529c0.303,0,0.53-0.23,0.53-0.529\n\t\t\tC43.96,36.596,43.73,36.362,43.43,36.362z M43.43,37.327c-0.24,0-0.425-0.204-0.425-0.434c0-0.229,0.185-0.434,0.425-0.434\n\t\t\tc0.239,0,0.42,0.204,0.42,0.434S43.666,37.327,43.43,37.327z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M43.583,37.031l-0.091-0.12c0.072-0.021,0.125-0.081,0.125-0.161c0-0.097-0.084-0.141-0.18-0.141h-0.271v0.026\n\t\t\tc0.068-0.004,0.076,0.017,0.076,0.07v0.395c0,0.041-0.014,0.039-0.078,0.05v0.024h0.258V37.15c-0.066-0.011-0.08-0.009-0.08-0.042\n\t\t\tv-0.18h0.061c0.099,0.143,0.144,0.246,0.191,0.246h0.104v-0.017C43.669,37.138,43.622,37.088,43.583,37.031z M43.406,36.895\n\t\t\th-0.064v-0.252h0.061c0.058,0,0.105,0.031,0.105,0.117C43.507,36.846,43.475,36.895,43.406,36.895z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M40.424,39.857L40.424,39.857c-0.16,0.002-0.255-0.054-0.313-0.185c-0.064-0.15-0.125-0.336-0.188-0.516l-0.93-2.646\n\t\t\tc-0.018-0.043-0.031-0.088-0.047-0.129c-0.016-0.025-0.041-0.029-0.055-0.029c-0.021,0-0.037,0.008-0.047,0.013\n\t\t\tc-0.101,0.062-0.304,0.163-0.462,0.219c-0.045,0.2-0.137,0.451-0.198,0.64l-0.812,2.322c-0.074,0.204-0.231,0.312-0.42,0.312\n\t\t\th-0.029h-0.021v0.174v0.021h0.021c0.185-0.01,0.365-0.021,0.546-0.021c0.2,0,0.409,0.011,0.61,0.021H38.1V39.88v-0.02h-0.076\n\t\t\tc-0.168,0-0.354-0.03-0.354-0.155c-0.002-0.081,0.058-0.214,0.108-0.388l0.156-0.512h1.117l0.191,0.572\n\t\t\tc0.057,0.161,0.104,0.299,0.102,0.355c0,0.098-0.176,0.125-0.293,0.125H39h-0.023v0.174v0.023L39,40.053\n\t\t\tc0.256-0.009,0.51-0.021,0.758-0.021c0.242,0,0.469,0.012,0.695,0.021l0.023,0.002v-0.176v-0.021H40.424z M40.457,40.036\n\t\t\tc-0.229-0.01-0.457-0.021-0.699-0.021c-0.248,0-0.5,0.011-0.758,0.021v-0.15h0.055c0.115,0,0.314-0.021,0.314-0.147\n\t\t\tc0-0.065-0.047-0.2-0.104-0.363l-0.197-0.587h-1.146l-0.164,0.527c-0.049,0.17-0.109,0.304-0.109,0.394\n\t\t\tc0,0.153,0.213,0.177,0.379,0.177h0.058v0.152c-0.203-0.01-0.41-0.02-0.612-0.02c-0.183,0-0.363,0.009-0.546,0.02v-0.152h0.029\n\t\t\tc0.198,0,0.364-0.117,0.44-0.33l0.81-2.319c0.065-0.188,0.157-0.438,0.187-0.629c0.162-0.056,0.364-0.155,0.461-0.218\n\t\t\tc0.018-0.004,0.024-0.01,0.041-0.01c0.016,0,0.024,0,0.035,0.016c0.016,0.041,0.032,0.087,0.047,0.125l0.93,2.646\n\t\t\tc0.061,0.178,0.121,0.363,0.189,0.518c0.061,0.143,0.165,0.201,0.331,0.201h0.031L40.457,40.036z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M43.359,39.198c-0.031,0.109-0.076,0.246-0.156,0.357c-0.094,0.127-0.282,0.252-0.498,0.281\n\t\t\tc-0.136,0.014-0.267,0.021-0.39,0.021c-0.098,0-0.192-0.007-0.285-0.013c-0.163-0.018-0.323-0.088-0.323-0.305V36.93\n\t\t\tc0-0.28,0.178-0.297,0.366-0.297h0.105v-0.106c-0.25,0.001-0.496,0.021-0.746,0.021c-0.26,0-0.518-0.019-0.779-0.021v0.106h0.05\n\t\t\tc0.178,0,0.401,0.034,0.401,0.312v2.603c0,0.325-0.219,0.359-0.401,0.359h-0.05v0.107c0.439,0,0.875-0.021,1.314-0.021\n\t\t\tc0.441,0,0.878,0.021,1.316,0.021c0.059-0.274,0.127-0.543,0.18-0.816H43.359z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M28.722,39.86c-0.185-0.003-0.351-0.018-0.354-0.266v-2.65c0.002-0.247,0.168-0.266,0.354-0.268h0.069h0.021V36.5v-0.02\n\t\t\th-0.021c-0.236,0.003-0.541,0.021-0.793,0.021c-0.246,0-0.551-0.02-0.748-0.02h-0.021v0.174v0.021h0.094\n\t\t\tc0.183,0.002,0.351,0.021,0.353,0.269v2.649c-0.002,0.248-0.17,0.265-0.353,0.267H27.25h-0.021v0.021v0.152v0.021h0.021\n\t\t\tc0.197,0,0.498-0.021,0.746-0.021c0.252,0,0.553,0.021,0.797,0.021h0.021v-0.174V39.86H28.722z M28.792,40.034\n\t\t\tc-0.242,0-0.545-0.02-0.797-0.02c-0.248,0-0.548,0.02-0.746,0.02v-0.152h0.071c0.183,0,0.373-0.022,0.373-0.289v-2.648\n\t\t\tc0-0.266-0.19-0.289-0.373-0.289h-0.07v-0.15c0.197,0,0.5,0.02,0.748,0.02c0.256,0,0.561-0.02,0.795-0.02l-0.001,0.15h-0.069\n\t\t\tc-0.183,0-0.375,0.023-0.375,0.289v2.648c0,0.266,0.192,0.289,0.375,0.289h0.069V40.034z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M20.433,36.484c-0.208,0-0.415,0.021-0.622,0.021c-0.236,0-0.469-0.021-0.708-0.021h-0.022v0.174v0.021h0.133\n\t\t\tc0.147,0.002,0.417,0.054,0.418,0.455v1.86l-2.224-2.504c-0.168-0.007-0.316,0.013-0.464,0.013c-0.191,0-0.388-0.02-0.581-0.02\n\t\t\tH16.34v0.174v0.021h0.087c0.218,0,0.438,0.164,0.438,0.433v2.13c-0.003,0.404-0.099,0.62-0.432,0.621h-0.07H16.34v0.174v0.021\n\t\t\th0.023c0.227,0,0.461-0.021,0.687-0.021c0.217,0,0.433,0.021,0.652,0.021h0.022v-0.174v-0.021h-0.106\n\t\t\tc-0.382-0.006-0.45-0.129-0.455-0.569v-2.026l2.567,2.861l0.208,0.008h0.025l-0.003-0.023c-0.027-0.156-0.031-0.332-0.031-0.51\n\t\t\tv-2.301c0.005-0.598,0.22-0.615,0.429-0.621h0.075h0.022v-0.003v-0.172v-0.022H20.433z M20.435,36.656h-0.076\n\t\t\tc-0.213,0.001-0.451,0.041-0.451,0.643v2.303c0,0.177,0.004,0.353,0.029,0.51h-0.193l-2.604-2.905v2.085\n\t\t\tc0,0.441,0.086,0.594,0.477,0.594h0.084v0.15c-0.216,0-0.434-0.021-0.651-0.021c-0.229,0-0.461,0.021-0.688,0.021v-0.15h0.07\n\t\t\tc0.35,0,0.455-0.238,0.455-0.643v-2.132c0-0.283-0.232-0.455-0.46-0.455h-0.064v-0.151c0.19,0,0.39,0.02,0.581,0.02\n\t\t\tc0.15,0,0.299-0.02,0.45-0.02l2.251,2.534h0.001l0.004-0.007l0.003-0.003v-1.896c0-0.416-0.287-0.477-0.439-0.477h-0.11v-0.15\n\t\t\tc0.238,0,0.47,0.02,0.708,0.02c0.208,0,0.414-0.02,0.623-0.02V36.656z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M19.811,36.547c-0.232,0-0.456-0.019-0.686-0.021v0.105h0.089c0.157,0.002,0.463,0.07,0.463,0.5l-0.007,1.912l-0.009,0.01\n\t\t\tl-0.016,0.018l-2.251-2.545c-0.151,0-0.297,0.021-0.45,0.021c-0.187,0-0.376-0.019-0.561-0.021v0.105h0.044\n\t\t\tc0.239,0.001,0.482,0.182,0.482,0.479v2.13c0,0.404-0.115,0.665-0.477,0.667l-0.05-0.001v0.105c0.219,0,0.444-0.021,0.667-0.021\n\t\t\tc0.213,0,0.422,0.021,0.631,0.021v-0.105h-0.062c-0.4-0.002-0.498-0.176-0.5-0.616v-2.14l2.627,2.938h0.167\n\t\t\tc-0.023-0.154-0.026-0.32-0.026-0.488v-2.303c0-0.605,0.254-0.664,0.472-0.666h0.053v-0.105\n\t\t\tC20.211,36.527,20.012,36.547,19.811,36.547z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M15.702,39.594l-0.881-1.318l0.025-0.01c0.395-0.154,0.689-0.426,0.689-0.871c0-0.701-0.546-0.865-1.182-0.868\n\t\t\tc-0.267,0-0.51,0.021-0.814,0.021c-0.297,0-0.598-0.019-0.763-0.021v0.108h0.069c0.158,0,0.381,0.025,0.381,0.405v2.57\n\t\t\tc0,0.203-0.189,0.297-0.381,0.297h-0.069v0.104c0.243,0,0.474-0.021,0.711-0.021c0.284,0,0.569,0.021,0.848,0.021v-0.104h-0.068\n\t\t\tc-0.23,0-0.438-0.028-0.438-0.431v-1.104h0.023h0.346l0.005,0.012c0.331,0.582,0.663,1.129,1.036,1.629\n\t\t\tc0.154,0,0.315-0.021,0.473-0.021c0.162,0,0.317,0.017,0.475,0.021v-0.109C15.951,39.873,15.84,39.803,15.702,39.594z\n\t\t\t M14.101,38.236h-0.248H13.83v-1.55l0.021-0.002c0.068-0.006,0.137-0.02,0.29-0.02c0.461,0,0.761,0.301,0.761,0.76\n\t\t\tC14.9,38.031,14.61,38.236,14.101,38.236z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M16.212,39.857c-0.245-0.027-0.333-0.078-0.474-0.289l-0.85-1.271c0.39-0.16,0.692-0.445,0.692-0.902\n\t\t\tc-0.001-0.734-0.588-0.909-1.227-0.911c-0.27,0-0.512,0.021-0.814,0.021c-0.308,0-0.621-0.021-0.784-0.021h-0.022v0.174v0.022\n\t\t\th0.112c0.158,0.004,0.333,0.002,0.337,0.36v2.57c-0.001,0.171-0.153,0.25-0.337,0.25h-0.09h-0.022v0.174v0.021h0.022\n\t\t\tc0.253,0,0.49-0.021,0.732-0.021c0.287,0,0.582,0.021,0.87,0.021h0.023v-0.174V39.86h-0.113c-0.234-0.007-0.387-0.002-0.393-0.384\n\t\t\tv-1.061h0.299c0.327,0.578,0.659,1.121,1.047,1.631c0.176,0.008,0.338-0.013,0.492-0.013c0.166,0,0.328,0.021,0.497,0.021h0.021\n\t\t\tV39.88v-0.02L16.212,39.857z M16.21,40.036c-0.166,0-0.329-0.021-0.495-0.021c-0.157,0-0.32,0.021-0.477,0.021\n\t\t\tc-0.389-0.513-0.724-1.059-1.052-1.643h-0.333v1.084c0,0.392,0.181,0.406,0.415,0.406h0.091v0.151c-0.29,0-0.582-0.021-0.87-0.021\n\t\t\tc-0.243,0-0.481,0.021-0.733,0.021v-0.151h0.09c0.189,0,0.36-0.086,0.36-0.272v-2.57c0-0.366-0.203-0.384-0.36-0.384h-0.09v-0.15\n\t\t\tc0.162,0,0.475,0.02,0.784,0.02c0.303,0,0.546-0.02,0.814-0.02c0.637,0,1.205,0.172,1.205,0.891c0,0.453-0.303,0.732-0.704,0.89\n\t\t\tl0.864,1.294c0.142,0.215,0.243,0.275,0.491,0.305V40.036z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M28.722,36.633h0.047v-0.107c-0.232,0.001-0.525,0.021-0.771,0.021c-0.242,0-0.531-0.019-0.725-0.021v0.107h0.047\n\t\t\tc0.181,0,0.396,0.032,0.396,0.312v2.648c0,0.28-0.214,0.312-0.396,0.312h-0.047v0.105c0.194-0.002,0.479-0.02,0.721-0.02\n\t\t\tc0.244,0,0.537,0.02,0.775,0.02v-0.105h-0.047c-0.183-0.001-0.396-0.033-0.396-0.312v-2.648\n\t\t\tC28.326,36.664,28.537,36.633,28.722,36.633z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M13.707,34.237h-0.168c-0.261,0-0.464,0-0.464-0.31v-2.398c0-0.725-0.279-1.301-1.069-1.301\n\t\t\tc-0.623,0-1.079,0.316-1.61,0.697v-0.604c0-0.075-0.028-0.094-0.054-0.094c-0.448,0.195-0.828,0.316-1.228,0.447v0.176\n\t\t\tc0.436,0.175,0.54,0.268,0.54,0.613H9.653v2.463c0,0.31-0.185,0.31-0.445,0.31H9.041v0.239c0.334-0.01,0.669-0.019,1.003-0.019\n\t\t\tc0.335,0,0.661,0.019,0.986,0.019v-0.239h-0.167c-0.26,0-0.467,0-0.467-0.31v-2.752c0.309-0.203,0.783-0.447,1.079-0.447\n\t\t\tc0.531,0,0.855,0.263,0.855,0.793v2.406c0,0.311-0.185,0.311-0.445,0.311h-0.167v0.238c0.332-0.008,0.668-0.018,1.003-0.018\n\t\t\ts0.659,0.018,0.986,0.018C13.707,34.474,13.707,34.237,13.707,34.237z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M14.141,36.685c-0.151,0-0.217,0.017-0.288,0.021v1.508h0.248c0.506,0,0.778-0.191,0.778-0.789\n\t\t\tC14.878,36.973,14.591,36.685,14.141,36.685z M14.101,38.192h-0.226v-1.467c0.065-0.006,0.129-0.02,0.266-0.02\n\t\t\tc0.44,0.004,0.715,0.277,0.718,0.719C14.855,38.012,14.601,38.185,14.101,38.192z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M26.948,36.33l-0.027-0.008l-0.002,0.022c-0.008,0.133-0.108,0.135-0.25,0.138h-2.61h-0.023\n\t\t\tc-0.131-0.003-0.223-0.005-0.258-0.132l-0.006-0.016h-0.138h-0.021v0.021c0,0.146-0.012,0.287-0.03,0.427\n\t\t\tc-0.017,0.146-0.041,0.288-0.062,0.431l-0.002,0.025h0.176h0.019l0.004-0.018c0.084-0.375,0.074-0.438,0.449-0.443h0.808\n\t\t\tl0.002,2.68c-0.009,0.393-0.119,0.396-0.398,0.402h-0.096H24.46v0.174v0.021h0.023c0.197,0,0.553-0.021,0.854-0.021\n\t\t\tc0.271,0,0.625,0.021,0.824,0.021h0.019V39.88v-0.02h-0.119c-0.24-0.006-0.394-0.031-0.396-0.413v-2.669h0.809\n\t\t\tc0.318,0,0.34,0.275,0.353,0.465l0.002,0.029l0.178-0.065l0.015-0.006v-0.015c0-0.135,0-0.272,0.01-0.406\n\t\t\tc0.016-0.137,0.035-0.271,0.055-0.408l0.006-0.021L26.948,36.33z M27.007,36.778c-0.01,0.138-0.01,0.273-0.01,0.408l-0.151,0.058\n\t\t\tc-0.011-0.187-0.035-0.485-0.373-0.485h-0.832v2.69c0,0.392,0.178,0.437,0.42,0.437h0.094v0.151c-0.193,0-0.549-0.021-0.822-0.021\n\t\t\tc-0.303,0-0.658,0.021-0.854,0.021v-0.151h0.096c0.279,0,0.421-0.024,0.421-0.425v-2.702h-0.83c-0.379,0-0.396,0.092-0.472,0.461\n\t\t\th-0.151c0.021-0.144,0.047-0.283,0.062-0.433c0.021-0.142,0.03-0.282,0.03-0.43h0.123c0.039,0.151,0.166,0.146,0.302,0.146h2.61\n\t\t\tc0.138,0,0.263-0.005,0.272-0.156l0.121,0.021C27.042,36.506,27.025,36.639,27.007,36.778z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M26.96,36.373c-0.022,0.147-0.166,0.156-0.296,0.156h-2.609H24.03c-0.118,0.001-0.248-0.005-0.295-0.146h-0.082\n\t\t\tc-0.002,0.138-0.009,0.275-0.03,0.409c-0.013,0.137-0.039,0.273-0.058,0.405h0.107c0.066-0.352,0.111-0.467,0.488-0.461h0.849\n\t\t\tv2.726c0,0.405-0.162,0.445-0.441,0.446h-0.073v0.105c0.2-0.002,0.541-0.021,0.834-0.021c0.265,0,0.603,0.019,0.801,0.021v-0.106\n\t\t\th-0.073c-0.242-0.001-0.439-0.062-0.439-0.458v-2.714h0.852c0.34,0.001,0.385,0.291,0.393,0.479l0.111-0.041\n\t\t\tc0-0.131,0-0.264,0.01-0.396c0.015-0.131,0.033-0.26,0.054-0.391L26.96,36.373z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M31.769,32.714c-0.186,0.974-0.949,1.607-1.867,1.607c-1.431,0-2.771-1.348-2.771-3.227c0-2.231,1.551-2.947,2.621-2.947\n\t\t\tc1.041,0,1.81,0.435,1.988,1.525h0.23l-0.092-1.33c-0.716-0.25-1.443-0.494-2.195-0.494c-1.822,0-3.522,1.367-3.522,3.272\n\t\t\tc0,2.093,1.377,3.497,3.551,3.497c0.616,0.001,1.666-0.25,2.057-0.492l0.213-1.356L31.769,32.714z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M23.909,39.856c-0.158,0.005-0.251-0.051-0.309-0.182c-0.064-0.15-0.125-0.336-0.187-0.516l-0.933-2.646\n\t\t\tc-0.017-0.043-0.03-0.088-0.045-0.129c-0.017-0.025-0.043-0.029-0.056-0.029c-0.021,0-0.034,0.008-0.049,0.013\n\t\t\tc-0.099,0.062-0.302,0.164-0.459,0.217c-0.043,0.202-0.135,0.453-0.201,0.642l-0.811,2.322c-0.074,0.207-0.23,0.312-0.418,0.312\n\t\t\th-0.03H20.39v0.174v0.021h0.024c0.181-0.01,0.362-0.021,0.545-0.021c0.202,0,0.409,0.011,0.61,0.021h0.022v-0.174V39.86h-0.077\n\t\t\tc-0.168,0-0.356-0.03-0.358-0.154c0-0.082,0.061-0.216,0.112-0.389l0.156-0.512h1.115l0.193,0.572\n\t\t\tc0.057,0.16,0.104,0.299,0.1,0.355c0,0.098-0.172,0.125-0.291,0.125h-0.057h-0.021v0.174v0.023l0.022-0.002\n\t\t\tc0.26-0.009,0.51-0.021,0.758-0.021c0.24,0,0.471,0.012,0.695,0.021l0.022,0.002v-0.177v-0.021H23.909z M23.942,40.036h-0.001\n\t\t\tc-0.228-0.01-0.453-0.021-0.695-0.021c-0.248,0-0.502,0.011-0.76,0.021v-0.15h0.057c0.117,0,0.312-0.021,0.312-0.147\n\t\t\tc0-0.065-0.045-0.2-0.101-0.363l-0.198-0.587h-1.147l-0.161,0.527c-0.051,0.17-0.112,0.304-0.112,0.394\n\t\t\tc0,0.153,0.213,0.177,0.379,0.177h0.057v0.152c-0.204-0.01-0.41-0.02-0.614-0.02c-0.182,0-0.363,0.009-0.545,0.02v-0.152h0.03\n\t\t\tc0.197,0,0.366-0.117,0.439-0.33l0.81-2.319c0.066-0.188,0.158-0.438,0.188-0.629c0.161-0.056,0.363-0.155,0.458-0.218\n\t\t\tc0.016-0.004,0.023-0.01,0.041-0.01c0.016,0,0.025,0,0.036,0.016c0.015,0.041,0.03,0.087,0.046,0.125l0.932,2.646\n\t\t\tc0.061,0.178,0.119,0.363,0.188,0.518c0.061,0.143,0.166,0.201,0.334,0.201h0.027V40.036z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M23.914,39.908c-0.174,0-0.293-0.068-0.355-0.217c-0.066-0.154-0.127-0.343-0.188-0.521l-0.931-2.646\n\t\t\tc-0.017-0.039-0.03-0.087-0.045-0.122c-0.002-0.006-0.002-0.006-0.008-0.006H22.38c-0.011,0-0.019,0.005-0.028,0.008\n\t\t\tc-0.098,0.062-0.302,0.163-0.451,0.203c-0.032,0.191-0.123,0.443-0.189,0.631l-0.809,2.322c-0.077,0.22-0.254,0.346-0.46,0.346\n\t\t\th-0.008v0.105C20.608,40,20.783,39.99,20.96,39.99c0.196,0,0.395,0.011,0.588,0.021v-0.105h-0.033\n\t\t\tc-0.164-0.003-0.395-0.021-0.401-0.2c0.001-0.101,0.063-0.231,0.113-0.401l0.166-0.543h1.177l0.205,0.604\n\t\t\tc0.057,0.163,0.104,0.297,0.104,0.371c-0.006,0.154-0.221,0.167-0.336,0.171H22.51v0.104c0.25-0.01,0.496-0.021,0.736-0.021\n\t\t\tc0.234,0,0.455,0.011,0.676,0.021v-0.104H23.914z M21.474,38.525l0.484-1.476h0.016h0.011H22l0.482,1.476H21.474z",
            fill: "#1d1d1d"
          }
        })
      ]),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M21.986,37.071h-0.011v0.003l-0.469,1.431h0.945L21.986,37.071z M21.98,37.125l0.439,1.354h-0.884L21.98,37.125z",
            fill: "#1d1d1d"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }