<template>
  <svg class="icon-move" xmlns="http://www.w3.org/2000/svg" width="16.984" height="16.984" viewBox="0 0 16.984 16.984">
    <path d="M502.275,411.253l-2.388-2.387a.75.75,0,1,0-1.06,1.061h0l1.107,1.107h-5.181v-5.181l1.106,1.107a.75.75,0,1,0,1.061-1.061h0l-2.387-2.388a.75.75,0,0,0-1.061,0l-2.387,2.388a.75.75,0,1,0,1.061,1.061l1.107-1.107v5.181h-5.181l1.106-1.107a.75.75,0,1,0-1.06-1.061h0l-2.388,2.387a.75.75,0,0,0,0,1.061l2.388,2.387a.75.75,0,0,0,1.061-1.061l-1.107-1.106h5.181v5.18l-1.107-1.106a.75.75,0,0,0-1.061,1.061l2.387,2.386a.75.75,0,0,0,1.061,0l2.387-2.386a.75.75,0,0,0-1.06-1.061h0l-1.106,1.106v-5.18h5.18l-1.106,1.106a.75.75,0,1,0,1.061,1.061l2.387-2.387a.752.752,0,0,0,0-1.061Z" transform="translate(-485.51 -403.291)" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-move'
  }
</script>
