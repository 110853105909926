import { Model } from '@vuex-orm/core';
import Playlist from '@app2/models/Playlist';
import Hub from '@app2/models/Hub';

export default class PlaylistHub extends Model {
  static entity = 'App\\PlaylistHub';

  static primaryKey = ['playlist_id', 'hub_id'];

  static apiConfig = {
    baseURL: '/api/hubs/',
    actions: {
      updatePlaylists(hub, config) {
        return this.put(`${hub.hash}/playlists`, config, { save: false })
      },
      attachPlaylists(hub, playlists, config = {}) {
        return this.post(`${hub.hash}/playlists`, { playlists }, config)
      },
      detachPlaylists(hub, playlists, config = {}) {
        return this.delete(`${hub.hash}/playlists`, { data: {playlists}, ...config })
          .then(() => {
              if (config.save && config.save === false) return;
              playlists.forEach(f =>
                PlaylistHub.delete(r => r.playlist_id === f && r.hub_id === hub.id)
              );
            },
          );
      }
    }
  };

  static fields() {
    return {
      playlist_id: this.attr(null),
      playlist: this.belongsTo(Playlist, 'playlist_id'),
      hub_id: this.attr(null),
      hub: this.belongsTo(Hub, 'hub_id'),
      position: this.attr(null),
    };
  }
}
