<template>
  <div class="side-panel-collapse" :class="{'can-hover':!visible}">
    <div class="slot-top" @click.prevent="visible = !visible">
      <h4 class="txt-heading-small mb-1">{{ data.title }}</h4>
      <b-btn variant="icon primary" size="sm">
        <svg width="24" class="icon-sm" viewBox="0 0 24 24"><use xlink:href="#icon-chevron-down"></use></svg>
      </b-btn>
    </div>
    <b-collapse class="slot-middle pr-1" v-if="data.completed || data.failed" v-model="visible">
      <div class="pt-3 px-2 pb-3">
        <b-alert v-if="data.completed" show fade variant="success">
          <svg viewBox="0 0 24 24" class="alert-icon icon-sm"><use xlink:href="#icon-status-success-color"></use></svg>
          <div class="alert-content">
            <h6 class="txt-body"><b>Successful rows</b></h6>
            <!-- <p class="txt-body">Andrew Tate (Wrong characters - Location row - Berl1n)</p>
            <p class="txt-body">Andrew Tate (Wrong characters - Location row - Berl1n)</p> -->
          </div>
          <div class="alert-count">
            {{ numberFormat(data.completed) }}
          </div>
        </b-alert>
        <b-alert v-if="data.failed" show variant="warning">
          <svg viewBox="0 0 24 24" class="alert-icon icon-sm"><use xlink:href="#icon-warning-triangle-color"></use></svg>
          <div class="alert-content">
            <h6 class="txt-body"><b>Failed rows</b></h6>
            <!-- <p class="txt-body">Andrew Tate (Wrong characters - Location row - Berl1n)</p>
            <p class="txt-body">Andrew Tate (Wrong characters - Location row - Berl1n)</p> -->
          </div>
          <div class="alert-count">
            {{ numberFormat(data.failed) }}
          </div>
        </b-alert>
      </div>
    </b-collapse>
    <div class="slot-bottom" @click.prevent="visible = !visible">
      <div class="txt-body txt-muted">
        <svg class="mr-2 icon-sm" width="24" v-if="data.failed" viewBox="0 0 24 24"><use xlink:href="#icon-warning-triangle-color"></use></svg>
        {{ numberFormat(data.count) }} people
      </div>
      <p class="ml-auto txt-body-small txt-muted-2">{{ data.created_at | datetime }}</p>
    </div>
  </div>
</template>
<script>
  import moment from '@app2/utils/moment';
  import { numberFormat } from '@app2/utils/helpers'
  export default {
    name: 'SidePanelOption',
    data() {
      return {
        visible: false,
      }
    },
    props: {
      data: {}
    },
    methods: {
      moment,
      numberFormat,
    }
  }
</script>
