<template>
  <audience-slide-in-template
    :back-route="{name: 'UploadStep1'}"
    back-label="Back to Step 1"
    hidePanelTitle
  >
    <h6 class="txt-title-small"><b>Step 2</b> of 3 <br /><span class="txt-muted">Select fields to import</span></h6>
    <hr class="mb-3"/>
    <div class="tag-list mb-3">
      <span class="tag-basic tag-variant-dark">
        <svg viewBox="0 0 24 24" class="icon-sm mr-2"><use xlink:href="#icon-page-single"></use></svg>
        <span>{{ file.name }}</span>
      </span>
    </div>
    <div class="side-panel-widget pt-2">
      <div class="audience-edit-row px-4 py-3">
        <div class="slot-left">
          <p class="txt-body txt-muted">Supported Fields</p>
        </div>
        <div class="slot-right">
          <p class="txt-body"><span class="txt-muted">Fields from </span><b>{{ file.name }}</b><span class="txt-muted"> file</span></p>
        </div>
      </div>
      <div class="audience-edit-row px-4 py-3" v-for="(ch, index) in columnHeaders" :key="index">
        <div class="slot-left"><span class="txt-body-small">{{ ch.supported_field }}</span></div>
        <!-- <div class="hr-line" /> -->
        <div class="slot-right">
          <import-column-field :col-header="ch" :col-index="index" class="w-100" />
        </div>
      </div>
    </div>

    <div class="side-panel-library-cta p-3">
      <b-btn block variant="primary" size="lg" :to="{name: 'UploadStep3'}">
        Next Step
        <svg class="btn-right" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-arrow-right"></use></svg>
      </b-btn>
    </div>
  </audience-slide-in-template>
</template>

<script>
  import ImportColumnField from '@app2/modules/Audience/Import/Components/ImportColumnField'
  import AudienceSlideInTemplate from "@app2/modules/Audience/Components/Template";

  export default {
    name: 'UploadStep2',
    components: {
      ImportColumnField,
      AudienceSlideInTemplate
    },
    computed: {
      columnOptions() {
        return this.$store.getters['v2/audience/importColumns']
      },
      columnHeaders() {
        return this.$store.getters['v2/audience/columnHeaders']
      },
      file() {
        return this.$store.getters['v2/audience/csvFile']
      },
    },
    methods: {
      mapColumn(column, index) {
        this.$store.commit('v2/audience/SET_MAP_COLUMN', {column, index})
      }
    }
  }
</script>
