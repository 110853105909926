<template>
  <audience-slide-in-template
    back-label="Bulk Actions"
    :back-route="{name: 'BulkIndex'}"
    :title="$route.params.title"
  >
    <selected-audience />
    <hr class="mt-0 mb-3">
    <div class="side-panel-widget p-4">
      <form-control-select
        v-model="tagList"
        placeholder="Search audience tags..."
        option-icon="tag"
        custom-class="txt-muted"
        track-by="name"
        select-label="name"
        tag-icon="tag"
        :selected-title="($route.name === 'TagsAttach' ? 'Add the following' : 'Remove the following')"
        :label="($route.name === 'TagsAttach' ? 'Add Audience Tags' : 'Remove Audience Tags')"
        :options="tagOptions"
        :type="`tag${tagList.length > 1 ? 's' : ''}`"
        :close-on-select="false"
        :search-visible="true"
        :taggable="($route.name === 'TagsAttach' ? true : false)"
        multiple
        infinite
        hide-selected 
        :blockKeys="['Delete']"
        @remove-item="removeTag"
        @select="selectTag"
        @tag="addTag"
        @infinite="tagsInfiniteHandler"
      />
    </div>
    <div class="side-panel-library-cta p-3">
      <b-btn block variant="primary" size="lg" :disabled="isSaving" @click="confirmTags">
        <svg class="btn-left" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-status-success"></use></svg>
        Apply Edits
      </b-btn>
    </div>
  </audience-slide-in-template>
</template>

<script>
  import moment from '@app2/utils/moment';
  import Tag from "@app2/models/Tag";
  import debounce from "lodash/debounce";
  import FormControlSelect from "@app2/core/Components/Common/Forms/Select.vue";
  import AudienceSlideInTemplate from "@app2/modules/Audience/Components/Template";
  import SelectedAudience from '@app2/modules/Audience/Bulk/Components/SelectedAudience'
  import eventBus from '@app2/core/eventBus'
  import rootVue from "@app2/app";

  export default {
    name: 'AudienceManageTags',
    data() {
      return {
        tagPage: 1,
        tagLast: null,
        tagIdentifier: +new Date(),
        tagList: [],
        audienceList: [],
        initial: true,
        bulkTags: [],
        isSaving: false,
        toSaveTags: [],
        tempTagCounter: 0
      }
    },
    components: {
      AudienceSlideInTemplate,
      FormControlSelect,
      SelectedAudience
    },
    computed: {
      tagOptions() {
        return Tag.query().where('type', 'audience').all();
      },
      selectedAudience() {
        return this.$store.getters['v2/audience/selectedAudience']
      },
      tagListCleaned () {
        return this.tagList.map(tag => {
          if (tag.$id) {
            return {
              name: tag.name,
              audiences: this.selectedAudience,
              originalAudiences: this.selectedAudience,
            }
          }
          return tag
        })
      }
    },
    watch: {
      tagList(val) {
        eventBus.$emit('audience-bulk-has-changed', !!val.length)
      }
    },
    destroyed() {
      eventBus.$off('reset-bulk-edit-settings');
      eventBus.$off('save-bulk-edit-settings');
    },
    async mounted() {
      // await Tag.api().fetchForAudiences({ params: { page: this.tagPage++, limit: 9999 } });
      // this.selectedAudience.forEach(audience => {
      //   let tags = audience.tags;
      //   if ( !tags || tags && !tags.length ) return;
      //   tags.forEach(tag => {
      //     if ( !this.tagList.filter(t => t.name === tag).length ) {
      //       const findTag = Tag.query().where('name', tag).first()
      //       this.tagList.push({
      //         id: findTag.id,
      //         name: findTag.name,
      //         audiences: [audience],
      //         originalAudiences: [audience]
      //       });
      //     } else {
      //       this.tagList.filter(t => t.name === tag)[0].audiences.push(audience);
      //       this.tagList.filter(t => t.name === tag)[0].originalAudiences.push(audience);
      //     }
      //   });
      // });

      // // this.setCommonTags()
      // if (this.$route.name === 'TagsDetach') {
      //   this.tagList = [];
      // }
      eventBus.$on('reset-bulk-edit-settings', () => {
        this.tagList = [];
        setTimeout(() => {
          rootVue.$bvModal.hide('bulk-dialog');
        }, 100)
      }),
      eventBus.$on('save-bulk-edit-settings', () => {
        eventBus.$emit('audience-bulk-has-changed', false);
        this.confirmTags();
      })
    },
    methods: {
      moment,
      async tagsInfiniteHandler($state) {
        try {
          const { response: { data } } = await Tag.api().fetchForAudiences({ params: { page: this.tagPage++, limit: 9999 } });
          if (data.data.length) {
            $state.loaded();
          } else {
            $state.complete();
          }
          this.tagLast = data.meta.last_page;
        } catch (e) {
          $state.error(e);
          throw e;
        }
      },
      resetTagId() {
        if (this.tagPage >= this.tagLast && this.tagLast)
          return;
        this.tagIdentifier = +new Date();
      },
      debouncer: debounce((cb, args) => cb(args), 500),
      async addTag(value) {
        await Tag.insertOrUpdate({
          data: { id: `create-${this.tempTagCounter}`, name: value, type: 'audience' }
        });
        this.tagList = [Tag.find(value), ...this.tagList];
        this.bulkTags = [...this.bulkTags, Tag.find(value)];
        this.tempTagCounter++

        this.toSaveTags = [...this.tagListCleaned, {
          audiences: this.selectedAudience,
          originalAudiences: this.selectedAudience,
          name: value
        }]
      },
      setInitial() {
        if ( this.initial ) {
          this.infiniteId++;
          this.initial = false;
        }
      },
      setCommonTags() {
        this.selectedAudience.forEach(a => {
          a.tags.forEach(tag => {
            const getTag = Tag.query().where('name', tag).first()
            this.bulkTags.push(getTag)
          })
        })
      },
      async confirmTags() {
        try {
          this.isSaving = true;
          // const tags = this.toSaveTags.map(tag => {
          //   return {
          //     audiences: tag.audiences?.map(a => a.id) || [],
          //     originalAudiences: tag.originalAudiences?.map(a => a.id) || [],
          //     name: tag.name
          //   }
          // })

          // if (this.$route.name === 'TagsAttach') {
          //   const { response: { data } } = await Tag.api().setForAudiences({ tags }); 
          //   data.forEach(a => {
          //     eventBus.$emit('update-audience', a)

          //     const findAudienceToUpdate = this.selectedAudience.findIndex(person => person.id === a.id)
          //     if(findAudienceToUpdate !== -1) {
          //       this.selectedAudience[findAudienceToUpdate] = a
          //       this.$store.commit('v2/audience/SET_SELECTED_AUDIENCE', this.selectedAudience)
          //     }
          //   })

          //   rootVue.$bvModal.hide('bulk-dialog')
          //   this.$toasted.show('Audience tag updated successfully!', { type: 'success' });
          // }

          // if (this.$route.name === 'TagsDetach') {
          //   const tagsToRemove = [], audiences = [];
          //   if (this.tagList) {
          //     for (let i = 0; i < this.tagList.length; i++) {
          //       tagsToRemove.push(this.tagList[i].name)
          //     }
          //     for (let k = 0; k < this.selectedAudience.length; k++) {
          //       let audience = this.selectedAudience[k];
          //       audiences.push(audience.id);
          //     }
              
          //     let res = await axios.post(`/api/internal/v2/tags/audiences/bulk-detach`, {audiences, audiences, tags: tagsToRemove})
          //     if (res && res.data && res.data.length) {
          //       res.data.map(data => eventBus.$emit('update-audience', data))
          //     }
              
          //     rootVue.$bvModal.hide('bulk-dialog')
          //     this.$toasted.show('Audience tag updated successfully!', { type: 'success' });
          //   }
          // }
          const tagsToSave = [], audiences = [];
          let route = this.$route.name === 'TagsAttach' ? 'attach' : 'detach';
          if (this.tagList) {
            for (let i = 0; i < this.tagList.length; i++) {
              tagsToSave.push(this.tagList[i].name)
            }
            for (let k = 0; k < this.selectedAudience.length; k++) {
              let audience = this.selectedAudience[k];
              audiences.push(audience.id);
            }
            
            let res = await axios.post(`/api/internal/v2/tags/audiences/bulk-${route}`, {audiences, audiences, tags: tagsToSave})
            if (res && res.data && res.data.length) {
              res.data.map(data => eventBus.$emit('update-audience', data))
            }
            
            rootVue.$bvModal.hide('bulk-dialog')
            this.$toasted.show('Audience tag updated successfully!', { type: 'success' });
          }

        } catch (error) {
          console.log('error: ', error)
          this.$toasted.show('Error while saving changes', { type: 'error' });
        }

        this.isSaving = false;
      },
      removeTag(tag) {
        tag.audiences = []
        this.toSaveTags = [...this.tagList, tag]
      },
      selectTag(tag) {
        // newly selected tag should add to all selected audience
        this.toSaveTags = [...this.tagListCleaned, {
          audiences: this.selectedAudience,
          originalAudiences: this.selectedAudience,
          name: tag.name
        }]
      }
    }
  }
</script>
