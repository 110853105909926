import Base from '@app2/models/Base';

export default class Sitemap extends Base {
  static entity = 'App\\Sitemap';

  static apiConfig = {
    baseURL: '/api/internal/v2/sitemaps/',
    actions: {
      fetch: {
        url: '/',
        dataKey: 'data'
      }
    }
  };

  watchFields = [
    'url',
  ];

  static fields() {
    return {
      id: this.attr(null),
      hash: this.attr(null),
      page_count: this.attr(0),
      status: this.attr('success'),
      url: this.attr(null),
      ...super.fields(),
    };
  }
}
