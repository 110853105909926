import Base from '@app/models/Base';
import File from "@app/models/File";
import Tag from "@app/models/Tag";
import MediaPlaylist from "@app/models/FilePlaylist";
import momentUTC from "@app/utils/moment";
import moment from 'moment';
import {set} from "@app/utils/helpers";

const formatDate = (model) => {
  if ( !model ) model = this;
  if ( !model.scheduled_at ) return;
  let time = momentUTC(model.scheduled_at).format('HH:mm');
  let date = momentUTC(model.scheduled_at).format( 'YYYY-MM-DD');
  set(model, 'scheduled_at', moment(`${date} ${time}`).utc().format('YYYY-MM-DD HH:mm:ss'))
}

export default class Playlist extends Base {
  static entity = 'App\\Playlist';

  static apiConfig = {
    baseURL: '/api/playlists/',
    actions: {
      fetch: {
        url: '/',
        dataKey: 'data',
      },
      uploadThumbnail(playlist, thumbnail) {
        const form = new FormData();
        form.append('thumbnail', thumbnail);
        return this.post(`${playlist}/thumbnail`, form)
      },
      removeThumbnail(playlist) {
        return this.delete(`${playlist}/thumbnail`);
      },
      duplicate(playlistObject) {
        return this.post(`${playlistObject.hash}/duplicate`, { title: playlistObject.title })
      }
    }
  };

  watchFields = ['title', 'description', 'meta', 'privacy', 'sort', 'showAttachments', 'attachments', 'tags', 'scheduled_at'];
  requiredFields = ['title'];

  static fields() {
    return {
      id: this.attr(null),
      hash: this.attr(null),
      team: this.attr(null),
      team_id: this.attr(null),
      user_id: this.attr(null),
      title: this.attr(null),
      description: this.attr(null),
      sort: this.attr(null),
      files: this.belongsToMany(File, MediaPlaylist, 'playlist_id', 'file_id'),
      startTime: this.attr(-1),
      autoplay: this.attr(false),
      order: this.attr(false),
      useDefault: this.attr(false),
      meta: this.attr(null),
      privacy: this.attr(null),
      video_count: this.attr(null),
      audio_count: this.attr(null),
      thumbnail: this.attr(null),
      disabled: this.attr(null),
      permissions: this.attr(null),
      sections: this.attr(null),
      attachments: this.attr([]),
      showAttachments: this.attr(null),
      tags: this.hasMany(Tag, 'playlist_hash', 'hash'),
      index_tags: this.attr(null),
      scheduled_at: this.attr(null),
      ...super.fields(),
    };
  }

  static mutators() {
    return {
      meta(value) {
        if ( !value || Array.isArray(value) ) return {};
        return value;
      },
      description(value) {
        if ( !value ) return '';
        return value;
      },
      sort(value) {
        if ( !value ) return 'position';
        return value;
      },
    };
  }
  get lowerTitle() {
    return (this.title) ? this.title.toLowerCase() : '';
  }

  get scheduleDate() {
    return this.scheduled_at ? momentUTC(this.scheduled_at).format( 'YYYY-MM-DD') : null;
  }
  set scheduleDate(value) {
    set(this, 'scheduled_at', moment(`${value} ${this.scheduleTime}`).utc().format('YYYY-MM-DD HH:mm:ss'))
  }

  get scheduleTime() {
    return this.scheduled_at ? momentUTC(this.scheduled_at).format('HH:mm') : '00:00';
  }
  set scheduleTime(value) {
    if ( !this.scheduleDate ) return;
    set(this, 'scheduled_at', moment(`${this.scheduleDate} ${value}`).utc().format('YYYY-MM-DD HH:mm:ss'))
  }

  reformatDate() {
    formatDate(this);
  }

  static afterCreate(model) {
    if ( !this ) return;
    formatDate(model);
  }
}
