var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" } },
    [
      _c("path", {
        attrs: {
          fill: "#F0F0F0",
          d: "M19 10c0 4.971-4.029 9-9 9s-9-4.029-9-9 4.029-9 9-9 9 4.029 9 9z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FF883E",
          d: "M18.995 10a9 9 0 00-5.87-8.44v16.88a9 9 0 005.87-8.44z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#169B62",
          d: "M1 10a9 9 0 005.87 8.44V1.56A9 9 0 001 10z"
        }
      }),
      _c("g", { attrs: { fill: "none", transform: "translate(1 1)" } }, [
        _c("circle", { attrs: { cx: "9", cy: "9", r: "9" } }),
        _c("circle", {
          attrs: { cx: "9", cy: "9", r: "9.5", stroke: "#E4E4E4" }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }