var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "media-play",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "53",
        height: "37",
        viewBox: "0 0 53 37"
      }
    },
    [
      _c("g", { attrs: { id: "Play", transform: "translate(0.001 0)" } }, [
        _c("rect", {
          attrs: {
            id: "Rectangle_267",
            "data-name": "Rectangle 267",
            width: "53",
            height: "37",
            rx: "18.5",
            fill: "#fff"
          }
        }),
        _vm._v(" "),
        _c(
          "g",
          {
            attrs: {
              id: "Play_Icon",
              "data-name": "Play Icon",
              transform: "translate(21.011 11.288)"
            }
          },
          [
            _c("path", {
              attrs: {
                id: "Play_Icon-2",
                "data-name": "Play Icon",
                d:
                  "M59.594,47.544a3.088,3.088,0,0,1,0,5.372l-6.613,3.749a3.089,3.089,0,0,1-4.613-2.686v-7.5a3.088,3.088,0,0,1,4.613-2.686Z",
                transform: "translate(-48.369 -43.392)",
                fill: "#293ad3"
              }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }