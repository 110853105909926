var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Rewards_Icon",
        xmlns: "http://www.w3.org/2000/svg",
        width: "23.71",
        height: "25.096",
        viewBox: "0 0 23.71 25.096"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M1196.709,274.86a3.158,3.158,0,0,0-.054-.582v-.006a3.1,3.1,0,0,0-.169-.565l-.011-.026-.006-.015a3.333,3.333,0,0,0-.861-1.112l-.006,0-.02-.015a3.4,3.4,0,0,0-4.523.346h0l-3.286,3.286-3.286-3.286h0a3.4,3.4,0,0,0-4.525-.345l-.011.008-.016.015a3.24,3.24,0,0,0-.337.327l-.017.017-.01.014a3.082,3.082,0,0,0,1.783,4.953v0l3.315.728h6.216l3.371-.741v0A3.057,3.057,0,0,0,1196.709,274.86Zm-15.038,1.545-.014,0a1.566,1.566,0,0,1-.68-.318,1.578,1.578,0,0,1-.249-2.216l.155-.15a1.875,1.875,0,0,1,1.144-.386,1.9,1.9,0,0,1,1.382.594l.014.014,3.653,3.653Zm12.276-.016-.018,0-5.465,1.2,3.653-3.653.015-.016a1.9,1.9,0,0,1,2.525-.206,1.806,1.806,0,0,1,.445.574,1.527,1.527,0,0,1,.108.568A1.562,1.562,0,0,1,1193.947,276.389Z",
          transform: "translate(-1177.592 -271.832)",
          fill: "#919ae8"
        }
      }),
      _vm._v(" "),
      _c("g", { attrs: { transform: "translate(-1177.592 -271.832)" } }, [
        _c("path", {
          attrs: {
            d:
              "M1184.036,293.178H1182.6a1.892,1.892,0,0,1-1.89-1.889v-7.174h14.251v1.963a.75.75,0,0,0,1.5,0V284.1a1.719,1.719,0,0,0,1.553-1.708v-1.6a3.39,3.39,0,0,0-3.39-3.391H1180.98a3.389,3.389,0,0,0-3.388,3.391v1.6a1.718,1.718,0,0,0,1.623,1.711v7.185a3.39,3.39,0,0,0,3.39,3.389h1.432a.749.749,0,1,0,0-1.5Zm-4.944-10.786v-1.6a1.892,1.892,0,0,1,1.888-1.891h13.648a1.894,1.894,0,0,1,1.89,1.891v1.6a.225.225,0,0,1-.225.225h-16.975A.226.226,0,0,1,1179.092,282.392Z",
            fill: "#5060f0"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1200.184,289.147a.741.741,0,0,0-.735-.748l-.015,0h-2.839a.75.75,0,0,0,0,1.5h1.029l-3.032,3.031-2.547-2.547a.749.749,0,0,0-1.061,0l-3.709,3.709a.75.75,0,0,0,1.061,1.061h0l3.178-3.179,2.547,2.548a.752.752,0,0,0,1.061,0l3.562-3.562v1.028a.75.75,0,0,0,1.5,0Z",
            fill: "#5060f0"
          }
        })
      ]),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M1188.445,279.134h0Z",
          transform: "translate(-1177.592 -271.832)",
          fill: "#919ae8"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M1188.555,288.646v-11.6a.75.75,0,0,0-1.5,0v11.6a.75.75,0,0,0,1.5,0",
          transform: "translate(-1177.592 -271.832)",
          fill: "#919ae8"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }