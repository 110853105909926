<template>
  <svg class="icon-section-cta" xmlns="http://www.w3.org/2000/svg" width="18.863" height="18.861" viewBox="0 0 18.863 18.861">
    <path d="M751.683,1069.331a9.43,9.43,0,1,0,9.431,9.431A9.431,9.431,0,0,0,751.683,1069.331Zm5.608,15.039a7.928,7.928,0,1,1,2.324-5.608A7.908,7.908,0,0,1,757.291,1084.37Z" transform="translate(-742.251 -1069.331)" fill="#939393"/><path d="M751.682,1073.679a5.083,5.083,0,1,0,5.084,5.083A5.084,5.084,0,0,0,751.682,1073.679Zm0,8.667a3.584,3.584,0,1,1,3.584-3.584A3.576,3.576,0,0,1,751.682,1082.346Z" transform="translate(-742.251 -1069.331)" fill="#939393"/><circle cx="1.416" cy="1.416" r="1.416" transform="translate(8.015 8.016)" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-section-cta'
  }
</script>
