import { Model } from '@vuex-orm/core';
import { timestamps } from '@app2/utils/model-fields';
import File from '@app2/models/File';

export default class Transcription extends Model {
  static entity = 'App\\Transcription';


  static apiConfig = {
    baseURL: '/api/transcriptions/'
  };

  static fields() {
    return {
      id: this.attr(null),
      file_id: this.attr(null),
      file: this.belongsTo(File, 'media_id'),
      transcription: this.attr(null),
      start_time: this.attr(null),
      score: this.attr(null),
      end_time: this.attr(null),
      highlight: this.attr(null),
      created_at_local: this.attr(null),
      ...timestamps,
    };
  }

  static beforeCreate(model) {
    if (model.id === null) {
      return false;
    }
  }

  static handleResponse(responseData) {
    if (!responseData || !responseData.data || !responseData.path || !responseData.path.includes('/transcription')) return;
    this.insertOrUpdate({ data: responseData.data });
  }
}
