<template>
  <div class="form-control-checkbox">
    <b-checkbox v-model="model" :unchecked-value="uncheckedValue" :disabled="disabled">
      <span class="form-control-checkbox-slot" v-if="label || description">
        <span :class="labelClass" v-if="label">{{ label }}</span>
        <span :class="descriptionClass" v-if="description"><br />{{ description }}</span>
      </span>
    </b-checkbox>
  </div>
</template>
<script>
  export default {
    name: 'FormControlCheckbox',
    props: {
      value: {},
      label: {},
      description: {},
      uncheckedValue: {},
      disabled: Boolean,
      descriptionClass: {
        default: "txt-body txt-muted"
      },
      labelClass: {
        default: "txt-body"
      }
    },
    computed: {
      model: {
        get() { return this.value },
        set(val) { this.$emit('input', val) }
      }
    }
  }
</script>
