var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" } },
    [
      _c("path", {
        attrs: {
          fill: "#DA0000",
          d:
            "M18.073 13.96h-.423v-.28h-.46v.3h-.1v-.4h.66v.28h.371c.029-.06.059-.119.087-.18h-.298v-.1h.346l.075-.18h-1.26v-.1h1.297c.037-.093.069-.187.103-.281V13h-.14v.05h-1.26V13h-.14v.05h-1.26V13h-.14v.05h-1.26V13h-.14v.05h-1.26V13h-.14v.05h-1.26V13h-.14v.05h-1.26V13H9.93v.05H8.67V13h-.14v.05H7.27V13h-.14v.05H5.87V13h-.14v.05H4.47V13h-.14v.05H3.07V13h-.14v.05H1.67V13h-.14v.019c.034.094.066.188.103.281h.457v.1h-.42l.075.18h.505v-.3h.1v.58h.18v-.58h.1v.58h.18v-.58h.1v.68h-.66v-.28h-.458c.027.061.058.12.087.18h.211v.1h-.163C3.393 16.942 6.452 19 10 19s6.607-2.058 8.073-5.04zm-.723-.1h.14v.1h-.14v-.1zM3.07 13.3h1.82v.1H3.07v-.1zm.42.66h-.14v-.1h.14v.1zm.82 0h-.66v-.28h-.46v.3h-.1v-.4h.66v.28h.46v-.18h-.3v-.1h.4v.38zm1.4 0h-.66v-.28h-.46v.18h.3v.1h-.4v-.38h.56v-.3h.1v.58h.18v-.58h.1v.58h.18v-.58h.1v.68zm.16-.66h1.82v.1H5.87v-.1zm.42.66h-.14v-.1h.14v.1zm.82 0h-.66v-.28h-.46v.3h-.1v-.4h.66v.28h.46v-.18h-.3v-.1h.4v.38zm1.4 0h-.66v-.28h-.46v.18h.3v.1h-.4v-.38h.56v-.3h.1v.58h.18v-.58h.1v.58h.18v-.58h.1v.68zm.58 0h-.14v-.1h.14v.1zm.82 0h-.66v-.28h-.46v.3h-.1v-.4h.66v.28h.46v-.18h-.3v-.1h.4v.38zm-1.24-.56v-.1h1.82v.1H8.67zm2.64.56h-.66v-.28h-.46v.18h.3v.1h-.4v-.38h.56v-.3h.1v.58h.18v-.58h.1v.58h.18v-.58h.1v.68zm.16-.66h1.82v.1h-1.82v-.1zm.42.66h-.14v-.1h.14v.1zm.82 0h-.66v-.28h-.46v.3h-.1v-.4h.66v.28h.46v-.18h-.3v-.1h.4v.38zm1.4 0h-.66v-.28h-.46v.18h.3v.1h-.4v-.38h.56v-.3h.1v.58h.18v-.58h.1v.58h.18v-.58h.1v.68zm.16-.66h1.82v.1h-1.82v-.1zm.42.66h-.14v-.1h.14v.1zm.82 0h-.66v-.28h-.46v.3h-.1v-.4h.66v.28h.46v-.18h-.3v-.1h.4v.38zm.28-.28v.18h.3v.1h-.4v-.38h.56v-.3h.1v.58h.18v-.58h.1v.58h.18v-.58h.1v.68h-.66v-.28h-.46z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M1.53 13h.14v-.05h1.26V13h.14v-.05h1.26V13h.14v-.05h1.26V13h.14v-.05h1.26V13h.14v-.05h1.26V13h.14v-.05h1.26V13h.14v-.05h1.26V13h.14v-.05h1.26V13h.14v-.05h1.26V13h.14v-.05h1.26V13h.14v-.05h1.26V13h.14v-.05h1.26V13h.14v-.05h.025c.322-.925.505-1.915.505-2.95s-.183-2.025-.505-2.95h-.025V7h-.14v.05h-1.26V7h-.14v.05h-1.26V7h-.14v.05h-1.26V7h-.14v.05h-1.26V7h-.14v.05h-1.26V7h-.14v.05h-1.26V7h-.14v.05H8.67V7h-.14v.05H7.27V7h-.14v.05H5.87V7h-.14v.05H4.47V7h-.14v.05H3.07V7h-.14v.05H1.67V7h-.14v.05h-.025C1.183 7.975 1 8.965 1 10s.183 2.025.505 2.95h.025V13zm10.489-3.86a1.718 1.718 0 01-.628 2.348l.004-.005a2.25 2.25 0 00-.446-3.151c.451.114.837.405 1.07.808zM9.433 7.823A.307.307 0 0010 7.92a.306.306 0 00.567-.097.325.325 0 01-.567.307.325.325 0 01-.567-.307zm.369 3.623l-.068-1.672V8.452A.702.702 0 0010 8.229c.068.095.16.172.266.223v1.323l-.068 1.672a2.245 2.245 0 00.541-3.072 2.052 2.052 0 01-.381 3.368c.279.102.575.154.875.14-.354.115-.715.12-1.055.048l-.003.079-.175.24-.175-.241-.003-.079c-.34.072-.701.067-1.055-.048.3.014.596-.038.875-.14a2.052 2.052 0 01-.381-3.368 2.246 2.246 0 00.541 3.072zM7.981 9.14a1.718 1.718 0 011.07-.807 2.25 2.25 0 00-.446 3.151l.004.005a1.72 1.72 0 01-.628-2.349z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#239F40",
          d:
            "M1.67 7v-.05h1.26V7h.14v-.05h1.26V7h.14v-.05h1.26V7h.14v-.05h1.26V7h.14v-.05h1.26V7h.14v-.05h1.26V7h.14v-.05h1.26V7h.14v-.05h1.26V7h.14v-.05h1.26V7h.14v-.05h1.26V7h.14v-.05h1.26V7h.14v-.05h1.26V7h.14v-.019c-.052-.146-.101-.294-.16-.437V6.7h-.66v-.28h-.46v.3h-.1v-.4h.66v.28h.46v-.18h-.3v-.1h.298c-.027-.061-.058-.12-.087-.18H17.07v-.1h1.003C16.607 3.058 13.548 1 10 1S3.393 3.058 1.927 6.04h.163v.1h-.211c-.028.06-.059.119-.087.18h.458v-.3h.1v.58h.18v-.58h.1v.58h.18v-.58h.1v.68h-.66v-.28h-.46v.18h.3v.1h-.4v-.156c-.059.143-.108.291-.16.437V7h.14zm15.68-.4h.14v.1h-.14v-.1zm-2.66.1h-.14v-.1h.14v.1zm.82 0h-.66v-.28h-.46v.3h-.1v-.4h.66v.28h.46v-.18h-.3v-.1h.4v.38zm.74-.68h.1v.58h.18v-.58h.1v.58h.18v-.58h.1v.68h-.66v-.28h-.46v.18h.3v.1h-.4v-.38h.56v-.3zm-1.98.02h1.82v.1h-1.82v-.1zm-2.38.66h-.14v-.1h.14v.1zm.82 0h-.66v-.28h-.46v.3h-.1v-.4h.66v.28h.46v-.18h-.3v-.1h.4v.38zm.74-.68h.1v.58h.18v-.58h.1v.58h.18v-.58h.1v.68h-.66v-.28h-.46v.18h.3v.1h-.4v-.38h.56v-.3zm-1.98.02h1.82v.1h-1.82v-.1zm-2.38.66h-.14v-.1h.14v.1zm.82 0h-.66v-.28h-.46v.3h-.1v-.4h.66v.28h.46v-.18h-.3v-.1h.4v.38zm.74-.68h.1v.58h.18v-.58h.1v.58h.18v-.58h.1v.68h-.66v-.28h-.46v.18h.3v.1h-.4v-.38h.56v-.3zm-1.98.02h1.82v.1H8.67v-.1zm-2.38.66h-.14v-.1h.14v.1zm.82 0h-.66v-.28h-.46v.3h-.1v-.4h.66v.28h.46v-.18h-.3v-.1h.4v.38zm.74-.68h.1v.58h.18v-.58h.1v.58h.18v-.58h.1v.68h-.66v-.28h-.46v.18h.3v.1h-.4v-.38h.56v-.3zm-1.98.02h1.82v.1H5.87v-.1zm-2.38.66h-.14v-.1h.14v.1zm.82 0h-.66v-.28h-.46v.3h-.1v-.4h.66v.28h.46v-.18h-.3v-.1h.4v.38zm.74-.68h.1v.58h.18v-.58h.1v.58h.18v-.58h.1v.68h-.66v-.28h-.46v.18h.3v.1h-.4v-.38h.56v-.3zm-1.98.02h1.82v.1H3.07v-.1z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M2.09 6.7v-.1h-.3v-.18h.46v.28h.66v-.68h-.1v.58h-.18v-.58h-.1v.58h-.18v-.58h-.1v.3h-.458c-.033.075-.07.148-.102.224V6.7h.4z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M2.09 6.04h-.163l-.048.1h.211v-.1zM1.53 7v-.019l-.025.069h.025V7zM1.67 6.95v.1h1.26v-.1zM1.53 12.95h-.025l.025.069v-.069zM1.67 12.95v.1h1.26v-.1zM2.09 13.3h-.457c.013.033.023.067.037.1h.42v-.1z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M2.25 13.96h.66v-.68h-.1v.58h-.18v-.58h-.1v.58h-.18v-.58h-.1v.3h-.506c.015.034.033.066.048.1h.458v.28z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M2.09 13.86h-.211l.048.1h.163v-.1zM3.91 6.42h.3v.18h-.46v-.28h-.66v.4h.1v-.3h.46v.28h.66v-.38h-.4zM3.07 6.04h1.82v.1H3.07z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M3.35 6.6h.14v.1h-.14zM4.49 6.7h.4v-.1h-.3v-.18h.46v.28h.66v-.68h-.1v.58h-.18v-.58h-.1v.58h-.18v-.58h-.1v.3h-.56zM3.07 6.95v.1h1.26v-.1zM4.47 6.95v.1h1.26v-.1zM4.47 12.95v.1h1.26v-.1zM3.07 12.95v.1h1.26v-.1zM5.61 13.86h-.18v-.58h-.1v.58h-.18v-.58h-.1v.3h-.56v.38h.4v-.1h-.3v-.18h.46v.28h.66v-.68h-.1zM3.35 13.86h.14v.1h-.14z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M3.91 13.68h.3v.18h-.46v-.28h-.66v.4h.1v-.3h.46v.28h.66v-.38h-.4zM3.07 13.3h1.82v.1H3.07zM7.29 6.7h.4v-.1h-.3v-.18h.46v.28h.66v-.68h-.1v.58h-.18v-.58h-.1v.58h-.18v-.58h-.1v.3h-.56zM6.71 6.42h.3v.18h-.46v-.28h-.66v.4h.1v-.3h.46v.28h.66v-.38h-.4z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M6.15 6.6h.14v.1h-.14zM5.87 6.04h1.82v.1H5.87zM7.27 6.95v.1h1.26v-.1zM5.87 6.95v.1h1.26v-.1zM5.87 12.95v.1h1.26v-.1zM7.27 12.95v.1h1.26v-.1zM5.87 13.3h1.82v.1H5.87zM6.15 13.86h.14v.1h-.14z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M8.41 13.86h-.18v-.58h-.1v.58h-.18v-.58h-.1v.3h-.56v.38h.4v-.1h-.3v-.18h.46v.28h.66v-.68h-.1zM6.71 13.68h.3v.18h-.46v-.28h-.66v.4h.1v-.3h.46v.28h.66v-.38h-.4zM10.09 6.7h.4v-.1h-.3v-.18h.46v.28h.66v-.68h-.1v.58h-.18v-.58h-.1v.58h-.18v-.58h-.1v.3h-.56z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M8.67 6.04h1.82v.1H8.67zM9.51 6.42h.3v.18h-.46v-.28h-.66v.4h.1v-.3h.46v.28h.66v-.38h-.4z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M8.95 6.6h.14v.1h-.14zM10.07 6.95v.1h1.26v-.1zM8.67 6.95v.1h1.26v-.1zM10.07 12.95v.1h1.26v-.1zM8.67 12.95v.1h1.26v-.1zM9.51 13.68h.3v.18h-.46v-.28h-.66v.4h.1v-.3h.46v.28h.66v-.38h-.4zM11.21 13.86h-.18v-.58h-.1v.58h-.18v-.58h-.1v.3h-.56v.38h.4v-.1h-.3v-.18h.46v.28h.66v-.68h-.1z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M8.67 13.3h1.82v.1H8.67zM8.95 13.86h.14v.1h-.14zM11.75 6.6h.14v.1h-.14zM11.47 6.04h1.82v.1h-1.82z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M12.89 6.7h.4v-.1h-.3v-.18h.46v.28h.66v-.68h-.1v.58h-.18v-.58h-.1v.58h-.18v-.58h-.1v.3h-.56zM12.31 6.42h.3v.18h-.46v-.28h-.66v.4h.1v-.3h.46v.28h.66v-.38h-.4zM12.87 6.95v.1h1.26v-.1zM11.47 6.95v.1h1.26v-.1zM12.87 12.95v.1h1.26v-.1zM11.47 12.95v.1h1.26v-.1zM12.31 13.68h.3v.18h-.46v-.28h-.66v.4h.1v-.3h.46v.28h.66v-.38h-.4zM11.47 13.3h1.82v.1h-1.82z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M11.75 13.86h.14v.1h-.14zM14.01 13.86h-.18v-.58h-.1v.58h-.18v-.58h-.1v.3h-.56v.38h.4v-.1h-.3v-.18h.46v.28h.66v-.68h-.1zM15.69 6.7h.4v-.1h-.3v-.18h.46v.28h.66v-.68h-.1v.58h-.18v-.58h-.1v.58h-.18v-.58h-.1v.3h-.56z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M14.27 6.04h1.82v.1h-1.82zM15.11 6.42h.3v.18h-.46v-.28h-.66v.4h.1v-.3h.46v.28h.66v-.38h-.4z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M14.55 6.6h.14v.1h-.14zM14.27 6.95v.1h1.26v-.1zM15.67 6.95v.1h1.26v-.1zM15.67 12.95v.1h1.26v-.1zM14.27 12.95v.1h1.26v-.1zM16.91 13.96v-.68h-.1v.58h-.18v-.58h-.1v.58h-.18v-.58h-.1v.3h-.56v.38h.4v-.1h-.3v-.18h.46v.28zM15.11 13.68h.3v.18h-.46v-.28h-.66v.4h.1v-.3h.46v.28h.66v-.38h-.4z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M14.55 13.86h.14v.1h-.14zM14.27 13.3h1.82v.1h-1.82zM17.07 6.14h1.051l-.048-.1H17.07v.1zM17.91 6.42h.3v.18h-.46v-.28h-.66v.4h.1v-.3h.46v.28h.66v-.156c-.032-.076-.069-.149-.102-.224h-.298v.1z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M17.35 6.6h.14v.1h-.14zM18.47 7.05h.025l-.025-.069v.069zM17.07 6.95v.1h1.26v-.1zM17.07 12.95v.1h1.26v-.1zM18.47 13v.019l.025-.069h-.025V13zM17.75 13.58h-.66v.4h.1v-.3h.46v.28h.423l.048-.1h-.371v-.28z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M17.35 13.86h.14v.1h-.14zM17.07 13.4h1.26l.037-.1H17.07v.1zM17.91 13.68h.298c.015-.034.033-.066.048-.1h-.346v.1z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#DA0000",
          d:
            "M9.642 11.742a2.243 2.243 0 01-.875.14c.354.115.715.12 1.055.048l-.005-.112c-.059-.025-.119-.046-.175-.076zM10.358 11.742a2.052 2.052 0 00.381-3.368 2.246 2.246 0 01-.541 3.072l-.009.233c.054.026.113.042.169.063z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#DA0000",
          d:
            "M11.395 11.483l-.004.005a1.718 1.718 0 00-.443-3.155 2.249 2.249 0 01.447 3.15zM10.033 11.593c.052.029.102.062.156.086l.009-.233.068-1.672V8.452A.702.702 0 0110 8.229L10.112 10l-.079 1.593zM10 12.25l.175-.241.003-.079c-.054-.011-.108-.018-.161-.033L10 12.25zM10 8.13a.324.324 0 00.567-.307.307.307 0 01-.567.097l.045.168L10 8.13zM8.648 10.564c.172.528.539.937.994 1.178.056.03.117.051.176.075l-.015-.371a2.245 2.245 0 01-.541-3.072 2.051 2.051 0 00-.614 2.19z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#DA0000",
          d:
            "M10.033 11.593l-.015.303c.052.016.107.022.161.033.34.072.701.067 1.055-.048-.3.014-.596-.038-.875-.14-.057-.021-.115-.037-.17-.063-.054-.023-.104-.056-.156-.085zM8.609 11.488l-.004-.005a2.25 2.25 0 01.446-3.15 1.718 1.718 0 00-.442 3.155z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#DA0000",
          d:
            "M9.817 11.818l.005.112.003.079.175.241.018-.353.015-.303.079-1.594L10 8.229a.702.702 0 01-.266.223v1.323l.068 1.672.015.371zM10 8.13l.045-.042L10 7.92a.306.306 0 01-.567-.097.325.325 0 00.567.307z"
        }
      }),
      _c("g", { attrs: { fill: "none" } }, [
        _c("circle", { attrs: { cx: "10", cy: "10", r: "9" } }),
        _c("circle", {
          attrs: { cx: "10", cy: "10", r: "9.5", stroke: "#E4E4E4" }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }