var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-element-image",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18.032",
        height: "15.198",
        viewBox: "0 0 18.032 15.198"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M15.496 0H2.536A2.535 2.535 0 0 0 0 2.536v10.126a2.536 2.536 0 0 0 2.536 2.536h12.96a2.536 2.536 0 0 0 2.536-2.536V2.539A2.535 2.535 0 0 0 15.496 0ZM2.536 1.5h12.96a1.037 1.037 0 0 1 1.036 1.036v9.121l-2.855-3.018a.748.748 0 0 0-1.089 0l-1.644 1.741L5.89 5.038a.752.752 0 0 0-1.09 0L1.5 8.523V2.539A1.039 1.039 0 0 1 2.536 1.5Zm0 12.2a1.039 1.039 0 0 1-1.037-1.036v-1.956l3.842-4.061 5.054 5.342a.75.75 0 0 0 1.089 0l1.648-1.742 3.025 3.2a1.018 1.018 0 0 1-.661.256Z",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M11.597 7.179a2.118 2.118 0 1 0-2.119-2.118 2.118 2.118 0 0 0 2.119 2.118Zm0-2.738a.619.619 0 1 1-.619.62.62.62 0 0 1 .619-.622Z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }