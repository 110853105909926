import Campaign from "@app2/modules/AccountSettings/Pages/Routes/Subscription/Helpers/subscription.campaign.js";
import get from 'lodash/get';
import set from 'lodash/set';
import store from "@app2/store";

// Function to handle trial/checkout logic
const handleTrialCheckout = async (next, onboardStep, trialHash, query) => {
    if (!store.getters['auth/GET_PLANS'].find(pl => pl.hash === trialHash)) {
        await store.dispatch('auth/fetchPlan', trialHash);
    }
    const plan = store.getters['auth/GET_PLANS'].find(pl => pl.hash === trialHash);
    set(window, 'Searchie.checkout', plan);
    if (plan && plan.price > 0 && !get(plan, 'metadata.checkout_information.no_cc', false)) {
        // Redirect to the appropriate route based on onboard step
        next({ name: onboardStep === 0 ? 'OnboardingDetails' : onboardStep === 1 ? 'OnboardingPlan' : 'OnboardingMigration', query });
        return true;
    }
    return false
};

// Function to handle promo logic
const handlePromo = async (next, onboardStep, promoHash, query) => {
    const campaign = new Campaign(promoHash);
    await campaign.initialize();
    if (campaign.canCheckout) {
        // Redirect to the appropriate route based on onboard step
        next({ name: onboardStep === 0 ? 'OnboardingDetails' : onboardStep === 1 ? 'OnboardingPlan' : 'OnboardingMigration', query });
        return true;
    }
    return false;
};

export const onboardGuard = async (to, from, next) => {
    // Get necessary information from the user and the store
    const user = get(store, 'state.auth.user');
    const onboardStep = get(user, 'onboard_step', null);
    const isOnboard = get(user, 'is_onboard', false);
    if ( isOnboard ) {
        next();
        return;
    }
    const trialHash = get(user, 'meta.trial_hash', null);
    const promoHash = get(user, 'meta.promo_hash') || get(user, 'team.meta.latest_campaign.hash');
    const couponCode = get(user, 'meta.checkout_coupon');
    const checkoutInformation = get(store, 'state.auth.user.chargebee_primary_payment_source');
    const campaignActiveSubscriptionStatus = get(store, 'state.auth.user.chargebee_active_subscription.status');

    // Prepare common query parameters
    const query = {
        checkout: trialHash,
        promo_hash: promoHash,
    };

    if ( couponCode ) query.coupon = couponCode;

    let isHandled = false;

    // Check if user is in a trial and requires additional information
    if (trialHash && !checkoutInformation) {
        isHandled = await handleTrialCheckout(next, onboardStep, trialHash, query);
        if (isHandled) return;
    }

    // Check if there's an active promo campaign
    if (promoHash && campaignActiveSubscriptionStatus !== 'cancelled') {
        isHandled = await handlePromo(next, onboardStep, promoHash, query);
        if (isHandled) return;
    }

    // Determine the appropriate route based on onboard step
    if (onboardStep === 0) {
        next({ name: 'OnboardingDetails', query });
    } else if (onboardStep === 1) {
        next({ name: 'OnboardingMigration', query });
    } else {
        // User is onboarded, allow navigation to the target route
        next();
    }
};