<template>
	<svg class="icon-typography" xmlns="http://www.w3.org/2000/svg" width="18.284" height="12.122" viewBox="0 0 18.284 12.122">
	  <path d="M17.849 10.146a1.368 1.368 0 01-.138-.578V5.98a2.914 2.914 0 00-.731-2.21 3.436 3.436 0 00-2.4-.69 13.532 13.532 0 00-3.51.5l.069 1.6 3.243-.137a1.224 1.224 0 01.776.2.913.913 0 01.241.733v.535l-1.846.139a4.151 4.151 0 00-2.277.68 2.339 2.339 0 00-.742 1.958q0 2.831 2.674 2.829a5.724 5.724 0 002.536-.6 3.161 3.161 0 001.061.484 6.5 6.5 0 001.406.12l.069-1.741a.645.645 0 01-.431-.234zM15.4 9.965l-.259.068a5.544 5.544 0 01-1.432.207q-.845 0-.845-.948a.88.88 0 010-.136.935.935 0 01.97-.9l1.57-.139zM0 12.114h2.454l.946-2.1h3.532l-.072 2.1h2.46L9.694-.002H5.449zm7.274-9.978l-.25 5.738h-2.69L6.9 2.136z" fill="#939393"/>
	</svg>
</template>
<script>
  export default {
    name: 'IconTypography',
  };
</script>
