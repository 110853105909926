<template>
  <svg class="icon-day" xmlns="http://www.w3.org/2000/svg" width="16.764" height="16.651" viewBox="0 0 16.764 16.651">
  <g fill="currentColor">
    <path d="M12.504 1.926h-.376V.75a.75.75 0 00-1.5 0v1.176H6.137V.75a.75.75 0 00-1.5 0v1.176H4.26A4.26 4.26 0 000 6.186v6.206a4.259 4.259 0 004.26 4.259h8.244a4.26 4.26 0 004.26-4.259V6.186a4.26 4.26 0 00-4.26-4.26zm2.76 10.466a2.764 2.764 0 01-2.76 2.76H4.26a2.764 2.764 0 01-2.761-2.76V6.186A2.765 2.765 0 014.26 3.425h.377v1.178a.75.75 0 001.5 0V3.425h4.491v1.178a.75.75 0 001.5 0V3.425h.376a2.766 2.766 0 012.76 2.761z"/>
    <path d="M4.865 6.556a.75.75 0 00-.75.75v1.019a.75.75 0 001.5 0V7.306a.75.75 0 00-.75-.75z"/>
  </g>
</svg>
</template>
<script>
  export default {
    name: 'IconDay',
  };
</script>
