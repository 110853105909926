var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { role: "form" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.stepNext($event)
          }
        }
      },
      [
        _c("form-control-text", {
          staticClass: "mb-3 mt-4",
          class: { "is-invalid": _vm.$v.$error },
          attrs: {
            id: "name",
            name: "name",
            placeholder: "Type here...",
            label: "Your name",
            type: "text",
            icon: "user-single"
          },
          on: {
            change: function($event) {
              return _vm.$v.name.$touch()
            }
          },
          model: {
            value: _vm.name,
            callback: function($$v) {
              _vm.name = $$v
            },
            expression: "name"
          }
        }),
        _vm._v(" "),
        _c(
          "b-alert",
          { attrs: { show: _vm.$v.$error, fade: "", variant: "warning mb-3" } },
          [
            _c(
              "svg",
              {
                staticClass: "alert-icon icon-sm",
                attrs: { viewBox: "0 0 24 24" }
              },
              [
                _c("use", {
                  attrs: { "xlink:href": "#icon-warning-triangle-color" }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "alert-content" }, [
              !_vm.$v.name.required
                ? _c("span", { staticClass: "txt-body" }, [
                    _vm._v(_vm._s(_vm.error))
                  ])
                : _vm._e()
            ])
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "onboarding-cta pt-4 my-4" },
      [_vm._t("back"), _vm._v(" "), _vm._t("action")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }