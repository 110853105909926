import Base from '@app2/models/Base';

export default class Segment extends Base {
  static entity = 'App\\Segment';

  static primaryKey = 'hash';

  static apiConfig = {
    baseURL: "/api/internal/v2/segments/",
    actions: {
      fetch: {
        url: '/',
        dataKey: 'data'
      },
    }
  };

  watchFields = ['title', 'groups', 'logic', 'hidden'];

  requiredFields = ['title', 'logic', 'preview'];

  static fields() {
    return {
      id: this.attr(null),
      title: this.attr(null),
      groups: this.attr([]),
      hash: this.attr(null),
      audiences: this.attr([]),
      audiences_count: this.attr(0),
      hidden: this.attr(null),
      hubs: this.attr(null),
      preview: this.attr(false),
      logic: this.string('and'),
      type: this.string('audience'),
      page: this.attr(null),
      permissions: this.attr({}),
      show_errors: this.attr(false),
      ...super.fields(),
    };
  }
  get lowerTitle() {
    return (this.title) ? this.title.toLowerCase() : '';
  }
  get hasEmptyGroups() {
    return !!this.groups.filter(gr => !gr.conditions.length).length;
  }
  get hasRelativeDateError() {
    for (let i = 0; i < this.groups.length; i++) {
      for (let j = 0; j < this.groups[i].conditions.length; j++) {
        const value = this.groups[i].conditions[j].value;
        const operator = this.groups[i].conditions[j].operator
        if(['more_than', 'exactly', 'less_than'].includes(operator) && parseInt(value) === 0) {
          return true;
        }
      }
    }
    return false;
  }
  get hasEmailError() {
    let emails = []
    function validateEmail(email) {
        const emailrgx = /\S+@\S+\.\S*[a-zA-Z]$/;
        return emailrgx.test(email);
    }
    for (let i = 0; i < this.groups.length; i++) {
      for (let j = 0; j < this.groups[i].conditions.length; j++) {
        const condition = this.groups[i].conditions[j].condition;
        const value = this.groups[i].conditions[j].value;
        const operator = this.groups[i].conditions[j].operator
        if(condition === 'email' && (operator === 'equals' || operator === 'not_equals')) {
          emails.push(value)
        }
      }
    }
    if(emails.every(validateEmail)) return false
    return true;
  }
  get hasLessThanZeroError() {
    for (let i = 0; i < this.groups.length; i++) {
      for (let j = 0; j < this.groups[i].conditions.length; j++) {
        const value = this.groups[i].conditions[j].value;
        const operator = this.groups[i].conditions[j].operator
        let type = this.groups[i].conditions[j].type
        if(['equals', 'lt'].includes(operator) && parseInt(value) <= 0 && type !== 'attribute_number') {
          return true;
        }
      }
    }
    return false;
  }

  get hasValidationErrors() {
    return this.hasRelativeDateError || this.hasEmailError || this.hasLessThanZeroError;
  }
  get conditionsCount() {
    return this.groups.map(g => g.conditions.length).reduce((a, b) => a + b, 0);
  }
  get hasEmptyConditions() {
    for (let i = 0; i < this.groups.length; i++) {
      for (let j = 0; j < this.groups[i].conditions.length; j++) {
        let value = this.groups[i].conditions[j].value;
        if ( typeof value === "string" ) value = value.replace(/\s+/g, '');
        if (
          (!value || value === '') &&
          !['null', 'not_null'].includes(this.groups[i].conditions[j].operator) &&
          this.groups[i].conditions[j].type !== 'attribute_boolean' &&
          !(this.groups[i].conditions[j].type === 'attribute_number' && value === 0)
        ) return true;
      }
    }
    return false;
  }
  get isInvalid() {
    return this.hasEmptyConditions || this.hasEmptyGroups || this.hasValidationErrors;
  }
}
