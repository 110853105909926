import { Mark } from 'prosemirror-to-html-js';
export default class Strikethrough extends Mark {
  matching () {
    return this.mark.type === "strike";
  }

  tag () {
    return "s";
  }
}
