var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "modal",
      staticClass: "playlist-form-slide-in",
      attrs: {
        id: "playlist-form-dialog",
        "modal-class": "modal-right",
        static: "",
        "hide-footer": "",
        "hide-header": ""
      },
      on: { show: _vm.init, hide: _vm.onHide }
    },
    [
      _vm.show
        ? _c(
            "div",
            { attrs: { id: "hub-playlist-form" } },
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [_c("router-view")],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }