var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "14.028",
        height: "13.192",
        viewBox: "0 0 14.028 13.192"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M302.278,365.692H289.75a.75.75,0,0,0,0,1.5h12.528a.75.75,0,0,0,0-1.5Z",
          transform: "translate(-289 -354)",
          fill: "#293ad3"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M4.726,10.774a.75.75,0,0,0,1.061,0l4.506-4.506a.75.75,0,0,0-1.06-1.06h0L6.007,8.432V.75a.75.75,0,0,0-1.5,0V8.433L1.281,5.207A.75.75,0,0,0,.221,6.269Z",
          transform: "translate(12.27 10.994) rotate(180)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }