var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "64",
        height: "54",
        viewBox: "0 0 64 54",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M22.62 13c-.9 0-1.62.72-1.62 1.62v12.76c0 .89.72 1.62 1.62 1.62.25 0 .5-.06.72-.17l12.76-6.38c.55-.28.9-.84.9-1.45s-.35-1.17-.89-1.45l-12.76-6.38c-.23-.11-.48-.17-.73-.17z",
          stroke: "#fff",
          "stroke-width": "2",
          "stroke-miterlimit": "10"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M29.26 41H5c-2.21 0-4-1.79-4-4V5c0-2.21 1.79-4 4-4h48c2.21 0 4 1.79 4 4v16.43",
          stroke: "#fff",
          "stroke-width": "2",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M48 53c8.2843 0 15-6.7157 15-15s-6.7157-15-15-15-15 6.7157-15 15 6.7157 15 15 15z",
          stroke: "#D75050",
          "stroke-width": "2",
          "stroke-miterlimit": "10"
        }
      }),
      _c("path", {
        attrs: {
          d: "M43 33l10 10M43 43l10-10M35.28 45.95l-11.27 7.04",
          stroke: "#D75050",
          "stroke-width": "2",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }