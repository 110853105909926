const namespaced = true;

const state = {
  modalState: false,
  activeQuery: null,
  dateRange: null,
};

const mutations = {
  SET_ANALYTICS_MODAL_STATE(state, payload) {
    state.modalState = payload;
  },
  SET_ACTIVE_QUERY(state, payload) {
    state.activeQuery = payload;
  },
  SET_DATE_RANGE(state, payload) {
    state.dateRange = payload;
  }
};

const actions = {
  
};

const getters = {

};


export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
