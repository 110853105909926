<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.316" height="13.49" viewBox="0 0 17.316 13.49" class="icon-cloud-sync">
    <path id="Path_1072" data-name="Path 1072" d="M558.565,439.41a4.182,4.182,0,0,0-1.32.225,4.933,4.933,0,0,0-9.581,1.657c0,.087.022.172.027.258a3.2,3.2,0,0,0-2.244,3.047c0,.078,0,.171.012.272h0v.013h0a3.126,3.126,0,0,0,1.1,2.106,3.435,3.435,0,0,0,2.225.817h4.01l-.735.769a.75.75,0,1,0,1.085,1.037l1.99-2.083a.749.749,0,0,0-.024-1.06l-2.082-1.991a.75.75,0,0,0-1.036,1.085l.779.744h-3.987a1.943,1.943,0,0,1-1.251-.46,1.618,1.618,0,0,1-.583-1.1v-.014l-.006-.133a1.706,1.706,0,0,1,1.6-1.7.752.752,0,0,0,.688-.9A3.437,3.437,0,1,1,556,440.8a.75.75,0,0,0,1.143.527,2.685,2.685,0,0,1,4.119,2.284v.016a2.684,2.684,0,0,1-2.676,2.682.75.75,0,0,0,0,1.5h0a4.178,4.178,0,0,0,4.176-4.181v-.016A4.2,4.2,0,0,0,558.565,439.41Z" transform="translate(-545.447 -436.353)" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-cloud-sync',
  }
</script>
