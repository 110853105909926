import VueRouter from 'vue-router';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location, onResolve, onReject) {
  if (onReject) {
    const wrappedReject = err => {
      if (err.name !== 'NavigationDuplicated') {
        onReject(err);
      }
    };
    return originalPush.call(this, location, onResolve, wrappedReject);
  }
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function (location, onResolve, onReject) {
  if (onReject) {
    const wrappedReject = err => {
      if (err.name !== 'NavigationDuplicated') {
        onReject(err);
      }
    };
    return originalReplace.call(this, location, onResolve, wrappedReject);
  }
  return originalReplace.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
};

export default VueRouter;