var render = function() {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "scroll", staticClass: "form-control-text" },
    [
      _vm.label
        ? _c(
            "p",
            {
              staticClass: "form-control-label txt-body mb-1",
              class: { "txt-muted": _vm.disabled }
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.description
        ? _c(
            "span",
            { staticClass: "form-control-description txt-body mb-3" },
            [_vm._v(_vm._s(_vm.description))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("description"),
      _vm._v(" "),
      _vm.disabled && _vm.disabledTooltip
        ? _c(
            "b-tooltip",
            {
              attrs: {
                target: function() {
                  return _vm.$refs.inputGroup
                }
              }
            },
            [_vm._v(_vm._s(_vm.disabledTooltip))]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.viewMode
        ? _c(
            "b-input-group",
            { ref: "inputGroup" },
            [
              _vm.icon
                ? _c("b-input-group-prepend", [
                    _c(
                      "svg",
                      {
                        staticClass: "icon-regular ml-1",
                        class:
                          ((_obj = {}),
                          (_obj["txt-" + _vm.iconVariant] = _vm.iconVariant),
                          _obj),
                        attrs: { viewBox: "0 0 24 24" }
                      },
                      [
                        _c("use", {
                          attrs: { "xlink:href": "#icon-" + _vm.icon }
                        })
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.avatarIcon
                ? _c("b-input-group-prepend", [
                    _c("img", { attrs: { src: _vm.avatarIcon } })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.$slots.left
                ? _c("b-input-group-prepend", [_vm._t("left")], 2)
                : _vm._e(),
              _vm._v(" "),
              _vm.showCounter || _vm.showPasswordCheck
                ? [
                    _vm.showCounter
                      ? _c("b-input-group-append", [
                          _c(
                            "span",
                            { staticClass: "form-control-ghost visible" },
                            [
                              _vm._v(
                                _vm._s(_vm.model ? _vm.model.length : "0") +
                                  " / " +
                                  _vm._s(_vm.maxlength)
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showPasswordCheck
                      ? _c(
                          "b-input-group-append",
                          [
                            _c(
                              "b-btn",
                              {
                                attrs: {
                                  variant: "platform-primary ml-auto mr-2",
                                  disabled: !_vm.model,
                                  size: "sm"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.passwordVisible = !_vm.passwordVisible
                                  }
                                }
                              },
                              [
                                _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "fade",
                                      appear: "",
                                      mode: "out-in"
                                    }
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "icon-sm",
                                        attrs: { viewBox: "0 0 24 24" }
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href":
                                              "#icon-" +
                                              (_vm.passwordVisible
                                                ? "eye-off"
                                                : "eye")
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.clearable
                ? _c(
                    "b-input-group-append",
                    [
                      _c(
                        "transition",
                        { attrs: { name: "fade", appear: "", mode: "out-in" } },
                        [
                          _vm.model.length
                            ? _c(
                                "b-btn",
                                {
                                  attrs: {
                                    variant: "platform-primary ml-auto mr-2"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.model = ""
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "icon-sm",
                                      attrs: { viewBox: "0 0 24 24" }
                                    },
                                    [
                                      _c("use", {
                                        attrs: { "xlink:href": "#icon-cross" }
                                      })
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : !_vm.showPasswordCheck
                ? _c("b-input-group-append", [_vm._t("append")], 2)
                : _vm._e(),
              _vm._v(" "),
              _vm.$slots.right
                ? _c("b-input-group-append", [_vm._t("right")], 2)
                : _vm._e(),
              _vm._v(" "),
              _c("b-form-input", {
                ref: "input",
                class:
                  ((_obj$1 = { "is-invalid": !!_vm.error }),
                  (_obj$1[_vm.inputClass] = !!_vm.inputClass),
                  _obj$1),
                attrs: {
                  formatter: _vm.formatter,
                  "lazy-formatter": _vm.lazyFormatter,
                  disabled: _vm.disabled,
                  type: _vm.inputType,
                  state: _vm.state,
                  placeholder: _vm.placeholder,
                  readonly: _vm.readonly,
                  size: _vm.size,
                  step: _vm.step,
                  maxlength: _vm.maxlength,
                  min: _vm.min,
                  max: _vm.max
                },
                on: {
                  change: function($event) {
                    return _vm.$emit("change")
                  },
                  blur: function($event) {
                    return _vm.$emit("blur")
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onEnter($event)
                  }
                },
                model: {
                  value: _vm.model,
                  callback: function($$v) {
                    _vm.model = $$v
                  },
                  expression: "model"
                }
              })
            ],
            2
          )
        : _c("div", [
            _c("p", { staticClass: "my-3 txt-bold" }, [
              _vm._v(_vm._s(_vm.model) + " " + _vm._s(_vm.viewModeText))
            ])
          ]),
      _vm._v(" "),
      _vm.hasManyErrors
        ? _vm._l(_vm.error, function(err, key) {
            return _c(
              "b-alert",
              {
                key: key,
                staticClass: "mb-0",
                attrs: { variant: "error", show: !!_vm.error && _vm.showError }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "alert-icon icon-sm",
                    attrs: { width: "24", viewBox: "0 0 24 24" }
                  },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "#icon-status-error-color" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "alert-content" }, [
                  _c("span", { staticClass: "txt-body" }, [_vm._v(_vm._s(err))])
                ])
              ]
            )
          })
        : _c(
            "b-alert",
            {
              staticClass: "mb-0",
              attrs: { variant: "error", show: !!_vm.error && _vm.showError }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "alert-icon icon-sm",
                  attrs: { width: "24", viewBox: "0 0 24 24" }
                },
                [
                  _c("use", {
                    attrs: { "xlink:href": "#icon-status-error-color" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "alert-content" }, [
                _c("span", { staticClass: "txt-body" }, [
                  _vm._v(_vm._s(_vm.error))
                ])
              ])
            ]
          ),
      _vm._v(" "),
      _vm._t("error")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }