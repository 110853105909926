var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-move-file",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18",
        height: "15.828",
        viewBox: "0 0 18 15.828"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M237.373,76.723h-6.259V75.715a2.166,2.166,0,0,0-2.153-2.135h-.012a.164.164,0,0,1-.033,0h-4.7a2.164,2.164,0,0,0-2.164,2.13v1.339c0,.007,0,.012,0,.017s0,.011,0,.018v9.643a2.681,2.681,0,0,0,2.682,2.681h12.633a2.682,2.682,0,0,0,2.68-2.681V79.4h0A2.68,2.68,0,0,0,237.373,76.723Zm-8.409-1.649v0Zm9.589,4.33v7.322a1.184,1.184,0,0,1-1.181,1.182H224.74a1.184,1.184,0,0,1-1.182-1.182V77.065l0-.006V75.733a.665.665,0,0,1,.663-.654h4.73a.666.666,0,0,1,.665.657v.987h-2.777a.75.75,0,1,0,0,1.5h10.536a1.183,1.183,0,0,1,1.18,1.182Z",
          transform: "translate(-222.053 -73.579)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M232.484,80.2a.75.75,0,0,0-1.059,1.063l.852.85h-4.261a.75.75,0,1,0,0,1.5h4.264l-.856.856a.751.751,0,0,0,1.061,1.062h0l2.136-2.135a.751.751,0,0,0,0-1.062Z",
          transform: "translate(-222.053 -73.579)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }