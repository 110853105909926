<template>
  <svg class="icon-customize-menus" xmlns="http://www.w3.org/2000/svg" width="18.864" height="18.862" viewBox="0 0 18.864 18.862">
    <path d="M9.431,0a9.431,9.431,0,1,0,9.432,9.431A9.432,9.432,0,0,0,9.431,0M15.04,15.039a7.931,7.931,0,1,1,2.323-5.608,7.9,7.9,0,0,1-2.323,5.608" transform="translate(0.001)" fill="#939393"/><path d="M13,5.622H5.86a.75.75,0,0,0,0,1.5H13a.75.75,0,0,0,0-1.5" transform="translate(0.001)" fill="#939393"/><path d="M13,8.681H5.86a.75.75,0,0,0,0,1.5H13a.75.75,0,0,0,0-1.5" transform="translate(0.001)" fill="#939393"/><path d="M13,11.74H5.86a.75.75,0,0,0,0,1.5H13a.75.75,0,0,0,0-1.5" transform="translate(0.001)" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-customize-menus'
  }
</script>
