var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-upload-lg",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "27.918",
        height: "22.071",
        viewBox: "0 0 27.918 22.071"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M23.25,14.927,19.6,11.279a1,1,0,0,0-1.414,0L14.54,14.927a1,1,0,1,0,1.413,1.415L17.894,14.4v6.67a1,1,0,0,0,2,0V14.4l1.941,1.941a1,1,0,0,0,1.415-1.415Z",
          transform: "translate(-0.001)",
          fill: "#293ad3"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M27.919,11.659a6.594,6.594,0,0,0-8.95-6.217A7.858,7.858,0,0,0,3.634,7.864c0,.2.015.391.03.586A5.02,5.02,0,0,0,0,13.28q0,.206.018.426a4.879,4.879,0,0,0,1.729,3.317,5.41,5.41,0,0,0,3.5,1.284h6.362a1,1,0,0,0,0-2H5.245a3.413,3.413,0,0,1-2.2-.807A2.879,2.879,0,0,1,2.012,13.54l0-.005L2,13.28a3.025,3.025,0,0,1,2.839-3.02,1,1,0,0,0,.917-1.2,5.924,5.924,0,0,1-.122-1.2A5.859,5.859,0,0,1,17.292,7.02a1,1,0,0,0,1.524.7,4.64,4.64,0,0,1,7.1,3.938v.026a4.6,4.6,0,0,1-.787,2.583,1,1,0,0,0,1.659,1.119h0a6.615,6.615,0,0,0,1.128-3.7Z",
          transform: "translate(-0.001)",
          fill: "#293ad3"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M.018,13.71v0h0Z",
          transform: "translate(-0.001)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }