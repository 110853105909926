<template>
  <svg class="icon-raw" xmlns="http://www.w3.org/2000/svg" width="15.443" height="14.114" viewBox="0 0 15.443 14.114">
    <path d="M9.751.135A6.926 6.926 0 008.379 0a7.044 7.044 0 00-6.367 4.085L1.42 2.902a.75.75 0 10-1.34.671l1.416 2.831a.748.748 0 001.006.336l2.832-1.416a.75.75 0 00-.671-1.341l-1.239.619a5.553 5.553 0 014.956-3.1 5.643 5.643 0 011.081.107 5.559 5.559 0 014.483 5.445 5.693 5.693 0 01-.106 1.083 5.559 5.559 0 01-5.444 4.481 5.69 5.69 0 01-1.085-.106 5.563 5.563 0 01-3.374-2.124.75.75 0 10-1.2.9 7.057 7.057 0 004.285 2.7 7.24 7.24 0 001.37.132 7.057 7.057 0 006.916-5.691 7.148 7.148 0 00.134-1.372A7.057 7.057 0 009.751.135z" fill="currentColor"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-raw',
  }
</script>
