var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "14.748",
        height: "12.236",
        viewBox: "0 0 14.748 12.236"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: "currentColor",
          d:
            "M.746 1.5h13.248a.75.75 0 000-1.5H.746a.75.75 0 000 1.5zM13.998 7.242H.746a.75.75 0 000 1.5h13.248a.75.75 0 000-1.5zM.746 5.02h8.28a.75.75 0 000-1.5H.746a.75.75 0 000 1.5zM9.03 10.736H.746a.75.75 0 000 1.5h8.28a.75.75 0 000-1.5z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }