var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 18.776 15.903"
      }
    },
    [
      _c("defs", [
        _c("clipPath", { attrs: { id: "a" } }, [
          _c("path", { attrs: { fill: "none", d: "M0 0h18.776v15.903H0z" } })
        ])
      ]),
      _vm._v(" "),
      _c("g", { attrs: { "data-name": "Gear Loading Icon" } }, [
        _c("g", { attrs: { "data-name": "Group 97" } }, [
          _c("path", {
            attrs: {
              fill: "#f4a321",
              "fill-rule": "evenodd",
              d:
                "M13.587 6.06a.444.444 0 0 0-.438-.336 1.5 1.5 0 0 1-1.03-2.62.378.378 0 0 0 .041-.514 6.731 6.731 0 0 0-1.08-1.09.38.38 0 0 0-.518.041 1.572 1.572 0 0 1-1.7.382A1.514 1.514 0 0 1 7.941.444a.377.377 0 0 0-.334-.4 6.8 6.8 0 0 0-1.533 0 .38.38 0 0 0-.337.39 1.516 1.516 0 0 1-.933 1.455 1.574 1.574 0 0 1-1.683-.384.38.38 0 0 0-.514-.043 6.8 6.8 0 0 0-1.1 1.09.38.38 0 0 0 .041.518 1.51 1.51 0 0 1 .377 1.7 1.575 1.575 0 0 1-1.482.921.371.371 0 0 0-.394.335 6.838 6.838 0 0 0 0 1.55.451.451 0 0 0 .447.335 1.5 1.5 0 0 1 1.4.934 1.515 1.515 0 0 1-.381 1.684.379.379 0 0 0-.041.514 6.738 6.738 0 0 0 1.075 1.092.38.38 0 0 0 .519-.041 1.568 1.568 0 0 1 1.7-.384 1.511 1.511 0 0 1 .925 1.481.378.378 0 0 0 .334.4 6.791 6.791 0 0 0 1.533 0 .379.379 0 0 0 .338-.39 1.513 1.513 0 0 1 .932-1.454 1.572 1.572 0 0 1 1.688.384.381.381 0 0 0 .515.044 6.773 6.773 0 0 0 1.1-1.09.378.378 0 0 0-.041-.518 1.509 1.509 0 0 1 1.01-2.629h.084a.38.38 0 0 0 .4-.335 6.862 6.862 0 0 0 .001-1.543zM6.826 9.107a2.273 2.273 0 1 1 2.272-2.271 2.273 2.273 0 0 1-2.273 2.273z",
              "data-name": "Path 61"
            }
          })
        ]),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            cx: "4.356",
            cy: "4.356",
            r: "4.356",
            fill: "#fcfcfc",
            "data-name": "Ellipse 89",
            transform: "translate(10.063 7.19)"
          }
        }),
        _vm._v(" "),
        _c("g", { attrs: { "data-name": "settings-work-tool" } }, [
          _c("g", { attrs: { "data-name": "Group 97" } }, [
            _c("path", {
              attrs: {
                fill: "#f4a321",
                "fill-rule": "evenodd",
                d:
                  "M17.95 11.153a.231.231 0 0 0-.228-.176.785.785 0 0 1-.537-1.366.2.2 0 0 0 .022-.268 3.531 3.531 0 0 0-.563-.569.2.2 0 0 0-.27.022.818.818 0 0 1-.886.2.789.789 0 0 1-.48-.771.2.2 0 0 0-.174-.209 3.569 3.569 0 0 0-.8 0 .2.2 0 0 0-.176.2.792.792 0 0 1-.487.758.822.822 0 0 1-.878-.2.2.2 0 0 0-.268-.023 3.554 3.554 0 0 0-.575.568.2.2 0 0 0 .022.27.787.787 0 0 1 .2.886.822.822 0 0 1-.773.481.193.193 0 0 0-.205.174 3.578 3.578 0 0 0 0 .808.234.234 0 0 0 .233.174.78.78 0 0 1 .731.487.789.789 0 0 1-.2.878.2.2 0 0 0-.022.268 3.524 3.524 0 0 0 .561.569.2.2 0 0 0 .27-.021.817.817 0 0 1 .886-.2.788.788 0 0 1 .483.772.2.2 0 0 0 .174.209 3.531 3.531 0 0 0 .8 0 .2.2 0 0 0 .176-.2.79.79 0 0 1 .487-.758.82.82 0 0 1 .88.2.2.2 0 0 0 .268.022 3.51 3.51 0 0 0 .575-.568.2.2 0 0 0-.021-.27.786.786 0 0 1 .526-1.37h.044a.2.2 0 0 0 .209-.174 3.587 3.587 0 0 0-.004-.803zm-3.526 1.589a1.185 1.185 0 1 1 1.185-1.185 1.185 1.185 0 0 1-1.185 1.185z",
                "data-name": "Path 61"
              }
            })
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }