import { Model } from '@vuex-orm/core';
import File from '@app2/models/File';
import Playlist from '@app2/models/Playlist';

export default class FilePlaylist extends Model {
  static entity = 'App\\FilePlaylist';

  static primaryKey = ['file_id', 'playlist_id'];

  static apiConfig = {
    baseURL: '/api/playlists/',
    actions: {
      updateFiles(playlist, files) {
        return this.put(`${playlist.hash}/files`, files, { save: false })
      },
      attachFiles(playlist, files, config = { }) {
        return this.post(`${playlist.hash}/files`, { files }, config)
      },
      detachFiles(playlist, files, config = {}) {
        return this.delete(`${playlist.hash}/files`, { data: {files}, ...config })
          .then(() => {
            if (config.save && config.save === false) return;
            files.forEach(f =>
                FilePlaylist.delete(r => r.file_id === f && r.playlist_id === playlist.id)
            );
          },
        );
      }
    }
  };

  static fields() {
    return {
      file_id: this.attr(null),
      file: this.belongsTo(File, 'file_id'),
      playlist_id: this.attr(null),
      playlist: this.belongsTo(Playlist, 'playlist_id'),
      position: this.attr(null),
    };
  }
}
