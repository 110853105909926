var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-tags",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "13.783",
        height: "13.791",
        viewBox: "0 0 13.783 13.791"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(-983.013 -997.641)" } }, [
        _c("path", {
          attrs: {
            d:
              "M995.851,1002.915a.75.75,0,1,0-1.5.085h0l.049.883v.019a1.11,1.11,0,0,1-.324.781l-4.929,4.927a1.1,1.1,0,0,1-1.558,0h0l-2.755-2.753a1.1,1.1,0,0,1-.324-.779,1.086,1.086,0,0,1,.322-.777l0,0h0l4.93-4.929a1.1,1.1,0,0,1,.779-.325l.844.05a.75.75,0,0,0,.088-1.5l-.844-.05h0l-.088,0a2.6,2.6,0,0,0-1.841.765l-4.929,4.927-.007.011a2.6,2.6,0,0,0,.007,3.67l2.755,2.752,0,0a2.6,2.6,0,0,0,3.679,0l4.93-4.929a2.61,2.61,0,0,0,.762-1.84v-.086Z",
            fill: "#939393"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M996.577,997.86a.752.752,0,0,0-1.061,0l-4.568,4.568a.75.75,0,0,0,1.061,1.061l4.568-4.568A.752.752,0,0,0,996.577,997.86Z",
            fill: "#939393"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }