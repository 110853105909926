<template>
  <audience-slide-in-template
    back-label="Bulk Actions"
    :back-route="{name: 'BulkIndex'}"
    :title="$route.params.title"
  >
    <selected-audience />
    <hr class="mt-0 mb-3">
    <div class="side-panel-widget p-4">
      <!-- v-if="$can('achievements')" -->
      <!-- <div class="fx-row-center fx-wrap mb-2 pb-1" v-if="achievements.length">
        <template v-for="(item, i) in achievements">
          <badge
            v-if="item.settings"
            size="btn"
            class="mr-1 mb-1"
            :shape="`shape-${getShape(item)}`"
            :key="i"
            :outline="getOutline(item)"
            :base="getBase(item)"
            :icon-type="getIllustrationType(item)"
            :icon-size="getIconSize(item)"
            :icon-color="getIconColor(item)"
            :inner="getIllustrationInner(item)"
            v-b-tooltip.hover
            :title="item.title"
          />
        </template>
      </div> -->
      <form-control-select
        v-if="$can('achievements')"
        v-model="achievements"
        placeholder="Search Manual Achievements..."
        track-by="id"
        select-label="title"
        :options="achievementOptions"
        :search-visible="true"
        :type="`manual achievement${achievements.length > 1 ? 's' : ''}`"
        :selected-title="$route.name === 'AchievementsAttach' ? 'Add' : 'Remove'"
        :block-keys="['Delete']"
        :identifier="infiniteId"
        tag-size="md"
        tag-basic
        tag-block
        infinite
        hide-selected
        multiple
        @infinite="achievementsInfiniteHandler"
      >
        <template #optionIcon="{ item }">
          <badge
            v-if="item.settings"
            size="sm"
            class="mr-2"
            :shape="`shape-${getShape(item)}`"
            :outline="getOutline(item)"
            :base="getBase(item)"
            :icon-size="getIconSize(item)"
            :icon-color="getIconColor(item)"
            :icon-type="getIllustrationType(item)"
            :inner="getIllustrationInner(item)"
          />
        </template>
        <template #tagIcon="{ item }">
          <badge
            v-if="item.settings"
            size="btn"
            class="mr-2"
            :shape="`shape-${getShape(item)}`"
            :outline="getOutline(item)"
            :base="getBase(item)"
            :icon-size="getIconSize(item)"
            :icon-color="getIconColor(item)"
            :icon-type="getIllustrationType(item)"
            :inner="getIllustrationInner(item)"
          />
        </template>
      </form-control-select>
    </div>
    <div class="side-panel-library-cta p-3">
      <b-btn block variant="primary" size="lg" :disabled="isSaving" @click="saveBulkAchievements">
        <svg class="btn-left" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-status-success"></use></svg>
        Apply Edits
      </b-btn>
    </div>
  </audience-slide-in-template>
</template>

<script>
  import moment from '@app2/utils/moment';
  import Achievement from "@app2/models/Achievement";
  import Audience from "@app2/models/Audience";
  import debounce from "lodash/debounce";
  import FormControlSelect from "@app2/core/Components/Common/Forms/Select.vue";
  import AudienceSlideInTemplate from "@app2/modules/Audience/Components/Template";
  import SelectedAudience from '@app2/modules/Audience/Bulk/Components/SelectedAudience'
  import eventBus from '@app2/core/eventBus'
  import rootVue from "@app2/app";
  import {set} from "@app2/utils/helpers";
  import { uniqBy, get } from 'lodash'
  import Badge from "@utils/Badge/Badge.vue";
  import badgeMixin from '@app2/modules/Audience/Person/badge.mixin'

  export default {
    name: 'AudienceManageTags',
    data() {
      return {
        initial: true,
        achievementPage: 1,
        infiniteId: new Date(),
        isSaving: false,
        bulkPayload: {
          audiences: [],
          achievements: []
        }
      }
    },
    mixins: [badgeMixin],
    components: {
      AudienceSlideInTemplate,
      FormControlSelect,
      SelectedAudience,
      Badge
    },
    destroyed() {
      eventBus.$off('reset-bulk-edit-settings');
      eventBus.$off('save-bulk-edit-settings');
    },
    computed: {
      selectedAudience() {
        return this.$store.getters['v2/audience/selectedAudience']
      },
      achievements: {
        get() {
          return Achievement.query().whereIdIn(get(this.bulkPayload, 'achievements').map(a => a.id || a)).all()
        },
        set(value) {
          this.hasChanged = true
          eventBus.$emit('audience-bulk-has-changed', !!value.length)
          eventBus.$on('reset-bulk-edit-settings', () => {
            this.achievements = [];
            setTimeout(() => {
              rootVue.$bvModal.hide('bulk-dialog');
            }, 100)
          }),
          eventBus.$on('save-bulk-edit-settings', () => {
            eventBus.$emit('audience-bulk-has-changed', false);
            this.saveBulkAchievements();
          })
          set(this.bulkPayload, 'achievements', value.map(item => item.id));
        }
      },
      tagListCleaned () {
        return this.tagList.map(tag => {
          if (tag.$id) {
            return {
              name: tag.name,
              audiences: this.selectedAudience,
              originalAudiences: this.selectedAudience,
            }
          }
          return tag
        })
      },
      achievementOptions() {
        let achievements = Achievement.query().all()
        achievements = achievements.filter(a => a.id !== 'create')
        return achievements
      },
    },
    async mounted() {

    },
    methods: {
      moment,
      debouncer: debounce((cb, args) => cb(args), 500),
      async achievementsInfiniteHandler($state) {
        try {
          const params = {
            page: this.achievementPage++,
            limit: 20,
            'filter[type]': 'manual'
          };
          const { response: { data } } = await Achievement.api().fetch({ params })
          if (data.data.length) {
            $state.loaded();
            await Audience.insertOrUpdate({ data: data.data })
          } else {
            $state.complete();
          }
          this.tagLast = data.meta.last_page;
        } catch (e) {
          $state.error(e);
          throw e;
        }
      },
      async attach() {
        await Audience.api().attachAchievements(this.bulkPayload)
        this.updateSelectedAudienceRows()
      },
      async detach() {
        await Audience.api().detachAchievements(this.bulkPayload)
        this.updateSelectedAudienceRows()
      },
      updateSelectedAudienceRows() {
        this.bulkPayload.achievements.forEach(a => {
          this.selectedAudience.forEach(audience => {
            if(this.$route.name === 'AchievementsAttach') {
              audience.achievements.push(a)
            } else {
              audience.achievements = audience.achievements.filter(h => h !== a)
            }
            eventBus.$emit('update-audience', audience)
          })
        })
      },
      saveBulkAchievements() {
        set(this.bulkPayload, 'audiences', this.selectedAudience.map(a => a.id))
        this.isSaving = true

        if(this.$route.name === 'AchievementsAttach') {
          this.attach()
        } else {
          this.detach()
          eventBus.$emit('remove-achievements', this.bulkPayload.achievements)
        }
        rootVue.$bvModal.hide('bulk-dialog')
        this.isSaving = false
        this.$toasted.show('Achievements updated successfully!', { type: 'success' });
      }
    }
  }
</script>
