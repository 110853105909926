<template>
  <svg class="icon-arrow-down" xmlns="http://www.w3.org/2000/svg" width="10.817" height="6.159" viewBox="0 0 10.817 6.159">
    <path d="M341.6,392.877l-4.659-4.658a.75.75,0,0,0-1.061,0l-4.658,4.658a.751.751,0,1,0,1.062,1.061l4.127-4.127,4.128,4.127a.75.75,0,1,0,1.061-1.061Z" transform="translate(341.817 394.157) rotate(180)" fill="#293ad3"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconArrowDown',
  };
</script>
