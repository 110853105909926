var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Auto_Process_Small",
        "data-name": "Auto Process Small",
        xmlns: "http://www.w3.org/2000/svg",
        width: "13.988",
        height: "11.402",
        viewBox: "0 0 13.988 11.402"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Path_1077",
          "data-name": "Path 1077",
          d: "M550.466,510.93Z",
          transform: "translate(-543.596 -504.028)",
          fill: "#293ad3"
        }
      }),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Group_4151",
            "data-name": "Group 4151",
            transform: "translate(-545.597 -505.907)"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "Path_1078",
              "data-name": "Path 1078",
              d:
                "M556.2,508.378a3.364,3.364,0,0,0-1.07.184,3.982,3.982,0,0,0-7.737,1.333c0,.071.018.141.021.212a2.579,2.579,0,0,0-1.814,2.459l.01.219h0v.016h0a2.524,2.524,0,0,0,.887,1.694,2.779,2.779,0,0,0,1.8.659h3.5l-1.089,1.14a.6.6,0,0,0,.868.83l2.1-2.2a.6.6,0,0,0-.019-.848l-2.2-2.1a.6.6,0,1,0-.829.868l1.16,1.11h-3.5a1.577,1.577,0,0,1-1.016-.372,1.319,1.319,0,0,1-.473-.9v-.016l-.005-.1a1.387,1.387,0,0,1,1.3-1.383.6.6,0,0,0,.551-.72,2.865,2.865,0,0,1-.058-.568,2.784,2.784,0,0,1,5.54-.4.6.6,0,0,0,.915.421,2.161,2.161,0,0,1,1.153-.336,2.193,2.193,0,0,1,2.188,2.189v.013a2.178,2.178,0,0,1-2.171,2.174.6.6,0,1,0,0,1.2h0a3.373,3.373,0,0,0,3.37-3.374v-.013A3.388,3.388,0,0,0,556.2,508.378Z",
              fill: "#293ad3"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_1079",
              "data-name": "Path 1079",
              d: "M550.627,512.845Z",
              fill: "#293ad3"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }