<template>
  <div>
    <!-- Glider -->
    <div ref="glider" class="auth-slider">
      <div :class="`auth-slide-${item.id}`" v-for="(item, index) in list" :key="index">
        <figure class="auth-slider-figure" :class="{'ml-auto': item.id !== 'amy', 'order-1 mt-3': item.id == 'juan'}">
          <img height="280" width="248" class="auth-slider-img" :src="`${$assetUrl}/images/auth/slider-${item.id}.png`" :alt="item.label.name" />
        </figure>
        <div :class="{'ml-auto': item.id == 'amy','mt-4 ml-4': item.id == 'colleen', 'my-4 py-4 ml-4': item.id == 'juan'}">
          <h4 class="txt-heading-small txt-muted"><b class="txt-primary">{{ item.label.name }}</b>{{ item.label.calling }}</h4>
          <blockquote class="auth-slider-blockquote" :class="{'small':item.id == 'amy',}">
            <span class="auth-slider-blockquote-inner">{{ item.description }}</span>
          </blockquote>
        </div>
      </div>
    </div>

    <!-- Small illustrations -->
    <transition name="slide-right" mode="out-in" :duration="500" appear>
      <div class="auth-slider-illustration-wrap" v-if="currentSlide && currentSlide.id == 'amy'" key="illustrations-amy">
        <svg class="auth-slider-illustration a1" width="116" height="188" viewBox="0 0 116 188" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M48.1155 153.097C36.6278 137.622 32.6334 117.045 36.6802 98.3322C40.9772 78.4822 61.0219 69.2865 80.1243 66.3229C88.079 65.0856 97.1114 65.4391 102.989 70.9863C108.866 76.5335 108.192 88.569 100.429 90.7667C95.2887 92.2369 89.6946 88.6001 87.4409 83.724C85.1871 78.8479 85.6023 73.1638 86.799 67.9401C91.8925 45.7649 110.152 32.0618 133.302 35.7371" stroke="black" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M97.0908 172.86C95.4298 172.167 93.9361 171.139 92.701 169.84C91.466 168.54 90.5156 166.996 89.908 165.301C89.32 163.6 89.089 161.788 89.2293 159.977C89.3696 158.166 89.8782 156.394 90.7239 154.769C85.8644 164.104 78.5069 164.601 71.0473 162.402C77.6239 164.328 82.2816 171.766 79.6659 180.186C85.8167 168.128 93.6115 171.218 97.0908 172.86Z" stroke="black" stroke-width="3.5" stroke-miterlimit="10" stroke-linejoin="round"/>
        </svg>

        <svg class="auth-slider-illustration a2" width="175" height="175" viewBox="0 0 175 175" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M76.8349 99.2993C71.758 104.331 63.7122 109.169 56.1519 113.08C50.3788 115.354 40.311 117.77 33.913 117.961" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M17.7508 82.6943C22.1279 83.1454 26.4484 81.3154 30.1091 79.0767C34.5495 76.3568 36.3766 75.0783 35.9222 69.4396C35.3668 62.5512 37.1263 55.7973 39.5897 49.4279C41.1775 45.3157 42.3631 36.0961 48.7164 38.5904C53.7042 40.5504 50.2997 54.5907 49.5999 59.7428C49.937 57.2663 54.9288 52.4421 56.4999 50.3603C59.2068 46.7716 61.96 43.2149 64.7499 39.6933C67.7731 35.8767 79.0999 18.2406 83.9694 20.6818C87.3217 22.3674 87.5498 26.2894 86.0763 29.6427C88.1565 24.8911 99.383 15.6655 101.707 24.5276C102.417 27.2298 100.744 29.5655 99.5155 32.0157C100.681 31.2487 101.928 30.4458 103.274 30.4027C115.325 29.9702 106.05 42.1867 103.461 46.467C106.471 41.8796 112.929 44.1295 111.867 49.4463C111.057 53.4859 108.713 57.3746 106.675 60.8887C102.648 67.8086 97.9727 74.3419 93.1907 80.764C91.4432 83.1076 89.1457 86.4857 86.4961 90.01" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M86.0748 29.6336C82.4914 34.5345 79.1595 39.3309 75.8123 44.4421C73.5092 47.9511 71.1033 50.3041 68.907 53.9399" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M99.6567 31.5473L79.6682 58.8631" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M105.02 44.2381C99.3116 51.8122 94.4525 58.682 88.7908 66.4224" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M83.5374 157.968C85.3267 151.458 87.0247 144.821 87.7171 138.091C88.3673 131.771 85.9492 127.371 83.3781 121.835C78.5416 111.413 77.5197 100.666 74.1629 89.8539C73.2428 86.8919 72.1653 82.202 76.852 82.6029C84.8275 83.2784 88.9087 97.1707 91.3628 103.125C90.5935 101.258 97.528 91.043 98.6354 89.0646C101.283 84.3269 104.005 79.628 106.754 74.9515C110.664 68.3164 113.738 59.8656 119.39 54.5923C123.937 50.3461 129.401 54.1772 127.697 60.48C128.626 57.0384 130.92 53.3358 133.763 51.1565C137.127 48.5815 142.286 50.08 143.092 54.6118C143.422 56.4615 142.37 60.1222 141.271 61.4831C144.417 57.5971 152.045 58.0351 151.738 63.9994C151.539 67.9258 148.934 71.3792 147.47 74.8948C150.32 68.0742 157.704 71.7703 154.504 79.1394C148.032 96.2563 139.451 112.497 130.172 128.238C128.419 131.209 126.688 133.475 126.47 136.911C126.315 139.326 126.064 141.729 125.865 144.136L124.946 155.101" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M127.386 60.7803L113.937 87.265" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M142.565 58.9494C139.477 64.8422 135.394 72.5907 132.563 78.61C130.551 82.8856 126.917 87.7918 125.491 92.2563" fill="white"/>
          <path d="M142.565 58.9494C139.477 64.8422 135.394 72.5907 132.563 78.61C130.551 82.8856 126.917 87.7918 125.491 92.2563" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M148.68 72.4755C144.029 81.3517 138.746 88.2353 135.928 97.9698Z" fill="white"/>
          <path d="M148.68 72.4755C144.029 81.3517 138.746 88.2353 135.928 97.9698" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

      <div class="auth-slider-illustration-wrap" v-else-if="currentSlide && currentSlide.id == 'colleen'" key="illustrations-colleen">
        <svg class="auth-slider-illustration b1" width="73" height="76" viewBox="0 0 73 76" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.5101 34.3952C14.1712 32.344 14.4183 30.1734 14.2363 28.0161C14.0543 25.8589 13.447 23.7605 12.4515 21.8494C11.4352 19.9501 10.054 18.2828 8.39274 16.9498C6.73143 15.6168 4.82502 14.6462 2.7904 14.0976C14.4816 17.2504 20.4731 11.0379 24.2269 2.56161C20.9065 10.0241 23.771 20.203 32.9798 24.6356C17.973 20.4491 14.7309 29.9445 13.5101 34.3952Z" stroke="#161616" stroke-width="4" stroke-miterlimit="10" stroke-linejoin="round"/>
          <path d="M58.1014 72.4482C58.7949 71.6775 59.3301 70.773 59.6742 69.7899C60.0184 68.8069 60.1642 67.7661 60.1028 66.7313C60.0298 65.6975 59.7511 64.6939 59.2837 63.782C58.8162 62.8701 58.1699 62.0691 57.3843 61.4282C61.8982 65.1108 65.7504 63.5607 69.0649 60.5766C66.14 63.2008 65.3968 68.2335 68.5691 71.99C62.8025 67.2018 59.5123 70.7339 58.1014 72.4482Z" stroke="#161616" stroke-width="4" stroke-miterlimit="10" stroke-linejoin="round"/>
        </svg>

        <svg class="auth-slider-illustration b2" width="68" height="123" viewBox="0 0 68 123" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M87.164 2.66321C98.6517 18.1377 102.646 38.7149 98.5993 57.4276C94.3023 77.2776 74.2576 86.4732 55.1552 89.4369C47.2005 90.6742 38.1681 90.3206 32.2906 84.7734C26.4131 79.2262 27.0877 67.1908 34.8509 64.993C39.9908 63.5228 45.5849 67.1596 47.8386 72.0358C50.0924 76.9119 49.6772 82.596 48.4805 87.8197C43.387 109.995 25.1278 123.698 1.97737 120.023" stroke="black" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div> 

      <div class="auth-slider-illustration-wrap" v-else-if="currentSlide && currentSlide.id == 'juan'" key="illustrations-juan">
        <svg class="auth-slider-illustration c1" width="159" height="178" viewBox="0 0 159 178" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M89.0339 83.1446C100.404 77.2994 103.079 59.5896 93.9464 50.628C86.886 43.704 75.2385 42.9011 66.3316 47.1591C57.4247 51.4172 51.106 59.9201 47.5082 69.1122C45.3558 74.6084 44.5351 81.909 49.2432 85.4847C54.9834 89.8263 63.3531 84.3519 66.4445 77.8608C73.8228 62.3732 64.7155 42.3672 49.4162 34.5454C4.62783 11.6558 -56.0724 69.8143 -34.553 115.44" stroke="#161616" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M76.2828 175.288L77.0922 164.928" stroke="#161616" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M91.0973 161.535L83.1714 160.543" stroke="#161616" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M73.0066 158.635L65.0973 157.964" stroke="#161616" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M78.1243 154.584L78.5967 147.154" stroke="#161616" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M148.096 37.9874C148.31 37.2643 148.928 33.7861 148.833 33.041C148.737 32.2958 148.494 31.5741 148.096 30.9331C147.699 30.292 147.447 30.2214 146.821 29.7881C146.196 29.3548 143.573 28.4428 142.821 28.2881C147.14 29.177 150.834 25.4933 152.092 22.4873C150.979 25.1341 153.393 30.4118 156.821 31.7881C149.093 29.7881 148.482 36.4221 148.096 37.9874Z" stroke="#161616" stroke-width="4" stroke-miterlimit="10" stroke-linejoin="round"/>
        </svg>
      </div>
    </transition>

    <!-- Dots -->
    <div ref="dots" class="auth-slider-dots"></div>
  </div>
</template>
<script>
  import Glider from "glider-js";

  export default {
    name: 'AuthSlider',
    components: {},
    data() {
      return {
        glider: null,
        slide: 0,
        dismissDialog: false,
        list: [
          {
            id: 'amy',
            label: {
              name: 'Amy Porterfield',
              calling: 'Online Marketing Expert'
            },
            description: 'The search tool alone has been such a valuable addition, giving users the ability to search through hundreds of hours of content to find exactly what they need. We highly recommend Searchie.io!',
          },
          {
            id: 'colleen',
            label: {
              name: 'Colleen Kessler',
              calling: 'RaisingLifelongLearners.com'
            },
            description: 'Searchie serves my coaching clients amazingly.  After a live call with a client, I upload the recording where it generates a searchable replay, downloadable transcript, and mp3 they can use to refer back to the suggestions I’ve made, and the breakthroughs we’ve had related to how they can better meet the social, emotional, and educational needs of their quirky, creative, and outside-the-box kiddos.',
          },
          {
            id: 'juan',
            label: {
              name: 'Juan R. Arroyo Yap',
              calling: 'Juan on One Coaching, LLC'
            },
            description: 'I joined Searchie for the ability to do Hubs because I want to have courses and videos supporting my funnel building coaching. I love that videos are searchable and one can easily find what they are looking for.',
          },
        ]
      }
    },
    mounted() {
      let self = this;
      this.$nextTick(() => {
        self.glider = new Glider(self.$refs.glider, {
          slidesToScroll: 1,
          slidesToShow: 1,
          duration: 0,
          scrollLock: true,
          rewind: true,
          dots: this.$refs.dots,
        });
        self.glider.setOption({duration: 1,})

        let autoplayDelay = 10000;
        let autoplay = setInterval(() => {
            self.glider.scrollItem('next')
        }, autoplayDelay);

        self.$refs.glider.addEventListener('mouseover', (event) => {
            if (autoplay != null) {
              clearInterval(autoplay);
              autoplay = null;
            }
        }, 300);

        self.$refs.glider.addEventListener('mouseout', (event) => {
            if (autoplay == null) {
              autoplay = setInterval(() => {
                  self.glider.scrollItem('next')
              }, autoplayDelay);
            }
        }, 300);

      });
    },
    computed: {
      currentSlide() {
        if ( !this.glider ) return;
        return this.list[this.glider.slide];
      },
    },
    methods: {
      scrollTo(index) {
        this.glider.scrollItem(index)
      },
    },
  }
</script>
