<template>
  <svg class="icon-hubs-cancel" xmlns="http://www.w3.org/2000/svg" width="20.48" height="18.982" viewBox="0 0 20.48 18.982">
    <path d="M7.915 11.456h-.006zM8.583 10.788a1.622 1.622 0 00-.656-2.184L5.99 7.53a1.653 1.653 0 00-.666-.2v-.006l-.079-.006H5.172a1.663 1.663 0 00-1.66 1.6v.027l-.007 2.15v.005a1.605 1.605 0 00.222.815l.006.011a1.683 1.683 0 002.23.6l1.936-1.063a1.656 1.656 0 00.63-.6l.007-.012.009-.014zm-1.39-.647l-1.941 1.066-.086.022-.148-.083-.014-.051.008-2.12.161-.156h.008l.072.02h.005l1.937 1.074.009.005.064.109-.015.053-.058.056z" fill="#939393"/>
    <path d="M18.972 9.587v-6.8A2.782 2.782 0 0016.19.004H2.784A2.783 2.783 0 000 2.787v10.429a2.783 2.783 0 002.783 2.782h7.292a.75.75 0 100-1.5H2.783A1.285 1.285 0 011.5 13.216v-7.84h13.182a.75.75 0 000-1.5H1.5V2.783a1.287 1.287 0 011.284-1.284H16.19a1.284 1.284 0 011.282 1.283v6.8a.75.75 0 001.5 0z" fill="#939393"/>
    <path d="M12.47 7.824zM14.714 7.387h-.048a5.724 5.724 0 00-2.2.438 5.8 5.8 0 102.243-.437zm-.041 10.1a4.3 4.3 0 01-1.629-8.271 4.226 4.226 0 011.627-.325h.017a4.3 4.3 0 11-.017 8.6z" fill="#e34c4c"/>
    <path d="M16.879 10.987a.752.752 0 00-1.061 0l-1.136 1.136-1.136-1.136a.751.751 0 10-1.061 1.062l1.136 1.135-1.136 1.136a.75.75 0 001.061 1.061l1.136-1.136 1.136 1.136a.75.75 0 101.061-1.061l-1.136-1.136 1.136-1.135a.753.753 0 000-1.062z" fill="#e34c4c"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconHubsCancel'
  }
</script>
