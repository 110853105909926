<template>
  <div class="settings-photo" v-if="user">
    <form role="form">
      <div class="photo-upload">
          <div class="photo-preview">
            <icon-user></icon-user>
            <span role="img" :style="previewStyle"></span>
              <!-- <div class="photo-edit">
                  <input ref="photo" type="file" class="photo-uploader" name="photo" @change="update" :disabled="form.busy">
                  <div class="btn">
                      <icon-edit></icon-edit>
                  </div>
              </div> -->
          </div>
      </div>
      <b-form-invalid-feedback v-if="form.errors.has('photo')">
        {{ form.errors.get('photo') }}
      </b-form-invalid-feedback>
    </form>
  </div>
</template>

<script>
  import axios from '@app2/api/axios';
  import SparkForm from '@app2/utils/SparkForm';
  export default {
    name: 'profile-image',
    props: ['user'],
    data() {
      return {
        form: new SparkForm({})
      };
    },
    methods: {
      update(e) {
        e.preventDefault();
        if ( ! this.$refs.photo.files.length) {
          return;
        }
        let self = this;
        this.form.startProcessing();
        axios.post(`/api/audience/${user.hash}`, this.gatherFormData())
          .then(
            async () => {
              await this.$store.dispatch('auth/fetchUser');
              self.form.finishProcessing();
            },
            (error) => {
              self.form.setErrors(error.response.data.errors);
            }
          );
      },
      gatherFormData() {
        const data = new FormData();
        data.append('photo', this.$refs.photo.files[0]);
        return data;
      }
    },
    computed: {
      previewStyle() {
        return `background-image: url('${this.user.photo}')`;
      },
    }
  };

</script>
