var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-file",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "13.04",
        height: "14.92",
        viewBox: "0 0 13.04 14.92"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Path_1121",
          "data-name": "Path 1121",
          d:
            "M1363.17,285.064l-2.155-1.214a1.005,1.005,0,0,0-1.5.871v2.445a1.007,1.007,0,0,0,1.5.871l2.155-1.22a1.008,1.008,0,0,0,0-1.753Z",
          transform: "translate(-1354.931 -278.308)",
          fill: "#979797"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_1122",
          "data-name": "Path 1122",
          d:
            "M1366.889,281.551l-2.371-2.27a3.493,3.493,0,0,0-2.419-.971h-3.491l-.086,0a3.6,3.6,0,0,0-3.591,3.486v7.954a3.6,3.6,0,0,0,3.593,3.484h5.849a3.6,3.6,0,0,0,3.594-3.483v-5.671A3.5,3.5,0,0,0,1366.889,281.551Zm-2.516,10.182h-5.849a2.1,2.1,0,0,1-2.093-2.03v-7.864a2.094,2.094,0,0,1,2.091-2.031h3.577a2,2,0,0,1,1.381.555l2.371,2.27a2,2,0,0,1,.616,1.444V289.7A2.1,2.1,0,0,1,1364.373,291.733Z",
          transform: "translate(-1354.931 -278.308)",
          fill: "#979797"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }