var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        height: "18",
        width: "18",
        viewBox: "0 0 18 18",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { "data-name": "Group 646" } }, [
        _c("path", {
          attrs: {
            d:
              "M11.66 6.67a3.21 3.21 0 0 1-.21 1.32 2.95 2.95 0 0 1-.88.98 4.64 4.64 0 0 0-.88.82 1.12 1.12 0 0 0-.21.66v.43H8.33a1.6 1.6 0 0 1-.31-1.02 1.07 1.07 0 0 1 .24-.65 5.84 5.84 0 0 1 .89-.85 5.1 5.1 0 0 0 .84-.82 1.3 1.3 0 0 0 .21-.77q0-.95-1.38-.95A7.9 7.9 0 0 0 7.2 6l-.3.07-.07-1.08a6.63 6.63 0 0 1 2.2-.43 3.27 3.27 0 0 1 2 .5 1.9 1.9 0 0 1 .64 1.6zM8.12 13.6v-1.82h1.5v1.82z",
            "data-name": "Path 475",
            fill: "#939393"
          }
        }),
        _vm._v(" "),
        _c("g", { attrs: { "data-name": "Group 645" } }, [
          _c(
            "g",
            {
              attrs: {
                "data-name": "Ellipse 54",
                fill: "none",
                stroke: "#939393",
                "stroke-width": "1.5"
              }
            },
            [
              _c("circle", {
                attrs: { cx: "9", cy: "9", r: "9", stroke: "none" }
              }),
              _vm._v(" "),
              _c("circle", { attrs: { cx: "9", cy: "9", r: "8.25" } })
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }