var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 20 20"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(1 1)" } }, [
        _c("path", {
          attrs: {
            d: "M773,344a9,9,0,1,1-9-9,9,9,0,0,1,9,9Zm0,0",
            transform: "translate(-755 -335)",
            fill: "#f0f0f0"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M772.44,474.827a9.032,9.032,0,0,0,0-6.261H755.56a9.033,9.033,0,0,0,0,6.261l8.44.783Zm0,0",
            transform: "translate(-755 -462.696)",
            fill: "#0052b4"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M776.178,616.9a9,9,0,0,0,8.44-5.87h-16.88a9,9,0,0,0,8.44,5.87Zm0,0",
            transform: "translate(-767.178 -598.905)",
            fill: "#d80027"
          }
        }),
        _c(
          "g",
          { attrs: { fill: "none", stroke: "#e4e4e4", "stroke-width": "1" } },
          [
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9", stroke: "none" }
            }),
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9.5", fill: "none" }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }