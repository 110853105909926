var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "auth-page",
      class: { "with-details": !!_vm.checkoutDetails }
    },
    [
      _c(
        "div",
        { staticClass: "auth-page-inner" },
        [
          _c("div", { staticClass: "auth-page-content" }, [
            _c(
              "div",
              { staticClass: "inner" },
              [
                _c("transition", { attrs: { name: "fade", appear: "" } }, [
                  !_vm.completed
                    ? _c("div", { staticClass: "txt-center pb-4 mb-4" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "117",
                              height: "30",
                              viewBox: "0 0 117 30",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M34.2919 18.2271L35.7039 16.5377C36.538 17.235 37.5871 17.6233 38.6741 17.6371C39.3549 17.6371 39.723 17.4001 39.723 17.0118V16.9815C39.723 16.6033 39.4205 16.3965 38.1799 16.1091C36.2283 15.6603 34.7205 15.1005 34.7205 13.2296V13.1993C34.7205 11.4999 36.072 10.2694 38.2505 10.2694C39.6102 10.218 40.9443 10.6485 42.0176 11.4848L40.7468 13.28C40.0039 12.7267 39.1106 12.4119 38.185 12.3774C37.5647 12.3774 37.2672 12.6396 37.2672 12.9674V12.9926C37.2672 13.4111 37.5798 13.5927 38.8506 13.8852C40.9586 14.339 42.2647 15.0198 42.2647 16.7344V16.7798C42.2647 18.6558 40.7871 19.7652 38.5632 19.7652C36.9995 19.7903 35.4805 19.2433 34.2919 18.2271Z",
                                fill: "black"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d:
                                  "M45.0231 10.4252H52.3908V12.5835H47.5345V13.9703H50.9233V15.9875H47.5345V17.4399H52.4514V19.6033H45.0231V10.4252Z",
                                fill: "black"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d:
                                  "M57.8977 10.3596H60.3435L64.2467 19.5881H61.5235L60.8528 17.9492H57.3228L56.6672 19.5881H53.9995L57.8977 10.3596ZM60.1216 15.9774L59.113 13.3702L58.1044 15.9774H60.1216Z",
                                fill: "black"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d:
                                  "M66.1176 10.4252H70.4494C71.8463 10.4252 72.8145 10.7933 73.4298 11.4338C73.7056 11.7136 73.9202 12.0478 74.06 12.415C74.1997 12.7822 74.2616 13.1745 74.2417 13.5669V13.5921C74.2634 14.1999 74.0934 14.7991 73.7559 15.305C73.4183 15.8109 72.9302 16.1978 72.3607 16.4111L74.5443 19.6033H71.6143L69.7686 16.8297H68.6542V19.6033H66.1327L66.1176 10.4252ZM70.3284 14.8226C71.1907 14.8226 71.69 14.404 71.69 13.7384V13.7081C71.69 12.992 71.1857 12.6239 70.3133 12.6239H68.6542V14.8226H70.3284Z",
                                fill: "black"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d:
                                  "M75.921 15.0344C75.913 14.4165 76.0268 13.8031 76.2559 13.2292C76.485 12.6553 76.8249 12.1321 77.2562 11.6896C77.6875 11.2471 78.2017 10.8938 78.7695 10.6501C79.3374 10.4063 79.9477 10.2767 80.5656 10.2688H80.7471C81.5037 10.2339 82.2569 10.3908 82.9367 10.7249C83.6165 11.0591 84.2007 11.5596 84.6352 12.1801L82.7289 13.6577C82.505 13.3285 82.2043 13.0588 81.8529 12.8717C81.5015 12.6846 81.1099 12.5857 80.7118 12.5835C80.1107 12.6033 79.5421 12.8608 79.1307 13.2996C78.7194 13.7383 78.4991 14.3224 78.5181 14.9235C78.5205 14.9503 78.5205 14.9773 78.5181 15.0041V15.0344C78.5181 16.4061 79.4208 17.4802 80.7067 17.4802C81.1155 17.4767 81.5173 17.3739 81.8775 17.1806C82.2376 16.9874 82.5455 16.7094 82.7743 16.3708L84.6805 17.7323C84.0967 18.567 83.2638 19.1955 82.301 19.5279C81.3382 19.8603 80.2949 19.8795 79.3205 19.5829C78.3461 19.2863 77.4906 18.689 76.8763 17.8765C76.262 17.064 75.9206 16.078 75.9009 15.0596L75.921 15.0344Z",
                                fill: "black"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d:
                                  "M86.5111 10.4252H89.0325V13.8543H92.2852V10.4252H94.8067V19.5881H92.2852V16.1035H89.0325V19.5881H86.5111V10.4252Z",
                                fill: "black"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d:
                                  "M98.0341 10.4252H100.586V19.5881H98.0341V10.4252Z",
                                fill: "black"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d:
                                  "M103.501 10.4252H110.868V12.5835H106.017V13.9703H109.406V15.9875H106.017V17.4399H110.939V19.6033H103.501V10.4252Z",
                                fill: "black"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d:
                                  "M26.9934 12.1808C26.4959 11.3245 25.7829 10.6132 24.9253 10.1178L16.1486 5.07374L10.464 1.75475C9.60638 1.26021 8.63382 0.999921 7.64387 1C6.65392 1.00008 5.68141 1.26052 4.82391 1.75519C3.96641 2.24987 3.25409 2.96137 2.75844 3.8183C2.26279 4.67523 2.00123 5.64745 2.00002 6.6374V23.3635C1.99838 23.9934 2.10238 24.6192 2.30771 25.2147C2.58082 25.9992 3.02388 26.7138 3.60524 27.3072C4.18659 27.9006 4.89187 28.3582 5.67064 28.6474C6.44942 28.9365 7.28246 29.05 8.11022 28.9798C8.93797 28.9096 9.73998 28.6574 10.4589 28.2411L24.9455 19.8781C26.2411 19.1309 27.1869 17.8997 27.5747 16.4552C27.9625 15.0108 27.7607 13.4714 27.0135 12.1758L26.9934 12.1808ZM3.48802 6.63236C3.488 5.90695 3.67901 5.19432 4.04181 4.56616C4.40462 3.93799 4.92645 3.41644 5.55479 3.05395C6.18314 2.69146 6.89586 2.50082 7.62127 2.5012C8.34668 2.50158 9.05921 2.69297 9.68718 3.05612L14.6455 5.92114L7.78051 9.9564C7.18748 10.3094 6.75745 10.8818 6.58365 11.5497C6.40984 12.2176 6.50627 12.9271 6.85202 13.5244C7.19778 14.1216 7.765 14.5586 8.43073 14.7405C9.09647 14.9224 9.80705 14.8345 10.4085 14.4961L13.5106 12.6751C14.1134 12.3275 14.8296 12.2331 15.5019 12.4128C16.1742 12.5925 16.7478 13.0315 17.0969 13.6335C17.2697 13.9319 17.382 14.2613 17.4274 14.6031C17.4728 14.9449 17.4503 15.2923 17.3613 15.6254C17.2723 15.9585 17.1184 16.2708 16.9086 16.5443C16.6988 16.8179 16.4371 17.0475 16.1385 17.2198L3.63934 24.4379C3.54387 24.0876 3.49303 23.7266 3.48802 23.3635V6.63236Z",
                                fill: "black"
                              }
                            })
                          ]
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                !!_vm.checkoutDetails
                  ? _c("div", { staticClass: "effect-left" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "188",
                            height: "181",
                            viewBox: "0 0 188 181",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M38.3391 41.995C30.7404 59.7063 31.6117 80.6494 39.8753 97.9195C48.6452 116.238 70.2729 120.55 89.543 119.017C97.5682 118.382 106.274 115.949 110.71 109.194C115.146 102.438 111.707 90.8845 103.646 90.5411C98.305 90.2991 93.7034 95.1307 92.6381 100.396C91.5728 105.661 93.2909 111.095 95.6629 115.901C105.745 136.297 126.678 145.407 148.351 136.479",
                              stroke: "black",
                              "stroke-width": "3.5",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d:
                                "M81.4186 11.4444C79.9629 12.5026 78.7472 13.848 77.8461 15.398C76.945 16.9481 76.3774 18.6703 76.1781 20.4592C75.9993 22.2505 76.1935 24.0666 76.7487 25.7961C77.3039 27.5256 78.2084 29.1321 79.4068 30.5171C72.5209 22.559 65.2478 23.7765 58.4986 27.6405C64.4518 24.2464 67.2636 15.933 62.7721 8.34599C71.5441 18.6554 78.4133 13.847 81.4186 11.4444Z",
                              stroke: "black",
                              "stroke-width": "3.5",
                              "stroke-miterlimit": "10",
                              "stroke-linejoin": "round"
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._t("default")
              ],
              2
            )
          ]),
          _vm._v(" "),
          !_vm.isPopup
            ? _c(
                "transition",
                { attrs: { name: "fade", appear: "" } },
                [
                  _vm.checkoutDetails
                    ? _c("auth-details", { ref: "details" })
                    : !_vm.completed &&
                      !(
                        _vm.isMultiplePlans &&
                        _vm.$route.name === "OnboardingPlan" &&
                        _vm.$store.getters["v2/register/plans"].length > 1
                      )
                    ? _c(
                        "div",
                        { staticClass: "auth-page-slider" },
                        [
                          _c("auth-slider", {
                            staticClass: "auth-page-slider-inner"
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade", appear: "" } },
            [
              _vm.hasScrolled
                ? _c(
                    "b-btn",
                    {
                      staticClass: "scroll-to-top",
                      on: { click: _vm.scrollToTop }
                    },
                    [
                      _c(
                        "svg",
                        { attrs: { width: "24", viewBox: "0 0 24 24" } },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "#icon-arrow-left" }
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }