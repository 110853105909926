<template>
  <div>
    <b-modal
      id="embed-domains-dialog"
      modal-class="modal-right"
      static
      ref="modal"
      hide-footer
      @hide="onHide"
      @hidden="onHidden"
      @show="fetchDomains"
      hide-header>
      <div class="side-panel-template">
        <div class="side-panel-header pt-4 mb-0">
          <div class="inner mb-0">
            <div class="side-panel-title">
              <div class="inner my-auto">
                <div>
                  <h3 class="txt-title-small"><b>Edit Approved Domains</b></h3>
                  <p class="txt-body txt-muted mb-0">Domain restricted content will only be accessible on the domains listed here.</p>
                </div>
              </div>
            </div>
            <b-btn variant="icon ml-auto" size="sm" @click="$bvModal.hide('embed-domains-dialog')">
              <svg class="icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-cross"></use></svg>
            </b-btn>
          </div>
        </div>
        <hr class="mt-3 mb-4">
        <div class="side-panel-body">
          <div class="side-panel-widget p-4">
            <side-panel-link-row
              v-for="(item, index) in form.domains"
              :key="index"
              :link="item.substr(0, 42) + '...'"
              class="mt-2"
              @delete="removeDomain(index)"
              v-b-tooltip:hover :title="item.length > 42 ? item : ''"
            >
              <template #left>
                <div class="fx-row-center">
                  <svg class="ml-2" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-status-success-color"></use></svg>
                </div>
              </template>
            </side-panel-link-row>
            <form-control-text
              v-model="inputText"
              class="mt-2"
              :class="{'with-actions': openForm}"
              input-class="txt-heading-small mb-0"
              placeholder="e.g. mywebiste.com"
              :state="!validating && !isValid && isValidated ? false : null"
              :error="!validating && !isValid && isValidated ? error : null"
              @keydown.native.enter="addDomain(true)"
              v-if="showForm"
              ref="input">
              <template #left>
                <svg v-if="validating" class="icon-regular anim-rotate ml-1 mr-2" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-loading-circle"></use></svg>
                <svg v-else-if="isValidated && isValid" class="icon-regular ml-1 mr-2" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-status-success-color"></use></svg>
                <!-- <svg v-else-if="isValidated && !isValid" class="mr-2" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-link"></use></svg> -->
              </template>
              <template #right>
                <b-btn variant="platform-primary ml-auto mr-2" size="sm" @click="clear">
                  <svg class="icon-sm" viewBox="0 0 24 24"><use xlink:href="#icon-cross"></use></svg>
                </b-btn>
                <b-btn :disabled="adding || !temp || validating || !isValid && isValidated" variant="platform-primary ml-auto mr-2" size="sm" @click="addDomain(false)">
                  <svg viewBox="0 0 24 24" class="icon-sm"><use xlink:href="#icon-tick"></use></svg>
                </b-btn>
              </template>
            </form-control-text>
            <button @click="openForm" type="button" class="btn btn-secondary btn-block mt-2">
              <svg width="24" viewBox="0 0 24 24" class="btn-left"><use xlink:href="#icon-plus-circle"></use></svg>
              Add Domain
            </button>
          </div>
          <div class="side-panel-library-cta p-3">
            <b-btn block variant="primary" size="lg" @click="update(false)" :disabled="!hasChanged || saving">
              Confirm
            </b-btn>
          </div>
          <delete-domain-modal
            :domain="form.domains[selected]"
            @unpublish="removeDomain(selected, true)"
          />
        </div>
      </div>
    </b-modal>
    <exit-dialog
      id="exit-domains-dialog"
      subtitle="Save changes?"
      title="Save changes before exit?"
      description="Unsaved data will be lost."
      accept-label="Save & Exit"
      reject-label="Exit"
      @reject="reset"
      @accept="update(true)"
      :processing="saving" />
  </div>
</template>

<script>
import FormControlTextarea from "@app2/core/Components/Common/Forms/TextArea";
import FormControlText from "@app2/core/Components/Common/Forms/Text";
import SidePanelLinkRow from "@app2/core/Components/Sidepanel/LinkRow";
import DeleteDomainModal from "@app2/modules/AccountSettings/Pages/Routes/Player/Modal/DeleteDomain";
import ExitDialog from "@app2/core/Components/Common/Modals/ExitDialog";
import debounce from "lodash/debounce";

export default {
  name: 'EmbedDomainsDialog',
  data() {
    return {
      temp: null,
      selected: null,
      initialDomain: null,
      saving: null,
      error: 'Please enter a valid domain.',
      errorState: 'error',
      form: new SparkForm({
        domains: []
      }),
      originalDomains: [],
      showForm: false,
      adding: false,
      validating: false,
      isValid: false,
      isValidated: false,
    }
  },
  computed: {
    hasChanged() {
      return JSON.stringify(this.originalDomains) !== JSON.stringify(this.form.domains)
    },
    inputText: {
      get() { return this.temp },
      set(val) {
        this.temp = val
        this.isValid = false;
        this.isValidated = false;
        this.validateDomain();
      }
    }
  },
  methods: {
    onHide(event) {
      if ( this.hasChanged ) {
        this.$bvModal.show('exit-domains-dialog')
        event.preventDefault();
      }
    },
    onHidden() {
      this.form.domains = [];
      this.originalDomains = [];
    },
    reset() {
      this.form.domains = this.originalDomains;
      this.$bvModal.hide('exit-domains-dialog')
      this.$bvModal.hide('embed-domains-dialog')
    },
    async fetchDomains() {
      try {
        const {data} = await axios.get('/api/teams/domains');
        this.form.domains = data;
        this.originalDomains = JSON.parse(JSON.stringify(data));
      } catch (e) {
        throw e;
      }
    },
    removeDomain(index, force = false) {
      if ( this.originalDomains.includes(this.form.domains[index]) && !force ) {
        this.selected = index;
        this.$bvModal.show('domain-settings-delete-modal');
      } else {
        this.form.domains = this.form.domains.filter((w,i) => i !== index);
      }
      this.$forceUpdate();
    },
    async update(close) {
      this.saving = true;
      try {
        this.form.startProcessing();
        await axios.put('/api/teams/domains', this.form);
        this.form.finishProcessing();
        this.$toasted.show(`Embed domains have been updated!`, { type: 'success' });
        this.originalDomains = JSON.parse(JSON.stringify(this.form.domains));
      } catch (e) {
        this.form.setErrors(error.data.errors);
        throw e;
      }
      this.$bvModal.hide('exit-domains-dialog')
      if ( close ) this.$bvModal.hide('embed-domains-dialog')
      this.saving = false;
    },
    isDomain(domain) {
      try {
        // Check if the domain appears to be a valid URL
        if (!/^(https?:\/\/)?([\w\d-]+\.)+[\w]{2,}(\/.*)?$/.test(domain)) {
          return false; // Not a valid URL format
        }

        // If the domain doesn't have a protocol, prepend "http://"
        if (!domain.startsWith('http://') && !domain.startsWith('https://')) {
          domain = 'http://' + domain;
        }

        // Attempt to create a URL object
        const url = new URL(domain);
        return url.hostname !== '';
      } catch (error) {
        return false;
      }
    },
    addDomain(remain) {
      if (this.validating || !this.isValid && this.isValidated) return;
      this.adding = true;
      if (this.isDomain(this.temp)) {
        this.form.domains.push(this.temp)
        this.temp = null
        this.$emit('change')
        if (!remain) this.showForm = false;
        this.adding = false;
        this.isValidated = false;
      } else {
        this.isValid = false;
      }
    },
    openForm(){
      this.showForm = true;
      this.$nextTick(() => {
        if (this.$refs.input) this.$refs.input.focus();
      });
    },
    clear() {
      this.temp = null;
      this.isValid = false;
      this.isValidated = false;
      this.showForm = false;
    },
    debouncer: debounce((cb, args) => cb(args), 500),
    validateDomain() {
      this.debouncer(() => {
        this.validating = true;
        setTimeout(() => {
          this.isValid = this.isDomain(this.temp);
          this.validating = false;
          this.isValidated = true;
        }, 300)
      })
    }
  },
  components: {
    ExitDialog,
    DeleteDomainModal,
    SidePanelLinkRow,
    FormControlText,
    FormControlTextarea,
  },
}
</script>
