<template>
  <svg class="icon-sort-alphabetical-reverse" xmlns="http://www.w3.org/2000/svg" width="13.705" height="14.932" viewBox="0 0 13.705 14.932">
    <g transform="translate(-1133.866 -422.04)">
      <path fill="#939393" d="M1147.351,431.738a.749.749,0,0,0-1.06,0h0l-1.446,1.446v-8.821a.75.75,0,0,0-1.5,0v8.821l-1.447-1.446a.75.75,0,1,0-1.061,1.061l2.727,2.727a.752.752,0,0,0,1.061,0l2.727-2.727A.75.75,0,0,0,1147.351,431.738Z"/>
      <path fill="#939393" transform="translate(0 8)" d="M1137.744,422.5a.75.75,0,0,0-1.381,0l-1.806,4.258-.631,1.49a.751.751,0,1,0,1.382.586h0l.266-.628a.485.485,0,0,0,.055.011h2.835a.574.574,0,0,0,.07-.014l.267.631a.75.75,0,0,0,1.382-.585Zm-1.539,4.216.848-2,.849,2Z"/>
      <path fill="#939393" transform="translate(0 -8)" d="M1139.27,435.473h-2.731l3.284-3.6a.749.749,0,0,0-.553-1.255h-4.432a.75.75,0,0,0,0,1.5h2.73l-3.284,3.594a.75.75,0,0,0,.554,1.256h4.432a.749.749,0,0,0,0-1.5Z"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-sort-alphabetical-reverse'
  }
</script>
