var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.219",
        height: "10"
      }
    },
    [
      _c("g", { attrs: { "data-name": "List Icon" } }, [
        _c(
          "g",
          {
            attrs: {
              "data-name": "Group 1891",
              transform: "translate(-10.031 -11.404)"
            }
          },
          [
            _c("rect", {
              attrs: {
                "data-name": "Rectangle 396",
                width: "4",
                height: "4",
                rx: "2",
                transform: "translate(10.031 11.404)",
                fill: "#939393"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                "data-name": "Line 124",
                fill: "none",
                stroke: "#939393",
                "stroke-linecap": "round",
                "stroke-width": "1.5",
                d: "M16.5 13.5h9"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "g",
          {
            attrs: {
              "data-name": "Group 1892",
              transform: "translate(-10.031 -5.404)"
            }
          },
          [
            _c("rect", {
              attrs: {
                "data-name": "Rectangle 396",
                width: "4",
                height: "4",
                rx: "2",
                transform: "translate(10.031 11.404)",
                fill: "#939393"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                "data-name": "Line 124",
                fill: "none",
                stroke: "#939393",
                "stroke-linecap": "round",
                "stroke-width": "1.5",
                d: "M16.5 13.5h9"
              }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }