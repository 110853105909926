<template>
  <svg class="icon-video-sm" xmlns="http://www.w3.org/2000/svg" width="15.49" height="11.407" viewBox="0 0 15.49 11.407">
    <path d="M9.655 4.194L7.546 3.001a1.736 1.736 0 00-2.591 1.51v2.392a1.736 1.736 0 002.591 1.509l2.11-1.2a1.737 1.737 0 00.652-.652 1.734 1.734 0 00-.654-2.363m-.653 1.626l-.088.088-2.109 1.2-.114.03-.206-.119-.03-.115V4.515a.237.237 0 01.235-.236l.115.031 2.111 1.193.119.205z" fill="currentColor"/>
    <path d="M12.24 0H3.25A3.25 3.25 0 000 3.25v4.904a3.251 3.251 0 003.25 3.25h8.99a3.25 3.25 0 003.25-3.25v-4.9A3.249 3.249 0 0012.24 0zm1.75 8.157a1.752 1.752 0 01-1.75 1.75H3.25a1.754 1.754 0 01-1.75-1.75V3.254a1.754 1.754 0 011.75-1.751h8.99a1.752 1.752 0 011.75 1.751z" fill="currentColor"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconVideoSm',
  };
</script>
