var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.45",
        height: "17.45",
        viewBox: "0 0 17.45 17.45"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M16.79 5.39a8.66 8.66 0 00-1.9-2.84A8.66 8.66 0 0012.06.66 8.65 8.65 0 008.72 0 8.65 8.65 0 005.4.66a8.66 8.66 0 00-2.84 1.9A8.68 8.68 0 00.66 5.37 8.64 8.64 0 000 8.72a8.64 8.64 0 00.66 3.34 8.69 8.69 0 001.9 2.83 8.65 8.65 0 002.83 1.9 8.67 8.67 0 003.33.66 8.67 8.67 0 003.34-.66 8.64 8.64 0 002.83-1.9 8.66 8.66 0 001.9-2.83 8.66 8.66 0 00.66-3.34 8.65 8.65 0 00-.66-3.33zm-1.4 6.1a7.15 7.15 0 01-1.56 2.34 7.13 7.13 0 01-2.34 1.57 7.15 7.15 0 01-2.77.55 7.15 7.15 0 01-2.76-.55 7.13 7.13 0 01-2.34-1.57 7.15 7.15 0 01-1.57-2.34 7.16 7.16 0 01-.55-2.77 7.17 7.17 0 01.55-2.76A7.16 7.16 0 013.62 3.6a7.13 7.13 0 012.34-1.56 7.15 7.15 0 012.76-.55 7.16 7.16 0 012.77.55 7.15 7.15 0 012.34 1.56 7.16 7.16 0 011.57 2.35 7.16 7.16 0 01.55 2.76 7.15 7.15 0 01-.55 2.77z",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M9.4 8.4V4.57a.75.75 0 00-.74-.75.75.75 0 00-.75.75v4.5l2.77 2.45a.75.75 0 001.06-.06.75.75 0 00-.07-1.06z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }