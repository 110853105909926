import { Mark } from 'prosemirror-to-html-js';
export default class Underline extends Mark {
  matching () {
    return this.mark.type === "underline";
  }

  tag () {
    return "u";
  }
}
