var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-text-bold",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "8.682",
        height: "12",
        viewBox: "0 0 8.682 12"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M0,0H4.712A4.421,4.421,0,0,1,7.517.75a2.927,2.927,0,0,1,.936,2.426,3.559,3.559,0,0,1-.3,1.6A2.75,2.75,0,0,1,7.13,5.823,2.4,2.4,0,0,1,8.3,6.794a3.459,3.459,0,0,1,.379,1.765,3.176,3.176,0,0,1-1.023,2.594A4.395,4.395,0,0,1,4.783,12H0ZM4.588,2.082H2.435V4.941H4.606q1.375,0,1.376-1.429a1.248,1.248,0,0,0-1.394-1.43m.071,4.906H2.435V9.917H4.659A1.808,1.808,0,0,0,5.832,9.6a1.464,1.464,0,0,0,.38-1.165q0-1.448-1.553-1.447",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }