export default {
  template: `
      <div class="ag-header-cell-inner" @click="onSortToggled($event)">
        <b-btn variant="icon primary mr-4" size="sm" v-if="params.enableMenu && params.menuIcon" ref="menuButton" class="ml-auto" @click.stop.prevent>
          <svg class="icon-sm" width="24" viewBox="0 0 24 24"><use :xlink:href="'#icon-' + params.menuIcon"></use></svg>
        </b-btn>
        <div class="ag-header-cell-label">{{ params.displayName }}</div>
        <div v-if="params.enableSorting && sortDirection === 'asc' && params.column.colId === currentSortedColumn" :class="ascSort" class="customSortDownLabel">
          <svg width="24" viewBox="0 0 24 24"><use xlink:href="#icon-triangle"></use></svg>
        </div>
        <div v-if="params.enableSorting && sortDirection === 'desc' && params.column.colId === currentSortedColumn" :class="descSort" class="customSortUpLabel">
          <svg width="24" viewBox="0 0 24 24"><use xlink:href="#icon-triangle-up"></use></svg>
        </div>
      </div>
    `,
  data: function () {
    return {
      ascSort: null,
      descSort: null,
      noSort: null,
      sortDirection: null
    };
  },
  beforeMount() {},
  mounted() {
    this.params.column.addEventListener('sortChanged', this.onSortChanged);
    this.onSortChanged();
  },
  computed: {
    currentSortedColumn() {
      return this.$store.getters['v2/audience/currentSortedColumn']
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('v2/audience/SET_CURRENT_SORTED_COLUMN', null)
    next();
  },
  methods: {
    getSortDirection() {
      if ( this.params.column.isSortAscending() ) return 'asc';
      if ( this.params.column.isSortDescending() ) return 'desc';

      return ''
    },
    onMenuClicked() {
      this.params.showColumnMenu(this.$refs.menuButton);
    },

    onSortChanged() {
      this.ascSort = this.descSort = this.noSort = 'inactive';
      if (this.params.column.isSortAscending()) {
        this.ascSort = 'active';
      } else if (this.params.column.isSortDescending()) {
        this.descSort = 'active';
      } else {
        this.noSort = 'active';
      }
    },

    onSortToggled(event) {
      this.$store.commit('v2/audience/SET_CURRENT_SORTED_COLUMN', this.params.column.colId)
      let sortDirection = this.getSortDirection() === 'desc' ? 'asc' : 'desc'
      this.sortDirection = sortDirection
      this.params.setSort(sortDirection, event.shiftKey);
    },

    onSortRequested(order, event) {
      this.params.setSort(order, event.shiftKey);
    },
  },
};
