var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-exit",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "14.325",
        height: "17.584",
        viewBox: "0 0 14.325 17.584"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(-2519.86 -531.985)" } }, [
        _c("g", { attrs: { transform: "translate(2.762 -48.377)" } }, [
          _c("path", {
            attrs: {
              d:
                "M2531.2,588.621l-2.9-2.9a.751.751,0,0,0-1.061,1.062h0l1.623,1.623h-6.3a.75.75,0,0,0,0,1.5h6.3l-1.615,1.616a.751.751,0,0,0,1.061,1.062l2.9-2.9a.752.752,0,0,0,0-1.061Z",
              fill: "currentColor"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M2530.165,593.426a.749.749,0,0,0-.75.75v1.337h0a.938.938,0,0,1-.935.934h-8.948a.937.937,0,0,1-.935-.934V582.8a.937.937,0,0,1,.935-.935h8.948a.937.937,0,0,1,.935.935v1.338a.75.75,0,0,0,1.5,0h0V582.8a2.433,2.433,0,0,0-2.434-2.434h-8.948a2.433,2.433,0,0,0-2.434,2.434v12.717a2.433,2.433,0,0,0,2.434,2.433h8.948a2.434,2.434,0,0,0,2.434-2.433h0v-1.337A.749.749,0,0,0,2530.165,593.426Z",
              fill: "currentColor"
            }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }