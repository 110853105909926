var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Image_File_Icon",
        "data-name": "Image File Icon",
        xmlns: "http://www.w3.org/2000/svg",
        width: "15.969",
        height: "18.581",
        viewBox: "0 0 15.969 18.581"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Path_6983",
          "data-name": "Path 6983",
          d:
            "M494.554,1147.9h-6.928a.75.75,0,0,0,0,1.5h6.928a.75.75,0,0,0,0-1.5Z",
          transform: "translate(-483.108 -1134.778)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_6984",
          "data-name": "Path 6984",
          d:
            "M499.077,1146.145v-4.787a3.7,3.7,0,0,0-1.086-2.62l-2.878-2.875a3.706,3.706,0,0,0-2.62-1.085h-6.352v0a3.034,3.034,0,0,0-3.033,3.036v12.51h0a3.034,3.034,0,0,0,3.034,3.034h9.9a3.035,3.035,0,0,0,3.035-3.035v-4.179Zm-12.936-9.867h6.352a2.206,2.206,0,0,1,1.56.646l2.878,2.876a2.2,2.2,0,0,1,.646,1.558v2.978l-3.456-3.457a.75.75,0,0,0-1.061,0l-2.072,2.072-2.906-2.906a.749.749,0,0,0-1.06,0l-2.414,2.414v-4.645A1.536,1.536,0,0,1,486.141,1136.278Zm9.9,15.581h-9.9a1.537,1.537,0,0,1-1.534-1.534h0v-5.743l2.944-2.944,2.906,2.906a.752.752,0,0,0,1.061,0l2.071-2.072,3.987,3.986v3.867A1.538,1.538,0,0,1,496.042,1151.859Z",
          transform: "translate(-483.108 -1134.778)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }