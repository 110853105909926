var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-overcast",
      attrs: { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "18" }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M9 18a9 9 0 119-9 8.978 8.978 0 01-9 9zm0-3.562l.675-.675L9 11.176l-.675 2.587zm-.862-.038l-.337 1.275.787-.787zm1.725 0l-.45.45.787.787zm.638 2.325l-1.5-1.5-1.5 1.5a9.652 9.652 0 001.5.15 6.568 6.568 0 001.499-.15zm-1.5-15.6a7.868 7.868 0 00-2.587 15.3l1.688-6.262a1.457 1.457 0 01-.6-1.2 1.5 1.5 0 112.4 1.2l1.687 6.262a7.868 7.868 0 00-2.588-15.3zM14.139 12.9a.582.582 0 01-.787.113.51.51 0 01-.113-.75 5.763 5.763 0 000-6.525.51.51 0 01.113-.75.582.582 0 01.787.113 6.764 6.764 0 010 7.8zm-2.737-1.462a.637.637 0 01.037-.862 2.447 2.447 0 00.563-1.575 2.574 2.574 0 00-.563-1.575.678.678 0 01-.037-.862.517.517 0 01.787-.037 3.736 3.736 0 010 4.95.545.545 0 01-.789-.04zm-4.8 0a.545.545 0 01-.787.037 3.736 3.736 0 010-4.95.516.516 0 01.787.037.637.637 0 01-.037.862 2.575 2.575 0 00-.562 1.575 2.447 2.447 0 00.562 1.575.635.635 0 01.035.863zM4.65 13.014a.582.582 0 01-.787-.113 6.764 6.764 0 010-7.8.582.582 0 01.787-.113.51.51 0 01.112.75 5.762 5.762 0 000 6.525.541.541 0 01-.112.751z",
          fill: "#fc7e0f"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }