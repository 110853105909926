var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-theme",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20.118",
        height: "22.258",
        viewBox: "0 0 20.118 22.258"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M17.885 11.92a.751.751 0 00.293-1.242L7.719.219a.751.751 0 00-1.242.293l-.839 2.515-1.326-1.326a.75.75 0 00-1.061 1.061l1.433 1.433L.807 8.072a2.759 2.759 0 000 3.9l5.619 5.618a2.758 2.758 0 003.9 0l4.724-4.724zM1.867 9.134l3.878-3.878 1.434 1.433a.75.75 0 001.06-1.061L6.785 4.174a.734.734 0 00.113-.183l.618-1.853 8.742 8.742-1.738.581H2.42l-.551-.55a1.258 1.258 0 010-1.777zm7.4 7.4a1.258 1.258 0 01-1.778 0l-3.568-3.568h8.914z",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M18.534 14.792a10.924 10.924 0 00-1.537-1.589.748.748 0 00-.943 0 10.985 10.985 0 00-1.537 1.589 6.5 6.5 0 00-1.581 3.878 3.588 3.588 0 003.589 3.588 3.589 3.589 0 003.589-3.588 6.506 6.506 0 00-1.58-3.878zm-2.006 5.967a2.093 2.093 0 01-2.089-2.089 5.077 5.077 0 011.258-2.947 9.79 9.79 0 01.832-.92 9.472 9.472 0 01.955 1.079 4.905 4.905 0 011.136 2.788 2.093 2.093 0 01-2.092 2.089z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }