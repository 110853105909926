var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.765",
        height: "17.654",
        viewBox: "0 0 17.765 17.654"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Today_Icon",
            "data-name": "Today Icon",
            transform: "translate(0.499 0.5)"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "Union_26",
              "data-name": "Union 26",
              d:
                "M11738.3,24361.654a4.262,4.262,0,0,1-4.262-4.262v-6.205a4.262,4.262,0,0,1,4.262-4.262h.373v-1.174a.752.752,0,0,1,1.5,0v1.174h4.487v-1.174a.752.752,0,0,1,1.5,0v1.174h.372a4.261,4.261,0,0,1,4.262,4.262v6.205a4.261,4.261,0,0,1-4.262,4.262Zm-2.764-10.467v6.205a2.763,2.763,0,0,0,2.764,2.758h8.241a2.758,2.758,0,0,0,2.758-2.758v-6.205a2.767,2.767,0,0,0-2.758-2.764h-.372v1.18a.752.752,0,0,1-1.5,0v-1.18h-4.487v1.18a.752.752,0,0,1-1.5,0v-1.18h-.373A2.772,2.772,0,0,0,11735.534,24351.188Zm2.623,2.139v-1.02a.749.749,0,1,1,1.5,0v1.02a.749.749,0,1,1-1.5,0Z",
              transform: "translate(-11734.036 -24345)",
              fill: "#939393",
              stroke: "rgba(0,0,0,0)",
              "stroke-miterlimit": "10",
              "stroke-width": "1"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }