var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-arrow-right-small",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "4.515",
        height: "7.531",
        viewBox: "0 0 4.515 7.531"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M.22 1.281l2.484 2.485L.22 6.251a.75.75 0 001.061 1.061l3.014-3.016a.749.749 0 000-1.06L1.281.22A.75.75 0 00.22 1.281z",
          fill: "#fff"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }