var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-menu-hubs",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "28",
        height: "23",
        viewBox: "0 0 28 23"
      }
    },
    [
      _c("rect", {
        attrs: { width: "28", height: "23", fill: "#fff", opacity: "0" }
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cl-3",
        attrs: {
          d:
            "M47.014,196.063h0l-2.493-1.386a1.909,1.909,0,0,0-.783-.23v-.006a.753.753,0,0,0-.082-.007h-.005c-.043,0-.073,0-.073,0a1.923,1.923,0,0,0-1.921,1.851v.025l-.011,2.77v.005a1.875,1.875,0,0,0,.256.94l.007.012a1.947,1.947,0,0,0,2.588.7h0l2.493-1.369h0l.006,0h0a1.913,1.913,0,0,0,.733-.7l.008-.013.019-.034.013-.023a1.873,1.873,0,0,0-.758-2.524Zm-.736,1.979-2.5,1.372,0,0-.211.054a.446.446,0,0,1-.375-.208l-.049-.18.011-2.741a.424.424,0,0,1,.422-.407h0l.016,0,.192.054,0,0,2.494,1.386.009,0a.373.373,0,0,1,.2.329l-.045.178-.161.153Z",
          transform: "translate(-36 -183.677)",
          fill: "#7b88ff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M44.52,194.677h0Z",
          transform: "translate(-36 -183.677)",
          fill: "#293ad3"
        }
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cl-3",
        attrs: {
          d: "M57.592,196.949h-6.7a.75.75,0,0,0,0,1.5h6.7a.75.75,0,0,0,0-1.5Z",
          transform: "translate(-36 -183.677)",
          fill: "#7b88ff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M58.62,184.677H41.368A3.369,3.369,0,0,0,38,188.045V202.3a3.367,3.367,0,0,0,3.368,3.367H58.62a3.367,3.367,0,0,0,3.368-3.367V188.045A3.369,3.369,0,0,0,58.62,184.677ZM60.488,202.3a1.871,1.871,0,0,1-1.868,1.868H41.368A1.871,1.871,0,0,1,39.5,202.3V191.229H57.592a.75.75,0,0,0,0-1.5H39.5v-1.684a1.871,1.871,0,0,1,1.868-1.868H58.62a1.871,1.871,0,0,1,1.868,1.868Z",
          transform: "translate(-36 -183.677)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }