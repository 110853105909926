var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19",
        height: "19",
        viewBox: "0 0 19 19"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M14.574,181.4H5.426A4.934,4.934,0,0,0,.5,186.326v9.148A4.934,4.934,0,0,0,5.426,200.4h9.148a4.934,4.934,0,0,0,4.926-4.926v-9.148A4.934,4.934,0,0,0,14.574,181.4Zm3.418,14.175a3.39,3.39,0,0,1-3.418,3.418H5.426a3.39,3.39,0,0,1-3.418-3.418v-5.228H4.32a3.116,3.116,0,0,0,0-6.233H2.712a3.669,3.669,0,0,1,2.614-1.307h9.148a3.39,3.39,0,0,1,3.418,3.418v9.349Z",
          transform: "translate(-0.5 -181.4)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }