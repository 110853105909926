var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20.5",
        height: "14.974",
        viewBox: "0 0 20.5 14.974"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Group_7412",
            "data-name": "Group 7412",
            transform: "translate(-1302.25 -562.412)"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "Polygon_2",
              "data-name": "Polygon 2",
              d:
                "M1314.93,567.594a1.109,1.109,0,0,1,0,1.93l-2.375,1.346a1.11,1.11,0,0,1-1.657-.965V567.21a1.109,1.109,0,0,1,1.657-.963Z",
              transform: "translate(0.742 1.341)",
              fill: "#979797"
            }
          }),
          _vm._v(" "),
          _c("line", {
            attrs: {
              id: "Line_275",
              "data-name": "Line 275",
              y2: "3.881",
              transform: "translate(1309.766 567.958)",
              fill: "none",
              stroke: "#979797",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "1.5"
            }
          }),
          _vm._v(" "),
          _c("rect", {
            attrs: {
              id: "Rectangle_1250",
              "data-name": "Rectangle 1250",
              width: "19",
              height: "13.474",
              rx: "3.415",
              transform: "translate(1303 563.162)",
              "stroke-width": "1.5",
              stroke: "#979797",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              fill: "none"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }