var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "25",
        height: "17.798",
        viewBox: "0 0 24.811 17.798"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Storage_Cloud_Bar_Icon",
            "data-name": "Storage Cloud Bar Icon",
            transform: "translate(0 0)"
          }
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Mask_Group_34",
                "data-name": "Mask Group 34",
                transform: "translate(0.339 0)"
              }
            },
            [
              _c("path", {
                attrs: {
                  id: "Path_800",
                  "data-name": "Path 800",
                  d:
                    "M1028.627,194.881a5.8,5.8,0,0,0-2.082.4,6.918,6.918,0,0,0-13.52,2.078c0,.186.013.372.028.556a4.407,4.407,0,0,0-3.254,4.25c0,.116,0,.241.016.375h0v.009h0a4.283,4.283,0,0,0,1.515,2.9,4.742,4.742,0,0,0,3.07,1.129h14.257a5.826,5.826,0,0,0,5.82-5.827v-.023A5.854,5.854,0,0,0,1028.627,194.881Z",
                  transform: "translate(-1010.138 -190.435)",
                  fill: "#c7cbff"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_799",
              "data-name": "Path 799",
              d:
                "M1028.627,194.881a5.8,5.8,0,0,0-2.082.4,6.918,6.918,0,0,0-13.52,2.078c0,.186.013.372.028.556a4.407,4.407,0,0,0-3.254,4.25c0,.116,0,.241.016.375h0v.009h0a4.283,4.283,0,0,0,1.515,2.9,4.742,4.742,0,0,0,3.07,1.129h14.257a5.826,5.826,0,0,0,5.82-5.827v-.023A5.854,5.854,0,0,0,1028.627,194.881Zm4.184,5.875a4.157,4.157,0,0,1-4.151,4.158H1014.4a3.079,3.079,0,0,1-1.988-.73,2.611,2.611,0,0,1-.937-1.778v-.01l-.009-.227a2.739,2.739,0,0,1,2.57-2.733.833.833,0,0,0,.765-1,5.363,5.363,0,0,1-.109-1.075,5.256,5.256,0,0,1,10.457-.756.835.835,0,0,0,1.273.585,4.173,4.173,0,0,1,6.387,3.542Z",
              transform: "translate(-1009.799 -190.435)",
              fill: "#293ad3"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }