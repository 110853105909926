var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Profile_Picture_Empty",
        "data-name": "Profile Picture Empty",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "34",
        height: "34",
        viewBox: "0 0 34 34"
      }
    },
    [
      _c(
        "defs",
        [
          _c("clipPath", { attrs: { id: "clip-path" } }, [
            _c("circle", {
              attrs: {
                id: "jake-davies-FoVlxDABKQc-unsplash",
                cx: "17",
                cy: "17",
                r: "17",
                fill: "#fafafa",
                stroke: "#f0f0f0",
                "stroke-width": "1.5"
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "linear-gradient",
                x1: "0.403",
                y1: "0.741",
                x2: "1.238",
                y2: "-1.918",
                gradientUnits: "objectBoundingBox"
              }
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-opacity": "0" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-opacity": "0.502" } })
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "linear-gradient-2",
              x1: "0.414",
              y1: "1",
              x2: "1.964",
              y2: "-1.85",
              "xlink:href": "#linear-gradient"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "CIRCLE",
            fill: "#fafafa",
            stroke: "rgba(0,0,0,0.1)",
            "stroke-width": "1.5"
          }
        },
        [
          _c("circle", {
            attrs: { cx: "17", cy: "17", r: "17", stroke: "none" }
          }),
          _vm._v(" "),
          _c("circle", {
            attrs: { cx: "17", cy: "17", r: "16.25", fill: "none" }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Mask_Group_65",
            "data-name": "Mask Group 65",
            "clip-path": "url(#clip-path)"
          }
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Group_4378",
                "data-name": "Group 4378",
                transform: "translate(4.331 6.324)"
              }
            },
            [
              _c("path", {
                attrs: {
                  id: "Path_634",
                  "data-name": "Path 634",
                  d:
                    "M337.508,387.6a12.481,12.481,0,0,0-12.48,12.48v1.564a3.811,3.811,0,0,0,3.811,3.809H346.18a3.811,3.811,0,0,0,3.809-3.809v-1.564A12.482,12.482,0,0,0,337.508,387.6Z",
                  transform: "translate(-325.028 -373.035)",
                  fill: "url(#linear-gradient)"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "Path_635",
                  "data-name": "Path 635",
                  d:
                    "M334.8,392.868a6.574,6.574,0,1,0-6.573-6.573A6.573,6.573,0,0,0,334.8,392.868Z",
                  transform: "translate(-322.316 -379.72)",
                  fill: "url(#linear-gradient-2)"
                }
              })
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }