<template>
  <svg class="icon-proofread-pending-small" xmlns="http://www.w3.org/2000/svg" width="15.221" height="11.75" viewBox="0 0 15.221 11.75"><path d="M2336.748,730.906a2.7,2.7,0,1,0,0-5.406v0h0a2.7,2.7,0,0,0,0,5.406Zm0-3.906h0a1.2,1.2,0,1,1-1.2,1.2A1.2,1.2,0,0,1,2336.751,727Z" transform="translate(-2332.3 -725.5)" fill="currentColor"/><path d="M2341.869,727h4.9a.75.75,0,0,0,0-1.5h-4.9a.75.75,0,0,0,0,1.5Z" transform="translate(-2332.3 -725.5)" fill="currentColor"/><path d="M2347.521,729.4a.75.75,0,0,0-.75-.75h-1.936c-.014,0-.026.007-.039.007a4.481,4.481,0,0,0-1.69-.35l-.044-.006h-.036a4.423,4.423,0,0,1-6.275,2.761,4.451,4.451,0,0,0-4.451,4.45v.6a1.149,1.149,0,0,0,1.142,1.14h6.62a1.127,1.127,0,0,0,.928-.521,4.453,4.453,0,0,0,5.661-6.583h.12A.75.75,0,0,0,2347.521,729.4Zm-10.77,3.162a2.94,2.94,0,0,1,1.843.646c.006,0,.013.005.02.01a4.444,4.444,0,0,0,1.088,2.509v.027h-5.9v-.241A2.956,2.956,0,0,1,2336.751,732.558Zm2.95,3.543h0v0Zm3.336-.351a2.976,2.976,0,0,1-1.127-5.728h0a2.955,2.955,0,0,1,1.125-.225h.013a2.977,2.977,0,1,1-.013,5.953Z" transform="translate(-2332.3 -725.5)" fill="currentColor"/><path d="M2341.337,728.636Z" transform="translate(-2332.3 -725.5)" fill="currentColor"/><path d="M2343.769,732.444v-1.611a.75.75,0,1,0-1.5,0h0v1.95a.752.752,0,0,0,.254.562l1.177,1.038a.75.75,0,0,0,.993-1.124Z" transform="translate(-2332.3 -725.5)" fill="currentColor"/></svg>

</template>

<script>
  export default {
    name: 'IconProofreadPendingSmall'
  }
</script>
