var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "23.252",
        height: "17.583",
        viewBox: "0 0 23.252 17.583"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Group_4827",
            "data-name": "Group 4827",
            transform: "translate(-37.258 835.641)"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "Path_1252",
              "data-name": "Path 1252",
              d:
                "M49.2-834.266l-5.266,2.372H42.077a5.049,5.049,0,0,0-4.819,5.041c0,.069,0,.149.006.235h0v.014h0a5.049,5.049,0,0,0,4.812,4.8h1.855l5.267,2.371a.746.746,0,0,0,.715-.054.747.747,0,0,0,.343-.63v-13.465a.748.748,0,0,0-.342-.63A.748.748,0,0,0,49.2-834.266Zm-.442,12.989L44.4-823.238a.761.761,0,0,0-.308-.066H42.142a3.548,3.548,0,0,1-3.38-3.383v-.014l0-.152a3.549,3.549,0,0,1,3.385-3.542h1.952a.762.762,0,0,0,.308-.066l4.355-1.961Z",
              fill: "#979797"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_1253",
              "data-name": "Path 1253",
              d:
                "M55.876-826.849a7.465,7.465,0,0,0-2.192-5.295.75.75,0,0,0-1.061,0,.75.75,0,0,0,0,1.061,5.965,5.965,0,0,1,1.754,4.234,5.973,5.973,0,0,1-1.754,4.235.75.75,0,0,0,0,1.061.751.751,0,0,0,1.061,0h0A7.472,7.472,0,0,0,55.876-826.849Z",
              fill: "#979797"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_1254",
              "data-name": "Path 1254",
              d:
                "M60.51-826.855a12.087,12.087,0,0,0-3.549-8.565h0a.75.75,0,0,0-1.061,0,.75.75,0,0,0,0,1.061h0a10.578,10.578,0,0,1,3.11,7.5,10.594,10.594,0,0,1-3.111,7.516.748.748,0,0,0,0,1.061.75.75,0,0,0,1.061,0h0A12.1,12.1,0,0,0,60.51-826.855Z",
              fill: "#979797"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }