import Base from '@app2/models/Base';

export default class Template extends Base {
  static primaryKey = 'id';
  static entity = 'App\\Template';

  static apiConfig = {
    baseURL: '/api/internal/v2/templates/',
    actions: {
      fetch: {
        url: '/',
        dataKey: 'data',
      },
      tags() {
        return this.get('/tags')
      },
    }
  };

  watchFields = [
    'title',
    'description',
    'settings',
  ];

  static fields() {
    return {
      id: this.attr(null),
      hash: this.attr(null),
      author: this.attr(null),
      title: this.attr(null),
      type: this.attr(null),
      description: this.attr(null),
      hub_template_url: this.attr(null),
      thumb_url: this.attr(null),
      is_featured: this.boolean(false),
      settings: this.attr({}),
      hub_hash: this.attr(null),
      tags: this.attr(null),
      hub: this.attr(null),
      slug: this.attr(null),
      ...super.fields(),
    };
  }
  static mutators() {
    return {
      settings(value) {
        if ( !value || Array.isArray(value) ) return {};
        return value;
      },
    };
  }
}
