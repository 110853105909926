var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-menu-media",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "28",
        height: "23",
        viewBox: "0 0 28 23"
      }
    },
    [
      _c("rect", {
        attrs: { width: "28", height: "23", fill: "#fff", opacity: "0" }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M136.533,54.966h-17.3a3.344,3.344,0,0,0-3.343,3.345V68.943a3.35,3.35,0,0,0,3.35,3.344h17.293a3.344,3.344,0,0,0,3.344-3.344V58.31A3.345,3.345,0,0,0,136.533,54.966Zm1.843,13.977a1.847,1.847,0,0,1-1.844,1.844H119.239a1.852,1.852,0,0,1-1.851-1.846V58.311a1.847,1.847,0,0,1,1.844-1.845h17.3a1.847,1.847,0,0,1,1.843,1.844Z",
          transform: "translate(-113.889 -51.967)",
          fill: "#293ad3"
        }
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cl-3",
        attrs: {
          d:
            "M130.08,61.975v0l-2.514-1.385,0,0a1.933,1.933,0,0,0-2.876,1.625v2.808a1.882,1.882,0,0,0,.26.942l.007.011a1.952,1.952,0,0,0,2.6.691h0l2.513-1.389v0a1.9,1.9,0,0,0,.735-.705v0l.02-.034.008-.012.009-.018a1.875,1.875,0,0,0-.77-2.529Zm-3.241,3.379,0,0-.216.056a.448.448,0,0,1-.379-.21l-.051-.181V62.252a.417.417,0,0,1,.427-.407l.216.054,0,0,2.514,1.385.01.005a.379.379,0,0,1,.2.335l-.045.176,0,0-.158.151Z",
          transform: "translate(-113.889 -51.967)",
          fill: "#7b88ff"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }