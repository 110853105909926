<template>
  <svg class="icon-page-lg" xmlns="http://www.w3.org/2000/svg" width="18.991" height="17" viewBox="0 0 18.991 16.966">
    <path d="M15.884 0H3.11A3.107 3.107 0 0 0 .002 3.107v10.754a3.107 3.107 0 0 0 3.108 3.106h12.776a3.107 3.107 0 0 0 3.107-3.106V3.108A3.107 3.107 0 0 0 15.884 0ZM3.108 1.5h12.776a1.61 1.61 0 0 1 1.607 1.608v3.658H1.5V3.108a1.611 1.611 0 0 1 1.61-1.609Zm12.776 13.968H3.11a1.611 1.611 0 0 1-1.608-1.607v-5.6h15.991v5.595a1.61 1.61 0 0 1-1.609 1.611Z" fill="currentColor"/>
    <path d="M4.51 5.024h10.059a.75.75 0 0 0 0-1.5H4.51a.75.75 0 1 0 0 1.5Z" fill="currentColor"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-page-lg'
  }
</script>
