<template>
  <b-modal :id="id" :static="static" centered ref="modal" size="sm" @hidden="$emit('hidden')">
    <template slot="modal-header" slot-scope="{ close }">
      <h5 class="txt-body txt-red-3">{{ title }}</h5>
      <b-button variant="icon warning" size="sm" @click="close()" class="close">
        <svg class="icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-cross"></use></svg>
      </b-button>
    </template>
    <div :class="$store.getters['isMobile'] ? 'txt-body' : 'txt-heading-small'" v-if="customText" v-html="customText"></div>
    <div :class="$store.getters['isMobile'] ? 'txt-body' : 'txt-heading-small'" v-if="resourceName && extraInfo && !customText">{{ extraInfo }}</div>
    <div :class="$store.getters['isMobile'] ? 'txt-body' : 'txt-heading-small'" v-if="resourceName && !customText">Are you sure want to {{ action }} <b>"{{ resourceName }}"</b>?</div>
    <slot></slot>
    <template slot="modal-footer">
      <b-btn @click="$bvModal.hide(id)" variant="warning" :disabled="processing">
        Cancel
      </b-btn>
      <b-btn
        :disabled="disabled || processing"
        variant="danger"
        @click="$emit('accept')"
      >
        {{ deleteButtonLabel }}
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import eventBus from "@app2/core/eventBus";

export default {
  name: 'DeleteDialog',
  props: {
    title: {
      type: String,
      required: true
    },
    id: {},
    resourceName: {},
    processing: {},
    disabled: {
      type: Boolean,
      default: false
    },
    static: {
      type: Boolean,
      default: true
    },
    deleteButtonLabel: {
      type: String,
      default: 'Delete'
    },
    action: {
      type: String,
      default: 'delete'
    },
    extraInfo: {
      type: String,
      default: ''
    },
    customText: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      eventBus.$emit('reset-highlight');
    })
  }
};
</script>
