import hubs from '@app2/modules/Hubs/hubs.store';
import library from '@app2/modules/Library/library.store';
import audience from '@app2/modules/Audience/audience.store';
import apps from '@app2/modules/Apps/apps.store';
import widgets from '@app2/modules/Widgets/widgets.store';
import wisdom from '@app2/modules/Wisdom/wisdom.store';
import account from '@app2/modules/AccountSettings/account.store';
import data from '@app2/modules/Data/data.store';
import transcriptEditor from '@app2/modules/Library/Media/TranscriptEditor/editor.store';
import dashboard from '@app2/modules/Dashboard/dashboard.store';
import webhooks from '@app2/modules/Webhooks/webhooks.store';
import achievements from '@app2/modules/Achievements/SlideIn/achievements.store';
import sitemaps from '@app2/modules/Sitemaps/sitemap.store';
import register from '@app2/modules/Register/register.store';
import spotlight from '@app2/modules/Spotlight/spotlight.store';
import router from "@app2/router";
import axios from "axios";
import debounce from "lodash/debounce";

const namespaced = true;

const state = {
  editors: {
    widget: false,
    transcript: false,
    wisdom: false,
  },
  modals: {
    widget: false,
    transcript: false,
    wisdom: false,
  },
  tempRoute: null,
  googleFonts: {
    featured: [],
    all: [],
  },
};

const mutations = {
  STORE_EDITOR_UPDATE(state, payload) {
    state.editors[payload.key] = payload.value;
  },
  STORE_TEMP_ROUTE(state, payload) {
    state.tempRoute = payload;
  },
  STORE_MODAL_STATE(state, payload) {
    state.modals[payload.key] = payload.value;
  },
  STORE_GOOGLE_FONTS(state, payload) {
    state.googleFonts = payload;
  },
};

const actions = {
  goToRoute({state, commit}) {
    if ( state.tempRoute ) router.push(state.tempRoute);
    commit('STORE_TEMP_ROUTE', null);
    commit('STORE_EDITOR_UPDATE', { key: 'widget', value: false });
    commit('STORE_EDITOR_UPDATE', { key: 'transcript', value: false });
    commit('STORE_EDITOR_UPDATE', { key: 'wisdom', value: false });
  },
  async getGoogleFonts({state, commit}) {
    const debouncer = debounce((cb, args) => cb(args), 300);
    if ( state.googleFonts.all.length ) return;
    debouncer(async () => {
      const fonts = await axios.get('https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyBAMlDAo3TNfGVzHkh4QYE4gvXpUmH0DfE');
      commit('STORE_GOOGLE_FONTS', {
        featured: fonts.data.items.slice(0,100).sort((a, b) => a.family.localeCompare(b.family)),
        all: fonts.data.items.sort((a, b) => a.family.localeCompare(b.family)),
      });
    });
  }
};

const getters = {

};

const modules = {
  hubs,
  audience,
  apps,
  transcriptEditor,
  library,
  account,
  widgets,
  wisdom,
  dashboard,
  data,
  webhooks,
  achievements,
  sitemaps,
  register,
  spotlight,
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
  modules,
};
