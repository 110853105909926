var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.764",
        height: "16.651",
        viewBox: "0 0 16.764 16.651"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(-1062.673 -402.102)" } }, [
        _c("path", {
          attrs: {
            d:
              "M1075.177,404.028h-.376v-1.176a.75.75,0,1,0-1.5,0v1.176h-4.491v-1.176a.75.75,0,1,0-1.5,0v1.176h-.377a4.26,4.26,0,0,0-4.26,4.26v6.206a4.259,4.259,0,0,0,4.259,4.259h8.245a4.26,4.26,0,0,0,4.26-4.259v-6.206A4.261,4.261,0,0,0,1075.177,404.028Zm2.76,10.466a2.764,2.764,0,0,1-2.76,2.76h-8.244a2.765,2.765,0,0,1-2.762-2.76v-6.206a2.765,2.765,0,0,1,2.762-2.761h.377V406.7a.75.75,0,0,0,1.5,0v-1.178h4.49V406.7a.75.75,0,0,0,1.5,0v-1.178h.376a2.767,2.767,0,0,1,2.761,2.761Z",
            fill: "#939393"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1067.537,408.658a.75.75,0,0,0-.75.75v1.019a.75.75,0,0,0,1.5,0v-1.019A.75.75,0,0,0,1067.537,408.658Z",
            fill: "#939393"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1073.8,413.626l-1.59-1.59,1.59-1.591a.75.75,0,1,0-1.061-1.06l-2.121,2.12a.749.749,0,0,0,0,1.061l2.121,2.122a.75.75,0,0,0,1.061-1.061Z",
            fill: "#939393"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }