<template>
</template>

<script>
  export default {
    name: 'AuthPopup',
    created() {
      if (!window.opener) return;
      window.opener.postMessage({authDone: true}, '*');
    }
  };
</script>
