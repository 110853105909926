var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "41",
        height: "23",
        viewBox: "0 0 41 23"
      }
    },
    [
      _c("g", { staticClass: "bars", attrs: { transform: "translate(0 3)" } }, [
        _c("rect", {
          attrs: {
            id: "Rectangle_1550",
            "data-name": "Rectangle 1550",
            width: "3",
            height: "18",
            rx: "1.5",
            transform: "translate(29 2)",
            fill: "#2b994f",
            opacity: "0.1"
          }
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            id: "Rectangle_1552",
            "data-name": "Rectangle 1552",
            width: "3",
            height: "23",
            rx: "1.5",
            transform: "translate(10 -3)",
            fill: "#2b994f",
            opacity: "0.1"
          }
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            id: "Rectangle_1551",
            "data-name": "Rectangle 1551",
            width: "3",
            height: "14",
            rx: "1.5",
            transform: "translate(38 6)",
            fill: "#2b994f"
          }
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            id: "Rectangle_1553",
            "data-name": "Rectangle 1553",
            width: "3",
            height: "15",
            rx: "1.5",
            transform: "translate(19 5)",
            fill: "#2b994f",
            opacity: "0.1"
          }
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            id: "Rectangle_1555",
            "data-name": "Rectangle 1555",
            width: "3",
            height: "10",
            rx: "1.5",
            transform: "translate(0 10)",
            fill: "#2b994f",
            opacity: "0.1"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }