import Vue from 'vue';

const namespaced = true;

const state = {
  bus: new Vue(),
  media: null,
};

const mutations = {
  RESET_BUS(state) {
    state.bus = null;
    state.bus = new Vue();
  },
  SET_MEDIA(state, payload) {
    state.media = payload;
  },
};

export default {
  namespaced,
  state,
  mutations,
};
