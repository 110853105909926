const namespaced = true;

const state = {
  isModalOpen: false,
  breadcrumbs: [],
  recursiveRoutes: [],
};

const mutations = {
  SET_MODAL_STATE(state, payload) {
    state.isModalOpen = payload;
  },
}

const actions = {

}

const getters = {
  
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
