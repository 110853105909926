var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.792",
        height: "17.792",
        viewBox: "0 0 17.792 17.792"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M117.049,892.152a8.9,8.9,0,1,0,8.9,8.9A8.9,8.9,0,0,0,117.049,892.152Zm-5.23,14.126a7.395,7.395,0,0,1-.485-9.923,4.131,4.131,0,0,0,.153.5c1,.245,2.528-.655,1.6,1.292-1.057,1.842,2.292,3.2,2.4,3.292.151.311.208,1.031.664,1.34.525.368,1.107.19,1.027.91.033,1.205-.408,4.367.965,4.669a7.294,7.294,0,0,1-6.324-2.077Zm10.46,0a7.392,7.392,0,0,1-3.143,1.863c.517-1.044.175-2.295.6-3.249.387-.791,1.352-1.448,1.346-2.514.088-.793-.847-1.023-1.382-1.172-.462-1.111-1.782-1.811-2.8-.792-.409.282-.684.455-1.034.294-.071-.474-.426-.9-.46-1.4a1.6,1.6,0,0,0,1.864-.486c1.247-.26,4.286-.637,2.7-2.656a3.98,3.98,0,0,1-.922-2.231,7.392,7.392,0,0,1,3.222,12.343Z",
          transform: "translate(-108.153 -892.152)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }