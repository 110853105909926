<template>
  <svg class="icon-section-column" xmlns="http://www.w3.org/2000/svg" width="20" height="16.5" viewBox="0 0 19.95 16.5">
    <path d="M17.2 0H2.75A2.748 2.748 0 0 0 0 2.75v11a2.749 2.749 0 0 0 2.75 2.749H17.2a2.75 2.75 0 0 0 2.75-2.749v-11A2.75 2.75 0 0 0 17.2 0Zm-4.376 1.5V15h-5.7V1.5ZM1.499 13.751v-11A1.255 1.255 0 0 1 2.75 1.5h2.875V15H2.75a1.253 1.253 0 0 1-1.251-1.25Zm16.951 0a1.252 1.252 0 0 1-1.25 1.25h-2.876v-13.5H17.2a1.254 1.254 0 0 1 1.25 1.251Z" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-section-column'
  }
</script>
