var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "hub-badge-shape",
      attrs: { width: "79", height: "79", viewBox: "0 0 79 79" }
    },
    [
      _vm.element == "shape-1"
        ? [
            _c("path", {
              attrs: {
                d:
                  "M37.4795 0.704781C38.8725 -0.0943015 40.5886 -0.0943025 41.9815 0.70478L71.9816 17.915C73.3745 18.7141 74.2326 20.1908 74.2326 21.789V56.2094C74.2326 57.8075 73.3745 59.2843 71.9816 60.0834L41.9815 77.2936C40.5886 78.0927 38.8725 78.0927 37.4795 77.2936L7.47952 60.0834C6.08659 59.2843 5.22852 57.8075 5.22852 56.2094V21.789C5.22852 20.1908 6.08659 18.7141 7.47952 17.915L37.4795 0.704781Z",
                fill: _vm.outlineColor
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M37.8099 6.32775C38.9983 5.64596 40.4626 5.64596 41.6511 6.32775L67.2474 21.0117C68.4359 21.6935 69.168 22.9535 69.168 24.317V53.6849C69.168 55.0485 68.4359 56.3084 67.2474 56.9902L41.6511 71.6742C40.4626 72.3559 38.9984 72.3559 37.8099 71.6742L12.2135 56.9902C11.0251 56.3084 10.293 55.0485 10.293 53.6849V24.317C10.293 22.9535 11.0251 21.6935 12.2135 21.0117L37.8099 6.32775Z",
                fill: _vm.baseColor
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.element == "shape-2"
        ? [
            _c("path", {
              attrs: {
                d:
                  "M5.41211 13.2088C5.41211 8.34513 9.38017 4.40234 14.275 4.40234H66.1863C71.0811 4.40234 75.0492 8.34513 75.0492 13.2088V64.7894C75.0492 69.6531 71.0811 73.5959 66.1863 73.5959H14.275C9.38017 73.5959 5.41211 69.6531 5.41211 64.7894V13.2088Z",
                fill: _vm.outlineColor
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M10.4766 14.4659C10.4766 11.6866 12.744 9.43359 15.5411 9.43359H64.9201C67.7172 9.43359 69.9846 11.6866 69.9846 14.4659V63.5304C69.9846 66.3096 67.7172 68.5626 64.9201 68.5626H15.5411C12.744 68.5626 10.4766 66.3096 10.4766 63.5304V14.4659Z",
                fill: _vm.baseColor
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.element == "shape-3"
        ? [
            _c("rect", {
              attrs: {
                x: "1.74609",
                y: "1.25781",
                width: "75.9677",
                height: "75.4839",
                rx: "37.7419",
                fill: _vm.outlineColor
              }
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                x: "6.81055",
                y: "6.28906",
                width: "65.8387",
                height: "65.4194",
                rx: "32.7097",
                fill: _vm.baseColor
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.element == "shape-4"
        ? [
            _c("path", {
              attrs: {
                d:
                  "M38.5172 0.338626C39.6142 -0.112875 40.8468 -0.112876 41.9438 0.338626L66.5319 10.4585C67.629 10.91 68.5005 11.776 68.9549 12.8661L79.1397 37.2976C79.5941 38.3876 79.5941 39.6124 79.1397 40.7024L68.9549 65.1339C68.5005 66.224 67.629 67.09 66.5319 67.5415L41.9438 77.6614C40.8468 78.1129 39.6142 78.1129 38.5172 77.6614L13.929 67.5415C12.832 67.09 11.9604 66.224 11.506 65.1339L1.32127 40.7024C0.86687 39.6124 0.86687 38.3876 1.32127 37.2976L11.506 12.866C11.9604 11.776 12.832 10.91 13.929 10.4585L38.5172 0.338626Z",
                fill: _vm.outlineColor
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M38.7382 5.32618C39.6936 4.93294 40.7672 4.93294 41.7226 5.32618L63.1381 14.1403C64.0936 14.5335 64.8527 15.2878 65.2485 16.2372L74.1191 37.5163C74.5148 38.4656 74.5148 39.5323 74.1191 40.4817L65.2485 61.7608C64.8527 62.7102 64.0936 63.4645 63.1381 63.8577L41.7226 72.6718C40.7672 73.065 39.6936 73.065 38.7382 72.6718L17.3227 63.8577C16.3672 63.4645 15.6081 62.7102 15.2123 61.7608L6.34175 40.4817C5.94598 39.5323 5.94598 38.4656 6.34174 37.5163L15.2123 16.2372C15.6081 15.2878 16.3672 14.5335 17.3227 14.1403L38.7382 5.32618Z",
                fill: _vm.baseColor
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.element == "shape-5"
        ? [
            _c("path", {
              attrs: {
                d:
                  "M49.2356 3.69027C51.677 5.90102 54.8231 7.18821 58.1232 7.32656C61.6427 7.49174 64.9736 8.95511 67.465 11.4306C69.9564 13.9062 71.4292 17.2159 71.5954 20.7129C71.7491 23.9964 73.0586 27.122 75.2952 29.5439C77.6662 32.1275 78.9805 35.4978 78.9805 38.9943C78.9805 42.4907 77.6662 45.861 75.2952 48.4446C73.0586 50.8666 71.7491 53.9921 71.5954 57.2756C71.4473 60.7787 69.9804 64.0987 67.4852 66.578C64.9901 69.0572 61.6488 70.5148 58.1232 70.662C54.8187 70.8147 51.6731 72.1159 49.2356 74.3382C46.6355 76.6941 43.2435 78 39.7247 78C36.2059 78 32.8139 76.6941 30.2138 74.3382C27.7763 72.1159 24.6307 70.8147 21.3262 70.662C17.8067 70.4968 14.4758 69.0334 11.9844 66.5579C9.49298 64.0824 8.02024 60.7726 7.854 57.2756C7.71476 53.9965 6.41931 50.8704 4.1944 48.4446C1.80625 45.8695 0.480469 42.496 0.480469 38.9943C0.480469 35.4926 1.80625 32.119 4.1944 29.5439C6.41931 27.1181 7.71476 23.9921 7.854 20.7129C8.02917 17.2189 9.50478 13.9142 11.9943 11.4405C14.4839 8.96683 17.8097 7.50062 21.3262 7.32656C24.6263 7.18821 27.7724 5.90102 30.2138 3.69027C32.8054 1.31734 36.2006 0 39.7247 0C43.2489 0 46.644 1.31734 49.2356 3.69027Z",
                fill: _vm.outlineColor
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M48.0091 8.24536C50.1354 10.1708 52.8756 11.2919 55.7499 11.4125C58.8152 11.5563 61.7164 12.8309 63.8863 14.987C66.0562 17.1431 67.3389 20.0257 67.4837 23.0715C67.6176 25.9314 68.7581 28.6536 70.7062 30.763C72.7712 33.0133 73.9159 35.9487 73.9159 38.994C73.9159 42.0393 72.7712 44.9747 70.7062 47.225C68.7581 49.3344 67.6176 52.0566 67.4837 54.9165C67.3547 57.9675 66.0771 60.8592 63.9039 63.0185C61.7307 65.1778 58.8205 66.4474 55.7499 66.5755C52.8718 66.7086 50.1321 67.8418 48.0091 69.7774C45.7445 71.8293 42.7902 72.9667 39.7254 72.9667C36.6606 72.9667 33.7063 71.8293 31.4417 69.7774C29.3187 67.8418 26.579 66.7086 23.7008 66.5755C20.6355 66.4317 17.7344 65.1571 15.5645 63.001C13.3945 60.8449 12.1118 57.9623 11.967 54.9165C11.8458 52.0604 10.7175 49.3378 8.77963 47.225C6.69964 44.9821 5.54492 42.0439 5.54492 38.994C5.54492 35.9441 6.69964 33.0059 8.77963 30.763C10.7175 28.6502 11.8458 25.9276 11.967 23.0715C12.1196 20.0284 13.4048 17.1501 15.5731 14.9956C17.7415 12.8411 20.6382 11.564 23.7008 11.4125C26.5752 11.2919 29.3153 10.1708 31.4417 8.24536C33.6989 6.17861 36.656 5.03125 39.7254 5.03125C42.7948 5.03125 45.7519 6.17861 48.0091 8.24536Z",
                fill: _vm.baseColor
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.element == "shape-6"
        ? [
            _c("path", {
              attrs: {
                d:
                  "M3.51367 5.89408C3.51367 4.02821 5.02626 2.51562 6.89212 2.51562H73.5707C75.4366 2.51562 76.9492 4.02821 76.9492 5.89408V38.7656C76.9492 59.0443 60.5101 75.4834 40.2314 75.4834C19.9528 75.4834 3.51367 59.0443 3.51367 38.7656V5.89408Z",
                fill: _vm.outlineColor
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M8.57812 8.54862C8.57812 7.99537 9.02662 7.54688 9.57986 7.54688H70.8828C71.4361 7.54688 71.8846 7.99537 71.8846 8.54861V38.7969C71.8846 56.2785 57.7129 70.4501 40.2314 70.4501C22.7498 70.4501 8.57812 56.2785 8.57812 38.7969V8.54862Z",
                fill: _vm.baseColor
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.element == "shape-7"
        ? [
            _c("path", {
              attrs: {
                d:
                  "M6.98932 3.19619C31.4395 7.38574 48.0834 7.36894 72.4355 3.19619C72.9262 3.10665 73.4307 3.12551 73.9133 3.25145C74.3958 3.37739 74.8445 3.60731 75.2276 3.92489C75.6106 4.24248 75.9186 4.63995 76.1298 5.0891C76.3409 5.53824 76.4499 6.02805 76.4492 6.52377V24.533C76.4492 68.257 43.6438 75.4846 39.7314 75.4846C35.8191 75.4846 3.01367 68.257 3.01367 24.533V6.52377C3.01389 6.03185 3.12223 5.54592 3.33113 5.09993C3.54002 4.65395 3.84443 4.25868 4.22307 3.94176C4.60171 3.62485 5.04544 3.39392 5.52325 3.26514C6.00106 3.13636 6.5014 3.11283 6.98932 3.19619Z",
                fill: _vm.outlineColor
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M10.4521 8.62495C32.7289 13.0488 46.703 13.0549 69.0106 8.62495C69.2984 8.56716 69.5955 8.57364 69.8805 8.64393C70.1654 8.71421 70.4311 8.84654 70.6583 9.03135C70.8855 9.21615 71.0685 9.44882 71.1941 9.71251C71.3197 9.97621 71.3848 10.2643 71.3846 10.5561V24.5332C71.3846 63.5332 43.1056 70.4525 39.7313 70.4525C36.3571 70.4525 8.07813 63.5332 8.07813 24.5332V10.5561C8.07794 10.2643 8.143 9.97621 8.26861 9.71251C8.39421 9.44882 8.57723 9.21615 8.80442 9.03135C9.0316 8.84654 9.29728 8.71421 9.58225 8.64393C9.86721 8.57364 10.1643 8.56716 10.4521 8.62495Z",
                fill: _vm.baseColor
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.element == "shape-8"
        ? [
            _c("path", {
              attrs: {
                d:
                  "M5.41211 8.17654C5.41211 6.09211 7.11271 4.40234 9.2105 4.40234H71.2508C73.3486 4.40234 75.0492 6.09211 75.0492 8.17654V62.1459C75.0492 63.733 74.05 65.1505 72.5489 65.6928L41.5287 76.9011C40.6902 77.204 39.7711 77.204 38.9326 76.9011L7.91242 65.6928C6.41134 65.1505 5.41211 63.733 5.41211 62.1459V8.17654Z",
                fill: _vm.outlineColor
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M10.4766 10.6917C10.4766 9.99685 11.0434 9.43359 11.7427 9.43359H68.7185C69.4178 9.43359 69.9846 9.99685 69.9846 10.6917V60.187C69.9846 60.7176 69.6496 61.1912 69.1471 61.3708L40.6592 71.5546C40.3822 71.6536 40.079 71.6536 39.802 71.5546L11.3141 61.3708C10.8116 61.1912 10.4766 60.7176 10.4766 60.187V10.6917Z",
                fill: _vm.baseColor
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.element == "shape-9"
        ? [
            _c("path", {
              attrs: {
                d:
                  "M39.7312 0C31.3869 0 23.9095 3.68825 18.8603 9.51355C18.6121 9.7999 18.488 9.94308 18.3527 10.0355C18.2278 10.1207 18.1052 10.1765 17.9588 10.2149C17.8 10.2565 17.6222 10.2565 17.2666 10.2565H7.44487C5.34708 10.2565 3.64648 11.9463 3.64648 14.0307V63.9693C3.64648 66.0537 5.34708 67.7435 7.44487 67.7435H17.2666C17.6222 67.7435 17.8 67.7435 17.9588 67.7851C18.1052 67.8235 18.2278 67.8793 18.3527 67.9645C18.488 68.0569 18.6121 68.2001 18.8603 68.4865C23.9095 74.3118 31.3869 78 39.7312 78C48.0755 78 55.5528 74.3118 60.602 68.4864C60.8502 68.2001 60.9743 68.0569 61.1097 67.9645C61.2345 67.8793 61.3571 67.8235 61.5035 67.7851C61.6623 67.7435 61.8401 67.7435 62.1957 67.7435H72.0174C74.1152 67.7435 75.8158 66.0537 75.8158 63.9693V14.0307C75.8158 11.9463 74.1152 10.2565 72.0174 10.2565H62.1957C61.8401 10.2565 61.6623 10.2565 61.5035 10.2149C61.3571 10.1765 61.2345 10.1207 61.1097 10.0355C60.9743 9.94308 60.8502 9.7999 60.602 9.51355C55.5528 3.68825 48.0755 0 39.7312 0Z",
                fill: _vm.outlineColor
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d:
                  "M39.7311 5.03125C32.9228 5.03125 26.8259 8.03387 22.6977 12.7965C22.6948 12.7999 22.6891 12.8067 22.681 12.8166C22.569 12.9517 21.9768 13.666 21.22 14.1824C20.6154 14.5951 19.9599 14.8937 19.2506 15.0797C18.3694 15.3107 17.4697 15.2919 17.2935 15.2882C17.2804 15.288 17.2714 15.2878 17.2665 15.2878H10.7367C10.0276 15.2878 9.6731 15.2878 9.40226 15.4249C9.16402 15.5455 8.97033 15.738 8.84894 15.9747C8.71094 16.2438 8.71094 16.5961 8.71094 17.3007V60.6973C8.71094 61.4019 8.71094 61.7542 8.84894 62.0233C8.97033 62.26 9.16402 62.4525 9.40226 62.5731C9.6731 62.7102 10.0276 62.7102 10.7367 62.7102H17.2665C17.2714 62.7102 17.2804 62.71 17.2934 62.7097C17.4696 62.7061 18.3694 62.6873 19.2506 62.9183C19.9599 63.1042 20.6154 63.4029 21.22 63.8155C21.9768 64.332 22.569 65.0463 22.681 65.1814C22.6891 65.1913 22.6948 65.1981 22.6977 65.2015C26.8259 69.9641 32.9228 72.9667 39.7311 72.9667C46.5394 72.9667 52.6363 69.9641 56.7645 65.2015C56.7674 65.1981 56.773 65.1913 56.7812 65.1814C56.8932 65.0464 57.4854 64.3321 58.2422 63.8155C58.8468 63.4029 59.5023 63.1042 60.2116 62.9183C61.0928 62.6873 61.9926 62.7061 62.1688 62.7097C62.1818 62.71 62.1908 62.7102 62.1957 62.7102H68.7255C69.4346 62.7102 69.7891 62.7102 70.0599 62.5731C70.2982 62.4525 70.4919 62.26 70.6133 62.0233C70.7513 61.7542 70.7513 61.4019 70.7513 60.6973V17.3007C70.7513 16.5961 70.7513 16.2438 70.6133 15.9747C70.4919 15.738 70.2982 15.5455 70.0599 15.4249C69.7891 15.2878 69.4346 15.2878 68.7255 15.2878H62.1957C62.1908 15.2878 62.1818 15.288 62.1688 15.2882C61.9925 15.2919 61.0928 15.3107 60.2116 15.0797C59.5023 14.8937 58.8468 14.5951 58.2422 14.1824C57.4854 13.666 56.8932 12.9517 56.7812 12.8166C56.7731 12.8067 56.7674 12.7999 56.7645 12.7965C52.6363 8.03387 46.5394 5.03125 39.7311 5.03125Z",
                fill: _vm.baseColor
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.element == "shape-10"
        ? [
            _c("path", {
              attrs: {
                d:
                  "M40.2307 0C31.8864 0 24.409 3.68825 19.3598 9.51355C19.1116 9.7999 18.9875 9.94308 18.8521 10.0355C18.7273 10.1207 18.6047 10.1765 18.4583 10.2149C18.2995 10.2565 18.1217 10.2565 17.7661 10.2565H9.2105C7.11271 10.2565 5.41211 11.9463 5.41211 14.0307V40.8871C5.41211 59.9944 21.0009 75.4839 40.2307 75.4839C59.4604 75.4839 75.0492 59.9944 75.0492 40.8871V14.0307C75.0492 11.9463 73.3486 10.2565 71.2508 10.2565H62.6952C62.3396 10.2565 62.1618 10.2565 62.003 10.2149C61.8566 10.1765 61.734 10.1207 61.6092 10.0355C61.4738 9.94309 61.3497 9.79994 61.1016 9.51367C56.0524 3.68836 48.5749 0 40.2307 0Z",
                fill: _vm.outlineColor
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M61.2673 61.7882C57.1062 65.9218 51.805 68.7368 46.0339 69.8771C40.2629 71.0174 34.2811 70.432 28.8449 68.1947C23.4087 65.9575 18.7621 62.1689 15.4926 57.3079C12.2232 52.447 10.4776 46.732 10.4766 40.8854L10.4766 15.2901H17.9531C18.1303 15.2901 18.3835 15.2901 18.6874 15.2901C19.0471 15.2568 19.403 15.1915 19.751 15.0951C20.4551 14.915 21.1216 14.6126 21.7198 14.2019C22.0568 13.968 22.3705 13.7026 22.6567 13.4093C22.8973 13.1703 23.0745 12.9627 23.1632 12.8557L23.2012 12.8054C25.311 10.3701 27.9249 8.4158 30.8644 7.07614C33.8039 5.73648 36.9998 5.04297 40.2338 5.04297C43.4677 5.04297 46.6636 5.73648 49.6031 7.07614C52.5427 8.4158 55.1566 10.3701 57.2664 12.8054L57.3107 12.8557C57.3993 12.9627 57.5766 13.1703 57.8171 13.4093C58.1033 13.7026 58.4171 13.968 58.7541 14.2019C59.3484 14.6113 60.0105 14.9136 60.7102 15.0951C61.0586 15.1873 61.4145 15.2483 61.7738 15.2775C62.0777 15.2775 62.3309 15.2775 62.5081 15.2775H69.9846V40.8854C69.9919 44.7688 69.2254 48.6152 67.7292 52.2028C66.2331 55.7904 64.0369 59.0481 61.2673 61.7882Z",
                fill: _vm.baseColor
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.element == "shape-11"
        ? [
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d:
                  "M74.4523 15.8746C71.5352 15.5058 68.826 14.1783 66.7557 12.1033C64.6854 10.0283 63.3728 7.32502 63.0269 4.42344C62.9809 3.97375 62.7852 3.55216 62.4708 3.22548C62.1564 2.89881 61.7413 2.68573 61.2912 2.62003C54.1439 1.67592 46.9398 1.22153 39.7297 1.26007C32.5197 1.22153 25.3155 1.67592 18.1682 2.62003C17.7181 2.68573 17.303 2.89881 16.9886 3.22548C16.6742 3.55216 16.4785 3.97375 16.4326 4.42344C16.0866 7.32502 14.774 10.0283 12.7037 12.1033C10.6334 14.1783 7.92423 15.5058 5.00715 15.8746C4.5354 15.929 4.09674 16.1427 3.76456 16.4799C3.43238 16.8172 3.22681 17.2575 3.18226 17.7273C2.91447 21.1272 2.37891 29.1884 2.37891 38.846C2.37891 48.5036 2.91447 56.5845 3.18226 59.9647C3.22536 60.434 3.4307 60.8741 3.76338 61.21C4.09606 61.546 4.53554 61.7571 5.00715 61.8075C7.9244 62.1801 10.6329 63.5101 12.7027 65.5864C14.7726 67.6628 16.0853 70.3665 16.4326 73.2686C16.4802 73.7177 16.6764 74.1384 16.9905 74.4647C17.3046 74.7911 17.7188 75.0047 18.1682 75.072C25.3159 76.011 32.5199 76.4621 39.7297 76.4221C46.9395 76.4621 54.1435 76.011 61.2912 75.072C61.7406 75.0047 62.1548 74.7911 62.4689 74.4647C62.783 74.1384 62.9792 73.7177 63.0269 73.2686C63.3724 70.3659 64.6846 67.6612 66.7548 65.5846C68.825 63.5079 71.5344 62.1785 74.4523 61.8075C74.9239 61.7571 75.3634 61.546 75.696 61.21C76.0287 60.8741 76.2341 60.434 76.2772 59.9647C76.545 56.555 77.0805 48.5036 77.0805 38.846C77.0805 29.1884 76.545 21.1075 76.2772 17.7273C76.2326 17.2575 76.027 16.8172 75.6949 16.4799C75.3627 16.1427 74.924 15.929 74.4523 15.8746Z",
                fill: _vm.outlineColor
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d:
                  "M58.4294 7.33365C52.2334 6.6061 45.9974 6.25789 39.7569 6.29125L39.7296 6.2914L39.7024 6.29125C33.4619 6.25789 27.2259 6.6061 21.0299 7.33365C20.2326 10.4605 18.6059 13.3343 16.3003 15.6452C14.0163 17.9343 11.1707 19.5662 8.06543 20.393C7.79439 24.3978 7.44336 31.1004 7.44336 38.845C7.44336 46.581 7.79361 53.29 8.0644 57.289C11.1707 58.1185 14.0168 59.7529 16.3008 62.044C18.605 64.3554 20.231 67.2287 21.0289 70.3547C27.2252 71.0781 33.4611 71.4236 39.7013 71.3889L39.7296 71.3887L39.758 71.3889C45.9979 71.4236 52.2334 71.0781 58.4294 70.3548C59.2262 67.228 60.852 64.3539 63.1566 62.0422C65.4408 59.7508 68.2874 58.1168 71.3942 57.2882C71.6653 53.2791 72.0159 46.5857 72.0159 38.845C72.0159 31.1039 71.6652 24.3912 71.3944 20.3931C68.2889 19.5664 65.4431 17.9344 63.159 15.6452C60.8534 13.3343 59.2267 10.4605 58.4294 7.33365Z",
                fill: _vm.baseColor
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.element == "shape-12"
        ? [
            _c("path", {
              attrs: {
                d:
                  "M40.7467 1.95937C40.2651 1.56042 39.5631 1.56042 39.0815 1.95937C31.5076 8.23269 23.426 11.9976 12.6924 11.9976C10.7249 11.9976 8.78717 11.8711 6.88696 11.6259C6.11238 11.5259 5.41211 12.1157 5.41211 12.8918V39.675C5.41211 59.8245 19.952 76.1589 40.2307 76.1589C60.5093 76.1589 75.0492 59.8245 75.0492 39.675V12.8058C75.0492 12.0224 74.3363 11.4305 73.5558 11.5422C71.4594 11.8423 69.3159 11.9976 67.1359 11.9976C56.4023 11.9976 48.3206 8.23269 40.7467 1.95937Z",
                fill: _vm.outlineColor
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d:
                  "M41.1262 8.6122C40.4013 8.09699 39.4268 8.09699 38.7019 8.6122C31.35 13.8374 23.1108 17.0287 12.6923 17.0287C12.6499 17.0287 12.6075 17.0286 12.5651 17.0285C11.4263 17.0257 10.4766 17.9308 10.4766 19.0696V39.6738C10.4766 57.4019 23.096 71.1255 40.2306 71.1255C57.3651 71.1255 69.9846 57.4019 69.9846 39.6738V19.0644C69.9846 17.9157 69.019 17.0068 67.8704 17.0234C67.626 17.0269 67.3811 17.0287 67.1358 17.0287C56.7173 17.0287 48.4782 13.8374 41.1262 8.6122Z",
                fill: _vm.baseColor
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }