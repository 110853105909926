var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19.81",
        height: "19.628",
        viewBox: "0 0 19.81 19.628"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(3)" } }, [
        _c("path", {
          attrs: {
            d:
              "M254.061,1065.441h-4.435a.75.75,0,0,0,0,1.5h4.435a.75.75,0,0,0,0-1.5Z",
            transform: "translate(-243.438 -1047.313)",
            fill: "currentColor"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M259.333,1059.066l-.015-.014-.011-.008a2.648,2.648,0,0,1-.955-1.949v-3.031a6.9,6.9,0,0,0-1.806-4.652,6.35,6.35,0,0,0-4.5-2.095l-.2,0a6.508,6.508,0,0,0-6.508,6.51v3.272a2.646,2.646,0,0,1-.953,1.948l-.01.008-.017.015a2.7,2.7,0,0,0-.917,2.027,2.635,2.635,0,0,0,.039.454l0,.013a2.728,2.728,0,0,0,2.652,2.216h0l.031,0h11.365a2.732,2.732,0,0,0,2.681-2.219l0-.017a2.556,2.556,0,0,0,.037-.451A2.7,2.7,0,0,0,259.333,1059.066Zm-1.806,3.213H246.161a1.231,1.231,0,0,1-1.207-1l-.016-.19a1.2,1.2,0,0,1,.408-.9l0,0a4.138,4.138,0,0,0,1.49-3.043h0v-3.324a5.009,5.009,0,0,1,5.008-5.01l.153,0a4.85,4.85,0,0,1,3.444,1.609,5.4,5.4,0,0,1,1.412,3.639v3.083a4.142,4.142,0,0,0,1.491,3.042l0,0a1.2,1.2,0,0,1,.407.9l-.016.191A1.23,1.23,0,0,1,257.527,1062.279Z",
            transform: "translate(-243.438 -1047.313)",
            fill: "currentColor"
          }
        }),
        _vm._v(" "),
        _vm.notifications
          ? _c(
              "g",
              {
                attrs: {
                  transform: "translate(0 7)",
                  fill: "#293ad3",
                  stroke: "#fafafa",
                  "stroke-width": "3"
                }
              },
              [
                _c("circle", {
                  attrs: { cx: "2", cy: "2", r: "2", stroke: "none" }
                }),
                _vm._v(" "),
                _c("circle", {
                  attrs: { cx: "2", cy: "2", r: "3.5", fill: "none" }
                })
              ]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }