import { render, staticRenderFns } from "./Affiliates.vue?vue&type=template&id=09e2c70a&"
import script from "./Affiliates.vue?vue&type=script&lang=js&"
export * from "./Affiliates.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/src/searchie/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('09e2c70a')) {
      api.createRecord('09e2c70a', component.options)
    } else {
      api.reload('09e2c70a', component.options)
    }
    module.hot.accept("./Affiliates.vue?vue&type=template&id=09e2c70a&", function () {
      api.rerender('09e2c70a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/2.0/js/modules/AccountSettings/Pages/Affiliates.vue"
export default component.exports