import { Node } from 'prosemirror-to-html-js';
import get from 'lodash/get';
export default class InlineParagraph extends Node {
  matching () {
    return this.node.type === "paragraph";
  }

  tag () {
    return [
      {
        tag: "span",
        attrs: {
          style: this.getStyles()
        }
      }
    ]
  }

  getStyles() {
    let styles = '';
    if ( get(this.node, 'attrs.textAlign') ) {
      styles += `text-align: ${get(this.node, 'attrs.textAlign')};`
    }
    return styles;
  }
}
