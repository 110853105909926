<template>
  <svg class="icon-widget-small" xmlns="http://www.w3.org/2000/svg" width="14.5" height="12.5" viewBox="0 0 14.5 12.5">
    <path d="M1362.479,1159v-.825a2.924,2.924,0,0,0-2.924-2.925h-8.65a2.924,2.924,0,0,0-2.925,2.925v6.65a2.924,2.924,0,0,0,2.925,2.925h8.65a2.925,2.925,0,0,0,2.924-2.925V1159Zm-11.575-2.25h8.65a1.428,1.428,0,0,1,1.425,1.425v.075h-11.5v-.075A1.427,1.427,0,0,1,1350.9,1156.75Zm8.65,9.5h-8.65a1.428,1.428,0,0,1-1.426-1.425v-5.075h11.5v5.075A1.428,1.428,0,0,1,1359.554,1166.25Z" transform="translate(-1347.979 -1155.25)" fill="#939393"/>
    <path d="M1356.5,1161.928l-1.744-.969a.841.841,0,0,0-.4-.1.806.806,0,0,0-.815.794v1.927a.786.786,0,0,0,.108.393.828.828,0,0,0,1.112.3l1.739-.962a.8.8,0,0,0,.31-.3l.012-.024A.779.779,0,0,0,1356.5,1161.928Z" transform="translate(-1347.979 -1155.25)" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconWidgetSmall',
  };
</script>
