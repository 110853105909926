<template>
  <div class="audience-edit-row pr-3 py-3">
    <div class="slot-left fluid">
      <span class="handle">
        <svg width="24" viewBox="0 0 24 24"><use xlink:href="#icon-drag-horizontal"></use></svg>
      </span>
      <form-control-select
        v-model="title"
        :options="fields"
        :identifier="false"
        track-by="title"
        select-label="label"
        full
        small
        @select="state.title.$touch()"
      />
    </div>
    <div class="slot-right">
      <form-control-text
        v-model="field.label"
        icon=""
        :state="state.label.$error ? false : null"
        :error="error"
        :disabled="!title || disabled"
        @change="state.label.$touch()"
        size="sm"
      />
      <b-button variant="icon ml-2" size="sm" @click="$emit('delete')" :disabled="disabled">
        <svg class="icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-cross"></use></svg>
      </b-button>
    </div>
  </div>
</template>
<script>
  import {set} from "@app2/utils/helpers";
  import FormControlSelect from "@app2/core/Components/Common/Forms/Select";
  import FormControlText from "@app2/core/Components/Common/Forms/Text";
  export default {
    name: 'ExportField',
    components: {FormControlText, FormControlSelect},
    props: {
      field: {},
      fieldOptions: {},
      list: {},
      error: {},
      state: {},
      disabled: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      title: {
        get() {
          let fields = this.fieldOptions.filter(op => op.title === this.field.title);
          return ( fields && fields.length ) ? fields[0] : false;
        },
        set(value) {
          set(this.field, 'title', value.title);
          set(this.field, 'label', value.label);
          this.state.title.$touch();
          this.state.label.$touch();
        },
      },
      fields() {
        return this.fieldOptions.filter(op => !this.list.map(f => f.title).includes(op.title));
      }
    },
  }
</script>
