var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            id: "embed-domains-dialog",
            "modal-class": "modal-right",
            static: "",
            "hide-footer": "",
            "hide-header": ""
          },
          on: { hide: _vm.onHide, hidden: _vm.onHidden, show: _vm.fetchDomains }
        },
        [
          _c("div", { staticClass: "side-panel-template" }, [
            _c("div", { staticClass: "side-panel-header pt-4 mb-0" }, [
              _c(
                "div",
                { staticClass: "inner mb-0" },
                [
                  _c("div", { staticClass: "side-panel-title" }, [
                    _c("div", { staticClass: "inner my-auto" }, [
                      _c("div", [
                        _c("h3", { staticClass: "txt-title-small" }, [
                          _c("b", [_vm._v("Edit Approved Domains")])
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "txt-body txt-muted mb-0" }, [
                          _vm._v(
                            "Domain restricted content will only be accessible on the domains listed here."
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-btn",
                    {
                      attrs: { variant: "icon ml-auto", size: "sm" },
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.hide("embed-domains-dialog")
                        }
                      }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "icon-sm",
                          attrs: { width: "24", viewBox: "0 0 24 24" }
                        },
                        [_c("use", { attrs: { "xlink:href": "#icon-cross" } })]
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("hr", { staticClass: "mt-3 mb-4" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "side-panel-body" },
              [
                _c(
                  "div",
                  { staticClass: "side-panel-widget p-4" },
                  [
                    _vm._l(_vm.form.domains, function(item, index) {
                      return _c("side-panel-link-row", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip:hover",
                            arg: "hover"
                          }
                        ],
                        key: index,
                        staticClass: "mt-2",
                        attrs: {
                          link: item.substr(0, 42) + "...",
                          title: item.length > 42 ? item : ""
                        },
                        on: {
                          delete: function($event) {
                            return _vm.removeDomain(index)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "left",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "fx-row-center" }, [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "ml-2",
                                        attrs: {
                                          width: "24",
                                          viewBox: "0 0 24 24"
                                        }
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href":
                                              "#icon-status-success-color"
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    }),
                    _vm._v(" "),
                    _vm.showForm
                      ? _c("form-control-text", {
                          ref: "input",
                          staticClass: "mt-2",
                          class: { "with-actions": _vm.openForm },
                          attrs: {
                            "input-class": "txt-heading-small mb-0",
                            placeholder: "e.g. mywebiste.com",
                            state:
                              !_vm.validating && !_vm.isValid && _vm.isValidated
                                ? false
                                : null,
                            error:
                              !_vm.validating && !_vm.isValid && _vm.isValidated
                                ? _vm.error
                                : null
                          },
                          nativeOn: {
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.addDomain(true)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "left",
                                fn: function() {
                                  return [
                                    _vm.validating
                                      ? _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "icon-regular anim-rotate ml-1 mr-2",
                                            attrs: {
                                              width: "24",
                                              viewBox: "0 0 24 24"
                                            }
                                          },
                                          [
                                            _c("use", {
                                              attrs: {
                                                "xlink:href":
                                                  "#icon-loading-circle"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm.isValidated && _vm.isValid
                                      ? _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "icon-regular ml-1 mr-2",
                                            attrs: {
                                              width: "24",
                                              viewBox: "0 0 24 24"
                                            }
                                          },
                                          [
                                            _c("use", {
                                              attrs: {
                                                "xlink:href":
                                                  "#icon-status-success-color"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "right",
                                fn: function() {
                                  return [
                                    _c(
                                      "b-btn",
                                      {
                                        attrs: {
                                          variant:
                                            "platform-primary ml-auto mr-2",
                                          size: "sm"
                                        },
                                        on: { click: _vm.clear }
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "icon-sm",
                                            attrs: { viewBox: "0 0 24 24" }
                                          },
                                          [
                                            _c("use", {
                                              attrs: {
                                                "xlink:href": "#icon-cross"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-btn",
                                      {
                                        attrs: {
                                          disabled:
                                            _vm.adding ||
                                            !_vm.temp ||
                                            _vm.validating ||
                                            (!_vm.isValid && _vm.isValidated),
                                          variant:
                                            "platform-primary ml-auto mr-2",
                                          size: "sm"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.addDomain(false)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "icon-sm",
                                            attrs: { viewBox: "0 0 24 24" }
                                          },
                                          [
                                            _c("use", {
                                              attrs: {
                                                "xlink:href": "#icon-tick"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            3369814030
                          ),
                          model: {
                            value: _vm.inputText,
                            callback: function($$v) {
                              _vm.inputText = $$v
                            },
                            expression: "inputText"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary btn-block mt-2",
                        attrs: { type: "button" },
                        on: { click: _vm.openForm }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "btn-left",
                            attrs: { width: "24", viewBox: "0 0 24 24" }
                          },
                          [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-plus-circle" }
                            })
                          ]
                        ),
                        _vm._v("\n            Add Domain\n          ")
                      ]
                    )
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "side-panel-library-cta p-3" },
                  [
                    _c(
                      "b-btn",
                      {
                        attrs: {
                          block: "",
                          variant: "primary",
                          size: "lg",
                          disabled: !_vm.hasChanged || _vm.saving
                        },
                        on: {
                          click: function($event) {
                            return _vm.update(false)
                          }
                        }
                      },
                      [_vm._v("\n            Confirm\n          ")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("delete-domain-modal", {
                  attrs: { domain: _vm.form.domains[_vm.selected] },
                  on: {
                    unpublish: function($event) {
                      return _vm.removeDomain(_vm.selected, true)
                    }
                  }
                })
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("exit-dialog", {
        attrs: {
          id: "exit-domains-dialog",
          subtitle: "Save changes?",
          title: "Save changes before exit?",
          description: "Unsaved data will be lost.",
          "accept-label": "Save & Exit",
          "reject-label": "Exit",
          processing: _vm.saving
        },
        on: {
          reject: _vm.reset,
          accept: function($event) {
            return _vm.update(true)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }