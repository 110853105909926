import Vue from 'vue'
import rootVue from "@app2/app"

Vue.directive('click-away', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      //console.log(event.target,el,binding,vnode)
      // workaround: this condition is targetting the dropdown daterangepicker only to avoid closing the dropdown when selecting dates
      if((!event.target.id && !event.target.className) || (typeof event.target.className === 'string' && (event.target.className.includes('mx') || event.target.closest('.searchie-calendar')))) return

      if (!(el == event.target || el.contains(event.target)))
        vnode.context[binding.expression] = false
    };
    document.body.addEventListener("click", el.clickOutsideEvent)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})

Vue.directive('popover-click-away', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (event.target.classList.contains('popover-dropdown-search')) return // dont hide popover
      if(el.id === 'date-filter') return
      rootVue.$emit('bv::hide::popover', el.id)
      vnode.context.$root.$emit('bv::hide::popover', el.id) // works on content editor
    };
    document.body.addEventListener("click", el.clickOutsideEvent)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
