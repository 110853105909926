import Base from '@app2/models/Base';
import playerEmbedSettings from "@app2/utils/playerEmbedSettings";
import playerLogo from "@app2/utils/playerLogo";
import store from "@app2/store";
import get from "lodash/get"

export default class Widget extends Base {
  static entity = 'App\\Widget';
  static primaryKey = 'hash';

  static apiConfig = {
    baseURL: '/api/widgets/',
    actions: {
      fetch: {
        url: '/',
        dataKey: 'data'
      }
    }
  };

  watchFields = ['name', 'settings', 'privacy', 'player_logo', 'thumbnail'];
  requiredFields = ['name', 'type'];

  static fields() {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      name: this.attr(''),
      hash: this.attr(null),
      team_id: this.attr(null),
      settings: this.attr({}),
      privacy: this.attr(null),
      preview: this.attr(null),
      player_settings: this.attr({}),
      player_logo: this.attr({}),
      custom_logo_url: this.attr(null),
      type: this.attr(null),
      ...super.fields(),
    };
  }

  static mutators() {
    return {
      settings(value) {
        return ( Array.isArray(value) || !value ) ? {} : value
      },
      player_settings(value) {
        if ( typeof value === 'object' && !Object.entries(value).length) {
          let team = store.getters['auth/GET_TEAM'];
          if (!team) team = {};
          return {
            ...get(team, 'player_settings', {}),
            widget: {}
          };
        }
        Object.entries(value).forEach(level => {
          if ( ( Array.isArray(level[1]) || !level[1] ) ) {
            value[level[0]] = {};
          }
        });
        return value;
      }
    };
  }

  get playerEmbed() {
    return playerEmbedSettings(this.player_settings);
  }

  get title() {
    return this.name;
  }

  get playerEmbedLogo() {
    return playerLogo(this.player_logo);
  }
}
