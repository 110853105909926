import { Model } from '@vuex-orm/core';
import { timestamps } from '@app/utils/model-fields';
import File from '@app/models/File';
import moment from '@app/utils/moment';
import Connection from '@app/models/Connection';
import FolderPlaylist from "@app/models/FolderPlaylist";
import Playlist from "@app/models/Playlist";

export default class Folder extends Model {
  static entity = 'App\\Folder';

  static apiConfig = {
    baseURL: "/api/folders/",
    actions: {
      fetch: {
        url: '/',
        dataKey: 'data'
      },
      attachFiles(folder, files) {
        return this.put(`${folder.hash}/bulk-move-file`, { files }, {save: false})
      },
      detachFiles(folder, files) {
        return this.put(`${folder.hash}/bulk-move-file`, { files }, {save: false})
      },
    }
  };

  static fields() {
    return {
      id: this.attr(null),
      hash: this.attr(null),
      connection_type: this.attr(null),
      connection_id: this.attr(null),
      connection: this.belongsTo(Connection, 'connection_id'),
      source_id: this.attr(null),
      title: this.attr(null),
      highlight: this.attr(null),
      is_auto_sync: this.attr(null),
      is_remote_sync_failed: this.attr(null),
      meta: this.attr(null),
      created_at_local: this.attr(null),
      files: this.hasMany(File, 'folder_id'),
      files_count: this.attr(null),
      page: this.attr(null),
      playlists: this.belongsToMany(Playlist, FolderPlaylist, 'folder_id', 'playlist_id'),
      folder_playlists: this.hasMany(FolderPlaylist, 'folder_id', 'id'),
      ...timestamps,
    };
  }

  static beforeCreate(model) {
    Folder.updateTime(model);
  }

  static beforeUpdate(model) {
    Folder.updateTime(model);
  }

  static updateTime(model) {
    if (model.created_at_local || !model.created_at) return;
    model.created_at_local = moment(model.created_at).format("MMMM D, YYYY @ hh:mm");
  }

  isInPlaylist(playlistId) {
    if (!this.folder_playlists) return false;
    return this.folder_playlists.map(p => p.playlist_id).includes(playlistId);
  }

  get lowerTitle() {
    return (this.title) ? this.title.toLowerCase() : '';
  }
}
