var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-visit",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "15.474",
        height: "10.991",
        viewBox: "0 0 15.474 10.991"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M526.4,775.192a9.137,9.137,0,0,0-13.587,0,3.7,3.7,0,0,0,0,4.936,9.136,9.136,0,0,0,13.587,0v0a3.7,3.7,0,0,0,0-4.936Zm-1.117,3.936a7.636,7.636,0,0,1-11.354,0,2.2,2.2,0,0,1,0-2.933,7.634,7.634,0,0,1,11.353,0,2.2,2.2,0,0,1,0,2.934Z",
          transform: "translate(-511.87 -772.165)",
          fill: "#939393"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "1.771",
          cy: "1.771",
          r: "1.771",
          transform: "translate(5.907 3.62)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }