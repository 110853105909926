import Achievement from "@app2/models/Achievement";

const namespaced = true;

const state = {
  showModal: false,
  achievement: null,
  select: false,
  slideInAction: null,
  isViewMode: true,
  criteriaOptions: [
    {id: 'completed-content', label: 'Completed Content', icon: 'tick-circle'},
    {id: 'time-since-joining', label: 'Time Since Joining', icon: 'calendar-empty'},
    // {id: 'consumption-time', label: 'Consumption Time', icon: 'clock-timeline', $isDisabled: true, disabled: true, tooltip: 'Coming Soon'},
    {id: 'number-of-searches', label: 'Number of Searches', icon: 'search-text'},
    {id: 'unique-logins', label: 'Unique Logins', icon: 'lock-tick'},
  ],
  typeOptions: [
    {
      id: 'milestone',
      label: 'Milestone',
      icon: 'flag-tick',
      description: 'Reward people for completing a specific goal in the Hub.',
      route: { name: 'CreateMilestone' }
    },
    {
      id: 'manual',
      label: 'Manual',
      icon: 'badge',
      description: 'Reward people for any of their other accomplishments.',
      route: { name: 'CreateManual' }
    },
    // {id: 'streak', label: 'Streak', icon: 'arrow-right-dot', $isDisabled: true, disabled: true, tooltip: 'Coming Soon', description: 'Reward people for continuous Hub engagement.'},
    // {id: 'challenge', label: 'Challenge', icon: 'cup', $isDisabled: true, disabled: true, tooltip: 'Coming Soon', description: 'Reward your audience for consuming content in the time window.'},
    // {id: 'community', label: 'Community', icon: 'user-multiple', $isDisabled: true, disabled: true, tooltip: 'Coming Soon', description: 'Coming soon.'},
  ],
  initialSegmentGroup: []
};

const mutations = {
  TOGGLE_MODAL(state, payload) {
    state.showModal = payload
  },
  TOGGLE_SELECT(state, payload) {
    state.select = payload
  },
  async SET_ACHIEVEMENT(state, payload) {
    if ( payload === 'create' ) {
      await Achievement.delete('create');
      await Achievement.insertOrUpdate({
        data: {
          id: 'create',
          settings: {
            appearance: {
              colors: {
                outline: '#5581F4',
                base: '#273E79',
                icon: '#fff',
              },
              shape: 1,
              illustration: {
                type: 'icon',
                inner: { name: 'Award', icon: 'award'},
              }
            },
            icon: {
              size: 50
            }
          }
        }
      });
    }
    state.achievement = payload
  },
  SET_SEGMENT_GROUPS(state, payload) {
    let arr = payload
    if(!payload) arr = []

    state.initialSegmentGroup = JSON.stringify(arr)
  },
  SET_SLIDE_IN_ACTION(state, payload) {
    state.slideInAction = payload
  },
  SET_VIEW_MODE(state, payload) {
    state.isViewMode = payload
  },
};

const actions = {

};

const getters = {
  criteriaOptions: state => state.criteriaOptions,
  typeOptions: state => state.typeOptions,
  initialSegmentGroup: state => state.initialSegmentGroup,
  slideInAction: state => state.slideInAction,
  isViewMode: state => state.isViewMode,
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
