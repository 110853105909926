<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15.942" height="19.131" viewBox="0 0 15.942 19.131">
      <g id="Group_1791" data-name="Group 1791" transform="translate(-329 -381)">
        <path id="Path_673" data-name="Path 673" d="M333.172,386.41h6.977a.75.75,0,1,0,0-1.5h-6.977a.75.75,0,0,0,0,1.5Z"/>
        <path id="Path_674" data-name="Path 674" d="M337.444,388.155h-4.3a.75.75,0,0,0,0,1.5h4.3a.75.75,0,0,0,0-1.5Z"/>
        <path id="Path_675" data-name="Path 675" d="M334.927,391.412H333.14a.75.75,0,0,0,0,1.5h1.787a.75.75,0,0,0,0-1.5Z"/>
        <path id="Path_676" data-name="Path 676" d="M341.442,381H332.5a3.5,3.5,0,0,0-3.5,3.5v12.131a3.5,3.5,0,0,0,3.5,3.5h8.942a3.5,3.5,0,0,0,3.5-3.5V384.5A3.5,3.5,0,0,0,341.442,381Zm2,15.631a2,2,0,0,1-2,2H332.5a2,2,0,0,1-2-2V384.5a2.005,2.005,0,0,1,2-2h8.942a2,2,0,0,1,2,2Z"/>
        <path id="Path_677" data-name="Path 677" d="M340.554,392.671l.168-1.353q.521.033,1.134.1l.093-.89-.144-.034q-.438-.084-.958-.134l.151-1.21h-.622l-.151,1.16h-.109a2.147,2.147,0,0,0-1.386.408,1.5,1.5,0,0,0-.5,1.239,1.382,1.382,0,0,0,.362,1.008,2.878,2.878,0,0,0,1.226.629l-.185,1.462q-.562-.033-1.226-.127l-.109.874.168.033a9.213,9.213,0,0,0,1.042.177l-.118.974a2.208,2.208,0,0,0,.622.059l.126-.991h.012a2.065,2.065,0,0,0,1.386-.45,1.636,1.636,0,0,0,.529-1.323,1.413,1.413,0,0,0-.322-1A2.888,2.888,0,0,0,340.554,392.671Zm-.589-.2a1.435,1.435,0,0,1-.487-.261.44.44,0,0,1-.126-.328q0-.543.756-.579Zm.985,1.956a.66.66,0,0,1-.679.639l.159-1.285a1.076,1.076,0,0,1,.408.247.49.49,0,0,1,.114.332l0,0Z"/>
      </g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-settings-invoices'
  }
</script>
