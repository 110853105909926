var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-element-button",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "21.979",
        height: "18.667",
        viewBox: "0 0 21.979 18.667"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "m21.467 12.409-7.2-2.4a.749.749 0 0 0-.948.948l2.4 7.195a.75.75 0 0 0 1.421.006l1.106-3.223 3.224-1.108a.75.75 0 0 0-.006-1.421Zm-4.052 1.229a.754.754 0 0 0-.466.466l-.505 1.473-1.223-3.668 3.669 1.223ZM6.49 6.571a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5Z",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M11.454 13.142H7.321a5.822 5.822 0 0 1 0-11.643h7.337a5.827 5.827 0 0 1 5.465 7.832.75.75 0 1 0 1.408.518A7.326 7.326 0 0 0 14.658 0H7.321a7.32 7.32 0 1 0 0 14.641h4.133a.75.75 0 0 0 0-1.5Z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }