import Vue from 'vue';
import Router from '@plugins/VueRouter/index';

const Listing = () => import(/* webpackChunkName: "v2-audience-listing" */ '@app2/modules/Audience/Segment/Pages/Index')
const Item = () => import(/* webpackChunkName: "v2-audience-listing" */ '@app2/modules/Audience/Segment/Pages/Show')
const Create = () => import(/* webpackChunkName: "v2-audience-listing" */ '@app2/modules/Audience/Segment/Pages/Create')

Vue.use(Router);

const router = new Router({
  mode: 'abstract',
  name: 'audience-segment',
  routes: [
    {
      path: '/segments',
      name: 'Listing',
      component: Listing,
    },
    {
      path: '/segments/create',
      name: 'Create',
      component: Create,
    },
    {
      path: '/segments/:hash',
      name: 'Item',
      component: Item,
    },
  ],
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

export default router;
