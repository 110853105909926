import qs from "query-string";

const namespaced = true;

const state = {
  isModalOpen: false,
  connection: null,
  breadcrumbs: [],
  recursiveRoutes: [],
  isScout: false,
  automation: null,
  originalAutomation: null,
  newAutomation: false,
  isAppSettingsOpen: false,
  wisdomFolders: [],
  openInLibrary: null,
};

const mutations = {
  SET_MODAL_STATE(state, payload) {
    state.isModalOpen = payload;
  },
  SET_SETTINGS_MODAL_STATE(state, payload) {
    state.isAppSettingsOpen = payload;
  },
  SET_CONNECTION(state, payload) {
    state.connection = payload;
  },
  SET_IS_SCOUT(state, payload) {
    state.isScout = payload;
  },
  SET_BREADCRUMBS(state, data) {
    state.breadcrumbs = data.$route.matched.flatMap(route => {
      let routeRecord = [route]
      let breadcrumb = route.meta?.breadcrumb

      if (typeof breadcrumb === 'function') {
        breadcrumb = breadcrumb.call(data, data.$route.params)
      }

      return routeRecord
    }).map(route => route.path.length === 0
      ? ({ ...route, path: '/connection' })
      : route
    )
    // note that params in breadcrumbs is not consistent and might be undefined
    let currentBreadcrumb = data.$route.meta?.breadcrumb
    if (typeof currentBreadcrumb === 'function') {
      currentBreadcrumb = currentBreadcrumb.call(data, data.$route.params)
    }
    const index = state.recursiveRoutes.findIndex((route, index) => {
      if (data.$route.query && data.$route.query.path === route.query.path) {
        return true
      }
    })
    if (index !== -1) {
      state.recursiveRoutes.length = index
    }
    if (currentBreadcrumb && currentBreadcrumb.parent) {
      const matched = data.$router.resolve({ name: currentBreadcrumb.parent }).route.matched
      state.breadcrumbs = [...matched, ...state.recursiveRoutes, data.$route]
    }
    // save the recursive route
    if (currentBreadcrumb && currentBreadcrumb.recursive) {
      state.recursiveRoutes.push(data.$route)
    }
  },
  SET_AUTOMATION(state, payload) {
    state.automation = payload;
  },
  SET_ORIGINAL_AUTOMATION(state, payload) {
    state.originalAutomation = payload;
  },
  SET_NEW_AUTOMATION(state, payload) {
    state.newAutomation = payload;
  },
  SET_OPEN_IN_LIBRARY(state, payload) {
    state.openInLibrary = payload;
  }
}

const actions = {

}

const getters = {
  breadcrumbTitle: (state) => (breadcrumb, params, data) => {
    let name = breadcrumb

    if (typeof name === 'function') {
      name = name.call(data, params || data.$route.params)
    }

    if (typeof name === 'object') {
      name = name.title
    }

    return name
  },
  breadcrumbPath: (state) => (breadcrumb, data) => {
    let { path, query, params } = breadcrumb
    for (const [key, value] of Object.entries(params || data.$route.params)) {
      path = path ? path.replace(`:${key}`, value) : path
    }
    if (query) {
      path = `${path}?` + qs.stringify(query)
    }

    return path
  },
  breadcrumbLast: state => {
    return state.breadcrumbs[state.breadcrumbs.length - 2];
  },
  GET_OPEN_IN_LIBRARY(state) {
    return state.openInLibrary;
  }
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
