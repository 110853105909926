<template>
  <svg id="Profile_Picture_Empty" data-name="Profile Picture Empty" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="95" height="95" viewBox="0 0 95 95">
    <defs>
      <clipPath id="clip-path">
        <circle id="jake-davies-FoVlxDABKQc-unsplash" cx="47.5" cy="47.5" r="47.5" fill="#fafafa" stroke="#f3f3f3" stroke-width="1.5"/>
      </clipPath>
      <linearGradient id="linear-gradient" x1="0.403" y1="0.741" x2="1.238" y2="-1.918" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-opacity="0"/>
        <stop offset="1" stop-opacity="0.502"/>
      </linearGradient>
      <linearGradient id="linear-gradient-2" x1="0.414" y1="1" x2="1.964" y2="-1.85" xlink:href="#linear-gradient"/>
    </defs>
    <g id="CIRCLE" fill="#fafafa" stroke="rgba(0,0,0,0.1)" stroke-width="1.5">
      <circle cx="47.5" cy="47.5" r="47.5" stroke="none"/>
      <circle cx="47.5" cy="47.5" r="46.75" fill="none"/>
    </g>
    <g id="Mask_Group_65" data-name="Mask Group 65" clip-path="url(#clip-path)">
      <g id="Group_4378" data-name="Group 4378" transform="translate(12.136 17.72)">
        <path id="Path_634" data-name="Path 634" d="M360,387.595a34.972,34.972,0,0,0-34.97,34.97v4.383a10.678,10.678,0,0,0,10.677,10.672h48.59a10.677,10.677,0,0,0,10.672-10.672v-4.383A34.973,34.973,0,0,0,360,387.595Z" transform="translate(-325.028 -346.797)" fill="url(#linear-gradient)"/>
        <path id="Path_635" data-name="Path 635" d="M346.641,416.56a18.42,18.42,0,1,0-18.418-18.417A18.416,18.416,0,0,0,346.641,416.56Z" transform="translate(-311.671 -379.72)" fill="url(#linear-gradient-2)"/>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconProfileEmpty'
  }
</script>