var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-control-select", {
    attrs: {
      options: _vm.columnOptions,
      "track-by": "key",
      "select-label": "header",
      identifier: false,
      small: "",
      full: ""
    },
    model: {
      value: _vm.header,
      callback: function($$v) {
        _vm.header = $$v
      },
      expression: "header"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }