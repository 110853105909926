var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "media-add",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "147",
        height: "37",
        viewBox: "0 0 147 37"
      }
    },
    [
      _c("rect", {
        attrs: { width: "147", height: "37", rx: "18.5", fill: "#fff" }
      }),
      _vm._v(" "),
      _c("g", { staticClass: "media-add_cont" }, [
        _c(
          "g",
          {
            staticClass: "media-add_txt",
            attrs: { transform: "translate(27.222)" }
          },
          [
            _c(
              "text",
              {
                attrs: {
                  transform: "translate(0 15)",
                  fill: "#293AD3",
                  "font-size": "15",
                  "font-family": "TitilliumWeb-Bold, Titillium Web",
                  "font-weight": "700",
                  "letter-spacing": "0.02em"
                }
              },
              [
                _c("tspan", { attrs: { x: "0", y: "0" } }, [
                  _vm._v("Add Media")
                ])
              ]
            ),
            _vm._v(" "),
            _vm.duration
              ? _c(
                  "text",
                  {
                    attrs: {
                      transform: "translate(0 45)",
                      "font-size": "16",
                      "font-family": "TitilliumWeb, Titillium Web",
                      "font-weight": "600",
                      "letter-spacing": ""
                    }
                  },
                  [
                    _vm.duration == "00:00:00"
                      ? _c("tspan", { attrs: { x: "0", y: "0" } }, [
                          _vm._v("Add Media")
                        ])
                      : _c("tspan", { attrs: { x: "0", y: "0" } }, [
                          _vm._v(_vm._s(_vm.duration))
                        ])
                  ]
                )
              : _c(
                  "text",
                  {
                    attrs: {
                      transform: "translate(0 15)",
                      "font-size": "15",
                      "font-family": "TitilliumWeb-Bold, Titillium Web",
                      "font-weight": "700",
                      "letter-spacing": "0.02em"
                    }
                  },
                  [
                    _c("tspan", { attrs: { x: "0", y: "0" } }, [
                      _vm._v("Add Media")
                    ])
                  ]
                )
          ]
        ),
        _vm._v(" "),
        _c(
          "g",
          {
            staticClass: "media-add_plus",
            attrs: { transform: "translate(0 10.821) rotate(-45)" }
          },
          [
            _c("line", {
              attrs: {
                id: "Line_1",
                "data-name": "Line 1",
                y1: "8.829",
                x2: "8.829",
                fill: "none",
                stroke: "#293ad3",
                "stroke-linecap": "round",
                "stroke-width": "2"
              }
            }),
            _vm._v(" "),
            _c("line", {
              attrs: {
                id: "Line_2",
                "data-name": "Line 2",
                x2: "8.829",
                y2: "8.829",
                fill: "none",
                stroke: "#293ad3",
                "stroke-linecap": "round",
                "stroke-width": "2"
              }
            }),
            _vm._v(" "),
            _c("line", {
              attrs: {
                id: "Line_3",
                transform: "translate(-20 20)",
                "data-name": "Line 1",
                y1: "8.829",
                x2: "8.829",
                fill: "none",
                stroke: "#293ad3",
                "stroke-linecap": "round",
                "stroke-width": "2"
              }
            }),
            _vm._v(" "),
            _c("line", {
              attrs: {
                id: "Line_4",
                transform: "translate(-20 20)",
                "data-name": "Line 2",
                x2: "8.829",
                y2: "8.829",
                fill: "none",
                stroke: "#293ad3",
                "stroke-linecap": "round",
                "stroke-width": "2"
              }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }