var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 20 20"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: "red",
          d:
            "M10 1a9 9 0 00-9 9c0 4.971 4.029 9 9 9s9-4.029 9-9a9 9 0 00-9-9zm4.48 10.344h-3.136v3.136H8.656v-3.136H5.52V8.656h3.136V5.52h2.688v3.136h3.136v2.688z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M11.344 5.52H8.656v3.136H5.52v2.688h3.136v3.136h2.688v-3.136h3.136V8.656h-3.136z"
        }
      }),
      _c("g", { attrs: { fill: "none" } }, [
        _c("circle", { attrs: { cx: "10", cy: "10", r: "9" } }),
        _c("circle", {
          attrs: { cx: "10", cy: "10", r: "9.5", stroke: "#E4E4E4" }
        })
      ]),
      _c("g", [
        _c("defs", [
          _c("path", { attrs: { id: "a", d: "M-5002-2986.5h1000v600h-1000z" } })
        ]),
        _c("clipPath", { attrs: { id: "b" } }, [
          _c("use", { attrs: { overflow: "visible", "xlink:href": "#a" } })
        ]),
        _c("g", { attrs: { "clip-path": "url(#b)" } }, [
          _c("path", {
            attrs: { fill: "#0065BD", d: "M-5002-2986.5H4998v6000H-5002z" }
          }),
          _c("path", {
            attrs: {
              fill: "none",
              stroke: "#FFF",
              "stroke-width": ".6",
              d: "M-5002-2986.5l1000 600m-1000 0l1000-600"
            }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }