<template>
  <div>
    <h3 class="txt-heading txt-center mb-4 pb-4"><b>Forgot Password?</b></h3>
    <b-form @submit.prevent.stop="onSubmit">
      <form-control-text
        label="Email"
        class="mb-3"
        :state="$v.email.$error ? false : null"
        @change="$v.email.$touch()"
        id="loginEmail"
        placeholder="Email address"
        required
        type="email"
        v-model="email"
        disabled />
      <form-control-text
        label="New password"
        class="mb-3"
        :state="$v.password.$error ? false : null"
        @change="$v.password.$touch()"
        id="registerPass"
        placeholder="New password"
        required
        type="password"
        v-model="password"
        showPasswordCheck
        :error="($v.password.$error && !$v.password.minLen) ? 'Minimum of 8 characters' : false" />
      <form-control-text
        label="Confirm new password"
        :state="$v.passwordConfirm.$error ? false : null"
        @change="$v.passwordConfirm.$touch()"
        aria-describedby="registerPasswordMatch"
        id="registerPassC"
        placeholder="Confirm new password"
        required
        type="password"
        v-model="passwordConfirm"
        showPasswordCheck
        :error="($v.passwordConfirm.$error) ? `The passwords don't match.` : false"/>
        <b-alert :show="!!error" fade variant="warning" class="my-3">
          <svg viewBox="0 0 24 24" class="alert-icon icon-sm"><use xlink:href="#icon-warning-triangle-color"></use></svg>
          <div class="alert-content">
            <span class="txt-body">{{error}}</span>
            <a href="#" class="alert-link" @click.prevent="onReset()">
              <b>Try again?</b>
            </a>
          </div>
        </b-alert>
      <b-btn :disabled="$v.$invalid || loading" block type="submit" variant="primary" class="my-4">Reset password</b-btn>
      <p class="mt-2 txt-body txt-center auth-page-cta"><b>Already have an account?</b> <b-btn variant="link ml-1" :to="{name: 'login'}">Login!</b-btn></p>
    </b-form>
  </div>
</template>

<script>
import { email, required, minLength, sameAs } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import { emailFormatter } from '@app2/utils/helpers';
import FormControlText from "@app2/core/Components/Common/Forms/Text";

const { mapActions: mapAuthActions } = createNamespacedHelpers('auth');
export default {
  name: 'ResetPassword',
  components: {FormControlText},
  metaInfo() {
    return {
      title: 'Reset Password',
    }
  },
  data: () => ({
    email: '',
    password: '',
    passwordConfirm: '',
    token: null,
    error: null,
    loading: null,
  }),
  validations: {
    email: {
      required,
      email: (val) => email(emailFormatter(val)),
    },
    password: {
      required,
      minLen: minLength(8)
    },
    passwordConfirm: {
      sameAs: sameAs('password')
    }
  },
  created() {
    this.email = emailFormatter(this.$route.query.email || null);
    this.token = this.$route.query.token;
    if (!this.token || !this.email) {
      this.$router.push('/login');
    }
  },
  methods: {
    ...mapAuthActions(['resetPassword']),
    async onSubmit() {
      this.error = null;
      this.loading = true;
      try {
        const data = {
          email: this.email,
          token: this.token,
          password: this.password,
          password_confirmation: this.passwordConfirm,
        };
        await this.resetPassword(data);
        this.$toasted.show('Password reset successfully!', { type: 'success' });
        this.$router.push('/');
      } catch ({ data: { message } }) {
        this.error = message;
      } finally {
        this.loading = null;
      }
    },
    onReset() {
      this.error = null;
      this.email = null;
      this.password = null;
      this.$v.$reset();
    }
  },
};
</script>
