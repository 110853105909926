export default {
  data() {
    return {
      order: 'desc',
      orderBy: 'created_at',
      orderByOptions: [
        {
          id: 'title',
          title: 'Name',
        },
        {
          id: 'created_at',
          title: 'Modified',
        },
        // {
        //   id: 'size',
        //   title: 'Size',
        // }
      ],
      orderOptions: [
        {
          id: 'asc',
          title: 'Ascending',
          symbol: ''
        },
        {
          id: 'desc',
          title: 'Descending',
          symbol: '-'
        }
      ]
    }
  },
  computed: {
    orderObj() {
      return this.orderOptions.find((opt) => opt.id === this.order) || this.orderOptions[0];
    },
    orderByObj() {
      return this.orderByOptions.find((opt) => opt.id === this.orderBy) || this.orderByOptions[0];
    },
    sortSymbol () {
      return this.order === 'asc' ? '' : '-'
    },
    sortQuery () {
      let sortBy = this.orderBy
      sortBy === 'title' ? 'lowerTitle' : sortBy
      return `${this.sortSymbol}${this.orderBy}`
    },
    currentFileSortQuery() {
      const order = this.orderBy;
      return order
    }
  },
  methods: {
    setOrders(type = 'file') {
      let order = localStorage.getItem(`${type}Order`);
      this.order = ( !order || order === 'newest' || order === 'relevance' ) ? 'desc' : order;
      this.orderBy = localStorage.getItem(`${type}OrderBy`) || 'created_at';
    },
    toggleOrder(order, type = 'file') {
      this.order = order.id;
      localStorage.setItem(`${type}Order`, this.order);
    },
    toggleOrderBy(order, type = 'file') {
      this.orderBy = order.id;
      localStorage.setItem(`${type}OrderBy`, this.orderBy);
    },
    handleSort ({ order, orderBy }) {
      this.order = order;
      this.orderBy = orderBy;
    },
  }

}
