<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" class="icon-computer" preserveAspectRatio="xMidYMid meet">
    <g data-name="Icon Holder">
      <g data-name="Facebook Icon" fill="#939393">
        <path data-name="Path 714" d="M13.85 12.5h-12a.75.75 0 1 0 0 1.5h12a.75.75 0 0 0 0-1.5z"/>
        <path data-name="Path 715"
              d="M14.7 5.02V4.9A2.9 2.9 0 0 0 11.99 2H3.72A2.9 2.9 0 0 0 1 4.9v4.33a2.9 2.9 0 0 0 2.72 2.89h8.26a2.9 2.9 0 0 0 2.73-2.89v-.13zm-1.5 4.12v.08a1.4 1.4 0 0 1-1.3 1.4H3.8a1.4 1.4 0 0 1-1.3-1.4V4.9a1.4 1.4 0 0 1 1.3-1.4h8.1a1.4 1.4 0 0 1 1.3 1.4v4.25z"/>
      </g>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'IconComputer',
  };
</script>
