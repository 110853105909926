var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "badge",
      staticClass: "hub-badge",
      class: [{ "hub-badge-disabled": _vm.disabled }, "hub-badge-" + _vm.size]
    },
    [
      _c("hub-badge-shape", {
        class: { invisible: !_vm.shape },
        attrs: {
          element: _vm.shape,
          "outline-color": _vm.outline,
          "base-color": _vm.base
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "hub-badge-icon", style: _vm.iconStyles },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [
              _vm.iconType === "icon"
                ? _c(
                    "svg",
                    {
                      key: "badge-icon",
                      class: { "badge-emoji": _vm.innerIcon == "emoji" },
                      style: { backgroundPosition: _vm.innerPosition },
                      attrs: { width: "25", viewBox: "0 0 25 25" }
                    },
                    [
                      _c("use", {
                        attrs: { "xlink:href": "#badge-" + _vm.innerIcon }
                      })
                    ]
                  )
                : _c("badge-text", {
                    key: "badge-text",
                    attrs: { text: _vm.inner }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }