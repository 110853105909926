<template>
  <svg class="icon-section-cta-grid" xmlns="http://www.w3.org/2000/svg" width="19.033" height="15.006" viewBox="0 0 19.033 15.006">
    <path d="M3.25,8.512H5.262a3.249,3.249,0,0,0,3.25-3.25V3.25A3.249,3.249,0,0,0,5.262,0H3.25A3.249,3.249,0,0,0,0,3.25V5.262a3.25,3.25,0,0,0,3.25,3.25M1.5,3.25A1.753,1.753,0,0,1,3.25,1.5H5.262a1.752,1.752,0,0,1,1.75,1.75V5.262a1.752,1.752,0,0,1-1.75,1.75H3.25A1.752,1.752,0,0,1,1.5,5.262Z" fill="#939393"/><path d="M15.783,0H13.771a3.249,3.249,0,0,0-3.25,3.25V5.262a3.249,3.249,0,0,0,3.25,3.25h2.012a3.25,3.25,0,0,0,3.25-3.25V3.25A3.25,3.25,0,0,0,15.783,0m1.75,5.262a1.753,1.753,0,0,1-1.75,1.75H13.771a1.753,1.753,0,0,1-1.75-1.75V3.25A1.753,1.753,0,0,1,13.771,1.5h2.012a1.753,1.753,0,0,1,1.75,1.751Z" fill="#939393"/><path d="M13.4,10.259H.75a.75.75,0,0,0,0,1.5H13.4a.75.75,0,0,0,0-1.5" fill="#939393"/><path d="M9.984,13.506H.75a.75.75,0,0,0,0,1.5H9.984a.75.75,0,1,0,0-1.5" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-section-cta-grid'
  }
</script>
