<template>
  <div>
    <form role="form">
      <div>
        <label class="form-control-label txt-body">What is your profession?</label>
        <multiselect
          class="mb-3"
          @close="checkValid"
          v-model="form.selected_industry"
          :options="['Coach / Consultant', 'Sales / Marketing Professional', 'Podcaster', 'Education Professional', 'Other']"
          openDirection="bottom"
          :maxHeight="172"
          required
          placeholder="Choose">
          <span slot="placeholder"></span>
          <template slot="singleLabel" slot-scope="props">
            <span>{{props.option}}</span>
          </template>
          <template slot="caret" slot-scope="{ toggle }"> 
            <svg @mousedown.prevent.stop="toggle" viewBox="0 0 24 24" class="icon-regular caret"><use xlink:href="#icon-chevron-down"></use></svg>
          </template>
        </multiselect>
      </div>
      <div>
        <label class="form-control-label txt-body">What is your company size? (if applicable)</label>
        <multiselect
          class="mb-3"
          @close="checkValid"
          :maxHeight="172"
          required
          v-model="form.employees"
          :options="['1', '2-5', '6-20', '21-50', '51-100', '101-150', '150-250', '250+']"
          placeholder="Choose">
          <span slot="placeholder"></span>
          <template slot="singleLabel" slot-scope="props">
            <span>{{ props.option == 1 ? 'Just Me' :  props.option}}</span>
          </template>
          <template slot="option" slot-scope="props">
            <span>{{ props.option == 1 ? 'Just Me' :  props.option}}</span>
          </template>
          <template slot="caret" slot-scope="{ toggle }"> 
            <svg @mousedown.prevent.stop="toggle" viewBox="0 0 24 24" class="icon-regular caret"><use xlink:href="#icon-chevron-down"></use></svg>
          </template>
        </multiselect>
      </div>
    </form>
    <div class="onboarding-cta pt-4 my-4">
      <slot name="back"></slot>
      <slot name="action" ></slot>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import axios from '@app2/api/axios';

  export default {
    name: 'AboutStep',
    components: {
      Multiselect
    },
    data() {
      return {
        form: {
          selected_industry: null,
          employees: null,
        },
      }
    },
    mounted() {
      if (this.form.selected_industry && this.form.employees) {
        this.$emit('loading', false);
      } else {
        this.$emit('loading', true);
      }
      this.$emit('animation', false);
      this.$parent.$on('step-next', this.stepNext.bind(this));
    },
    beforeDestroy() {
      this.$parent.$off('step-next');
    },
    methods: {
      submit() {
        return this.$store.dispatch('auth/updateOnboarding', {
          onboard_step: 3,
          meta: this.form
        });
      },
      async stepNext(done) {
        try {
          this.$emit('loading', true);
          await this.submit();
          this.$emit('next',3);
        } catch (e) {
          done(e);
          this.$emit('loading', true);
          throw e;
        }
      },
      catchEnter(e) {
        let cmd = e.key.toLowerCase();
        if (cmd == 'enter' && (this.form.selected_industry && this.form.employees)) {
          this.stepNext();
        }
    	},
      checkValid() {
        if (this.form.selected_industry && this.form.employees) {
          this.$emit('loading', false);
        } else {
          this.$emit('loading', true);
        }
      }
    },
    created() {
    	window.addEventListener('keypress', this.catchEnter);
    },
    destroyed() {
    	window.removeEventListener('keypress', this.catchEnter);
    },
  };
</script>
