<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14.659" height="16.521" viewBox="0 0 14.659 16.521">
    <g>
      <g>
        <path d="M1179.767,488.58h5.583a.751.751,0,0,0,0-1.5h-5.583a.751.751,0,0,0,0,1.5Z" transform="translate(-1175.229 -482.277)" fill="#939393"/>
        <path d="M1179.767,491.288h3.214a.751.751,0,0,0,0-1.5h-3.214a.751.751,0,0,0,0,1.5Z" transform="translate(-1175.229 -482.277)" fill="#939393"/>
        <path d="M1179.767,494h5.583a.751.751,0,0,0,0-1.5h-5.583a.751.751,0,0,0,0,1.5Z" transform="translate(-1175.229 -482.277)" fill="#939393"/>
        <path d="M1186.7,482.277a.75.75,0,0,0,0,1.5h0a1.691,1.691,0,0,1,1.688,1.689V495.61a1.691,1.691,0,0,1-1.688,1.688h-8.284a1.692,1.692,0,0,1-1.689-1.688V485.466a1.692,1.692,0,0,1,1.689-1.689.749.749,0,1,0,0-1.5,3.188,3.188,0,0,0-3.188,3.188V495.61a3.188,3.188,0,0,0,3.188,3.188h8.284a3.188,3.188,0,0,0,3.187-3.188V485.466A3.189,3.189,0,0,0,1186.7,482.277Z" transform="translate(-1175.229 -482.277)" fill="#939393"/>
        <path d="M1180.952,483.777h3.213a.751.751,0,0,0,0-1.5h-3.213a.751.751,0,0,0,0,1.5Z" transform="translate(-1175.229 -482.277)" fill="#939393"/>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconShare'
  }
</script>
