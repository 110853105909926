var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Thumbs_Up_Icon",
        "data-name": "Thumbs Up Icon",
        xmlns: "http://www.w3.org/2000/svg",
        width: "15.621",
        height: "14.984",
        viewBox: "0 0 15.621 14.984"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Path_7122",
          "data-name": "Path 7122",
          d:
            "M754.783,558.629h-2.848v-2.274a3.069,3.069,0,0,0-1.839-2.812l-.349-.152a1.329,1.329,0,0,0-.538-.114,1.343,1.343,0,0,0-1.318,1.089l-.158.813a5.307,5.307,0,0,1-1.187,2.445l-1.592,1.847a.744.744,0,0,0-.182.489v7.551a.749.749,0,0,0,.75.75h7.857a3.087,3.087,0,0,0,3.034-2.516l.79-4.2a2.425,2.425,0,0,0,.042-.46A2.462,2.462,0,0,0,754.783,558.629Zm.945,2.642-.789,4.2a1.588,1.588,0,0,1-1.56,1.294h-7.107v-6.522l1.41-1.636a6.8,6.8,0,0,0,1.523-3.137l.121-.622.169.074a1.568,1.568,0,0,1,.94,1.437v3.024a.75.75,0,0,0,.75.75h3.6a.966.966,0,0,1,.963.958Z",
          transform: "translate(-741.624 -553.277)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_7123",
          "data-name": "Path 7123",
          d:
            "M742.374,559.759a.75.75,0,0,0-.75.75v7a.75.75,0,0,0,1.5,0v-7A.75.75,0,0,0,742.374,559.759Z",
          transform: "translate(-741.624 -553.277)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }