<template>
  <div class="upgrade-promo fx-col-center">
    <h1 class="txt-title-large">
      <svg class="stars" width="59" height="65" viewBox="0 0 59 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.3553 47.5007C10.1413 48.2238 9.52272 51.7019 9.61858 52.4471C9.71444 53.1922 9.95758 53.9139 10.3551 54.5549C10.7526 55.196 11.0041 55.2666 11.6299 55.6999C12.2556 56.1332 14.8782 57.0452 15.6299 57.1999C11.3109 56.311 7.61669 59.9947 6.35879 63.0007C7.47193 60.3539 5.05814 55.0763 1.62977 53.7C9.35839 55.6999 9.96917 49.0659 10.3553 47.5007Z" stroke="black" stroke-width="3" stroke-miterlimit="10" stroke-linejoin="round"/>
        <path d="M37.748 5.27881L41.2089 12.0701" stroke="black" stroke-width="3.31934" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M32.5918 20.8491L38.7831 17.7982" stroke="black" stroke-width="3.31934" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M47.0273 14.344L53.054 11.0691" stroke="black" stroke-width="3.31934" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M45.2569 19.6998L48.4471 25.2863" stroke="black" stroke-width="3.31934" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <b><slot name="title"></slot></b>
      <svg class="hand" width="98" height="93" viewBox="0 0 98 93" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M88.58 74.1895L82.9416 60.4644C81.152 56.1122 78.2933 50.5852 78.9972 45.7479C79.6595 41.1789 80.6644 36.6211 82.3831 32.3376C83.271 30.1337 84.3539 27.9699 84.8861 25.647C85.4183 23.3242 85.5688 21.6086 85.0281 19.3487C84.7262 18.0724 84.1976 16.2036 82.7022 16.0219C81.0989 15.8288 79.8768 16.8809 79.0327 18.1206C75.841 22.8141 76.0475 29.0176 72.8234 33.731C70.8565 24.6577 68.7591 15.3198 63.4233 7.70785C62.2946 6.09787 60.4056 4.40925 58.5991 5.21193C57.2518 5.81368 57.8483 8.04802 58.0937 9.08538C60.0828 17.495 63.673 25.5137 64.9628 34.0764C64.4824 33.6916 64.0694 32.9647 63.5948 32.4738C62.9821 31.8433 62.3708 31.2187 61.7492 30.6026C60.5045 29.3647 59.2406 28.1498 57.9588 26.9637C55.4268 24.621 52.8105 22.366 50.1184 20.2088C47.9529 18.4748 45.5702 16.7368 42.8024 16.4807C42.007 16.4104 41.1572 16.476 40.4989 16.9332C39.3504 17.7218 39.2058 19.4361 39.7776 20.7039C40.3494 21.9718 41.4525 22.8986 42.4929 23.8217C47.2238 28.0136 51.3385 32.8928 54.6661 38.2596C50.58 36.8199 46.0118 37.2358 42.0682 38.907C37.6081 40.7989 34.0821 45.6207 34.799 50.5589C34.9893 51.8862 35.9357 53.0654 37.0639 53.7586C39.19 55.065 42.1273 54.0256 43.7195 52.2644C44.5726 51.3241 44.9834 50.0823 45.9398 49.1607C50.6982 44.5582 59.2502 47.2924 59.5552 54.1742C59.6486 56.2937 58.5606 58.5142 56.6557 59.4755C53.5081 61.0631 49.8367 60.2268 47.0511 58.4057C44.6615 56.8478 42.8671 55.0629 39.7831 55.6385C38.5928 55.8585 37.6199 56.8148 37.209 57.952C36.4178 60.1207 37.7654 63.0963 39.2475 64.6922C41.2382 66.834 44.2352 68.4711 47.0061 69.4818C53.4873 71.8457 57.163 71.5524 64.553 71.1768C69.3288 70.9333 69.9008 76.6427 72.2777 79.8713" stroke="black" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23.0625 48.1455L5.76193 43.3876" stroke="black" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.4316 54.3821L14.2209 56.0876" stroke="black" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </h1>
    <p class="txt-muted txt-heading-small mt-2 mb-4 pb-3 txt-center">{{ description }}</p>
    <div class="upgrade-promo-buttons fx-row-center">
      <svg class="arrow" width="106" height="96" viewBox="0 0 106 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.3898 75.9118C14.3794 45.0537 48.9927 13.5414 86.291 11.9273" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M67.1884 5.03375C75.1778 7.9058 78.6667 8.84839 86.6427 11.6783L72.2151 24.4246" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M29.3336 54.1589C20.2524 68.6271 25.5388 87.7683 39.0657 90.3916" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <b-btn ref="upgrade-btn" class="all-events mr-2" variant="primary" size="lg" @click.stop="upgrade">
        <svg class="icon-regular btn-left" viewBox="0 0 24 24"><use xlink:href="#icon-lock-locked"></use></svg>
        <span class="txt-heading-small mb-0"><b>Upgrade</b></span>
      </b-btn>
      <b-btn variant="primary-dark" class="tooltip-cta" size="lg" :href="link" target="_blank">
        <span class="txt-heading-small mb-0"><b>Learn More</b></span>
      </b-btn>
    </div>
    <div class="upgrade-promo-image">
      <slot name="image"></slot>
    </div>
  </div>
</template>

<script>
import rootRouter from "@app2/router";

export default {
  name: 'UpgradePromo',
  props: {
    description: {
      type: String
    },
    link: {
      type: String
    },
  },
  methods: {
    upgrade() {
      rootRouter.push({name: 'AccountSubscription'});
    }
  }
}
</script>