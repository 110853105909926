<template>
  <svg class="icon-user" xmlns="http://www.w3.org/2000/svg" width="13.5" height="17.531" viewBox="0 0 13.5 17.531">
    <path vector-effect="non-scaling-stroke" d="M331.778,387.6a6.751,6.751,0,0,0-6.75,6.75v.846a2.061,2.061,0,0,0,2.061,2.06h9.379a2.061,2.061,0,0,0,2.06-2.06v-.846A6.751,6.751,0,0,0,331.778,387.6Zm5.25,7.6a.563.563,0,0,1-.56.56h-9.379a.563.563,0,0,1-.561-.56v-.846a5.25,5.25,0,0,1,10.5,0Z" transform="translate(-325.028 -379.72)" fill="#939393"/>
    <path vector-effect="non-scaling-stroke" d="M331.778,386.831a3.555,3.555,0,1,0-3.555-3.555A3.555,3.555,0,0,0,331.778,386.831Zm0-5.611a2.055,2.055,0,1,1-2.056,2.056A2.059,2.059,0,0,1,331.778,381.22Z" transform="translate(-325.028 -379.72)" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconUser',
  };
</script>
