var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-upload",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "24",
        height: "24",
        viewBox: "0 0 24 24"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M23.186 12.258a5.217 5.217 0 00-7.132-4.9A6.219 6.219 0 003.9 9.226c0 .167.012.334.025.5a3.963 3.963 0 001.048 7.788h6.366a.75.75 0 100-1.5H4.973a2.464 2.464 0 01-.163-4.923.75.75 0 00.688-.9 4.815 4.815 0 01-.1-.964 4.726 4.726 0 019.4-.681.75.75 0 001.143.526 3.755 3.755 0 015.367 4.822.75.75 0 101.349.656 5.227 5.227 0 00.529-2.292z",
          fill: "#fff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M17.12 12.656a.751.751 0 00-1.061 0L13.915 14.8a.751.751 0 001.062 1.062l.865-.864v4.657a.75.75 0 001.5 0v-4.654l.862.862a.75.75 0 001.061-1.06z",
          fill: "#fff"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }