<template>
  <svg class="media-snapshot-finishes" xmlns="http://www.w3.org/2000/svg" width="16.252" height="16.252" viewBox="0 0 16.252 16.252">
    <path d="M8.126 0a8.126 8.126 0 108.126 8.126A8.126 8.126 0 008.126 0zm4.685 12.811a6.624 6.624 0 111.941-4.685 6.6 6.6 0 01-1.941 4.685z" fill="#939393"/>
    <path d="M10.547 5.613L7.111 9.048 5.705 7.641a.751.751 0 00-1.061 1.062l1.937 1.937a.75.75 0 001.061 0l3.966-3.966a.75.75 0 00-1.061-1.06z" fill="#939393"/>
  </svg>
</template>

<script>
  export default {
    name: 'MediaSnapshotFinishes'
  }
</script>
