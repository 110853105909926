var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-impersonate",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.215",
        height: "16.137",
        viewBox: "0 0 16.215 16.137"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M11.837 9.782a3.168 3.168 0 00-2.656 1.439 2.01 2.01 0 00-2.148 0 3.175 3.175 0 10.52 1.756v-.017a.55.55 0 111.1 0v.019a3.177 3.177 0 103.177-3.2zm-7.455 4.856a1.678 1.678 0 111.677-1.678 1.682 1.682 0 01-1.677 1.678zm7.459 0a1.678 1.678 0 111.677-1.678 1.682 1.682 0 01-1.681 1.678z",
          fill: "#293ad3"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M15.992 8.1l-2.534-2.5-1.2-5.022a.75.75 0 00-1-.527L8.104 1.259 4.951.051a.75.75 0 00-1 .527l-1.2 5.021-2.534 2.5a.749.749 0 00.527 1.283H15.46a.749.749 0 00.526-1.283zM5.216 1.757l2.623 1.005a.742.742 0 00.537 0l2.623-1.005.829 3.475H4.382zM3.742 6.728h8.73l1.166 1.152H2.576z",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }