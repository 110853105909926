<template>
  <svg class="icon-section-scroll" xmlns="http://www.w3.org/2000/svg" width="23.604" height="15.501" viewBox="0 0 23.604 15.501">
    <g fill="#939393"><path d="M22.859 14.001h-.754a1.254 1.254 0 01-1.25-1.25v-10a1.253 1.253 0 011.25-1.251h.754a.75.75 0 100-1.5h-.754a2.75 2.75 0 00-2.75 2.75v10a2.75 2.75 0 002.75 2.749h.754a.75.75 0 100-1.5z"/><path d="M15.231 0H8.477a2.75 2.75 0 00-2.75 2.75v10a2.75 2.75 0 002.75 2.749h6.754a2.749 2.749 0 002.75-2.749v-10A2.749 2.749 0 0015.231 0zm1.25 12.75a1.253 1.253 0 01-1.25 1.25H8.477a1.254 1.254 0 01-1.25-1.25v-10a1.253 1.253 0 011.25-1.251h6.754a1.253 1.253 0 011.25 1.251z"/><path d="M1.504.001H.75a.75.75 0 000 1.5h.754a1.253 1.253 0 011.25 1.251v10a1.253 1.253 0 01-1.25 1.25H.75a.75.75 0 000 1.5h.754a2.749 2.749 0 002.75-2.749v-10A2.749 2.749 0 001.504.001z"/></g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-section-scroll'
  }
</script>
