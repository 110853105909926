<template>
  <b-modal
    class="segment-slide-in"
    id="segment-editor-dialog"
    modal-class="modal-right"
    static
    ref="modal"
    hide-footer
    hide-header
    v-model="isModalOpen"
    @show="onShow"
    @hide="onHide"
  >
    <div id="segment-app"></div>
  </b-modal>
</template>
<script>
  import App from '@app2/modules/Audience/Segment/segment.app';
  import router from "@app2/modules/Audience/Segment/segment.router"
  import Segment from "@app2/models/Segment";
  import eventBus from "@app2/core/eventBus";
  import rootVue from "@app2/app";
  export default {
    name: 'SegmentEditor',
    data() {
      return {
        app: null,
      }
    },
    computed: {
      isModalOpen: {
        get() { return this.$store.state.v2.audience.segment.isModalOpen },
        set(val) { return this.$store.commit('v2/audience/segment/SET_MODAL_STATE', val) },
      },
      segment() {
        return Segment.find(router.currentRoute.params.hash);
      },
    },
    mounted() {
      this.app = App();
    },
    methods: {
      onShow() {
        this.$store.dispatch('v2/audience/segment/getAttributes');
        this.$store.commit('v2/audience/segment/SET_SEGMENT_KEY');
        if ( !router.currentRoute.name ) router.push({name: 'Create'}).catch(() => {});
      },
      onHide(event) {
        if ( router.currentRoute.name === 'Item' && this.segment.hasChanged && !this.$store.state.v2.audience.segment.applying ) {
          event.preventDefault();
          eventBus.$emit('segmentToExit', () => {
            rootVue.$bvModal.hide('segment-editor-dialog');
          });
        }
        if ( !this.$store.state.v2.audience.segment.showFilters ) {
          this.$store.dispatch('v2/audience/segment/resetNewSegment');
        }
        this.$store.commit('v2/audience/segment/SET_SELECT_MODEL', null)
        this.$store.commit('v2/audience/segment/SET_APPLYING', false);
      }
    }
  }
</script>
