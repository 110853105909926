var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ul",
        _vm._l(_vm.lineNumber, function(i) {
          return _c("li", { key: i }, [_vm._v(_vm._s(i))])
        }),
        0
      ),
      _vm._v(" "),
      _c("b-textarea", {
        attrs: { rows: "10" },
        on: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.generateLineNumber($event)
          }
        },
        model: {
          value: _vm.audienceList,
          callback: function($$v) {
            _vm.audienceList = $$v
          },
          expression: "audienceList"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }