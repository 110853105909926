var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-vocab",
      attrs: {
        width: "20.729",
        height: "15.342",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 20.729 15.342"
      }
    },
    [
      _c("g", { attrs: { fill: "#939393" } }, [
        _c("path", {
          attrs: {
            d:
              "M20.678 2.155a.751.751 0 00-.335-.622A10.5 10.5 0 0014.987 0a8.874 8.874 0 00-4.652 1.317A8.887 8.887 0 005.751.038 10.494 10.494 0 00.328 1.613a.746.746 0 00-.33.624L.05 14.591a.75.75 0 001.17.619 9.076 9.076 0 014.581-1.321 7.41 7.41 0 014.173 1.284.755.755 0 00.835 0 7.4 7.4 0 014.225-1.319 8.868 8.868 0 013.253.651 9.028 9.028 0 01.957.446c.11.059.194.109.248.141l.058.037.012.007a.75.75 0 001.166-.623zm-5.642 10.2a8.88 8.88 0 00-4.649 1.317 8.87 8.87 0 00-4.584-1.278 10.415 10.415 0 00-4.257.956L1.502 2.664a9.079 9.079 0 014.249-1.125 7.408 7.408 0 014.173 1.284.755.755 0 00.835 0 7.4 7.4 0 014.228-1.32 9.17 9.17 0 014.193 1.09l.044 10.687a10.435 10.435 0 00-4.188-.926z"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M12.743 5.885a6.576 6.576 0 012.278-.442 5.039 5.039 0 011.342.181.75.75 0 00.4-1.446 6.5 6.5 0 00-1.742-.234 8.059 8.059 0 00-2.8.534.75.75 0 10.52 1.407zM16.777 7.622a6.485 6.485 0 00-1.74-.234 8.083 8.083 0 00-2.8.534.75.75 0 00.52 1.408 6.586 6.586 0 012.279-.443 5.067 5.067 0 011.34.181.75.75 0 00.4-1.445zM4.328 3.779l-1.463 6.64 1.345-.006.258-1.2 2.321-.01.27 1.2 1.345-.005L6.882 3.77zm.389 4.262l.66-3.154h.469l.687 3.148z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }