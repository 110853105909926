var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "28",
        height: "28",
        viewBox: "0 0 28 28"
      }
    },
    [
      _c("circle", { attrs: { cx: "14", cy: "14", r: "14", fill: "#ef4e4e" } }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          cx: "5",
          cy: "5",
          r: "5",
          transform: "translate(9 9)",
          fill: "#fff"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }