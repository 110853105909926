<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.933" height="18.65" viewBox="0 0 17.933 18.65">
    <g id="Group_20113" data-name="Group 20113" transform="translate(-195.827 -78.796)">
      <path id="Path_7801" data-name="Path 7801" d="M210.857,84.8H198.731a2.9,2.9,0,0,0-2.9,2.9v6.843a2.9,2.9,0,0,0,2.9,2.9h12.126a2.9,2.9,0,0,0,2.9-2.9V87.7A2.9,2.9,0,0,0,210.857,84.8Zm1.4,9.745a1.407,1.407,0,0,1-1.4,1.4H198.731a1.407,1.407,0,0,1-1.4-1.4V87.7a1.4,1.4,0,0,1,1.4-1.4h12.126a1.406,1.406,0,0,1,1.4,1.4Z" fill="#939393"/>
      <path id="Path_7802" data-name="Path 7802" d="M199.359,83.3h10.87a.75.75,0,0,0,0-1.5h-10.87a.75.75,0,0,0,0,1.5Z" fill="#939393"/>
      <path id="Path_7803" data-name="Path 7803" d="M201.253,80.3h7.082a.75.75,0,0,0,0-1.5h-7.082a.75.75,0,0,0,0,1.5Z" fill="#939393"/>
      <path id="Path_7804" data-name="Path 7804" d="M207.056,90.373h-1.512V88.861a.75.75,0,0,0-1.5,0v1.512h-1.511a.75.75,0,0,0,0,1.5h1.511v1.511a.75.75,0,0,0,1.5,0V91.873h1.512a.75.75,0,0,0,0-1.5Z" fill="#939393"/>
    </g>
  </svg>

</template>

<script>
  export default {
    name: 'IconPlaylistAddAlt'
  }
</script>
