import { Node } from 'prosemirror-to-html-js';

export default class ItemList extends Node {
  matching () {
    return this.node.type === "list_item" || this.node.type === "listItem";
  }

  tag () {
    return "li";
  }
}
