var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        "data-name": "Rename Icon",
        xmlns: "http://www.w3.org/2000/svg",
        width: "14.3",
        height: "17.1"
      }
    },
    [
      _c("path", {
        attrs: {
          "data-name": "Path 9227",
          d:
            "M1.1 14.1l4.5-1.8a.8.8 0 00.2-.2l7.7-7.7A2.6 2.6 0 009.8.8L2.1 8.4a.7.7 0 00-.1.3L0 13a.8.8 0 001 1zm2.2-4.7l7.6-7.6a1.1 1.1 0 111.5 1.6L5 10.9 2.2 12z",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          "data-name": "Path 9228",
          d: "M13.6 15.6H.8a.8.8 0 000 1.5h12.8a.8.8 0 000-1.5z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }