<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14.215" height="14.214" viewBox="0 0 14.215 14.214">
    <path id="eyedropper" d="M1068.007,153.131a1.658,1.658,0,0,0-2.344,0l-3.38,3.381-1.032-1.031a.75.75,0,1,0-1.061,1.061l.5.5-5.709,5.708a2.408,2.408,0,0,0,3.4,3.4l5.709-5.708.5.5a.75.75,0,0,0,1.061-1.061l-.5-.5h0l-.531-.53,3.381-3.381A1.658,1.658,0,0,0,1068.007,153.131Zm-10.681,11.963a.907.907,0,0,1-1.284-1.282l5.709-5.709,1.093,1.092.19.191Z" transform="translate(-1054.277 -152.646)" fill="#293ad3"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-eyedropper'
  }
</script>
