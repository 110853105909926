var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-css",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.825",
        height: "19.224",
        viewBox: "0 0 17.825 19.224"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M15.328 0H2.464l-.191.011h-.037A2.5 2.5 0 00.001 2.493l.014.268 1.276 11.944a2.5 2.5 0 001.5 2.031l5.362 2.286a2.51 2.51 0 002.018-.028l4.919-2.258a2.5 2.5 0 001.443-2l1.28-12 .011-.2v-.042A2.5 2.5 0 0015.328 0zm-.864 15.378l-4.909 2.255a1 1 0 01-.419.091 1.019 1.019 0 01-.394-.08l-5.361-2.285a1 1 0 01-.6-.812L1.505 2.601l-.006-.106a1 1 0 01.89-.989l.129-.008h12.809a1 1 0 011 .992l-.008.13-1.275 11.959a1 1 0 01-.577.8",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M13.218 3.916H4.604a.75.75 0 000 1.5h5.113L4.689 7.675a.75.75 0 00.308 1.434h6.991l-.352 3.321-2.726 1.092-2.72-1.092-.163-1.531a.75.75 0 00-1.491.158l.21 1.98a.75.75 0 00.466.617l3.418 1.373a.75.75 0 00.559 0l3.424-1.373a.749.749 0 00.467-.617l.487-4.6a.749.749 0 00-.745-.829H8.497l5.028-2.258a.75.75 0 00-.307-1.434z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }