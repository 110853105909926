var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 20 20"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(1 1)" } }, [
        _c("path", {
          attrs: {
            d: "M773,344a9,9,0,1,1-9-9,9,9,0,0,1,9,9Zm0,0",
            transform: "translate(-755 -335)",
            fill: "#6da544"
          }
        }),
        _c("path", {
          attrs: {
            d: "M798.052,415.141l7.435,5.478-7.435,5.478-7.435-5.478Zm0,0",
            transform: "translate(-789.052 -411.619)",
            fill: "#ffda44"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M894.827,471.7a3.13,3.13,0,1,1-3.13-3.13,3.13,3.13,0,0,1,3.13,3.13Zm0,0",
            transform: "translate(-882.696 -462.696)",
            fill: "#f0f0f0"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M890.154,535.348a5.264,5.264,0,0,0-1.564.236,3.129,3.129,0,0,0,5.693,1.755,5.275,5.275,0,0,0-4.129-1.991Zm0,0",
            transform: "translate(-882.719 -526.544)",
            fill: "#0052b4"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M900.289,472.3a3.131,3.131,0,0,0-5.949-1.835,6.454,6.454,0,0,1,5.949,1.835Zm0,0",
            transform: "translate(-888.217 -462.696)",
            fill: "#0052b4"
          }
        }),
        _c(
          "g",
          { attrs: { fill: "none", stroke: "#e4e4e4", "stroke-width": "1" } },
          [
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9", stroke: "none" }
            }),
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9.5", fill: "none" }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }