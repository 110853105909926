import store from "@app2/store";
import axios from "@app2/api/axios";
import get from 'lodash/get';
import {set} from '@app2/utils/helpers';

export default class Campaign {
    constructor(promoHash) {
        this.promoHash = promoHash;
    }

    async initialize() {
        this.data = get(store, 'state.v2.register.campaign');
        this.state = get(store, 'state.auth.user.team.meta.latest_campaign_checkout_state');
        if (!this.data || this.data?.hash !== this.promoHash || this.items?.length !== this.items?.filter(item => item && !!item.chargebee_item_price)?.length) {
            try {
                let request = await axios.get(`/api/internal/v2/campaigns/${this.promoHash}`);
                this.data = request.data;
            } catch (error) {
                console.error('Axios request error:', error);
            }
        }
        if ( this.data?.hash !== this.promoHash ) this.state = null;
        store.commit('v2/register/SET_CAMPAIGN', this.data);
    }

    updateUser(user) {
        user = get(store.state.auth, 'user', user);
        if ( !user ) return;
        if ( !user.meta || Array.isArray(user.meta) ) set(user, 'meta', {});
        set(user, 'meta.promo_hash', this.promoHash);
        if ( !user.name || !user.email ) return;
        axios.put('/settings/contact', {
            name: user.name,
            email: user.email,
            meta: user.meta,
        });
    }

    async checkout() {
        const { data } = await axios.post('/api/internal/v2/campaigns/checkout', {
            hash: this.promoHash,
        });
        if ( data && data.url ) {
            // Open the checkout URL in the current window
            // window.open(data.url, '_self');
            this.url = data.url;
            this.$emit('setUrl', data.url);
            this.$bvModal.show('checkout-modal');
        }
        if ( data && data.redirect_url ) {
            await store.dispatch('auth/fetchUser');
        }
        return data && !!data.redirect_url
    }

    get isMultiplePlan() {
        return this.data?.is_multiple_plan || false;
    }

    get isActive() {
        return this.data?.is_active || false;
    }

    get items() {
        return this.data?.campaign_items;
    }

    get isCreditCardRequired() {
        return this.data?.is_credit_card_required || false;
    }

    get isFreePlan() {
        return !this.isCreditCardRequired && this.items?.length === 1 && this.items[0]?.chargebee_item_price?.price === 0;
    }

    get canCheckout() {
        const activeSubscription = store.getters['auth/GET_SUBSCRIPTION'];
        const trial = store.getters['auth/GET_TRIAL'];
        const subscriptionIsActive = (!trial || trial && trial.is_paid) && activeSubscription && activeSubscription.status === 'active';
        return !['requested', 'succeeded'].includes(this.state) && this.isActive && !subscriptionIsActive;
    }
}