var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" } },
    [
      _c("path", {
        attrs: {
          fill: "#21468B",
          d: "M18.477 13H1.523c1.237 3.493 4.56 6 8.477 6s7.24-2.507 8.477-6z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M19 10c0-1.054-.19-2.06-.523-3H1.523C1.19 7.94 1 8.946 1 10s.19 2.06.523 3h16.954c.333-.94.523-1.946.523-3z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#AE1C28",
          d:
            "M10 1C6.083 1 2.76 3.507 1.523 7h16.954C17.24 3.507 13.917 1 10 1z"
        }
      }),
      _c("g", { attrs: { fill: "none" } }, [
        _c("circle", { attrs: { cx: "10", cy: "10", r: "9" } }),
        _c("circle", {
          attrs: { cx: "10", cy: "10", r: "9.5", stroke: "#E4E4E4" }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }