<template>
  <svg class="icon-rewind" xmlns="http://www.w3.org/2000/svg" width="17.088" height="13.302" viewBox="0 0 17.088 13.302">
    <path d="M4.795 4.176a.729.729 0 01-.034-.167v-.017a.637.637 0 01.021-.094.749.749 0 01.042-.19c0-.009.012-.015.016-.024a.737.737 0 01.149-.212v-.005L8.397.208a.75.75 0 111.036 1.085l-2.06 1.97a10.262 10.262 0 016.838 2.4 8.926 8.926 0 012.87 6.886.75.75 0 01-1.5 0 7.417 7.417 0 00-2.368-5.766 8.811 8.811 0 00-5.945-2.028h-.002l2.047 2.141a.75.75 0 01-1.084 1.037L4.97 4.525c-.022-.024-.032-.056-.051-.081a.756.756 0 01-.1-.161.794.794 0 01-.024-.107z" fill="#939393"/>
    <path d="M.228 3.467L3.636.208a.75.75 0 011.037 1.085l-2.866 2.74L4.548 6.9a.75.75 0 01-1.085 1.037L.204 4.529a.75.75 0 01.024-1.061z" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconRewind'
  }
</script>
