var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.662",
        height: "15.217",
        viewBox: "0 0 16.662 15.217"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Path_9253",
          "data-name": "Path 9253",
          d:
            "M496.235,603.244a7.4,7.4,0,0,0-1.479-.146,7.609,7.609,0,0,0-6.932,4.506l-.693-1.386a.75.75,0,1,0-1.342.671l1.54,3.078a.748.748,0,0,0,1.006.336l3.079-1.54a.75.75,0,0,0-.671-1.341l-1.521.761a6.1,6.1,0,1,1,5.548,8.632,6.239,6.239,0,0,1-1.191-.116,6.106,6.106,0,0,1-3.706-2.334.75.75,0,0,0-1.2.9,7.608,7.608,0,0,0,4.62,2.907,7.7,7.7,0,0,0,1.479.144,7.61,7.61,0,0,0,7.457-6.135,7.722,7.722,0,0,0,.145-1.48A7.609,7.609,0,0,0,496.235,603.244Z",
          transform: "translate(-485.71 -603.098)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }