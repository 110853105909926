<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="6.159" height="10.818" class="icon-arrow-left-sm" viewBox="0 0 6.159 10.818">
    <g transform="translate(0 10.818) rotate(-90)">
      <path id="Path_662" data-name="Path 662" d="M341.6,392.877l-4.659-4.658a.75.75,0,0,0-1.061,0l-4.658,4.658a.751.751,0,1,0,1.062,1.061l4.127-4.127,4.128,4.127a.75.75,0,1,0,1.061-1.061Z" transform="translate(-330.999 -387.999)" fill="rgba(0,0,0,0.9)"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'IconArrowLeftSm',
  };
</script>
