var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-thumbs-up",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "15.621",
        height: "14.984",
        viewBox: "0 0 15.621 14.984"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M13.159 5.352h-2.848V3.078A3.069 3.069 0 008.472.266L8.123.114A1.329 1.329 0 007.585 0a1.343 1.343 0 00-1.318 1.089l-.158.813a5.307 5.307 0 01-1.187 2.445L3.33 6.194a.744.744 0 00-.182.489v7.551a.749.749 0 00.75.75h7.857a3.087 3.087 0 003.034-2.516l.79-4.2a2.425 2.425 0 00.042-.46 2.462 2.462 0 00-2.462-2.456zm.945 2.642l-.789 4.2a1.588 1.588 0 01-1.56 1.294H4.648V6.966l1.41-1.636a6.8 6.8 0 001.523-3.137l.121-.622.169.074a1.568 1.568 0 01.94 1.437v3.024a.75.75 0 00.75.75h3.6a.966.966 0 01.963.958zM.75 6.482a.75.75 0 00-.75.75v7a.75.75 0 001.5 0v-7a.75.75 0 00-.75-.75z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }