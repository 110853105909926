<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.761" height="17.65" viewBox="0 0 17.761 17.65">
    <g id="Custom_Calendar_Icon" data-name="Custom Calendar Icon" transform="translate(0.5 0.5)">
      <path id="Custom_Calendar_Icon-2" data-name="Custom Calendar Icon" d="M11738.458,24092.65h-.006a4.255,4.255,0,0,1-4.257-4.256v-6.209a4.256,4.256,0,0,1,4.263-4.258h.375v-1.178a.75.75,0,1,1,1.5,0v1.178h4.493v-1.178a.75.75,0,1,1,1.5,0v1.178h.375a4.255,4.255,0,0,1,4.257,4.258v6.209a4.255,4.255,0,0,1-4.257,4.256Zm-2.764-10.465v6.209a2.762,2.762,0,0,0,2.764,2.758h8.241a2.757,2.757,0,0,0,2.758-2.758v-6.209a2.757,2.757,0,0,0-2.758-2.758h-.375v1.174a.75.75,0,1,1-1.5,0v-1.174h-4.493v1.174a.75.75,0,1,1-1.5,0v-1.174h-.375A2.762,2.762,0,0,0,11735.694,24082.186Zm3.889,6.826a.75.75,0,0,1-.75-.75v-.006a.74.74,0,0,1,.75-.744h6.016a.75.75,0,0,1,0,1.5Zm1.336-2.6a.753.753,0,0,1,0-1.059l3.259-3.254a.744.744,0,0,1,1.059,0,.753.753,0,0,1,0,1.059l-3.253,3.254a.752.752,0,0,1-1.064,0Z" transform="translate(-11734.195 -24076)" fill="#939393" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconCustomDates'
  }
</script>
