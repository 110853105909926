<template>
  <svg class="icon-desktop" xmlns="http://www.w3.org/2000/svg" width="20" height="19.998" viewBox="0 0 20 19.998">
    <path d="M19.25,18.5H.75a.75.75,0,0,0,0,1.5h18.5a.75.75,0,0,0,0-1.5" fill="currentColor"/>
    <path d="M10,13.8a2.326,2.326,0,1,0-2.326-2.326A2.325,2.325,0,0,0,10,13.8m0-3.152a.826.826,0,1,1-.826.826A.829.829,0,0,1,10,10.649" fill="currentColor"/>
    <path d="M3,16.5H17a3,3,0,0,0,3-3V3a3,3,0,0,0-3-3H3A3,3,0,0,0,0,3V13.5a3,3,0,0,0,3,3M1.5,3A1.5,1.5,0,0,1,3,1.5H17A1.5,1.5,0,0,1,18.5,3V13.5A1.5,1.5,0,0,1,17,15H3a1.5,1.5,0,0,1-1.5-1.5Z" fill="currentColor"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-desktop'
  }
</script>
