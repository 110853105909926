var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "14.751",
        height: "17.027",
        viewBox: "0 0 14.751 17.027"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M359.772,243.652h-2.935v-.026a3.3,3.3,0,0,0-3.3-3.3h-.785a3.3,3.3,0,0,0-3.3,3.3v.027h-2.926a.75.75,0,0,0,0,1.5h.529v8.9l0,.01a3.3,3.3,0,0,0,3.3,3.292h5.589a3.3,3.3,0,0,0,3.3-3.3v-8.9h.528a.75.75,0,0,0,0-1.5Zm-8.825-.026a1.8,1.8,0,0,1,1.8-1.8h.785a1.8,1.8,0,0,1,1.8,1.8v.026h-4.391Zm4.995,12.225h-5.589a1.806,1.806,0,0,1-1.8-1.8v-8.866h9.195v8.866A1.805,1.805,0,0,1,355.942,255.851Z",
          transform: "translate(-345.771 -240.324)",
          fill: "#293ad3"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M351.429,247.678a.75.75,0,0,0-.75.75v4.179a.75.75,0,1,0,1.5,0v-4.179A.75.75,0,0,0,351.429,247.678Z",
          transform: "translate(-345.771 -240.324)",
          fill: "#293ad3"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M354.868,247.678a.75.75,0,0,0-.75.75v4.179a.75.75,0,1,0,1.5,0v-4.179A.75.75,0,0,0,354.868,247.678Z",
          transform: "translate(-345.771 -240.324)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }