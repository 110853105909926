var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 20 20"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(1 1)" } }, [
        _c("path", {
          attrs: {
            d: "M773,344a9,9,0,1,1-9-9,9,9,0,0,1,9,9Zm0,0",
            transform: "translate(-755 -335)",
            fill: "#f0f0f0"
          }
        }),
        _c("path", {
          attrs: {
            d: "M950.9,488.723h9.391a9.006,9.006,0,0,0-.31-2.348H950.9Zm0,0",
            transform: "translate(-942.286 -479.723)",
            fill: "#d80027"
          }
        }),
        _c("path", {
          attrs: {
            d: "M950.9,381.871h8.07a9.046,9.046,0,0,0-2.077-2.348H950.9Zm0,0",
            transform: "translate(-942.286 -377.566)",
            fill: "#d80027"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M837.918,702.035a8.961,8.961,0,0,0,5.6-1.957h-11.2a8.961,8.961,0,0,0,5.6,1.957Zm0,0",
            transform: "translate(-828.918 -684.035)",
            fill: "#d80027"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M763.066,595.575h15.358a8.943,8.943,0,0,0,1.011-2.348h-17.38a8.947,8.947,0,0,0,1.011,2.348Zm0,0",
            transform: "translate(-761.745 -581.879)",
            fill: "#d80027"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M759.169,336.405h.82l-.763.554.291.9-.763-.554-.763.554.252-.775a9.049,9.049,0,0,0-1.745,1.945h.263l-.486.353q-.114.19-.218.385l.232.714-.433-.314q-.161.342-.294.7l.256.786h.943L756,342.2l.291.9-.763-.554-.457.332A9.1,9.1,0,0,0,755,344h9v-9a8.957,8.957,0,0,0-4.831,1.405Zm.349,6.695-.763-.554-.763.554.292-.9-.763-.554h.943l.291-.9.292.9h.943l-.763.554Zm-.291-3.518.291.9-.763-.554-.763.554.292-.9-.763-.554h.943l.291-.9.292.9h.943Zm3.52,3.518-.763-.554-.763.554.292-.9-.763-.554h.943l.291-.9.292.9h.943l-.763.554Zm-.291-3.518.291.9-.763-.554-.763.554.292-.9-.763-.554h.943l.291-.9.292.9h.943Zm0-2.622.291.9-.763-.554-.763.554.292-.9-.763-.554h.943l.291-.9.292.9h.943Zm0,0",
            transform: "translate(-755 -335)",
            fill: "#0052b4"
          }
        }),
        _c(
          "g",
          { attrs: { fill: "none", stroke: "#e4e4e4", "stroke-width": "1" } },
          [
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9", stroke: "none" }
            }),
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9.5", fill: "none" }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }