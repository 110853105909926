<template>
  <div class="card-segment" @mouseenter="cardHovered = true" @mouseleave="cardHovered = false" :class="{hovered: isHovered, disabled: item.status !== 'done', failed: item.status == 'failed'}" v-if="item && item.id">
    <div class="slot-top">
      <h3 class="txt-heading-small">{{ item.title }}</h3>

      <slot name="top">
        <div class="slot-top-cta">
          <b-btn v-if="item.status === 'done'" variant="icon primary" size="sm" @click="download">
            <svg class="icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-download"></use></svg>
          </b-btn>
          <svg :id="'failed-indicator-' + item.hash" v-else-if="item.status == 'failed'" class="icon-sm mx-2 my-2" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-warning-triangle-color"></use></svg>
          <b-btn :id="`card-export-dd-${item.id}`" variant="icon primary ml-1" size="sm" @click.stop="">
            <svg viewBox="0 0 24 24" class="icon-sm"><use xlink:href="#icon-more"></use></svg>
          </b-btn>
          <b-popover ref="dd" placement="leftbottom" :target="`card-export-dd-${item.id}`" triggers="click blur" >
            <div class="dropdown-cont sm">
              <b-dropdown-item v-if="item.status === 'failed'" @click.stop.prevent="retry" :id="'failed-btn-' + item.hash">
                <svg class="btn-left icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-reset"></use></svg>
                Retry
              </b-dropdown-item>
              <b-dropdown-item @click.stop.prevent="rename">
                <svg class="btn-left icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-rename"></use></svg>
                Rename
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item variant="warning" @click.stop.prevent="destroy">
                <svg class="btn-left icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-trash"></use></svg>
                Delete
              </b-dropdown-item>
            </div>
          </b-popover>
          <b-tooltip :target="'download-indicator-' + item.hash" :show="cardHovered && !isHovered && item.status !== 'failed'" noninteractive>{{item.status == 'done' ? 'Download' : 'Loading...'}}</b-tooltip>
          <b-tooltip v-if="item.status == 'failed'" :target="'failed-btn-' + item.hash" noninteractive>Retry export</b-tooltip>
          <b-tooltip v-if="item.status == 'failed'" :show="cardHovered && !isHovered && item.status == 'failed'" :target="'failed-indicator-' + item.hash" noninteractive>Export failed</b-tooltip>
        </div>
      </slot>
    </div>
    <div class="slot-bottom">
      <div class="card-segment-avatars">
        <b-avatar-group v-if="item.audience_count" class="mr-1" size="32px" rounded>
          <b-avatar v-b-tooltip="audience.name" style="min-width: 32px" v-for="audience in item.top_audiences.slice(0,5)" :key="audience.hash" :src="audience | avatar"></b-avatar>
        </b-avatar-group>
        <span class="txt-body" v-if="item.audience_count && getOtherPeopleCount">{{'+' + (getOtherPeopleCount) + ' others'}}</span>
        <span class="txt-body" v-if="!item.audience_count">0 people</span>
      </div>
      <span class="txt-body-small txt-muted-2 ml-auto">{{ item.created_at | date }}</span>
    </div>
  </div>
</template>
<script>
  import axios from '@app2/api/axios';
  import eventBus from "@app2/core/eventBus";
  import {set} from "@app2/utils/helpers";
  import rootVue from "@app2/app";

  export default {
    name: 'HubsItem',
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        isHovered: false,
        cardHovered: false
      }
    },
    computed: {
      getOtherPeopleCount() {
        let count = this.item.audience_count - 5

        if(count > 0) {
          return count
        } else {
          return 0
        }
      }
    },
    methods: {
      async destroy() {
        eventBus.$emit('set-export-item', this.item);
        setTimeout(() => {
          rootVue.$bvModal.show('export-delete-dialog')
        }, 200)
      },
      rename() {
        eventBus.$emit('set-export-item', this.item);
        setTimeout(() => {
          rootVue.$bvModal.show('export-rename-dialog')
        }, 200)
      },
      async download() {
        if ( this.item.status !== 'done' ) return;
        const resp = await axios.get(`/api/audience/exports/${this.item.hash}/download`);
        location = resp.data;
      },
      async retry() {
        if ( this.item.status !== 'failed' ) return;
        const resp = await axios.post(`/api/audience/exports/${this.item.hash}/retry`);
        this.item.status = resp.data.status;
      }
    },
    mounted() {
      eventBus.$on('rename-export-success', payload => {
        if ( payload.id === this.item.id ) {
          set(this.item, 'title', payload.title);
        }
      });
    }
  }
</script>
