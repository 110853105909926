var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" } },
    [
      _c("path", {
        attrs: {
          fill: "#E30A17",
          d:
            "M19 10c0-4.971-4.029-9-9-9s-9 4.029-9 9 4.029 9 9 9 9-4.029 9-9zm-7.34.879l-1.033-.336-.639.879v-1.086l-.285-.093a2.371 2.371 0 01-.589 1.343 2.98 2.98 0 01-2.526 1.404 2.99 2.99 0 110-5.98 2.98 2.98 0 012.526 1.404c.326.365.537.829.589 1.343l.285-.093V8.578l.639.879 1.033-.336-.638.879.638.879z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M7.336 12.392a2.392 2.392 0 010-4.784c.71 0 1.341.315 1.779.806A2.983 2.983 0 006.588 7.01a2.99 2.99 0 100 5.98 2.98 2.98 0 002.526-1.404 2.376 2.376 0 01-1.778.806z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#E30A17",
          d:
            "M8.955 10l.748-.243a2.365 2.365 0 00-.589-1.343 2.376 2.376 0 00-1.778-.806 2.392 2.392 0 000 4.784c.71 0 1.341-.315 1.779-.806.326-.365.536-.829.589-1.343L8.955 10z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M10.627 9.457l-.639-.879v1.086l-.285.093-.748.243.748.243.285.093v1.086l.639-.879 1.033.336-.638-.879.638-.879z"
        }
      }),
      _c("g", { attrs: { fill: "none" } }, [
        _c("circle", { attrs: { cx: "10", cy: "10", r: "9" } }),
        _c("circle", {
          attrs: { cx: "10", cy: "10", r: "9.5", stroke: "#E4E4E4" }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }