<template>
  <b-modal id="domain-settings-delete-modal" centered ref="modal" size="sm">
    <template slot="modal-header" slot-scope="{ close }">
      <h5 class="txt-body txt-primary">Unpublish Domain?</h5>
      <b-button variant="icon primary" size="sm" @click="close()" class="close">
        <svg width="24" viewBox="0 0 24 24"><use xlink:href="#icon-cross"></use></svg>
      </b-button>
    </template>
    <div class="txt-heading-small txt-break-word">Are you sure you want to unpublish <strong>all your content</strong> from"{{ domain }}"?</div>
    <template slot="modal-footer">
      <b-btn @click="$refs.modal.hide()" variant="secondary">
        Cancel
      </b-btn>
      <b-btn
        variant="primary"
        @click="unpublish"
      >
        Unpublish
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
  export default {
    name: 'DeleteDomainModal',
    props: {
      domain: String
    },
    methods: {
      unpublish() {
        this.$emit('unpublish')
        this.$refs.modal.hide()
      }
    }
  }
</script>