import Base from '@app2/models/Base';

export default class WisdomQuery extends Base {
  static entity = 'App\\WisdomQuery';
  static primaryKey = 'hash';

  static apiConfig = {
    baseURL: `/`,
    actions: {
      fetch(url, config) {
        return this.get(`${url}`, {
          dataKey: 'data',
          ...config,
        })
      },
    }
  };

  static fields() {
    return {
      id: this.attr(null),
      hash: this.attr(null),
      prompt: this.attr(null),
      is_default_prompt: this.attr(null),
      response: this.attr(null),
      session_id: this.attr(null),
      session: this.attr({}),
      extra_attributes: this.attr([]),
      context_files: this.attr([]),
      ...super.fields(),
    };
  }

  static mutators() {
    return {
      
    };
  }
}
