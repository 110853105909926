var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "onboarding-language" }, [
    _c(
      "form",
      { attrs: { role: "form" } },
      [
        _c("label", { staticClass: "form-control-label txt-body" }, [
          _vm._v("Which language should we use when your video is transcribed?")
        ]),
        _vm._v(" "),
        _c("form-control-select", {
          staticClass: "mb-3",
          attrs: {
            id: "language",
            full: "",
            "option-icon": "icon",
            "option-custom-icon": "",
            "input-icon": _vm.vocabularyIcon,
            "track-by": "code",
            "select-label": "name",
            placeholder: "Choose Language",
            options: _vm.options,
            required: ""
          },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        }),
        _vm._v(" "),
        _c("b-alert", { attrs: { show: "", fade: "", variant: "info mb-3" } }, [
          _c(
            "svg",
            {
              staticClass: "alert-icon icon-sm",
              attrs: { viewBox: "0 0 24 24" }
            },
            [
              _c("use", {
                attrs: { "xlink:href": "#icon-status-question-color" }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "alert-content" }, [
            _c("p", { staticClass: "txt-body mb-3" }, [
              _vm._v(
                "Choose a default language for your newly uploaded files to be transcribed in. "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "txt-body" }, [
              _c("b", [_vm._v("Note:")]),
              _vm._v(
                " you can change the language again anytime from your account settings."
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "b-alert",
          {
            staticClass: "mb-3",
            attrs: { show: _vm.form.busy, fade: "", variant: "info" }
          },
          [
            _c(
              "svg",
              {
                staticClass: "alert-icon icon-sm",
                attrs: { viewBox: "0 0 24 24" }
              },
              [
                _c("use", {
                  attrs: { "xlink:href": "#icon-status-question-color" }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "alert-content" }, [
              _c("p", { staticClass: "txt-body" }, [
                _vm._v("Language loading...")
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "b-alert",
          {
            staticClass: "mb-3",
            attrs: {
              show: !_vm.form.busy && _vm.form.successful,
              fade: "",
              variant: "success"
            }
          },
          [
            _c(
              "svg",
              {
                staticClass: "alert-icon icon-sm",
                attrs: { viewBox: "0 0 24 24" }
              },
              [
                _c("use", {
                  attrs: { "xlink:href": "#icon-status-success-color" }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "alert-content" }, [
              _c("p", { staticClass: "txt-body" }, [
                _vm._v("Transcription language updated!")
              ])
            ])
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "onboarding-cta pt-2 mb-4" },
      [_vm._t("back"), _vm._v(" "), _vm._t("finish")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }