import { Mark } from 'prosemirror-to-html-js';
import get from "lodash/get";
export default class Highlight extends Mark {
  matching () {
    return this.mark.type === "highlight";
  }

  tag () {
    return [
      {
        tag: "span",
        attrs: {
          style: this.getStyles()
        }
      }
    ]
  }

  getStyles() {
    let styles = '';
    if ( get(this.mark, 'attrs.color') ) {
      styles += `background-color: ${get(this.mark, 'attrs.color')};`
    }
    return styles;
  }
}
