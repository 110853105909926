var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-export",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18",
        height: "16",
        viewBox: "0 0 17.965 15.946"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M17.215 4.264a.75.75 0 00-.75.75v7.9a1.535 1.535 0 01-1.533 1.532h-11.9A1.535 1.535 0 011.5 12.914v-7.9a.75.75 0 00-1.5 0v7.9a3.033 3.033 0 003.032 3.032h11.9a3.033 3.033 0 003.033-3.032v-7.9a.75.75 0 00-.75-.75z",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M5.033 3.638L8.451.219a.752.752 0 011.061 0l3.419 3.419a.75.75 0 01-1.06 1.061L9.732 2.561v7.953a.75.75 0 11-1.5 0V2.561L6.093 4.699a.75.75 0 11-1.06-1.061z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }