var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-forward",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.086",
        height: "13.302",
        viewBox: "0 0 17.086 13.302"
      }
    },
    [
      _c("g", { attrs: { fill: "#939393" } }, [
        _c("path", {
          attrs: {
            d:
              "M12.292 4.176a.729.729 0 00.034-.167v-.017a.637.637 0 00-.021-.094.749.749 0 00-.042-.19c0-.009-.012-.015-.016-.024a.737.737 0 00-.149-.212v-.005L8.69.208a.75.75 0 10-1.036 1.085l2.06 1.97a10.262 10.262 0 00-6.838 2.4 8.926 8.926 0 00-2.87 6.886.75.75 0 001.5 0 7.417 7.417 0 012.368-5.766 8.81 8.81 0 015.945-2.028h.008L7.78 6.896a.75.75 0 001.084 1.037l3.259-3.408c.022-.024.032-.056.051-.081a.756.756 0 00.1-.161.794.794 0 00.018-.107z"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M16.854 3.467L13.446.208a.75.75 0 00-1.037 1.085l2.866 2.74L12.534 6.9a.75.75 0 001.085 1.037l3.259-3.408a.75.75 0 00-.024-1.061z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }