const namespaced = true;

const state = {
  fileFilter: [],
  widgetFilter: [],
  playlistFilter: [],
  hubFilter: [],
  folderFilter: [],
  keywordFilter: [],
  browserFilter: [],
  systemFilter: [],
  deviceFilter: [],
  controllerName: 'media',
  controllerIcon: 'files',
  filterSelectedItems: [],
  keywordOptions: [],
  browserOptions: [],
  systemOptions: [],
  deviceOptions: [],
  filterModule: null,
  dateRangeFilter: [],
  dateFilter: []
};

const mutations = {
  SET_FILE_FILTER(state, file) {
    state.fileFilter.push(file)
  },
  SET_WIDGET_FILTER(state, widget) {
    state.widgetFilter.push(widget)
  },
  SET_PLAYLIST_FILTER(state, playlist) {
    state.playlistFilter.push(playlist)
  },
  SET_HUB_FILTER(state, hub) {
    state.hubFilter.push(hub)
  },
  SET_FOLDER_FILTER(state, folder) {
    state.folderFilter.push(folder)
  },
  SET_BROWSER_FILTER(state, browser) {
    state.browserFilter.push(browser)
  },
  SET_KEYWORD_FILTER(state, keyword) {
    state.keywordFilter.push(keyword)
  },
  SET_SYSTEM_FILTER(state, system) {
    state.systemFilter.push(system)
  },
  SET_DEVICE_FILTER(state, device) {
    state.deviceFilter.push(device)
  },
  SET_CONTROLLER_NAME(state, name) {
    // sets the icon for the item controller beside datepicker (right)
    switch(name) {
      case 'media':
        state.controllerIcon = 'files'
        break;
      case 'folders':
        state.controllerIcon = 'folderlg'
        break;
      case 'widgets':
        state.controllerIcon = 'widget'
        break;
      case 'playlists':
        state.controllerIcon = 'playlists'
        break;
      case 'hubs':
        state.controllerIcon = 'hubs'
        break;
      case 'keywords':
        state.controllerIcon = 'keywords'
        break;
      case 'system':
        state.controllerIcon = 'os'
        break;
      case 'devices':
        state.controllerIcon = 'devices'
        break;
      case 'name':
        state.controllerIcon = 'keywords'
        break;
      case 'email':
        state.controllerIcon = 'email'
        break;
      case 'created_after':
        state.controllerIcon = 'calendar-big'
        break;
      case 'created_before':
        state.controllerIcon = 'calendar-big'
        break;
      case 'created_between':
        state.controllerIcon = 'calendar-big'
        break;
    }
    state.controllerName = name
  },
  SET_DATERANGE_FILTER(state, date) {
    state.dateRangeFilter = date
  },
  SET_DATE_FILTER(state, date) {
    state.dateFilter = date
  },
  SET_FILTER_ITEM_SELECTED(state, payload) {
    let item = {}
    // icon prop here indicates the icon for selected item from dynamic selection beside datepicker (left-side)
    switch(state.controllerName) {
      case 'folders':
        item = {
          id: payload.$id,
          name: payload.title,
          icon: 'icon-local',
          model: state.controllerName
        }
        break;
      case 'widgets':
        item = {
          id: payload.$id,
          name: payload.name,
          icon: 'icon-tags',
          model: state.controllerName
        }
        break;
      case 'playlists':
        item = {
          id: payload.id,
          name: payload.title,
          icon: 'icon-playlists',
          model: state.controllerName
        }
        break;
      case 'hubs':
        item = {
          id: payload.id,
          name: payload.title,
          icon: 'icon-hubs',
          model: state.controllerName
        }
        break;
      case 'media':
        item = {
          id: payload.id,
          name: payload.title,
          icon: 'icon-files',
          model: state.controllerName
        }
        break;
      case 'files':
        item = {
          id: payload.id,
          name: payload.title,
          icon: 'icon-files',
          model: state.controllerName
        }
        break;
      case 'keywords':
        item = {
          name: payload.keyword,
          icon: 'icon-keywords',
          model: state.controllerName
        }
        break;
      case 'system':
        item = {
          name: payload.keyword,
          icon: 'icon-os',
          model: state.controllerName
        }
        break;
      case 'browsers':
        item = {
          name: payload.keyword,
          icon: 'icon-browser',
          model: state.controllerName
        }
        break;
      case 'devices':
        item = {
          name: payload.keyword,
          icon: 'icon-devices',
          model: state.controllerName
        }
        break;
    }
    state.filterSelectedItems.push(item)
  },
  REMOVE_FILTER_ITEM_SELECTED(state, item) {
    state.filterSelectedItems.splice(item.index, 1)
    let filterIndex = null

    switch(item.model) {
      case 'folders':
        filterIndex = state.folderFilter.findIndex(f => f.title === item.name)
        state.folderFilter.splice(filterIndex, 1)
        break;
      case 'widgets':
        filterIndex = state.widgetFilter.findIndex(w => w.name === item.name)
        state.widgetFilter.splice(filterIndex, 1)
        break;
      case 'playlists':
        filterIndex = state.playlistFilter.findIndex(w => w.title === item.name)
        state.playlistFilter.splice(filterIndex, 1)
        break;
      case 'hubs':
        filterIndex = state.hubFilter.findIndex(h => h.title === item.name)
        state.hubFilter.splice(filterIndex, 1)
        break;
      case 'files':
        filterIndex = state.fileFilter.findIndex(f => f.title === item.name)
        state.fileFilter.splice(filterIndex, 1)
        break;
      case 'keywords':
        filterIndex = state.keywordFilter.findIndex(f => f === item.keyword)
        state.keywordFilter.splice(filterIndex, 1)
        break;
      case 'system':
        filterIndex = state.systemFilter.findIndex(f => f === item.keyword)
        state.systemFilter.splice(filterIndex, 1)
        break;
      case 'browsers':
        filterIndex = state.browserFilter.findIndex(b => b === item.keyword)
        state.browserFilter.splice(filterIndex, 1)
        break;
      case 'devices':
        filterIndex = state.deviceFilter.findIndex(b => b === item.keyword)
        state.deviceFilter.splice(filterIndex, 1)
        break;
    }
  },
  SET_KEYWORDS(state, payload) {
    state.keywordOptions = payload
  },
  SET_BROWSERS(state, payload) {
    state.browserOptions = payload
  },
  SET_SYSTEMS(state, payload) {
    state.systemOptions = payload
  },
  SET_DEVICES(state, payload) {
    state.deviceOptions = payload
  },
  SET_FILTER_MODULE(state, module) {
    state.filterModule = module
  },
  CLEAR_ALL_FILTERS(state) {
    state.folderFilter = []
    state.widgetFilter = []
    state.fileFilter = []
    state.playlistFilter = []
    state.keywordFilter = []
    state.systemFilter = []
    state.browserFilter = []
    state.filterSelectedItems = []
  }
}


const actions = {
  setInterval({commit}, interval) {
    commit('SET_INTERVAL', interval)
  },
  removeFilterItemSelected({commit}, index) {
    commit('REMOVE_FILTER_ITEM_SELECTED', index)
  },
  async getFilters({commit}) {
    function getKeyword() {
      return axios.get('/api/analytics/keyword')
    }
    function getBrowser() {
      return axios.get('/api/analytics/browser')
    }
    function getSystem() {
      return axios.get('/api/analytics/os')
    }
    function getDevice() {
      return axios.get('/api/analytics/device')
    }
    Promise.all([
      getKeyword(),
      getBrowser(),
      getSystem(),
      getDevice(),
    ]).then((res) => {
      // check if keyword is empty and take it out from the array
      let keywords = res[0].data.filter(k => k.keyword != "")
      commit('SET_KEYWORDS', keywords)
      commit('SET_BROWSERS', res[1].data)
      commit('SET_SYSTEMS', res[2].data)
      commit('SET_DEVICES', res[3].data)
    })
  },
  setFilterModule({commit}, module) {
    commit('SET_FILTER_MODULE', module)
  }
}

const getters = {
  controllerName: state => {
    return state.controllerName
  },
  controllerIcon: state => {
    return state.controllerIcon
  },
  fileFilters: state => {
    return state.fileFilter
  },
  widgetFilters: state => {
    return state.widgetFilter
  },
  playlistFilters: state => {
    return state.playlistFilter
  },
  folderFilters: state => {
    return state.folderFilter
  },
  keywordFilters: state => {
    return state.keywordFilter
  },
  hubFilters: state => {
    return state.hubFilter
  },
  systemFilters: state => {
    return state.systemFilter
  },
  deviceFilters: state => {
    return state.deviceFilter
  },
  browserFilters: state => {
    return state.browserFilter
  },
  filterSelectedItems: state => {
    return state.filterSelectedItems
  },
  keywordOptions: state => {
    return state.keywordOptions
  },
  browserOptions: state => {
    return state.browserOptions
  },
  systemOptions: state => {
    return state.systemOptions
  },
  deviceOptions: state => {
    return state.deviceOptions
  },
  filterModule: state => {
    return state.filterModule
  }
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
