var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-text-striketrough",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "11.984",
        height: "12",
        viewBox: "0 0 11.984 12"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M11.984,5.25H7.228c-.163-.053-.319-.107-.5-.157A9.737,9.737,0,0,1,4.484,4.28a1.223,1.223,0,0,1-.509-1.11q0-1.509,2.153-1.509.983,0,3.356.272L9.636.407l-.593-.1A18.556,18.556,0,0,0,5.959,0a4.487,4.487,0,0,0-2.84.806A2.844,2.844,0,0,0,2.093,3.187,3.164,3.164,0,0,0,2.7,5.25H0v1.5H5.806A6.074,6.074,0,0,1,7.518,7.4a1.247,1.247,0,0,1,.474,1.077q0,1.865-2.1,1.864a28.679,28.679,0,0,1-3.491-.3l-.187,1.508.61.119A16.837,16.837,0,0,0,6.009,12a4.18,4.18,0,0,0,2.839-.906A3.437,3.437,0,0,0,9.89,8.356,3.357,3.357,0,0,0,9.546,6.75h2.438Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }