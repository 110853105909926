<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14.489" height="14.484" viewBox="0 0 14.489 14.484">
    <g id="Group_1930" data-name="Group 1930" transform="translate(-282.01 -373)">
      <path id="Path_625" data-name="Path 625" d="M291.233,380.5A2.232,2.232,0,1,1,289,378.267a.749.749,0,1,0,0-1.5,3.73,3.73,0,1,0,3.73,3.73.749.749,0,0,0-1.5,0Z" fill="#919ae8"/>
      <g id="Group_1772" data-name="Group 1772">
        <path id="Path_626" data-name="Path 626" d="M294.587,377.581a.75.75,0,0,0-.437.966h0a5.5,5.5,0,1,1-3.2-3.2.75.75,0,1,0,.53-1.4,7,7,0,1,0,4.077,4.077A.751.751,0,0,0,294.587,377.581Z" fill="#293ad3"/>
        <path id="Path_627" data-name="Path 627" d="M295.75,375.459h-1.711V373.75a.749.749,0,1,0-1.5,0V375.9l-4.147,4.146a.75.75,0,1,0,1.061,1.061l4.148-4.148h2.148a.749.749,0,0,0,0-1.5Z" fill="#293ad3"/>
      </g>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'IconTarget',
  };
</script>
