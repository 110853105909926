<template>
  <svg class="icon-delete-lg" xmlns="http://www.w3.org/2000/svg" width="16.385" height="19" viewBox="0 0 16.385 18.984">
    <path d="M760.572,1267.659h-3.409v-.128a3.637,3.637,0,0,0-3.637-3.637h-.8a3.633,3.633,0,0,0-3.633,3.634v.131h-3.409a.75.75,0,0,0,0,1.5h.7v10.087a3.631,3.631,0,0,0,3.629,3.632h6.232a3.634,3.634,0,0,0,3.634-3.632v-10.087h.7a.75.75,0,0,0,0-1.5Zm-9.977-.131a2.137,2.137,0,0,1,2.134-2.134h.8a2.14,2.14,0,0,1,2.137,2.137v.128h-5.068Zm5.648,13.851h-6.231a2.132,2.132,0,0,1-2.13-2.133v-10.053h10.495v10.053A2.137,2.137,0,0,1,756.243,1281.379Z" transform="translate(-744.937 -1263.894)" fill="#939393"/><path d="M751.23,1272.206a.75.75,0,0,0-.75.75v4.661a.75.75,0,0,0,1.5,0v-4.661A.75.75,0,0,0,751.23,1272.206Z" transform="translate(-744.937 -1263.894)" fill="#939393"/><path d="M755.029,1272.206a.75.75,0,0,0-.75.75v4.661a.75.75,0,0,0,1.5,0v-4.661A.75.75,0,0,0,755.029,1272.206Z" transform="translate(-744.937 -1263.894)" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconDeleteLg'
  }
</script>
