<template>
  <div class="side-panel-link-row">
    <div class="handle px-2" v-if="draggable">
      <svg width="24" viewBox="0 0 24 24"><use xlink:href="#icon-drag-horizontal"></use></svg>
    </div>
    <div v-else-if="$slots.left">
      <slot name="left"></slot>
    </div>
    <p class="txt-heading-small w-100" :class="{'pl-0':draggable}" :contenteditable="editable" ref="link" @input="$emit('input')">{{ model }}</p>
    <div class="fx-row-center">
      <div v-if="$slots.right">
        <slot name="right"></slot>
      </div>
      <b-btn
        :disabled="disabled"
        @click="$emit('delete')"
        :variant="'icon ml-auto mr-2 ' + variant"
        size="sm"
        >
        <svg width="24" height="24" viewBox="0 0 24 24" class="icon-sm txt-red"><use xlink:href="#icon-trash"></use></svg>
      </b-btn>
    </div>
  </div>
</template>
<script>
import { focusContentEditableElement } from "@app2/utils/helpers";
export default {
  name: 'SidePanelLinkRow',
  props: {
    link: {
      type: String,
    },
    value: {
      type: String,
    },
    draggable: Boolean,
    disabled: Boolean,
    editable: Boolean,
  },
  computed: {
    variant() {
      return this.disabled ? 'disabled' : 'warning';
    },
    model: {
      get() {
        if ( this.link ) return this.link;
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    focus() {
      if (this.$refs.link) {
        focusContentEditableElement(this.$refs.link);
      }
    }
  }
}
</script>
