var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19",
        height: "19",
        viewBox: "0 0 19 19"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M14.172,388.6H4.925A4.933,4.933,0,0,0,0,393.526v9.148A4.933,4.933,0,0,0,4.925,407.6h9.147A4.934,4.934,0,0,0,19,402.674v-9.148A4.786,4.786,0,0,0,14.172,388.6Zm0,17.593H4.925a3.389,3.389,0,0,1-3.417-3.418v-9.148a3.389,3.389,0,0,1,3.417-3.418h9.147a3.389,3.389,0,0,1,3.417,3.418v5.228H15.177a3.116,3.116,0,0,0,0,6.233h1.508A3.46,3.46,0,0,1,14.172,406.193Z",
          transform: "translate(0 -388.6)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }