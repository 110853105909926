var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "22.186",
        height: "15.329",
        viewBox: "0 0 22.186 15.329"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(-732.001 -388)" } }, [
        _c("path", {
          attrs: {
            d:
              "M754.187,399.257a5.217,5.217,0,0,0-7.132-4.9,6.219,6.219,0,0,0-12.154,1.868c0,.167.012.334.025.5a3.963,3.963,0,0,0,1.048,7.788h6.366a.749.749,0,1,0,0-1.5h-6.366a2.464,2.464,0,0,1-.163-4.923.75.75,0,0,0,.688-.9,4.815,4.815,0,0,1-.1-.964,4.726,4.726,0,0,1,9.4-.681.75.75,0,0,0,1.143.526,3.755,3.755,0,0,1,5.367,4.822.75.75,0,1,0,1.349.656h0A5.227,5.227,0,0,0,754.187,399.257Z",
            transform: "translate(0 -2)",
            fill: "#fff"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M749.3,401.125l-2.393,2.393-.683-.684a.75.75,0,0,0-1.061,1.061l1.214,1.214a.751.751,0,0,0,1.061,0l2.923-2.923a.75.75,0,1,0-1.061-1.06Z",
            transform: "translate(0 -2)",
            fill: "#fff"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }