<template>
  <svg class="icon-element-headline" xmlns="http://www.w3.org/2000/svg" width="14.238" height="16.058" viewBox="0 0 14.238 16.058">
    <path d="M14.238 3.57V0H0v3.57a.75.75 0 0 0 1.5 0V1.501h4.869V14.56h-1.56a.75.75 0 0 0 0 1.5H9.43a.75.75 0 0 0 0-1.5H7.869V1.501h4.869v2.071a.75.75 0 0 0 1.5 0Z" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-element-headline'
  }
</script>
