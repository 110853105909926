var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "23.444",
        height: "18.203"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            fill: "none",
            stroke: "#939393",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1.5"
          }
        },
        [
          _c("path", {
            staticClass: "no-fill",
            attrs: {
              d:
                "M7.028 4.806H5.045a4.3 4.3 0 000 8.591h1.983l5.413 2.437V2.369z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "no-fill",
            attrs: { d: "M16.087 13.867a6.74 6.74 0 000-9.53" }
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "no-fill",
            attrs: { d: "M19.363 17.142a11.371 11.371 0 000-16.082" }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }