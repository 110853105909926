var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-retrying",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "12.556",
        height: "11.486",
        viewBox: "0 0 12.556 11.486"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M3390.91,552.723a5.741,5.741,0,0,0-4.4-6.725l-.027-.006-.077-.014h0a5.469,5.469,0,0,0-.97-.084,5.6,5.6,0,0,0-5.05,3.233l-.5-1a.75.75,0,1,0-1.342.67l1.416,2.832a.75.75,0,0,0,1.006.335l2.832-1.416a.749.749,0,0,0-.67-1.34l-1.471.735a4.079,4.079,0,0,1,4.488-2.484h0l.054.01a4.252,4.252,0,0,1,3.327,4.143,4.377,4.377,0,0,1-.086.849l0,.026a4.052,4.052,0,0,1-3.992,3.4,4.251,4.251,0,0,1-.658-.053,4.051,4.051,0,0,1-2.639-1.637.75.75,0,0,0-1.219.875,5.549,5.549,0,0,0,3.618,2.243h0a5.862,5.862,0,0,0,.894.07,5.549,5.549,0,0,0,5.465-4.631h0l0-.02,0-.006Z",
          transform: "translate(-3378.464 -545.893)",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }