import Router from '@plugins/VueRouter/index';

const router = new Router({
  mode: 'abstract',
  name: 'audience-export',
  routes: [
    {
      path: '/',
      name: 'ExportIndex',
      component: require('@app2/modules/Audience/Export/Pages/Index').default
    },
    {
      path: '/recent-exports',
      name: 'RecentExports',
      component: require('@app2/modules/Audience/Export/Pages/RecentExports').default
    },
  ]
});

export default router;
