var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { height: "24", width: "24", viewBox: "0 0 24 24" } },
    [
      _c(
        "text",
        {
          attrs: {
            "dominant-baseline": "middle",
            "text-anchor": "middle",
            x: "12",
            y: "13",
            fill: "currentColor"
          }
        },
        [_vm._v(_vm._s(_vm.text))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }