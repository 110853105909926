var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18.345",
        height: "16.739",
        viewBox: "0 0 18.345 16.739"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(-641.162 -455.668)" } }, [
        _c("path", { attrs: { d: "M652.33,455.755h0Z", fill: "#939393" } }),
        _c("path", {
          attrs: {
            d:
              "M659.246,461.971a8.37,8.37,0,0,0-6.913-6.216,8.3,8.3,0,0,0-3.263.174,8.367,8.367,0,0,0-5.129,3.837,8.219,8.219,0,0,0-.508,1.02l-.85-1.7a.75.75,0,1,0-1.341.671h0l1.71,3.424a.751.751,0,0,0,1.006.336l3.425-1.71a.75.75,0,1,0-.67-1.342l-1.909.953a6.828,6.828,0,0,1,7.311-4.177h0a6.875,6.875,0,0,1,5.89,6.789,6.957,6.957,0,0,1-.072.99,6.867,6.867,0,0,1-5.1,5.675,6.915,6.915,0,0,1-1.69.214,7.012,7.012,0,0,1-.987-.072,6.868,6.868,0,0,1-4.519-2.683.75.75,0,1,0-1.2.9,8.37,8.37,0,0,0,5.507,3.27,8.367,8.367,0,0,0,9.476-7.089,8.561,8.561,0,0,0,.087-1.2A8.264,8.264,0,0,0,659.246,461.971Z",
            fill: "#939393"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M651.034,459.573a.75.75,0,0,0-.75.75h0V463.9a.753.753,0,0,0,.219.53l2.756,2.756a.75.75,0,0,0,1.061-1.061l-2.537-2.537v-3.265A.75.75,0,0,0,651.034,459.573Z",
            fill: "#939393"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }