<template>
  <svg width="202" height="104" viewBox="0 0 202 104" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M184.721 15.9316L174.903 15.9316" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M184.721 52L174.903 52" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M103.824 79.25L21.8845 79.25" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M62.8594 15.9316L14.3693 15.9316" stroke="#BBBBBB" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M14.3691 52L85.3674 52" stroke="#BBBBBB" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M165.781 79.25L180.722 79.25" stroke="#BBBBBB" stroke-width="1.5" stroke-linecap="round"/>
    <rect x="1.04688" y="0.931641" width="199.851" height="30" rx="6" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="1.04688" y="37" width="199.851" height="66.0684" rx="6" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="10.2695" y="64.2871" width="181.405" height="29.9273" rx="6" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'FigureImports',
  }
</script>
