var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-key",
      attrs: {
        id: "Key_Icon",
        "data-name": "Key Icon",
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.835",
        height: "16.836",
        viewBox: "0 0 16.835 16.836"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Path_6617",
          "data-name": "Path 6617",
          d:
            "M3250.116,731.944a1.405,1.405,0,1,0,1.987,0A1.4,1.4,0,0,0,3250.116,731.944Z",
          transform: "translate(-3240.063 -727.148)",
          fill: "#979797"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_6618",
          "data-name": "Path 6618",
          d:
            "M3255.2,728.844h0a5.783,5.783,0,0,0-9.877,4.082,5.839,5.839,0,0,0,.248,1.595l-5.291,5.29a.758.758,0,0,0-.219.53v2.893a.749.749,0,0,0,.749.749h2.893a.743.743,0,0,0,.53-.22l1.124-1.124a.744.744,0,0,0,.22-.53v-.9h.889a.749.749,0,0,0,.75-.75v-.889h.9a.744.744,0,0,0,.53-.219l.883-.881a5.849,5.849,0,0,0,1.592.246,5.784,5.784,0,0,0,4.083-9.876Zm-1.06,7.127a4.262,4.262,0,0,1-3.023,1.249,4.326,4.326,0,0,1-1.547-.285.748.748,0,0,0-.8.17l-.971.969h-1.335a.748.748,0,0,0-.75.75v.888h-.889a.749.749,0,0,0-.75.75V741.8l-.685.685h-1.832v-1.832l5.379-5.379a.752.752,0,0,0,.17-.8,4.316,4.316,0,0,1-.287-1.548,4.285,4.285,0,0,1,7.317-3.022l.53-.529-.53.53a4.29,4.29,0,0,1,0,6.066Z",
          transform: "translate(-3240.063 -727.148)",
          fill: "#979797"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }