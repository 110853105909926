var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-section-search",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.236",
        height: "16.903",
        viewBox: "0 0 17.236 16.903"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M16.999 15.606l-3.406-3.2a7.627 7.627 0 00-5.9-12.4H7.609a7.546 7.546 0 00-2.9.585 7.634 7.634 0 107.839 12.892l3.427 3.221a.75.75 0 101.027-1.093zm-9.362-1.834a6.135 6.135 0 01-2.354-11.8 6.069 6.069 0 012.337-.469h.011a6.135 6.135 0 110 12.271z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }