var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-close-lg",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "14.121",
        height: "14.121",
        viewBox: "0 0 14.121 14.121"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            fill: "none",
            stroke: "currentColor",
            "stroke-linecap": "round",
            "stroke-width": "1.5"
          }
        },
        [
          _c("path", {
            attrs: { d: "M13.061 13.061l-12-12M1.061 13.061l12-12" }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }