var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-widget",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20.979",
        height: "17.969",
        viewBox: "0 0 20.979 17.969"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(0 0)" } }, [
        _c("path", {
          attrs: {
            d: "M140.188,154.83a.75.75,0,1,0,0,1.5h9.447a.75.75,0,1,0,0-1.5Z",
            transform: "translate(-135.901 -150.979)",
            fill: "currentColor"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M142.515,165.343h-3.83a1.284,1.284,0,0,1-1.284-1.283v-10.3a1.285,1.285,0,0,1,1.284-1.284h12.453a1.287,1.287,0,0,1,1.284,1.284v1.818a.75.75,0,0,0,1.5,0v-1.818a2.783,2.783,0,0,0-2.784-2.783H138.685a2.783,2.783,0,0,0-2.784,2.783v10.3a2.783,2.783,0,0,0,2.784,2.783h3.83a.749.749,0,1,0,0-1.5Z",
            transform: "translate(-135.901 -150.979)",
            fill: "currentColor"
          }
        }),
        _c("path", {
          attrs: {
            d: "M148.807,157.729h0Z",
            transform: "translate(-135.901 -150.979)",
            fill: "currentColor"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M151.044,157.282a5.75,5.75,0,0,0-2.235.447,5.832,5.832,0,1,0,2.235-.447Zm0,10.167a4.334,4.334,0,0,1-1.665-8.336h0a4.334,4.334,0,1,1,1.663,8.337Z",
            transform: "translate(-135.901 -150.979)",
            fill: "currentColor"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M142.782,157.952h-2.655a.75.75,0,0,0,0,1.5h2.655a.75.75,0,0,0,0-1.5Z",
            transform: "translate(-135.901 -150.979)",
            fill: "currentColor"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M153.064,161.686v0l-1.953-1.076h0a1.668,1.668,0,0,0-2.478,1.408V164.2a1.633,1.633,0,0,0,.224.814l.008.013a1.683,1.683,0,0,0,2.241.6l0,0,1.94-1.073h0l.012-.006v0a1.64,1.64,0,0,0,.635-.61l.014-.024.02-.035a1.625,1.625,0,0,0-.666-2.187Zm-.728,1.545-1.95,1.078-.087.022-.151-.084-.015-.054V162.05a.156.156,0,0,1,.164-.152l.083.019.007,0L152.341,163l.01.005.066.112-.015.058-.054.052Z",
            transform: "translate(-135.901 -150.979)",
            fill: "currentColor"
          }
        }),
        _c("path", {
          attrs: {
            d: "M153.7,163.932h0v0Z",
            transform: "translate(-135.901 -150.979)",
            fill: "currentColor"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }