var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "audience-slide-in-template",
    { ref: "tmp", attrs: { title: "Import Audience" } },
    [
      !_vm.trial
        ? _c("h4", { staticClass: "txt-heading-small txt-muted mb-3" }, [
            _vm._v("Import your contacts.")
          ])
        : _vm._e(),
      _vm._v(" "),
      !!_vm.trial
        ? _c(
            "div",
            { key: "empty", staticClass: "side-panel-group-empty" },
            [
              _c("illustration-magic-stick"),
              _vm._v(" "),
              _c("h4", { staticClass: "txt-heading txt-bold mt-4" }, [
                _vm._v("This is an active plan feature.")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "txt-body txt-muted" }, [
                _vm._v("Contacts cannot be imported while on a trial account.")
              ]),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  attrs: { variant: "primary mt-4" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.goToSubscription($event)
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "btn-left",
                      attrs: { viewBox: "0 0 24 24" }
                    },
                    [
                      _c("use", {
                        attrs: { "xlink:href": "#icon-lock-locked" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("b", [_vm._v("Activate subscription")])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.trial
        ? _c("div", { staticClass: "card-library mx-0" }, [
            _c("p", { staticClass: "txt-heading-small" }, [
              _vm._v("Upload CSV")
            ]),
            _vm._v(" "),
            _c("input", {
              ref: "upload",
              staticClass: "trigger",
              attrs: { type: "file", id: "files", accept: ".csv" },
              on: {
                change: _vm.parseCSV,
                drop: function($event) {
                  _vm.dragging = false
                }
              }
            }),
            _vm._v(" "),
            _c(
              "svg",
              {
                staticClass: "icon-lg ml-auto",
                attrs: { viewBox: "0 0 24 24" }
              },
              [_c("use", { attrs: { "xlink:href": "#icon-cloud-upload" } })]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("b-alert", { attrs: { show: !_vm.trial } }, [
        _c(
          "svg",
          {
            staticClass: "alert-icon icon-sm",
            attrs: { width: "24", viewBox: "0 0 24 24" }
          },
          [
            _c("use", {
              attrs: { "xlink:href": "#icon-status-question-color" }
            })
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "alert-content" }, [
          _c("span", { staticClass: "txt-body" }, [
            _vm._v(
              "You can import contacts from other platforms by exporting them into a CSV file and uploading it."
            )
          ])
        ])
      ]),
      _vm._v(" "),
      !_vm.trial
        ? _c(
            "div",
            { staticClass: "side-panel-widget mt-auto" },
            [
              _c("side-panel-option", {
                attrs: {
                  link: { name: "RecentImports" },
                  label: "Recent Imports",
                  description: "View Audience you imported earlier.",
                  "icon-left": "clock",
                  "icon-right": "arrow-right"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }