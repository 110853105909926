<template>
  <b-modal :id="id" :static="static" :centered="centered" ref="modal" size="sm" header-class="pb-3" @hidden="$emit('hidden')">
    <template slot="modal-header" slot-scope="{ close }">
      <h5 class="txt-body txt-primary">
        <slot name="subtitle"></slot>
        {{ subtitle }}
      </h5>
      <b-button variant="icon primary" size="sm" @click="close()" class="close">
        <svg class="icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-cross"></use></svg>
      </b-button>
    </template>
    <div :class="$store.getters['isMobile'] ? 'txt-body' : 'txt-heading-small mb-0'">{{ title }}</div>
    <p class="txt-body txt-muted mt-4 mb-2" v-if="description">{{ description }}</p>
    <template slot="modal-footer">
      <b-btn @click="$emit('reject')" variant="secondary" :disabled="processing">
        {{ rejectLabel }}
      </b-btn>
      <b-btn
        :disabled="processing || disabledAccept"
        variant="primary"
        @click="$emit('accept')">
        {{ acceptLabel }}
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
  export default {
    name: 'ExitDialog',
    props: {
      title: {
        type: String,
        required: true
      },
      rejectLabel: {
        type: String,
        default: 'Exit'
      },
      acceptLabel: {
        type: String,
        default: 'Save & Exit'
      },
      description: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        required: true
      },
      centered: {
        type: Boolean,
        default: true
      },
      static: {
        type: Boolean,
        default: true
      },
      disabledAccept: {
        type: Boolean,
        default: false
      },
      id: {},
      processing: {}
    },
  };
</script>
