var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "social",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "14",
        height: "14",
        viewBox: "0 0 31.431 31.431"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: "#4267B2",
          d:
            "M27.198 0H4.235A4.235 4.235 0 0 0-.002 4.235V27.2a4.235 4.235 0 0 0 4.237 4.23h11.324l.019-11.23H12.66a.688.688 0 0 1-.688-.686l-.014-3.62a.689.689 0 0 1 .689-.691h2.913V11.7c0-4.06 2.479-6.27 6.1-6.27h2.972a.689.689 0 0 1 .689.689v3.055a.689.689 0 0 1-.688.689h-1.825c-1.969 0-2.351.936-2.351 2.309V15.2h4.328a.688.688 0 0 1 .684.77l-.429 3.62a.689.689 0 0 1-.684.607h-3.879l-.019 11.234h6.74a4.235 4.235 0 0 0 4.233-4.231V4.235A4.235 4.235 0 0 0 27.198 0z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }