var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 20 20"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(1 1)" } }, [
        _c("path", {
          attrs: {
            d: "M773,344a9,9,0,1,1-9-9,9,9,0,0,1,9,9Zm0,0",
            transform: "translate(-755 -335)",
            fill: "#f0f0f0"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M1053.931,363.427a9,9,0,0,0-5.087-8.107v16.214a9,9,0,0,0,5.087-8.107Zm0,0",
            transform: "translate(-1035.931 -354.427)",
            fill: "#d80027"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M755,363.427a9,9,0,0,0,5.087,8.107V355.32A9,9,0,0,0,755,363.427Zm0,0",
            transform: "translate(-755 -354.427)",
            fill: "#d80027"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M893.262,481.382l1.565-.783-.783-.391v-.783l-1.565.783.783-1.565h-.783l-.783-1.174-.783,1.174h-.783l.783,1.565-1.565-.783v.783l-.783.391,1.565.783-.391.783h1.565v1.174h.783v-1.174h1.565Zm0,0",
            transform: "translate(-882.696 -471.208)",
            fill: "#d80027"
          }
        }),
        _c(
          "g",
          { attrs: { fill: "none", stroke: "#e4e4e4", "stroke-width": "1" } },
          [
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9", stroke: "none" }
            }),
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9.5", fill: "none" }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }