import { render, staticRenderFns } from "./IconSettingsSecurity.vue?vue&type=template&id=369c1c49&"
import script from "./IconSettingsSecurity.vue?vue&type=script&lang=js&"
export * from "./IconSettingsSecurity.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/src/searchie/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('369c1c49')) {
      api.createRecord('369c1c49', component.options)
    } else {
      api.reload('369c1c49', component.options)
    }
    module.hot.accept("./IconSettingsSecurity.vue?vue&type=template&id=369c1c49&", function () {
      api.rerender('369c1c49', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/icons/IconSettingsSecurity.vue"
export default component.exports