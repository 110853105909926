<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.762" height="17.652" viewBox="0 0 17.762 17.652">
    <g id="Last_Week_Icon" data-name="Last Week Icon" transform="translate(0.499 0.5)">
      <path id="Union_25" data-name="Union 25" d="M11738.37,24260.525a4.258,4.258,0,0,1-4.256-4.262v-6.205a4.258,4.258,0,0,1,4.256-4.262h.378v-1.176a.752.752,0,0,1,1.5,0v1.176h4.487v-1.176a.752.752,0,0,1,1.5,0v1.176h.371a4.259,4.259,0,0,1,4.262,4.262v6.205a4.259,4.259,0,0,1-4.262,4.262Zm-2.758-10.467v6.205a2.76,2.76,0,0,0,2.758,2.758h8.244a2.766,2.766,0,0,0,2.765-2.758v-6.205a2.771,2.771,0,0,0-2.765-2.764h-.371v1.182a.752.752,0,0,1-1.5,0v-1.182h-4.487v1.182a.752.752,0,0,1-1.5,0v-1.182h-.378A2.765,2.765,0,0,0,11735.612,24250.059Zm9.645,2.137v-1.016a.749.749,0,1,1,1.5,0v1.016a.749.749,0,1,1-1.5,0Zm-3.514,0v-1.016a.749.749,0,1,1,1.5,0v1.016a.749.749,0,1,1-1.5,0Zm-3.513,0v-1.016a.752.752,0,0,1,1.5,0v1.016a.752.752,0,0,1-1.5,0Z" transform="translate(-11734.114 -24243.873)" fill="#939393" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
    </g>
  </svg>
</template>

<script>
  export default {
    namel: 'IconLastWeekDate'
  }
</script>
