var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19",
        height: "19",
        viewBox: "0 0 19 19"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M14.474,155.7H5.326A4.934,4.934,0,0,0,.4,160.626v9.148A4.934,4.934,0,0,0,5.326,174.7h9.148a4.934,4.934,0,0,0,4.926-4.926v-9.148A4.934,4.934,0,0,0,14.474,155.7Zm3.418,14.074a3.39,3.39,0,0,1-3.418,3.418H5.326a3.39,3.39,0,0,1-3.418-3.418v-1.508H4.22a3.116,3.116,0,0,0,0-6.233H1.807v-1.508a3.39,3.39,0,0,1,3.418-3.418h9.148a3.39,3.39,0,0,1,3.418,3.418v9.249Z",
          transform: "translate(-0.4 -155.7)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }