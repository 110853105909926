var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-integrate",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.218",
        height: "17.222",
        viewBox: "0 0 17.218 17.222"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M2326.053,804.7a.751.751,0,0,0-.75-.75h-1.625a.971.971,0,0,1,0-1.942h1.625a.749.749,0,0,0,.75-.75v-2.187a2.372,2.372,0,0,0-2.373-2.373h-2.513v-.875a2.471,2.471,0,0,0-4.943,0v.875h-1.67a2.374,2.374,0,0,0-2.373,2.373v1.437h-.875a2.471,2.471,0,0,0,0,4.942h.875v2.75a2.375,2.375,0,0,0,2.37,2.374h2.423a.75.75,0,0,0,.75-.75V808.2a.971.971,0,1,1,1.943,0v1.625a.749.749,0,0,0,.75.75h3.263a2.374,2.374,0,0,0,2.373-2.373h0Zm-1.5,3.5a.874.874,0,0,1-.873.873h-2.513V808.2a2.471,2.471,0,0,0-4.943,0v.875h-1.671a.875.875,0,0,1-.872-.874v-3.5a.751.751,0,0,0-.75-.75h-1.625a.971.971,0,0,1,0-1.942h1.625a.749.749,0,0,0,.75-.75v-2.187a.875.875,0,0,1,.873-.873h2.42a.75.75,0,0,0,.75-.75v-1.625a.971.971,0,1,1,1.943,0v1.625a.749.749,0,0,0,.75.75h3.263a.874.874,0,0,1,.873.873v1.437h-.875a2.471,2.471,0,0,0,0,4.942h.875v2.75Z",
          transform: "translate(-2308.835 -793.35)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }