import { Model } from '@vuex-orm/core';
import { timestamps } from '@app/utils/model-fields';
import {set} from "@app/utils/helpers";
import get from 'lodash/get';
import merge from 'lodash/merge';

export default class Base extends Model {
  static primaryKey = 'hash';
  static fields() {
    return {
      initial: Model.attr(''),
      resetKey: Model.number(new Date().getTime()),
      ...timestamps
    };
  }
  watchFields = [];
  requiredFields = [];
  setInitial(model) {
    model = model || this;
    set(model, 'initial', JSON.stringify(Object.fromEntries(this.watchFields.map(field => [field, model[field]]))))
  }
  async resetToInitial() {
    Object.entries(this.changedProperties).forEach(p => {
      set(this, p[0], get(JSON.parse(this.initial), p[0], ''));
    });
    set(this, 'resetKey', new Date().getTime());
  }
  get current() {
    return JSON.stringify(Object.fromEntries(this.watchFields.map(field => [field, this[field]])));
  }
  get hasChanged() {
    if ( !this.initial || !this.current ) return false;
    return this.initial !== this.current;
  }
  get changedProperties() {
    let properties = {};
    this.watchFields.forEach(field => {
      let current = JSON.stringify(get(JSON.parse(this.current), field, ''));
      let initial = JSON.stringify(get(JSON.parse(this.initial), field, ''));
      if ( current !== initial ) {
        properties[field] = JSON.parse(current);
      }
    });
    return properties;
  }
  get requiredProperties() {
    let properties = {};
    this.requiredFields.forEach(field => {
      properties[field] = this[field];
    });
    return properties;
  }
  get toSaveProperties() {
    return merge(this.requiredProperties, this.changedProperties);
  }
  static beforeCreate(model) {
    model.setInitial(model);
  }
}
