<template>
  <svg  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       viewBox="0 0 14.726 20.067">
    <path
        d="M14.009 17.252a.75.75 0 0 0-1.017.3 1.953 1.953 0 0 1-.29.4 1.927 1.927 0 0 1-1.414.612H3.436a1.94 1.94 0 0 1-1.937-1.937v-5.6A1.941 1.941 0 0 1 3.436 9.09h7.852a1.935 1.935 0 0 1 1.692.994.75.75 0 0 0 1.021.288.75.75 0 0 0 .288-1.021 3.431 3.431 0 0 0-2.381-1.7V4.542a4.418 4.418 0 0 0-1.331-3.214A4.422 4.422 0 0 0 7.363 0a4.419 4.419 0 0 0-3.214 1.332 4.419 4.419 0 0 0-1.332 3.214v3.109A3.433 3.433 0 0 0 0 11.029v5.6a3.437 3.437 0 0 0 3.437 3.437h7.852a3.426 3.426 0 0 0 2.508-1.087 3.433 3.433 0 0 0 .513-.711.75.75 0 0 0-.301-1.016zm-8.8-14.859A2.9 2.9 0 0 1 7.363 1.5a2.9 2.9 0 0 1 2.154.893 2.9 2.9 0 0 1 .892 2.153v3.046H4.317V4.546a2.9 2.9 0 0 1 .892-2.153z"></path>
    <path
        d="M6.613 12.655v2.35a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-2.35a.75.75 0 0 0-.75-.75.75.75 0 0 0-.75.75zM19.343 9.4a.75.75 0 0 0-1.061 0l-4.318 4.318-1.483-1.482a.749.749 0 0 0-1.06 0 .749.749 0 0 0 0 1.06l2.013 2.013a.75.75 0 0 0 1.061 0l4.848-4.848a.75.75 0 0 0 0-1.061z"></path>
  </svg>
</template>
<script>
  export default {
    name: 'IconPassword',
  };
</script>
