<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14.748" height="12.236" viewBox="0 0 14.748 12.236">
    <path fill="currentColor" d="M.746 1.5h13.248a.75.75 0 000-1.5H.746a.75.75 0 000 1.5zM13.998 7.242H.746a.75.75 0 000 1.5h13.248a.75.75 0 000-1.5zM.746 5.02h8.28a.75.75 0 000-1.5H.746a.75.75 0 000 1.5zM9.03 10.736H.746a.75.75 0 000 1.5h8.28a.75.75 0 000-1.5z"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconTextLeft'
  }
</script>
