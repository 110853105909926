export default (logoObj) => {
  if ( !logoObj ) return false;
  let logoArr = Object.entries(logoObj);
  logoArr = logoArr.filter(l => !!l[1] && l[0] !== 'level');
  if ( logoObj.level === 'team' ) {
    logoArr = logoArr.filter(l => l[0] !== 'widget' && l[0] !== 'file');
  } else if ( logoObj.level === 'widget' ) {
    logoArr = logoArr.filter(l => l[0] !== 'file');
  }
  if ( logoArr && logoArr.length && logoArr[logoArr.length - 1] )
    return logoArr[logoArr.length - 1][1];
}
