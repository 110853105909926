var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 20 20"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: "none",
          d:
            "M15.656 16.996l-11.311.001.003.002h11.305l.003-.003zM17.486 14.993l-14.972.001.001.002h14.969l.002-.003zM14.326 4.998v0zM10 1l-.013.001h.025L10 1zM8.011 7.22c-.008-.018.002-.033.007-.048-.005.015-.015.03-.007.048zM9.99 19h.02-.02zM8.003 7.22l.006.002z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#000067",
          d:
            "M14.325 8.995l.002-.998v-1-.999-.999-.998-1-.892a8.974 8.974 0 00-4.314-1.108h-.025a8.942 8.942 0 00-4.146 1.022 2.931 2.931 0 011.895.477c.013.008.029.014.037.031-.016.012-.027-.003-.038-.007a2.667 2.667 0 00-3.564 1.788 2.667 2.667 0 003.561 3.162c.013-.005.028-.008.041-.012.002-.007.008-.009.015-.008l.003.003-.015.01a2.995 2.995 0 01-4.603-1.732 2.977 2.977 0 01.061-1.657 8.96 8.96 0 00-2.176 4.92h13.198l.068-.003zM11.48 5.542l-.002.01-1.501-.12 1.031 1.101-.005.008-1.292-.754c-.007.027.006.045.012.064l.415 1.321c.005.015.014.03.006.047l.001.002-.001.001.001-.001h.001l.005-.002.001.006-.006-.004-.002.001v-.003l-.803-1.186-.041-.059-.218 1.489-.01.001-.216-1.478c-.021.006-.025.023-.033.035l-.788 1.165c-.007.012-.016.022-.024.033v.003l-.001-.001.001.001H8.01l-.007.005V7.22l.005.002.001-.001.001-.001c-.007-.018.003-.033.008-.048l.422-1.345.013-.045-1.303.759a.03.03 0 00-.005-.007l1.032-1.102-1.5.12a.036.036 0 01-.002-.01l1.403-.543-1.414-.548.002-.007 1.511.12-1.031-1.1a.03.03 0 00.005-.007l1.303.76-.45-1.432.01-.005.84 1.243.218-1.488.01-.001.218 1.49.84-1.244.01.005-.45 1.433 1.303-.76.006.007-1.031 1.101 1.511-.121.003.007-1.413.547 1.401.543z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#CD0101",
          d:
            "M18.941 8.997h-4.553l-.063-.002-.068.002H1.059a9.179 9.179 0 00-.059 1h18c0-.338-.023-.671-.059-1z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FEFDFD",
          d:
            "M19 10v-.003H1V10c0 .337.022.669.058.996h17.884c.036-.327.058-.659.058-.996zM18.771 11.994H1.229c.077.341.176.674.291.999h16.959c.115-.325.214-.657.292-.999z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#CD0101",
          d:
            "M18.479 12.993H1.52c.121.344.262.677.423 1h16.113c.161-.323.302-.656.423-1z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FEFEFE",
          d:
            "M18.057 13.993H1.944c.172.346.358.682.571 1.001l14.972-.001c.212-.318.398-.654.57-1z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#CD0000",
          d:
            "M18.942 10.996H1.058c.037.339.097.671.171.998h17.542c.074-.327.134-.659.171-.998z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FEFEFE",
          d: "M15.652 3.001a9.044 9.044 0 00-1.326-.892v.892h1.326z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#CD0101",
          d:
            "M14.326 4h2.369a9.126 9.126 0 00-1.043-1h-1.326v1zM14.326 5.997h3.726a9.017 9.017 0 00-.571-.999h-3.155v.999z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FEFEFE",
          d: "M14.326 6.997h4.15a8.91 8.91 0 00-.424-.999h-3.726v.999z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#CD0000",
          d: "M14.326 7.997h4.442a8.783 8.783 0 00-.293-1h-4.15l.001 1z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FEFEFE",
          d:
            "M14.325 8.995l.063.002h4.553a9.156 9.156 0 00-.172-1h-4.442l-.002.998zM14.326 4.998h3.155A8.912 8.912 0 0016.695 4h-2.369v.998z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FCCB00",
          d:
            "M11.488 4.445l-1.511.121 1.031-1.101-.006-.007-1.303.76.45-1.433-.01-.005L9.3 4.024l-.218-1.49-.01.001-.218 1.488-.84-1.243-.01.005.45 1.432-1.303-.76a.03.03 0 01-.005.007l1.031 1.101-1.511-.12a.014.014 0 01-.002.006l1.414.548-1.403.543a.036.036 0 01.002.01l1.5-.12-1.032 1.102a.03.03 0 01.005.007l1.301-.759a.966.966 0 01-.012.045l-.421 1.345c-.005.015-.014.03-.007.048l.025-.033.787-1.166c.008-.012.012-.03.034-.035.071.494.144.986.216 1.478l.01-.001.217-1.489.04.059.803 1.187c.008-.017-.001-.032-.006-.047l-.415-1.321c-.005-.02-.018-.038-.01-.066l1.292.754.005-.008-1.031-1.101 1.501.12.002-.01-1.402-.543 1.413-.547-.004-.006z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FCCB00",
          d:
            "M8.01 7.221v.001l.001-.003v.001zM10.145 7.222l-.001-.002v.003zM3.174 5.735a2.995 2.995 0 004.604 1.732l-.003-.005-.042.012a2.667 2.667 0 01-3.561-3.162 2.667 2.667 0 013.564-1.788c.011.004.023.019.039.007-.009-.017-.025-.023-.038-.031a2.931 2.931 0 00-1.895-.477 9.024 9.024 0 00-2.607 2.055 2.977 2.977 0 00-.061 1.657z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FCCB00",
          d:
            "M7.778 7.467l.015-.01c-.001-.001-.002-.003-.004-.003-.006 0-.012.001-.015.008l.004.005zM8.003 7.227l.006-.005-.006-.002zM8.009 7.222h.001v-.001l-.001.001zM10.151 7.22l-.005.002.006.004z"
        }
      }),
      _c("path", {
        attrs: { fill: "#FCCB00", d: "M10.145 7.222l-.001.001.002-.001z" }
      }),
      _c("path", {
        attrs: {
          fill: "#CD0101",
          d:
            "M17.485 14.996H2.516c.237.354.503.684.786 1h13.397c.282-.316.549-.646.786-1z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FEFEFE",
          d:
            "M16.698 15.996H3.301c.322.36.668.697 1.043 1.001l11.311-.001a8.96 8.96 0 001.043-1z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#CD0101",
          d:
            "M15.653 16.999H4.348c.475.384.991.718 1.539 1h8.227a9.124 9.124 0 001.539-1z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FEFEFE",
          d:
            "M14.113 17.999H5.886A8.965 8.965 0 009.99 19h.02a8.958 8.958 0 004.103-1.001z"
        }
      }),
      _c("g", { attrs: { fill: "none" } }, [
        _c("circle", { attrs: { cx: "10", cy: "10", r: "9" } }),
        _c("circle", {
          attrs: { cx: "10", cy: "10", r: "9.5", stroke: "#E4E4E4" }
        })
      ]),
      _c("g", [
        _c("defs", [
          _c("path", { attrs: { id: "a", d: "M-5002-2986.5h1000v600h-1000z" } })
        ]),
        _c("clipPath", { attrs: { id: "b" } }, [
          _c("use", { attrs: { overflow: "visible", "xlink:href": "#a" } })
        ]),
        _c("g", { attrs: { "clip-path": "url(#b)" } }, [
          _c("path", {
            attrs: { fill: "#0065BD", d: "M-5002-2986.5H4998v6000H-5002z" }
          }),
          _c("path", {
            attrs: {
              fill: "none",
              stroke: "#FFF",
              "stroke-width": ".6",
              d: "M-5002-2986.5l1000 600m-1000 0l1000-600"
            }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }