var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.audience
    ? _c(
        "div",
        { staticClass: "settings audience__full-details" },
        [
          _c(
            "b-breadcrumb",
            [
              _c(
                "b-breadcrumb-item",
                {
                  staticClass: "px-3",
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ name: "audience" })
                    }
                  }
                },
                [_c("icon-back", { staticClass: "back" })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "settings-left" },
              [
                _c("profile-image", { attrs: { user: _vm.audience } }),
                _vm._v(" "),
                _c("div", { staticClass: "settings-meta audience-meta" }, [
                  _c("h3", [
                    _vm._v(_vm._s(_vm.audience ? _vm.audience.name : ""))
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(_vm.audience ? _vm.audience.email : ""))
                  ]),
                  _vm._v(" "),
                  _vm.audience
                    ? _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.audienceTypeRenderer(_vm.audience.audience_type)
                          )
                        }
                      })
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "settings-nav audience__settings" },
                  [
                    _c(
                      "b-nav",
                      _vm._l(_vm.routes, function(route) {
                        return _c(
                          "b-nav-item",
                          {
                            key: route.fullPath,
                            attrs: {
                              active: route.name === _vm.$route.name,
                              to:
                                "/audience/" +
                                _vm.audience.hash +
                                "/" +
                                route.path
                            }
                          },
                          [
                            _c("icon-" + route.path, { tag: "component" }),
                            _vm._v(
                              "\n            " +
                                _vm._s(route.name) +
                                "\n            "
                            ),
                            _c(
                              "transition",
                              { attrs: { name: "right" } },
                              [
                                route.name === _vm.$route.name
                                  ? _c("icon-arrow-right", {
                                      staticClass: "icon-primary ml-auto",
                                      attrs: { width: "17" }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col profile-btn" },
                      [
                        _c(
                          "b-btn",
                          {
                            staticClass: "editor-save",
                            attrs: {
                              size: "sm",
                              variant: "warning-fill no-shadow",
                              block: ""
                            },
                            on: { click: _vm.confirmDelete }
                          },
                          [_vm._v("\n            Remove Person\n          ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("confirm-delete-modal")
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "settings-right audience__settings--right" },
              [_c("router-view")],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }