<template>
  <svg class="icon-fb-notf" xmlns="http://www.w3.org/2000/svg" width="27.002" height="27" viewBox="0 0 27.002 27">
    <path d="M24533.375,18786h0l-9.006,0a3.375,3.375,0,0,1-3.369-3.371v-18.262a3.373,3.373,0,0,1,3.369-3.369h18.262a3.375,3.375,0,0,1,3.371,3.369v16.5a4.34,4.34,0,0,0-1-.117,4.253,4.253,0,0,0-4.252,4.246,4.336,4.336,0,0,0,.117,1l-3.6,0,.018-8.934h3.088a.545.545,0,0,0,.541-.486l.34-2.879a.538.538,0,0,0-.543-.609h-3.443v-2.414c0-1.1.318-1.836,1.873-1.836h1.447a.549.549,0,0,0,.549-.549v-2.428a.546.546,0,0,0-.549-.543h-2.359a4.864,4.864,0,0,0-3.51,1.281,5.059,5.059,0,0,0-1.346,3.7v2.785h-2.316a.549.549,0,0,0-.549.549l.014,2.877a.549.549,0,0,0,.549.549h2.316l-.014,8.934Z" transform="translate(-24520.998 -18761)" fill="#475993"/>
    <g transform="translate(-2197.863 -296.865)">
      <circle cx="3" cy="3" r="3" transform="translate(2218.865 317.865)" fill="#f61d0c"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-facebook-notf',
  };
</script>
