var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 20 20"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(1 1)" } }, [
        _c("ellipse", {
          attrs: {
            cx: "8.991",
            cy: "8.991",
            rx: "8.991",
            ry: "8.991",
            transform: "translate(0 0)",
            fill: "#f0f0f0"
          }
        }),
        _c("g", { attrs: { transform: "translate(0.31 0.31)" } }, [
          _c("path", {
            attrs: {
              d: "M59.8,131.313a8.958,8.958,0,0,0-1.549,3.129h4.677Z",
              transform: "translate(-58.254 -128.106)",
              fill: "#0052b4"
            }
          }),
          _c("path", {
            attrs: {
              d: "M351.881,134.443a8.959,8.959,0,0,0-1.549-3.129l-3.129,3.129Z",
              transform: "translate(-334.518 -128.106)",
              fill: "#0052b4"
            }
          }),
          _c("path", {
            attrs: {
              d: "M58.254,309.427a8.96,8.96,0,0,0,1.549,3.129l3.128-3.129Z",
              transform: "translate(-58.254 -298.4)",
              fill: "#0052b4"
            }
          }),
          _c("path", {
            attrs: {
              d: "M312.555,59.8a8.959,8.959,0,0,0-3.129-1.549v4.677Z",
              transform: "translate(-298.399 -58.254)",
              fill: "#0052b4"
            }
          }),
          _c("path", {
            attrs: {
              d: "M131.313,350.332a8.96,8.96,0,0,0,3.129,1.549V347.2Z",
              transform: "translate(-128.106 -334.518)",
              fill: "#0052b4"
            }
          }),
          _c("path", {
            attrs: {
              d: "M134.441,58.254a8.96,8.96,0,0,0-3.128,1.549l3.128,3.129Z",
              transform: "translate(-128.106 -58.254)",
              fill: "#0052b4"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M309.427,351.881a8.96,8.96,0,0,0,3.128-1.549l-3.128-3.129v4.677Z",
              transform: "translate(-298.4 -334.518)",
              fill: "#0052b4"
            }
          }),
          _c("path", {
            attrs: {
              d: "M347.2,309.427l3.129,3.129a8.958,8.958,0,0,0,1.549-3.129Z",
              transform: "translate(-334.518 -298.4)",
              fill: "#0052b4"
            }
          })
        ]),
        _c("g", { attrs: { transform: "translate(0 0)" } }, [
          _c("path", {
            attrs: {
              d:
                "M69.105,59.017H61.363V51.275a9.072,9.072,0,0,0-2.346,0v7.742H51.275a9.072,9.072,0,0,0,0,2.346h7.742v7.742a9.073,9.073,0,0,0,2.346,0V61.363h7.742a9.072,9.072,0,0,0,0-2.346Z",
              transform: "translate(-51.199 -51.199)",
              fill: "#d80027"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M309.427,309.427h0l4.012,4.012q.277-.277.529-.577l-3.435-3.435Z",
              transform: "translate(-298.09 -298.09)",
              fill: "#d80027"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M115.2,309.427h0l-4.012,4.012q.277.277.577.529l3.435-3.435v-1.106Z",
              transform: "translate(-108.55 -298.09)",
              fill: "#d80027"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M103.687,115.2h0l-4.012-4.012q-.277.277-.529.577l3.435,3.435h1.106Z",
              transform: "translate(-97.041 -108.55)",
              fill: "#d80027"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M309.427,103.687h0l4.012-4.012q-.277-.277-.577-.529l-3.435,3.435Z",
              transform: "translate(-298.09 -97.041)",
              fill: "#d80027"
            }
          })
        ]),
        _c(
          "g",
          { attrs: { fill: "none", stroke: "#e4e4e4", "stroke-width": "1" } },
          [
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9", stroke: "none" }
            }),
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9.5", fill: "none" }
            })
          ]
        ),
        _c("g", { attrs: { transform: "translate(9.077 9.214)" } }, [
          _c("g", { attrs: { transform: "translate(-0.077 -0.214)" } }, [
            _c("path", {
              attrs: {
                d:
                  "M764,339.5a4.5,4.5,0,1,1-4.5-4.5,4.5,4.5,0,0,1,4.5,4.5Zm0,0",
                transform: "translate(-755 -335)",
                fill: "#f0f0f0"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M778.964,335a4.521,4.521,0,0,0-4.079,2.52h8.1a4.432,4.432,0,0,0-4.019-2.52Zm0,0",
                transform: "translate(-774.465 -335)",
                fill: "#ff9811"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M778.964,631.852a4.432,4.432,0,0,0,4.019-2.52h-8.1a4.521,4.521,0,0,0,4.079,2.52Zm0,0",
                transform: "translate(-774.465 -622.852)",
                fill: "#6da544"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M891.816,470.257a1.56,1.56,0,1,1-1.56-1.56,1.557,1.557,0,0,1,1.56,1.56Zm0,0",
                transform: "translate(-885.757 -465.757)",
                fill: "#0052b4"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M917.554,496.595a.96.96,0,1,1-.96-.96.948.948,0,0,1,.96.96Zm0,0",
                transform: "translate(-912.095 -492.095)",
                fill: "#f0f0f0"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M913.718,485.028l.3.66.72-.06-.42.6.42.6-.72-.06-.3.66-.3-.66-.72.06.42-.6-.42-.6.72.06Zm0,0",
                transform: "translate(-909.219 -481.728)",
                fill: "#0052b4"
              }
            })
          ]),
          _c(
            "g",
            {
              attrs: {
                transform: "translate(-0.077 -0.215)",
                fill: "none",
                stroke: "#fff",
                "stroke-width": "1"
              }
            },
            [
              _c("ellipse", {
                attrs: {
                  cx: "4.5",
                  cy: "4.5",
                  rx: "4.5",
                  ry: "4.5",
                  stroke: "none"
                }
              }),
              _c("ellipse", {
                attrs: { cx: "4.5", cy: "4.5", rx: "5", ry: "5", fill: "none" }
              })
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }