var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 20 20"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(1 1)" } }, [
        _c("path", {
          attrs: {
            d: "M773,344a9,9,0,1,1-9-9,9,9,0,0,1,9,9Zm0,0",
            transform: "translate(-755 -335)",
            fill: "#d80027"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M795.482,459.66l.777,2.391h2.514l-2.034,1.478.777,2.391-2.034-1.478-2.034,1.478.777-2.391-2.034-1.478h2.514Zm0,0",
            transform: "translate(-790.557 -454.182)",
            fill: "#ffda44"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M935.083,589.575l-.763-.554-.763.554.291-.9-.763-.554h.943l.291-.9.291.9h.943l-.763.554Zm0,0",
            transform: "translate(-925.26 -576.143)",
            fill: "#ffda44"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M970.114,546.756h-.943l-.291.9-.291-.9h-.943l.763-.554-.291-.9.763.554.763-.554-.292.9Zm0,0",
            transform: "translate(-958.3 -536.063)",
            fill: "#ffda44"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M970.114,481.768l-.763.554.292.9-.763-.554-.763.554.291-.9-.763-.554h.943l.291-.9.291.9Zm0,0",
            transform: "translate(-958.3 -474.461)",
            fill: "#ffda44"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M935.087,438.945l-.291.9.763.554h-.943l-.292.9-.291-.9h-.943l.763-.554-.292-.9.763.554Zm0,0",
            transform: "translate(-925.264 -434.377)",
            fill: "#ffda44"
          }
        }),
        _c(
          "g",
          { attrs: { fill: "none", stroke: "#e4e4e4", "stroke-width": "1" } },
          [
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9", stroke: "none" }
            }),
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9.5", fill: "none" }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }