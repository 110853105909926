import { render, staticRenderFns } from "./Collapse.vue?vue&type=template&id=681101db&"
import script from "./Collapse.vue?vue&type=script&lang=js&"
export * from "./Collapse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/src/searchie/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('681101db')) {
      api.createRecord('681101db', component.options)
    } else {
      api.reload('681101db', component.options)
    }
    module.hot.accept("./Collapse.vue?vue&type=template&id=681101db&", function () {
      api.rerender('681101db', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/2.0/js/core/Components/Sidepanel/Collapse.vue"
export default component.exports