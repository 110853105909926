var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "b-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c("form-control-text", {
            staticClass: "mb-3",
            attrs: {
              label: "Email",
              state: _vm.$v.email.$error ? false : null,
              id: "loginEmail",
              placeholder: "Email address",
              required: "",
              type: "email",
              disabled: ""
            },
            on: {
              change: function($event) {
                return _vm.$v.email.$touch()
              }
            },
            model: {
              value: _vm.email,
              callback: function($$v) {
                _vm.email = $$v
              },
              expression: "email"
            }
          }),
          _vm._v(" "),
          _c("form-control-text", {
            staticClass: "mb-3",
            attrs: {
              label: "New password",
              state: _vm.$v.password.$error ? false : null,
              id: "registerPass",
              placeholder: "New password",
              required: "",
              type: "password",
              showPasswordCheck: "",
              error:
                _vm.$v.password.$error && !_vm.$v.password.minLen
                  ? "Minimum of 8 characters"
                  : false
            },
            on: {
              change: function($event) {
                return _vm.$v.password.$touch()
              }
            },
            model: {
              value: _vm.password,
              callback: function($$v) {
                _vm.password = $$v
              },
              expression: "password"
            }
          }),
          _vm._v(" "),
          _c("form-control-text", {
            attrs: {
              label: "Confirm new password",
              state: _vm.$v.passwordConfirm.$error ? false : null,
              "aria-describedby": "registerPasswordMatch",
              id: "registerPassC",
              placeholder: "Confirm new password",
              required: "",
              type: "password",
              showPasswordCheck: "",
              error: _vm.$v.passwordConfirm.$error
                ? "The passwords don't match."
                : false
            },
            on: {
              change: function($event) {
                return _vm.$v.passwordConfirm.$touch()
              }
            },
            model: {
              value: _vm.passwordConfirm,
              callback: function($$v) {
                _vm.passwordConfirm = $$v
              },
              expression: "passwordConfirm"
            }
          }),
          _vm._v(" "),
          _c(
            "b-alert",
            {
              staticClass: "my-3",
              attrs: { show: !!_vm.error, fade: "", variant: "warning" }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "alert-icon icon-sm",
                  attrs: { viewBox: "0 0 24 24" }
                },
                [
                  _c("use", {
                    attrs: { "xlink:href": "#icon-warning-triangle-color" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "alert-content" }, [
                _c("span", { staticClass: "txt-body" }, [
                  _vm._v(_vm._s(_vm.error))
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "alert-link",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onReset()
                      }
                    }
                  },
                  [_c("b", [_vm._v("Try again?")])]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "b-btn",
            {
              staticClass: "my-4",
              attrs: {
                disabled: _vm.$v.$invalid || _vm.loading,
                block: "",
                type: "submit",
                variant: "primary"
              }
            },
            [_vm._v("Reset password")]
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "mt-2 txt-body txt-center auth-page-cta" },
            [
              _c("b", [_vm._v("Already have an account?")]),
              _vm._v(" "),
              _c(
                "b-btn",
                { attrs: { variant: "link ml-1", to: { name: "login" } } },
                [_vm._v("Login!")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "txt-heading txt-center mb-4 pb-4" }, [
      _c("b", [_vm._v("Forgot Password?")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }