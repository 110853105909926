<template>
  <svg id="Help_Icon" class="icon-help" data-name="Help Icon" xmlns="http://www.w3.org/2000/svg" width="15.999" height="16" viewBox="0 0 15.999 16">
    <g id="Group_4158" data-name="Group 4158" transform="translate(-573.242 -502.948)">
      <g id="Group_13933" data-name="Group 13933" transform="translate(266.666 -35.107)">
        <rect id="Rectangle_1696" data-name="Rectangle 1696" width="1.5" height="1.819" transform="translate(313.692 548.656)" fill="#939393"/>
        <path id="Path_5697" data-name="Path 5697" d="M314.591,541.431a6.65,6.65,0,0,0-2.2.428l.073,1.079.3-.065a7.818,7.818,0,0,1,1.635-.183q1.377,0,1.377.949a1.32,1.32,0,0,1-.208.773,5.122,5.122,0,0,1-.846.819,5.832,5.832,0,0,0-.884.851,1.077,1.077,0,0,0-.247.65,1.6,1.6,0,0,0,.317,1.014h1.145v-.43a1.119,1.119,0,0,1,.213-.655,4.668,4.668,0,0,1,.877-.825,2.956,2.956,0,0,0,.878-.976,3.237,3.237,0,0,0,.214-1.324,1.907,1.907,0,0,0-.636-1.6A3.262,3.262,0,0,0,314.591,541.431Z" fill="#939393"/>
        <path id="Path_5698" data-name="Path 5698" d="M314.576,538.055a8,8,0,1,0,8,8A8,8,0,0,0,314.576,538.055Zm4.6,12.6a6.5,6.5,0,1,1,1.9-4.6A6.473,6.473,0,0,1,319.172,550.651Z" fill="#939393"/>
      </g>
    </g>
  </svg>

</template>

<script>
  export default {
    name: 'IconHelp'
  }
</script>
