<template>
	<svg class="icon-week" xmlns="http://www.w3.org/2000/svg" width="17.762" height="17.652" viewBox="0 0 17.762 17.652">
	  <path d="M4.756 17.152A4.258 4.258 0 01.5 12.891V6.686a4.258 4.258 0 014.256-4.262h.378V1.248a.752.752 0 011.5 0v1.176h4.487V1.248a.752.752 0 011.5 0v1.176H13a4.259 4.259 0 014.262 4.262v6.205A4.259 4.259 0 0113 17.152zM2 6.686v6.205a2.76 2.76 0 002.758 2.758H13a2.766 2.766 0 002.765-2.758V6.686A2.771 2.771 0 0013 3.922h-.371V5.1a.752.752 0 01-1.5 0V3.922H6.638V5.1a.752.752 0 01-1.5 0V3.922h-.382A2.765 2.765 0 002 6.686zm9.645 2.137V7.807a.75.75 0 111.5 0v1.015a.75.75 0 11-1.5 0zm-3.514 0V7.807a.75.75 0 111.5 0v1.015a.75.75 0 11-1.5 0zm-3.513 0V7.807a.752.752 0 011.5 0v1.015a.752.752 0 01-1.5 0z" fill="currentColor" stroke="rgba(0,0,0,0)" stroke-miterlimit="10"/>
	</svg>
</template>
<script>
  export default {
    name: 'IconWeek',
  };
</script>
