<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.761" height="17.65" viewBox="0 0 17.761 17.65">
    <g id="Last_Year_Icon" data-name="Last Year Icon" transform="translate(0.501 0.5)">
      <path id="Last_Year_Icon-2" data-name="Last Year Icon" d="M11738.357,24162.652a4.256,4.256,0,0,1-4.257-4.262v-6.2a4.255,4.255,0,0,1,4.257-4.258h.382v-1.182a.749.749,0,0,1,1.5,0v1.182h4.493v-1.182a.749.749,0,0,1,1.5,0v1.182h.376a4.255,4.255,0,0,1,4.257,4.258v6.2a4.256,4.256,0,0,1-4.257,4.262Zm-2.76-10.465v6.2a2.773,2.773,0,0,0,2.76,2.766h8.247a2.772,2.772,0,0,0,2.759-2.766v-6.2a2.765,2.765,0,0,0-2.759-2.76h-.376v1.176a.749.749,0,1,1-1.5,0v-1.176h-4.493v1.176a.749.749,0,1,1-1.5,0v-1.176h-.382A2.766,2.766,0,0,0,11735.6,24152.188Zm7.187,7.506h-1.377l.559-1.994h-.644l-1.364-5.221h1.589l.886,3.4.885-3.4h1.6l-1.917,7.215Z" transform="translate(-11734.101 -24146.002)" fill="#939393" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconLastYearDate'
  }
</script>
