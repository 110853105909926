var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" } },
    [
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M19 10c0-4.971-4.029-9-9-9s-9 4.029-9 9 4.029 9 9 9 9-4.029 9-9zm-9 3.803a3.803 3.803 0 110-7.607 3.803 3.803 0 010 7.607z"
        }
      }),
      _c("circle", {
        attrs: { cx: "10", cy: "10", r: "3.803", fill: "#BC002D" }
      }),
      _c("g", { attrs: { fill: "none" } }, [
        _c("circle", { attrs: { cx: "10", cy: "10", r: "9" } }),
        _c("circle", {
          attrs: { cx: "10", cy: "10", r: "9.5", stroke: "#E4E4E4" }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }