var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "audience-slide-in-template",
    {
      attrs: {
        "back-label": "Bulk Actions",
        "back-route": { name: "BulkIndex" },
        title: _vm.$route.params.title
      }
    },
    [
      _c("selected-audience"),
      _vm._v(" "),
      _c("hr", { staticClass: "mt-0 mb-3" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-widget p-4" },
        [
          _vm.$can("achievements")
            ? _c("form-control-select", {
                attrs: {
                  placeholder: "Search Manual Achievements...",
                  "track-by": "id",
                  "select-label": "title",
                  options: _vm.achievementOptions,
                  "search-visible": true,
                  type:
                    "manual achievement" +
                    (_vm.achievements.length > 1 ? "s" : ""),
                  "selected-title":
                    _vm.$route.name === "AchievementsAttach" ? "Add" : "Remove",
                  "block-keys": ["Delete"],
                  identifier: _vm.infiniteId,
                  "tag-size": "md",
                  "tag-basic": "",
                  "tag-block": "",
                  infinite: "",
                  "hide-selected": "",
                  multiple: ""
                },
                on: { infinite: _vm.achievementsInfiniteHandler },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "optionIcon",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.settings
                            ? _c("badge", {
                                staticClass: "mr-2",
                                attrs: {
                                  size: "sm",
                                  shape: "shape-" + _vm.getShape(item),
                                  outline: _vm.getOutline(item),
                                  base: _vm.getBase(item),
                                  "icon-size": _vm.getIconSize(item),
                                  "icon-color": _vm.getIconColor(item),
                                  "icon-type": _vm.getIllustrationType(item),
                                  inner: _vm.getIllustrationInner(item)
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "tagIcon",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.settings
                            ? _c("badge", {
                                staticClass: "mr-2",
                                attrs: {
                                  size: "btn",
                                  shape: "shape-" + _vm.getShape(item),
                                  outline: _vm.getOutline(item),
                                  base: _vm.getBase(item),
                                  "icon-size": _vm.getIconSize(item),
                                  "icon-color": _vm.getIconColor(item),
                                  "icon-type": _vm.getIllustrationType(item),
                                  inner: _vm.getIllustrationInner(item)
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  184442462
                ),
                model: {
                  value: _vm.achievements,
                  callback: function($$v) {
                    _vm.achievements = $$v
                  },
                  expression: "achievements"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-library-cta p-3" },
        [
          _c(
            "b-btn",
            {
              attrs: {
                block: "",
                variant: "primary",
                size: "lg",
                disabled: _vm.isSaving
              },
              on: { click: _vm.saveBulkAchievements }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "btn-left",
                  attrs: { width: "24", viewBox: "0 0 24 24" }
                },
                [_c("use", { attrs: { "xlink:href": "#icon-status-success" } })]
              ),
              _vm._v("\n      Apply Edits\n    ")
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }