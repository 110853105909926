var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "8.646",
        height: "6.492",
        viewBox: "0 0 8.646 6.492"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Path_454",
          "data-name": "Path 454",
          d: "M1546.521,69.546l-4.493,4.493-2.154-2.181",
          transform: "translate(-1538.874 -68.547)",
          fill: "none",
          stroke: "#293ad3",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }