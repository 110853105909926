var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-folder-lg",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.04",
        height: "15",
        viewBox: "0 0 17.04 14.995"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M14.471 2.961H8.62v-.905A2.081 2.081 0 006.557.003H2.085A2.083 2.083 0 00.003 2.052V12.429a2.57 2.57 0 002.57 2.569h11.9a2.57 2.57 0 002.569-2.569v-6.9a2.569 2.569 0 00-2.571-2.568zM15.54 5.53v6.9a1.071 1.071 0 01-1.069 1.069h-11.9a1.071 1.071 0 01-1.07-1.069V2.074a.583.583 0 01.581-.574h4.454a.584.584 0 01.583.576v.885H4.547a.75.75 0 100 1.5h9.922a1.071 1.071 0 011.069 1.069z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }