<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28" height="28" viewBox="0 0 28 28">
    <defs>
      <clipPath id="clip-path">
        <circle id="Mask" cx="14" cy="14" r="14" fill="#fafafa" stroke="#f3f3f3" stroke-width="1.5"/>
      </clipPath>
      <linearGradient id="linear-gradient" x1="0.403" y1="0.741" x2="1.238" y2="-1.918" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-opacity="0"/>
        <stop offset="1" stop-opacity="0.502"/>
      </linearGradient>
      <linearGradient id="linear-gradient-2" x1="0.414" y1="1" x2="1.964" y2="-1.85" xlink:href="#linear-gradient"/>
    </defs>
    <g id="Profile" clip-path="url(#clip-path)">
      <g id="CIRCLE" transform="translate(0 2)" fill="#fafafa" stroke="rgba(0,0,0,0.04)" stroke-width="1.5">
        <circle cx="14" cy="14" r="14" stroke="none"/>
        <circle cx="14" cy="14" r="13.25" fill="none"/>
      </g>
      <g id="Group_19414" data-name="Group 19414" transform="translate(3.577 5.223)">
        <path id="Path_6652" data-name="Path 6652" d="M335.335,387.595A10.308,10.308,0,0,0,325.028,397.9v1.292a3.147,3.147,0,0,0,3.147,3.145H342.5a3.147,3.147,0,0,0,3.146-3.145V397.9A10.308,10.308,0,0,0,335.335,387.595Z" transform="translate(-325.028 -375.57)" fill="url(#linear-gradient)"/>
        <path id="Path_6653" data-name="Path 6653" d="M333.651,390.578a5.429,5.429,0,1,0-5.428-5.428A5.428,5.428,0,0,0,333.651,390.578Z" transform="translate(-323.344 -379.72)" fill="url(#linear-gradient-2)"/>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconAudienceProfileLoading'
  }
</script>
