var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-no-transcription",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "13.989",
        height: "13.031",
        viewBox: "0 0 13.989 13.031"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M836.031,831.947a.75.75,0,0,0-.75.75h0v3.4a1.3,1.3,0,0,1-1.295,1.295h-3.438a.75.75,0,0,0,0,1.5h3.438a2.794,2.794,0,0,0,2.795-2.795v-3.4A.75.75,0,0,0,836.031,831.947Z",
          transform: "translate(-823.837 -827.824)",
          fill: "currentColor"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M837.606,828.043a.752.752,0,0,0-1.061,0l-.917.918a2.772,2.772,0,0,0-1.642-.542h-7.354a2.8,2.8,0,0,0-2.8,2.795V836.1a2.786,2.786,0,0,0,1.992,2.663l-.815.816a.75.75,0,0,0,1.061,1.061L837.606,829.1A.75.75,0,0,0,837.606,828.043Zm-10.974,9.348a1.3,1.3,0,0,1-1.3-1.295v-4.882a1.3,1.3,0,0,1,1.3-1.3h7.354a1.282,1.282,0,0,1,.551.134l-1.322,1.322h-5.758a.75.75,0,0,0,0,1.5h4.258l-1.566,1.565c-.006,0-.011,0-.017,0h-2.675a.75.75,0,0,0,0,1.5h1.2l-1.456,1.456Z",
          transform: "translate(-823.837 -827.824)",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }