import store from "@app2/store";

export const isSubscriptionCancelled = () => {
    const activeSubscription = store.getters['auth/GET_SUBSCRIPTION'];
    return activeSubscription?.status === 'cancelled';
};

export const subscribedGuard = async (to, from, next) => {
    if (isSubscriptionCancelled()) {
        next({ name: 'AccountSubscription' });
    } else {
        // User is subscribed, allow navigation to the target route
        next();
    }
};