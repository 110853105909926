var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-files",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18.741",
        height: "21.601",
        viewBox: "0 0 18.741 21.601"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Group_9009",
            "data-name": "Group 9009",
            transform: "translate(-1534.741 -718.189)"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "Path_3470",
              "data-name": "Path 3470",
              d:
                "M1545.788,732.152h-6.928a.75.75,0,0,0,0,1.5h6.928a.75.75,0,0,0,0-1.5Z",
              fill: "#979797"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_3471",
              "data-name": "Path 3471",
              d:
                "M1542.076,734.809h-3.216a.75.75,0,0,0,0,1.5h3.216a.75.75,0,0,0,0-1.5Z",
              fill: "#979797"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_3472",
              "data-name": "Path 3472",
              d:
                "M1541.859,729.493l1.74-.961a.809.809,0,0,0,.309-.3l.013-.024a.78.78,0,0,0-.322-1.055l-1.744-.969a.819.819,0,0,0-.4-.1.806.806,0,0,0-.815.794V728.8a.784.784,0,0,0,.109.394A.826.826,0,0,0,1541.859,729.493Z",
              fill: "#979797"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_3473",
              "data-name": "Path 3473",
              d:
                "M1550.176,718.189h-8.319a3.306,3.306,0,0,0-3.306,3.306v.659h-.324a3.486,3.486,0,0,0-3.486,3.486V736.3a3.488,3.488,0,0,0,3.486,3.486h8.195a3.486,3.486,0,0,0,3.486-3.486v-.757h.268a3.306,3.306,0,0,0,3.306-3.3V721.495h0A3.305,3.305,0,0,0,1550.176,718.189Zm-1.768,18.115a1.989,1.989,0,0,1-1.986,1.986h-8.195a1.99,1.99,0,0,1-1.986-1.986V725.64a1.991,1.991,0,0,1,1.986-1.987h8.195a1.99,1.99,0,0,1,1.986,1.987Zm3.574-14.809v10.747a1.809,1.809,0,0,1-1.806,1.806h-.268V725.64a3.486,3.486,0,0,0-3.486-3.486h-6.371v-.659a1.809,1.809,0,0,1,1.806-1.807h8.319a1.809,1.809,0,0,1,1.806,1.807Z",
              fill: "#979797"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }