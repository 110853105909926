var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-cloudup",
      class: { "icon-cloudup-full": _vm.clip > 25 },
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "33",
        height: "16",
        viewBox: "0 0 33 16"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Group_1973",
            "data-name": "Group 1973",
            transform: "translate(-21 -963.499)"
          }
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Mask_Group_34",
                "data-name": "Mask Group 34",
                transform: "translate(0 -16)"
              }
            },
            [
              _c("path", {
                staticClass: "icon-fill",
                attrs: {
                  id: "Path_800",
                  "data-name": "Path 800",
                  d:
                    "M1026.725,194.432a5.217,5.217,0,0,0-1.872.364,6.219,6.219,0,0,0-12.154,1.868c0,.167.012.334.025.5a3.962,3.962,0,0,0-2.925,3.821c0,.1,0,.217.014.337h0v.008h0a3.851,3.851,0,0,0,1.362,2.606,4.263,4.263,0,0,0,2.76,1.015h12.817a5.237,5.237,0,0,0,5.232-5.238v-.021A5.262,5.262,0,0,0,1026.725,194.432Z",
                  transform: "translate(-981.104 789.065)",
                  fill: "#c7cbff"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("path", {
            staticClass: "icon-stroke",
            attrs: {
              id: "Path_799",
              "data-name": "Path 799",
              d:
                "M1026.725,194.432a5.217,5.217,0,0,0-1.872.364,6.219,6.219,0,0,0-12.154,1.868c0,.167.012.334.025.5a3.962,3.962,0,0,0-2.925,3.821c0,.1,0,.217.014.337h0v.008h0a3.851,3.851,0,0,0,1.362,2.606,4.263,4.263,0,0,0,2.76,1.015h12.817a5.237,5.237,0,0,0,5.232-5.238v-.021A5.262,5.262,0,0,0,1026.725,194.432Zm3.761,5.282a3.737,3.737,0,0,1-3.732,3.738h-12.817a2.768,2.768,0,0,1-1.787-.656,2.348,2.348,0,0,1-.842-1.6v-.009l-.008-.2a2.462,2.462,0,0,1,2.31-2.457.749.749,0,0,0,.688-.9,4.819,4.819,0,0,1-.1-.966,4.725,4.725,0,0,1,9.4-.68.751.751,0,0,0,1.144.526,3.751,3.751,0,0,1,5.742,3.184Z",
              transform: "translate(-981.104 773.064)",
              fill: "#293ad3"
            }
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "icon-mark",
            attrs: {
              id: "Path_816",
              "data-name": "Path 816",
              d: "M.868-9.8v5.746h1.54V-9.8Zm0,6.482v1.563h1.54V-3.318Z",
              transform: "translate(36.282 976.664)",
              fill: "#c24141"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }