var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-section-header",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19",
        height: "16",
        viewBox: "0 0 19 16"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M13.779.021H15.75l.23-.015a.75.75,0,0,1,.195,1.488,3.31,3.31,0,0,1-.425.027H13.779a.75.75,0,0,1,0-1.5",
          transform: "translate(0 14.479)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M17.5,4.355v-1.7a.75.75,0,0,1,1.5,0v1.7a3.283,3.283,0,0,1-.092.772.75.75,0,1,1-1.457-.355,1.777,1.777,0,0,0,.049-.417",
          transform: "translate(0 8.395)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d: "M8.479,0h2.3a.75.75,0,0,1,0,1.5h-2.3a.75.75,0,0,1,0-1.5",
          transform: "translate(0 14.5)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d: "M3.2,0H5.479a.75.75,0,0,1,0,1.5H3.158A.75.75,0,1,1,3.2,0",
          transform: "translate(0 14.499)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M18.25,15.743a.749.749,0,0,1-.75-.75v-1.55H1.5V15.25a.75.75,0,0,1-1.5,0v-2.3a.692.692,0,0,1,.026-.128A.719.719,0,0,1,0,12.693v-2.5a3.251,3.251,0,0,1,3.25-3.25h12.5A3.25,3.25,0,0,1,19,10.193v2.5h0v2.3a.75.75,0,0,1-.75.75m-2.5-7.3H3.25a1.753,1.753,0,0,0-1.75,1.75v1.75h16v-1.75a1.753,1.753,0,0,0-1.75-1.75",
          transform: "translate(0 -6.943)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M.75,1.659a.75.75,0,0,1,.75.75V3.852a1.75,1.75,0,0,0,.1.588.75.75,0,1,1-1.413.5A3.245,3.245,0,0,1,0,3.852V2.409a.75.75,0,0,1,.75-.75",
          transform: "translate(0 8.898)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }