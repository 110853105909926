var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-rename",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "18",
        height: "17.769",
        viewBox: "0 0 18 17.769"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M485.342,381.81a4.534,4.534,0,0,0-1.693.423v-3.37h-2.1V389.8l.6.062q1.786.157,2.461.157a4.176,4.176,0,0,0,3.026-.886,4.534,4.534,0,0,0,.877-3.2,5.112,5.112,0,0,0-.76-3.222A2.976,2.976,0,0,0,485.342,381.81Zm.674,5.823a1.627,1.627,0,0,1-1.411.51q-.234,0-.956-.062v-4.186l.22-.047a4.41,4.41,0,0,1,1.191-.157q1.317,0,1.316,2.116A3.33,3.33,0,0,1,486.016,387.633Z",
          transform: "translate(-481.548 -375.892)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M499.156,388.229a1.244,1.244,0,0,1-.125-.525v-3.261a2.645,2.645,0,0,0-.667-2.006,3.119,3.119,0,0,0-2.179-.627,12.17,12.17,0,0,0-3.19.455l.064,1.457,2.946-.125a1.112,1.112,0,0,1,.706.18.829.829,0,0,1,.219.666v.487l-1.677.125a3.781,3.781,0,0,0-2.069.619,2.121,2.121,0,0,0-.675,1.779q0,2.571,2.43,2.571a5.225,5.225,0,0,0,2.3-.548,2.885,2.885,0,0,0,.963.439,6.027,6.027,0,0,0,1.278.109l.063-1.583A.581.581,0,0,1,499.156,388.229Zm-2.226-.165-.235.063a5.05,5.05,0,0,1-1.3.188q-.77,0-.769-.862a.851.851,0,0,1,.878-.94l1.427-.126Z",
          transform: "translate(-481.548 -375.892)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M490.509,375.892a.75.75,0,0,0-.75.75v16.269a.75.75,0,0,0,1.5,0V376.642A.75.75,0,0,0,490.509,375.892Z",
          transform: "translate(-481.548 -375.892)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }