import Base from "@app2/models/Base";
import Playlist from '@app2/models/Playlist';
import Hub from '@app2/models/Hub';
import Page from "@app2/models/Page";
import AudienceAttribute from "@app2/models/AudienceAttribute";
import File from "@app2/models/File";

export default class Section extends Base {
  static entity = 'App\\Section';

  static apiConfig = {
    baseURL: '/api/hubs/',
  };

  static fields() {
    return {
      id: this.attr(null),
      hub_id: this.attr(null),
      page_id: this.attr(null),
      hub: this.belongsTo(Hub, 'hub_id'),
      page: this.belongsTo(Page, 'page_id'),
      model_id: this.attr(null),
      model_type: this.attr(null),
      model: this.attr(null),
      hidden: this.attr(null),
      type: this.attr(null),
      label: this.attr(null),
      title: this.attr(null),
      settings: this.attr({}),
      blocks: this.attr(null),
      permissions: this.attr(null),
      position: this.attr(null),
      ...super.fields(),
    };
  }

  static setModels(section) {
    if (section.model && section.model_type === Playlist.entity) {
      Playlist.insertOrUpdate({data: section.model});
    }
    if (section.model && section.model_type === AudienceAttribute.entity) {
      AudienceAttribute.insertOrUpdate({data: section.model});
    }
    if (section.model && section.model_type === File.entity) {
      File.insertOrUpdate({data: section.model});
    }
    if ( Array.isArray(section.blocks) ) {
      section.blocks.forEach(block => {
        Section.setModels(block);
      });
    }
  }

  static afterCreate(section) {
    Section.setModels(section);
  }
}
