var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.318",
        height: "14.468",
        viewBox: "0 0 17.318 14.468"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Group_1928",
            "data-name": "Group 1928",
            transform: "translate(-576 -405.999)"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "Path_725",
              "data-name": "Path 725",
              d: "M585.913,406.076h0Z",
              transform: "translate(-0.117)",
              fill: "#c7cbff"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_726",
              "data-name": "Path 726",
              d: "M580.394,407.485h0Z",
              transform: "translate(-0.052)",
              fill: "#c7cbff"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_727",
              "data-name": "Path 727",
              d: "M588.651,407.043h0Z",
              transform: "translate(-0.149)",
              fill: "#c7cbff"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_728",
              "data-name": "Path 728",
              d: "M583.014,406.231h0Z",
              transform: "translate(-0.083)",
              fill: "#c7cbff"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_729",
              "data-name": "Path 729",
              d:
                "M581.516,411.565a.754.754,0,0,0,.331-1.005.734.734,0,0,0-.991-.337l-1.417.716a5.772,5.772,0,0,1,.252-.529v0a5.83,5.83,0,0,1,1.528-1.721l0,0a5.758,5.758,0,0,1,2.061-1h0a5.729,5.729,0,0,1,1.443-.185,5.582,5.582,0,0,1,.835.062h0a5.766,5.766,0,0,1,2.154.772h0a5.837,5.837,0,0,1,1.7,1.548.733.733,0,0,0,1.034.154.758.758,0,0,0,.152-1.05,7.337,7.337,0,0,0-2.131-1.942,7.208,7.208,0,0,0-2.7-.967,7.02,7.02,0,0,0-1.049-.078,7.288,7.288,0,0,0-4.395,1.485,7.355,7.355,0,0,0-1.915,2.161v0a7.478,7.478,0,0,0-.358.751l-.661-1.343a.736.736,0,0,0-.992-.336.756.756,0,0,0-.331,1.006h0l1.479,3.007a.735.735,0,0,0,.992.337Z",
              fill: "#c7cbff"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_730",
              "data-name": "Path 730",
              d: "M580.873,419.6h0Z",
              transform: "translate(-0.057 -0.172)",
              fill: "#c7cbff"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_731",
              "data-name": "Path 731",
              d: "M591.072,417h0Z",
              transform: "translate(-0.206 -0.13)",
              fill: "#c7cbff"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_732",
              "data-name": "Path 732",
              d: "M589.13,419.156h0Z",
              transform: "translate(-0.155 -0.172)",
              fill: "#c7cbff"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_733",
              "data-name": "Path 733",
              d:
                "M593.241,416.923h0l-1.479-3.008a.735.735,0,0,0-.992-.336l-2.966,1.5a.753.753,0,0,0-.331,1.006.734.734,0,0,0,.992.336l1.414-.715c-.082.176-.152.357-.251.524l0,0a5.834,5.834,0,0,1-1.527,1.722l0,0a5.792,5.792,0,0,1-6.5.352l0,0a5.811,5.811,0,0,1-1.7-1.548.733.733,0,0,0-1.036-.152.757.757,0,0,0-.15,1.05,7.331,7.331,0,0,0,2.13,1.941,7.228,7.228,0,0,0,2.7.967,7.341,7.341,0,0,0,1.049.076,7.248,7.248,0,0,0,4.393-1.484A7.358,7.358,0,0,0,590.9,417a7.609,7.609,0,0,0,.357-.748l.661,1.343a.736.736,0,0,0,.992.337A.755.755,0,0,0,593.241,416.923Z",
              transform: "translate(0 -0.172)",
              fill: "#c7cbff"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }