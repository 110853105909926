import get from "lodash/get";
import moment from "moment";

export const parseAttributes = (attributes) => {
  attributes.forEach(att => {
    if ( att.type === 'multiple' ) {
      if ( get(att, 'settings.allowMultiple', false) ) {
        att.value = att.options.filter(op => op.attribute_data && op.attribute_data.length);
      } else {
        att.value = att.options.find(op => op.attribute_data && op.attribute_data.length);
      }
    } else {
      if ( !att.attribute_data || att.attribute_data && !att.attribute_data.length ) return;
      if ( att.type === 'boolean' ) {
        att.value =  !!att.attribute_data[0].value;
      } else if ( att.type === 'date' ) {
        let value = new moment.utc(att.attribute_data[0].value, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss");
        att.value = value;
        att.originalValue = new moment.utc(att.attribute_data[0].value, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
        att.dateValue = value
        att.timeValue = value.substr(-8);
      } else {
        att.value = att.attribute_data[0].value;
      }
    }
  })
  return attributes
}

export const prepareAttributes = (attrs) => {
  let attributes = [];
  attrs.forEach(field => {
    if ( field.type === 'multiple' ) {
      field.options.forEach(op => {
        attributes.push({
          id: op.id,
          type: op.type,
          value: (
            field.value &&
            (
              Array.isArray(field.value) &&
              field.value.map(f => f.id).includes(op.id)
              ||
              !Array.isArray(field.value) &&
              field.value.id === op.id
            )
          ) ? op.title : null
        })
      });
    } else if (field.type === 'date') {
      if ( !field.dateValue ) {
        attributes.push({
          id: field.id,
          value: null,
          type: field.type,
        })
      } else {
        let value = new moment(`${field.dateValue} ${field.timeValue || '00:00:00'}`, "YYYY-MM-DD HH:mm:ss").utc();
        attributes.push({
          id: field.id,
          value: value.format("YYYY-MM-DD HH:mm:ss"),
          type: field.type,
        })
      }
    } else {
      if ( !field.hasOwnProperty('value') || field.type === 'text' && field.value === '' ) {
        attributes.push({
          id: field.id,
          value: field.type === 'boolean' ? false : null,
          type: field.type,
        })
      } else {
        let value = (field.type === 'number') ? parseFloat(field.value) : field.value;
        attributes.push({
          id: field.id,
          value,
          type: field.type,
        })
      }
    }
  })
  return attributes
}

export const isAttributeValid = (field) => {
  if ( !field ) return;
  let isValid;
  if ( field.type === 'multiple' && get(field, 'settings.allowMultiple', false) ) {
    isValid = field.value && Array.isArray(field.value) && field.value.length;
  } else if (field.type === 'date') {
    isValid = !!field.dateValue;
  } else {
    isValid = !!field.value && field.value !== '';
  }
  return isValid;
}
