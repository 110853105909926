<template>
  <svg class="icon-section-hero" xmlns="http://www.w3.org/2000/svg" width="19.374" height="16.099" viewBox="0 0 19.374 16.099">
    <g transform="translate(-3213.108 -408.046)">
      <path d="M3232.482,420.759v-9.333a3.38,3.38,0,0,0-3.379-3.38h-12.622a3.38,3.38,0,0,0-3.373,3.386v9.334a3.38,3.38,0,0,0,3.379,3.379h12.622a3.378,3.378,0,0,0,3.144-2.187.693.693,0,0,0,.042-.134A3.359,3.359,0,0,0,3232.482,420.759Zm-16-11.214h12.62a1.882,1.882,0,0,1,1.879,1.881v8.559l-3.314-3.315a.749.749,0,0,0-1.06,0l-1.82,1.82-5.463-5.463a.749.749,0,0,0-1.061,0l-3.656,3.656v-5.251A1.88,1.88,0,0,1,3216.483,409.545Zm12.623,13.1h-12.619a1.882,1.882,0,0,1-1.879-1.879V418.8l4.186-4.187,5.464,5.464a.751.751,0,0,0,1.06,0l1.82-1.82,3.533,3.533A1.876,1.876,0,0,1,3229.106,422.645Z" fill="#979797"/>
      <path d="M3226.967,414.5a1.521,1.521,0,1,0-1.521-1.521A1.521,1.521,0,0,0,3226.967,414.5Z" fill="#979797"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-section-hero'
  }
</script>
