import Vue from 'vue';

const namespaced = true;

const state = {
  canvApi: null
};

const mutations = {
  SET_CANVA_API(state, payload) {
    state.canvApi = payload
  }
};

const getters = {
  canvaApi: state => state.canvApi
}

export default {
  namespaced,
  state,
  mutations,
  getters
};
