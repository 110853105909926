<template>
  <div v-click-away v-if="pages > 1" class="pagination" >
    <b-btn variant="icon primary" size="sm" disabled>
      <!-- <svg class="icon-sm btn-left" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-settings"></use></svg> -->
      <b>{{perPageRows}} {{perPageRows ? 'Rows' : ''}}</b>
    </b-btn>
    <div class="pagination-inner">
      <b-btn
        variant="icon primary"
        @click="firstPage"
        :disabled="activePage == 1"
        v-if="pages > 3"
      >
        First
      </b-btn>
      <div v-if="pageTrays.prevPageNumbers && pageTrays.prevPageNumbers.length > 0" class="pagination-more">
        <transition name="fade">
          <div class="pagination__more-option--previous" v-if="isMoreOptionPrevOpen">
            <div
              v-for="(prevPage, key) in pageTrays.prevPageNumbers"
              :key="key"
              @click="switchPage(prevPage)">
              {{ prevPage }}
            </div>
          </div>
        </transition>
        <div class="pagination__more-option--btn" @click="isMoreOptionPrevOpen = !isMoreOptionPrevOpen">
          <i></i>
          <i></i>
          <i></i>
        </div>
      </div>
      <b-btn variant="icon primary" :disabled="activePage == 1" @click="previousPage">
        <svg width="24" viewBox="0 0 24 24"><use xlink:href="#icon-arrow-left"></use></svg>
      </b-btn>
      <b-btn
        :variant="page === activePage ? 'primary' : 'icon primary'"
        @click="switchPage(page)"
        v-for="(page, key) in navigablePageNumber"
        :key="key">
        {{ page }}
      </b-btn>
      <b-btn variant="icon primary" :disabled="isLastPage" @click="nextPage">
        <svg width="24" viewBox="0 0 24 24"><use xlink:href="#icon-arrow-right"></use></svg>
      </b-btn>
      <div v-if="pageTrays.nextPageNumbers && pageTrays.nextPageNumbers.length > 0" class="pagination__more-option-container">
        <transition name="fade">
          <div class="pagination__more-option--next" v-if="isMoreOptionNextOpen">
            <div
              v-for="(nextPage, key) in pageTrays.nextPageNumbers"
              :key="key"
              @click="switchPage(nextPage)">
              {{ nextPage }}
            </div>
          </div>
        </transition>
        <div class="pagination__more-option--btn" @click="isMoreOptionNextOpen = !isMoreOptionNextOpen">
          <i></i>
          <i></i>
          <i></i>
        </div>
      </div>
      <b-btn
        variant="icon primary"
        @click="lastPage"
        :disabled="isLastPage"
        v-if="pages > 3"
      >
        Last
      </b-btn>
    </div>

    <div class="txt-body">
      <b>{{perPageRows * currentPage - perPageRows + 1}}-{{isLastPage ? countTotal : perPageRows * currentPage}}</b> <span class=" txt-muted">of {{countTotal}}</span>
    </div>

  </div>
</template>

<script>
  export default {
    props: {
      pages: {
        type: Number,
        required: true
      },
      currentPage: {
        type: [Number, String],
      },
      perPageSize: Number,
      perPageRows: {
        type: Number,
        default: 25,
      },
      countTotal: {
        type: Number,
        default: 0,
      },
    },
    directives: {
      clickAway: {
        bind(el, binding, vnode) {
          el.clickOutsideEvent = (event) => {
            if (!(el == event.target || el.contains(event.target))) {
              vnode.context.isMoreOptionPrevOpen = false;
              vnode.context.isMoreOptionNextOpen = false;
            }
          };
          document.body.addEventListener("click", el.clickOutsideEvent);
        },
        unbind(el) {
          document.body.removeEventListener(
            "click",
            el.clickOutsideEvent
          );
        }
      }
    },
    watch: {
      pages: {
        handler() {
          this.reloadPagination()
        },
        immediate: true
      },
      currentPage(val) {
        this.switchPage(+val)
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.isLastPage = parseInt(this.currentPage) === this.pages;
      });
    },
    data() {
      return {
        activePage: +this.currentPage,
        isLastPage: false,
        isMoreOptionPrevOpen: false,
        isMoreOptionNextOpen: false,
        pageNumbers: [],
        navigablePageNumber: [],
        firstLoad: false,
        pageSets: [],
        pageTrays: {}
      }
    },
    methods: {
      reloadPagination() {
        this.getPageNumbers()
        this.pageSets = this.groupPageNumbers()
        this.navigablePageNumber = this.pageSets[this.groupIndexOfCurrentPage()]
        this.pageTrays = this.getPrevAndNextPages()
        this.firstLoad = true
      },
      switchPage(page) {
        this.isLastPage = page === this.pages;
        this.activePage = page
        this.$emit('switch-page', page)

        if (!this.navigablePageNumber.includes(this.activePage)) {
          this.reloadPagination()
        }
        // if (page !== this.$route.query.page) {
        this.$router.push({ ...this.$route, query: { ...this.$route.query, page } }).catch(()=>{});
        // }
      },
      firstPage() {
        this.switchPage(1)
      },
      lastPage() {
        this.switchPage(this.pages)
      },
      nextPage() {
        this.activePage++
        this.switchPage(this.activePage)
      },
      previousPage() {
        this.activePage--
        this.switchPage(this.activePage)
      },
      getPageNumbers() {
        this.pageNumbers = []
        for (let i = 1; i <= this.pages; i++) {
          this.pageNumbers.push(i)
        }
      },
      getPrevAndNextPages() {
        // get the index of the first number in pageNumbers array to set the last page number for previous page numbers
        let indexOfFirstNumberInPageNumber = this.pageNumbers.find(pn => pn == this.navigablePageNumber[0])
        let indexOfLastNumberInPageNumber = this.pageNumbers.find(pn => pn == this.navigablePageNumber[this.navigablePageNumber.length-1])

        // get the next page numbers outside from navigablePageNumber
        let nextPageNumbers = this.pageNumbers.slice(indexOfLastNumberInPageNumber, indexOfLastNumberInPageNumber+6)

        let startOfNumber = null
        // if indexOfFirstNumberInPageNumber exists, start the prevPageNumbers by it. If not, start from 0
        if(this.pageNumbers[indexOfFirstNumberInPageNumber-7]) {
          startOfNumber = indexOfFirstNumberInPageNumber-7
        } else {
          startOfNumber = 0
        }
        // get the previous page numbers
        let prevPageNumbers = this.pageNumbers.slice(startOfNumber, indexOfFirstNumberInPageNumber-1)

        return {
          nextPageNumbers,
          prevPageNumbers
        }
      },
      groupPageNumbers() {
        return this.pageNumbers.reduce((acc, val, i) => {
          let index = Math.floor(i / this.perPageSize)
          let page = acc[index] || (acc[index] = [])
          page.push(val)

          return acc
        }, [])
      },
      groupIndexOfCurrentPage() {
        let newSet = []

        for(let pn of this.pageSets) {
          newSet.push(pn)
          if(pn.find(e => e == this.activePage)) break
        }

        return newSet.length-1
      }
    }
  }
</script>
