import get from "lodash/get";
export default (totalAddons, showAll = false) => {
    let addons = totalAddons.filter(addon => addon.status === 'active' && get(addon, 'item.enabled_in_portal', false) || addon.pivot?.quantity > 0 || showAll),
      buckets = [];
    addons.forEach(addon => {
      let index = buckets.findIndex(bucket => bucket && bucket.type === addon.item_id);
      if (index !== -1) {
        if (buckets[index].hasOwnProperty('addons')) {
          buckets[index].addons.push(addon);
        } else {
          buckets[index].addons = [addon];
        }
      } else {
        buckets.push({
          type: addon.item_id,
          item: addon.item,
          addons: [addon]
        })
      }
    });
    buckets.forEach(bucket => {
      bucket.isActive = bucket.addons.some(addon => addon.pivot?.quantity > 0);
      bucket.subscriptions = bucket.addons
        .filter(addon => addon.pivot?.quantity > 0)
        .map(addon => ({
          quantity: addon.pivot.quantity,
          amount: parseFloat(addon.pivot.amount) / 100,
          unit_price: parseFloat(addon.pivot.unit_price) / 100,
          period_unit: addon.period_unit,
        }));
    });
    return buckets;
  }