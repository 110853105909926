<template>
  <svg class="icon-column-center" xmlns="http://www.w3.org/2000/svg" width="13.5" height="20.51" viewBox="0 0 13.5 20.51">
    <path d="M11.75 11.004H7.5v-1.5h1.25a1.75 1.75 0 0 0 1.75-1.75v-4a1.75 1.75 0 0 0-1.75-1.75H7.5V.75a.75.75 0 0 0-1.5 0v1.254H4.75A1.751 1.751 0 0 0 3 3.754v4a1.751 1.751 0 0 0 1.75 1.75H6v1.5H1.75A1.751 1.751 0 0 0 0 12.754v4a1.751 1.751 0 0 0 1.75 1.75H6v1.256a.75.75 0 0 0 1.5 0v-1.256h4.25a1.75 1.75 0 0 0 1.75-1.75v-4a1.75 1.75 0 0 0-1.75-1.75ZM4.5 7.754v-4a.252.252 0 0 1 .25-.25h4a.251.251 0 0 1 .25.25v4a.25.25 0 0 1-.25.25h-4a.251.251 0 0 1-.25-.25Zm7.5 9a.25.25 0 0 1-.25.25h-10a.251.251 0 0 1-.25-.25v-4a.252.252 0 0 1 .25-.25h10a.251.251 0 0 1 .25.25Z" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-column-center'
  }
</script>
