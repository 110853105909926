<template>
  <svg class="icon-arrow-right-alt" xmlns="http://www.w3.org/2000/svg" width="14.994" height="10.512" viewBox="0 0 14.994 10.512">
    <path d="M14.774 4.726a.75.75 0 010 1.061l-4.506 4.506a.75.75 0 11-1.06-1.06l3.224-3.226H.75a.75.75 0 110-1.5h11.683L9.207 1.281a.75.75 0 011.062-1.06z" fill="rgba(0,0,0,0.9)"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconArrowRightAlt',
  };
</script>
