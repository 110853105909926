var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-google-drive",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "27.582",
        height: "25",
        viewBox: "0 0 27.582 25"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M48.494,96.25c-.372.646-.207,1.166-.83,1.525a4.167,4.167,0,0,1-2.086.558H31.147a4.181,4.181,0,0,1-2.086-.557c-.623-.359-.458-.881-.83-1.526L33.556,90h9.612Zm0,0",
          transform: "translate(-24.571 -73.333)",
          fill: "#1e88e5"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M74.411,20.105c.745,0,1.46-.5,2.083-.137A4.181,4.181,0,0,1,78.021,21.5l7.215,12.5a4.177,4.177,0,0,1,.562,2.085c0,.72-.88,1.44-1.254,2.084l-7.727-2.087-4.808-8.329Zm0,0",
          transform: "translate(-58.215 -19.412)",
          fill: "#fbc02d"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M101.747,90a4.131,4.131,0,0,1-.562,2.083l-2.548,4.41a4.206,4.206,0,0,1-1.382,1.285L92.762,90Zm0,0",
          transform: "translate(-74.165 -73.333)",
          fill: "#e53935"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M13.676,38.162c-.373-.645-1.254-1.364-1.254-2.083a4.178,4.178,0,0,1,.562-2.086L20.2,21.5a4.183,4.183,0,0,1,1.525-1.528c.623-.36,1.339.136,2.084.137l2.4,7.645L21.4,36.075Zm0,0",
          transform: "translate(-12.422 -19.411)",
          fill: "#4caf50"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M21.407,90l-4.493,7.778a4.206,4.206,0,0,1-1.382-1.285l-2.548-4.41A4.131,4.131,0,0,1,12.422,90Zm0,0",
          transform: "translate(-12.422 -73.333)",
          fill: "#1565c0"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M61.569,18.555l-4.486,7.778L52.59,18.555a4.133,4.133,0,0,1,1.806-.548L59.485,18A4.118,4.118,0,0,1,61.569,18.555Zm0,0",
          transform: "translate(-43.292 -18)",
          fill: "#2e7d32"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }