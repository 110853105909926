import { Node } from 'prosemirror-to-html-js';
import get from "lodash/get";
export default class Personalisation extends Node {
  matching () {
    return this.node.type === "personalisation";
  }

  tag () {
    return "";
  }

  text () {
    let label = get(this.node, 'attrs.label', '');
    if ( label.includes('attribute-') ) {
      const attribute = window.Searchie?.attributeValues?.find(a => a.id === parseInt(label.replace(/\D/g, '')))
      if ( !attribute || attribute && attribute.value === undefined ) return '';
      if ( attribute.type === 'boolean' ) return attribute.value ? 'Yes' : 'No';
      if ( attribute.type === 'date' && !attribute.withTime ) return attribute.value.split(" ")[0];
      if ( attribute.type === 'multiple' && attribute.value ) {
        if ( Array.isArray(attribute.value) ) return attribute.value.map(a => a.title).join(", ");
        return attribute.value.title;
      }
      return attribute.value || '';
    }
    return label;
  }
}
