var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-proofread-pending",
      attrs: {
        "data-name": "Proofread Processing",
        xmlns: "http://www.w3.org/2000/svg",
        width: "20.088",
        height: "15.386",
        viewBox: "0 0 20.088 15.386"
      }
    },
    [
      _c(
        "g",
        [
          _c("path", {
            attrs: {
              d:
                "M2220.182,754.091a3.391,3.391,0,0,0,.1-6.781c-.013,0-.026-.008-.039-.009a.217.217,0,0,0-.026,0l-.03,0v.005h0V747.3h0v.005a.884.884,0,0,0-.113.009.579.579,0,0,1,.112-.015,3.4,3.4,0,0,0,0,6.792Zm0-5.292h.008a1.894,1.894,0,1,1-.008,0Z",
              transform: "translate(-2214.423 -747.299)",
              fill: "#939393"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M2227.121,748.8h6.64a.75.75,0,0,0,0-1.5h-6.64a.75.75,0,0,0,0,1.5Z",
              transform: "translate(-2214.423 -747.299)",
              fill: "#939393"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M2234.511,752.311a.75.75,0,0,0-.75-.75H2231a5.752,5.752,0,0,0-2.257-.47l-.017,0h-.031a5.737,5.737,0,0,1-8.51,3.741,5.763,5.763,0,0,0-5.764,5.764v.812a1.287,1.287,0,0,0,1.282,1.279h8.966a1.281,1.281,0,0,0,1.165-.768,5.791,5.791,0,0,0,7.223-8.856h.7A.75.75,0,0,0,2234.511,752.311Zm-10.061,8.875h-8.527v-.592a4.242,4.242,0,0,1,7.016-3.232,5.789,5.789,0,0,0,1.512,3.445Zm4.253,0a4.3,4.3,0,0,1-1.628-8.272,4.245,4.245,0,0,1,1.629-.326h.017a4.3,4.3,0,1,1-.018,8.6Z",
              transform: "translate(-2214.423 -747.299)",
              fill: "#939393"
            }
          }),
          _vm._v(" "),
          _c("pat", {
            attrs: {
              d:
                "M2229.429,756.562v-2.3a.75.75,0,0,0-1.5,0h0V756.9a.751.751,0,0,0,.254.562l1.594,1.407a.75.75,0,0,0,.993-1.124Z",
              transform: "translate(-2214.423 -747.299)",
              fill: "#939393"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }