import {getActiveFilterObjects, getActiveFilters} from "@app2/utils/helpers";

const namespaced = true;

const state = {
  query: '',
  filters: {
    sources: [],
    status: null,
    duration: null,
    date: null,
    tags: [],
    folders: [],
    playlists: [],
    transcription_status: null,
    types: [],
  },
  isActive: false,
  filterKey: 0,
  filterOptions: {
    sources: [
      {
        value: 'local',
        label: 'Uploads'
      },
      {
        value: 'dropbox',
        label: 'Dropbox'
      },
      {
        value: 'extension',
        label: 'Recorded'
      },
      {
        value: 'googledrive',
        label: 'Google Drive'
      },
      {
        value: 'facebook',
        label: 'Facebook'
      },
      {
        value: 'graph',
        label: 'OneDrive'
      },
      {
        value: 'vimeo',
        label: 'Vimeo'
      },
      {
        value: 'zoom',
        label: 'Zoom'
      },
      {
        value: 'wistia',
        label: 'Wistia'
      },
      {
        value: 'podcast',
        label: 'Podcast'
      },
    ],
    status: [
      {
        value: 'processed',
        label: 'Processed'
      },
      {
        value: 'pending',
        label: 'Pending'
      },
      {
        value: 'failed',
        label: 'Failed'
      },
      {
        value: 'raw',
        label: 'Not processed'
      },
    ],
    transcription_status: [
      {
        value: 'transcribed',
        label: 'Transcribed'
      },
      {
        value: 'no-transcription',
        label: 'No Transcription'
      }
    ],
    duration: [
      {
        value: '<20',
        label: 'Short (< 20 minutes)'
      },
      {
        value: '>30',
        label: 'Long (> 30 minutes)'
      },
    ],
    dates: [
      {
        value: 'day',
        label: 'Today'
      },
      {
        value: 'week',
        label: 'Last week'
      },
      {
        value: 'month',
        label: 'Last month'
      },
      {
        value: 'year',
        label: 'Last year'
      },
    ],
    types: [
      {
        category: 'Video',
        options: [
          { value: 'mp3', label: 'MP3' },
          { value: 'mp4', label: 'MP4' },
          { value: 'mpeg', label: 'MPEG' },
        ]
      },
      {
        category: 'Image',
        options: [
          { value: 'jpg', label: 'JPG' },
          { value: 'png', label: 'PNG' },
          { value: 'jpeg', label: 'JPEG' },
        ]
      },
      {
        category: 'Text',
        options: [
          { value: 'txt', label: 'TXT' },
          { value: 'doc', label: 'DOC / DOCX' },
          { value: 'csv', label: 'CSV' },
          { value: 'pdf', label: 'PDF' },
          { value: 'xls', label: 'XLS / XLSX' },
          { value: 'ppt', label: 'PPT / PPTX' },
        ]
      }
    ]
  }
};

const mutations = {
  SET_QUERY(state, payload) {
    state.query = payload;
  },
  SET_FILTERS(state, payload) {
    state.filters = payload;
  },
  SET_FILTER(state, payload) {
    state.filters[payload.key] = payload.value;
  },
  FILTER_KEY(state, payload) {
    state.filterKey++;
  },
  ADD_PLAYLIST(state, playlist) {
    if ( !state.filters.playlists.includes(playlist) ) state.filters.playlists.push(playlist);
  },
  REMOVE_PLAYLIST(state, playlist) {
    state.filters.playlists = state.filters.playlists.filter(f => f !== playlist);
  },
  CLEAR_FILTERS(state) {
    state.filters = JSON.parse(JSON.stringify({
      sources: [],
      status: null,
      duration: null,
      date: null,
      folders: [],
      playlists: [],
      tags: [],
      transcription_status: null,
      types: [],
    }));
  },
  REMOVE_FILTER(state, filter) {
    if (Array.isArray(state.filters[filter.type])) {
      let index = state.filters[filter.type].findIndex(i => i === filter.value);
      state.filters[filter.type].splice(index, 1);
    } else {
      state.filters[filter.type] = null;
    }
  }
};

const actions = {
};

const getters = {
  GET_QUERY: state => {
    return state.query;
  },
  GET_FILTERS: state => {
    let filters = JSON.parse(JSON.stringify(state.filters));
    return getActiveFilters(filters);
  },
  GET_FILTER_OBJECTS: state => {
    let filters = JSON.parse(JSON.stringify(state.filters));
    return getActiveFilterObjects(filters);
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
