<template>
  <svg class="icon-proofread-done-small" xmlns="http://www.w3.org/2000/svg" width="17.708" height="11.75" viewBox="0 0 17.708 11.75"><path d="M2355.692,731.059a4.45,4.45,0,0,0-4.451,4.45v.6a1.148,1.148,0,0,0,1.142,1.14H2359a1.147,1.147,0,0,0,1.139-1.141v-.6A4.45,4.45,0,0,0,2355.692,731.059Zm0,1.5a2.955,2.955,0,0,1,2.951,2.951v.241h-5.9v-.241A2.956,2.956,0,0,1,2355.692,732.558Zm2.95,3.543h0v0Z" transform="translate(-2351.241 -725.5)" fill="currentColor"/><path d="M2355.689,730.906a2.7,2.7,0,1,0,0-5.406v0h0a2.7,2.7,0,0,0,0,5.406Zm0-3.906h0a1.2,1.2,0,1,1-1.2,1.2A1.2,1.2,0,0,1,2355.692,727Z" transform="translate(-2351.241 -725.5)" fill="currentColor"/><path d="M2360.81,727h4.9a.75.75,0,0,0,0-1.5h-4.9a.75.75,0,0,0,0,1.5Z" transform="translate(-2351.241 -725.5)" fill="currentColor"/><path d="M2366.461,729.4a.75.75,0,0,0-.75-.75h-5.146a.75.75,0,0,0,0,1.5h5.146A.75.75,0,0,0,2366.461,729.4Z" transform="translate(-2351.241 -725.5)" fill="currentColor"/><path d="M2368.729,730.3a.749.749,0,0,0-1.06,0l-3.887,3.886-1.3-1.3a.751.751,0,0,0-1.061,1.062h0l1.834,1.834a.752.752,0,0,0,1.061,0l4.416-4.417A.75.75,0,0,0,2368.729,730.3Z" transform="translate(-2351.241 -725.5)" fill="currentColor"/></svg>

</template>

<script>
  export default {
    name: 'IconProofreadDoneSmall'
  }
</script>
