var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-audience-view",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.305",
        height: "12.563",
        viewBox: "0 0 17.305 12.563"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M8.66 3.103a3.187 3.187 0 103.187 3.187A3.187 3.187 0 008.66 3.103zm0 4.874a1.688 1.688 0 111.687-1.687A1.689 1.689 0 018.66 7.977z",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M16.437 8.773a4 4 0 00-.009-4.99 10.06 10.06 0 00-1.546-1.566 9.873 9.873 0 00-14 1.565 4 4 0 000 5 9.761 9.761 0 00.71.8 9.857 9.857 0 0014.055.05l.007-.006a10.993 10.993 0 00.772-.836c.006.001.005-.009.011-.017zm-1.827-.2a8.392 8.392 0 01-11.972-.037 8.261 8.261 0 01-.6-.687v-.006a2.487 2.487 0 010-3.111 8.405 8.405 0 0111.926-1.336 8.557 8.557 0 011.319 1.336 2.495 2.495 0 010 3.115v.012a9.605 9.605 0 01-.655.708z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }