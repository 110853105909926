var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-doc",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "15.968",
        height: "18.58",
        viewBox: "0 0 15.968 18.58"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M500.1,1090.789l-2.878-2.876a3.7,3.7,0,0,0-2.62-1.084h-6.352v0a3.033,3.033,0,0,0-3.033,3.036v12.51h0a3.034,3.034,0,0,0,3.034,3.034h9.9a3.034,3.034,0,0,0,3.034-3.034v-8.966A3.7,3.7,0,0,0,500.1,1090.789Zm-.414,11.586a1.539,1.539,0,0,1-1.535,1.535h-9.9a1.536,1.536,0,0,1-1.534-1.535h0v-12.51a1.535,1.535,0,0,1,1.533-1.537H494.6a2.214,2.214,0,0,1,1.56.646l2.878,2.876a2.207,2.207,0,0,1,.646,1.559Z",
          transform: "translate(-485.213 -1086.829)",
          fill: "#293ad3"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M489.731,1093.083H495.4a.75.75,0,0,0,0-1.5h-5.666a.75.75,0,0,0,0,1.5Z",
          transform: "translate(-485.213 -1086.829)",
          fill: "#293ad3"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M489.731,1095.769h4.141a.75.75,0,0,0,0-1.5h-4.141a.75.75,0,0,0,0,1.5Z",
          transform: "translate(-485.213 -1085.829)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }