var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 21 21" } },
    [
      _c("g", [
        _c("g", [
          _c("circle", {
            attrs: { fill: "#0079c1", cx: "10.5", cy: "10.5", r: "9" }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#e4e4e4",
              d:
                "M10.5,0A10.5,10.5,0,1,0,21,10.5,10.5,10.5,0,0,0,10.5,0Zm6.36,16.86A9,9,0,0,1,6.21,18.41a9.09,9.09,0,0,1-3.62-3.62,9,9,0,0,1-.12-8.34A9,9,0,0,1,6.21,2.59,9,9,0,0,1,19.5,10.5,9,9,0,0,1,16.86,16.86Z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#fff",
              d:
                "M17,4.25,10.46,8.42,4,4.28A9.16,9.16,0,0,0,2.14,7.19l5.11,3.27L2.12,13.75A8.91,8.91,0,0,0,4,16.66l6.5-4.16L17,16.69a9,9,0,0,0,1.85-2.9l-5.2-3.33,5.17-3.33A9.05,9.05,0,0,0,17,4.25Z"
            }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }