var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "b-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c("form-control-text", {
            attrs: {
              state: _vm.$v.form.email.$error ? false : null,
              id: "loginEmail",
              label: "Email",
              placeholder: "Enter email address...",
              required: "",
              type: "email"
            },
            on: {
              change: function($event) {
                return _vm.$v.form.email.$touch()
              }
            },
            model: {
              value: _vm.form.email,
              callback: function($$v) {
                _vm.$set(_vm.form, "email", $$v)
              },
              expression: "form.email"
            }
          }),
          _vm._v(" "),
          _c(
            "b-btn",
            {
              staticClass: "my-4",
              attrs: {
                disabled: _vm.$v.$invalid || _vm.loading,
                block: "",
                type: "submit",
                variant: "primary"
              }
            },
            [_vm._v("Send reset email")]
          ),
          _vm._v(" "),
          _c(
            "b-alert",
            {
              staticClass: "mb-3",
              attrs: { show: !!_vm.error, fade: "", variant: "error" }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "alert-icon icon-sm",
                  attrs: { width: "24", viewBox: "0 0 24 24" }
                },
                [
                  _c("use", {
                    attrs: { "xlink:href": "#icon-status-error-color" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "alert-content" }, [
                _c("span", { staticClass: "txt-body" }, [
                  _vm._v(_vm._s(_vm.error) + " "),
                  _c(
                    "a",
                    {
                      staticClass: "alert-link",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onReset()
                        }
                      }
                    },
                    [_vm._v("Try again?")]
                  )
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "b-alert",
            {
              staticClass: "mb-0",
              attrs: { variant: "error", show: !!_vm.error && _vm.showError }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "alert-icon icon-sm",
                  attrs: { width: "24", viewBox: "0 0 24 24" }
                },
                [
                  _c("use", {
                    attrs: { "xlink:href": "#icon-status-error-color" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "alert-content" }, [
                _c("span", { staticClass: "txt-body" }, [
                  _vm._v(_vm._s(_vm.error))
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c("b-alert", { attrs: { show: "", fade: "", variant: "info" } }, [
            _c(
              "svg",
              {
                staticClass: "alert-icon icon-sm",
                attrs: { width: "24", viewBox: "0 0 24 24" }
              },
              [
                _c("use", {
                  attrs: { "xlink:href": "#icon-status-question-color" }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "alert-content" }, [
              !_vm.prefilled
                ? _c("p", { staticClass: "txt-body mb-3" }, [
                    _c("b", [
                      _vm._v(
                        "Enter the email address you used when you signed up and we’ll send you instructions to reset your password."
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("p", { staticClass: "txt-body" }, [
                _vm._v(
                  "For security reasons, we do NOT store your password. So rest assured that we will never send your password via email."
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "b-btn",
            {
              staticClass: "my-4",
              attrs: { disabled: _vm.loading, block: "", variant: "secondary" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "login" })
                }
              }
            },
            [
              _c(
                "svg",
                { staticClass: "btn-left", attrs: { viewBox: "0 0 24 24" } },
                [_c("use", { attrs: { "xlink:href": "#icon-arrow-left" } })]
              ),
              _vm._v("\n      Back to Login\n    ")
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "mt-2 txt-body txt-center txt-muted auth-page-cta" },
            [
              _c("b", [_vm._v("Don’t have an account?")]),
              _vm._v(" "),
              _c(
                "b-btn",
                { attrs: { variant: "link ml-1", to: { name: "Register" } } },
                [_vm._v("Sign Up for free!")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "txt-heading txt-center mb-4 pb-4" }, [
      _c("b", [_vm._v("Forgot Password?")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }