<template>
  <svg class="icon-menu-playlists" xmlns="http://www.w3.org/2000/svg" width="28" height="23" viewBox="0 0 28 23">
    <rect width="28" height="23" fill="#fff" opacity="0"/>
    <path d="M58.162,130.14H40.91a3.367,3.367,0,0,0-3.369,3.367v9.257a3.368,3.368,0,0,0,3.369,3.367H58.162a3.366,3.366,0,0,0,3.367-3.367v-9.257A3.366,3.366,0,0,0,58.162,130.14Zm1.868,12.624a1.871,1.871,0,0,1-1.868,1.867H40.91a1.871,1.871,0,0,1-1.869-1.867v-9.257a1.872,1.872,0,0,1,1.869-1.868H58.162a1.871,1.871,0,0,1,1.868,1.868Z" transform="translate(-35.541 -123.134)" fill="#293ad3"/>
    <path d="M41.672,128.637H57.4a.75.75,0,1,0,0-1.5H41.672a.75.75,0,1,0,0,1.5Z" transform="translate(-35.541 -123.134)" fill="#293ad3"/>
    <path d="M43.976,125.634H55.1a.75.75,0,0,0,0-1.5H43.976a.75.75,0,0,0,0,1.5Z" transform="translate(-35.541 -123.134)" fill="#293ad3"/>
    <path class="cl-3" d="M51.586,136.483h0L49.078,135.1a1.923,1.923,0,0,0-.795-.226v0l-.161-.017H48.1a1.915,1.915,0,0,0-1.9,1.871v2.808a1.88,1.88,0,0,0,.26.94l.007.012a1.953,1.953,0,0,0,2.6.691l0,0,2.513-1.389h0a1.9,1.9,0,0,0,.736-.707l.02-.035.007-.012.01-.019a1.875,1.875,0,0,0-.77-2.529Zm-.717,1.984-2.521,1.394-.216.056a.452.452,0,0,1-.379-.211l-.051-.183V136.76a.414.414,0,0,1,.412-.406h.015l.215.054,0,0,2.514,1.385.011.006a.374.374,0,0,1,.2.333l-.045.178,0,.006Z" transform="translate(-35.541 -123.134)" fill="#7b88ff"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconMenuPlaylists',
  };
</script>
