class UserPilotManager {
    constructor() {
      this.mutationObserver = null;
    }
  
    unmount() {
      if (this.mutationObserver) {
        this.mutationObserver.disconnect();
      }
    }
  
    mount() {
      const handleMutations = (mutationsList) => {
        for (let mutation of mutationsList) {
          if (mutation.type === 'childList') {
            for (let node of mutation.addedNodes) {
              if (node.nodeName === 'IFRAME' && node.id === 'userpilot-survey') {
                setTimeout(() => {
                  this.injectSurveyCustomCSS(node.id);
                }, 100);
              } else if (node.nodeName === 'IFRAME' && node.id === 'userpilot-checklist') {
                setTimeout(() => {
                  this.injectChecklistCustomCSS(node.id);
                }, 100);
              }
            }
          }
        }
      };
  
      const observer = new MutationObserver(handleMutations);
      const observerConfig = { childList: true, subtree: true };
      observer.observe(document.body, observerConfig);
      this.mutationObserver = observer;
    }
  
    injectSurveyCustomCSS(id) {
      const element = document.getElementById(id);
      if (element) {
        const iframeDocument = element.contentDocument || element.contentWindow.document;
        const style = iframeDocument.createElement('style');
        style.type = 'text/css';
        const css = `
          /* Userpilot survey */
          .survey-module { height: auto !important; max-height: 100%; bottom: 0; position: absolute !important;}
          .survey-module .survey-title { font-size: 17px; letter-spacing: .3px; font-weight: 700; line-height: 1.6;}
          .survey-module .survey-subtitle { line-height: 1.6; font-size: 17px; color: #bbb;}
          .survey-module .submit-button { font-size: 14px; letter-spacing: .4px; height: 48px; width: 100%; margin-top: 16px;}
          .survey-module .submit-button.disabled { background: #E4E4E4; opacity: 1; color: #bbb;}
          .survey-module .choices-container { margin-bottom: 0; border: none; padding: 10px 0 10 35px;}
          .survey-module .choices-container:hover,.survey-module .choices-container.active, .choices-container.active, .survey-module .choices-container.active:hover { background-color: transparent !important; border: none !important;}
          .survey-module .choices-container span i { display: block; font-size: 14px; color: #bbb; line-height: 1.6; font-style: normal; transition: .2s;}
          .survey-module .choices-container span { font-size: 17px; color: #fff; letter-spacing: .3px; transition: .2s; line-height: 1.6;}
          .survey-module .choices-container.active span i, .survey-module .choices-container:hover span i { color: #BDCFFF;}
          .survey-module .choices-container.active span, .survey-module .choices-container:hover span { color: #5581F4;}
          .survey-module .choices-container .checkmark { top: 24px; left: 0; height: 24px; width: 24px; transition: .2s; border: 1px solid #E4E4E4; filter: drop-shadow(0px 0px 0px rgba(50, 101, 235, 0));}
          .survey-module .choices-container:hover .checkmark { border-color: #5581F4; box-shadow: 0px 0px 0px 4px rgba(50, 101, 235, 0.2);}
          .survey-module .choices-container input:checked ~ .checkmark { background-color: #5581F4;}
          .survey-module .choices-container input:checked ~ .checkmark path { stroke: #fff !important;}
          .survey-module .choices-container input ~ .checkmark svg { width: 12px; height: 9px;}
        `;
        style.appendChild(iframeDocument.createTextNode(css));
        iframeDocument.head.appendChild(style);
      }
    }
  
    injectChecklistCustomCSS(id) {
      const element = document.getElementById(id);
      if (element) {
        const iframeDocument = element.contentDocument || element.contentWindow.document;
        const style = iframeDocument.createElement('style');
        style.type = 'text/css';
        const css = `
          /* Userpilot checklist */
          .main-container div.container:not(.controller) { border-radius: 8px;}
          .main-container div.progress { padding: 0; background: #e4e4e4;}
          .main-container div.progress-width { height: 4px;}
          .main-container .controller.container { border-radius: 8px !important; width: auto !important;}
          .main-container .controller.container .controller { font-size: 14px !important; padding: 4px !important; font-weight: 700; letter-spacing: .4px;}
          .main-container div.experience-title { font-size: 16px; letter-spacing: .3px; line-height: 1.4;}
          .main-container div.experience-status { margin-bottom: auto;}
          .main-container div.experience-status > span { width: 20px; min-width: 20px; height: 20px;}
          .main-container div.experience-label { line-height: 1.4;}
          .main-container div.experience-action { padding-left: 30px;}
          .main-container div.button { font-size: 12px; padding: 9px; border-radius: 6px;}
          .main-container div.experiences > .task-item.experience::after { opacity: .2;}
          .main-container div.dismiss-popup-container .dismiss-prompt { font-size: 17px; font-weight: 600; line-height: 1.6;}
          .main-container div.dismiss-popup-container .dismiss-buttons { padding-bottom: 24px; column-gap: 8px;}
          .main-container div.dismiss-popup-container .dismiss-buttons > * { flex: 1 0 0; font-size: 12px; padding: 7px 16px; border: none !important;}
          .main-container div.footer { padding: 16px 24px 16px; text-align: left;}
        `;
        style.appendChild(iframeDocument.createTextNode(css));
        iframeDocument.head.appendChild(style);
      }
    }
  }
  
  export default UserPilotManager;
  