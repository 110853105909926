import Base from '@app2/models/Base';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import Page from "@app2/models/Page";
import Vue from 'vue';

export default class Hub extends Base {
  static entity = 'App\\Hub';

  static apiConfig = {
    baseURL: '/api/internal/v2/hubs/',
    actions: {
      fetch: {
        url: '/',
        dataKey: 'data',
      },
      validateDomain(hubHash, domain) {
        return this.post(`${hubHash}/validate-domain`, {domain}, {save:false})
      },
      createPage(hubHash, title) {
        return this.post(`${hubHash}/pages`, {title}, {save: false});
      },
      getDefaultContent(hubHash) {
        return this.get(`${hubHash}/default-content`, {
          dataKey: 'data',
        });
      },
      duplicate(hub, title) {
        return this.post(`${hub}/duplicate`, {
          title: title
        })
      },
      copyFromTemplate(endpoint, title) {
        return this.post(endpoint, {
          title: title
        })
      },
      duplicatePage(hub, page, title) {
        return this.post(`${hub}/pages/${page}/duplicate`, { title })
      },
      snapshot(hubHash, params, cancelToken) {
        const config = {
          dataKey: 'data',
          params,
        }
        if (cancelToken) config.cancelToken = cancelToken
        return this.get(`${hubHash}/analytics/snapshot`, config)
      },
      attachAttributes(hubHash, attributes, cancelToken) {
        const config = {
          dataKey: 'data',
          attributes,
        }
        if (cancelToken) config.cancelToken = cancelToken
        return this.post(`${hubHash}/attributes`, config)
      },
      getAttributes(hubHash, params, cancelToken) {
        const config = {
          dataKey: 'data',
          params,
        }
        if (cancelToken) config.cancelToken = cancelToken
        return this.get(`${hubHash}/attributes`, config)
      },
    }
  };

  watchFields = [
    'title',
    'description',
    'menu_items',
    'theme',
    'auth',
    'onboarding',
    'onboard',
    'domain',
    'meta',
    'favicon_url',
    'hub_products',
    'hub_prices',
    'payment_gateway_id',
    'custom_login_logo_url',
    // 'custom_logo_url',
    'social_image_url',
    'esp',
    'hub_switcher_hashes'
  ]

  static fields() {
    return {
      id: this.attr(null),
      hash: this.attr(null),
      team: this.attr(null),
      team_id: this.attr(null),
      user_id: this.attr(null),
      title: this.attr(null),
      domain: this.attr(null),
      description: this.attr(null),
      hub_template_url: this.attr(null),
      pages: this.hasMany(Page, 'hub_id'),
      dashboard: this.hasOne(Page, 'hub_id'),
      hub_switchers: this.attr([]),
      page: this.attr([]),
      sections: this.attr([]),
      email_templates: this.attr([]),
      audiences_count: this.attr(0),
      menu_items: this.attr({
        header: [],
        footer: []
      }),
      hub_products: this.attr([]),
      hub_prices: this.attr([]),
      current_theme_id: this.attr(null),
      social_image_url: this.attr(null),
      custom_logo_url: this.attr(null),
      custom_login_logo_url: this.attr(null),
      favicon_url: this.attr(null),
      payment_gateway_id: this.attr(null),
      payment_gateway: this.attr(null),
      theme: this.attr(null),
      meta: this.attr({}),
      auth: this.attr(null),
      onboarding: this.attr(null),
      onboard: this.attr({
        checked_set_privacy: false,
        has_customize_branding: false,
        has_content: false,
        has_domain: false,
        has_payments: false,
        has_audience: false,
      }), // for hub dashboard onboarding...
      hasAccess: this.attr(null),
      esp: this.attr({
        is_enabled: 0,
        mail_host: null,
        mail_port: null,
        mail_encryption: null,
        mail_username: null,
        mail_password: null,
        mail_from_name: null,
        mail_from_email: null,
        mail_reply_to: null,
      }), // for custom email configuration...
      hub_switcher_hashes: this.attr([]),
      ...super.fields(),
    };
  }
  static mutators() {
    return {
      meta(value) {
        if ( !value || Array.isArray(value) ) return {};
        return value;
      },
      hub_prices(value) {
        if ( !value || !Array.isArray(value) ) return [];
        return value;
      },
      description(value) {
        if ( !value ) return '';
        return value;
      },
      esp(value) {
        if ( !value ) return {
          is_enabled: 0,
          mail_host: null,
          mail_port: null,
          mail_encryption: null,
          mail_username: null,
          mail_password: null,
          mail_from_name: null,
          mail_from_email: null,
          mail_reply_to: null,
        };
        return value;
      },
      theme(value) {
        if ( !value ) return {settings: {}};
        if ( !value.settings || Array.isArray(value.settings) && !value.settings.length ) value.settings = {};
        return value;
      },
      pages(value) {
        if (value && Array.isArray(value) && value.length) {
          value.forEach(p => {
            if ( !p.settings || Array.isArray(p.settings)) p.settings = {};
            p.elements = [
              {
                id: "content",
                title: "Content",
                toggleable: false,
                show: true,
                fillable: true
              }
            ];
          })
        }
        return value;
      },
      sections(value) {
        if ( value && Array.isArray(value) && value.length ) {
          value.forEach(s => {
            if ( !s ) return;
            if ( !s.settings || Array.isArray(s.settings)) s.settings = {};
            if (s.blocks && s.blocks.length) {
              s.blocks.forEach(b => {
                if ( !b.settings || Array.isArray(b.settings)) b.settings = {};
              })
            }
          });
        }
        return value;
      },
      menu_items(value) {
        if ( !value ) value = {footer: [], header: []};
        function setSettings(menu) {
          for (let i = 0; i < value[menu].length; i++) {
            if ( !value[menu][i].settings || Array.isArray(value[menu][i].settings)) value[menu][i].settings = {};
          }
        }
        setSettings('footer');
        setSettings('header');
        return value;
      },
    };
  }

  get isPrivate() {
    return this.auth;
  }

  get hasRegistration() {
    return get(this, `meta.registration.enabled`, false);
  }

  get hasPayments() {
    return get(this, `meta.payments.enabled`, false);
  }

  get canAddLinksInEmails() {
    return get(this, `esp.is_enabled`, false) && get(this, `esp.mail_host`, null);
  }

  get hasMemberDirectory() {
    return get(this, `meta.memberDirectory.enabled`, false);
  }

  // this works for hub.sections, hub.sections.blocks and hub.menu_items
  modelTitle(payload) {
    return payload.title || get(payload, 'model.title') || this.typeTitle(payload.type);
  }

  modelDescription(payload) {
    return get(payload, 'settings.description') || get(payload, 'model.description');
  }

  typeTitle(type) {
    switch (type) {
      case 'cta-grid':
        return 'Call to Action Grid';
      case 'cta':
        return 'Call to Action';
      case 'featured':
        return 'Feature';
      case 'url':
        return 'URL';
      case 'carousel':
        return 'Carousel';
      case 'carousel-cta':
        return 'URL';
      case 'carousel-playlist':
        return 'Playlist';
      case 'carousel-file':
        return 'File';
      case 'carousel-page':
        return 'Page';
      case 'text':
        return 'Paragraph';
      case 'row':
        return 'Columns';
      case 'line-break':
        return 'Line Break';
      case 'grid-playlist':
        return 'Playlist';
      case 'grid-file':
        return 'File';
      case 'grid-page':
        return 'Page';
      case 'grid-url':
        return 'URL';
      case 'paragraph':
        return 'Paragraph';
    }
    return capitalize(type.replace('-', ' '));
  }

  get hideSearchOnHome() {
    if (!this.sections || this.sections && !Array.isArray(this.sections)) return false;
    return this.sections.reduce(function (carry, section) {
      return get(section, 'settings.hideSearchOnHome', false) || carry;
    }, false);
  }
  get withProgress() {
    return get(
      this,
      `theme.settings.progress.enabled`,
      false
    );
  }
}
