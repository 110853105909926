var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "78.732",
        height: "99.246",
        viewBox: "0 0 78.732 99.246"
      }
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "linear-gradient",
                x1: "0.5",
                x2: "0.5",
                y2: "1",
                gradientUnits: "objectBoundingBox"
              }
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#e4e4e4" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#e4e4e4",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Group_19278",
            "data-name": "Group 19278",
            opacity: "0.7"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "Path_634",
              "data-name": "Path 634",
              d:
                "M1790,863.842a4.22,4.22,0,0,1-4.2,4.2h-70.326a4.217,4.217,0,0,1-4.208-4.2V857.5a39.366,39.366,0,0,1,78.732,0Z",
              transform: "translate(-1711.27 -768.795)",
              fill: "url(#linear-gradient)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_635",
              "data-name": "Path 635",
              d:
                "M1733.289,810.249a18.815,18.815,0,1,1-18.825,18.824A18.812,18.812,0,0,1,1733.289,810.249Z",
              transform: "translate(-1693.921 -810.249)",
              fill: "#e4e4e4"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }