import axios from "@app2/api/axios";
import File from "@app2/models/File";
import Folder from "@app2/models/Folder";
const namespaced = true;

const state = {
  isModalOpen: false,
  query: '',
  files: {
    loading: false,
    total: 0,
    page: 1,
    list: [],
    lastPage: null,
    isComplete: false,
  },
  folders: {
    loading: false,
    total: 0,
    page: 1,
    list: [],
    lastPage: null,
    isComplete: false,
  },
};

const mutations = {
  SET_MODAL_STATE(state, payload) {
    state.isModalOpen = payload
  },
  SET_QUERY(state, payload) {
    state.query = payload
  },
  SET_PROPERTY(state, { key, property, value }) {
    state[key][property] = value
  },
  ADD_TO_LIST(state, { key, value}) {
    state[key].list.push(value)
  },
  RESET_FILES(state) {
    state.files = {
      loading: false,
      total: 0,
      page: 1,
      list: [],
      lastPage: null,
      isComplete: false,
    }
  },
  RESET_FOLDERS(state) {
    state.folders = {
      loading: false,
      total: 0,
      page: 1,
      list: [],
      lastPage: null,
      isComplete: false,
    }
  },
};

const actions = {
  async getFiles({ state, commit, rootGetters }, reset = true) {
    if ( reset ) {
      commit('RESET_FILES');
      commit('RESET_FOLDERS');
    }
    try {
      commit('SET_PROPERTY', { key: 'files', property: 'loading', value: true });
      const {data} = await axios.get(`/api/internal/v2/search/files_es`, {
        params: {
          query: state.query || '*',
          fields: ['title'],
          include: ['tags', 'filePlaylists'],
          page: state.page,
        }
      });
      commit('SET_PROPERTY', { key: 'files', property: 'total', value: data.data[0]?.total_hits ?? 0 });
      commit('SET_PROPERTY', { key: 'folders', property: 'total', value: data.folder_data[0]?.total_hits ?? 0 });
      if (data.data.length) {
        commit('SET_PROPERTY', { key: 'files', property: 'page', value: state.files.page + 1 });
        data.data.forEach(item => {
          if ( !item._source.hash && item._source.hash_value ) item._source.hash = item._source.hash_value;
          if ( !item._source?.thumbnail || item._source?.thumbnail === '' ) item._source.thumbnail = null;
          if ( !item._source?.thumbnail_url || item._source?.thumbnail_url === '' ) item._source.thumbnail_url = null;
          if ( !item._source?.thumb_url || item._source?.thumb_url === '' ) item._source.thumb_url = null;
          commit('ADD_TO_LIST', { key: 'files', value: item });
        });
        commit('SET_PROPERTY', { key: 'files', property: 'lastPage', value: 1 });
      }
      if (data.folder_data.length) {
        commit('SET_PROPERTY', { key: 'folders', property: 'page', value: state.folders.page + 1 });
        data.folder_data.forEach(item => {
          item._type = 'folders';
          commit('ADD_TO_LIST', { key: 'folders', value: item });
        });
        commit('SET_PROPERTY', { key: 'folders', property: 'lastPage', value: 1 });
      }
    } catch (e) {
      throw e;
    } finally {
      commit('SET_PROPERTY', { key: 'files', property: 'loading', value: false });
      commit('SET_PROPERTY', { key: 'folders', property: 'loading', value: false });
    }
  },
  async getFolders({ state, commit, rootGetters }, reset) {
    if ( reset ) {
      commit('RESET_FOLDERS');
    }
    commit('SET_PROPERTY', { key: 'folders', property: 'loading', value: false });
    try {
      const {data} = await axios.get(`/api/internal/v2/search/folders`, {
        params: {
          query: state.query || '*',
          page: state.folders.page,
        }
      });
      await Folder.insertOrUpdate({ data: data.data });
      Folder.query().whereIdIn(data.data.map(f=>f.id)).get().forEach(f => {
        commit('ADD_TO_LIST', { key: 'folders', value: f });
      });
      commit('SET_PROPERTY', { key: 'folders', property: 'total', value: data.meta.total });
      if (data.data.length) {
        commit('SET_PROPERTY', { key: 'folders', property: 'page', value: state.files.page + 1 });
        Folder.query().whereIdIn(data.data.map(f=>f.hash)).get().forEach(f => {
          commit('ADD_TO_LIST', { key: 'folders', value: f });
        });
        commit('SET_PROPERTY', { key: 'folders', property: 'lastPage', value: data.meta.last_page });
      }
      if (!data.links.next || !data.data.length) {
        commit('SET_PROPERTY', { key: 'folders', property: 'complete', value: true });
      }
    } catch (e) {
      throw e;
    } finally {
      commit('SET_PROPERTY', { key: 'folders', property: 'loading', value: false });
    }
  },
  updateQuery({ commit }) {
    commit('SET_QUERY', '');
  }
};

const getters = {
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
