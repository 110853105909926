<template>
  <b-modal
    class="person-slide-in"
    id="export-dialog"
    modal-class="modal-right"
    static
    ref="modal"
    hide-footer
    hide-header
    v-model="isModalOpen"
  >
    <div id="export-app"></div>
  </b-modal>
</template>

<script>
  import App from '@app2/modules/Audience/Export/export.app';
  import router from "@app2/modules/Audience/Export/export.router"

  export default {
    name: 'AudienceExport',
    computed: {
      isModalOpen: {
        get() { return this.$store.state.v2.audience.exports.isModalOpen },
        set(val) { return this.$store.commit('v2/audience/exports/SET_MODAL_STATE', val) },
      }
    },
    mounted() {
      this.app = App();
      this.$nextTick(() => {
        if ( !router.currentRoute.name ) router.push({name: 'RecentExports'});
      });
    },
  }
</script>
