var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-view-list",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "13",
        height: "9",
        viewBox: "0 0 13 9"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(-11 -13)" } }, [
        _c("rect", {
          attrs: {
            width: "13",
            height: "3",
            rx: "1.5",
            transform: "translate(11 13)",
            fill: "currentColor"
          }
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            width: "13",
            height: "3",
            rx: "1.5",
            transform: "translate(11 19)",
            fill: "currentColor"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }