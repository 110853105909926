var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("form", { attrs: { role: "form" } }, [
      _c(
        "div",
        [
          _c("label", { staticClass: "form-control-label txt-body" }, [
            _vm._v("What is your profession?")
          ]),
          _vm._v(" "),
          _c(
            "multiselect",
            {
              staticClass: "mb-3",
              attrs: {
                options: [
                  "Coach / Consultant",
                  "Sales / Marketing Professional",
                  "Podcaster",
                  "Education Professional",
                  "Other"
                ],
                openDirection: "bottom",
                maxHeight: 172,
                required: "",
                placeholder: "Choose"
              },
              on: { close: _vm.checkValid },
              scopedSlots: _vm._u([
                {
                  key: "singleLabel",
                  fn: function(props) {
                    return [_c("span", [_vm._v(_vm._s(props.option))])]
                  }
                },
                {
                  key: "caret",
                  fn: function(ref) {
                    var toggle = ref.toggle
                    return [
                      _c(
                        "svg",
                        {
                          staticClass: "icon-regular caret",
                          attrs: { viewBox: "0 0 24 24" },
                          on: {
                            mousedown: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return toggle($event)
                            }
                          }
                        },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "#icon-chevron-down" }
                          })
                        ]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.form.selected_industry,
                callback: function($$v) {
                  _vm.$set(_vm.form, "selected_industry", $$v)
                },
                expression: "form.selected_industry"
              }
            },
            [
              _c("span", {
                attrs: { slot: "placeholder" },
                slot: "placeholder"
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("label", { staticClass: "form-control-label txt-body" }, [
            _vm._v("What is your company size? (if applicable)")
          ]),
          _vm._v(" "),
          _c(
            "multiselect",
            {
              staticClass: "mb-3",
              attrs: {
                maxHeight: 172,
                required: "",
                options: [
                  "1",
                  "2-5",
                  "6-20",
                  "21-50",
                  "51-100",
                  "101-150",
                  "150-250",
                  "250+"
                ],
                placeholder: "Choose"
              },
              on: { close: _vm.checkValid },
              scopedSlots: _vm._u([
                {
                  key: "singleLabel",
                  fn: function(props) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(props.option == 1 ? "Just Me" : props.option)
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "option",
                  fn: function(props) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(props.option == 1 ? "Just Me" : props.option)
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "caret",
                  fn: function(ref) {
                    var toggle = ref.toggle
                    return [
                      _c(
                        "svg",
                        {
                          staticClass: "icon-regular caret",
                          attrs: { viewBox: "0 0 24 24" },
                          on: {
                            mousedown: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return toggle($event)
                            }
                          }
                        },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "#icon-chevron-down" }
                          })
                        ]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.form.employees,
                callback: function($$v) {
                  _vm.$set(_vm.form, "employees", $$v)
                },
                expression: "form.employees"
              }
            },
            [
              _c("span", {
                attrs: { slot: "placeholder" },
                slot: "placeholder"
              })
            ]
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "onboarding-cta pt-4 my-4" },
      [_vm._t("back"), _vm._v(" "), _vm._t("action")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }