import Vue from 'vue';
import Router from '@plugins/VueRouter/index';
import store from "@app2/store";

const Create = () => import(/* webpackChunkName: "v2-person" */ '@app2/modules/Audience/Person/Pages/Create')
const Show = () => import(/* webpackChunkName: "v2-person" */ '@app2/modules/Audience/Person/Pages/Show')
const Activity = () => import(/* webpackChunkName: "v2-person" */ '@app2/modules/Audience/Person/Pages/Activity')

Vue.use(Router);

const router = new Router({
  mode: 'abstract',
  name: 'audience-person',
  routes: [
    {
      path: '/create',
      name: 'Create',
      component: Create,
    },
    {
      path: '/show',
      name: 'Show',
      component: Show,
    },
    {
      path: '/activity',
      name: 'Activity',
      component: Activity,
    },
  ],
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  store.commit('v2/audience/person/SET_BG_VARIANT', (to.name === 'Create' || to.name === 'Show') ? 'white' : '');
  next();
});

export default router;
