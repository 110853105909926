import { Model } from '@vuex-orm/core';
import { timestamps } from '@app/utils/model-fields';

export default class Connection extends Model {
  static entity = 'App\\Connection';

  static apiConfig = {
    baseURL: '/api/connections/'
  };

  static fields() {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      type: this.attr(null),
      source_id: this.attr(null),
      title: this.attr(null),
      ...timestamps,
    }
  }
}
