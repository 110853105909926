import Base from '@app2/models/Base';

export default class WisdomSession extends Base {
  static entity = 'App\\WisdomSession';
  static primaryKey = 'hash';

  static apiConfig = {
    baseURL: `/`,
    actions: {
      fetch(url, config) {
        return this.get(`${url}`, {
          dataKey: 'data',
          ...config,
        })
      },
    }
  };

  static fields() {
    return {
      id: this.attr(null),
      hash: this.attr(null),
      title: this.attr(null),
      audience_id: this.attr(null),
      team_id: this.attr(null),
      audience: this.attr({}),
      conversations: this.attr([]),
      duration: this.attr(null),
      ...super.fields(),
    };
  }

  static mutators() {
    return {
      
    };
  }
}
