var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon icon-folder",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 20 16"
      }
    },
    [
      _c("path", {
        staticClass: "cl-2",
        attrs: {
          fill: "#939393",
          d:
            "M14.715 6a2.106 2.106 0 0 0-2.11-2.087h-2.043v-.594A2.3 2.3 0 0 0 8.3 1H3.266A2.3 2.3 0 0 0 1 3.315v8.153a2.105 2.105 0 0 0 2.11 2.091h9.5a2.106 2.106 0 0 0 2.11-2.09V6zM3.283 2.5h5a.8.8 0 0 1 .784.816v.594H3.113a2.123 2.123 0 0 0-.61.1v-.693a.8.8 0 0 1 .78-.817zm9.932 8.959a.605.605 0 0 1-.61.6h-9.5a.605.605 0 0 1-.611-.6V6.224a.8.8 0 0 1 .784-.815H12.6a.6.6 0 0 1 .61.6z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }