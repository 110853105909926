var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-edit",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "13.64",
        height: "13.64",
        viewBox: "0 0 13.64 13.64"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M1225.872,557.529v0a2.541,2.541,0,0,0-3.594,0l0,0-7.319,7.317a.753.753,0,0,0-.167.251l-1.763,4.267a.769.769,0,0,0,1,1l4.266-1.766a.757.757,0,0,0,.249-.166l7.319-7.319a2.54,2.54,0,0,0,.005-3.585Zm-1.092,2.5-7.21,7.211-2.412,1,1-2.411,7.213-7.211v0a1,1,0,1,1,1.413,1.415Z",
          transform: "translate(-1212.97 -556.783)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }