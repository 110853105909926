var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "3.013",
        height: "13.058"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            "data-name": "Group 744",
            transform: "rotate(90 1.506 1.507)",
            fill: "#939393"
          }
        },
        [
          _c("circle", {
            attrs: {
              "data-name": "Ellipse 4",
              cx: "1.507",
              cy: "1.507",
              r: "1.507"
            }
          }),
          _c("circle", {
            attrs: {
              "data-name": "Ellipse 5",
              cx: "1.507",
              cy: "1.507",
              r: "1.507",
              transform: "translate(5.022)"
            }
          }),
          _c("circle", {
            attrs: {
              "data-name": "Ellipse 6",
              cx: "1.507",
              cy: "1.507",
              r: "1.507",
              transform: "translate(10.045)"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }