<template>
  <b-modal
    class="playlist-form-slide-in"
    id="playlist-form-dialog"
    modal-class="modal-right"
    static
    ref="modal"
    hide-footer
    hide-header
    @show="init"
    @hide="onHide"
  >
    <div id="hub-playlist-form" v-if="show">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </b-modal>
</template>

<script>
import router from '@app2/modules/Library/Builder/playlist.router';
import { createNamespacedHelpers } from 'vuex';
import rootVue from "@app2/app";
import rootRouter from "@app2/router";
import eventBus from "@app2/core/eventBus";
import Connection from "@app2/models/Connection";
const { mapState: mapPlaylistState, mapActions: mapPlaylistActions } = createNamespacedHelpers('v2/library/builder');

export default {
  name: 'PlaylistFormSlideInDialog',
  data () {
    return {
      show: false,
      mediaEditorHasUnsaved: false,
      canClose: true
    }
  },
  mounted() {
    eventBus.$on('playlist-editor-unsaved', isUnsaved => {
      this.playlistEditorHaveUnsaved = isUnsaved
    });
    eventBus.$on('media-editor-unsaved', isUnsaved => {
      this.mediaEditorHasUnsaved = isUnsaved
    });
    eventBus.$on('close-modal', isUnsaved => {
      this.$bvModal.hide('playlist-form-dialog')
    });
  },
  destroyed () {
    eventBus.$off('playlist-editor-unsaved');
    eventBus.$off('media-editor-unsaved');
  },
  methods: {
    ...mapPlaylistActions(['setPlaylist', 'setSelectedFolders']),
    init() {
      this.show = false
      this.$nextTick(() => {
        this.show = true
        Connection.api().get();

        // On first load of library, this causes upload button to show playlist form instead of select folder...
        // const includesLibrary = this.$route.name && this.$route.name.toLowerCase().includes('library')
        // if (
        //   !includesLibrary &&
        //   router.currentRoute.name !== 'SectionSelectFolder' &&
        //   router.currentRoute.name !== 'MediaPage' &&
        //   router.currentRoute.name !== 'SectionSelectPlaylist'
        // ) {
        //   router.push({ name: 'PlaylistForm' }).catch(err => err);
        // }

        this.setSelectedFolders([]);
        this.playlistEditorHaveUnsaved = false
        this.$store.commit('v2/library/builder/SET_IS_PLAYLIST_MODAL_OPEN', true);
      });
    },
    onHide(e) {
      if(this.mediaEditorHasUnsaved) {
        e.preventDefault();
        eventBus.$emit('media-save-before-close')
        return;
      }
      if ([
        'SectionSelectFolder',
        'SectionSelectMedia',
        'MediaPage',
        'SectionSelectPlaylist',
        'Chapters',
        'Cards',
        'Card',
      ].includes(router.currentRoute.name)
        // && !router.currentRoute.params.noBackRoute
      ) {
        return router.push({ name: 'PlaylistForm' })
      }
      if (!this.playlist) return;
      if (this.playlistEditorHaveUnsaved) {
        e.preventDefault();
        rootVue.$bvModal.show('playlist-form-modal-close-warning');
        return;
      }
      this.$store.commit('v2/audience/segment/SET_SELECT_MODEL', null);
      this.$store.commit('v2/library/builder/SET_IS_PLAYLIST_MODAL_OPEN', false);
    },
    onHidden() {
      if ( !rootRouter.currentRoute.query.file ) return;
      const query = Object.assign({}, rootRouter.currentRoute.query);
      delete query.file;
      delete query.showAi;
      rootRouter.replace({ query });
    }
  },
  watch: {
    showModal(value) {
      if (value) {
        this.init();
      }
    },
  },
  computed: {
    ...mapPlaylistState(['showModal', 'playlist']),
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.value = val;
        if (this.$v && this.$v.model) this.$v.model.$touch();
      },
    },
  },
  props: {
    title: {},
    buttonLabel: {
      type: String,
      default: 'Create',
    },
    placeholder: {
      type: String,
      default: 'Enter Name',
    },
    id: {},
    resourceName: {},
    processing: {},
  },
};
</script>
