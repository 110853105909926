import get from "lodash/get";
import merge from "lodash/merge";

export default (embed) => {
  let settings = {};
  if ( typeof embed.widget === 'object' && Object.entries(embed.widget).length && embed.file ) {
    delete embed.file;
  }
  Object.entries(embed).forEach(level => {
    if ( level[0] === 'widget' ) {
      let overwrite = !!level[1].overwrite;
      if ( overwrite ) {
        settings = merge(settings, level[1]);
      } else {
        settings = merge(settings, {
          colors: {
            accent: get(level[1], 'colors.accent')
          }
        });
      }
    } else {
      settings = merge(settings, level[1]);
    }
  });
  return settings;
}
