<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="220.945" height="73.979" viewBox="0 0 220.945 73.979">
    <defs>
      <linearGradient id="linear-gradient" x1="0.453" y1="-0.588" x2="0.46" y2="1.939" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff"/>
        <stop offset="1" stop-color="#f0f0f0"/>
      </linearGradient>
      <linearGradient id="linear-gradient-2" x1="0.477" y1="0.183" x2="0.479" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff"/>
        <stop offset="0.443" stop-color="#fff"/>
        <stop offset="1" stop-color="#f0f0f0"/>
      </linearGradient>
      <linearGradient id="linear-gradient-3" x1="0.148" y1="0.776" x2="0.829" y2="1.605" xlink:href="#linear-gradient-2"/>
      <linearGradient id="linear-gradient-4" x1="32.851" y1="333.03" x2="32.927" y2="332.919" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#f4f4f4"/>
        <stop offset="0.367" stop-color="#f4f4f4"/>
        <stop offset="0.592" stop-color="#f4f4f4"/>
        <stop offset="1" stop-color="#fff"/>
      </linearGradient>
      <linearGradient id="linear-gradient-5" x1="0.411" y1="0" x2="1.052" y2="2.146" xlink:href="#linear-gradient-2"/>
    </defs>
    <g id="Dog_sleeping" data-name="Dog sleeping" transform="translate(0.75)">
      <path id="Union_38" data-name="Union 38" d="M17539.008,3715.24l7.432-5.056h37.215s8.117-.552,11.164,5.056C17578.664,3715.173,17539.008,3715.24,17539.008,3715.24Zm-66.006-.066s.83-5.542,15.908-5.542h42.25v5.542Zm17.486-31.166c-.006-.539.088-1.1.775-1.219.98-.172,2.605.618,3.877,1.378a43.6,43.6,0,0,1,7.473,5.6l-9.33,3.012A25.071,25.071,0,0,1,17490.488,3684.007Z" transform="translate(-17416.502 -3642.511)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill="url(#linear-gradient)"/>
      <path id="Path_2456" data-name="Path 2456" d="M864.152,301.336c7.445,0,10.561-1.862,8.913-6.758-5.01-14.869-19.616-20.267-31.9-20.983-9.7-.565-15,1.554-20.878,4.106l-3.025-1.555a24.247,24.247,0,0,0-4.911-3.587,14.821,14.821,0,0,0-11.854-.945c-5.917,1.908-6.983,4.029-9.439,6.477-1.512,1.509-2.567,3.576-4.508,4.491-2.158,1.014-12.4.45-13.047,2.729a4.929,4.929,0,0,0,1.188,4.177c6.042,7.885,19.158,11.781,19.158,11.781Z" transform="translate(-715.878 -228.608)" fill-rule="evenodd" fill="url(#linear-gradient-2)"/>
      <path id="Path_2531" data-name="Path 2531" d="M834.271,301.248a7.043,7.043,0,0,1,6.673-4.968h7.562c-6.412-8.85-.039-18.592,7-19.1,20.255-1.464,17.43,24.037,17.43,24.037Z" transform="translate(-714.794 -228.52)" fill-rule="evenodd" fill="url(#linear-gradient-3)"/>
      <path id="Path_2458-2" data-name="Path 2458-2" d="M814.485,278.033a49.768,49.768,0,0,0-7.8-9.207c-1.351-1.251-5.138-4.233-5.661,1.3a36.507,36.507,0,0,0,.888,9.967,25.446,25.446,0,0,0,6.487,0A17,17,0,0,0,814.485,278.033Z" transform="translate(-715.388 -228.662)" fill-rule="evenodd" fill="url(#linear-gradient-4)"/>
      <path id="Path_2459" data-name="Path 2459" d="M816.718,276.074a18.262,18.262,0,0,1,3.418,1.386s-3.174,13.865-11.908,23.8h-4.084C813.884,290.048,816.718,276.074,816.718,276.074Z" transform="translate(-715.331 -228.534)" fill="#cb1a1a" fill-rule="evenodd"/>
      <path id="Path_2532" data-name="Path 2532" d="M789.574,283.772a8.526,8.526,0,0,1,4.784,2.221" transform="translate(-715.591 -228.427)" fill="none" stroke="rgba(11,11,11,0.9)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      <path id="Path_2533" data-name="Path 2533" d="M773.505,285.06a4.935,4.935,0,0,0-.037,1.463,22.313,22.313,0,0,0,4.843-1.9,1.231,1.231,0,0,0,.372-1.318C776.084,283.576,773.8,284.023,773.505,285.06Z" transform="translate(-715.878 -228.433)" fill="#707070" fill-rule="evenodd"/>
      <path id="Path_2534" data-name="Path 2534" d="M782.366,301.083s.83-5.641,15.914-5.641h16.59c-.068-1.751.705-5.705,3.917-6.452a8.727,8.727,0,0,1,10.366,8.594v3.5Z" transform="translate(-715.718 -228.356)" fill-rule="evenodd" fill="url(#linear-gradient-5)"/>
      <g id="Group_7118" data-name="Group 7118" transform="translate(68.181 69.928)" opacity="0.3">
        <path id="Path_1687-5" data-name="Path 1687-5" d="M785.887,298.157a5.6,5.6,0,0,0-2.014,1.958" transform="translate(-783.873 -298.154)" fill="#f7f7f7" stroke="#939393" stroke-linecap="round" stroke-miterlimit="4.002" stroke-width="1.001"/>
        <path id="Path_1688-5" data-name="Path 1688-5" d="M788.16,298.154a4.895,4.895,0,0,0-1.776,1.714" transform="translate(-783.828 -298.154)" fill="#f7f7f7" stroke="#939393" stroke-linecap="round" stroke-miterlimit="4.002" stroke-width="1.001"/>
      </g>
      <g id="Group_7119" data-name="Group 7119" transform="translate(121.034 69.457)" opacity="0.3">
        <path id="Path_1687-5-2" data-name="Path 1687-5-2" d="M837.814,297.7a6.387,6.387,0,0,0-2.013,2.426" transform="translate(-835.801 -297.69)" fill="#f7f7f7" stroke="#939393" stroke-linecap="round" stroke-miterlimit="4.002" stroke-width="1.001"/>
        <path id="Path_1688-5-2" data-name="Path 1688-5-2" d="M840.088,297.69a5.6,5.6,0,0,0-1.777,2.13" transform="translate(-835.756 -297.69)" fill="#f7f7f7" stroke="#939393" stroke-linecap="round" stroke-miterlimit="4.002" stroke-width="1.001"/>
      </g>
      <path id="Path_2528" data-name="Path 2528" d="M791.924,248.373l-.63-1.914,5.92-1.936.629,1.912-1.987,5.231,3.35-1.1.629,1.914-5.919,1.938-.63-1.914,1.989-5.229Z" transform="translate(-716.207 -229.725)" fill="rgba(0,0,0,0.9)"/>
      <path id="Path_2529" data-name="Path 2529" d="M808.919,252.722l.266-1.311,4.071.822-.266,1.311-2.884,2.378,2.3.465-.267,1.312-4.071-.822.267-1.311,2.883-2.379Z" transform="translate(-715.26 -228.878)" fill="rgba(0,0,0,0.9)"/>
      <path id="Path_2530" data-name="Path 2530" d="M804.252,230.212l.758-1.023,3.174,2.332-.758,1.023-3.436.894,1.8,1.321-.756,1.021-3.174-2.332.756-1.021,3.438-.9Z" transform="translate(-715.371 -229.189)" fill="rgba(0,0,0,0.9)"/>
      <path id="Path_9371" data-name="Path 9371" d="M478.591,0H698.036" transform="translate(-478.591 73.229)" fill="none" stroke="#e4e4e4" stroke-linecap="round" stroke-width="1.5"/>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconPuppy'
  }
</script>