var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-arrow-right-sm",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "6.159",
        height: "10.818",
        viewBox: "0 0 6.159 10.818"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(6.159 0) rotate(90)" } }, [
        _c("path", {
          attrs: {
            d:
              "M341.6,392.877l-4.659-4.658a.75.75,0,0,0-1.061,0l-4.658,4.658a.751.751,0,1,0,1.062,1.061l4.127-4.127,4.128,4.127a.75.75,0,1,0,1.061-1.061Z",
            transform: "translate(-330.999 -387.999)",
            fill: "rgba(0,0,0,0.9)"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }