var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "148",
        height: "148",
        viewBox: "0 0 148 148",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M127.965 130.975C104.34 117.159 85.7111 105.605 62.0959 91.7801C61.8658 91.648 61.6106 91.5653 61.3468 91.5371C61.0829 91.5089 60.8161 91.5359 60.5632 91.6164C60.3104 91.6968 60.0771 91.829 59.8781 92.0045C59.679 92.18 59.5187 92.395 59.4072 92.6358L58.5918 94.5677C58.3997 94.9975 58.373 95.483 58.5165 95.9313C58.66 96.3797 58.9637 96.7594 59.3696 96.9979L125.151 135.943C125.369 136.07 125.611 136.152 125.862 136.185C126.113 136.217 126.368 136.199 126.612 136.132C126.856 136.065 127.084 135.949 127.283 135.793C127.481 135.636 127.647 135.442 127.769 135.22L128.7 133.531C128.929 133.092 128.981 132.581 128.844 132.106C128.707 131.63 128.392 131.225 127.965 130.975Z",
          stroke: "currentColor",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M80.9121 55.6328L73.0332 59.1266",
          stroke: "currentColor",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M61.5781 47.1641L65.2107 55.7776",
          stroke: "currentColor",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M70.0938 67.1953L74.0422 75.6042",
          stroke: "currentColor",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M62.126 64.3772L54.3192 68.2461",
          stroke: "currentColor",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M35.2344 91.3633L29.8906 92.4299",
          stroke: "currentColor",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M28.334 102.785L27.0714 97.0113",
          stroke: "currentColor",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M25.4336 89.3359L24.4088 83.5527",
          stroke: "currentColor",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M22.3423 93.691L16.8991 94.5375",
          stroke: "currentColor",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M69.0018 102.674L71.1584 97.9369",
          stroke: "currentColor",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M47.3592 116.758C47.1452 117.481 46.5266 120.959 46.6225 121.704C46.7184 122.449 46.9615 123.171 47.359 123.812C47.7565 124.453 48.008 124.524 48.6338 124.957C49.2595 125.39 51.8821 126.302 52.6338 126.457C48.3148 125.568 44.6206 129.252 43.3627 132.258C44.4758 129.611 42.062 124.333 38.6337 122.957C46.3623 124.957 46.9731 118.323 47.3592 116.758Z",
          stroke: "currentColor",
          "stroke-width": "4",
          "stroke-miterlimit": "10",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M51.4938 100.605C53.1815 102.829 55.4267 104.191 57.4988 104.604",
          stroke: "currentColor",
          "stroke-width": "3.46856",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }