<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="social" width="37.917" height="32.207" viewBox="0 0 37.917 32.207">
    <path d="M9.473,26.168l9.485-6.03,9.473,6.03-9.473,6.04Zm9.485-8.048,9.473-6.04,9.485,6.04-9.485,6.04ZM0,18.11l9.473-6.03L0,6.04,9.473,0l9.485,6.04L9.473,12.08l9.485,6.03L9.473,24.15ZM18.959,6.04,28.432,0l9.485,6.04-9.485,6.04Z" transform="translate(0)" fill="#0061ff"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-dropbox'
  }
</script>
