const namespaced = true;

const state = {
  isModalOpen: false
};

const mutations = {
  SET_MODAL_STATE(state, payload) {
    state.isModalOpen = payload
  },
};

const actions = {};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
