<template>
	<svg width="163" height="25" viewBox="0 0 163 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1.12292 11.842H2.68492C3.61992 11.842 4.19192 11.325 4.19192 10.522C4.19192 9.697 3.61992 9.169 2.68492 9.169H1.12292V11.842ZM2.88292 12.81H1.12292V16H0.0449219V8.201H2.88292C4.32392 8.201 5.29192 9.202 5.29192 10.511C5.29192 11.831 4.32392 12.81 2.88292 12.81Z" fill="url(#paint0_linear_1845_6415)"/>
		<path d="M8.45262 15.241C9.33262 15.241 10.1026 14.581 10.1026 13.349C10.1026 12.128 9.33262 11.468 8.45262 11.468C7.57262 11.468 6.80262 12.128 6.80262 13.349C6.80262 14.581 7.57262 15.241 8.45262 15.241ZM8.45262 10.544C10.0366 10.544 11.1586 11.743 11.1586 13.349C11.1586 14.966 10.0366 16.165 8.45262 16.165C6.86862 16.165 5.74662 14.966 5.74662 13.349C5.74662 11.743 6.86862 10.544 8.45262 10.544Z" fill="url(#paint1_linear_1845_6415)"/>
		<path d="M15.5073 10.709H16.5743L17.9493 14.691L19.1153 10.709H20.2043L18.4883 16H17.4213L16.0133 11.974L14.6383 16H13.5493L11.8113 10.709H12.9443L14.1323 14.691L15.5073 10.709Z" fill="url(#paint2_linear_1845_6415)"/>
		<path d="M21.9716 12.81H24.8646C24.8426 12.051 24.3476 11.457 23.4126 11.457C22.5436 11.457 22.0156 12.128 21.9716 12.81ZM25.0186 14.185L25.9096 14.493C25.6126 15.428 24.7656 16.165 23.5336 16.165C22.1146 16.165 20.8716 15.131 20.8716 13.338C20.8716 11.677 22.0706 10.544 23.4016 10.544C25.0296 10.544 25.9426 11.666 25.9426 13.316C25.9426 13.448 25.9316 13.58 25.9206 13.646H21.9386C21.9606 14.592 22.6426 15.252 23.5336 15.252C24.3916 15.252 24.8206 14.779 25.0186 14.185Z" fill="url(#paint3_linear_1845_6415)"/>
		<path d="M30.1762 10.654V11.754C30.0222 11.732 29.8682 11.721 29.7252 11.721C28.8562 11.721 28.2732 12.183 28.2732 13.349V16H27.2392V10.709H28.2512V11.633C28.6362 10.819 29.2962 10.621 29.8352 10.621C29.9782 10.621 30.1212 10.643 30.1762 10.654Z" fill="url(#paint4_linear_1845_6415)"/>
		<path d="M31.8867 12.81H34.7797C34.7577 12.051 34.2627 11.457 33.3277 11.457C32.4587 11.457 31.9307 12.128 31.8867 12.81ZM34.9337 14.185L35.8247 14.493C35.5277 15.428 34.6807 16.165 33.4487 16.165C32.0297 16.165 30.7867 15.131 30.7867 13.338C30.7867 11.677 31.9857 10.544 33.3167 10.544C34.9447 10.544 35.8577 11.666 35.8577 13.316C35.8577 13.448 35.8467 13.58 35.8357 13.646H31.8537C31.8757 14.592 32.5577 15.252 33.4487 15.252C34.3067 15.252 34.7357 14.779 34.9337 14.185Z" fill="url(#paint5_linear_1845_6415)"/>
		<path d="M37.8693 13.338C37.8693 14.427 38.4303 15.23 39.4203 15.23C40.3663 15.23 40.9493 14.405 40.9493 13.316C40.9493 12.227 40.3773 11.479 39.4313 11.479C38.4853 11.479 37.8693 12.249 37.8693 13.338ZM40.9603 15.285V15.197C40.7073 15.703 40.1463 16.143 39.3213 16.143C37.7703 16.143 36.8133 14.911 36.8133 13.338C36.8133 11.842 37.8253 10.566 39.3213 10.566C40.2563 10.566 40.7623 11.028 40.9383 11.457V8.036H41.9613V15.021C41.9613 15.527 42.0053 15.912 42.0163 16H41.0153C40.9933 15.879 40.9603 15.604 40.9603 15.285Z" fill="url(#paint6_linear_1845_6415)"/>
		<path d="M47.437 16H46.414V8.036H47.437V11.457C47.69 10.984 48.306 10.555 49.186 10.555C50.792 10.555 51.617 11.787 51.617 13.327C51.617 14.9 50.726 16.143 49.153 16.143C48.372 16.143 47.767 15.802 47.437 15.23V16ZM50.561 13.327C50.561 12.183 49.956 11.468 48.999 11.468C48.086 11.468 47.426 12.183 47.426 13.327C47.426 14.471 48.086 15.219 48.999 15.219C49.945 15.219 50.561 14.471 50.561 13.327Z" fill="url(#paint7_linear_1845_6415)"/>
		<path d="M54.2152 18.211H53.0822L54.4022 15.417L52.1252 10.709H53.3132L54.9632 14.361L56.5472 10.709H57.6582L54.2152 18.211Z" fill="url(#paint8_linear_1845_6415)"/>
		<path d="M70.294 4.83537C70.294 4.21423 70.4579 3.60173 70.7684 3.06686C71.3982 1.97124 72.5758 1.29403 73.8396 1.29403C74.4564 1.29403 75.0689 1.45794 75.6038 1.76851L79.8525 4.22286L73.969 7.66499C72.8993 8.29907 72.5499 9.67937 73.184 10.7491C73.8094 11.8016 75.1552 12.1596 76.2206 11.5643L78.882 10.0029C79.9561 9.38174 81.3321 9.74838 81.9532 10.8267C82.5743 11.9051 82.2077 13.2811 81.1293 13.9022L70.4234 20.0834C70.3457 19.7815 70.294 19.4795 70.294 19.1646V4.83537ZM90.4291 9.58447C90.0064 8.85119 89.3939 8.23868 88.6606 7.81165L76.2508 0.647017C75.4917 0.207045 74.6592 0 73.8396 0C72.166 0 70.5441 0.867002 69.6469 2.41553C69.2242 3.15313 68.9999 3.98562 68.9999 4.83537V19.1646C68.9999 19.7038 69.0862 20.2387 69.263 20.752V20.7563C69.9618 22.7534 71.8339 24.0043 73.831 24.0043C74.3615 24.0043 74.9007 23.918 75.427 23.7326C75.7116 23.6334 75.9877 23.5083 76.2465 23.3573L88.6563 16.1927C90.9726 14.8512 91.7663 11.8965 90.4291 9.58447Z" fill="url(#paint9_linear_1845_6415)"/>
		<path d="M96.8419 14.7502L98.054 13.3052C98.8261 13.9221 99.6931 14.2456 100.599 14.2456C101.181 14.2456 101.496 14.0428 101.496 13.7064V13.6848C101.496 13.3613 101.237 13.1801 100.172 12.9343C98.5026 12.5504 97.2128 12.0802 97.2128 10.467V10.4454C97.2128 8.98748 98.3689 7.935 100.254 7.935C101.587 7.935 102.631 8.29302 103.485 8.97885L102.398 10.5144C101.682 10.0098 100.892 9.74233 100.198 9.74233C99.6715 9.74233 99.4127 9.96663 99.4127 10.247V10.2686C99.4127 10.6266 99.6801 10.7862 100.771 11.0321C102.579 11.4246 103.7 12.0069 103.7 13.4778V13.4993C103.7 15.1039 102.432 16.0572 100.526 16.0572C99.128 16.0486 97.8081 15.6129 96.8419 14.7502Z" fill="url(#paint10_linear_1845_6415)"/>
		<path d="M106.036 8.066H112.351V9.91646H108.189V11.107H111.096V12.8194H108.189V14.066H112.407V15.9165H106.036V8.066Z" fill="url(#paint11_linear_1845_6415)"/>
		<path d="M118.986 12.8186L118.111 10.5885L117.226 12.8186H118.986ZM117.08 8.00908H119.176L122.519 15.9156H120.186L119.612 14.5138H116.584L116.023 15.9156H113.737L117.08 8.00908Z" fill="url(#paint12_linear_1845_6415)"/>
		<path d="M127.72 11.8359C128.462 11.8359 128.885 11.4779 128.885 10.9042V10.8827C128.885 10.2659 128.436 9.95097 127.707 9.95097H126.284V11.8359H127.72ZM124.11 8.066H127.824C129.023 8.066 129.855 8.38088 130.381 8.92006C130.843 9.36866 131.076 9.97685 131.076 10.749V10.7705C131.076 11.9697 130.438 12.7677 129.463 13.1817L131.335 15.9165H128.824L127.241 13.5398H126.288V15.9165H124.114V8.066H124.11Z" fill="url(#paint13_linear_1845_6415)"/>
		<path d="M132.524 12.014V11.9925C132.524 9.70635 134.284 7.91196 136.661 7.91196C138.265 7.91196 139.296 8.58486 139.991 9.55107L138.352 10.8192C137.903 10.2585 137.385 9.90046 136.635 9.90046C135.535 9.90046 134.763 10.8322 134.763 11.9752V11.9968C134.763 13.1744 135.535 14.0931 136.635 14.0931C137.454 14.0931 137.937 13.7135 138.408 13.1399L140.047 14.3045C139.305 15.3268 138.308 16.0773 136.57 16.0773C134.331 16.073 132.524 14.3606 132.524 12.014Z" fill="url(#paint14_linear_1845_6415)"/>
		<path d="M141.6 8.066H143.774V11.0035H146.565V8.066H148.743V15.9165H146.565V12.9316H143.774V15.9165H141.6V8.066Z" fill="url(#paint15_linear_1845_6415)"/>
		<path d="M153.651 8.066H151.464V15.9165H153.651V8.066Z" fill="url(#paint16_linear_1845_6415)"/>
		<path d="M156.164 8.066H162.479V9.91646H158.316V11.107H161.224V12.8194H158.316V14.066H162.535V15.9165H156.164V8.066Z" fill="url(#paint17_linear_1845_6415)"/>
		<defs>
			<linearGradient id="paint0_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
			<linearGradient id="paint1_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
			<linearGradient id="paint2_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
			<linearGradient id="paint3_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
			<linearGradient id="paint4_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
			<linearGradient id="paint5_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
			<linearGradient id="paint6_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
			<linearGradient id="paint7_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
			<linearGradient id="paint8_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
			<linearGradient id="paint9_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
			<linearGradient id="paint10_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
			<linearGradient id="paint11_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
			<linearGradient id="paint12_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
			<linearGradient id="paint13_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
			<linearGradient id="paint14_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
			<linearGradient id="paint15_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
			<linearGradient id="paint16_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
			<linearGradient id="paint17_linear_1845_6415" x1="120.269" y1="16.8547" x2="11.2407" y2="11.7761" gradientUnits="userSpaceOnUse"><stop stop-color="#88A2FF"/><stop offset="1" stop-color="#E68FEE"/></linearGradient>
		</defs>
	</svg>


</template>
<script>
  export default {
    name: 'IconPowerSearchieCopilot',
  };
</script>
