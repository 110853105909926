var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "281.903",
        height: "221.799",
        viewBox: "0 0 281.903 221.799"
      }
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "linear-gradient",
                x1: "0.5",
                x2: "0.5",
                y2: "1",
                gradientUnits: "objectBoundingBox"
              }
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#e4e4e4" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#e4e4e4",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "linear-gradient-4",
                x1: "0.477",
                y1: "0.183",
                x2: "0.479",
                y2: "1",
                gradientUnits: "objectBoundingBox"
              }
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#fff" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.443", "stop-color": "#fff" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#f0f0f0" } })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "linear-gradient-5",
                x1: "32.851",
                y1: "333.03",
                x2: "32.927",
                y2: "332.919",
                gradientUnits: "objectBoundingBox"
              }
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#f4f4f4" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.367", "stop-color": "#f4f4f4" }
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.592", "stop-color": "#f4f4f4" }
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#fff" } })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Empty_Audience_illustration",
            "data-name": "Empty Audience illustration",
            transform: "translate(-718 -411.451)"
          }
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Group_17916",
                "data-name": "Group 17916",
                transform: "translate(-2.802 -81.503)",
                opacity: "0.7"
              }
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Group_17913",
                    "data-name": "Group 17913",
                    transform: "translate(801.803 492.953)"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      id: "Path_634",
                      "data-name": "Path 634",
                      d:
                        "M1813.206,877.313a5.464,5.464,0,0,1-5.437,5.437h-91.052a5.46,5.46,0,0,1-5.448-5.437V869.1a50.968,50.968,0,0,1,101.936,0Z",
                      transform: "translate(-1711.27 -754.254)",
                      fill: "url(#linear-gradient)"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_635",
                      "data-name": "Path 635",
                      d:
                        "M1738.837,810.249a24.36,24.36,0,1,1-24.373,24.371A24.357,24.357,0,0,1,1738.837,810.249Z",
                      transform: "translate(-1687.866 -810.249)",
                      fill: "#e4e4e4"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "g",
                {
                  attrs: {
                    id: "Group_17917",
                    "data-name": "Group 17917",
                    transform: "translate(720.803 596.316)"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      id: "Path_634-2",
                      "data-name": "Path 634",
                      d:
                        "M1766.906,850.433a2.982,2.982,0,0,1-2.967,2.967h-49.7a2.98,2.98,0,0,1-2.973-2.967v-4.482a27.818,27.818,0,0,1,55.636,0Z",
                      transform: "translate(-1711.27 -783.268)",
                      fill: "url(#linear-gradient)"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_635-2",
                      "data-name": "Path 635",
                      d:
                        "M1727.767,810.249a13.3,13.3,0,1,1-13.3,13.3A13.294,13.294,0,0,1,1727.767,810.249Z",
                      transform: "translate(-1699.947 -810.249)",
                      fill: "#e4e4e4"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "g",
                {
                  attrs: {
                    id: "Group_17918",
                    "data-name": "Group 17918",
                    transform: "translate(928.803 573.29)"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      id: "Path_634-3",
                      "data-name": "Path 634",
                      d:
                        "M1785.173,861.038a3.961,3.961,0,0,1-3.942,3.941H1715.22a3.958,3.958,0,0,1-3.949-3.941v-5.954a36.951,36.951,0,0,1,73.9,0Z",
                      transform: "translate(-1711.27 -771.821)",
                      fill: "url(#linear-gradient)"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_635-3",
                      "data-name": "Path 635",
                      d:
                        "M1732.134,810.249a17.661,17.661,0,1,1-17.67,17.669A17.659,17.659,0,0,1,1732.134,810.249Z",
                      transform: "translate(-1695.181 -810.249)",
                      fill: "#e4e4e4"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "g",
            {
              attrs: {
                id: "Dog_sleeping",
                "data-name": "Dog sleeping",
                transform: "translate(809 558.939)"
              }
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Group_7120",
                    "data-name": "Group 7120",
                    transform: "translate(0 38.234)"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      id: "Path_2458",
                      "data-name": "Path 2458",
                      d:
                        "M801.7,275.888a43.208,43.208,0,0,0-7.472-5.6c-1.269-.76-2.894-1.551-3.875-1.378-.691.119-.783.677-.778,1.217a25.133,25.133,0,0,0,2.793,8.772",
                      transform: "translate(-772.087 -266.868)",
                      fill: "#e8e8e8",
                      "fill-rule": "evenodd"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_2455",
                      "data-name": "Path 2455",
                      d:
                        "M772.393,300.926s.831-5.543,15.909-5.543h42.25v5.543Zm73.436-4.991h37.217s8.116-.553,11.161,5.058c-16.149-.066-55.808,0-55.808,0Z",
                      transform: "translate(-772.393 -266.499)",
                      fill: "#e8e8e8",
                      "fill-rule": "evenodd"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_2456",
                      "data-name": "Path 2456",
                      d:
                        "M864.152,301.336c7.445,0,10.561-1.862,8.913-6.758-5.01-14.869-19.616-20.267-31.9-20.983-9.7-.565-15,1.554-20.878,4.106l-3.025-1.555a24.247,24.247,0,0,0-4.911-3.587,14.821,14.821,0,0,0-11.854-.945c-5.917,1.908-6.983,4.029-9.439,6.477-1.512,1.509-2.567,3.576-4.508,4.491-2.158,1.014-12.4.45-13.047,2.729a4.929,4.929,0,0,0,1.188,4.177c6.042,7.885,19.158,11.781,19.158,11.781Z",
                      transform: "translate(-772.375 -266.842)",
                      "fill-rule": "evenodd",
                      fill: "url(#linear-gradient-4)"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_2531",
                      "data-name": "Path 2531",
                      d:
                        "M834.271,301.248a7.043,7.043,0,0,1,6.673-4.968h7.562c-6.412-8.85-.039-18.592,7-19.1,20.255-1.464,17.43,24.037,17.43,24.037Z",
                      transform: "translate(-771.291 -266.753)",
                      fill: "#fff",
                      "fill-rule": "evenodd"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_2458-2",
                      "data-name": "Path 2458-2",
                      d:
                        "M814.485,278.033a49.768,49.768,0,0,0-7.8-9.207c-1.351-1.251-5.138-4.233-5.661,1.3a36.507,36.507,0,0,0,.888,9.967,25.446,25.446,0,0,0,6.487,0A17,17,0,0,0,814.485,278.033Z",
                      transform: "translate(-771.884 -266.896)",
                      "fill-rule": "evenodd",
                      fill: "url(#linear-gradient-5)"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_2459",
                      "data-name": "Path 2459",
                      d:
                        "M816.718,276.074a18.262,18.262,0,0,1,3.418,1.386s-3.174,13.865-11.908,23.8h-4.084C813.884,290.048,816.718,276.074,816.718,276.074Z",
                      transform: "translate(-771.828 -266.768)",
                      fill: "#cb1a1a",
                      "fill-rule": "evenodd"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_2532",
                      "data-name": "Path 2532",
                      d: "M789.574,283.772a8.526,8.526,0,0,1,4.784,2.221",
                      transform: "translate(-772.087 -266.661)",
                      fill: "none",
                      stroke: "rgba(0,0,0,0.9)",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "1.5"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_2533",
                      "data-name": "Path 2533",
                      d:
                        "M773.505,285.06a4.935,4.935,0,0,0-.037,1.463,22.313,22.313,0,0,0,4.843-1.9,1.231,1.231,0,0,0,.372-1.318C776.084,283.576,773.8,284.023,773.505,285.06Z",
                      transform: "translate(-772.375 -266.667)",
                      fill: "rgba(0,0,0,0.9)",
                      "fill-rule": "evenodd"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_2534",
                      "data-name": "Path 2534",
                      d:
                        "M782.366,301.083s.83-5.641,15.914-5.641h16.59c-.068-1.751.705-5.705,3.917-6.452a8.727,8.727,0,0,1,10.366,8.594v3.5Z",
                      transform: "translate(-772.215 -266.59)",
                      fill: "#fff",
                      "fill-rule": "evenodd"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Group_7118",
                        "data-name": "Group 7118",
                        transform: "translate(11.684 31.694)"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          id: "Path_1687-5",
                          "data-name": "Path 1687-5",
                          d: "M785.887,298.157a5.6,5.6,0,0,0-2.014,1.958",
                          transform: "translate(-783.873 -298.154)",
                          fill: "#f7f7f7",
                          stroke: "#e8e8e8",
                          "stroke-linecap": "round",
                          "stroke-miterlimit": "4.002",
                          "stroke-width": "1.001"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          id: "Path_1688-5",
                          "data-name": "Path 1688-5",
                          d: "M788.16,298.154a4.895,4.895,0,0,0-1.776,1.714",
                          transform: "translate(-783.828 -298.154)",
                          fill: "#f7f7f7",
                          stroke: "#e8e8e8",
                          "stroke-linecap": "round",
                          "stroke-miterlimit": "4.002",
                          "stroke-width": "1.001"
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Group_7119",
                        "data-name": "Group 7119",
                        transform: "translate(64.537 31.224)"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          id: "Path_1687-5-2",
                          "data-name": "Path 1687-5-2",
                          d: "M837.814,297.7a6.387,6.387,0,0,0-2.013,2.426",
                          transform: "translate(-835.801 -297.69)",
                          fill: "#f7f7f7",
                          stroke: "#e8e8e8",
                          "stroke-linecap": "round",
                          "stroke-miterlimit": "4.002",
                          "stroke-width": "1.001"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          id: "Path_1688-5-2",
                          "data-name": "Path 1688-5-2",
                          d: "M840.088,297.69a5.6,5.6,0,0,0-1.777,2.13",
                          transform: "translate(-835.756 -297.69)",
                          fill: "#f7f7f7",
                          stroke: "#e8e8e8",
                          "stroke-linecap": "round",
                          "stroke-miterlimit": "4.002",
                          "stroke-width": "1.001"
                        }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "Path_2528",
                  "data-name": "Path 2528",
                  d:
                    "M791.924,248.373l-.63-1.914,5.92-1.936.629,1.912-1.987,5.231,3.35-1.1.629,1.914-5.919,1.938-.63-1.914,1.989-5.229Z",
                  transform: "translate(-772.704 -229.725)",
                  fill: "rgba(0,0,0,0.9)"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "Path_2529",
                  "data-name": "Path 2529",
                  d:
                    "M808.919,252.722l.266-1.311,4.071.822-.266,1.311-2.884,2.378,2.3.465-.267,1.312-4.071-.822.267-1.311,2.883-2.379Z",
                  transform: "translate(-771.757 -228.878)",
                  fill: "rgba(0,0,0,0.9)"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "Path_2530",
                  "data-name": "Path 2530",
                  d:
                    "M804.252,230.212l.758-1.023,3.174,2.332-.758,1.023-3.436.894,1.8,1.321-.756,1.021-3.174-2.332.756-1.021,3.438-.9Z",
                  transform: "translate(-771.868 -229.189)",
                  fill: "rgba(0,0,0,0.9)"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_6066",
              "data-name": "Path 6066",
              d: "M478.591,0H698.036",
              transform: "translate(281.909 632.5)",
              fill: "none",
              stroke: "#e8e8e8",
              "stroke-linecap": "round",
              "stroke-width": "1.5"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }