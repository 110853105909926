var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "15.969",
        height: "17.98",
        viewBox: "0 0 15.969 17.98"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Text_File_icon",
            "data-name": "Text File icon",
            transform: "translate(0 0)"
          }
        },
        [
          _c(
            "g",
            { attrs: { id: "Group_20410", "data-name": "Group 20410" } },
            [
              _c("path", {
                attrs: {
                  id: "Path_7936",
                  "data-name": "Path 7936",
                  d:
                    "M488.412,1153.515a3.033,3.033,0,0,0-3.033,3.036v11.91h0a3.034,3.034,0,0,0,3.034,3.033h9.9a3.034,3.034,0,0,0,3.035-3.034v-8.366a3.7,3.7,0,0,0-1.086-2.62l-2.878-2.876a3.705,3.705,0,0,0-2.62-1.084h-6.352m11.436,14.946a1.538,1.538,0,0,1-1.535,1.535h-9.9a1.537,1.537,0,0,1-1.534-1.534h0v-11.91a1.535,1.535,0,0,1,1.533-1.537h6.352a2.209,2.209,0,0,1,1.56.646l2.878,2.876a2.206,2.206,0,0,1,.646,1.559Z",
                  transform: "translate(-485.379 -1153.515)",
                  fill: "#939393"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "Path_7937",
                  "data-name": "Path 7937",
                  d:
                    "M495.563,1158.269H489.9a.75.75,0,0,0,0,1.5h5.666a.75.75,0,0,0,0-1.5Z",
                  transform: "translate(-485.379 -1153.515)",
                  fill: "#939393"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "Path_7938",
                  "data-name": "Path 7938",
                  d:
                    "M494.038,1161.294H489.9a.75.75,0,0,0,0,1.5h4.141a.75.75,0,0,0,0-1.5Z",
                  transform: "translate(-485.379 -1153.515)",
                  fill: "#939393"
                }
              })
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }