var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-processed",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.316",
        height: "13.49",
        viewBox: "0 0 17.316 13.49"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M13.118 3.057a4.182 4.182 0 00-1.32.225 4.933 4.933 0 00-9.581 1.657c0 .087.022.172.027.258A3.2 3.2 0 000 8.244c0 .078 0 .171.012.272v.013a3.126 3.126 0 001.1 2.106 3.435 3.435 0 002.225.817h4.01l-.735.769a.75.75 0 101.085 1.037l1.99-2.083a.749.749 0 00-.024-1.06L7.581 8.124a.75.75 0 00-1.036 1.085l.779.744H3.337a1.943 1.943 0 01-1.251-.46 1.618 1.618 0 01-.583-1.1v-.014l-.006-.133a1.706 1.706 0 011.6-1.7.752.752 0 00.688-.9 3.437 3.437 0 116.768-1.199.75.75 0 001.143.527 2.685 2.685 0 014.119 2.284v.016a2.684 2.684 0 01-2.676 2.682.75.75 0 000 1.5 4.178 4.178 0 004.176-4.181v-.016a4.2 4.2 0 00-4.197-4.202z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }