import AboutStep from '@app2/modules/Onboarding/Components/AboutStep';
import NameStep from '@app2/modules/Onboarding/Components/NameStep';
import PhoneStep from '@app2/modules/Onboarding/Components/PhoneStep';
import LanguageStep from '@app2/modules/Onboarding/Components/LanguageStep';

export default [
  {
    id: 1,
    number: "1",
    title: 'Hey there! What’s your name?',
    component: NameStep,
    label: 'Name'
  },
  {
    id: 2,
    number: "2",
    title: 'What phone number can we reach you at?',
    component: PhoneStep,
    label: 'Contact'
  },
  {
    id: 3,
    number: "3",
    title: ' tell us a bit more about what you do',
    component: AboutStep,
    label: 'Profession'
  },
  {
    id: 4,
    number: "4",
    title: ' please choose a transcription language',
    component: LanguageStep,
    label: 'Language'
  },
];
