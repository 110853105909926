var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-section-carousel",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19.988",
        height: "17.5",
        viewBox: "0 0 19.988 17.5"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M19.988 12.466V5.029a2.434 2.434 0 00-2.385-2.434h-1.5A2.743 2.743 0 0013.369.002H6.615a2.743 2.743 0 00-2.734 2.593h-1.5A2.434 2.434 0 00-.004 5.028v7.436a2.445 2.445 0 002.381 2.442h1.5a2.743 2.743 0 002.734 2.592h6.754a2.743 2.743 0 002.734-2.592h1.5a2.446 2.446 0 002.389-2.44zm-18.488 0V5.027a.935.935 0 01.916-.934h1.451v9.317H2.421a.944.944 0 01-.921-.944zm13.121 2.284a1.252 1.252 0 01-1.25 1.25H6.617a1.252 1.252 0 01-1.25-1.25v-12a1.252 1.252 0 011.25-1.25h6.754a1.252 1.252 0 011.25 1.25zm2.947-1.342h-1.447V4.096h1.451a.935.935 0 01.916.935v7.439a.944.944 0 01-.92.938z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }