var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" } },
    [
      _c("path", {
        attrs: { fill: "#FFF", d: "M19 10H1c0 4.971 4.029 9 9 9s9-4.029 9-9z" }
      }),
      _c("path", {
        attrs: { fill: "red", d: "M10 1a9 9 0 00-9 9h18a9 9 0 00-9-9z" }
      }),
      _c("g", { attrs: { fill: "none" } }, [
        _c("circle", { attrs: { cx: "10", cy: "10", r: "9" } }),
        _c("circle", {
          attrs: { cx: "10", cy: "10", r: "9.5", stroke: "#E4E4E4" }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }