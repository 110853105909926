var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" } },
    [
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M10 1c-1.954 0-3.758.63-5.233 1.688h10.466A8.947 8.947 0 0010 1zM4.767 17.312C6.242 18.37 8.046 19 10 19s3.758-.63 5.233-1.688H4.767z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#0038B8",
          d:
            "M15.233 2.688H4.767A9.03 9.03 0 002.214 5.5h15.572a9.03 9.03 0 00-2.553-2.812zM17.786 14.5H2.214a9.03 9.03 0 002.553 2.812h10.466a9.03 9.03 0 002.553-2.812zM12.127 10l1.063-1.842h-2.127L10 6.315 8.937 8.158H6.809L7.873 10l-1.064 1.842h2.127L10 13.685l1.064-1.843h2.127L12.127 10zm-1.298 1.437H9.171L8.342 10l.829-1.436h1.658L11.658 10l-.829 1.437zm1.064-1.843l-.594-1.03h1.189l-.595 1.03zM10 7.128l.594 1.03H9.406L10 7.128zM8.702 8.564l-.595 1.03-.595-1.03h1.19zm-1.189 2.873l.595-1.03.595 1.03h-1.19zm1.892.405h1.19L10 12.872l-.595-1.03zm2.488-1.436l.598 1.036-1.217.038.619-1.074z"
        }
      }),
      _c("g", { attrs: { fill: "none" } }, [
        _c("circle", { attrs: { cx: "10", cy: "10", r: "9" } }),
        _c("circle", {
          attrs: { cx: "10", cy: "10", r: "9.5", stroke: "#E4E4E4" }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }