<template>
  <svg class="icon-hubs" xmlns="http://www.w3.org/2000/svg" width="18.759" height="17" viewBox="0 0 18.759 16.639">
    <path d="M137.852,236.35h0Z" transform="translate(-131.516 -228.822)" fill="#939393"/>
    <path d="M139.776,240.278l0,0h0Z" transform="translate(-131.516 -228.822)" fill="#939393"/>
    <path d="M140.408,239.671h0l0,.006Z" transform="translate(-131.516 -228.822)" fill="#939393"/>
    <path d="M139.788,237.426h0l-.009-.005h0l-1.928-1.069a1.639,1.639,0,0,0-.664-.2v-.005l-.078-.006-.009,0h0l-.03,0h-.033a1.663,1.663,0,0,0-1.66,1.6v.027l-.008,2.151v.005a1.614,1.614,0,0,0,.221.813l.008.013a1.68,1.68,0,0,0,2.23.6l0,0,1.938-1.063a1.656,1.656,0,0,0,.636-.61l.013-.02.024-.042a1.623,1.623,0,0,0-.657-2.183Zm-.734,1.537-1.937,1.064-.084.021-.149-.083-.014-.05.008-2.121.161-.156h.008l.072.02,0,0,1.937,1.075.009.005.063.108-.014.055-.06.057Z" transform="translate(-131.516 -228.822)" fill="#939393"/>
    <path d="M145.983,238.093h-3.152a.75.75,0,0,0,0,1.5h3.152a.75.75,0,0,0,0-1.5Z" transform="translate(-131.516 -228.822)" fill="#939393"/>
    <path d="M150.275,231.606a2.784,2.784,0,0,0-2.782-2.784H134.3a2.783,2.783,0,0,0-2.784,2.784v11.072a2.782,2.782,0,0,0,2.783,2.782h13.193a2.783,2.783,0,0,0,2.783-2.779V231.606Zm-1.5,11.072v0a1.285,1.285,0,0,1-1.283,1.281H134.3a1.287,1.287,0,0,1-1.284-1.283V234.2h12.97a.75.75,0,0,0,0-1.5h-12.97v-1.092a1.288,1.288,0,0,1,1.285-1.285h13.193a1.285,1.285,0,0,1,1.282,1.284Z" transform="translate(-131.516 -228.822)" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconHubs'
  }
</script>
