var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.43",
        height: "17.081"
      }
    },
    [
      _c("g", { attrs: { "data-name": "Redo Icon" } }, [
        _c("path", {
          attrs: {
            "data-name": "Path 778",
            d:
              "M6.55 3.991h7.294L11.133 1.28a.75.75 0 011.06-1.06l4.016 4.016a.75.75 0 010 1.061l-4.016 4.016a.751.751 0 01-1.062-1.061l2.762-2.761H6.549a5.048 5.048 0 00-5.05 5.04v.006a5.05 5.05 0 005.05 5.045h8.131a.75.75 0 010 1.5H6.549a6.551 6.551 0 01-6.55-6.544v-.008A6.547 6.547 0 016.55 3.991z",
            fill: "#939393"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }