<template>
  <audience-slide-in-template title="Import Audience" ref="tmp">
    <h4 class="txt-heading-small txt-muted mb-3" v-if="!trial">Import your contacts.</h4>
    <div class="side-panel-group-empty" key="empty" v-if="!!trial">
      <illustration-magic-stick/>
      <h4 class="txt-heading txt-bold mt-4">This is an active plan feature.</h4>
      <p class="txt-body txt-muted">Contacts cannot be imported while on a trial account.</p>
      <b-btn variant="primary mt-4" @click.stop="goToSubscription">
        <svg viewBox="0 0 24 24" class="btn-left"><use xlink:href="#icon-lock-locked"></use></svg>
        <b>Activate subscription</b>
      </b-btn>
    </div>
    <div class="card-library mx-0" v-if="!trial">
      <p class="txt-heading-small">Upload CSV</p>
      <input type="file" id="files" class="trigger" @change="parseCSV" ref="upload" @drop="dragging = false" accept=".csv">
      <svg viewBox="0 0 24 24" class="icon-lg ml-auto"><use xlink:href="#icon-cloud-upload"></use></svg>
    </div>
      <!-- <div class="card card-basic m-0" @click="goToCopy">
        <h5 class="pt-0">Copy and paste</h5>
        <span class="txt-hint">Copy and paste contacts from a spreadsheet.</span>
        <icon-arrow-right width="18" class="ml-auto mt-auto mr-2 mb-2"/>
      </div> -->
    <b-alert :show="!trial">
      <svg width="24" class="alert-icon icon-sm" viewBox="0 0 24 24"><use xlink:href="#icon-status-question-color"></use></svg>
      <div class="alert-content">
        <span class="txt-body">You can import contacts from other platforms by exporting them into a CSV file and uploading it.</span>
      </div>
    </b-alert>
    <div class="side-panel-widget mt-auto" v-if="!trial">
        <side-panel-option
          :link="{name: 'RecentImports'}"
          label="Recent Imports"
          description="View Audience you imported earlier."
          icon-left="clock"
          icon-right="arrow-right"
        />
    </div>
  </audience-slide-in-template>
</template>

<script>
  import AudienceSlideInTemplate from "@app2/modules/Audience/Components/Template";
  import SidePanelOption from "@app2/core/Components/Sidepanel/Option";
  import IllustrationMagicStick from "@illustrations/MagicStick";
  import rootRouter from "@app2/router";

  export default {
    name: 'UploadImport',
    components: {
      AudienceSlideInTemplate,
      SidePanelOption,
      IllustrationMagicStick
    },
    data() {
      return {
        dragging: false,
        uploadProgress: null
      }
    },
    computed: {
      trial() {
        return this.$store.getters['auth/GET_TRIAL'];
      },
    },
    methods: {
      goToSubscription() {
        rootRouter.push({name: 'AccountSubscription'});
        this.$refs.tmp.closePanel();
      },
      async parseCSV(e) {
        // const t0 = performance.now()
        const file = event.target.files[0];

        for await (const line of this.readLines(file, 'utf-8', '\n')) {
          const columns = line.split(',').map(col => col.trim().replace(/['"]+/g, ''))
          this.$store.commit('v2/audience/SET_IMPORT_COLUMNS', columns)
          this.$store.commit('v2/audience/SET_IMPORT_DATA', { key: 'file', data: file })
          this.$store.commit('v2/audience/SET_IMPORT_DATA', { key: 'source', data: 'file' })
          this.$router.push({name: 'UploadStep1'})
          // const t1 = performance.now()
          // console.log("Reading header took " + (t1 - t0) + " milliseconds.")

          return; // signals reader.cancel() to the async iterator
        }
      },
      async *readLines (blob, encoding = 'utf-8', delimiter = /\r?\n/g) {
        const reader = blob.stream().getReader();
        const decoder = new TextDecoder(encoding);

        try {
          let text = '';

          while (true) {
            const { value, done } = await reader.read();

            if (done) break;

            text += decoder.decode(value, { stream: true });

            const lines = text.split(delimiter);

            text = lines.pop();
            yield* lines;
          }

          yield text;
        } finally {
          reader.cancel();
        }
      },
      goToCopy() {
        this.$store.commit('v2/audience/SET_IMPORT_DATA', { key: 'source', data: 'txt' })
        this.$router.push({name: 'CopyStep1'})
      }
    }
  }
</script>
