<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="6.16" height="10.819" viewBox="0 0 6.16 10.819">
    <path d="M434.219,408.28l4.128,4.129-4.128,4.128a.751.751,0,0,0,1.062,1.062l4.658-4.66a.75.75,0,0,0,0-1.061l-4.659-4.659a.75.75,0,1,0-1.06,1.062Z" transform="translate(-433.999 -406.999)" fill="#fff"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconRight',
  };
</script>
