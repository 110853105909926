var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-add-person",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.488",
        height: "16.983",
        viewBox: "0 0 17.488 16.983"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M194.061,300.7a3.168,3.168,0,1,0-3.168-3.168A3.169,3.169,0,0,0,194.061,300.7Zm0-4.837a1.669,1.669,0,1,1-1.668,1.669A1.671,1.671,0,0,1,194.061,295.866Z",
          transform: "translate(-187.8 -294.367)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M194.062,301.576h0a6.258,6.258,0,0,0-6.261,6.259v1.242a2.273,2.273,0,0,0,2.269,2.272h7.982a2.271,2.271,0,0,0,2.271-2.271h0v-1.24A6.261,6.261,0,0,0,194.062,301.576Zm4.76,7.5h0a.774.774,0,0,1-.771.772h-7.98a.774.774,0,0,1-.771-.773v-1.242a4.759,4.759,0,0,1,4.761-4.76h0a4.759,4.759,0,0,1,4.76,4.761Z",
          transform: "translate(-187.8 -294.367)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M204.538,299.529H202.8V297.8a.75.75,0,0,0-1.5,0v1.733h-1.733a.75.75,0,1,0,0,1.5H201.3v1.733a.75.75,0,0,0,1.5,0v-1.733h1.733a.75.75,0,0,0,0-1.5Z",
          transform: "translate(-187.8 -294.367)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }