var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-zoom no-fill",
      attrs: {
        viewBox: "0 0 32 32",
        width: "14",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("clipPath", { attrs: { id: "zoom-" + _vm.index + "a" } }, [
        _c("path", { attrs: { d: "M-200-175H800v562H-200z" } })
      ]),
      _c("clipPath", { attrs: { id: "zoom-" + _vm.index + "b" } }, [
        _c("circle", { attrs: { cx: "107", cy: "106", r: "102" } })
      ]),
      _c("clipPath", { attrs: { id: "zoom-" + _vm.index + "c" } }, [
        _c("circle", { attrs: { cx: "107", cy: "106", r: "100" } })
      ]),
      _c("clipPath", { attrs: { id: "zoom-" + _vm.index + "d" } }, [
        _c("circle", { attrs: { cx: "107", cy: "106", r: "92" } })
      ]),
      _c("clipPath", { attrs: { id: "zoom-" + _vm.index + "e" } }, [
        _c("path", {
          attrs: {
            "clip-rule": "evenodd",
            d:
              "M135 94.06l26-19c2.27-1.85 4-1.42 4 2V135c0 3.84-2.16 3.4-4 2l-26-19zM47 77.2v43.2A17.69 17.69 0 0064.77 138h63a3.22 3.22 0 003.23-3.2V91.6A17.69 17.69 0 00113.23 74h-63A3.22 3.22 0 0047 77.2z"
          }
        })
      ]),
      _c("g", { attrs: { transform: "translate(0 -178)" } }, [
        _c("path", { attrs: { d: "M232 61h366v90H232z", fill: "#4a8cff" } })
      ]),
      _c(
        "g",
        { attrs: { transform: "matrix(.15686 0 0 .15686 -.784 -.627)" } },
        [
          _c("g", { attrs: { "clip-path": "url(#zoom-" + _vm.index + "b)" } }, [
            _c("path", { attrs: { d: "M0-1h214v214H0z", fill: "#e5e5e4" } })
          ]),
          _c("g", { attrs: { "clip-path": "url(#zoom-" + _vm.index + "c)" } }, [
            _c("path", { attrs: { d: "M2 1h210v210H2z", fill: "#4a8cff" } })
          ]),
          _c("g", { attrs: { "clip-path": "url(#zoom-" + _vm.index + "d)" } }, [
            _c("path", { attrs: { d: "M10 9h194v194H10z", fill: "#4a8cff" } })
          ]),
          _c("g", { attrs: { "clip-path": "url(#zoom-" + _vm.index + "e)" } }, [
            _c("path", { attrs: { d: "M42 69h128v74H42z", fill: "#fff" } })
          ])
        ]
      ),
      _c(
        "g",
        {
          attrs: {
            "clip-path": "url(#zoom-" + _vm.index + "a)",
            transform: "translate(0 -178)"
          }
        },
        [
          _c("path", {
            attrs: { d: "M232 19.25h180v38.17H232z", fill: "#90908f" }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }