var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return true
    ? _c(
        "svg",
        {
          attrs: {
            height: "49.535",
            viewBox: "0 0 193.034 49.535",
            width: "193.034",
            xmlns: "http://www.w3.org/2000/svg",
            "xmlns:xlink": "http://www.w3.org/1999/xlink"
          }
        },
        [
          _c("g", { attrs: { id: "Logotype" } }, [
            _c("g", { attrs: { "data-name": "Group 22", id: "Group_22" } }, [
              _c("path", {
                attrs: {
                  d:
                    "M631.543,183.166,616.019,174.2l-14.794,8.659a4.645,4.645,0,0,0,4.645,8.044l5.488-3.223a4.642,4.642,0,0,1,6.343,1.7h0a4.646,4.646,0,0,1-1.7,6.345l-24.5,14.148a9.991,9.991,0,0,0,14.422,5.36l25.614-14.789a9.977,9.977,0,0,0,0-17.281Z",
                  "data-name": "Path 16",
                  fill: "#2f29d3",
                  id: "Path_16",
                  transform: "translate(-590.964 -167.039)"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M615.936,194.845a4.646,4.646,0,0,0,1.7-6.345h0a4.642,4.642,0,0,0-6.343-1.7l-5.488,3.223a4.645,4.645,0,0,1-4.645-8.044l14.794-8.659h0l-10.088-5.826a9.976,9.976,0,0,0-14.964,8.64v29.577a10.036,10.036,0,0,0,.541,3.273l0,.008Z",
                  "data-name": "Path 99",
                  fill: "#f4a321",
                  id: "Path_99",
                  transform: "translate(-590.898 -166.156)"
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "g",
              {
                attrs: {
                  "data-name": "Group 62",
                  id: "Group_62",
                  transform: "translate(57.465 16.342)"
                }
              },
              [
                _c(
                  "g",
                  {
                    attrs: {
                      "data-name": "Group 61",
                      id: "Group_61",
                      transform: "translate(0)"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M655.447,198.638l2.5-2.987A8.342,8.342,0,0,0,663.2,197.6c1.2,0,1.852-.417,1.852-1.111v-.046c0-.671-.532-1.042-2.732-1.551-3.449-.788-6.111-1.76-6.111-5.093v-.046c0-3.008,2.385-5.185,6.274-5.185a10.2,10.2,0,0,1,6.666,2.153l-2.245,3.172a8.129,8.129,0,0,0-4.538-1.6c-1.088,0-1.619.462-1.619,1.042v.045c0,.741.556,1.066,2.8,1.575,3.727.809,6.042,2.014,6.042,5.046v.046c0,3.31-2.615,5.277-6.55,5.277A11.315,11.315,0,0,1,655.447,198.638Z",
                        "data-name": "Path 100",
                        id: "Path_100",
                        transform: "translate(-655.447 -184.518)"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d:
                          "M676.766,184.876H689.8v3.82H681.21v2.454h6v3.541h-6v2.569h8.7v3.819H676.766Z",
                        "data-name": "Path 101",
                        id: "Path_101",
                        transform: "translate(-657.787 -184.552)"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d:
                          "M701.5,184.746h4.328l6.9,16.319h-4.815l-1.181-2.893h-6.25l-1.157,2.893H694.6Zm3.935,9.931-1.806-4.607-1.829,4.607Z",
                        "data-name": "Path 102",
                        id: "Path_102",
                        transform: "translate(-659.744 -184.538)"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d:
                          "M718.678,184.876h7.663c2.476,0,4.189.648,5.276,1.759a5.022,5.022,0,0,1,1.436,3.774v.045a5.126,5.126,0,0,1-3.334,4.977l3.865,5.648H728.4l-3.264-4.907h-1.968v4.907h-4.49Zm7.453,7.777c1.528,0,2.407-.741,2.407-1.92v-.047c0-1.273-.926-1.92-2.431-1.92h-2.94v3.888Z",
                        "data-name": "Path 103",
                        id: "Path_103",
                        transform: "translate(-662.386 -184.552)"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d:
                          "M738.178,192.984v-.046a8.324,8.324,0,0,1,8.541-8.425,7.935,7.935,0,0,1,6.875,3.379l-3.379,2.616a4.338,4.338,0,0,0-3.542-1.9,4.007,4.007,0,0,0-3.865,4.283v.045c0,2.431,1.6,4.329,3.865,4.329a4.426,4.426,0,0,0,3.658-1.967l3.379,2.407a8.53,8.53,0,0,1-15.531-4.723Z",
                        "data-name": "Path 104",
                        id: "Path_104",
                        transform: "translate(-664.526 -184.512)"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d:
                          "M759.212,184.876h4.49v6.064h5.764v-6.064h4.49v16.2h-4.49v-6.157H763.7v6.157h-4.49Z",
                        "data-name": "Path 105",
                        id: "Path_105",
                        transform: "translate(-666.834 -184.552)"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M782.091,184.876h4.515v16.2h-4.515Z",
                        "data-name": "Path 106",
                        id: "Path_106",
                        transform: "translate(-669.345 -184.552)"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d:
                          "M792.959,184.876h13.032v3.82H797.4v2.454h6v3.541h-6v2.569h8.7v3.819H792.959Z",
                        "data-name": "Path 107",
                        id: "Path_107",
                        transform: "translate(-670.538 -184.552)"
                      }
                    })
                  ]
                )
              ]
            )
          ])
        ]
      )
    : _c(
        "svg",
        {
          staticClass: "icon-logo",
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            "xmlns:xlink": "http://www.w3.org/1999/xlink",
            x: "0px",
            y: "0px",
            width: "193px",
            height: "49.5px",
            viewBox: "0 0 193 49.5",
            "enable-background": "new 0 0 193 49.5",
            "xml:space": "preserve"
          }
        },
        [
          _c("g", [
            _c("path", {
              attrs: {
                fill: "#2F29D3",
                d:
                  "M40.6,16.1l-15.5-9l-14.8,8.7c-2.2,1.3-2.9,4.2-1.6,6.4s4.1,2.9,6.2,1.7l5.5-3.2c2.2-1.3,5.1-0.5,6.3,1.7\n    \t\tl0,0c1.3,2.2,0.5,5.1-1.7,6.3L0.5,42.8c1.8,5.2,7.5,8,12.7,6.1c0.6-0.2,1.2-0.5,1.7-0.8l25.6-14.8c4.8-2.8,6.4-8.9,3.7-13.6\n    \t\tC43.3,18.3,42.1,17,40.6,16.1L40.6,16.1z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                fill: "#F4A321",
                d:
                  "M25,28.7c2.2-1.3,3-4.1,1.7-6.3s-4.1-3-6.3-1.7l0,0l-5.5,3.2c-2.2,1.2-5.1,0.4-6.3-1.8s-0.5-5,1.7-6.2\n    \t\tl14.8-8.7L15,1.3C10.2-1.4,4.1,0.2,1.3,5C0.5,6.5,0,8.2,0,10v29.6c0,1.1,0.2,2.2,0.5,3.3l0,0L25,28.7z"
              }
            })
          ]),
          _vm._v(" "),
          _c("g", { staticClass: "txt" }, [
            _c("g", [
              _c("path", {
                attrs: {
                  d:
                    "M57.5,30.5l2.5-3c1.5,1.2,3.3,1.9,5.3,1.9c1.2,0,1.9-0.4,1.9-1.1l0,0c0-0.7-0.5-1-2.7-1.6c-3.4-0.8-6.1-1.8-6.1-5.1l0,0\n    \t\t\tc0-3,2.4-5.2,6.3-5.2c2.4-0.1,4.8,0.7,6.7,2.2l-2.2,3.2c-1.3-1-2.9-1.5-4.5-1.6c-1.1,0-1.6,0.5-1.6,1l0,0c0,0.7,0.6,1.1,2.8,1.6\n    \t\t\tc3.7,0.8,6,2,6,5l0,0c0,3.3-2.6,5.3-6.6,5.3C62.3,33.2,59.6,32.3,57.5,30.5z"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M76.4,16.7h13v3.8h-8.6V23h6v3.5h-6v2.6h8.7v3.8H76.4V16.7z"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M99.2,16.5h4.3l6.9,16.3h-4.8l-1.2-2.9h-6.2l-1.2,3h-4.7L99.2,16.5z M103.2,26.5l-1.8-4.6l-1.8,4.6H103.2z"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M113.8,16.7h7.7c2.5,0,4.2,0.6,5.3,1.8c1,1,1.5,2.4,1.4,3.8l0,0c0.1,2.2-1.3,4.2-3.3,5l3.9,5.6h-5.2l-3.3-4.9h-2v4.9h-4.5\n    \t\t\tL113.8,16.7z M121.2,24.4c1.5,0,2.4-0.7,2.4-1.9l0,0c0-1.3-0.9-1.9-2.4-1.9h-2.9v3.9L121.2,24.4z"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M131.1,24.8L131.1,24.8c-0.1-4.6,3.6-8.4,8.2-8.5c0.1,0,0.2,0,0.3,0c2.7-0.1,5.3,1.1,6.9,3.4l-3.4,2.6\n    \t\t\tc-0.8-1.2-2.1-1.9-3.5-1.9c-2.2,0.1-3.9,1.9-3.9,4.1v0.1l0,0c0,2.4,1.6,4.3,3.9,4.3c1.5,0,2.8-0.7,3.7-2l3.4,2.4\n    \t\t\tc-2.7,3.9-8,4.8-11.9,2.1C132.5,30.1,131.2,27.6,131.1,24.8L131.1,24.8z"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M149.8,16.7h4.5v6.1h5.8v-6.1h4.5v16.2h-4.5v-6.2h-5.8v6.2h-4.5V16.7z"
                }
              }),
              _vm._v(" "),
              _c("path", { attrs: { d: "M170.2,16.7h4.5v16.2h-4.5V16.7z" } }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M179.9,16.7h13v3.8h-8.6V23h6v3.5h-6v2.6h8.7v3.8h-13.1L179.9,16.7z"
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#2C9155",
              d:
                "M42.5,3.1h-18c-2.7,0-4.9,2.2-4.9,4.9l0,0c0,2.7,2.2,4.9,4.9,4.9h18c2.7,0,4.9-2.2,4.9-4.9l0,0\n\tC47.4,5.3,45.2,3.1,42.5,3.1z"
            }
          }),
          _vm._v(" "),
          _c("g", [
            _c("path", {
              attrs: {
                fill: "#FFFFFF",
                d:
                  "M24.4,10.4v-5H27c0.5,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.6,0.4,0.9s-0.1,0.6-0.3,0.8s-0.4,0.3-0.6,0.4\n\t\tc0.3,0,0.5,0.2,0.7,0.4s0.3,0.5,0.3,0.8c0,0.4-0.1,0.7-0.4,1s-0.6,0.4-1.1,0.4h-2.7V10.4z M25.5,7.4h1.4c0.2,0,0.3-0.1,0.4-0.2\n\t\ts0.2-0.2,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4S27,6.2,26.9,6.2h-1.4V7.4z M25.5,9.5h1.4c0.2,0,0.4-0.1,0.5-0.2s0.2-0.3,0.2-0.4\n\t\tc0-0.2-0.1-0.3-0.2-0.4S27,8.3,26.8,8.3h-1.4v1.2H25.5z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                fill: "#FFFFFF",
                d:
                  "M29.8,10.4v-5h3.6v0.9h-2.5v1.1h2.5v0.9h-2.5v1.2h2.5v0.9H29.8z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                fill: "#FFFFFF",
                d: "M35.8,10.4V6.3h-1.5V5.4h4v0.9h-1.5v4.1H35.8z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                fill: "#FFFFFF",
                d:
                  "M42.5,10.4l-0.3-0.9h-2.3l-0.3,0.9h-1.2l2-5.1h1.4l2,5.1H42.5z M41,6.3l-0.8,2.3h1.7L41,6.3z"
              }
            })
          ])
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }