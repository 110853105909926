import { Database } from '@vuex-orm/core';
import Widget from '@app2/models/Widget';
import Transcription from '@app2/models/Transcription';
import File from '@app2/models/File';
import Playlist from '@app2/models/Playlist';
import Section from '@app2/models/Section';
import Comment from '@app2/models/Comment';
import App from '@app2/models/App';
import FilePlaylist from '@app2/models/FilePlaylist';
import FolderPlaylist from '@app2/models/FolderPlaylist';
import Folder from '@app2/models/Folder';
import Connection from '@app2/models/Connection';
import Hub from '@app2/models/Hub';
import Template from '@app2/models/Template';
import Tag from '@app2/models/Tag';
import Page from '@app2/models/Page';
import DiscussionCategory from '@app2/models/DiscussionCategory';
import Audience from '@app2/models/Audience';
import Segment from '@app2/models/Segment';
import Achievement from '@app2/models/Achievement';
import Webhook from '@app2/models/Webhook';
import Sitemap from '@app2/models/Sitemap';
import AudienceAttribute from '@app2/models/AudienceAttribute';
import WisdomSession from '@app2/models/WisdomSession';
import WisdomQuery from '@app2/models/WisdomQuery';
import Prompt from '@app2/models/Prompt';

const database = new Database();

database.register(Connection);
database.register(File);
database.register(Playlist);
database.register(Section);
database.register(Comment);
database.register(FilePlaylist);
database.register(FolderPlaylist);
database.register(Folder);
database.register(Transcription);
database.register(Widget);
database.register(Tag);
database.register(Page);
database.register(DiscussionCategory);
database.register(Audience);
database.register(AudienceAttribute);
database.register(Segment);
database.register(Hub);
database.register(Template);
database.register(App);
database.register(Webhook);
database.register(Sitemap);
database.register(Achievement);
database.register(WisdomSession);
database.register(WisdomQuery);
database.register(Prompt);

export default database;
