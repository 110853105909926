<template>
  <svg class="icon-drag" xmlns="http://www.w3.org/2000/svg" width="8.499" height="18.143" viewBox="0 0 8.499 18.143">
    <path d="M1195.791,435.652h0l-1.115,1.115-1.114-1.115a.75.75,0,0,0-1.061,1.061l1.645,1.645a.75.75,0,0,0,1.061,0l1.645-1.646a.749.749,0,1,0-1.06-1.059Z" transform="translate(-1190.426 -420.434)"/>
    <path d="M1194.676,425.028a4.249,4.249,0,1,0,4.249,4.25A4.249,4.249,0,0,0,1194.676,425.028Zm0,7a2.751,2.751,0,1,1,2.75-2.75A2.755,2.755,0,0,1,1194.676,432.028Z" transform="translate(-1190.426 -420.434)"/>
    <path d="M1193.562,423.361l1.113-1.115,1.115,1.114a.75.75,0,0,0,1.062-1.06h0l-1.645-1.645a.75.75,0,0,0-1.061,0L1192.5,422.3a.751.751,0,0,0,1.062,1.062Z" transform="translate(-1190.426 -420.434)"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconDrag',
  };
</script>
