var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-right-circle",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "40",
        height: "40",
        viewBox: "0 0 40 40"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(40 40) rotate(180)" } }, [
        _c("g", { attrs: { fill: "none" } }, [
          _c("path", {
            attrs: {
              d: "M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z",
              stroke: "none"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M 20 1.5 C 15.05846977233887 1.5 10.4127197265625 3.424339294433594 6.918529510498047 6.918529510498047 C 3.424339294433594 10.4127197265625 1.5 15.05846977233887 1.5 20 C 1.5 24.94153022766113 3.424339294433594 29.5872802734375 6.918529510498047 33.08147048950195 C 10.4127197265625 36.57566070556641 15.05846977233887 38.5 20 38.5 C 24.94153022766113 38.5 29.5872802734375 36.57566070556641 33.08147048950195 33.08147048950195 C 36.57566070556641 29.5872802734375 38.5 24.94153022766113 38.5 20 C 38.5 15.05846977233887 36.57566070556641 10.4127197265625 33.08147048950195 6.918529510498047 C 29.5872802734375 3.424339294433594 24.94153022766113 1.5 20 1.5 M 20 0 C 31.04568862915039 0 40 8.954309463500977 40 20 C 40 31.04568862915039 31.04568862915039 40 20 40 C 8.954309463500977 40 0 31.04568862915039 0 20 C 0 8.954309463500977 8.954309463500977 0 20 0 Z",
              stroke: "none",
              fill: "rgba(130,130,130,0.05)"
            }
          })
        ]),
        _vm._v(" "),
        _c("path", {
          staticClass: "curColor",
          attrs: {
            d:
              "M350.716,395.485H336.562l5.2-5.2a.75.75,0,0,0-1.061-1.06l-6.485,6.484a.75.75,0,0,0,0,1.061l6.485,6.485a.75.75,0,1,0,1.061-1.06h0l-5.206-5.206h14.157a.75.75,0,0,0,0-1.5Z",
            transform: "translate(-322.999 -376.22)",
            fill: "currentColor"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }