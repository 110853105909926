<template>
  <svg class="media-snapshot-visits" xmlns="http://www.w3.org/2000/svg" width="17.993" height="12.999" viewBox="0 0 17.993 12.999">
    <path fill="#939393" d="M9.004 3.325a3.186 3.186 0 103.188 3.187 3.186 3.186 0 00-3.188-3.187zm0 4.874a1.688 1.688 0 111.688-1.687 1.69 1.69 0 01-1.688 1.683zM1.649 9.918zM.909 9.08zM1.651 9.925zM17.086 3.925zM.905 3.925z"/>
    <path fill="#939393" d="M17.098 9.065a4.118 4.118 0 00-.01-5.139A10.4 10.4 0 0015.479 2.3 10.294 10.294 0 00.908 3.923a4.115 4.115 0 000 5.157 10.28 10.28 0 0015.363.89l.006-.005a11.176 11.176 0 00.8-.868c.014-.012.014-.023.021-.032zm-1.88-.162a8.778 8.778 0 01-12.5-.039 8.614 8.614 0 01-.632-.716l-.005-.005a2.617 2.617 0 010-3.276v-.005a8.795 8.795 0 0112.456-1.393 8.943 8.943 0 011.378 1.393 2.621 2.621 0 010 3.279c0 .005 0 .01-.006.015-.216.256-.444.5-.684.737z"/>
  </svg>
</template>

<script>
  export default {
    name: 'MediaSnapshotVisits'
  }
</script>
