const namespaced = true;

const state = {
  exitRoute: null,
};

const mutations = {
  SET_EXIT_ROUTE(state, payload) {
    state.exitRoute = payload;
  }
};

const actions = {
};

const getters = {
  exitRoute: (state) => state.exitRoute,
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
