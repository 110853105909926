var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-column",
      attrs: {
        viewBox: "0 0 22.003 16.5",
        xmlns: "http://www.w3.org/2000/svg",
        width: "22",
        height: "16.5"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M19.001 0h-1a.75.75 0 0 0 0 1.5h1a.75.75 0 0 0 0-1.5ZM21.253 11.449a.75.75 0 0 0-.75.75v1.551a1.263 1.263 0 0 1-.028.264.75.75 0 0 0 1.467.315 2.775 2.775 0 0 0 .061-.579v-1.551a.75.75 0 0 0-.75-.75ZM20.472 2.484a1.291 1.291 0 0 1 .028.266v1.548a.75.75 0 0 0 1.5 0V2.75a2.729 2.729 0 0 0-.062-.582.75.75 0 1 0-1.466.317ZM21.253 6.511a.75.75 0 0 0-.75.75v1.976a.75.75 0 0 0 1.5 0V7.259a.75.75 0 0 0-.75-.748ZM19.001 15h-1a.75.75 0 0 0 0 1.5h1a.75.75 0 0 0 0-1.5ZM4.001 15h-1a.75.75 0 0 0 0 1.5h1a.75.75 0 0 0 0-1.5ZM.75 5.05a.75.75 0 0 0 .75-.75V2.75a1.191 1.191 0 0 1 .028-.264.75.75 0 1 0-1.467-.316A2.785 2.785 0 0 0 0 2.75V4.3a.75.75 0 0 0 .75.75ZM1.528 14.015a1.266 1.266 0 0 1-.028-.265v-1.549a.75.75 0 0 0-1.5 0v1.549a2.744 2.744 0 0 0 .062.582.75.75 0 0 0 1.466-.317ZM.75 9.988a.75.75 0 0 0 .75-.75V7.259a.75.75 0 0 0-1.5 0v1.975a.75.75 0 0 0 .75.754ZM4.001 0h-1a.75.75 0 0 0 0 1.5h1a.75.75 0 0 0 0-1.5ZM5.251 16.5h11.5V0h-11.5Zm1.5-15h8.5V15h-8.5Z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }