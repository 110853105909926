var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19",
        height: "19",
        viewBox: "0 0 19 19"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M14.374,285.6H5.226A4.934,4.934,0,0,0,.3,290.526v9.148A4.934,4.934,0,0,0,5.226,304.6h9.148a4.934,4.934,0,0,0,4.926-4.926v-9.148A4.934,4.934,0,0,0,14.374,285.6Zm3.418,14.074a3.39,3.39,0,0,1-3.418,3.418H5.226a3.39,3.39,0,0,1-3.418-3.418v-9.148a3.39,3.39,0,0,1,3.418-3.418h5.228v2.312a3.116,3.116,0,0,0,6.233,0v-1.508a3.242,3.242,0,0,1,1.206,2.614v9.148Z",
          transform: "translate(-0.3 -285.6)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }