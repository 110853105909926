import get from 'lodash/get'

export default {
  methods: {
    getOutline(item) {
      return get(item, 'settings.appearance.colors.outline', '#5581F4')
    },
    getBase(item) {
      return get(item, 'settings.appearance.colors.base', '#273E79')
    },
    getIconSize(item) {
      return get(item, 'settings.icon.size', 50)
    },
    getIconColor(item) {
      return get(item, 'settings.appearance.colors.icon', '#fff')
    },
    getIllustrationType(item) {
      return get(item, 'settings.appearance.illustration.type', 'icon')
    },
    getIllustrationIcon(item) {
      return get(item, 'settings.appearance.illustration.inner.icon', 'award')
    },
    getIllustrationInner(item) {
      return get(item, 'settings.appearance.illustration.inner', { name: 'Award', icon: 'award'})
    },
    getEmojiPosition(item) {
      return get(item, 'settings.appearance.illustration.inner.position', '0 0')
    },
    getShape(item) {
      return get(item, 'settings.appearance.shape', 1)
    }
  }
}
