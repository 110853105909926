var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-warning",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "22.94",
        height: "20.09",
        viewBox: "0 0 22.94 22.09"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(2.469 3.54)" } }, [
        _c(
          "g",
          {
            attrs: {
              "data-name": "bg-group",
              transform: "translate(-2.469 -3.54)",
              fill: "#edb2b2"
            }
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M 16.05019760131836 19.33983993530273 L 6.88975715637207 19.33983993530273 C 5.71809720993042 19.33983993530273 4.66738748550415 18.73489952087402 4.079107284545898 17.72163009643555 C 3.490827322006226 16.70835876464844 3.486437320709229 15.49594974517822 4.067367553710938 14.47843933105469 L 8.647587776184082 6.456119537353516 C 9.233386993408203 5.430079460144043 10.28848743438721 4.817529201507568 11.46997737884521 4.817529201507568 C 12.65146732330322 4.817529201507568 13.70656776428223 5.430079460144043 14.29236698150635 6.456119537353516 L 18.87258720397949 14.47843933105469 C 19.45351791381836 15.49594974517822 19.44912719726562 16.70835876464844 18.86084747314453 17.72163009643555 C 18.27256774902344 18.73489952087402 17.22185707092285 19.33983993530273 16.05019760131836 19.33983993530273 Z",
                stroke: "none"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M 11.46997737884521 5.567520141601562 C 10.56113719940186 5.567520141601562 9.749526977539062 6.038719177246094 9.298907279968262 6.827980041503906 L 4.718687057495117 14.85029983520508 C 4.271818161010742 15.63299942016602 4.275197982788086 16.56562042236328 4.727716445922852 17.34505844116211 C 5.18023681640625 18.1245002746582 5.98847770690918 18.58983993530273 6.88975715637207 18.58983993530273 L 16.05019760131836 18.58983993530273 C 16.95147705078125 18.58983993530273 17.75971794128418 18.1245002746582 18.21223831176758 17.34505844116211 C 18.66475677490234 16.56562042236328 18.66813659667969 15.63299942016602 18.22126770019531 14.85029983520508 L 13.64104747772217 6.82798957824707 C 13.19042778015137 6.038719177246094 12.37880706787109 5.567520141601562 11.46997737884521 5.567520141601562 M 11.46997737884521 4.067523956298828 C 12.82302474975586 4.067523956298828 14.1760721206665 4.739768981933594 14.94368743896484 6.084259033203125 L 19.52390670776367 14.10657978057861 C 21.04636764526367 16.7731990814209 19.12082672119141 20.08983993530273 16.05019760131836 20.08983993530273 L 6.88975715637207 20.08983993530273 C 3.819128036499023 20.08983993530273 1.893587112426758 16.7731990814209 3.416048049926758 14.10657978057861 L 7.996267318725586 6.084259033203125 C 8.763882637023926 4.739768981933594 10.11693000793457 4.067523956298828 11.46997737884521 4.067523956298828 Z",
                stroke: "none",
                fill: "#c24141"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M.868-9.8v5.746h1.54V-9.8Zm0,6.482v1.563h1.54V-3.318Z",
            transform: "translate(7.282 15.164)",
            fill: "#c24141"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }