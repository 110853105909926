<template>
  <svg class="icon-google-podcast" xmlns="http://www.w3.org/2000/svg" width="19" height="19">
    <path d="M1.881 7.817A1.088 1.088 0 00.792 8.908v1.188a1.089 1.089 0 002.177 0V8.908a1.088 1.088 0 00-1.088-1.091z" fill="#0066d9"/>
    <path d="M17.12 7.817a1.088 1.088 0 00-1.089 1.091v1.188a1.089 1.089 0 102.177 0V8.908a1.088 1.088 0 00-1.088-1.091z" fill="#4285f4"/>
    <path d="M5.64 11.38a1.088 1.088 0 00-1.088 1.088v1.188a1.089 1.089 0 002.177 0v-1.188A1.088 1.088 0 005.64 11.38zM5.64 4.255a1.088 1.088 0 00-1.088 1.088v3.885a1.088 1.088 0 002.175 0V5.343A1.088 1.088 0 005.64 4.255z" fill="#ea4335"/>
    <path d="M13.359 4.255a1.088 1.088 0 00-1.089 1.089v1.187a1.089 1.089 0 002.177 0V5.343a1.088 1.088 0 00-1.088-1.088z" fill="#34a853"/>
    <path d="M9.5.792a1.088 1.088 0 00-1.089 1.089v1.186a1.089 1.089 0 002.177 0V1.881A1.088 1.088 0 009.5.792zM9.5 14.844a1.088 1.088 0 00-1.089 1.089v1.187a1.089 1.089 0 002.177 0v-1.188A1.088 1.088 0 009.5 14.844z" fill="#fab908"/>
    <path d="M13.359 8.708a1.088 1.088 0 00-1.089 1.089v3.859a1.089 1.089 0 002.177 0V9.797a1.088 1.088 0 00-1.088-1.089z" fill="#34a853"/>
    <path d="M10.588 6.334a1.089 1.089 0 00-2.177 0v6.333a1.089 1.089 0 002.177 0V6.334z" fill="#fab908"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-google-podcast'
  }
</script>
