var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        "data-name": "Transcriptions Icon Settings",
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.86",
        height: "15.98",
        viewBox: "0 0 17.86 15.98"
      }
    },
    [
      _c("path", {
        attrs: {
          "data-name": "Path 2499",
          d:
            "M8.58 8.48l1.98-1.1a.92.92 0 00.35-.34l.02-.02a.89.89 0 00-.37-1.2l-1.98-1.1a.93.93 0 00-.46-.12.91.91 0 00-.92.9v2.2a.87.87 0 00.12.44.94.94 0 001.26.34z",
          fill: "#293ad3"
        }
      }),
      _c("path", {
        attrs: {
          "data-name": "Path 2500",
          d:
            "M17.86 11.57V3.34A3.34 3.34 0 0014.5 0H3.35A3.35 3.35 0 000 3.34v9.3a3.35 3.35 0 003.35 3.34H14.5a3.35 3.35 0 003.35-3.35v-1.06zM3.35 1.5H14.5a1.85 1.85 0 011.85 1.84v7.48H1.5V3.34A1.85 1.85 0 013.35 1.5zM14.5 14.48H3.35a1.85 1.85 0 01-1.85-1.85v-.31h14.86v.31a1.85 1.85 0 01-1.85 1.85z",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }