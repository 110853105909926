var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19",
        height: "19",
        viewBox: "0 0 19 19"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M14.374,51.9H5.226A4.921,4.921,0,0,0,.3,56.8V66a4.921,4.921,0,0,0,4.926,4.9h9.148A4.921,4.921,0,0,0,19.3,66V56.9A5.007,5.007,0,0,0,14.374,51.9ZM17.792,66a3.381,3.381,0,0,1-3.418,3.4H12.866V67.1a3.116,3.116,0,0,0-6.233,0v2.3H5.226A3.381,3.381,0,0,1,1.808,66V56.9a3.381,3.381,0,0,1,3.418-3.4h9.148a3.381,3.381,0,0,1,3.418,3.4Z",
          transform: "translate(-0.3 -51.9)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }