import axios from "@app2/api/axios";
import groupBy from "lodash/groupBy";

const namespaced = true;

const state = {
  webhook: null,
  isWebhooksModalOpen: false,
  events: null,
};

const mutations = {
  SET_WEBHOOK(state, payload) {
    state.webhook = payload;
  },
  SET_WEBHOOK_MODAL_STATE(state, payload) {
    state.isWebhooksModalOpen = payload;
  },
  SET_EVENTS(state, payload) {
    state.events = payload;
  }
};

const actions = {
  async getEvents({state, commit}) {
    if ( state.events ) return;
    let { data: { data } } = await axios.get('/api/internal/v2/webhooks/events', {
      params: {
        limit: 9999
      }
    });
    if (data && data.length) {
      commit('SET_EVENTS', data);
    }
  }
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
