var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-attachments",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.993",
        height: "14.992",
        viewBox: "0 0 16.993 14.992"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M259.142,1119.752a4.086,4.086,0,0,0-5.777,0l-5.952,5.951a2.388,2.388,0,0,0,.029,3.407l0,0,.038.036a2.336,2.336,0,0,0,3.308-.068v0l3.78-3.779a.75.75,0,0,0-1.06-1.061l-3.78,3.779-.015.015a.84.84,0,0,1-.613.266.825.825,0,0,1-.581-.235l-.037-.035a.89.89,0,0,1-.273-.64.879.879,0,0,1,.261-.628l5.953-5.951a2.584,2.584,0,1,1,3.655,3.655l-6.122,6.121a4.167,4.167,0,1,1-5.894-5.892l5.1-5.1a.75.75,0,0,0-1.06-1.061h0l-5.1,5.1a5.667,5.667,0,0,0,8.014,8.014l6.123-6.121a4.086,4.086,0,0,0,0-5.777Z",
          transform: "translate(-243.345 -1118.317)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }