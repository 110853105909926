var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Group_1910",
        "data-name": "Group 1910",
        xmlns: "http://www.w3.org/2000/svg",
        width: "23.999",
        height: "24",
        viewBox: "0 0 23.999 24"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Path_749",
          "data-name": "Path 749",
          d:
            "M947.705,164.747l.357-2.883q1.109.072,2.417.214l.2-1.9-.3-.072q-.932-.18-2.041-.286l.322-2.573h-1.324l-.322,2.471h-.232a4.576,4.576,0,0,0-2.953.869,3.191,3.191,0,0,0-1.075,2.639,2.943,2.943,0,0,0,.77,2.149,6.141,6.141,0,0,0,2.614,1.341l-.393,3.116q-1.2-.069-2.614-.269l-.233,1.862.357.07a19.808,19.808,0,0,0,2.23.377l-.252,2.075a4.667,4.667,0,0,0,1.322.129l.269-2.111h.017a4.4,4.4,0,0,0,2.953-.959,3.489,3.489,0,0,0,1.128-2.82,3.008,3.008,0,0,0-.679-2.139A6.155,6.155,0,0,0,947.705,164.747Zm-1.259-.425a3.026,3.026,0,0,1-1.042-.553v0a.945.945,0,0,1-.268-.7q0-1.159,1.61-1.233Zm2.094,4.192a1.4,1.4,0,0,1-1.45,1.349h-.006l.344-2.747a2.3,2.3,0,0,1,.87.527,1.047,1.047,0,0,1,.242.707Z",
          transform: "translate(-934.926 -154.085)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_750",
          "data-name": "Path 750",
          d:
            "M946.926,154.085a12,12,0,1,0,12,12A12,12,0,0,0,946.926,154.085Zm7.424,19.425a10.5,10.5,0,1,1,3.076-7.425A10.46,10.46,0,0,1,954.35,173.51Z",
          transform: "translate(-934.926 -154.085)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }