var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "social",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "14",
        height: "14",
        viewBox: "0 0 24 24"
      }
    },
    [
      _c("g", { attrs: { fill: "#1e64f0" } }, [
        _c("path", {
          attrs: {
            d:
              " M15.37 19.26c-3.45.95-11.23.49-12.48.49H0l4.76-5.37c1.29-1.45 2-1.75 3.39-1.75s4.77.14 6.58 0A18.14 18.14 0 0022 10.64c2.95-1.86 3.54-3 3.54-3s1.05 8.53-10.17 11.62z "
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              " M25.19 4.19C25.73.9 23.87 0 23.87 0S24 2.68 19 3.25c-4.37.5-19 .12-19 .12l4.71 5.4a4 4 0 003.39 1.74 60.59 60.59 0 006.75-.1 15.8 15.8 0 008-2.7 6 6 0 002.34-3.52z "
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }