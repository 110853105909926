<template>
  <svg class="icon-chapters" xmlns="http://www.w3.org/2000/svg" width="16.668" height="14" viewBox="0 0 16.668 13.969">
  	<path fill="#939393" d="M15.918 4.596H8.85a.75.75 0 000 1.5h7.068a.75.75 0 000-1.5zM15.918 8.532H.751a.75.75 0 000 1.5h15.167a.75.75 0 000-1.5zM8.484 12.47H.751a.75.75 0 000 1.5h7.733a.75.75 0 000-1.5zM8.85 2.159h7.068a.75.75 0 000-1.5H8.85a.75.75 0 000 1.5zM.264 5.57a1.933 1.933 0 001.649.921 1.914 1.914 0 00.926-.236l2.478-1.37a1.88 1.88 0 00.729-.7l.021-.035.009-.017.009-.016a1.884 1.884 0 00.213-.865 1.86 1.86 0 00-.977-1.637l-.009-.005L2.842.247a1.92 1.92 0 00-.8-.23h-.137A1.917 1.917 0 00-.01 1.861v2.783a1.882 1.882 0 00.255.927zm1.65-4.07h.018l.188.054 2.481 1.368.01.005a.362.362 0 01.191.32l-.043.17-.147.142-2.488 1.376-.208.053a.439.439 0 01-.364-.2l-.049-.179V1.896a.417.417 0 01.411-.397z"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconChapters'
  }
</script>
