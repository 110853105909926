export default {
  methods: {
    redirectHubRequest() {
      const hash = this.$route?.query?.redirect_hub || localStorage?.redirect_hub

      if (!hash) return

      const redirect = this.$route.query.redirect;
      let queryString = redirect ? new URLSearchParams({ redirect }).toString() : '';

      return axios.get(`/api/internal/v2/hubs/shortcuts/${hash}/login?${queryString}`).then(({ data })=> {
        delete localStorage.redirect_hub 
        window.location.replace(data.link);
      })
    },
    storeOrClearRedirectHub() {
      if (this.$route.query.redirect_hub) {
        localStorage.redirect_hub = this.$route.query.redirect_hub;
      } else {
        delete localStorage.redirect_hub;
      }
    },
  }
}
