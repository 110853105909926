<template>
  <svg class="icon-menu-widgets" xmlns="http://www.w3.org/2000/svg" width="28" height="23" viewBox="0 0 28 23">
    <rect width="28" height="23" fill="#fff" opacity="0"/>
    <path d="M40.578,157.363a.75.75,0,0,0,0,1.5H56.009a.75.75,0,0,0,0-1.5Z" transform="translate(-34.299 -153.22)" fill="#293ad3"/>
    <path d="M49.043,162.131a.75.75,0,0,0-.75-.75H40.578a.75.75,0,0,0,0,1.5h7.715A.75.75,0,0,0,49.043,162.131Z" transform="translate(-34.299 -153.22)" fill="#293ad3"/>
    <path class="cl-3" d="M57.886,167.662v0l-2.51-1.383a1.934,1.934,0,0,0-.794-.226v0l-.16-.017H54.4v0A1.914,1.914,0,0,0,52.5,167.9v2.808a1.879,1.879,0,0,0,.259.94l.008.013a1.953,1.953,0,0,0,2.6.69h0l2.513-1.39h0a1.892,1.892,0,0,0,.735-.707l.019-.031.012-.021.007-.014a1.876,1.876,0,0,0-.77-2.528Zm-.56,1.833-.157.151-2.522,1.393-.215.055a.45.45,0,0,1-.379-.209L54,170.7v-2.763a.415.415,0,0,1,.412-.406h.015l.215.054,0,0,2.514,1.385.011.006a.374.374,0,0,1,.2.333l-.045.177,0,0Z" transform="translate(-34.299 -153.22)" fill="#7b88ff"/>
    <path d="M46.107,171.711h-6.44a1.871,1.871,0,0,1-1.868-1.868V156.587a1.871,1.871,0,0,1,1.868-1.868H56.919a1.871,1.871,0,0,1,1.868,1.868v3.521a.75.75,0,0,0,1.5,0v-3.521a3.367,3.367,0,0,0-3.368-3.367H39.667a3.368,3.368,0,0,0-3.368,3.367v13.255a3.369,3.369,0,0,0,3.368,3.368h6.44a.75.75,0,1,0,0-1.5Z" transform="translate(-34.299 -153.22)" fill="#293ad3"/>
    <path d="M55.373,162.406a6.837,6.837,0,0,0-2.648.53,6.906,6.906,0,1,0,2.648-.53Zm.006,12.315a5.407,5.407,0,0,1-2.077-10.4h0a5.343,5.343,0,0,1,2.069-.413,5.407,5.407,0,1,1,.006,10.815Z" transform="translate(-34.299 -153.22)" fill="#293ad3"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconMenuWidgets',
  };
</script>
