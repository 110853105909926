var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.95",
        height: "19",
        viewBox: "0 0 16.95 18.987"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M13.554-.003H6.693a3.4 3.4 0 00-3.4 3.4v1.112h-.755A2.542 2.542 0 00-.004 7.051v9.4a2.541 2.541 0 002.542 2.541h7.722a2.541 2.541 0 002.541-2.541v-1.119h.749a3.4 3.4 0 003.4-3.4V3.397a3.4 3.4 0 00-3.396-3.4zm-2.248 16.446a1.045 1.045 0 01-1.042 1.042H2.542A1.045 1.045 0 011.5 16.443v-9.4a1.044 1.044 0 011.042-1.042h.755v5.923a3.4 3.4 0 003.4 3.4h4.613zm4.145-4.515a1.9 1.9 0 01-1.9 1.9H6.693a1.9 1.9 0 01-1.9-1.9V3.397a1.9 1.9 0 011.9-1.9h6.861a1.9 1.9 0 011.9 1.9z",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M12.856 3.849H7.39a.75.75 0 000 1.5h5.466a.75.75 0 000-1.5zM12.856 6.913H7.39a.75.75 0 000 1.5h5.466a.75.75 0 000-1.5zM12.856 9.978H7.39a.75.75 0 000 1.5h5.466a.75.75 0 000-1.5z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }