<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.466" height="14.47" viewBox="0 0 17.466 14.47">
    <g>
      <path d="M16.739 6.481H2.585l5.2-5.2A.75.75 0 006.724.221L.239 6.705a.75.75 0 000 1.061l6.485 6.485a.75.75 0 101.061-1.06L2.579 7.985h14.157a.75.75 0 000-1.5z"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'IconSearchArrowLeft'
  }
</script>
