<template>
  <svg class="icon-settings" xmlns="http://www.w3.org/2000/svg" width="17.196" height="17.884" viewBox="0 0 17.196 17.884">
    <path d="M16.243 9.775a.963.963 0 010-1.667 1.9 1.9 0 00.7-2.6l-1.2-2.072a1.9 1.9 0 00-1.651-.953 1.881 1.881 0 00-.955.258h.005a.96.96 0 01-.48.13.984.984 0 01-.674-.274.936.936 0 01-.291-.69 1.9 1.9 0 00-1.9-1.9H7.402a1.9 1.9 0 00-1.9 1.9.94.94 0 01-.291.69.993.993 0 01-.675.274.966.966 0 01-.48-.131 1.9 1.9 0 00-2.6.7l-1.2 2.072a1.9 1.9 0 00.7 2.6.963.963 0 010 1.668 1.9 1.9 0 00-.7 2.6l1.2 2.073a1.905 1.905 0 002.6.7h.005a.95.95 0 01.478-.132.987.987 0 01.674.275.938.938 0 01.292.692 1.9 1.9 0 001.9 1.9H9.8a1.9 1.9 0 001.9-1.9.939.939 0 01.292-.692.985.985 0 01.674-.275.929.929 0 01.476.132 1.906 1.906 0 002.6-.7l1.2-2.072a1.9 1.9 0 00-.7-2.6zm-.6-3.517l.054.2a.405.405 0 01-.2.352 2.462 2.462 0 000 4.264.4.4 0 01.2.35l-.054.2-1.2 2.072a.407.407 0 01-.353.2l-.2-.054a2.433 2.433 0 00-1.231-.334 2.475 2.475 0 00-1.713.692 2.441 2.441 0 00-.753 1.774.4.4 0 01-.4.4H7.398a.405.405 0 01-.4-.4 2.442 2.442 0 00-.753-1.775 2.478 2.478 0 00-1.712-.691 2.439 2.439 0 00-1.229.333h.005l-.206.056a.4.4 0 01-.35-.2l-1.2-2.074-.055-.2a.4.4 0 01.2-.351 2.463 2.463 0 000-4.265.406.406 0 01-.2-.351l.055-.2 1.2-2.074a.4.4 0 01.351-.2l.2.055a2.449 2.449 0 001.229.331 2.477 2.477 0 001.712-.69 2.437 2.437 0 00.753-1.773.4.4 0 01.4-.405h2.395a.4.4 0 01.4.405 2.441 2.441 0 00.753 1.772 2.479 2.479 0 001.712.692 2.445 2.445 0 001.229-.331h.006l.2-.053a.409.409 0 01.352.2l1.2 2.073.65-.374z" fill="#939393"/>
    <path d="M8.599 6.255a2.687 2.687 0 102.687 2.687 2.686 2.686 0 00-2.687-2.687zm0 3.874a1.187 1.187 0 111.187-1.187 1.19 1.19 0 01-1.187 1.188z" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-settings'
  }
</script>
