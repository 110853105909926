<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16.222" height="15.095" viewBox="0 0 16.222 15.095">
    <path d="M1785.61,245.454a.749.749,0,0,0-.846,0,6.7,6.7,0,0,1-10.5-5.523,6.792,6.792,0,0,1,.17-1.479.75.75,0,0,0-1.157-.783,8.2,8.2,0,1,0,12.648,8.567A.75.75,0,0,0,1785.61,245.454Zm-7.688,5.683a6.691,6.691,0,0,1-5.15-10.98,8.15,8.15,0,0,0,11.021,7.46A6.676,6.676,0,0,1,1777.922,251.137Z" transform="translate(-1769.717 -237.541)" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconMoon',
  };
</script>
