<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="78.732" height="99.246" viewBox="0 0 78.732 99.246">
    <defs>
      <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#e4e4e4"/>
        <stop offset="1" stop-color="#e4e4e4" stop-opacity="0"/>
      </linearGradient>
    </defs>
    <g id="Group_19278" data-name="Group 19278" opacity="0.7">
      <path id="Path_634" data-name="Path 634" d="M1790,863.842a4.22,4.22,0,0,1-4.2,4.2h-70.326a4.217,4.217,0,0,1-4.208-4.2V857.5a39.366,39.366,0,0,1,78.732,0Z" transform="translate(-1711.27 -768.795)" fill="url(#linear-gradient)"/>
      <path id="Path_635" data-name="Path 635" d="M1733.289,810.249a18.815,18.815,0,1,1-18.825,18.824A18.812,18.812,0,0,1,1733.289,810.249Z" transform="translate(-1693.921 -810.249)" fill="#e4e4e4"/>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconDeleteAudience'
  }
</script>
