var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Questionmark_icon",
        "data-name": "Questionmark icon",
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.213",
        height: "16.213",
        viewBox: "0 0 16.213 16.213"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Path_699",
            "data-name": "Path 699",
            fill: "rgba(199,203,255,0.5)"
          }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M 8.1064453125 15.46289539337158 C 4.050095081329346 15.46289539337158 0.750005304813385 12.16280555725098 0.750005304813385 8.1064453125 C 0.750005304813385 4.050095081329346 4.050095081329346 0.750005304813385 8.1064453125 0.750005304813385 C 12.16280555725098 0.750005304813385 15.46289539337158 4.050095081329346 15.46289539337158 8.1064453125 C 15.46289539337158 12.16280555725098 12.16280555725098 15.46289539337158 8.1064453125 15.46289539337158 Z",
              stroke: "none"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M 8.1064453125 1.499995231628418 C 4.463635444641113 1.499995231628418 1.499995231628418 4.463635444641113 1.499995231628418 8.1064453125 C 1.499995231628418 11.74924468994141 4.463635444641113 14.7128849029541 8.1064453125 14.7128849029541 C 11.74924468994141 14.7128849029541 14.7128849029541 11.74924468994141 14.7128849029541 8.1064453125 C 14.7128849029541 4.463635444641113 11.74924468994141 1.499995231628418 8.1064453125 1.499995231628418 M 8.1064453125 -3.814697265625e-06 C 12.58350563049316 -3.814697265625e-06 16.2128849029541 3.629375457763672 16.2128849029541 8.1064453125 C 16.2128849029541 12.58350563049316 12.58350563049316 16.2128849029541 8.1064453125 16.2128849029541 C 3.629375457763672 16.2128849029541 -3.814697265625e-06 12.58350563049316 -3.814697265625e-06 8.1064453125 C -3.814697265625e-06 3.629375457763672 3.629375457763672 -3.814697265625e-06 8.1064453125 -3.814697265625e-06 Z",
              stroke: "none",
              fill: "#293ad3"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          id: "Rectangle_811",
          "data-name": "Rectangle 811",
          width: "1.338",
          height: "1.623",
          transform: "translate(7.238 10.309)",
          fill: "#2e2ed3"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_1157",
          "data-name": "Path 1157",
          d:
            "M581.02,506.322a5.932,5.932,0,0,0-1.963.382l.065.963.268-.058a6.974,6.974,0,0,1,1.459-.163q1.228,0,1.229.847a1.178,1.178,0,0,1-.186.69,4.571,4.571,0,0,1-.755.731,5.2,5.2,0,0,0-.789.759.959.959,0,0,0-.22.58,1.429,1.429,0,0,0,.283.9h1.022v-.384a1,1,0,0,1,.19-.584,4.161,4.161,0,0,1,.782-.736,2.636,2.636,0,0,0,.783-.871,2.887,2.887,0,0,0,.191-1.181,1.7,1.7,0,0,0-.567-1.432A2.908,2.908,0,0,0,581.02,506.322Z",
          transform: "translate(-572.979 -502.459)",
          fill: "#2e2ed3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }