var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "onboarding-finish" }, [
    _c(
      "h2",
      { staticClass: "txt-title mb-0" },
      [
        _c("transition", { attrs: { appear: "", name: "fade" } }, [
          _c(
            "b",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.unlockedTitle,
                  expression: "unlockedTitle"
                }
              ]
            },
            [_vm._v("Registration Complete!")]
          )
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "onboarding-finish-figure",
        class: { unlocked: _vm.unlocked }
      },
      [
        _c(
          "svg",
          {
            attrs: {
              width: "164",
              height: "165",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("path", {
              attrs: {
                d: "M74.3333 108.833L112.667 59",
                stroke: "currentColor",
                "stroke-width": "5",
                "stroke-miterlimit": "10",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M51.3333 82L74.3333 108.833",
                stroke: "currentColor",
                "stroke-width": "5",
                "stroke-miterlimit": "10",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }
            })
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }