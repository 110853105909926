var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "80",
        height: "80",
        viewBox: "0 0 80 80",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M61.6663 23.3334V15C61.6663 11.3334 58.6663 8.33337 54.9997 8.33337H9.99967C6.33301 8.33337 3.33301 11.3334 3.33301 15V50C3.33301 53.6667 6.33301 56.6667 9.99967 56.6667H39.9997",
          stroke: "white",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M26.667 40.3333V26.1666C26.667 24.8333 28.0003 24 29.167 24.6666L41.0003 31.6666C42.0003 32.3333 42.0003 33.8333 41.0003 34.5L29.167 41.5C28.0003 42.5 26.667 41.6666 26.667 40.3333Z",
          stroke: "white",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M72.083 72.5H51.2497C48.958 72.5 47.083 70.625 47.083 68.3333V51.6667C47.083 49.375 48.958 47.5 51.2497 47.5H72.083C74.3747 47.5 76.2497 49.375 76.2497 51.6667V68.3333C76.2497 70.625 74.3747 72.5 72.083 72.5Z",
          stroke: "#707070",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M69.9997 47.5H53.333V39.1667C53.333 34.5834 57.083 30.8334 61.6663 30.8334C66.2497 30.8334 69.9997 34.5834 69.9997 39.1667V47.5Z",
          stroke: "#707070",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M61.667 55.8334V64.1667",
          stroke: "#707070",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }