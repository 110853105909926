<template>
  <svg class="icon-section-image" xmlns="http://www.w3.org/2000/svg" width="19" height="15.999" viewBox="0 0 19 15.999">
    <path d="M16.465 0H2.536A2.536 2.536 0 000 2.536v10.928a2.536 2.536 0 002.536 2.535h13.929A2.534 2.534 0 0019 13.464V2.54A2.535 2.535 0 0016.465 0zM2.536 1.5h13.929A1.039 1.039 0 0117.5 2.537v9.87l-3.1-3.275a.749.749 0 00-1.09 0l-1.777 1.877L6.152 5.32a.752.752 0 00-1.09 0L1.497 9.089V2.54a1.04 1.04 0 011.039-1.041zm0 13A1.039 1.039 0 011.5 13.464v-2.193l4.11-4.345 5.382 5.689a.75.75 0 001.089 0l1.777-1.878 3.3 3.486a1.027 1.027 0 01-.69.277z" fill="#939393"/>
    <path d="M12.232 7.512a2.2 2.2 0 10-2.2-2.2 2.2 2.2 0 002.2 2.2zm0-2.9a.7.7 0 11-.7.7.7.7 0 01.7-.698z" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-section-image'
  }
</script>
