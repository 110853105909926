var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-tag-lg",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.119",
        height: "16.12",
        viewBox: "0 0 16.119 16.12"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(-368.057 -863.876)" } }, [
        _c("path", {
          attrs: {
            d:
              "M383.517,870.861l-.061-1.968a.75.75,0,0,0-1.5.047h0l.061,1.964v.036a1.516,1.516,0,0,1-.447,1.072l-6.043,6.044v0a1.527,1.527,0,0,1-2.153,0L370,874.678a1.522,1.522,0,0,1,0-2.149l6.044-6.044a1.515,1.515,0,0,1,1.073-.447h.033l2.113.06a.75.75,0,0,0,.043-1.5l-2.113-.06h0l-.076,0a3.013,3.013,0,0,0-2.135.887l-6.042,6.043a3.021,3.021,0,0,0,0,4.27l3.378,3.378,0,0a3.03,3.03,0,0,0,4.266,0l0,0,6.044-6.044a3.019,3.019,0,0,0,.885-2.133v-.078Z",
            fill: "#939393"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M383.956,864.1a.75.75,0,0,0-1.061,0l-4.141,4.141a.75.75,0,0,0,1.06,1.061l4.142-4.141A.752.752,0,0,0,383.956,864.1Z",
            fill: "#939393"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }