var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 20 20"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(1 1)" } }, [
        _c("path", {
          attrs: {
            d:
              "M755,454.667a8.974,8.974,0,0,0,.559,3.127l8.432.782,8.432-.782a9.024,9.024,0,0,0,0-6.255l-8.432-.782-8.432.782a8.974,8.974,0,0,0-.559,3.127Zm0,0",
            transform: "translate(-755 -445.676)",
            fill: "#ffda44"
          }
        }),
        _c("path", {
          attrs: {
            d: "M784.6,340.864a8.994,8.994,0,0,0-16.864,0Zm0,0",
            transform: "translate(-767.179 -335)",
            fill: "#d80027"
          }
        }),
        _c("path", {
          attrs: {
            d: "M767.738,611.035a8.994,8.994,0,0,0,16.864,0Zm0,0",
            transform: "translate(-767.179 -598.916)",
            fill: "#d80027"
          }
        }),
        _c(
          "g",
          { attrs: { fill: "none", stroke: "#e4e4e4", "stroke-width": "1" } },
          [
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9", stroke: "none" }
            }),
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9.5", fill: "none" }
            })
          ]
        ),
        _c("g", { attrs: { transform: "translate(9.077 8.971)" } }, [
          _c("g", { attrs: { transform: "translate(-0.077 0.029)" } }, [
            _c("path", {
              attrs: {
                d:
                  "M764,339.5a4.5,4.5,0,1,1-4.5-4.5,4.5,4.5,0,0,1,4.5,4.5Zm0,0",
                transform: "translate(-755 -335)",
                fill: "#f0f0f0"
              }
            }),
            _c("path", {
              attrs: {
                d: "M950.9,487.549h4.7a4.5,4.5,0,0,0-.155-1.174H950.9Zm0,0",
                transform: "translate(-946.591 -483.049)",
                fill: "#d80027"
              }
            }),
            _c("path", {
              attrs: {
                d: "M950.9,380.7h4.035a4.518,4.518,0,0,0-1.038-1.174h-3Zm0,0",
                transform: "translate(-946.591 -378.545)",
                fill: "#d80027"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M835.117,701.056a4.48,4.48,0,0,0,2.8-.978h-5.6a4.481,4.481,0,0,0,2.8.978Zm0,0",
                transform: "translate(-830.617 -692.056)",
                fill: "#d80027"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M762.561,594.4h7.679a4.48,4.48,0,0,0,.505-1.174h-8.69a4.476,4.476,0,0,0,.505,1.174Zm0,0",
                transform: "translate(-761.9 -587.553)",
                fill: "#d80027"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M757.084,335.7h.41l-.381.277.146.448-.381-.277-.381.277.126-.387a4.526,4.526,0,0,0-.873.973h.131l-.243.176q-.057.095-.109.192l.116.357-.216-.157q-.081.171-.147.349l.128.393h.472l-.381.277.146.448-.381-.277-.229.166a4.553,4.553,0,0,0-.035.561h4.5V335a4.478,4.478,0,0,0-2.416.7Zm.174,3.347-.381-.277-.381.277.146-.448-.381-.277h.471l.146-.448.146.448h.472l-.381.277Zm-.146-1.759.146.448-.381-.277-.381.277.146-.448-.381-.277h.471l.146-.448.146.448h.472Zm1.76,1.759-.381-.277-.381.277.146-.448-.381-.277h.471l.146-.448.146.448h.471l-.381.277Zm-.146-1.759.146.448-.381-.277-.381.277.146-.448-.381-.277h.471l.146-.448.146.448h.471Zm0-1.311.146.448-.381-.277-.381.277.146-.448-.381-.277h.471l.146-.448.146.448h.471Zm0,0",
                transform: "translate(-755 -335)",
                fill: "#0052b4"
              }
            })
          ]),
          _c(
            "g",
            {
              attrs: {
                transform: "translate(-0.077 0.029)",
                fill: "none",
                stroke: "#fff",
                "stroke-width": "1"
              }
            },
            [
              _c("circle", {
                attrs: { cx: "4.5", cy: "4.5", r: "4.5", stroke: "none" }
              }),
              _c("circle", {
                attrs: { cx: "4.5", cy: "4.5", r: "5", fill: "none" }
              })
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }