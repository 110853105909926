<template>
  <svg class="icon-os" data-name="OS Icon" xmlns="http://www.w3.org/2000/svg" width="20.168" height="20.167" viewBox="0 0 20.168 20.167">
    <path d="M406.813,1105.918A10.083,10.083,0,1,0,416.9,1116,10.084,10.084,0,0,0,406.813,1105.918Zm6.07,16.154A8.585,8.585,0,1,1,415.4,1116,8.558,8.558,0,0,1,412.883,1122.072Z" transform="translate(-396.729 -1105.918)" fill="#939393"/>
    <path d="M406.652,1115.688a4.873,4.873,0,0,0-.691-2.5l-.009-.017-.012-.01a3.425,3.425,0,0,0-4.722,0l-.012.011-.009.015a4.847,4.847,0,0,0-.693,2.505c0,.115.005.229.013.344-.009.118-.014.237-.014.354a4.722,4.722,0,0,0,.7,2.466l.008.014.014.013a3.507,3.507,0,0,0,4.718,0l.012-.01.011-.017a4.746,4.746,0,0,0,.692-2.465c0-.118,0-.237-.012-.355S406.652,1115.8,406.652,1115.688Zm-2.033,2.115-.185.185a1.354,1.354,0,0,1-1.9-.184,3.861,3.861,0,0,1-.3-1.493l.011-.265v-.019l-.01-.252a4.053,4.053,0,0,1,.308-1.543,1.319,1.319,0,0,1,.274-.274,1.276,1.276,0,0,1,.759-.248,1.294,1.294,0,0,1,1.036.521,4.048,4.048,0,0,1,.306,1.54l-.008.259v.016l.009.27A3.864,3.864,0,0,1,404.619,1117.8Z" transform="translate(-396.729 -1105.918)" fill="#939393"/>
    <path d="M411.931,1116.03l-.014-.014a3.763,3.763,0,0,0-1.5-.781,7.112,7.112,0,0,1-1.287-.5.451.451,0,0,1-.218-.4.5.5,0,0,1,.228-.459,1.45,1.45,0,0,1,.782-.167c.392,0,1.081.058,2.056.17l.125.015.138-1.375-.486-.095a11.834,11.834,0,0,0-1.969-.21h-.009l-.158-.006a2.87,2.87,0,0,0-1.688.549l-.009.007a2.029,2.029,0,0,0-.7,1.531l.006.151,0,.1a1.858,1.858,0,0,0,.534,1.3l.011.01a4.244,4.244,0,0,0,1.751.843,3.467,3.467,0,0,1,.972.388.485.485,0,0,1,.2.392v.024a.72.72,0,0,1-.23.591,1.138,1.138,0,0,1-.743.2,17.3,17.3,0,0,1-2.118-.187l-.126-.019-.177,1.357.489.095h0a9.929,9.929,0,0,0,2.011.252l.125,0a2.778,2.778,0,0,0,1.725-.6l.006-.006a2.215,2.215,0,0,0,.722-1.635l0-.143,0-.149A1.9,1.9,0,0,0,411.931,1116.03Z" transform="translate(-396.729 -1105.918)" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconOs',
  };
</script>
