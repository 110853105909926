var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-dots",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "14",
        height: "3.013",
        viewBox: "0 0 13.058 3.013"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(13.058 3.013) rotate(180)" } }, [
        _c("circle", {
          attrs: { cx: "1.507", cy: "1.507", r: "1.507", fill: "#939393" }
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            cx: "1.507",
            cy: "1.507",
            r: "1.507",
            transform: "translate(5.022)",
            fill: "#939393"
          }
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            cx: "1.507",
            cy: "1.507",
            r: "1.507",
            transform: "translate(10.045)",
            fill: "#939393"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }