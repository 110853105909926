<template>
  <svg class="icon-people-add" xmlns="http://www.w3.org/2000/svg" width="24.515" height="23.86" viewBox="0 0 24.515 23.86">
    <g transform="translate(-225.444 -791.236)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
      <circle cx="3.414" cy="3.414" r="3.414" transform="translate(230.81 792.236)"/>
      <path d="M234.224 802.415a7.78 7.78 0 017.782 7.779v1.754a2.149 2.149 0 01-2.148 2.149h-11.266a2.148 2.148 0 01-2.148-2.149h0v-1.751a7.779 7.779 0 017.778-7.781zm10.885.411v-7.699m3.85 3.849h-7.699"/>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconPeopleAdd'
  }
</script>
