import Vue from 'vue';
import Vuelidate from 'vuelidate';
import router from '@app2/modules/Audience/Segment/segment.router';
import store from "@app2/store";

Vue.use(Vuelidate);

export default () => {
  return new Vue({
    el: '#segment-app',
    store,
    router,
    template: `<transition name="fade" mode="out-in"><router-view :key="${store.state.v2.audience.segment.segmentKey}"></router-view></transition>`
  });
};
