var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-folder-small",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "14.982",
        height: "12.961",
        viewBox: "0 0 14.982 12.961"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M256.629,62.106H251.2v-.84a1.841,1.841,0,0,0-1.848-1.814h-3.631a1.837,1.837,0,0,0-1.837,1.81v1.034l0,.012,0,.012v7.857a2.236,2.236,0,0,0,2.236,2.236h10.505a2.235,2.235,0,0,0,2.236-2.236V64.343h0A2.236,2.236,0,0,0,256.629,62.106Zm.737,2.237v5.833a.739.739,0,0,1-.737.737H246.124a.738.738,0,0,1-.737-.737V61.284a.338.338,0,0,1,.338-.333h3.631a.341.341,0,0,1,.349.335v.819h-1.857a.75.75,0,0,0,0,1.5h8.782a.74.74,0,0,1,.737.737Z",
          transform: "translate(-243.884 -59.451)",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }