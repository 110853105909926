var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "side-panel-template" }, [
    _c(
      "div",
      {
        staticClass: "side-panel-header pt-4",
        class: {
          "top-sticky-blur top-sticky-blur-neutral negative-mx-5 px-5":
            _vm.stickyHeader
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "inner mb-2",
            class: { "mt-2": _vm.stickyHeader && !_vm.backRoute }
          },
          [
            _vm.backRoute
              ? _c("router-link", {
                  attrs: { to: _vm.backRoute, custom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var navigate = ref.navigate
                          return [
                            _c(
                              "a",
                              {
                                staticClass: "txt-primary",
                                attrs: { role: "link" },
                                on: { click: navigate }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "icon-regular",
                                    attrs: { width: "24", viewBox: "0 0 24 24" }
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href": "#icon-arrow-left"
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", { staticClass: "back-label" }, [
                                  _vm._v(_vm._s(_vm.backLabel))
                                ])
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2344260563
                  )
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.topLabel
              ? _c(
                  "div",
                  { staticClass: "top-label txt-body txt-muted my-1 mr-auto" },
                  [_vm._v(_vm._s(_vm.topLabel))]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.backRoute && !_vm.hidePanelTitle
              ? _c("side-panel-title", {
                  key: _vm.$route.fullPath + _vm.titleKey,
                  ref: "title",
                  staticClass: "mb-auto",
                  attrs: {
                    error: _vm.titleError,
                    "error-message": _vm.titleErrorMessage,
                    "show-actions": _vm.renameActions,
                    editable: _vm.rename
                  },
                  on: {
                    blur: function($event) {
                      return _vm.$emit("blurTitle")
                    }
                  },
                  model: {
                    value: _vm.titleModel,
                    callback: function($$v) {
                      _vm.titleModel = $$v
                    },
                    expression: "titleModel"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm._t("header-cta"),
            _vm._v(" "),
            _c(
              "b-btn",
              {
                attrs: { variant: "icon ml-auto", size: "sm" },
                on: { click: _vm.closePanel }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "icon-sm",
                    attrs: { width: "24", viewBox: "0 0 24 24" }
                  },
                  [_c("use", { attrs: { "xlink:href": "#icon-cross" } })]
                )
              ]
            )
          ],
          2
        ),
        _vm._v(" "),
        _vm.backRoute && !_vm.hidePanelTitle
          ? _c(
              "div",
              { staticClass: "side-panel-group-header-sm pt-0 my-0" },
              [
                _c("side-panel-title", {
                  key: _vm.$route.fullPath + _vm.titleKey,
                  ref: "title",
                  staticClass: "my-0",
                  attrs: {
                    error: _vm.titleError,
                    "error-message": _vm.titleErrorMessage,
                    "show-actions": _vm.renameActions,
                    editable: _vm.rename
                  },
                  on: {
                    blur: function($event) {
                      return _vm.$emit("blurTitle")
                    }
                  },
                  model: {
                    value: _vm.titleModel,
                    callback: function($$v) {
                      _vm.titleModel = $$v
                    },
                    expression: "titleModel"
                  }
                }),
                _vm._v(" "),
                _vm.hasChanged
                  ? [
                      _c(
                        "b-btn",
                        {
                          attrs: { variant: "secondary", disabled: _vm.isBusy },
                          on: {
                            click: function($event) {
                              return _vm.$emit("cancel")
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            variant: "primary px-4 ml-2",
                            disabled: _vm.isBusy
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit("save")
                            }
                          }
                        },
                        [_vm._v("Save")]
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.subtitle
          ? _c("h5", { staticClass: "side-panel-title txt-bold" }, [
              _vm._v(_vm._s(_vm.subtitle))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._t("header")
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "side-panel-body" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }