var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-section-completed",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18",
        height: "18",
        viewBox: "0 0 18 18"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0m5.3,14.3A7.5,7.5,0,1,1,16.5,9a7.474,7.474,0,0,1-2.2,5.3",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M11.767,6.247,7.861,10.152,6.229,8.521a.75.75,0,1,0-1.06,1.061h0L7.33,11.744a.752.752,0,0,0,1.061,0l4.437-4.436a.75.75,0,0,0-1.061-1.061",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }