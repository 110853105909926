var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-menu",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "22.355",
        height: "15.395",
        viewBox: "0 0 22.355 15.395"
      }
    },
    [
      _c("path", {
        attrs: {
          d: "M59.691,432.7H47.263a1,1,0,0,0,0,2H59.691a1,1,0,1,0,0-2Z",
          transform: "translate(-46.263 -419.303)",
          fill: "currentColor"
        }
      }),
      _c("path", {
        attrs: {
          d: "M67.618,425.838H47.263a1,1,0,0,0,0,2H67.618a1,1,0,0,0,0-2Z",
          transform: "translate(-46.263 -419.303)",
          fill: "currentColor"
        }
      }),
      _c("path", {
        attrs: {
          d: "M47.263,421.3H67.618a1,1,0,0,0,0-2H47.263a1,1,0,0,0,0,2Z",
          transform: "translate(-46.263 -419.303)",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }