var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-info",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "16.213",
        height: "16.213",
        viewBox: "0 0 16.213 16.213"
      }
    },
    [
      _c("g", { attrs: { fill: "#fdedd2" } }, [
        _c("path", {
          staticClass: "fl-bg",
          attrs: {
            d:
              "M 8.1064453125 15.46289539337158 C 4.050095081329346 15.46289539337158 0.750005304813385 12.16280555725098 0.750005304813385 8.1064453125 C 0.750005304813385 4.050095081329346 4.050095081329346 0.750005304813385 8.1064453125 0.750005304813385 C 12.16280555725098 0.750005304813385 15.46289539337158 4.050095081329346 15.46289539337158 8.1064453125 C 15.46289539337158 12.16280555725098 12.16280555725098 15.46289539337158 8.1064453125 15.46289539337158 Z",
            stroke: "none"
          }
        }),
        _vm._v(" "),
        _c("path", {
          staticClass: "fl-prim",
          attrs: {
            d:
              "M 8.1064453125 1.499995231628418 C 4.463635444641113 1.499995231628418 1.499995231628418 4.463635444641113 1.499995231628418 8.1064453125 C 1.499995231628418 11.74924468994141 4.463635444641113 14.7128849029541 8.1064453125 14.7128849029541 C 11.74924468994141 14.7128849029541 14.7128849029541 11.74924468994141 14.7128849029541 8.1064453125 C 14.7128849029541 4.463635444641113 11.74924468994141 1.499995231628418 8.1064453125 1.499995231628418 M 8.1064453125 -3.814697265625e-06 C 12.58350563049316 -3.814697265625e-06 16.2128849029541 3.629375457763672 16.2128849029541 8.1064453125 C 16.2128849029541 12.58350563049316 12.58350563049316 16.2128849029541 8.1064453125 16.2128849029541 C 3.629375457763672 16.2128849029541 -3.814697265625e-06 12.58350563049316 -3.814697265625e-06 8.1064453125 C -3.814697265625e-06 3.629375457763672 3.629375457763672 -3.814697265625e-06 8.1064453125 -3.814697265625e-06 Z",
            stroke: "none",
            fill: "#f4a321"
          }
        })
      ]),
      _vm._v(" "),
      _c("path", {
        staticClass: "fl-prim",
        attrs: {
          d: "M.868-1.771V-7.506H2.4v5.735Zm0-6.469V-9.8H2.4v1.56Z",
          transform: "translate(6.523 13.702)",
          fill: "#f4a321"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }