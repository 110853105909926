var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-browser",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19.215",
        height: "19.214",
        viewBox: "0 0 19.215 19.214"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(-437.929 -1049.486)" } }, [
        _c("circle", {
          attrs: {
            cx: "8.857",
            cy: "8.857",
            r: "8.857",
            transform: "translate(438.68 1050.236)",
            fill: "none",
            stroke: "#939393",
            "stroke-linecap": "round",
            "stroke-width": "1.5"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.593,1050.457c-1.111,1.757-1.856,4.962-1.856,8.636s.745,6.879,1.856,8.636a8.658,8.658,0,0,0,3.887,0c1.111-1.756,1.856-4.961,1.856-8.636s-.745-6.879-1.856-8.636a8.654,8.654,0,0,0-3.887,0Z",
            fill: "none",
            stroke: "#939393",
            "stroke-linecap": "round",
            "stroke-width": "1.5"
          }
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            cx: "8.857",
            cy: "8.857",
            r: "8.857",
            transform: "translate(438.68 1050.236)",
            fill: "none",
            stroke: "#939393",
            "stroke-linecap": "round",
            "stroke-width": "1.5"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M456.173,1057.149c-1.757-1.11-4.962-1.856-8.637-1.856s-6.878.746-8.636,1.856a8.66,8.66,0,0,0,0,3.888c1.757,1.11,4.962,1.856,8.636,1.856s6.88-.746,8.637-1.856a8.66,8.66,0,0,0,0-3.888Z",
            fill: "none",
            stroke: "#939393",
            "stroke-linecap": "round",
            "stroke-width": "1.5"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }