import { Model } from '@vuex-orm/core';
import File from "@app/models/File";
import Audience from '@app/models/Audience';
import Playlist from '@app/models/Playlist';

export default class Tag extends Model {
  static entity = 'App\\Tag';

  static apiConfig = {
    baseURL: '/api/tags/',
    actions: {
      fetchForFiles(config) {
        return this.get(`files`, {
          ...config,
          dataKey: 'data'
        });
      },
      fetchForFile(id, config) {
        return this.get(`files/${id}`, {
          ...config,
          dataKey: 'data'
        });
      },
      fetchForAudiences(config) {
        return this.get(`audience`, {
          ...config,
          dataKey: 'data'
        });
      },
      fetchForAudience(id, config) {
        return this.get(`audience/${id}`, {
          ...config,
        });
      },
      fetchForPlaylists(config) {
        return this.get(`playlist`, {
          ...config,
          dataKey: 'data'
        });
      },
      fetchForPlaylist(id, config) {
        return this.get(`playlist/${id}`, {
          ...config,
        });
      },
      setForAudience(id, config) {
        return this.put(`audience/${id}`, {
          ...config
        });
      },
      setForAudiences(config) {
        return this.put(`audience`, {
          ...config
          }, {save: false});
      },
      attachToPlaylist(id, tags) {
        return this.post(`playlist/${id}/attach`, { ...tags }, {save: false});
      },
      detachToPlaylist(id, tags) {
        return this.post(`playlist/${id}/detach`, { ...tags }, {save: false});
      },
      attachFiles(tag, files) {
        return this.put(`bulk-attach`, {tag: tag.name, files}, {save: false});
      },
      detachFiles(tag, files) {
        return this.put(`bulk-detach`, {tag: tag.name, files}, {save: false});
      },
    }
  };

  static primaryKey = 'name';

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      slug: this.attr(null),
      type: this.attr(null),
      audience_hash: this.attr(null),
      audiences: this.belongsTo(Audience, 'audience_hash', 'hash'),
      file_hash: this.attr(null),
      files: this.belongsTo(File, 'file_hash', 'hash'),
      playlist_hash: this.attr(null),
      playlists: this.belongsTo(Playlist, 'playlist_hash', 'hash'),
    };
  }
}
