var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-section-grid",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18.107",
        height: "18.107",
        viewBox: "0 0 18.107 18.107"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M5.932 0H2.211A2.212 2.212 0 000 2.211v3.721a2.211 2.211 0 002.211 2.21h3.721a2.21 2.21 0 002.211-2.21V2.211A2.212 2.212 0 005.932 0zm.711 5.932a.712.712 0 01-.711.711H2.211a.713.713 0 01-.711-.711V2.211a.713.713 0 01.711-.711h3.721a.713.713 0 01.711.711zM15.896 0h-3.721a2.211 2.211 0 00-2.21 2.211v3.721a2.209 2.209 0 002.21 2.21h3.721a2.21 2.21 0 002.211-2.21V2.211A2.21 2.21 0 0015.896 0zm.711 5.932a.712.712 0 01-.711.711h-3.721a.712.712 0 01-.71-.711V2.211a.712.712 0 01.71-.711h3.721a.712.712 0 01.711.711zM15.896 9.965h-3.721a2.209 2.209 0 00-2.21 2.21v3.722a2.21 2.21 0 002.21 2.21h3.721a2.211 2.211 0 002.211-2.21v-3.723a2.21 2.21 0 00-2.211-2.209zm.711 5.932a.712.712 0 01-.711.71h-3.721a.711.711 0 01-.71-.71v-3.723a.711.711 0 01.71-.711h3.721a.712.712 0 01.711.711zM5.932 9.965H2.211A2.21 2.21 0 000 12.175v3.722a2.212 2.212 0 002.211 2.21h3.721a2.211 2.211 0 002.211-2.21v-3.723a2.21 2.21 0 00-2.211-2.209zm.711 5.932a.712.712 0 01-.711.71H2.211a.713.713 0 01-.711-.71v-3.723a.713.713 0 01.711-.711h3.721a.712.712 0 01.711.711z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }