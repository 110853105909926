var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "b-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c("form-control-text", {
            staticClass: "mb-3 mt-4",
            attrs: {
              state: _vm.$v.form.email.$error ? false : null,
              id: "loginEmail",
              placeholder: "Email address",
              label: "Email",
              required: "",
              type: "email"
            },
            on: {
              change: function($event) {
                return _vm.$v.form.email.$touch()
              }
            },
            model: {
              value: _vm.form.email,
              callback: function($$v) {
                _vm.$set(_vm.form, "email", $$v)
              },
              expression: "form.email"
            }
          }),
          _vm._v(" "),
          _c("form-control-text", {
            attrs: {
              state: _vm.$v.form.password.$error ? false : null,
              id: "loginPass",
              placeholder: "Password",
              label: "Password",
              required: "",
              type: "password",
              showPasswordCheck: ""
            },
            on: {
              change: function($event) {
                return _vm.$v.form.password.$touch()
              }
            },
            model: {
              value: _vm.form.password,
              callback: function($$v) {
                _vm.$set(_vm.form, "password", $$v)
              },
              expression: "form.password"
            }
          }),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "ml-auto",
              attrs: { to: "/login/forgot", size: "sm", variant: "link px-0" }
            },
            [_vm._v("Forgot password?")]
          ),
          _vm._v(" "),
          _c(
            "b-alert",
            { attrs: { show: !!_vm.error, fade: "", variant: "warning mb-0" } },
            [
              _c(
                "svg",
                {
                  staticClass: "alert-icon icon-sm",
                  attrs: { viewBox: "0 0 24 24" }
                },
                [
                  _c("use", {
                    attrs: { "xlink:href": "#icon-warning-triangle-color" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "alert-content" }, [
                _c("span", { staticClass: "txt-body" }, [
                  _vm._v(_vm._s(_vm.error))
                ]),
                _c("br"),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "alert-link",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onReset()
                      }
                    }
                  },
                  [_c("b", [_vm._v("Try again?")])]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "mt-3 mb-2",
              attrs: {
                id: "loginBtn",
                disabled: _vm.$v.$invalid || _vm.loading,
                block: "",
                type: "submit",
                variant: "primary"
              }
            },
            [_vm._v("\n      Sign in\n    ")]
          ),
          _vm._v(" "),
          _c("hr", { staticClass: "or my-4" }),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "mt-2 mb-4",
              attrs: {
                href: _vm.urlGoogle,
                target: _vm.target,
                id: "googleBtn",
                block: "",
                variant: "secondary"
              }
            },
            [
              _c(
                "svg",
                { staticClass: "btn-left", attrs: { viewBox: "0 0 24 24" } },
                [_c("use", { attrs: { "xlink:href": "#icon-google-logo" } })]
              ),
              _vm._v("\n      Sign in with Google\n    ")
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "mt-2 txt-body txt-center auth-page-cta with-arrow" },
        [
          _c("b", [_vm._v("Don’t have an account?")]),
          _vm._v(" "),
          _c(
            "b-btn",
            { attrs: { variant: "link ml-1", to: { name: "Register" } } },
            [_vm._v("Sign Up for free!")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "txt-heading txt-center mb-4" }, [
      _c("strong", [_vm._v("Welcome Back!")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }