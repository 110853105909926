import { Mark } from 'prosemirror-to-html-js';
import get from "lodash/get";
export default class TextSize extends Mark {
  matching () {
    return this.mark.type === "textSize";
  }

  tag () {
    return [
      {
        tag: "span",
        attrs: {
          'data-size': get(this.mark, 'attrs.size')
        }
      }
    ]
  }
}
