var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-download",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.072",
        height: "16.1",
        viewBox: "0 0 16.072 16.1"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M640.481,796.873H625.909a.75.75,0,0,0,0,1.5h14.572a.75.75,0,0,0,0-1.5Z",
          transform: "translate(-625.159 -782.273)",
          fill: "currentColor"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M632.665,795.6a.752.752,0,0,0,1.061,0l5.241-5.241a.75.75,0,0,0-1.061-1.06h0l-3.961,3.961V783.023a.75.75,0,0,0-1.5,0v10.233l-3.962-3.961a.75.75,0,0,0-1.06,1.062Z",
          transform: "translate(-625.159 -782.273)",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }