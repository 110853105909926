var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19",
        height: "19",
        viewBox: "0 0 19 19"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M14.172,362.9H5.025A4.933,4.933,0,0,0,.1,367.826v9.148A4.933,4.933,0,0,0,5.025,381.9h9.147a4.934,4.934,0,0,0,4.925-4.926v-9.148A4.809,4.809,0,0,0,14.172,362.9Zm0,17.593H5.025a3.389,3.389,0,0,1-3.417-3.418v-9.148a3.389,3.389,0,0,1,3.417-3.418h9.147a3.389,3.389,0,0,1,3.417,3.418v1.508H15.277a3.116,3.116,0,0,0,0,6.233h2.312v1.508A3.3,3.3,0,0,1,14.172,380.493Z",
          transform: "translate(-0.1 -362.9)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }