var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-element-linebreak",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18.047",
        height: "14.37",
        viewBox: "0 0 18.047 14.37"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M.75 1.502h1a.75.75 0 0 0 0-1.5h-1a.75.75 0 0 0 0 1.5ZM10.702 1.502h2.238a.75.75 0 0 0 0-1.5h-2.238a.75.75 0 0 0 0 1.5ZM5.107 1.502h2.238a.75.75 0 0 0 0-1.5H5.107a.75.75 0 0 0 0 1.5ZM16.297 1.502h1a.75.75 0 0 0 0-1.5h-1a.75.75 0 0 0 0 1.5ZM17.297 6.435H.75a.75.75 0 0 0 0 1.5h16.547a.75.75 0 0 0 0-1.5ZM1.75 12.87h-1a.75.75 0 0 0 0 1.5h1a.75.75 0 0 0 0-1.5ZM7.345 12.87H5.107a.75.75 0 0 0 0 1.5h2.238a.75.75 0 0 0 0-1.5ZM12.94 12.87h-2.238a.75.75 0 0 0 0 1.5h2.238a.75.75 0 0 0 0-1.5ZM17.297 12.87h-1a.75.75 0 0 0 0 1.5h1a.75.75 0 0 0 0-1.5Z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }