<template>
  <svg id="Vimeo_Icon" data-name="Vimeo Icon" class="icon-vimeo-plus" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <g id="Group_4432" data-name="Group 4432" transform="translate(-2207 -355)">
      <path id="Path_1049" data-name="Path 1049" d="M2.5,0h20A2.5,2.5,0,0,1,25,2.5v20A2.5,2.5,0,0,1,22.5,25H2.5A2.5,2.5,0,0,1,0,22.5V2.5A2.5,2.5,0,0,1,2.5,0Z" transform="translate(2207 355)" fill="#1ab7ea"/>
      <g id="Group_4114" data-name="Group 4114" transform="translate(2209.977 359.691)">
        <path id="Path_1048" data-name="Path 1048" d="M32.346,26.113q-.121,2.637-3.683,7.219-3.683,4.785-6.231,4.786-1.58,0-2.671-2.914L18.3,29.859q-.811-2.914-1.74-2.915a5.587,5.587,0,0,0-1.417.85L14.3,26.7q1.337-1.174,2.635-2.35a5.974,5.974,0,0,1,2.676-1.621q2.107-.2,2.594,2.881.526,3.328.73,4.138.608,2.761,1.338,2.76.566,0,1.7-1.791a7.055,7.055,0,0,0,1.215-2.726q.162-1.545-1.215-1.547a3.383,3.383,0,0,0-1.337.3q1.332-4.36,5.086-4.237Q32.506,22.585,32.346,26.113Z" transform="translate(-14.297 -22.5)" fill="#fff"/>
      </g>
    </g>
    <g id="Group_4434" data-name="Group 4434" transform="translate(-2200.447 -299.447)">
      <g id="Ellipse_184" data-name="Ellipse 184" transform="translate(2215.447 314.447)" fill="#fff" stroke="#23b6ea" stroke-width="1.5">
        <circle cx="6.5" cy="6.5" r="6.5" stroke="none"/>
        <circle cx="6.5" cy="6.5" r="5.75" fill="none"/>
      </g>
      <g id="Plus" transform="translate(2220 321.02) rotate(-45)">
        <line id="Line_1" data-name="Line 1" y1="2.858" x2="2.858" fill="none" stroke="#23b6ea" stroke-linecap="round" stroke-width="1.4"/>
        <line id="Line_2" data-name="Line 2" x2="2.858" y2="2.858" transform="translate(0 0)" fill="none" stroke="#23b6ea" stroke-linecap="round" stroke-width="1.4"/>
      </g>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-vimeo-plus'
  }
</script>
