<template>
  <svg class="icon-element-subheadline" xmlns="http://www.w3.org/2000/svg" width="21.985" height="14.728" viewBox="0 0 21.985 14.728">
    <path d="M8.636 13.228H7.287V1.5h4.287v1.812a.75.75 0 0 0 1.5 0V0H.002v3.312a.75.75 0 0 0 1.5 0V1.5H5.79v11.728H4.441a.75.75 0 0 0 0 1.5h4.2a.75.75 0 0 0 0-1.5Z" fill="#939393"/>
    <path d="M10.445 7.364a.75.75 0 0 0 .75.75h10.04a.75.75 0 0 0 0-1.5h-10.04a.75.75 0 0 0-.75.75ZM21.235 9.918h-10.04a.75.75 0 0 0 0 1.5h10.04a.75.75 0 0 0 0-1.5ZM18.389 13.228h-7.194a.75.75 0 0 0 0 1.5h7.194a.75.75 0 0 0 0-1.5Z" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-element-subheadline'
  }
</script>
