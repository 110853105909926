import store from "@app2/store";

let counter = 0;

const csrfToken = document.head.querySelector('meta[name="csrf-token"]');
if (!csrfToken) {
  throw 'CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token';
}
export default function(axios) {
  return {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': csrfToken.content,
    },
    transformRequest: axios.defaults.transformRequest.concat((data, headers) => {
      counter++;
      headers['X-CSRF-TOKEN'] = store.state.auth.token || csrfToken.content;
      return data;
    }),
  };
}
