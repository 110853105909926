<template>
  <svg id="Thumbs_down_Icon" data-name="Thumbs down Icon" xmlns="http://www.w3.org/2000/svg" width="15.621" height="14.984" viewBox="0 0 15.621 14.984">
    <path id="Path_7124" data-name="Path 7124" d="M754.783,562.909h-2.848v2.274a3.069,3.069,0,0,1-1.839,2.812l-.349.152a1.329,1.329,0,0,1-.538.114,1.343,1.343,0,0,1-1.318-1.089l-.158-.813a5.307,5.307,0,0,0-1.187-2.445l-1.592-1.847a.744.744,0,0,1-.182-.489v-7.551a.749.749,0,0,1,.75-.75h7.857a3.087,3.087,0,0,1,3.034,2.516l.79,4.2a2.424,2.424,0,0,1,.042.46A2.462,2.462,0,0,1,754.783,562.909Zm.945-2.642-.789-4.2a1.588,1.588,0,0,0-1.56-1.294h-7.107V561.3l1.41,1.636a6.8,6.8,0,0,1,1.523,3.137l.121.622.169-.074a1.568,1.568,0,0,0,.94-1.437v-3.024a.749.749,0,0,1,.75-.75h3.6a.966.966,0,0,0,.963-.958Z" transform="translate(-741.624 -553.277)" fill="#939393"/>
    <path id="Path_7125" data-name="Path 7125" d="M742.374,568.261a.75.75,0,0,1-.75-.75v-7a.75.75,0,0,1,1.5,0v7A.75.75,0,0,1,742.374,568.261Z" transform="translate(-741.624 -559.759)" fill="#939393"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconThumbsDOwn'
  }
</script>
