var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-interface",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18.401",
        height: "21.663",
        viewBox: "0 0 18.401 21.663"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M18.4 12.865a2.508 2.508 0 00-2.507-2.495 2.473 2.473 0 00-1.046.238 2.563 2.563 0 00-.845-1.471 6.018 6.018 0 10-7.625 2.341v1.271a.5.5 0 01-.852.353l-1.168-1.169-.011-.011a2.562 2.562 0 00-3.271-.35l-.015.01a2.5 2.5 0 00-.6 3.492l.039.05.193.228.043.047 4.92 4.916a4.6 4.6 0 003.256 1.347h5.116a4.371 4.371 0 004.371-4.37v-4.428zM4.349 6.017a4.518 4.518 0 118.247 2.523c-.069-.006-.137-.018-.208-.018a2.463 2.463 0 00-1 .22V5.978a2.568 2.568 0 00-2.062-2.519h-.02A2.5 2.5 0 006.415 5.51a2.528 2.528 0 00-.035.418v3.848a4.5 4.5 0 01-2.031-3.759zm9.68 14.147H8.913a3.111 3.111 0 01-2.2-.909l-4.9-4.894-.137-.163a.992.992 0 01-.181-.57 1 1 0 01.42-.816 1.054 1.054 0 01.578-.173 1.066 1.066 0 01.774.332l.011.011 1.18 1.181a2 2 0 003.413-1.414V5.925l.014-.167a1 1 0 01.989-.839l.153.013a1.068 1.068 0 01.852 1.046v5.049a.751.751 0 00.75.75.751.751 0 00.75-.75l.014-.167a1.006 1.006 0 01.989-.839l.155.014a1.07 1.07 0 01.85 1.045v1.7a.75.75 0 001.085.671.755.755 0 00.414-.628 1.004 1.004 0 012.007.051v4.42a2.876 2.876 0 01-2.864 2.87z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }