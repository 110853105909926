<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16.764" height="16.651" viewBox="0 0 16.764 16.651" class="icon-calendar-big">
    <g id="Calendar_Icon_Big" data-name="Calendar Icon Big" transform="translate(0)">
      <path id="Path_2699" data-name="Path 2699" d="M1416.339,491.089h-.376v-1.176a.75.75,0,0,0-1.5,0v1.176h-4.491v-1.176a.75.75,0,0,0-1.5,0v1.176h-.377a4.26,4.26,0,0,0-4.26,4.26v6.206a4.259,4.259,0,0,0,4.26,4.259h8.244a4.26,4.26,0,0,0,4.26-4.259v-6.206A4.26,4.26,0,0,0,1416.339,491.089Zm2.76,10.466a2.764,2.764,0,0,1-2.76,2.76h-8.244a2.764,2.764,0,0,1-2.761-2.76v-6.206a2.765,2.765,0,0,1,2.761-2.761h.377v1.178a.75.75,0,0,0,1.5,0v-1.178h4.491v1.178a.75.75,0,0,0,1.5,0v-1.178h.376a2.766,2.766,0,0,1,2.76,2.761Z" transform="translate(-1403.835 -489.163)" fill="#979797"/>
      <path id="Path_2700" data-name="Path 2700" d="M1408.7,495.719a.75.75,0,0,0-.75.75v1.019a.75.75,0,0,0,1.5,0v-1.019A.75.75,0,0,0,1408.7,495.719Z" transform="translate(-1403.835 -489.163)" fill="#979797"/>
      <path id="Path_2701" data-name="Path 2701" d="M1412.217,495.719a.749.749,0,0,0-.75.75v1.019a.75.75,0,0,0,1.5,0v-1.019A.75.75,0,0,0,1412.217,495.719Z" transform="translate(-1403.835 -489.163)" fill="#979797"/>
      <path id="Path_2702" data-name="Path 2702" d="M1415.729,495.719a.75.75,0,0,0-.75.75v1.019a.75.75,0,0,0,1.5,0v-1.019A.749.749,0,0,0,1415.729,495.719Z" transform="translate(-1403.835 -489.163)" fill="#979797"/>
      <path id="Path_2703" data-name="Path 2703" d="M1408.7,499.441a.75.75,0,0,0-.75.75v1.019a.75.75,0,0,0,1.5,0v-1.019A.75.75,0,0,0,1408.7,499.441Z" transform="translate(-1403.835 -489.163)" fill="#979797"/>
      <path id="Path_2704" data-name="Path 2704" d="M1412.217,499.441a.75.75,0,0,0-.75.75v1.019a.75.75,0,0,0,1.5,0v-1.019A.75.75,0,0,0,1412.217,499.441Z" transform="translate(-1403.835 -489.163)" fill="#979797"/>
    </g>
  </svg>

</template>
<script>
  export default {
    name: 'IconCalendarBig',
  };
</script>
