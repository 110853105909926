var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Loupe_Search_Bar_Icon",
        "data-name": "Loupe Search Bar Icon",
        xmlns: "http://www.w3.org/2000/svg",
        width: "18.411",
        height: "12.25",
        viewBox: "0 0 18.411 12.25"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Path_2591",
          "data-name": "Path 2591",
          d:
            "M1350.362,850.1a.75.75,0,0,0-.75-.75h-2.165a.75.75,0,0,0,0,1.5h.408l-4.127,4.127-3.938-3.937a.749.749,0,0,0-1.061,0l-3.271,3.271a2.347,2.347,0,1,0,.961,1.161l2.84-2.841,3.938,3.938a.752.752,0,0,0,1.061,0l4.6-4.6v.3a.75.75,0,0,0,1.5,0Zm-16.1,6.991a.811.811,0,1,1,.811-.811A.813.813,0,0,1,1334.262,857.1Z",
          transform: "translate(-1331.951 -849.355)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_2592",
          "data-name": "Path 2592",
          d:
            "M1343.23,858.209a.75.75,0,0,0-.75.75v1.9a.75.75,0,0,0,1.5,0v-1.9A.75.75,0,0,0,1343.23,858.209Z",
          transform: "translate(-1331.951 -849.355)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_2593",
          "data-name": "Path 2593",
          d:
            "M1347.158,857.1a.75.75,0,0,0-.75.75v3.008a.75.75,0,0,0,1.5,0v-3.008A.75.75,0,0,0,1347.158,857.1Z",
          transform: "translate(-1331.951 -849.355)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_2594",
          "data-name": "Path 2594",
          d:
            "M1339.3,855.244a.749.749,0,0,0-.75.75v4.861a.75.75,0,0,0,1.5,0v-4.861A.75.75,0,0,0,1339.3,855.244Z",
          transform: "translate(-1331.951 -849.355)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }