var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.466",
        height: "14.47",
        viewBox: "0 0 17.466 14.47"
      }
    },
    [
      _c("g", { attrs: { transform: "rotate(180 8.733 7.235)" } }, [
        _c("path", {
          attrs: {
            d:
              "M16.739 6.481H2.585l5.2-5.2A.75.75 0 006.724.221L.239 6.705a.75.75 0 000 1.061l6.485 6.485a.75.75 0 101.061-1.06L2.579 7.985h14.157a.75.75 0 000-1.5z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }