<template>
  <audience-slide-in-template
    :back-route="{name: 'ImportIndex'}"
    back-label="Back to Import Audience"
    hidePanelTitle
  >
    <h6 class="txt-title-small"><b>Step 1</b> of 3 <br /><span class="txt-muted">Bulk actions</span></h6>
    <hr class="mb-3"/>
    <div class="tag-list mb-3">
      <span class="tag-basic tag-variant-dark">
        <svg viewBox="0 0 24 24" class="icon-sm mr-2"><use xlink:href="#icon-page-single"></use></svg>
        <span>{{ fileName }}</span>
      </span>
    </div>
    <h6 class="txt-body txt-muted mb-3">
      Organise your contacts import by adding <br />tags and selecting Segment.
    </h6>

    <div class="side-panel-widget p-4">
      <form-control-select
        v-model="tags"
        placeholder="Search Audience tags..."
        tag-placeholder="Add new tag"
        option-icon="tag"
        title="Add Audience Tags"
        track-by="name"
        select-label="name"
        tag-icon="tag"
        multiple
        infinite 
        taggable
        :options="tagOptions"
        :type="`tag${tags.length > 1 ? 's' : ''}`"
        :close-on-select="false"
        hide-selected
        searchVisible 
        :blockKeys="['Delete']"
        @tag="addTag"
        @infinite="tagsInfiniteHandler"
      />
    </div>
    <div class="side-panel-widget p-4">
      <div class="fx-row-center">
        <span class="txt-heading-small" :class="{'txt-muted-2': $cantView('hubs')}">Give Access</span>
        <upgrade-button v-if="$cantView('hubs')" class="ml-auto" />
      </div>
      <form-control-select
        v-model="hubs"
        placeholder="Search hubs..."
        option-icon="hub"
        track-by="hash"
        select-label="title"
        tag-icon="hub"
        selected-title="Access given to"
        :options="hubOptions"
        :type="`hub${hubs.length > 1 ? 's' : ''}`"
        :close-on-select="false" 
        :actions="true"
        :disabled="$cantView('hubs')"
        multiple
        infinite
        hide-selected
        searchVisible 
        :fetching="fetching"
        :identifier="hubIdentifier"
        :blockKeys="['Delete']"
        @infinite="hubsInfiniteHandler"
        @search-change="resetHubId"
      />
      <template v-if="hubs.length">
        <hr class="mt-3 mb-4"/>
        <b-alert :show="$cant('send-email')" fade variant="info" class="mb-3">
          <svg width="24" class="alert-icon icon-sm" viewBox="0 0 24 24"><use xlink:href="#icon-status-question-color"></use></svg>
          <div class="alert-content">
            <span class="txt-body">To enable sending email notifications, contact support.</span>
            <a href="#" class="alert-link" @click.prevent="openChat">
              <b>Contact support.</b>
            </a>
          </div>
        </b-alert>
        <form-control-checkbox
          :disabled="$cant('send-email')"
          v-model="sendEmail"
          :value="1"
          :unchecked-value="0"
          label="Welcome email notification"
          description="If turned on the user will receive a welcome email to access these hubs." />
      </template>
    </div>

    <div class="side-panel-library-cta p-3">
      <b-btn block variant="primary" size="lg" :to="{name: 'UploadStep2'}">
        Next Step
        <svg class="btn-right" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-arrow-right"></use></svg>
      </b-btn>
    </div>
  </audience-slide-in-template>
</template>

<script>
  import Tag from "@app2/models/Tag";
  import Hub from "@app2/models/Hub";
  import merge from "lodash/merge";
  import debounce from "lodash/debounce";
  import FormControlSelect from "@app2/core/Components/Common/Forms/Select";
  import FormControlCheckbox from "@app2/core/Components/Forms/Checkbox";
  import AudienceSlideInTemplate from "@app2/modules/Audience/Components/Template";
  import UpgradeButton from '@app2/core/Components/UpgradeButton.vue';
  
  export default {
    name: 'UploadStep1',
    components: {
      FormControlSelect,
      AudienceSlideInTemplate,
      FormControlCheckbox,
      UpgradeButton
    },
    data() {
      return {
        tagPage: 1,
        hubPage: 1,
        hubOptions: [],
        hubLast: null,
        hubIdentifier: +new Date(),
        fetching: false,
      }
    },
    computed: {
      tagOptions() {
        return Tag.query().where('type', 'audience').all();
      },
      // hubOptions() {
      //   return Tag.query().all();
      // },
      fileName() {
        return this.$store.getters['v2/audience/csvFile'].name
      },
      tags: {
        get() {
          return this.$store.getters['v2/audience/importTags']
        },
        set(val) {
          this.$store.commit('v2/audience/SET_IMPORT_DATA', { key: 'tags', data: val })
        }
      },
      hubs: {
        get() {
          return this.$store.getters['v2/audience/importHubs']
        },
        set(value) {
          this.$store.commit('v2/audience/SET_IMPORT_DATA', { key: 'hubs', data: value })
        }
      },
      sendEmail: {
        get() {
          return this.$store.getters['v2/audience/importNotify']
        },
        set(val) {
          this.$store.commit('v2/audience/SET_IMPORT_DATA', { key: 'notify', data: val})
        }
      }
    },
    methods: {
      openChat() {
        if (!window.HubSpotConversations) return;
        window.HubSpotConversations.widget.load();
        window.HubSpotConversations.widget.open();
      },
      async tagsInfiniteHandler($state) {
        try {
          const { response: { data } } = await Tag.api().fetchForAudiences({ params: { page: this.tagPage++ } });
          if (data.data.length) {
            $state.loaded();
          } else {
            $state.complete();
          }
          this.tagLast = data.meta.last_page;
        } catch (e) {
          $state.error(e);
          throw e;
        }
      },
      async hubsInfiniteHandler($state) {
        try {
          this.fetching = true;
          const { response: { data } } = await Hub.api().fetch(({ params: { page: this.hubPage++ } }));
          // this.hubOptions = merge(this.hubOptions, data.data);
          data.data.map((hub)=>{
              if (hub.auth === true) {
                this.hubOptions.push(hub)
              }
            })
          if (data.data.length) {
            $state.loaded();
          } else {
            $state.complete();
          }
          this.hubLast = data.meta.last_page;
        } catch (e) {
          $state.error(e);
          throw e;
        } finally {
          this.fetching = false;
        }
      },
      resetHubId() {
        this.fetching = true;
        if (this.hubPage >= this.hubLast && this.hubLast)
          return;
        this.hubIdentifier = +new Date();
        setTimeout(()=>{
          this.fetching = false;
        }, 1000)
      },
      debouncer: debounce((cb, args) => cb(args), 100),
      resetTagId() {
        if (this.tagPage >= this.tagLast && this.tagLast)
          return;
        this.tagIdentifier = +new Date();
      },
      async addTag(tag) {
        const data = { name: tag, temp: true, type: 'files', id: tag};
        // this.tempTag = data;
        Tag.insertOrUpdate({ data });
        this.tags = [...this.tags, data];
      }
    },
    async created() {
      await Tag.api().fetchForAudiences();
      const { response: { data } } = await Hub.api().fetch(({ params: { page: this.hubPage++ } }));
      data.data.forEach(item => {
        if (item.auth === true) {
          this.hubOptions.push(item);
        }
      })
    },
  }
</script>
