import { Node } from 'prosemirror-to-html-js';
export default class HardBreak extends Node {
  matching () {
    return this.node.type === "hardBreak";
  }

  tag () {
    return "br";
  }

  selfClosing() {
    return true;
  }
}
