<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" class="icon-dots" height="3.013" viewBox="0 0 13.058 3.013">
    <g transform="translate(13.058 3.013) rotate(180)">
      <circle cx="1.507" cy="1.507" r="1.507" fill="#939393"/>
      <circle cx="1.507" cy="1.507" r="1.507" transform="translate(5.022)" fill="#939393"/>
      <circle cx="1.507" cy="1.507" r="1.507" transform="translate(10.045)" fill="#939393"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-dots'
  }
</script>
