<template>
  <div class="mb-4">
    <h6 class="txt-heading-small txt-muted mb-3">Here you can see all your exports of Audience.</h6>
    <hr class="mb-3" />
    <exports-item
      v-for="item in exports"
      :item="item"
      :key="item.id"
    />
  </div>
</template>
<script>
  import ExportsItem from "@app2/modules/Audience/Export/Components/Item";

  export default {
    name: 'ExportsList',
    components: {
      ExportsItem,
    },
    props: {
      exports: {
        type: Array,
        required: true
      },
    },
    methods: {
      goToAudienceList() {
        this.$store.commit('v2/audience/exports/SET_MODAL_STATE', false)
      }
    }
  }
</script>
