var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c("div", { staticClass: "settings-photo" }, [
        _c(
          "form",
          { attrs: { role: "form" } },
          [
            _c("div", { staticClass: "photo-upload" }, [
              _c(
                "div",
                { staticClass: "photo-preview" },
                [
                  _c("icon-user"),
                  _vm._v(" "),
                  _c("span", {
                    style: _vm.previewStyle,
                    attrs: { role: "img" }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _vm.form.errors.has("photo")
              ? _c("b-form-invalid-feedback", [
                  _vm._v(
                    "\n      " + _vm._s(_vm.form.errors.get("photo")) + "\n    "
                  )
                ])
              : _vm._e()
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }