var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-menu-analytics",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "28",
        height: "23",
        viewBox: "0 0 28 23"
      }
    },
    [
      _c("rect", {
        attrs: { width: "28", height: "23", fill: "#fff", opacity: "0" }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M68.339,216.268a.751.751,0,0,0-.75-.75H62.433a.75.75,0,0,0,0,1.5H65.78L59.7,223.1l-4.67-4.669a.749.749,0,0,0-1.061,0l-5.025,5.026a3.07,3.07,0,1,0,1.032,1.089l4.523-4.524,4.671,4.669a.751.751,0,0,0,1.06,0l6.607-6.608v3.344a.75.75,0,0,0,1.5,0ZM47.361,227.512A1.509,1.509,0,1,1,48.87,226,1.512,1.512,0,0,1,47.361,227.512Z",
          transform: "translate(-43.352 -213.518)",
          fill: "#293ad3"
        }
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cl-3",
        attrs: {
          d:
            "M64.323,225.944a.75.75,0,0,0-.75.75v7.064a.75.75,0,1,0,1.5,0v-7.064A.75.75,0,0,0,64.323,225.944Z",
          transform: "translate(-43.352 -213.518)",
          fill: "#7b88ff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cl-3",
        attrs: {
          d:
            "M59.186,228.774a.749.749,0,0,0-.75.75v4.234a.75.75,0,0,0,1.5,0v-4.234A.75.75,0,0,0,59.186,228.774Z",
          transform: "translate(-43.352 -213.518)",
          fill: "#7b88ff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cl-3",
        attrs: {
          d:
            "M54.048,225.008a.75.75,0,0,0-.75.75v8a.75.75,0,0,0,1.5,0v-8A.75.75,0,0,0,54.048,225.008Z",
          transform: "translate(-43.352 -213.518)",
          fill: "#7b88ff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cl-3",
        attrs: {
          d:
            "M64.323,225.944a.75.75,0,0,0-.75.75v7.064a.75.75,0,1,0,1.5,0v-7.064A.75.75,0,0,0,64.323,225.944Z",
          transform: "translate(-43.352 -213.518)",
          fill: "#7b88ff"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }