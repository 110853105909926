import axios from '@app2/api/axios';

const scriptUrl = 'https://js.hs-scripts.com/23365482.js';

const setSettings = (settings = {}) => {
    window.hsConversationsSettings = Object.assign({loadImmediately: false}, window.hsConversationsSettings, settings);
}

const loadScript = async (src) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.addEventListener('load', () => {
            resolve();
        });
        script.addEventListener('error', (error) => {
            reject(error);
        });
        document.body.appendChild(script);
    });
};

const identifyUser = async (user) => {
    const { data } = await axios.get('/api/internal/v2/teams/chat/token');
    if ( data.token ) {
        setSettings({
            identificationEmail: user.email,
            identificationToken: data.token
         });
    }
};

const setListener = () => {
    window.addEventListener('message', event => {
        if ( event.origin !== 'https://app.hubspot.com' ) return;
        event = JSON.parse(event.data);
        if ( event.type === "open-change" && !event.data.isOpen ) {
            window.HubSpotConversations.widget.remove();
        }
    });
}

const loadHubspot = async (user) => {
    setSettings();
    setListener();
    await identifyUser(user);
    await loadScript(scriptUrl);
}

const unloadHubspot = () => {
    if ( !window.HubSpotConversations ) return;
    window.HubSpotConversations.widget.remove();
    window.HubSpotConversations.clear();
    setTimeout(() => {
        location.reload();
    }, 100);

}

export { loadHubspot, unloadHubspot };