var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 20 20"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(1 1)" } }, [
        _c("path", {
          attrs: {
            d: "M773,344a9,9,0,0,1-18,0s9-9,9-9a9,9,0,0,1,9,9Zm0,0",
            transform: "translate(-755 -335)",
            fill: "#0052b4"
          }
        }),
        _c("path", {
          attrs: {
            d: "M959.254,539.278h.024v-.024l-.024.024Zm0,0",
            transform: "translate(-950.278 -530.278)",
            fill: "#f0f0f0"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M764,339.7V335h0a9,9,0,0,0-9,9h4.7v-2.644L762.34,344h1.636l.024-.024V342.34l-2.644-2.644Zm0,0",
            transform: "translate(-755 -335)",
            fill: "#f0f0f0"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M785.091,361.711a9.046,9.046,0,0,0-3.379,3.379v4.447h2.348v-5.478h5.478v-2.348Zm0,0",
            transform: "translate(-780.537 -360.537)",
            fill: "#d80027"
          }
        }),
        _c("path", {
          attrs: {
            d: "M866.156,445.052l-3.2-3.2h-1.107l4.3,4.3Zm0,0",
            transform: "translate(-857.156 -437.159)",
            fill: "#d80027"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M834.855,575.418l.494,1.033,1.115-.258-.5,1.03.9.711-1.117.252,0,1.145-.893-.716-.893.716,0-1.145-1.117-.252.9-.711-.5-1.03,1.115.258Zm0,0",
            transform: "translate(-829.427 -564.853)",
            fill: "#f0f0f0"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M1039.8,619.937l.247.517.558-.129-.25.515.448.356-.558.126,0,.573-.446-.358-.447.358v-.573l-.558-.126.448-.356-.25-.515.558.129Zm0,0",
            transform: "translate(-1026.322 -607.415)",
            fill: "#f0f0f0"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M987.516,495.277l.247.517.558-.129-.25.515.449.356-.558.126,0,.573-.447-.358-.447.358,0-.573-.558-.126.449-.356-.25-.515.558.129Zm0,0",
            transform: "translate(-976.338 -488.234)",
            fill: "#f0f0f0"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M1039.8,424.043l.247.517.558-.129-.25.515.448.356-.558.126,0,.573-.446-.358-.447.358v-.573l-.558-.126.448-.356-.25-.515.558.129Zm0,0",
            transform: "translate(-1026.322 -420.13)",
            fill: "#f0f0f0"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M1085.464,477.469l.247.517.558-.129-.25.515.449.356-.558.126,0,.573-.447-.358-.446.358,0-.573-.558-.126.449-.356-.25-.515.557.129Zm0,0",
            transform: "translate(-1069.983 -471.208)",
            fill: "#f0f0f0"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M1056.737,539.8l.194.6h.629l-.509.369.194.6-.509-.37-.509.37.194-.6-.509-.369h.629Zm0,0",
            transform: "translate(-1042.69 -530.801)",
            fill: "#f0f0f0"
          }
        }),
        _c(
          "g",
          { attrs: { fill: "none", stroke: "#e4e4e4", "stroke-width": "1" } },
          [
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9", stroke: "none" }
            }),
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9.5", fill: "none" }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }