var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-element-paragraph",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.193",
        height: "11.422",
        viewBox: "0 0 16.193 11.422"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M15.443 3.313H.75a.75.75 0 0 0 0 1.5h14.693a.75.75 0 0 0 0-1.5ZM15.443 6.613H.75a.75.75 0 0 0 0 1.5h14.693a.75.75 0 0 0 0-1.5ZM.75 1.5h14.693a.75.75 0 0 0 0-1.5H.75a.75.75 0 0 0 0 1.5ZM9.75 9.922h-9a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5Z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }