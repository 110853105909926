import axios from '@app2/api/axios';
import File from '@app2/models/File';
import FilePlaylist from "@app2/models/FilePlaylist";
import eventBus from '@app2/core/eventBus';
import cloneDeep  from 'lodash/cloneDeep'

import * as tus from 'tus-js-client';

const namespaced = true;

const state = {
  files: [
  ],
  toDelete: 0,
  uploading: false,
  queuedFiles: [],
  folderHash: null,
  isQueueing: false,
  playlist: null // attach file to playlist
};
const mutations = {
  async SET_FILES(state, files) {
    state.files.push(...files)
  },
  CLEAR_FILES(state) {
    state.files = []
  },
  REMOVE_FILE(state, index) {
    state.files.splice(index, 1);
  },
  SET_FOLDER_HASH(state, hash) {
    state.folderHash = hash;
  },
  SET_UPLOADING(state, value) {
    state.uploading = value;
  },
  TO_DELETE_INCREMENT(state) {
    state.toDelete++;
  },
  TO_DELETE_RESET(state) {
    state.toDelete = 0;
  },
  SET_PLAYLIST(state, value) {
    state.playlist = value
  }
};

const actions = {
  async replaceFile({ state, dispatch, commit }, {
    hash: fileHash,
    folderHash: folderId
  }) {
    if (!folderId && state.folderHash) folderId = state.folderHash;
    commit('SET_UPLOADING', true);
    commit('SET_FOLDER_HASH', folderId);
    await Promise.all(state.files.map(file => {
      if (!file.uploaderInstance) {
        uploadFile(file).then(upload => dispatch('saveUpload', { fileHash, folderId, upload, file, replace: true }))
      }
    }));
  },
  async uploadFiles({ state, dispatch, commit }, folderId) {
    if (!folderId && state.folderHash) folderId = state.folderHash;
    commit('SET_UPLOADING', true);
    commit('SET_FOLDER_HASH', folderId);

    let playlistHash = state.playlist?.hash

    await Promise.all(state.files.map(file => {
      if (!file.uploaderInstance) {
        uploadFile(file).then(upload => dispatch('saveUpload', { folderId, upload, file, playlistHash }))
      }
    }));
  },
  async saveUpload({ state, commit, getters }, { fileHash, folderId, upload, file, replace = false, playlistHash }) {
    try {
      let payload = {
        file_name: file.name,
        file_type: upload.file.type,
        file_url: upload.url,
      }

      if (replace) {
        payload.replace = true
        payload.file_hash = fileHash
      }

      let { data } = await axios.post(`/api/folders/${folderId}/media`, payload);
      data.connection_type = 'local'

      if (replace) {
        data.status_replace = 'replace_pending'
      } else {
        data.status_upload = 'media_pending'
      }
      File.insertOrUpdate({ data });
      if (data.meta?.collection_name === 'document') {
        eventBus.$emit('DocumentStatusUpdated', data)
      } else {
        eventBus.$emit('FileStatusUpdated', data)
      }

      // attach file to playlist if exists
      if (playlistHash) {
        await FilePlaylist.api().attachFiles({ hash: playlistHash }, [data.id]);
      }
    } catch (e) {
      throw e;
    } finally {
      commit('SET_PLAYLIST', null)
      commit('TO_DELETE_INCREMENT');
      // commit('SET_UPLOADING', false);
      if (state.toDelete === state.files.length) {
        commit('CLEAR_FILES');
        commit('TO_DELETE_RESET');
      }
    }
  },
  async abort({ state, commit }) {
    const aborts = state.files.map((file, i, files) =>
      abort(file)
        .then(() => {
          commit('TO_DELETE_INCREMENT');
          files.splice(i, 1);
        })
    );
    await Promise.all(aborts);
    commit('SET_UPLOADING', false);
    commit('CLEAR_FILES');
    commit('TO_DELETE_RESET');
  }
};

const getters = {
  uploadableFiles: state => state.files.filter(f => !f.uploaderInstance),
  // uploadingFiles: state => state.files.filter(f => f.uploaderInstance),
  allFiles: state => state.files,
  isUploading: state => state.uploading
};

function uploadFile(file) {
  return new Promise(async (resolve, reject) => {
    if (!file.uploaderInstance)
      file.uploaderInstance = await instantiateUploader(
        file.inputFile,
        () => resolve(file.uploaderInstance),
        (bytesUploaded, bytesTotal) => {
          // console.log('uploadFile', bytesUploaded, bytesTotal)
          file.loaded = bytesUploaded;
          file.size = bytesTotal;
        },
        reject
      );

    file.uploaderInstance.start();
  });
}

function abort(file) {
  return new Promise((resolve, reject) => {
    if (!file.uploaderInstance) reject('Missing tus uploader instance on file: ' + file.name);

    try {
      file.uploaderInstance.abort()

      if (file.inputFile.fileHash) {
        const fileObj = File.find(file.inputFile.fileHash)
        File.update({
          where: file.inputFile.fileHash,
          data: {
            status_replace: null
          }
        })
        eventBus.$emit('FileStatusUpdated', fileObj)
      }

      resolve();
    } catch (error) {
      reject(error)
    }
  });
}

async function instantiateUploader(inputFile, onSuccess, onProgress, onError) {
  const endpoint = `${process.env.MIX_TUSD_URL}/files/`;
  return new tus.Upload(inputFile, {
    endpoint,
    retryDelays: [2000, 5000, 7000, 12000, 22000],
    metadata: { filename: inputFile.name, filetype: inputFile.type },
    chunkSize: 20 * 1000000,
    onSuccess,
    onProgress,
    onError,
    onBeforeRequest: function (req) {
      let xhr = req.getUnderlyingObject()
      xhr.withCredentials = true
    }
  });
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
