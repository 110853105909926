<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.681" height="16.764" viewBox="0 0 17.681 16.764">
    <g id="Group_7422" data-name="Group 7422" transform="translate(-1412.16 -138.827)">
      <path id="Path_2826" data-name="Path 2826" d="M1413.92,151.3a.751.751,0,0,0,.406-.98h0a7.225,7.225,0,1,1,13.35,0,.75.75,0,0,0,1.386.575,8.725,8.725,0,1,0-16.121,0A.75.75,0,0,0,1413.92,151.3Z" fill="#939393"/>
      <path id="Path_2827" data-name="Path 2827" d="M1420.932,142.647a.749.749,0,0,0-.75.749h0v4.171a.748.748,0,0,0,.285.589l3.084,2.432a.749.749,0,1,0,.929-1.176l-2.8-2.208V143.4A.749.749,0,0,0,1420.932,142.647Z" fill="#939393"/>
      <path id="Path_2828" data-name="Path 2828" d="M1429.091,152.188h-8.518a2.739,2.739,0,0,0-5.326,0h-2.337a.75.75,0,0,0,0,1.5h2.4a2.732,2.732,0,0,0,5.206,0h8.578a.75.75,0,0,0,0-1.5Zm-11.181,1.9a1.25,1.25,0,1,1,1.25-1.25A1.252,1.252,0,0,1,1417.91,154.091Z" fill="#939393"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'IconTimeline',
  };
</script>
