<template>
  <svg id="Tags_Icon" data-name="Tags Icon" xmlns="http://www.w3.org/2000/svg" width="18.772" height="16.12" viewBox="0 0 18.772 16.12">
    <path id="Path_7023" data-name="Path 7023" d="M386.079,910.19v-.01l-.049-1.575a.75.75,0,0,0-1.5.047h0l.049,1.567v.026a1.073,1.073,0,0,1-.313.754l-4.832,4.832a1.072,1.072,0,0,1-1.51,0l-.578-.578,4.536-4.536a3.023,3.023,0,0,0,.885-2.135v-.076l-.061-1.968a.75.75,0,0,0-1.5.047h0l.061,1.963v.035a1.525,1.525,0,0,1-.447,1.075l-6.042,6.042a1.528,1.528,0,0,1-2.153,0l-3.375-3.375a1.522,1.522,0,0,1,0-2.149l6.044-6.045a1.515,1.515,0,0,1,1.071-.446h.035l2.113.06a.75.75,0,1,0,.042-1.5l-2.113-.06h0l-.077,0a3.01,3.01,0,0,0-2.132.886l-6.043,6.044a3.021,3.021,0,0,0,0,4.27l3.378,3.378,0,0a3.03,3.03,0,0,0,4.266,0l0,0,.447-.448.58.58,0,0a2.574,2.574,0,0,0,3.624,0l0,0,4.835-4.834a2.574,2.574,0,0,0,.751-1.814Z" transform="translate(-367.307 -901.521)" fill="#939393"/>
    <path id="Path_7024" data-name="Path 7024" d="M378,906.942a.749.749,0,0,0,1.06,0l4.142-4.14a.75.75,0,0,0-1.061-1.061L378,905.881A.75.75,0,0,0,378,906.942Z" transform="translate(-367.307 -901.521)" fill="#939393"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconMediaTags'
  }
</script>
