var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "12.998",
        height: "12.999",
        viewBox: "0 0 12.998 12.999"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M820.811,268.678l5.219-5.219a.75.75,0,0,0-1.061-1.061l-5.219,5.219-5.218-5.219a.75.75,0,0,0-1.061,1.061l5.218,5.219-5.218,5.218a.751.751,0,0,0,1.061,1.062l5.218-5.219,5.219,5.219a.751.751,0,1,0,1.061-1.062Z",
          transform: "translate(-813.251 -262.178)",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }