import {onboardGuard} from '@app2/router/guards/onboarding';

export default [
  {
    path: '/audiences',
    name: 'audience',
    component: require('@app2/modules/Audience/Listing/Pages/Index').default,
    meta: {
      hideAddMedia: true
    }
  },
  {
    path: '/audiences/exports',
    name: 'exports',
    component: require('@app2/modules/Audience/Export/Pages/Index').default,
    meta: {
      hideAddMedia: true
    }
  },
  {
    path: '/audiences/:query?',
    name: 'audienceFilter',
    component: require('@app2/modules/Audience/Listing/Pages/Index').default,
    meta: {
      hideAddMedia: true
    }
  },
  {
    path: '/audience/:hash',
    name: 'audienceDetail',
    component: require('@app2/modules/Audience/Listing/Components/AudienceFullDetails').default,
    redirect: '/audience/:hash/profile-details',
    meta: {
      hideAddMedia: true
    },
    children: [
      {
        path: 'profile-details',
        name: 'Profile',
        component: () => import('@app2/modules/Audience/Components/FullDetails/AudienceProfile'),
        beforeEnter: onboardGuard,
        props: true,
        meta: {
          hideAddMedia: true
        }
      },
      {
        path: 'access-details',
        name: 'Access',
        component: () => import('@app2/modules/Audience/Components/FullDetails/AudienceHubAccess'),
        beforeEnter: onboardGuard,
        props: true,
        meta: {
          hideAddMedia: true
        }
      },
      {
        path: 'activity-details',
        name: 'Activity',
        component: () => import('@app2/modules/Audience/Components/FullDetails/AudienceActivity'),
        beforeEnter: onboardGuard,
        props: true,
        meta: {
          hideAddMedia: true
        }
      },
      {
        path: 'stats-details',
        name: 'Stats',
        component: () => import('@app2/modules/Audience/Components/FullDetails/AudienceStats'),
        beforeEnter: onboardGuard,
        props: true,
        meta: {
          hideAddMedia: true
        }
      },
    ]
  },
];
