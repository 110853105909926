<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16.219" height="10">
    <g data-name="List Icon">
      <g data-name="Group 1891" transform="translate(-10.031 -11.404)">
        <rect data-name="Rectangle 396" width="4" height="4" rx="2" transform="translate(10.031 11.404)" fill="#939393"/>
        <path data-name="Line 124" fill="none" stroke="#939393" stroke-linecap="round" stroke-width="1.5" d="M16.5 13.5h9"/>
      </g>
      <g data-name="Group 1892" transform="translate(-10.031 -5.404)">
        <rect data-name="Rectangle 396" width="4" height="4" rx="2" transform="translate(10.031 11.404)" fill="#939393"/>
        <path data-name="Line 124" fill="none" stroke="#939393" stroke-linecap="round" stroke-width="1.5" d="M16.5 13.5h9"/>
      </g>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-list'
  }
</script>