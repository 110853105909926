<template>
  <audience-slide-in-template
    :back-route="{name: 'UploadStep2'}"
    back-label="Back to Step 2"
    hidePanelTitle
    :is-busy="isSaving"
  >
    <h6 class="txt-title-small"><b>Step 3</b> of 3 <br /><span class="txt-muted">Review and Complete your Import</span></h6>
    <hr class="mb-3"/>
    <div class="side-panel-widget p-4">
      <h6 class="mb-3 txt-body txt-dark">Imported From</h6>
      <div class="tag-list">
        <span v-if="csvFile" class="tag-basic tag-variant-dark">
          <svg viewBox="0 0 24 24" class="icon-sm mr-2"><use xlink:href="#icon-page-single"></use></svg>
          <span>{{ csvFile.name }}</span>
        </span>
        <span v-else class="tag-basic tag-variant-dark">
          <svg viewBox="0 0 24 24" class="icon-sm mr-2"><use xlink:href="#icon-copy"></use></svg>
          <span>Copy and Paste</span>
        </span>
      </div>
      <hr class="mt-3 mb-4" v-if="tags.length">
      <div v-if="tags.length">
        <h6 class="mb-3 txt-body txt-dark">Add <b>{{tags.length}} {{tags.length > 1 ? 'tags' : 'tag'}}</b></h6>
        <div class="tag-list">
          <span v-for="tag in tags" :key="tag.id" class="tag-basic tag-variant-dark">
            <svg viewBox="0 0 24 24" class="icon-sm mr-2"><use xlink:href="#icon-tag"></use></svg>
            <span>{{ tag.name }}</span>
          </span>
        </div>
      </div>
      <hr class="mt-3 mb-4" v-if="hubs.length">
      <div v-if="hubs.length">
        <h6 class="mb-3 txt-body txt-dark">Give access to <b>{{hubs.length}} {{hubs.length > 1 ? 'hubs' : 'hub'}}</b></h6>
        <div class="tag-list">
          <span v-for="hub in hubs" :key="hub.id" class="tag-basic tag-variant-dark">
            <svg viewBox="0 0 24 24" class="icon-sm mr-2"><use xlink:href="#icon-hub"></use></svg>
            <span>{{ hub.title }}</span>
          </span>
        </div>
      </div>
      <b-alert show class="mt-4">
        <svg width="24" class="alert-icon icon-sm" viewBox="0 0 24 24"><use xlink:href="#icon-status-question-color"></use></svg>
        <div class="alert-content">
          <span class="txt-body">Any contacts found that already exist in your audience will be updated with any new information. New tags, access, or changes to the fields in step 2 will be included in these updates.</span>
        </div>
      </b-alert>
      <!-- <hr class="mt-0 mb-4"/>
     <form-control-checkbox
       :value="1"
       :unchecked-value="0"
       label="Update existing contacts while importing"
       description="If any imported contacts are already in your audience, we'll automatically replace their information with the data from your import. This option may make the import process take longer." /> -->
    </div>
    <div class="side-panel-library-cta p-3">
      <b-btn block variant="primary" size="lg" :disabled="isSaving" @click="completeImport">
        <svg class="btn-left" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-status-success"></use></svg>
        Complete Import
      </b-btn>
    </div>
  </audience-slide-in-template>
</template>

<script>
  import Audience from '@app2/models/Audience'
  import AudienceSlideInTemplate from "@app2/modules/Audience/Components/Template";
  import FormControlCheckbox from "@app2/core/Components/Forms/Checkbox";

  export default {
    name: 'UploadStep3',
    components: {
      AudienceSlideInTemplate,
      FormControlCheckbox
    },
    data() {
      return {
        importInstance: null,
        isSaving: false,
        initialValue: null
      }
    },
    computed: {
      csvFile() {
        return this.$store.getters['v2/audience/csvFile']
      },
      tags() {
        return this.$store.getters['v2/audience/importTags']
      },
      hubs() {
        return this.$store.getters['v2/audience/importHubs']
      },
      audienceImport() {
        return this.$store.state.v2.audience.audienceImport
      },
      // notify: {
      //   get() {
      //     return this.$store.state.v2.audience.audienceImport.notify
      //   },
      //   set(value) {
      //     this.$store.commit('v2/audience/SET_IMPORT_DATA', { key: 'notify', data: value })
      //   }
      // }
    },
    methods: {
      async completeImport() {
        const importData = new FormData()
        importData.append('columns', JSON.stringify(this.audienceImport.columns))
        importData.append('file', this.audienceImport.file)
        importData.append('tags', JSON.stringify(this.audienceImport.tags))
        importData.append('hubs', JSON.stringify(this.audienceImport.hubs.map(({id}) => id)))
        importData.append('source', this.audienceImport.source)
        importData.append('notify', this.audienceImport.notify ? 1 : 0)
        this.isSaving = true

        if(this.$router.currentRoute.name == 'UploadStep3') {
          const emailColumn = this.audienceImport.columns.find(({key}) => key === 'email')

          if(emailColumn && emailColumn.header) {
            try {
              await Audience.api().importAudience(importData)

              this.$toasted.show('Importing in progress...', { type: 'info' });
              this.$store.commit('v2/audience/imports/SET_MODAL_STATE', false)
              this.$store.commit('v2/audience/RESET_AUDIENCE_IMPORT', this.initialValue)
            } catch (err) {
              console.log('err: ', err.response)
              const res = err.response.data
              let errors = []

              if(res.errors) {
                for (const [key, value] of Object.entries(res.errors)) {
                  errors.push(value)
                }
                this.$toasted.show(`${errors[0]}`, { type: 'error' });
              }
            }
            this.isSaving = false
          } else {
            this.$toasted.show('Email is required.', { type: 'error' });
            this.isSaving = false
          }
        }
      }
    }
  }
</script>
