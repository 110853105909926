var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: { id: _vm.id, static: _vm.static, centered: "", size: "sm" },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("h5", { staticClass: "txt-body txt-primary" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "close",
                  attrs: { variant: "icon", size: "sm" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "icon-sm",
                      attrs: { width: "24", viewBox: "0 0 24 24" }
                    },
                    [_c("use", { attrs: { "xlink:href": "#icon-cross" } })]
                  )
                ]
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.$emit("accept", _vm.model)
            }
          }
        },
        [
          _c("b-form-input", {
            ref: "input",
            staticClass: "mb-2",
            attrs: {
              size: "lg",
              placeholder: _vm.placeholder,
              state: _vm.$v.model.$error || _vm.error ? false : null
            },
            model: {
              value: _vm.model,
              callback: function($$v) {
                _vm.model = $$v
              },
              expression: "model"
            }
          }),
          _vm._v(" "),
          _c("b-alert", { attrs: { variant: "error", show: !!_vm.error } }, [
            _c(
              "svg",
              {
                staticClass: "alert-icon icon-sm",
                attrs: { width: "24", viewBox: "0 0 24 24" }
              },
              [
                _c("use", {
                  attrs: { "xlink:href": "#icon-status-error-color" }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "alert-content" }, [
              _c("span", { staticClass: "txt-body" }, [
                _vm._v(_vm._s(_vm.error))
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c(
            "b-btn",
            {
              attrs: {
                disabled: _vm.processing || _vm.$v.model.$error,
                variant: "primary",
                block: ""
              },
              on: {
                click: function($event) {
                  return _vm.$emit("accept", _vm.value)
                }
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "btn-left",
                  attrs: { width: "24", viewBox: "0 0 24 24" }
                },
                [
                  _c("use", {
                    attrs: { "xlink:href": "#icon-" + _vm.confirmIcon }
                  })
                ]
              ),
              _vm._v("\n      " + _vm._s(_vm.confirmText) + "\n    ")
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }