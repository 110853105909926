var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-sections",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18.003",
        height: "13.993",
        viewBox: "0 0 18.003 13.993"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(-856.03 -1025.534)" } }, [
        _c("path", {
          attrs: {
            d:
              "M856.491,1030.6l8.245,3.627a.748.748,0,0,0,.6,0l8.245-3.627a.75.75,0,0,0,0-1.373l-8.245-3.629a.747.747,0,0,0-.6,0l-8.245,3.629a.75.75,0,0,0,0,1.373Zm8.547-3.5,6.383,2.809-6.383,2.808-6.383-2.808Z",
            fill: "#939393"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M872.967,1034.462h0l-7.943,3.5-7.942-3.5a.75.75,0,0,0-.6,1.373l8.245,3.629a.748.748,0,0,0,.6,0l8.245-3.629a.75.75,0,1,0-.605-1.372Z",
            fill: "#939393"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }