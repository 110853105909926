<template>
  <svg class="icon-back-arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17.466" height="14.516" viewBox="0 0 17.466 14.516">
    <path d="M350.716,395.485H336.562l5.2-5.2a.75.75,0,0,0-1.061-1.06l-6.485,6.484a.75.75,0,0,0,0,1.061l6.485,6.485a.75.75,0,1,0,1.061-1.06h0l-5.206-5.206h14.157a.75.75,0,0,0,0-1.5Z" transform="translate(-333.977 -389.004)" fill="currentColor"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconBackArrow',
  };
</script>
