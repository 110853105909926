import { Node } from 'prosemirror-to-html-js';

export default class BulletList extends Node {
  matching () {
    return this.node.type === "bullet_list" || this.node.type === "bulletList";
  }

  tag () {
    return "ul";
  }
}
