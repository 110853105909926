var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-link",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17",
        height: "17",
        viewBox: "0 0 16.958 16.958"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M501.079,970.562a4.483,4.483,0,0,0-6.342,0l-.813.811a.75.75,0,1,0,1.06,1.062h0l.813-.812a2.985,2.985,0,0,1,4.221,4.222l-2.772,2.771a2.985,2.985,0,0,1-4.221,0h0a.75.75,0,0,0-1.061,1.061h0a4.487,4.487,0,0,0,6.341,0l2.773-2.772a4.486,4.486,0,0,0,0-6.342Z",
          transform: "translate(-485.434 -969.248)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M492.844,983.02h0l-.813.813a2.985,2.985,0,0,1-4.222-4.221l2.773-2.773a2.984,2.984,0,0,1,4.221,0,.75.75,0,1,0,1.061-1.059,4.483,4.483,0,0,0-6.343,0l-2.773,2.773a4.486,4.486,0,0,0,6.344,6.343l.813-.813a.75.75,0,0,0-1.06-1.06Z",
          transform: "translate(-485.434 -969.248)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }