var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "12.286",
        height: "22.573",
        viewBox: "0 0 12.286 22.573"
      }
    },
    [
      _c("g", { attrs: { id: "Plus", transform: "translate(1.414 1.414)" } }, [
        _c("path", {
          attrs: {
            id: "Arrow_Bign",
            "data-name": "Arrow Bign",
            d: "M1467.8,256l9.872,9.872-9.872,9.872",
            transform: "translate(-1467.797 -255.996)",
            fill: "none",
            stroke: "#293ad3",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }