<template>
  <svg class="icon-playlist-feed" xmlns="http://www.w3.org/2000/svg" width="17.85" height="20.024" viewBox="0 0 17.85 20.024">
    <path d="M8.925 12.58a3.105 3.105 0 00-3.1 3.1v1.234a3.1045 3.1045 0 106.209 0V15.68a3.1 3.1 0 00-3.109-3.1zm1.605 4.339a1.6045 1.6045 0 11-3.209 0v-1.234a1.6045 1.6045 0 113.209 0z" fill="#939393"/>
    <path d="M8.925 11.961a3.036 3.036 0 10-3.036-3.036 3.036 3.036 0 003.036 3.036zm0-4.573a1.536 1.536 0 11-1.536 1.537 1.539 1.539 0 011.536-1.537z" fill="#939393"/>
    <path d="M14.91 8.925a5.985 5.985 0 10-10.814 3.543.75014482.75014482 0 001.21-.887 4.485 4.485 0 117.237 0 .75044054.75044054 0 001.21.888 5.966 5.966 0 001.157-3.544z" fill="#939393"/>
    <path d="M8.925 0a8.926 8.926 0 00-5.95 15.578.75.75 0 001-1.117 7.426 7.426 0 119.9 0 .75.75 0 001 1.118A8.926 8.926 0 008.925.001z" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconPlaylistFeed'
  }
</script>
