<template>
  <svg class="icon-feed" xmlns="http://www.w3.org/2000/svg" width="15.074" height="15.111">
    <path d="M2.817 9.442a2.818 2.818 0 102.819 2.818 2.818 2.818 0 00-2.819-2.818zm0 4.136a1.318 1.318 0 111.319-1.318 1.32 1.32 0 01-1.319 1.318z" fill="#939393"/>
    <path d="M2.814 4.761A7.475 7.475 0 00.588 5.1a.75.75 0 00.445 1.433 6 6 0 017.484 7.6.75.75 0 001.427.464 7.53 7.53 0 00.369-2.328 7.5 7.5 0 00-7.5-7.5z" fill="#939393"/>
    <path d="M14.889 10.15A12.249 12.249 0 00.683.188a.75.75 0 10.261 1.477 10.772 10.772 0 0112.453 12.56.75.75 0 101.475.272 12.336 12.336 0 00.2-2.226 12.185 12.185 0 00-.183-2.121z" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconFeed',
  };
</script>
