export default [
  {
    path: '/library',
    name: 'library',
    component: () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Listing/Pages/Index.vue'),
    children: [
      {
        path: 'search/:query?',
        name: 'LibrarySearch',
        component: () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Listing/Pages/Files.vue'),
      },
      {
        path: ':type/:hash?',
        name: 'LibraryListing',
        component: () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Listing/Pages/Files.vue'),
      },
    ]
  },
]
