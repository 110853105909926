<template>
  <svg class="icon-analytics" xmlns="http://www.w3.org/2000/svg" width="19.66" height="14.836" viewBox="0 0 19.66 14.836">
    <path d="M200.3,217.673a.74.74,0,0,0-.219-.53h0a.69.69,0,0,0-.126-.084.775.775,0,0,0-.115-.078.752.752,0,0,0-.306-.056H197.1a.75.75,0,0,0,0,1.5h.623l-5.247,5.2-3.28-3.251a.749.749,0,0,0-1.056,0l-3.934,3.9a2.426,2.426,0,1,0,1.339,2.159,2.4,2.4,0,0,0-.276-1.1l3.4-3.369,3.28,3.252a.753.753,0,0,0,1.057,0l5.795-5.749v.634a.75.75,0,1,0,1.5,0ZM183.1,227.368a.938.938,0,1,1,.952-.937A.948.948,0,0,1,183.1,227.368Z" transform="translate(-180.644 -216.923)" fill="currentColor"/><path d="M192.674,227.064a.75.75,0,0,0-.75.75v3.194a.75.75,0,0,0,1.5,0v-3.194A.75.75,0,0,0,192.674,227.064Z" transform="translate(-180.644 -216.923)" fill="currentColor"/><path d="M196.779,224.447a.75.75,0,0,0-.75.75v5.81a.75.75,0,0,0,1.5,0V225.2A.75.75,0,0,0,196.779,224.447Z" transform="translate(-180.644 -216.923)" fill="currentColor"/><path d="M188.57,224.721a.749.749,0,0,0-.75.75v5.538a.75.75,0,1,0,1.5,0v-5.538A.75.75,0,0,0,188.57,224.721Z" transform="translate(-180.644 -216.923)" fill="currentColor"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconAnalytics',
  };
</script>
