<template>
  <div>
    <ul>
      <li v-for="i in lineNumber" :key="i">{{ i }}</li>
    </ul>
    <b-textarea rows="10" v-model="audienceList" @keyup.enter="generateLineNumber" />
  </div>
</template>

<script>
  

  export default {
    name: 'UploadStep1Copy',
    data() {
      return {
        audienceList: '',
        lineNumber: 1
      }
    },
    methods: {
      async parseCSVString() {
       
      },
      generateLineNumber() {
        this.lineNumber = this.audienceList.split('\n').length
      }
    }
  }
</script>