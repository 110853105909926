<template>
  <svg class="icon-youtube social" xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 43.686 30.913">
    <path d="M34.612,67.393H9.073A9.073,9.073,0,0,0,0,76.466V89.233a9.073,9.073,0,0,0,9.073,9.073h25.54a9.073,9.073,0,0,0,9.073-9.073V76.466A9.073,9.073,0,0,0,34.612,67.393ZM28.476,83.471l-11.946,5.7a.48.48,0,0,1-.686-.433V76.984a.48.48,0,0,1,.7-.428L28.487,82.61A.48.48,0,0,1,28.476,83.471Z" transform="translate(0 -67.393)" fill="#f61c0d"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconYoutube',
  };
</script>
