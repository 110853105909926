var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "audience-table-head top-sticky-blur" }, [
    _c("div", { staticClass: "wrapper-lg pt-2" }, [
      _c(
        "div",
        {
          staticClass: "table-bulk",
          class: {
            selected: _vm.countSelected || _vm.selectAll,
            disabled: _vm.loading,
            empty: _vm.empty
          }
        },
        [
          !_vm.empty
            ? [
                _c("b-form-checkbox", {
                  key: _vm.countSelected,
                  staticClass: "mr-2",
                  attrs: {
                    disabled: _vm.loading,
                    indeterminate: !!(_vm.countSelected && !_vm.selectAll)
                  },
                  model: {
                    value: _vm.selectAll,
                    callback: function($$v) {
                      _vm.selectAll = $$v
                    },
                    expression: "selectAll"
                  }
                }),
                _vm._v(" "),
                !_vm.loading
                  ? _c(
                      "span",
                      { staticClass: "txt-heading-small txt-muted mb-0" },
                      [
                        _c(
                          "transition",
                          { attrs: { name: "fade", mode: "out-in" } },
                          [
                            !_vm.countSelected
                              ? _c("span", [
                                  _c("b", { staticClass: "txt-dark" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("withCommas")(_vm.countTotal)
                                      )
                                    )
                                  ]),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.countTotal === 1
                                          ? "Person"
                                          : "People"
                                      ) +
                                      "\n            "
                                  )
                                ])
                              : _c("span", [
                                  _c("b", { staticClass: "txt-white" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("withCommas")(_vm.countSelected)
                                      ) + " Selected"
                                    )
                                  ]),
                                  _vm._v(
                                    "\n              out of " +
                                      _vm._s(
                                        _vm._f("withCommas")(_vm.countTotal)
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.countTotal === 1
                                          ? "Person"
                                          : "People"
                                      ) +
                                      "\n            "
                                  )
                                ])
                          ]
                        )
                      ],
                      1
                    )
                  : _c("span", { staticClass: "txt-muted" }, [
                      _vm._v("Loading...")
                    ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.countSelected || _vm.selectAll
              ? _c(
                  "div",
                  { staticClass: "slot-right" },
                  [
                    _c(
                      "b-btn",
                      {
                        staticClass: "ml-auto",
                        attrs: { variant: "platform-light" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.openBulkDialog($event)
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "btn-left",
                            attrs: { viewBox: "0 0 24 24" }
                          },
                          [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-pencil" }
                            })
                          ]
                        ),
                        _vm._v("\n            Edit\n          ")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-btn",
                      {
                        staticClass: "ml-2",
                        attrs: { variant: "platform-light" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.openExportAudienceDialog($event)
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "btn-left",
                            attrs: { viewBox: "0 0 24 24" }
                          },
                          [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-upload" }
                            })
                          ]
                        ),
                        _vm._v("\n            Export\n          ")
                      ]
                    ),
                    _vm._v(" "),
                    _c("hr", { staticClass: "vertical vertical-light" }),
                    _vm._v(" "),
                    _c(
                      "b-btn",
                      {
                        attrs: { variant: "platform-light" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.openBulkDeleteDialog($event)
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          { attrs: { width: "24", viewBox: "0 0 24 24" } },
                          [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-trash" }
                            })
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "slot-right" },
                  [
                    _c("audience-search", {
                      attrs: { disabled: _vm.loading },
                      on: {
                        sync: function($event) {
                          return _vm.$emit("sync")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "b-btn",
                      {
                        ref: "audience-table-imports",
                        attrs: {
                          variant: "icon primary ml-2",
                          disabled: _vm.loading
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          { attrs: { viewBox: "0 0 24 24", width: "24" } },
                          [_c("use", { attrs: { "xlink:href": "#icon-more" } })]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-popover",
                      {
                        ref: "dd",
                        attrs: {
                          placement: "leftbottom",
                          target: function() {
                            return _vm.$refs["audience-table-imports"]
                          },
                          triggers: "click blur"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "dropdown-cont sm" },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { disabled: _vm.loading },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.openExportAudienceDialog($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "btn-left icon-sm",
                                    attrs: { width: "24", viewBox: "0 0 24 24" }
                                  },
                                  [
                                    _c("use", {
                                      attrs: { "xlink:href": "#icon-upload" }
                                    })
                                  ]
                                ),
                                _vm._v(
                                  "\n                Export Audience\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("b-dropdown-divider"),
                            _vm._v(" "),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { disabled: _vm.loading },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.openRecentExportDialog($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "btn-left icon-sm",
                                    attrs: { width: "24", viewBox: "0 0 24 24" }
                                  },
                                  [
                                    _c("use", {
                                      attrs: { "xlink:href": "#icon-clock" }
                                    })
                                  ]
                                ),
                                _vm._v(
                                  "\n                Recent Exports\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { disabled: _vm.loading },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.openRecentImportDialog($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "btn-left icon-sm",
                                    attrs: { width: "24", viewBox: "0 0 24 24" }
                                  },
                                  [
                                    _c("use", {
                                      attrs: { "xlink:href": "#icon-clock" }
                                    })
                                  ]
                                ),
                                _vm._v(
                                  "\n                Recent Imports\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
          ])
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }