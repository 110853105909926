var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "audience-slide-in-template",
    {
      attrs: {
        "back-route": { name: "UploadStep2" },
        "back-label": "Back to Step 2",
        hidePanelTitle: "",
        "is-busy": _vm.isSaving
      }
    },
    [
      _c("h6", { staticClass: "txt-title-small" }, [
        _c("b", [_vm._v("Step 3")]),
        _vm._v(" of 3 "),
        _c("br"),
        _c("span", { staticClass: "txt-muted" }, [
          _vm._v("Review and Complete your Import")
        ])
      ]),
      _vm._v(" "),
      _c("hr", { staticClass: "mb-3" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-widget p-4" },
        [
          _c("h6", { staticClass: "mb-3 txt-body txt-dark" }, [
            _vm._v("Imported From")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "tag-list" }, [
            _vm.csvFile
              ? _c("span", { staticClass: "tag-basic tag-variant-dark" }, [
                  _c(
                    "svg",
                    {
                      staticClass: "icon-sm mr-2",
                      attrs: { viewBox: "0 0 24 24" }
                    },
                    [
                      _c("use", {
                        attrs: { "xlink:href": "#icon-page-single" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.csvFile.name))])
                ])
              : _c("span", { staticClass: "tag-basic tag-variant-dark" }, [
                  _c(
                    "svg",
                    {
                      staticClass: "icon-sm mr-2",
                      attrs: { viewBox: "0 0 24 24" }
                    },
                    [_c("use", { attrs: { "xlink:href": "#icon-copy" } })]
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Copy and Paste")])
                ])
          ]),
          _vm._v(" "),
          _vm.tags.length ? _c("hr", { staticClass: "mt-3 mb-4" }) : _vm._e(),
          _vm._v(" "),
          _vm.tags.length
            ? _c("div", [
                _c("h6", { staticClass: "mb-3 txt-body txt-dark" }, [
                  _vm._v("Add "),
                  _c("b", [
                    _vm._v(
                      _vm._s(_vm.tags.length) +
                        " " +
                        _vm._s(_vm.tags.length > 1 ? "tags" : "tag")
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tag-list" },
                  _vm._l(_vm.tags, function(tag) {
                    return _c(
                      "span",
                      {
                        key: tag.id,
                        staticClass: "tag-basic tag-variant-dark"
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "icon-sm mr-2",
                            attrs: { viewBox: "0 0 24 24" }
                          },
                          [_c("use", { attrs: { "xlink:href": "#icon-tag" } })]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(tag.name))])
                      ]
                    )
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hubs.length ? _c("hr", { staticClass: "mt-3 mb-4" }) : _vm._e(),
          _vm._v(" "),
          _vm.hubs.length
            ? _c("div", [
                _c("h6", { staticClass: "mb-3 txt-body txt-dark" }, [
                  _vm._v("Give access to "),
                  _c("b", [
                    _vm._v(
                      _vm._s(_vm.hubs.length) +
                        " " +
                        _vm._s(_vm.hubs.length > 1 ? "hubs" : "hub")
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tag-list" },
                  _vm._l(_vm.hubs, function(hub) {
                    return _c(
                      "span",
                      {
                        key: hub.id,
                        staticClass: "tag-basic tag-variant-dark"
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "icon-sm mr-2",
                            attrs: { viewBox: "0 0 24 24" }
                          },
                          [_c("use", { attrs: { "xlink:href": "#icon-hub" } })]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(hub.title))])
                      ]
                    )
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("b-alert", { staticClass: "mt-4", attrs: { show: "" } }, [
            _c(
              "svg",
              {
                staticClass: "alert-icon icon-sm",
                attrs: { width: "24", viewBox: "0 0 24 24" }
              },
              [
                _c("use", {
                  attrs: { "xlink:href": "#icon-status-question-color" }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "alert-content" }, [
              _c("span", { staticClass: "txt-body" }, [
                _vm._v(
                  "Any contacts found that already exist in your audience will be updated with any new information. New tags, access, or changes to the fields in step 2 will be included in these updates."
                )
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-library-cta p-3" },
        [
          _c(
            "b-btn",
            {
              attrs: {
                block: "",
                variant: "primary",
                size: "lg",
                disabled: _vm.isSaving
              },
              on: { click: _vm.completeImport }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "btn-left",
                  attrs: { width: "24", viewBox: "0 0 24 24" }
                },
                [_c("use", { attrs: { "xlink:href": "#icon-status-success" } })]
              ),
              _vm._v("\n      Complete Import\n    ")
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }