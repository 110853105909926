import Base from '@app2/models/Base';

export default class Achievement extends Base {
  static entity = 'App\\Achievement';
  static primaryKey = 'id';

  static apiConfig = {
    baseURL: '/api/internal/v2/achievements/',
    actions: {
      fetch: {
        url: '/',
        dataKey: 'data',
      },
      setForAudience(id, config) {
        return this.put(`audience/${id}`, {
          ...config
        });
      },
    }
  };

  watchFields = [
    'description',
    'type',
    'criteria',
    'enabled',
    'segment',
    'settings',
    'hub_id',
    'criteria_details'
  ];

  requiredFields = [
    'title',
    'type',
    'segment',
  ];

  static fields() {
    return {
      id: this.attr(null),
      title: this.attr(null),
      type: this.attr(null),
      criteria: this.attr(null),
      description: this.attr(''),
      hub_id: this.attr(null),
      hash: this.attr(null),
      settings: this.attr(null),
      segment_conditions: this.attr([]),
      segment: this.attr(null),
      enabled: this.attr(true),
      saving: this.attr(false),
      milestone_hub: this.attr(null),
      ...super.fields(),
    };
  }
  static mutators() {
    return {
      meta(value) {
        if ( !value || Array.isArray(value) ) return {};
        return value;
      },
      settings(value) {
        if ( !value || Array.isArray(value) ) return {};
        return value;
      },
    };
  }
}
