import Vue from 'vue';
import Vuelidate from 'vuelidate';
import Toasted from 'vue-toasted';
import VueProgressBar from 'vue-progressbar';
import '@app2/core/register-global';
import '@app2/core/register-userpilot';
import '@app2/core/register-boostrap-vue';
// import '@app2/core/register-logrocket';
import moment from 'moment';
import '@app2/modules/Library/Builder/playlist.app';

import UserPilotManager from '@app2/utils/UserPilotManager';

import App from '@app2/App.vue';
import router from '@app2/router/';
import store from '@app2/store';

import axios from '@app2/api/axios';
window.axios = axios;
window.moment = moment;

import '@app2/utils/filters';
import '@app2/utils/directives';
import VueMeta from 'vue-meta';


// import * as Sentry from '@sentry/vue'
//
// Sentry.init({
//   Vue: Vue,
//   dsn: process.env.MIX_SENTRY_LARAVEL_DSN,
//   environment: process.env.MIX_SENTRY_ENVIRONMENT,
// })

Vue.use(VueMeta);
Vue.use(Vuelidate);
Vue.use(Toasted, {
  duration: 4000,
  icon: 'icon',
  theme: 'bubble',
  position: 'bottom-center',
  className: 'searchie-toast',
  containerClass: 'searchie-toast-cont',
  iconPack: 'custom-class',
  action: {
    text: '',
    class: 'close',
    onClick(e, toastObject) {
      toastObject.goAway(0);
    },
  },
});
Vue.use(VueProgressBar, {
  color: '#f8d133',
  failedColor: '#ff0909',
  height: '3px',
});

window.store = store;

const vueConfig = {
  el: '#app',
  store,
  router,
  render: h => h(App),
};

if (process.env.NODE_ENV === 'production') {
  vueConfig.watch = {
    "$route": () => {
      if (window.Appcues)
        window.Appcues.page()
    }
  }
}

// declare global Vue before vue-bootstrap does, to prevent it's own Vue declaration that throws an Error
window.Vue = Vue
window.Bus = new Vue()
require('@app-spark/vue-bootstrap');

const userPilot = new UserPilotManager();
userPilot.mount();

Vue.prototype.$assetUrl = process.env.MIX_ASSET_URL || '';

export default new Vue(vueConfig);
