import { Userpilot } from 'userpilot'
import Bus from '@app2/core/eventBus';
import get from 'lodash/get';

if (process.env.NODE_ENV === 'production') {
    Userpilot.initialize('NX-a42ec23d');
    Bus.$on('userUpdated', user => {
        let plan = get(user, 'chargebee_current_plan.item_price_id', null);
        let seconds = get(user, 'chargebee_permissions.hours.used', 0);
        let subscriptionStatus = get(user, 'chargebee_active_subscription.status', null);
        Userpilot.identify(user.id,{
            name: user.name,
            email: user.email,
            created_at: user.created_at,
            plan: plan,
            usageSeconds: seconds,
            status: subscriptionStatus
        });
    });
    Bus.$on('routeUpdated', () => {
        Userpilot.reload();
    });
}