import Vue from 'vue';
import InfiniteLoading from '@app2/utils/vue-infinite-loading/components/InfiniteLoading';
import Spinner from '@app2/utils/vue-infinite-loading/components/Spinner';

// Import all icons

const iconsContext = require.context('@icons/', true, /\.(js|vue)$/i);
iconsContext.keys().map(key => {
  const name = key.match(/\w+/)[0].match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-');
  return Vue.component(name, iconsContext(key).default);
});

const popupIconsContext = require.context('@icons/PopupButtonPositions/', true, /\.(js|vue)$/i);
popupIconsContext.keys().map(key => {
  const name = key.match(/\w+/)[0].match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-');
  return Vue.component(name, popupIconsContext(key).default);
});

Vue.component('infinite-loading', InfiniteLoading);
Vue.component('spinner', Spinner);
