var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-google-podcast",
      attrs: { xmlns: "http://www.w3.org/2000/svg", width: "19", height: "19" }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M1.881 7.817A1.088 1.088 0 00.792 8.908v1.188a1.089 1.089 0 002.177 0V8.908a1.088 1.088 0 00-1.088-1.091z",
          fill: "#0066d9"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M17.12 7.817a1.088 1.088 0 00-1.089 1.091v1.188a1.089 1.089 0 102.177 0V8.908a1.088 1.088 0 00-1.088-1.091z",
          fill: "#4285f4"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M5.64 11.38a1.088 1.088 0 00-1.088 1.088v1.188a1.089 1.089 0 002.177 0v-1.188A1.088 1.088 0 005.64 11.38zM5.64 4.255a1.088 1.088 0 00-1.088 1.088v3.885a1.088 1.088 0 002.175 0V5.343A1.088 1.088 0 005.64 4.255z",
          fill: "#ea4335"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M13.359 4.255a1.088 1.088 0 00-1.089 1.089v1.187a1.089 1.089 0 002.177 0V5.343a1.088 1.088 0 00-1.088-1.088z",
          fill: "#34a853"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M9.5.792a1.088 1.088 0 00-1.089 1.089v1.186a1.089 1.089 0 002.177 0V1.881A1.088 1.088 0 009.5.792zM9.5 14.844a1.088 1.088 0 00-1.089 1.089v1.187a1.089 1.089 0 002.177 0v-1.188A1.088 1.088 0 009.5 14.844z",
          fill: "#fab908"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M13.359 8.708a1.088 1.088 0 00-1.089 1.089v3.859a1.089 1.089 0 002.177 0V9.797a1.088 1.088 0 00-1.088-1.089z",
          fill: "#34a853"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M10.588 6.334a1.089 1.089 0 00-2.177 0v6.333a1.089 1.089 0 002.177 0V6.334z",
          fill: "#fab908"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }