var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-page",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.765",
        height: "18.727",
        viewBox: "0 0 16.765 18.727"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M16.047 8.576 13.66 6.708l2.387-1.869a1.867 1.867 0 0 0 .716-1.479 1.911 1.911 0 0 0-.456-1.247 1.749 1.749 0 0 0-1.311-.607H4.837a2.142 2.142 0 0 0-2.142 2.143v6.125a2.141 2.141 0 0 0 2.142 2.141h10.161a1.752 1.752 0 0 0 1.308-.61 1.907 1.907 0 0 0 .457-1.246 1.866 1.866 0 0 0-.716-1.483Zm-.875 1.747a.246.246 0 0 1-.2.089H4.839a.644.644 0 0 1-.643-.641V3.646a.644.644 0 0 1 .643-.643h10.136a.242.242 0 0 1 .2.088.421.421 0 0 1 .093.267.366.366 0 0 1-.145.3l-3.142 2.459a.751.751 0 0 0 0 1.181l3.142 2.459a.361.361 0 0 1 .142.3.421.421 0 0 1-.093.266Z",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M.75 0A.75.75 0 0 0 0 .75v17.227a.75.75 0 0 0 1.5 0V.75A.75.75 0 0 0 .75 0Z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }