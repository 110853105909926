const authProps = {
  props: true,
  meta: {
    auth: true,
    ph: true,
  }
};

export default [
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "v2-register" */ '@app2/modules/Register/Pages/Index'),
    redirect: '/register/email',
    children: [
      {
        path: '/register/email',
        name: 'OnboardingEmail',
        component: () => import(/* webpackChunkName: "v2-register" */ '@app2/modules/Register/components/Email'),
        ...authProps
      },
      {
        path: '/register/plan',
        name: 'OnboardingPlan',
        component: () => import(/* webpackChunkName: "v2-register" */ '@app2/modules/Register/components/Plan'),
        ...authProps
      },
      {
        path: '/register/details',
        name: 'OnboardingDetails',
        component: () => import(/* webpackChunkName: "v2-register" */ '@app2/modules/Register/components/Details'),
        ...authProps
      },
      {
        path: '/register/migration',
        name: 'OnboardingMigration',
        component: () => import(/* webpackChunkName: "v2-register" */ '@app2/modules/Register/components/Migration'),
        ...authProps
      },
      {
        path: '/register/single-step',
        name: 'OnboardingSingleStep',
        component: () => import(/* webpackChunkName: "v2-register" */ '@app2/modules/Register/components/SingleStep'),
        ...authProps
      },
    ]
  },
]