var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Loupe_big_Icon",
        "data-name": "Loupe big Icon",
        xmlns: "http://www.w3.org/2000/svg",
        width: "20.545",
        height: "20.198",
        viewBox: "0 0 20.545 20.198"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Loupe",
          d:
            "M298.23,389l-2.691-2.528a9.81,9.81,0,1,0-1.393,1.435l2.714,2.551A1,1,0,0,0,298.23,389ZM282.3,385.908a7.834,7.834,0,1,1,5.541,2.3A7.806,7.806,0,0,1,282.3,385.908Z",
          transform: "translate(-278 -370.532)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }