<template>
  <svg id="Video_Player" data-name="Video Player" xmlns="http://www.w3.org/2000/svg" width="17.855" height="15.98" viewBox="0 0 17.855 15.98">
    <path id="Path_2499" data-name="Path 2499" d="M1268.122,344.466l1.978-1.093a.918.918,0,0,0,.351-.34l.016-.027a.888.888,0,0,0-.369-1.2l-1.976-1.094a.932.932,0,0,0-.46-.118.914.914,0,0,0-.924.9v2.189a.874.874,0,0,0,.123.448A.937.937,0,0,0,1268.122,344.466Z" transform="translate(-1259.539 -335.99)" fill="#293ad3"/>
    <path id="Path_2500" data-name="Path 2500" d="M1277.394,347.556v-8.221a3.344,3.344,0,0,0-3.344-3.345h-11.164a3.345,3.345,0,0,0-3.346,3.345v9.29a3.346,3.346,0,0,0,3.346,3.345h11.164a3.345,3.345,0,0,0,3.344-3.345v-1.069Zm-14.509-10.067h11.164a1.849,1.849,0,0,1,1.845,1.846v7.471h-14.855v-7.471A1.849,1.849,0,0,1,1262.885,337.489Zm11.164,12.981h-11.164a1.849,1.849,0,0,1-1.846-1.845v-.319h14.855v.319A1.849,1.849,0,0,1,1274.049,350.47Z" transform="translate(-1259.539 -335.99)" fill="#293ad3"/>
  </svg>

</template>
<script>
  export default {
    name: 'icon-embed-video',
  };
</script>
