var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-warning-small",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "14.52",
        height: "13.54",
        viewBox: "0 0 14.52 13.54"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(-2279.78 -1192.712)" } }, [
        _c("path", { attrs: { d: "M2285.235,1193.2h0Z", fill: "#E34C4C" } }),
        _c("path", {
          attrs: {
            d:
              "M2293.817,1200.847l-3.657-6.333a3.6,3.6,0,0,0-6.24,0h0l-3.657,6.333a3.6,3.6,0,0,0,3.122,5.4h7.312a3.6,3.6,0,0,0,3.12-5.4Zm-8.6-5.583a2.1,2.1,0,0,1,.769-.769h0a2.07,2.07,0,0,1,1.047-.282,2.1,2.1,0,0,1,1.824,1.053l3.657,6.332a2.1,2.1,0,0,1-1.822,3.155h-7.311a2.1,2.1,0,0,1-1.823-3.155l3.657-6.332",
            fill: "#E34C4C"
          }
        }),
        _c("rect", {
          attrs: {
            width: "1.606",
            height: "1.486",
            transform: "translate(2286.238 1201.311)",
            fill: "#E34C4C"
          }
        }),
        _c("rect", {
          attrs: {
            width: "1.606",
            height: "3.294",
            transform: "translate(2286.238 1196.66)",
            fill: "#E34C4C"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }