var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-control-select" },
    [
      _vm.title
        ? _c(
            "h5",
            {
              staticClass: "txt-heading-small",
              class: { "mb-3": !_vm.hideField }
            },
            [_vm._v(_vm._s(_vm.title))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.label
        ? _c(
            "label",
            {
              staticClass: "form-control-label txt-body mb-1",
              class: _vm.customClass
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.description
        ? _c(
            "span",
            { staticClass: "form-control-description txt-body mt-0 mb-3" },
            [_vm._v(_vm._s(_vm.description))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("description"),
      _vm._v(" "),
      !_vm.hideField
        ? _c(
            "multiselect",
            {
              ref: "multiselect",
              class:
                ((_obj = {
                  "is-multiple": _vm.multiple,
                  "multiselect-small": _vm.small,
                  "read-only": _vm.readonly
                }),
                (_obj[_vm.customSelectClass] = !!_vm.customSelectClass),
                _obj),
              attrs: {
                "tag-placeholder": _vm.tagPlaceholder,
                closeOnSelect: _vm.closeOnSelect,
                taggable: _vm.taggable,
                placeholder: _vm.placeholder,
                "track-by": _vm.trackBy,
                label: _vm.selectLabel,
                options: _vm.options,
                "block-keys": _vm.blockKeys,
                disabled: _vm.disabled,
                multiple: _vm.multiple,
                loading: _vm.isSearching,
                "hide-selected": _vm.hideSelected,
                "preselect-first": _vm.preselectFirst
              },
              on: {
                open: _vm.open,
                close: _vm.close,
                tag: function($event) {
                  return _vm.$emit("tag", $event)
                },
                select: function($event) {
                  return _vm.$emit("select", $event)
                },
                "search-change": function($event) {
                  return _vm.$emit("search-change", $event)
                }
              },
              scopedSlots: _vm._u(
                [
                  _vm.multiple && _vm.model.length && _vm.showSelectedItemsList
                    ? {
                        key: "beforeList",
                        fn: function() {
                          return [
                            _vm._l(_vm.model, function(item) {
                              return _c(
                                "li",
                                {
                                  key: "msl-" + item.id,
                                  staticClass: "multiple-selected-list",
                                  on: {
                                    click: function($event) {
                                      return _vm.removeItem(item)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "i",
                                    { staticClass: "icon txt-primary mr-2" },
                                    [
                                      _c(
                                        "svg",
                                        { attrs: { viewBox: "0 0 24 24" } },
                                        [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href": "#icon-tick"
                                            }
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "on-hover",
                                          attrs: { viewBox: "0 0 24 24" }
                                        },
                                        [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href": "#icon-cross"
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._t("optionIcon", null, { item: item }),
                                  _vm._v(
                                    "\n        " +
                                      _vm._s(item[_vm.selectLabel]) +
                                      "\n      "
                                  )
                                ],
                                2
                              )
                            }),
                            _vm._v(" "),
                            _c("hr")
                          ]
                        },
                        proxy: true
                      }
                    : null,
                  {
                    key: "caret",
                    fn: function(ref) {
                      var toggle = ref.toggle
                      return [
                        !_vm.isSearching
                          ? _c(
                              "svg",
                              {
                                staticClass: "icon-regular caret",
                                attrs: { viewBox: "0 0 24 24" },
                                on: {
                                  mousedown: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return toggle($event)
                                  }
                                }
                              },
                              [
                                _c("use", {
                                  attrs: { "xlink:href": "#icon-chevron-down" }
                                })
                              ]
                            )
                          : _c(
                              "svg",
                              {
                                staticClass: "icon-multiselect-spinner",
                                attrs: {
                                  width: "10",
                                  height: "10",
                                  viewBox: "0 0 12 12",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                },
                                on: {
                                  mousedown: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return toggle($event)
                                  }
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M0.853516 6C0.853516 3.2 3.05352 1 5.85352 1",
                                    stroke: "#5581F4",
                                    "stroke-width": "1.5",
                                    "stroke-miterlimit": "10",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                })
                              ]
                            ),
                        _vm._v(" "),
                        !_vm.small && _vm.searchable
                          ? _c(
                              "svg",
                              {
                                staticClass:
                                  "icon-regular txt-dark icon-search",
                                class: { visible: _vm.searchVisible },
                                attrs: { viewBox: "0 0 24 24" }
                              },
                              [
                                _c("use", {
                                  attrs: { "xlink:href": "#icon-search" }
                                })
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "singleLabel",
                    fn: function(props) {
                      return [
                        _vm.inputIcon
                          ? _c(
                              "svg",
                              {
                                staticClass: "icon-regular mr-2",
                                class: _vm.singleLabelIconClass,
                                attrs: { viewBox: "0 0 24 24" }
                              },
                              [
                                _c("use", {
                                  attrs: {
                                    "xlink:href": "#icon-" + _vm.inputIcon
                                  }
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._t(
                          "default",
                          [
                            _c("span", [
                              _vm._v(_vm._s(props.option[_vm.selectLabel]))
                            ])
                          ],
                          { props: props }
                        )
                      ]
                    }
                  },
                  {
                    key: "option",
                    fn: function(props) {
                      return [
                        _vm._t(
                          "default",
                          [
                            _vm.optionTooltipOnIcon &&
                            _vm.optionCustomIcon &&
                            _vm.selectLabel &&
                            !_vm.isSearching
                              ? [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip:hover",
                                          value: {
                                            boundary: "viewport",
                                            title: props.option[_vm.selectLabel]
                                          },
                                          expression:
                                            "{boundary: 'viewport', title: props.option[selectLabel]}",
                                          arg: "hover"
                                        }
                                      ],
                                      class: props.option[_vm.trackBy]
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "icon-regular",
                                          attrs: { viewBox: "0 0 24 24" }
                                        },
                                        [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href":
                                                "#icon-" +
                                                props.option[_vm.optionIcon]
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              : [
                                  _vm.optionCustomIcon
                                    ? _c(
                                        "svg",
                                        {
                                          staticClass: "icon-sm mr-2",
                                          attrs: { viewBox: "0 0 24 24" }
                                        },
                                        [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href":
                                                "#icon-" +
                                                props.option[_vm.optionIcon]
                                            }
                                          })
                                        ]
                                      )
                                    : _vm.optionIcon
                                    ? _c(
                                        "svg",
                                        {
                                          staticClass: "icon-sm mr-2",
                                          attrs: { viewBox: "0 0 24 24" }
                                        },
                                        [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href":
                                                "#icon-" + _vm.optionIcon
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._t("optionIcon", null, {
                                    item: props.option
                                  }),
                                  _vm._v(" "),
                                  props.option.isTag
                                    ? _c("span", [
                                        _vm._v("Add "),
                                        _c("strong", [
                                          _vm._v(_vm._s(props.option.label))
                                        ])
                                      ])
                                    : _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              value: {
                                                boundary: "viewport",
                                                title: props.option.tooltip,
                                                disabled: !props.option.tooltip
                                              },
                                              expression:
                                                "{boundary: 'viewport', title: props.option.tooltip, disabled: !props.option.tooltip}",
                                              modifiers: { hover: true }
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              props.option[_vm.selectLabel]
                                            )
                                          )
                                        ]
                                      )
                                ]
                          ],
                          { props: props }
                        )
                      ]
                    }
                  },
                  {
                    key: "afterList",
                    fn: function(props) {
                      return [
                        _vm._t(
                          "after",
                          [
                            _vm.infinite
                              ? _c(
                                  "infinite-loading",
                                  {
                                    staticStyle: { "min-height": "50px" },
                                    attrs: {
                                      identifier:
                                        _vm.identifier + "-" + _vm.infiniteIndex
                                    },
                                    on: {
                                      infinite: function($event) {
                                        return _vm.$emit("infinite", $event)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "spinner" },
                                        slot: "spinner"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "skeleton-item dropdown-item skeleton-item-light"
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  width: "180",
                                                  height: "19",
                                                  viewBox: "0 0 180 19",
                                                  fill: "none",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg"
                                                }
                                              },
                                              [
                                                _c("rect", {
                                                  attrs: {
                                                    y: "0.996094",
                                                    width: "18",
                                                    height: "18",
                                                    rx: "4",
                                                    fill: "#bbbbbb"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("rect", {
                                                  attrs: {
                                                    x: "26",
                                                    y: "6",
                                                    width: "120",
                                                    height: "6",
                                                    rx: "2",
                                                    fill: "#bbbbbb"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "skeleton-item dropdown-item skeleton-item-light"
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  width: "180",
                                                  height: "19",
                                                  viewBox: "0 0 180 19",
                                                  fill: "none",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg"
                                                }
                                              },
                                              [
                                                _c("rect", {
                                                  attrs: {
                                                    y: "0.996094",
                                                    width: "18",
                                                    height: "18",
                                                    rx: "4",
                                                    fill: "#bbbbbb"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("rect", {
                                                  attrs: {
                                                    x: "26",
                                                    y: "6",
                                                    width: "80",
                                                    height: "6",
                                                    rx: "2",
                                                    fill: "#bbbbbb"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {
                                      attrs: { slot: "no-more" },
                                      slot: "no-more"
                                    }),
                                    _vm._v(" "),
                                    _c("div", {
                                      attrs: { slot: "no-results" },
                                      slot: "no-results"
                                    })
                                  ]
                                )
                              : _vm._e()
                          ],
                          { props: props }
                        )
                      ]
                    }
                  },
                  {
                    key: "noOptions",
                    fn: function(props) {
                      return [
                        _vm._t(
                          "empty",
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.fetching,
                                    expression: "!fetching"
                                  }
                                ],
                                staticClass: "txt-body"
                              },
                              [_vm._v("List is empty")]
                            )
                          ],
                          { props: props }
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              ),
              model: {
                value: _vm.model,
                callback: function($$v) {
                  _vm.model = $$v
                },
                expression: "model"
              }
            },
            [
              _vm._v(" "),
              _vm._v(" "),
              _c("template", { slot: "noResult" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.fetching,
                        expression: "!fetching"
                      }
                    ]
                  },
                  [_vm._v(_vm._s(_vm.noMatchingString))]
                )
              ]),
              _vm._v(" "),
              _c("template", { slot: "selection" })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("transition-group", { attrs: { name: "fade", appear: "" } }, [
        _vm.showSelectedItems && _vm.multiple && _vm.model.length
          ? _c(
              "div",
              { key: "multiple", staticClass: "multiple-selection pt-3" },
              [
                !_vm.hideField || _vm.showCounter
                  ? _c(
                      "div",
                      { staticClass: "fx-row-center mb-3" },
                      [
                        _c("p", { staticClass: "txt-body" }, [
                          _vm._v(_vm._s(_vm.selectedTitle) + " "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.model.length) + " " + _vm._s(_vm.type)
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm._t("selectedTitle")
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.model, function(item) {
                  return _c(
                    "div",
                    {
                      key: "item-" + item.id,
                      staticClass: "btn btn-tag form-control-select-tag",
                      class: [
                        {
                          "mr-2 mb-2": !_vm.tagBlock,
                          "btn-block mb-0": _vm.tagBlock,
                          "tag-basic": _vm.tagBasic
                        },
                        "btn-" + _vm.tagSize
                      ]
                    },
                    [
                      _vm._t("tagIcon", null, { item: item }),
                      _vm._v(" "),
                      _vm.tagIcon
                        ? _c(
                            "svg",
                            {
                              staticClass: "btn-left icon-sm",
                              attrs: { viewBox: "0 0 24 24" }
                            },
                            [
                              _c("use", {
                                attrs: { "xlink:href": "#icon-" + _vm.tagIcon }
                              })
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "btn-tag-label",
                          class: _vm.tagBlock ? "mr-auto" : ""
                        },
                        [_vm._v(_vm._s(item[_vm.selectLabel]))]
                      ),
                      _vm._v(" "),
                      _vm.tagBlock
                        ? _vm._t("actions", null, { item: item })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.readonly
                        ? _c(
                            "b-btn",
                            {
                              attrs: {
                                variant: "icon ml-1 primary",
                                size: _vm.tagSize
                              },
                              on: {
                                click: function($event) {
                                  return _vm.removeItem(item)
                                }
                              }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "icon-sm",
                                  attrs: { viewBox: "0 0 24 24" }
                                },
                                [
                                  _c("use", {
                                    attrs: { "xlink:href": "#icon-cross" }
                                  })
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                })
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.suggestions.length &&
        (_vm.$can("copilot") || _vm.$can("searchie-ai"))
          ? _c(
              "div",
              { key: "suggestion", staticClass: "multiple-selection pt-3" },
              [
                _c("p", { staticClass: "txt-body mb-2" }, [
                  _c("strong", [_vm._v("Suggestions")])
                ]),
                _vm._v(" "),
                _vm._l(_vm.suggestions, function(item) {
                  return _c(
                    "div",
                    {
                      key: "item-" + item.id,
                      staticClass: "btn btn-tag btn-sm",
                      class: [
                        _vm.tagBlock ? "btn-block" : "mr-2 mb-2",
                        { disabled: _vm.isInTagModel(item) }
                      ],
                      on: {
                        click: function($event) {
                          return _vm.addSuggestedTag(item)
                        }
                      }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "btn-left",
                          attrs: { viewBox: "0 0 24 24" }
                        },
                        [
                          _c("use", {
                            attrs: {
                              "xlink:href":
                                "#icon-" +
                                (_vm.isInTagModel(item) ? "tick" : "plus")
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "btn-tag-label",
                          class: _vm.tagBlock ? "mr-auto" : ""
                        },
                        [_vm._v(_vm._s(item.content))]
                      )
                    ]
                  )
                })
              ],
              2
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "b-alert",
        {
          staticClass: "mt-3 mb-0",
          attrs: { variant: "error", show: !!_vm.error }
        },
        [
          _c(
            "svg",
            {
              staticClass: "alert-icon icon-sm",
              attrs: { width: "24", viewBox: "0 0 24 24" }
            },
            [_c("use", { attrs: { "xlink:href": "#icon-status-error-color" } })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "alert-content" }, [
            _c("span", { staticClass: "txt-body" }, [_vm._v(_vm._s(_vm.error))])
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }