var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "audience-slide-in-template",
    { attrs: { "top-label": "Bulk Actions" } },
    [
      _c("div", { staticClass: "side-panel-group-header-sm my-0" }, [
        _c(
          "h2",
          { staticClass: "side-panel-title txt-bold txt-title-small mb-0" },
          [_vm._v("Bulk Actions")]
        )
      ]),
      _vm._v(" "),
      _c("selected-audience"),
      _vm._v(" "),
      _c("hr", { staticClass: "mt-0 mb-3" }),
      _vm._v(" "),
      _c("div", { staticClass: "side-panel-body" }, [
        _c(
          "div",
          { staticClass: "side-panel-widget side-panel-widget-group" },
          [
            _c(
              "div",
              { staticClass: "side-panel-option-wrap" },
              [
                _vm._l(_vm.accessRoutes, function(r, index) {
                  return [
                    _c("side-panel-option", {
                      key: r.label,
                      attrs: {
                        link: {
                          name: r.route,
                          params: { showNotify: r.showNotify, title: r.label }
                        },
                        label: r.label,
                        description: r.description,
                        "icon-left": r.icon,
                        "icon-right": "arrow-right",
                        isLock: _vm.$cantView("hubs"),
                        customRight: _vm.$cantView("hubs")
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "right",
                            fn: function() {
                              return [
                                _c("upgrade-button", { staticClass: "ml-auto" })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _vm._v(" "),
                    index === 0 ? _c("hr", { staticClass: "my-0" }) : _vm._e()
                  ]
                })
              ],
              2
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "side-panel-widget side-panel-widget-group" },
          [
            _c(
              "div",
              { staticClass: "side-panel-option-wrap" },
              [
                _vm._l(_vm.tagRoutes, function(r, index) {
                  return [
                    _c("side-panel-option", {
                      key: r.label,
                      attrs: {
                        link: { name: r.route, params: { title: r.label } },
                        label: r.label,
                        description: r.description,
                        "icon-left": r.icon,
                        "icon-right": "arrow-right"
                      }
                    }),
                    _vm._v(" "),
                    index === 0 ? _c("hr", { staticClass: "my-0" }) : _vm._e()
                  ]
                })
              ],
              2
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "side-panel-widget side-panel-widget-group" },
          [
            _c(
              "div",
              { staticClass: "side-panel-option-wrap" },
              [
                _vm._l(_vm.achievementRoutes, function(r, index) {
                  return [
                    _c("side-panel-option", {
                      key: r.label,
                      attrs: {
                        link: { name: r.route, params: { title: r.label } },
                        label: r.label,
                        description: r.description,
                        "icon-left": r.icon,
                        "icon-right": "arrow-right",
                        isLock: _vm.$cantView("hubs"),
                        customRight: _vm.$cantView("hubs")
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "right",
                            fn: function() {
                              return [
                                _c("upgrade-button", { staticClass: "ml-auto" })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _vm._v(" "),
                    index === 0 ? _c("hr", { staticClass: "my-0" }) : _vm._e()
                  ]
                })
              ],
              2
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }