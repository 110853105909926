<template>
  <audience-slide-in-template top-label="Bulk Actions">
    <div class="side-panel-group-header-sm my-0">
      <h2 class="side-panel-title txt-bold txt-title-small mb-0">Bulk Actions</h2>
    </div>
    <selected-audience />
    <hr class="mt-0 mb-3">
    <div class="side-panel-body">
      <div class="side-panel-widget side-panel-widget-group">
        <div class="side-panel-option-wrap">
          <template  v-for="(r, index) in accessRoutes">
            <side-panel-option
              :key="r.label"
              :link="{name: r.route, params: { showNotify: r.showNotify, title: r.label }}"
              :label="r.label"
              :description="r.description"
              :icon-left="r.icon"
              icon-right="arrow-right"
              :isLock="$cantView('hubs')"
              :customRight="$cantView('hubs')"
            >
              <template #right>
                <upgrade-button class="ml-auto" />
              </template>
            </side-panel-option>
            <hr class="my-0" v-if="index === 0"/>
          </template>
        </div>
      </div>
      <div class="side-panel-widget side-panel-widget-group">
        <div class="side-panel-option-wrap">
          <template v-for="(r, index) in tagRoutes">
            <side-panel-option
              :key="r.label"
              :link="{name: r.route, params: { title: r.label }}"
              :label="r.label"
              :description="r.description"
              :icon-left="r.icon"
              icon-right="arrow-right"
            />
            <hr class="my-0" v-if="index === 0">
          </template>
        </div>
      </div>
      <div class="side-panel-widget side-panel-widget-group">
        <div class="side-panel-option-wrap">
          <template v-for="(r, index) in achievementRoutes">
            <side-panel-option
              :key="r.label"
              :link="{name: r.route, params: { title: r.label }}"
              :label="r.label"
              :description="r.description"
              :icon-left="r.icon"
              icon-right="arrow-right"
              :isLock="$cantView('hubs')"
              :customRight="$cantView('hubs')"
            >
              <template #right>
                <upgrade-button class="ml-auto" />
              </template>
            </side-panel-option>
            <hr class="my-0" v-if="index === 0">
          </template>
        </div>
      </div>
    </div>
  </audience-slide-in-template>
</template>

<script>
  import AudienceSlideInTemplate from "@app2/modules/Audience/Components/Template";
  import SidePanelOption from "@app2/core/Components/Sidepanel/Option";
  import SelectedAudience from '@app2/modules/Audience/Bulk/Components/SelectedAudience'
  import UpgradeButton from '@app2/core/Components/UpgradeButton.vue';
  import rootRouter from "@app2/router";
  import eventBus from '@app2/core/eventBus'

  export default {
    name: 'BulkIndex',
    components: {
      AudienceSlideInTemplate,
      SidePanelOption,
      SelectedAudience,
      UpgradeButton
    },
    data() {
      return {
        accessRoutes: [
          {
            label: 'Give Access',
            description: 'Give your audience access to specific Hubs.',
            icon: 'key',
            route: 'HubsAttach',
            showNotify: true
          },
          {
            label: 'Remove Access',
            description: 'Remove Hub access from your audience.',
            icon: 'key-disabled',
            route: 'HubsDetach',
            showNotify: false
          },
        ],
        tagRoutes: [
          {
            label: 'Add Audience Tags',
            description: 'Add tags to your audience.',
            icon: 'tag',
            route: 'TagsAttach'
          },
          {
            label: 'Remove Audience Tags',
            description: 'Remove tags from your audience.',
            icon: 'tag-disabled',
            route: 'TagsDetach',
          },
        ],
        achievementRoutes: [
          {
            label: 'Add Achievements',
            description: 'Add manual achievements to your audience.',
            icon: 'badge',
            route: 'AchievementsAttach'
          },
          {
            label: 'Remove Achievements',
            description: 'Remove manual achievements from your audience.',
            icon: 'badge-disabled',
            route: 'AchievementsDetach',
          },
        ],
      }
    },
    methods: {
      redirect() {
        rootRouter.push({name: 'AccountSubscription'});
      }
    },
    mounted() {
      setTimeout(() => {
        eventBus.$emit('audience-bulk-has-changed', false)
      }, 100) 
    }
  }
</script>
