import { render, staticRenderFns } from "./MagicStick.vue?vue&type=template&id=710fbe97&"
import script from "./MagicStick.vue?vue&type=script&lang=js&"
export * from "./MagicStick.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/src/searchie/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('710fbe97')) {
      api.createRecord('710fbe97', component.options)
    } else {
      api.reload('710fbe97', component.options)
    }
    module.hot.accept("./MagicStick.vue?vue&type=template&id=710fbe97&", function () {
      api.rerender('710fbe97', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/2.0/js/core/Components/Common/Illustrations/MagicStick.vue"
export default component.exports