import axios from 'axios';
import config from '@app2/api/config';
import eventBus from '@app2/core/eventBus';
import get from 'lodash/get';

const instance = axios.create(config(axios));

/**
 * Interceptors for responses that return tokens
 * Format expires_in (seconds) to an expiration date before passing it to the application
 */
instance.interceptors.response.use(
  res => res,
  error => {
    const { status } = (error && error.response) || { status: 500 };
    // Handle Session Timeouts / Logged out
    if (status === 401 && !get(error, 'response.config.url', '').includes('chat/token')) {
      eventBus.$emit('auth/unauthenticated');
    }
    throw error.response || { data: { message: error.message || 'Something went wrong.' } };
  }
);

export default instance;
