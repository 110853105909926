var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 20 20"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(1 1)" } }, [
        _c("path", {
          attrs: {
            d: "M773,344a9,9,0,1,1-9-9,9,9,0,0,1,9,9Zm0,0",
            transform: "translate(-755 -335)",
            fill: "#6da544"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M870.758,579.871a1.174,1.174,0,0,0,1.174,1.174h3.522a.978.978,0,0,0,.978.978h1.174a.978.978,0,0,0,.978-.978v-1.174Zm0,0",
            transform: "translate(-865.671 -569.11)",
            fill: "#f0f0f0"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M1034.045,450.758V453.5a.783.783,0,0,1-.783.783v1.174a1.959,1.959,0,0,0,1.956-1.957v-2.739Zm0,0",
            transform: "translate(-1021.034 -445.671)",
            fill: "#f0f0f0"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M842.6,453.5a.784.784,0,0,1-.783.783v1.174a1.959,1.959,0,0,0,1.957-1.957v-2.739H842.6Zm0,0",
            transform: "translate(-838.001 -445.671)",
            fill: "#f0f0f0"
          }
        }),
        _c("rect", {
          attrs: {
            width: "1.174",
            height: "2.739",
            transform: "translate(11.25 5.087)",
            fill: "#f0f0f0"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M902.826,452.323a.2.2,0,0,1-.391,0v-1.565H901.26v1.565a.2.2,0,0,1-.391,0v-1.565H899.7v1.565a3.522,3.522,0,0,0,3.108,1.358.783.783,0,0,1-.76.6v1.174A1.959,1.959,0,0,0,904,453.5v-2.739h-1.174Zm0,0",
            transform: "translate(-893.336 -445.671)",
            fill: "#f0f0f0"
          }
        }),
        _c("rect", {
          attrs: {
            width: "1.761",
            height: "1.174",
            transform: "translate(6.359 8.609)",
            fill: "#f0f0f0"
          }
        }),
        _c(
          "g",
          { attrs: { fill: "none", stroke: "#e4e4e4", "stroke-width": "1" } },
          [
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9", stroke: "none" }
            }),
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9.5", fill: "none" }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }