<template>
  <svg id="Audio_Icon_small" data-name="Audio Icon small" xmlns="http://www.w3.org/2000/svg" width="14.37" height="13.357" viewBox="0 0 14.37 13.357">
    <path id="Path_1117" data-name="Path 1117" d="M1179.138,350.958a6.889,6.889,0,0,0-.691-3.009l-.011-.021c-.035-.067-.082-.127-.12-.193a7.248,7.248,0,0,0-1.947-2.256c-.016-.012-.034-.022-.05-.035a7.238,7.238,0,0,0-.8-.519c-.024-.014-.046-.032-.07-.046l-.017-.009-.239-.124-.013-.006c-.048-.024-.1-.04-.147-.063-.147-.069-.3-.132-.447-.19s-.3-.112-.455-.16c-.129-.04-.257-.08-.388-.114-.192-.049-.384-.086-.577-.118-.1-.018-.205-.04-.309-.054a7.3,7.3,0,0,0-.909-.061h-.005a7.224,7.224,0,0,0-.9.061c-.1.014-.208.036-.312.054-.192.033-.384.07-.574.118-.132.034-.26.074-.389.115-.152.047-.3.1-.453.158s-.3.123-.452.193c-.047.022-.1.037-.143.061l-.012.006-.238.124-.018.009c-.022.012-.042.029-.064.042a7.254,7.254,0,0,0-.823.537l-.025.017a7.687,7.687,0,0,0-2.013,2.36c-.018.032-.041.06-.058.093l-.011.022c-.095.2-.174.4-.25.6-.015.041-.036.081-.05.122s-.018.065-.028.1a7.079,7.079,0,0,0-.363,2.194,6.732,6.732,0,0,0,.077,1.016h0l.28,2.07a.749.749,0,0,0,.844.643h0a.75.75,0,0,0,.643-.844h0l-.281-2.071,0-.011a5.376,5.376,0,0,1,.477-3.152c.005-.011.013-.02.019-.031a5.928,5.928,0,0,1,2.314-2.364c.112-.062.227-.12.343-.175s.26-.116.392-.167c.111-.042.223-.082.336-.117s.213-.067.32-.094c.15-.039.3-.068.456-.094.081-.014.162-.032.244-.042a5.8,5.8,0,0,1,.722-.05h.005a5.673,5.673,0,0,1,.715.049c.086.011.171.029.256.044.148.025.3.054.442.091.111.028.221.062.331.1s.22.073.329.115c.13.05.259.1.388.165s.237.115.352.179a6.127,6.127,0,0,1,1.762,1.529,5.855,5.855,0,0,1,.554.842l.013.021a5.377,5.377,0,0,1,.479,3.13l0,.024v.01l-.281,2.071a.75.75,0,0,0,.642.844h0a.751.751,0,0,0,.845-.642l.281-2.071h0A6.873,6.873,0,0,0,1179.138,350.958Z" transform="translate(-1164.768 -343.98)" fill="#fff"/>
    <path id="Path_1118" data-name="Path 1118" d="M1168.251,350.68a1,1,0,0,0-.873,1.112l.563,4.665a1,1,0,0,0,1.986-.24l-.563-4.664A1,1,0,0,0,1168.251,350.68Z" transform="translate(-1164.768 -343.98)" fill="#fff"/>
    <path id="Path_1119" data-name="Path 1119" d="M1175.656,350.68a1,1,0,0,0-1.113.873l-.563,4.664a1,1,0,0,0,1.986.24l.563-4.665A1,1,0,0,0,1175.656,350.68Z" transform="translate(-1164.768 -343.98)" fill="#fff"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-audio'
  }
</script>
