<template>
  <svg class="icon-element-paragraph" xmlns="http://www.w3.org/2000/svg" width="16.193" height="11.422" viewBox="0 0 16.193 11.422">
    <path d="M15.443 3.313H.75a.75.75 0 0 0 0 1.5h14.693a.75.75 0 0 0 0-1.5ZM15.443 6.613H.75a.75.75 0 0 0 0 1.5h14.693a.75.75 0 0 0 0-1.5ZM.75 1.5h14.693a.75.75 0 0 0 0-1.5H.75a.75.75 0 0 0 0 1.5ZM9.75 9.922h-9a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5Z" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-element-paragraph'
  }
</script>
