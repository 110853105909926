<template>
    <div class="auth-page-custom-details" v-if="details.length">
        <div
            v-for="(detail, index) in details"
            :key="`auth-detail-${index}`"
            class="auth-page-custom-detail"
            :class="{[`auth-page-custom-detail-${detail.type}`]: true}">
            <hr v-if="detail.type === 'horizontal_rule'" />
            <img v-else-if="detail.type === 'image'" :src="detail.src">
            <h2 class="txt-heading my-4" v-else-if="detail.type === 'heading' && (detail.level === 'regular' || !detail.level)"><strong>{{ detail.text }}</strong></h2>
            <h3 class="txt-heading-small" v-else-if="detail.type === 'heading' && detail.level === 'small'"><strong>{{ detail.text }}</strong></h3>
            <p class="txt-heading-small" :class="{'txt-muted': detail.muted}" v-else-if="detail.type === 'paragraph'">{{ detail.text }}</p>
            <template v-else-if="detail.type === 'list'">
                <ul>
                    <li v-for="(item, index) in getListBeforeBreak(detail.list)" class="txt-heading-small">
                        <span class="auth-page-custom-detail-list__icon mr-2">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.66667 8L6.66667 12.6667L13.3333 4" stroke="#5581F4" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                        {{ item }}
                    </li>
                    <template v-if="!hasListBreak(detail.list)">
                        <li v-for="(item, index) in getListAfterBreak(detail.list)" class="txt-heading-small">
                            <span class="auth-page-custom-detail-list__icon mr-2">
                                <svg class="txt-primary" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-tick"></use></svg>
                            </span>
                            {{ item }}
                        </li>
                    </template>
                </ul>
                <b-btn v-if="hasListBreak(detail.list)" variant="link" @click="showListAfterBreak(detail)">
                    <svg width="24" class="btn-left mr-1" viewBox="0 0 24 24"><use xlink:href="#icon-chevron-down"></use></svg>
                    See All
                </b-btn>
            </template>
            <div v-else-if="detail.type === 'quote'" class="my-4">
                <h3 class="txt-heading-small"><strong>{{ detail.author }}</strong></h3>
                <p class="txt-heading-small txt-muted">"{{ detail.text }}"</p>
            </div>
            <b-btn v-else-if="detail.type === 'contact_support'" variant="link" @click="openSupportModal" class="mb-4">
                <svg width="24" class="btn-left mr-1" viewBox="0 0 24 24"><use xlink:href="#icon-user-support"></use></svg>
                Contact Support
            </b-btn>
            <div v-else-if="detail.type === 'faq'" class="fx-row-start">
                <span class="auth-page-custom-detail-faq__icon mr-2">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 12.75V13.5M9 11.25V9.75C10.275 9.75 11.25 8.775 11.25 7.5C11.25 6.225 10.275 5.25 9 5.25C7.725 5.25 6.75 6.225 6.75 7.5M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#5581F4" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                </span>
                <div>
                    <h4 class="txt-heading-small">{{ detail.question }}</h4>
                    <p class="txt-heading-small txt-muted">
                        {{ detail.answer }}
                        <a v-if="detail.learnMore" :href="detail.learnMore" target="_blank" class="txt-primary">Learn more.</a>
                    </p>
                </div>
            </div>
            <div v-if="detail.type === 'image'" class="effect-right">
                <svg width="52" height="49" viewBox="0 0 52 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M36.5363 11.7256L16.5364 36.894" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M40.8585 35.1787L29.1176 43.5928" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.07983 19.0889L5.46516 22.6633" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
  import RegisterMixin from '@app2/modules/Register/register.mixin.js'
  export default {
    name: 'AuthDetails',
    mixins: [RegisterMixin],
    computed: {
        details() {
            return this.checkoutDetails?.sidebar || [];
        }
    },
    methods: {
        hasListBreak(list) {
            return list.find(item => item === '-');
        },
        getListBeforeBreak(list) {
            list = JSON.parse(JSON.stringify(list));
            if ( !this.hasListBreak(list) ) return list;
            return list.slice(0, list.indexOf('-'));
        },
        getListAfterBreak(list) {
            list = JSON.parse(JSON.stringify(list));
            if ( !this.hasListBreak(list) ) return [];
            return list.slice(list.indexOf('-') + 1);
        },
        showListAfterBreak(detail) {
            detail.list = detail.list.filter(item => item !== '-');
        },
        openSupportModal() {
            if (!window.HubSpotConversations) return;
            window.HubSpotConversations.widget.load();
            window.HubSpotConversations.widget.open();
        }
    }
  };
</script>
