var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19",
        height: "19",
        viewBox: "0 0 19 19"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M23704.924,23653a4.935,4.935,0,0,1-4.924-4.93v-9.145a4.93,4.93,0,0,1,4.924-4.926h9.15a4.934,4.934,0,0,1,4.926,4.926v9.145a4.939,4.939,0,0,1-4.926,4.93Zm-3.416-14.074v9.145a3.39,3.39,0,0,0,3.416,3.422h9.15a3.392,3.392,0,0,0,3.418-3.422v-9.145a3.391,3.391,0,0,0-3.418-3.418h-9.15A3.389,3.389,0,0,0,23701.508,23638.928Zm1.107.9a3.114,3.114,0,1,1,3.113,3.117A3.134,3.134,0,0,1,23702.615,23639.832Z",
          transform: "translate(-23700 -23634.002)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }