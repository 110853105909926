var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-audience-search",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "15.103",
        height: "15.103",
        viewBox: "0 0 15.103 15.103"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M14.881 13.814l-2.779-2.779a6.8 6.8 0 10-1.068 1.068l2.779 2.779a.755.755 0 001.068-1.068zm-8.085-1.732a5.287 5.287 0 113.737-1.549A5.269 5.269 0 016.8 12.082z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }