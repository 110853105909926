var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "1060",
        height: "627",
        viewBox: "0 0 1060 627"
      }
    },
    [
      _c(
        "defs",
        [
          _c("clipPath", { attrs: { id: "clip-path" } }, [
            _c("path", {
              attrs: {
                id: "Rectangle_2572",
                "data-name": "Rectangle 2572",
                d:
                  "M10,0H1050a10,10,0,0,1,10,10V627a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z",
                fill: "#e4e4e4",
                opacity: "0.3"
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "linear-gradient",
                x1: "0.439",
                y1: "0.311",
                x2: "1.999",
                y2: "-0.267",
                gradientUnits: "objectBoundingBox"
              }
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-opacity": "0" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-opacity": "0.502" } })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "linear-gradient-2",
                x1: "0.295",
                y1: "0.874",
                x2: "1.677",
                y2: "-1.649",
                gradientUnits: "objectBoundingBox"
              }
            },
            [
              _c("stop", { attrs: { offset: "0.076", "stop-opacity": "0" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-opacity": "0.502" } })
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "linear-gradient-3",
              x1: "0.622",
              y1: "0.181",
              x2: "2.258",
              y2: "-0.163",
              "xlink:href": "#linear-gradient"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { attrs: { id: "Thumbnail", "clip-path": "url(#clip-path)" } }, [
        _c("path", {
          attrs: {
            id: "Thumbnail_Base",
            "data-name": "Thumbnail Base",
            d:
              "M10,0H1050a10,10,0,0,1,10,10V627a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z",
            fill: "#e4e4e4",
            opacity: "0.3"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            id: "Path_7101",
            "data-name": "Path 7101",
            d:
              "M110.289,10.707,531.24.5c48-1.164,85.964,36.8,84.8,84.8L595.633,927.042c-1.164,48-41.017,87.85-89.014,89.014L85.668,1026.262c-48,1.164-85.964-36.8-84.8-84.8L21.275,99.721C22.438,51.724,62.291,11.871,110.289,10.707Z",
            transform: "translate(-272.272 497.356) rotate(-45)",
            opacity: "0.5",
            fill: "url(#linear-gradient)"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            id: "Path_7102",
            "data-name": "Path 7102",
            d:
              "M887.369,1053.487c0-28.242,21.787-51.139,48.662-51.139s48.663,22.9,48.663,51.139-21.788,51.137-48.663,51.137-48.662-22.9-48.662-51.137Z",
            transform: "translate(-52.103 -867.964)",
            opacity: "0.5",
            fill: "url(#linear-gradient-2)"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            id: "Path_7103",
            "data-name": "Path 7103",
            d:
              "M94.735,11.418,544.688.509c40-.97,71.637,30.669,70.667,70.667L595.623,885.051c-.97,40-34.18,73.208-74.178,74.178L71.492,970.138c-40,.97-71.636-30.669-70.667-70.667L20.557,85.6C21.527,45.6,54.738,12.387,94.735,11.418Z",
            transform: "translate(319.023 699.413) rotate(-45)",
            opacity: "0.5",
            fill: "url(#linear-gradient-3)"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }