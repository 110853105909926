var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "side-panel-group-header-sm pt-3 mb-3 mt-0" },
    [
      _vm.selectedAudience.length
        ? _c(
            "b-avatar-group",
            { staticClass: "mr-1", attrs: { size: "32px", rounded: "" } },
            _vm._l(_vm.selectedAudience, function(a, index) {
              return index < 5
                ? _c("b-avatar", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true }
                      }
                    ],
                    key: index,
                    staticStyle: { "min-width": "32px" },
                    attrs: { title: a.name, src: a.photo_url }
                  })
                : _vm._e()
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedAudience.length > 5
        ? _c("span", { staticClass: "mr-auto txt-primary" }, [
            _c("span", { staticClass: "txt-heading-small" }, [
              _c("b", [_vm._v("+" + _vm._s(_vm.selectedAudience.length - 5))]),
              _vm._v(
                " " +
                  _vm._s(
                    "" +
                      (_vm.selectedAudience.length === 1
                        ? " person"
                        : " people")
                  ) +
                  " selected"
              )
            ])
          ])
        : _c("span", { staticClass: "mr-auto txt-primary" }, [
            _c("span", { staticClass: "txt-heading-small" }, [
              _c("b", [_vm._v(_vm._s(_vm.selectedAudience.length))]),
              _vm._v(
                " " +
                  _vm._s(
                    "" +
                      (_vm.selectedAudience.length === 1
                        ? " person"
                        : " people")
                  ) +
                  " selected"
              )
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }