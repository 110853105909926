var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "191",
        height: "130",
        viewBox: "0 0 191 130"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            "data-name": "Group 3304",
            transform: "translate(-212 -37.899)"
          }
        },
        [
          _c("rect", {
            staticClass: "background",
            attrs: {
              "data-name": "Rectangle 715",
              width: "191",
              height: "130",
              rx: "10",
              transform: "translate(212 37.899)",
              fill: "#dbdeff"
            }
          }),
          _c(
            "g",
            {
              attrs: {
                "data-name": "Group 3302",
                transform: "translate(243 60.899)"
              }
            },
            [
              _c("rect", {
                attrs: {
                  "data-name": "Rectangle 713",
                  width: "130",
                  height: "84",
                  rx: "5",
                  fill: "#fff"
                }
              }),
              _c(
                "g",
                {
                  attrs: {
                    "data-name": "Group 3299",
                    transform: "translate(5 5)",
                    opacity: ".5",
                    fill: "#293ad3"
                  }
                },
                [
                  _c("circle", {
                    attrs: {
                      "data-name": "Ellipse 137",
                      cx: "2",
                      cy: "2",
                      r: "2"
                    }
                  }),
                  _c("circle", {
                    attrs: {
                      "data-name": "Ellipse 138",
                      cx: "2",
                      cy: "2",
                      r: "2",
                      transform: "translate(7)"
                    }
                  }),
                  _c("circle", {
                    attrs: {
                      "data-name": "Ellipse 139",
                      cx: "2",
                      cy: "2",
                      r: "2",
                      transform: "translate(14)"
                    }
                  })
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    "data-name": "Group 3301",
                    transform: "translate(88.734 19.167)"
                  }
                },
                [
                  _c("rect", {
                    attrs: {
                      "data-name": "Rectangle 712",
                      width: "36",
                      height: "60",
                      rx: "3",
                      transform: "translate(.266 -.167)",
                      fill: "#c7cbff"
                    }
                  }),
                  _c(
                    "g",
                    {
                      attrs: {
                        "data-name": "Group 3292",
                        transform: "translate(3.21 12.061)"
                      }
                    },
                    [
                      _c("rect", {
                        attrs: {
                          "data-name": "Rectangle 539",
                          width: "29.98",
                          height: "6.116",
                          rx: "2",
                          transform: "translate(0 8.657)",
                          fill: "#fff"
                        }
                      }),
                      _c(
                        "g",
                        {
                          attrs: {
                            "data-name": "Group 2940",
                            transform: "translate(0 21.265)",
                            opacity: ".5",
                            fill: "#293ad3"
                          }
                        },
                        [
                          _c("rect", {
                            attrs: {
                              "data-name": "Rectangle 541",
                              width: "8.657",
                              height: "2.164",
                              rx: "1.082"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              "data-name": "Rectangle 551",
                              width: "12.444",
                              height: "2.164",
                              rx: "1.082",
                              transform: "translate(10.821)"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              "data-name": "Rectangle 554",
                              width: "4.328",
                              height: "2.164",
                              rx: "1.082",
                              transform: "translate(25.429)"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              "data-name": "Rectangle 552",
                              width: "12.444",
                              height: "2.164",
                              rx: "1.082",
                              transform: "translate(7.575 3.787)"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              "data-name": "Rectangle 553",
                              width: "5.411",
                              height: "2.164",
                              rx: "1.082",
                              transform: "translate(0 3.787)"
                            }
                          })
                        ]
                      ),
                      _c("rect", {
                        attrs: {
                          "data-name": "Rectangle 542",
                          width: "15.053",
                          height: "2.164",
                          rx: "1.082",
                          transform: "translate(7.464)",
                          fill: "#293ad3",
                          opacity: ".5"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }