<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <g data-name="Calendar Icon">
      <path data-name="Path 709" d="M8.932 1.344h-.25V.593a.594.594 0 1 0-1.187 0v.751H4.5V.593a.594.594 0 1 0-1.187 0v.751h-.251A3.066 3.066 0 0 0 0 4.409v4.523A3.065 3.065 0 0 0 3.066 12h5.866A3.065 3.065 0 0 0 12 8.933V4.41a3.066 3.066 0 0 0-3.068-3.066zM3.066 2.531h.251v.751a.594.594 0 0 0 1.187 0v-.751h2.991v.751a.594.594 0 0 0 1.187 0v-.751h.25a1.883 1.883 0 0 1 1.879 1.879v.284H1.187V4.41a1.88 1.88 0 0 1 1.879-1.879zm5.866 8.281H3.066a1.881 1.881 0 0 1-1.879-1.879V5.88h9.624v3.053a1.883 1.883 0 0 1-1.879 1.878z" fill="#939393"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'IconCalendar',
  };
</script>
