<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="icon-card" width="17.835" height="13.654" viewBox="0 0 17.835 13.654">
    <path id="Path_671" data-name="Path 671" d="M342.835,384H331a3,3,0,0,0-3,3v7.655a3,3,0,0,0,3,3h11.835a3,3,0,0,0,3-3V387A3,3,0,0,0,342.835,384ZM331,385.5h11.835a1.5,1.5,0,0,1,1.5,1.5v1.064H329.5V387A1.5,1.5,0,0,1,331,385.5Zm11.835,10.656H331a1.5,1.5,0,0,1-1.5-1.5v-5.091h14.836v5.091A1.5,1.5,0,0,1,342.835,396.155Z" transform="translate(-328 -384)" fill="#939393"/>
    <path id="Path_672" data-name="Path 672" d="M337.532,393.064h-5.974a.75.75,0,0,0,0,1.5h5.974a.75.75,0,0,0,0-1.5Z" transform="translate(-328 -384)" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconCard',
  };
</script>
