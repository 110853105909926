<template>
  <svg class="icon-duration-short" xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
    <path d="M8.377 9.401a.755.755 0 00.753 0l3.033-1.779a.75.75 0 00-.759-1.293l-1.9 1.116V4.306a.75.75 0 00-1.5 0v4.448a.753.753 0 00.373.647z" fill="currentColor"/>
    <path d="M16.749 8a.75.75 0 00-.75.75A7.25 7.25 0 115.393 2.322a.75.75 0 10-.7-1.328 8.75 8.75 0 1012.8 7.756.75.75 0 00-.744-.75zM8.749 1.5a7.246 7.246 0 016.347 3.744.75.75 0 001.312-.727A8.747 8.747 0 008.749 0a.75.75 0 000 1.5z" fill="currentColor"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconDurationShort',
  };
</script>
