var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item && _vm.item.id
    ? _c(
        "div",
        {
          staticClass: "card-segment",
          class: {
            hovered: _vm.isHovered,
            disabled: _vm.item.status !== "done",
            failed: _vm.item.status == "failed"
          },
          on: {
            mouseenter: function($event) {
              _vm.cardHovered = true
            },
            mouseleave: function($event) {
              _vm.cardHovered = false
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "slot-top" },
            [
              _c("h3", { staticClass: "txt-heading-small" }, [
                _vm._v(_vm._s(_vm.item.title))
              ]),
              _vm._v(" "),
              _vm._t("top", [
                _c(
                  "div",
                  { staticClass: "slot-top-cta" },
                  [
                    _vm.item.status === "done"
                      ? _c(
                          "b-btn",
                          {
                            attrs: { variant: "icon primary", size: "sm" },
                            on: { click: _vm.download }
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "icon-sm",
                                attrs: { width: "24", viewBox: "0 0 24 24" }
                              },
                              [
                                _c("use", {
                                  attrs: { "xlink:href": "#icon-download" }
                                })
                              ]
                            )
                          ]
                        )
                      : _vm.item.status == "failed"
                      ? _c(
                          "svg",
                          {
                            staticClass: "icon-sm mx-2 my-2",
                            attrs: {
                              id: "failed-indicator-" + _vm.item.hash,
                              width: "24",
                              viewBox: "0 0 24 24"
                            }
                          },
                          [
                            _c("use", {
                              attrs: {
                                "xlink:href": "#icon-warning-triangle-color"
                              }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-btn",
                      {
                        attrs: {
                          id: "card-export-dd-" + _vm.item.id,
                          variant: "icon primary ml-1",
                          size: "sm"
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "icon-sm",
                            attrs: { viewBox: "0 0 24 24" }
                          },
                          [_c("use", { attrs: { "xlink:href": "#icon-more" } })]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-popover",
                      {
                        ref: "dd",
                        attrs: {
                          placement: "leftbottom",
                          target: "card-export-dd-" + _vm.item.id,
                          triggers: "click blur"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "dropdown-cont sm" },
                          [
                            _vm.item.status === "failed"
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: {
                                      id: "failed-btn-" + _vm.item.hash
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.retry($event)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "btn-left icon-sm",
                                        attrs: {
                                          width: "24",
                                          viewBox: "0 0 24 24"
                                        }
                                      },
                                      [
                                        _c("use", {
                                          attrs: { "xlink:href": "#icon-reset" }
                                        })
                                      ]
                                    ),
                                    _vm._v(
                                      "\n              Retry\n            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "b-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.rename($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "btn-left icon-sm",
                                    attrs: { width: "24", viewBox: "0 0 24 24" }
                                  },
                                  [
                                    _c("use", {
                                      attrs: { "xlink:href": "#icon-rename" }
                                    })
                                  ]
                                ),
                                _vm._v("\n              Rename\n            ")
                              ]
                            ),
                            _vm._v(" "),
                            _c("b-dropdown-divider"),
                            _vm._v(" "),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { variant: "warning" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.destroy($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "btn-left icon-sm",
                                    attrs: { width: "24", viewBox: "0 0 24 24" }
                                  },
                                  [
                                    _c("use", {
                                      attrs: { "xlink:href": "#icon-trash" }
                                    })
                                  ]
                                ),
                                _vm._v("\n              Delete\n            ")
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          target: "download-indicator-" + _vm.item.hash,
                          show:
                            _vm.cardHovered &&
                            !_vm.isHovered &&
                            _vm.item.status !== "failed",
                          noninteractive: ""
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.item.status == "done"
                              ? "Download"
                              : "Loading..."
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.item.status == "failed"
                      ? _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: "failed-btn-" + _vm.item.hash,
                              noninteractive: ""
                            }
                          },
                          [_vm._v("Retry export")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.status == "failed"
                      ? _c(
                          "b-tooltip",
                          {
                            attrs: {
                              show:
                                _vm.cardHovered &&
                                !_vm.isHovered &&
                                _vm.item.status == "failed",
                              target: "failed-indicator-" + _vm.item.hash,
                              noninteractive: ""
                            }
                          },
                          [_vm._v("Export failed")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "slot-bottom" }, [
            _c(
              "div",
              { staticClass: "card-segment-avatars" },
              [
                _vm.item.audience_count
                  ? _c(
                      "b-avatar-group",
                      {
                        staticClass: "mr-1",
                        attrs: { size: "32px", rounded: "" }
                      },
                      _vm._l(_vm.item.top_audiences.slice(0, 5), function(
                        audience
                      ) {
                        return _c("b-avatar", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip",
                              value: audience.name,
                              expression: "audience.name"
                            }
                          ],
                          key: audience.hash,
                          staticStyle: { "min-width": "32px" },
                          attrs: { src: _vm._f("avatar")(audience) }
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.item.audience_count && _vm.getOtherPeopleCount
                  ? _c("span", { staticClass: "txt-body" }, [
                      _vm._v(_vm._s("+" + _vm.getOtherPeopleCount + " others"))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.item.audience_count
                  ? _c("span", { staticClass: "txt-body" }, [
                      _vm._v("0 people")
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "txt-body-small txt-muted-2 ml-auto" }, [
              _vm._v(_vm._s(_vm._f("date")(_vm.item.created_at)))
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }