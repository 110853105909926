<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="icon-text-link" width="14.076" height="14.077" viewBox="0 0 14.076 14.077">
    <path d="M1418.126,300.549a3.788,3.788,0,0,0-5.358,0l-.662.663a.75.75,0,1,0,1.061,1.061h0l.662-.663a2.289,2.289,0,1,1,3.236,3.238l-2.256,2.255a2.29,2.29,0,0,1-3.235,0,.75.75,0,1,0-1.061,1.061,3.792,3.792,0,0,0,5.357,0l2.256-2.256a3.79,3.79,0,0,0,0-5.358Z" transform="translate(-1405.159 -299.439)" fill="currentColor"/>
    <path d="M1411.228,310.684h0l-.662.663a2.289,2.289,0,0,1-3.237-3.236l2.257-2.256a2.289,2.289,0,0,1,3.236,0,.75.75,0,1,0,1.06-1.06,3.787,3.787,0,0,0-5.357,0l-2.256,2.255a3.788,3.788,0,0,0,5.357,5.358l.663-.663a.75.75,0,1,0-1.06-1.06Z" transform="translate(-1405.159 -299.439)" fill="currentColor"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconTextLink'
  }
</script>
