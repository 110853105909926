import Base from '@app2/models/Base';

export default class AudienceAttribute extends Base {
  static entity = 'App\\Attribute';

  static apiConfig = {
    baseURL: '/api/internal/v2/data/attributes',
    actions: {
      fetch: {
        url: '/',
        dataKey: 'data'
      },
      attachToHub(hubHash, attributes, cancelToken) {
        const config = {
          dataKey: 'data',
          baseURL: '/api/internal/v2/hubs/',
          attributes,
        }
        if (cancelToken) config.cancelToken = cancelToken
        return this.post(`${hubHash}/attributes`, config)
      },
      fetchForHub(hubHash, params, cancelToken) {
        const config = {
          dataKey: 'data',
          baseURL: '/api/internal/v2/hubs/',
          params,
          dataTransformer: response => {
            return response.data;
          }
        }
        if (cancelToken) config.cancelToken = cancelToken
        return this.get(`${hubHash}/attributes`, config)
      },
      saveForHub(attrId, hubHash, params, cancelToken) {
        const config = {
          dataKey: 'data',
          baseURL: '/api/internal/v2/hubs/',
          params,
          dataTransformer: response => {
            return response.data;
          }
        }
        if (cancelToken) config.cancelToken = cancelToken
        return this.put(`${hubHash}/attributes/${attrId}`, config)
      },
      setPosition(hubHash, params) {
        const config = {
          dataKey: 'data',
          baseURL: '/api/internal/v2/hubs/',
          params,
          dataTransformer: response => {
            return response.data;
          }
        }
        return this.post(`${hubHash}/attributes/position`, config)
      }
    }
  };

  static primaryKey = 'id';

  watchFields = ['title', 'description', 'settings', 'type', 'hidden', 'pivot'];

  requiredFields = ['title', 'settings', 'type', 'hidden', 'options', 'pivot']

  static fields() {
    return {
      id: this.attr(null),
      hash: this.attr(null),
      title: this.attr(null),
      description: this.attr(null),
      options: this.attr([]),
      settings: this.attr({}),
      hidden: this.attr(false),
      pivot: this.attr({}),
      page: this.attr(null),
      type: this.attr(null),
      ...super.fields(),
    };
  }

  static mutators() {
    return {
      settings(value) {
        return ( Array.isArray(value) || !value ) ? {} : value
      },
      options(value) {
        return ( !Array.isArray(value) || !value ) ? [] : value
      },
      hidden(value) {
        return !!value;
      },
    }
  }
}
