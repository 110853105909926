<template>
  <svg class="icon-close-lg" xmlns="http://www.w3.org/2000/svg" width="14.121" height="14.121" viewBox="0 0 14.121 14.121">
    <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5">
      <path d="M13.061 13.061l-12-12M1.061 13.061l12-12"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'IconCloseLg',
  };
</script>
