<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12.286" height="22.573" viewBox="0 0 12.286 22.573">
    <g id="Plus" transform="translate(1.414 1.414)">
      <path id="Arrow_Bign" data-name="Arrow Bign" d="M1467.8,256l9.872,9.872-9.872,9.872" transform="translate(-1467.797 -255.996)" fill="none" stroke="#293ad3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-arrow-right-light',
  };
</script>
