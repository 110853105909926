<template>
  <div class="side-panel-group-header-sm pt-3 mb-3 mt-0">
    <b-avatar-group class="mr-1" v-if="selectedAudience.length" size="32px" rounded>
      <b-avatar style="min-width: 32px" v-if="index < 5" v-for="(a, index) in selectedAudience" v-b-tooltip.hover :title="a.name"  :key="index" :src="a.photo_url"></b-avatar>
    </b-avatar-group>
    <span class="mr-auto txt-primary" v-if="selectedAudience.length > 5"><span class="txt-heading-small"><b>+{{selectedAudience.length - 5}}</b> {{ `${(selectedAudience.length === 1) ? ' person' : ' people'}` }} selected</span></span>
    <span class="mr-auto txt-primary" v-else><span class="txt-heading-small"><b>{{selectedAudience.length}}</b> {{ `${(selectedAudience.length === 1) ? ' person' : ' people'}` }} selected</span></span>
  </div>
</template>

<script>
  export default {
    name: 'SelectedAudience',
    computed: {
      selectedAudience() {
        return this.$store.getters['v2/audience/selectedAudience']
      }
    },
  }
</script>