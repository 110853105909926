import Base from '@app2/models/Base';
export default class Audience extends Base {
  static entity = 'App\\Audience';

  static apiConfig = {
    baseURL: '/api/internal/v2/audience/',
    actions: {
      fetch: {
        url: '/',
        dataKey: 'data',
      },
      resendEmail(id, config) {
        return this.post(`${id}/resend-emails`, {
          ...config
        });
      },
      attachHubs(id, config) {
        return this.post(`${id}/hubs/attach`, {
          ...config
        })
      },
      detachHubs(id, config) {
        return this.delete(`${id}/hubs/detach`, {
          ...config
        })
      },
      recentImports() {
        return this.get('/imports')
      },
      importAudience(config) {
        return this.post(`/imports`, config)
      },
      attachAchievements(config) {
        return this.post(`bulk/achievements/attach`, config)
      },
      detachAchievements(config) {
        return this.delete(`bulk/achievements/detach`, { params: { ...config } })
      },
    }
  };

  watchFields = ['name', 'email', 'phone', 'achievements'];

  static fields() {
    return {
      id: this.attr(null),
      hash: this.attr(null),
      team: this.attr(null),
      team_id: this.attr(null),
      name: this.attr(null),
      email: this.attr(null),
      phone: this.attr(null),
      hubs: this.attr(null),
      tags: this.attr([]),
      tracking_id: this.attr(null),
      photo: this.attr(null),
      is_onboard: this.attr(null),
      has_creds: this.attr(null),
      ...super.fields(),
    };
  }
  get title() {
    return this.name;
  }
}
