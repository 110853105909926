var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-view-grid",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "12.199",
        height: "12.199",
        viewBox: "0 0 12.199 12.199"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(-11.522 -11.158)" } }, [
        _c("rect", {
          attrs: {
            width: "5.199",
            height: "5.199",
            rx: "2",
            transform: "translate(11.522 11.158)",
            fill: "currentColor"
          }
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            width: "5.199",
            height: "5.199",
            rx: "2",
            transform: "translate(18.522 11.158)",
            fill: "currentColor"
          }
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            width: "5.199",
            height: "5.199",
            rx: "2",
            transform: "translate(18.522 18.158)",
            fill: "currentColor"
          }
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            width: "5.199",
            height: "5.199",
            rx: "2",
            transform: "translate(11.522 18.158)",
            fill: "currentColor"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }