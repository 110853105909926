<template>
  <div>
    <form role="form" @submit.prevent="stepNext">
      <!-- Phone -->
      <form-control-text
        class="mb-3 mt-4"
        id="phone"
        name="phone"
        placeholder="+0 (000) 000-0000"
        label="Phone Number"
        type="tel"
        icon="phone"
        @input="handleInput"
        v-model="phone" />

      <b-alert :show="invalidPhone" fade variant="warning mb-3">
        <svg viewBox="0 0 24 24" class="alert-icon icon-sm"><use xlink:href="#icon-warning-triangle-color"></use></svg>
        <div class="alert-content">
          <span class="txt-body">Please enter a valid international phone number.</span>
        </div>
      </b-alert>

    </form>
    <div class="onboarding-cta pt-4 my-4">
      <slot name="back"></slot>
      <slot name="action" ></slot>
    </div>
  </div>
</template>

<script>
  import FormControlText from "@app2/core/Components/Common/Forms/Text";

  export default {
    name: 'PhoneStep',
    components: {
      FormControlText
    },
    data() {
      return {
        phone: this.user.phone || '',
        invalidPhone: false
      }
    },
    props: {
      user: {
        type: Object,
      },
    },
    mounted() {
      this.$emit('loading', false);
      this.$emit('animation', false);
      this.$parent.$on('step-next', this.stepNext.bind(this));
    },
    beforeDestroy() {
      this.$parent.$off('step-next');
    },
    methods: {
      async stepNext() {
        try {
          this.$emit('loading', true);
          await this.submit();
          this.$emit('next',2);
        } catch (e) {
          //done(e);
          this.$emit('loading', true);
          this.invalidPhone = true;
          throw e;
        }
      },
      submit() {
        return this.$store.dispatch('auth/updateOnboarding', {
          phone: this.phone,
          onboard_step: 2,
        })
      },
      handleInput() {
        this.invalidPhone = false
        this.$emit('loading', false);
      }
    },
  };
</script>
