import { Model } from '@vuex-orm/core';
import { timestamps } from '@app2/utils/model-fields';
import Connection from "@app2/models/Connection";

export default class App extends Model {
  static entity = 'App\\App';

  static apiConfig = {
    baseURL: '/api/internal/v2/apps/',
    actions: {
      fetch: {
        url: '/',
        dataKey: 'data',
      },
    }
  };

  static fields() {
    return {
      id: this.attr(null),
      category: this.attr(null),
      type: this.attr(null),
      title: this.attr(null),
      description: this.attr(null),
      install_url: this.attr(null),
      installed: this.attr(null),
      button: this.attr(null),
      connections: this.hasMany(Connection, 'type', 'type'),
      ...timestamps,
    }
  }
}
