var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-stripe",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "28",
        height: "28",
        viewBox: "0 0 28 28"
      }
    },
    [
      _c("rect", {
        attrs: { width: "28", height: "28", rx: "6.48", fill: "#6772e5" }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M13.166,11.057c0-.669.553-.97,1.445-.97a9.543,9.543,0,0,1,4.238,1.1V7.216a11.26,11.26,0,0,0-4.229-.776c-3.453,0-5.761,1.8-5.761,4.849,0,4.714,6.479,3.947,6.479,5.984,0,.786-.689,1.038-1.629,1.038A10.728,10.728,0,0,1,9,16.924v4.064a11.828,11.828,0,0,0,4.655.97c3.54,0,5.984-1.746,5.984-4.849C19.664,12.055,13.166,12.967,13.166,11.057Z",
          transform: "translate(-0.267 -0.194)",
          fill: "#fff",
          "fill-rule": "evenodd"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }