import SparkFormErrors from '@app2/utils/SparkFormErrors';
export default class SparkForm {
  constructor(fields) {
    /**
     * Create the form error helper instance.
     */
    Object.entries(fields).forEach(field => {
      this[field[0]] = field[1];
    });
    this.errors = new SparkFormErrors();
    this.busy = false;
    this.successful = false;
  }
  /**
   * Start processing the form.
   */
  startProcessing() {
    this.errors.forget();
    this.busy = true;
    this.successful = false;
  };

  /**
   * Finish processing the form.
   */
  finishProcessing() {
    this.busy = false;
    this.successful = true;
  };

  /**
   * Reset the errors and other state for the form.
   */
  resetStatus() {
    this.errors.forget();
    this.busy = false;
    this.successful = false;
  };

  /**
   * Set the errors on the form.
   */
  setErrors(errors) {
    this.busy = false;
    this.errors.set(errors);
  };
}
