<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18.411" height="13.76" viewBox="0 0 18.411 13.76">
    <g id="icon-stats-details" data-name="icon-stats-details" transform="translate(0 0)">
      <path id="Path_2591" data-name="Path 2591" d="M1350.362,850.1a.75.75,0,0,0-.75-.75h-2.165a.75.75,0,0,0,0,1.5h.408l-4.127,4.127-3.938-3.937a.749.749,0,0,0-1.061,0l-3.271,3.271a2.347,2.347,0,1,0,.961,1.161l2.84-2.841,3.938,3.938a.752.752,0,0,0,1.061,0l4.6-4.6v.3a.75.75,0,0,0,1.5,0Zm-16.1,6.991a.811.811,0,1,1,.811-.811A.813.813,0,0,1,1334.262,857.1Z" transform="translate(-1331.951 -849.355)" fill="#939393"/>
      <path id="Path_2592" data-name="Path 2592" d="M1343.23,858.209a.75.75,0,0,0-.75.75v2.9a.75.75,0,0,0,1.5,0v-2.9A.75.75,0,0,0,1343.23,858.209Z" transform="translate(-1331.951 -848.845)" fill="#939393"/>
      <path id="Path_2593" data-name="Path 2593" d="M1347.158,857.1a.75.75,0,0,0-.75.75v4.008a.75.75,0,0,0,1.5,0v-4.008A.75.75,0,0,0,1347.158,857.1Z" transform="translate(-1331.951 -848.845)" fill="#939393"/>
      <path id="Path_2594" data-name="Path 2594" d="M1339.3,855.244a.749.749,0,0,0-.75.75v5.861a.75.75,0,0,0,1.5,0v-5.861A.75.75,0,0,0,1339.3,855.244Z" transform="translate(-1331.951 -848.845)" fill="#939393"/>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconStatsDetails'
  }
</script>
