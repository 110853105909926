var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19",
        height: "19",
        viewBox: "0 0 19 19"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M14.172,337.1H4.925A4.933,4.933,0,0,0,0,342.026v9.148A4.933,4.933,0,0,0,4.925,356.1h9.147A4.933,4.933,0,0,0,19,351.174v-9.148A4.786,4.786,0,0,0,14.172,337.1Zm0,17.593H4.925a3.389,3.389,0,0,1-3.417-3.418v-9.249a3.39,3.39,0,0,1,3.417-3.418h9.147a3.19,3.19,0,0,1,2.613,1.307H15.177a3.116,3.116,0,0,0,0,6.233h2.312v5.228A3.221,3.221,0,0,1,14.172,354.693Z",
          transform: "translate(0 -337.1)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }