<template>
  <div class="onboarding-language">
    <form role="form">
      <!-- Language -->
      <label class="form-control-label txt-body">Which language should we use when your video is transcribed?</label>
      <form-control-select
        class="mb-3"
        id="language"
        v-model="value"
        full
        option-icon="icon"
        option-custom-icon
        :input-icon="vocabularyIcon"
        track-by="code"
        select-label="name"
        placeholder="Choose Language"
        :options="options"
        required
      />

      <!-- Info -->
      <b-alert show fade variant="info mb-3">
        <svg viewBox="0 0 24 24" class="alert-icon icon-sm"><use xlink:href="#icon-status-question-color"></use></svg>
        <div class="alert-content">
          <p class="txt-body mb-3">Choose a default language for your newly uploaded files to be transcribed in. </p>
          <p class="txt-body"><b>Note:</b> you can change the language again anytime from your account settings.</p>
        </div>
      </b-alert>

      <!-- Busy -->
      <b-alert :show="form.busy" fade variant="info" class="mb-3">
        <svg viewBox="0 0 24 24" class="alert-icon icon-sm"><use xlink:href="#icon-status-question-color"></use></svg>
        <div class="alert-content">
          <p class="txt-body">Language loading...</p>
        </div>
      </b-alert>

      <!-- Success -->
      <b-alert :show="!form.busy && form.successful" fade variant="success" class="mb-3">
        <svg viewBox="0 0 24 24" class="alert-icon icon-sm"><use xlink:href="#icon-status-success-color"></use></svg>
        <div class="alert-content">
          <p class="txt-body">Transcription language updated!</p>
        </div>
      </b-alert>
    </form>
    <div class="onboarding-cta pt-2 mb-4">
      <slot name="back"></slot>
      <slot name="finish"></slot>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import axios from '@app2/api/axios';
  import get from 'lodash/get';
  import SparkForm from '@app2/utils/SparkForm';
  import FormControlSelect from "@app2/core/Components/Common/Forms/Select";

  export default {
    name: 'LanguageStep',
    components: {FormControlSelect},
    computed: {
      ...mapState('auth', [
        'user'
      ]),
      vocabularyIcon() {
        if ( !this.value ) return;
        return `language-${this.value.code}`.toLowerCase();
      },
      isIdentical() {
        return this.form.name === this.user.name && this.form.email === this.user.email;
      }
    },
    data() {
      return {
        value: null,
        options: [],
        form: new SparkForm({
          code: '',
        })
      };
    },
    props: {
      loading: {
        type: Boolean,
      }
    },
    mounted() {
      this.setData();
      this.$emit('loading', true);
      this.$emit('animation', false);
    },
    methods: {
      async update() {
        this.form.startProcessing();
        this.$emit('loading', true);
        try {
          this.form.code = this.value.code;
          const response = await axios.put('/api/transcriptions/languages', this.form);
          window.Spark.state.currentTeam = response.data;
        } catch (error) {
          this.form.setErrors(error.data.errors);
        } finally {
          this.form.finishProcessing();
          this.$emit('loading', false);
        }
      },
      async setData() {
        if (this.spark.state.currentTeam) {
          this.form.code = get(this.spark.state.currentTeam, 'meta.settings.transcription_language.code');
        }
        this.form.startProcessing();
        const { data } = await axios.get('/api/transcriptions/languages');
        this.options = data.map(l => {
          return {
            ...l,
            icon: `language-${l.code}`.toLowerCase()
          }
        });
        this.value = this.form.code ? this.options.find(o => o.code === this.form.code) : this.options[0];
        this.form.resetStatus();
        this.$emit('loading', false);

      },
      catchEnter(e) {
        let cmd = e.key.toLowerCase();
        if (cmd == 'enter' && !this.loading) {
          this.$emit('finish');
        }
    	},
    },
    created() {
    	window.addEventListener('keypress', this.catchEnter);
    },
    destroyed() {
    	window.removeEventListener('keypress', this.catchEnter);
    },
  };
</script>
