<template>
  <svg class="icon-landscape" xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14">
    <path d="M7,11.355A2.326,2.326,0,1,0,9.325,13.68,2.326,2.326,0,0,0,7,11.355m0,3.151a.826.826,0,1,1,.826-.826A.828.828,0,0,1,7,14.506" transform="translate(0 14) rotate(-90)" fill="currentColor"/>
    <path d="M11,0H3A3,3,0,0,0,0,3V16a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V3a3,3,0,0,0-3-3m1.5,16A1.5,1.5,0,0,1,11,17.5H3A1.5,1.5,0,0,1,1.5,16V3A1.5,1.5,0,0,1,3,1.5h8A1.5,1.5,0,0,1,12.5,3Z" transform="translate(0 14) rotate(-90)" fill="currentColor"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-lanscape'
  }
</script>
