var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "16.319",
        height: "16.318",
        viewBox: "0 0 16.319 16.318"
      }
    },
    [
      _c("g", { attrs: { id: "Puzzle_Icon", "data-name": "Puzzle Icon" } }, [
        _c("path", {
          attrs: {
            id: "Path_664",
            "data-name": "Path 664",
            d:
              "M343.564,391.374h1.005a.749.749,0,0,0,.749-.75v-2.74a2.328,2.328,0,0,0-2.328-2.329H340.8v-.2a2.365,2.365,0,0,0-2.372-2.349h0a2.362,2.362,0,0,0-2.35,2.349v.2h-2.189a2.329,2.329,0,0,0-2.329,2.329v1.993h-.167l-.067,0a2.363,2.363,0,0,0-2.361,2.3h0v.01h0v.058a2.366,2.366,0,0,0,2.3,2.363h.294v2.387a2.328,2.328,0,0,0,2.329,2.328h2.939a.749.749,0,0,0,.75-.749v-.939a.863.863,0,0,1,1.726,0v.938a.749.749,0,0,0,.75.749h2.939a2.327,2.327,0,0,0,2.328-2.328v-3.14a.749.749,0,0,0-.749-.75h-.983a.864.864,0,0,1-.863-.841v-.025A.863.863,0,0,1,343.564,391.374Zm.022,3.225h.232v2.39a.831.831,0,0,1-.829.829H340.8v-.2a2.363,2.363,0,0,0-2.35-2.35h0a2.363,2.363,0,0,0-2.372,2.35v.2h-2.189a.832.832,0,0,1-.83-.829v-3.137a.749.749,0,0,0-.749-.75h-1a.865.865,0,0,1-.844-.861v-.024a.863.863,0,0,1,.859-.842h.987a.748.748,0,0,0,.749-.749v-2.743a.831.831,0,0,1,.83-.829h2.939a.751.751,0,0,0,.75-.75v-.939a.863.863,0,0,1,1.726,0v.939a.751.751,0,0,0,.75.75h2.939a.831.831,0,0,1,.829.829v1.99h-.291a2.363,2.363,0,0,0-2.306,2.362l0,.067h0A2.363,2.363,0,0,0,343.586,394.6Z",
            transform: "translate(-328.96 -383.008)"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }