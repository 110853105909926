var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-tick",
      attrs: {
        width: "12.65",
        height: "11.749",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 12.65 11.749"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M.203 7.643l3.71 3.874a.75.75 0 001.149-.078l7.44-10.249a.75.75 0 10-1.213-.881L4.375 9.831 1.286 6.605A.75.75 0 00.203 7.643z",
          fill: "#fff"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }