var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-failed",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.623",
        height: "15.484",
        viewBox: "0 0 16.623 15.484"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M4.792 2.034L.545 9.389a4.066 4.066 0 003.521 6.1h8.493a4.066 4.066 0 003.521-6.1l-4.249-7.356a4.064 4.064 0 00-7.039 0m7.767 11.953H4.066A2.567 2.567 0 011.5 11.417a2.537 2.537 0 01.344-1.278l4.247-7.356a2.563 2.563 0 01.938-.937 2.538 2.538 0 011.28-.346 2.566 2.566 0 012.223 1.283l4.248 7.356a2.565 2.565 0 01-2.221 3.847z",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M7.379 9.865h1.866v1.727H7.379zM7.379 4.463h1.866V8.29H7.379z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }