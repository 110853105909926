const namespaced = true;

const state = {
  exitRoute: null,
  audience: null,
  saving: false
};

const mutations = {
  SET_EXIT_ROUTE(state, payload) {
    state.exitRoute = payload;
  },
  SET_AUDIENCE(state, payload) {
    state.audience = payload;
  },
  SET_SAVING(state, payload) {
    state.saving = payload;
  },
};

const actions = {
};

const getters = {
  exitRoute: (state) => state.exitRoute,
  saving: (state) => state.saving
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
