import eventBus from "@app2/core/eventBus";
import UpgradeButton from '@app2/core/Components/UpgradeButton.vue';

export default {
  template: `
    <div class="ml-auto">
      <b-btn :id="'card-row-dd-' + index" variant="icon primary ml-auto popover-toggle" @click.stop="">
        <svg viewBox="0 0 24 24" width="24"><use xlink:href="#icon-more"></use></svg>
      </b-btn>
      <b-popover ref="dd" placement="auto" :target="'card-row-dd-' + index" triggers="click blur" >
        <div class="dropdown-cont sm">
          <b-dropdown-item @click.stop="handleActivity">
            <svg class="btn-left icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-activity"></use></svg>
            Activity
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item variant="warning" @click.stop="handleRemove">
            <svg class="btn-left icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-trash"></use></svg>
            Delete Person
          </b-dropdown-item>
        </div>
      </b-popover>
    </div>
  `,
  data() {
    return {
      index: null,
    }
  },
  components: {
    UpgradeButton,
  },
  methods: {
    handleProfile() {
      this.params.clicked(this.params, 'Profile') // second param is for person-slide-in route
    },
    handleActivity() {
      this.params.clicked(this.params, 'Activity') // second param is for person-slide-in route
    },
    handleAccess() {
      this.params.clicked(this.params, 'HubAccess') // second param is for person-slide-in route
    },
    handleRemove() {
      this.params.clicked(this.params, 'toDelete')
    },
  },
  beforeMount() {
    this.index = this.params.data.id
  },
};
