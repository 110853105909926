var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-keywords",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18.266",
        height: "17.964",
        viewBox: "0 0 18.266 17.964"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Group_9146",
            "data-name": "Group 9146",
            transform: "translate(-1633.948 -339.626)"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "Path_3532",
              "data-name": "Path 3532",
              d: "M1639.309,340.292h0Z",
              fill: "#979797"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_3533",
              "data-name": "Path 3533",
              d:
                "M1651.978,356.293l-2.629-2.47a8.675,8.675,0,0,0-6.673-14.194l-.024,0h-.036a8.584,8.584,0,0,0-3.306.666,8.693,8.693,0,1,0,9,14.611l2.642,2.483a.75.75,0,0,0,1.027-1.093Zm-6.6-1.322a7.193,7.193,0,0,1-5.5-13.294h0a7.114,7.114,0,0,1,2.738-.551h.015a7.193,7.193,0,0,1,2.741,13.845Z",
              fill: "#979797"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_3534",
              "data-name": "Path 3534",
              d:
                "M1646.035,344.995h-7.08a.75.75,0,0,0,0,1.5h7.08a.75.75,0,0,0,0-1.5Z",
              fill: "#979797"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_3535",
              "data-name": "Path 3535",
              d:
                "M1644.739,350.477h-5.786a.75.75,0,0,0,0,1.5h5.786a.75.75,0,0,0,0-1.5Z",
              fill: "#979797"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_3536",
              "data-name": "Path 3536",
              d:
                "M1638.953,349.236h3.314a.75.75,0,0,0,0-1.5h-3.314a.75.75,0,0,0,0,1.5Z",
              fill: "#979797"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_3537",
              "data-name": "Path 3537",
              d:
                "M1645.839,347.736h-1.22a.75.75,0,0,0,0,1.5h1.22a.75.75,0,0,0,0-1.5Z",
              fill: "#979797"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }