<template>
  <svg class="icon-section-watching" xmlns="http://www.w3.org/2000/svg" width="16.006" height="12.962" viewBox="0 0 16.006 12.962">
    <path d="M14.316,3.567,8.774.433A3.339,3.339,0,0,0,3.791,3.338V9.623a3.338,3.338,0,0,0,4.984,2.9l5.543-3.154a3.339,3.339,0,0,0,0-5.8M14.265,7.38a1.832,1.832,0,0,1-.688.688L8.034,11.221a1.838,1.838,0,0,1-2.743-1.6V3.338a1.839,1.839,0,0,1,2.744-1.6l5.543,3.135a1.838,1.838,0,0,1,.687,2.507" fill="#939393"/><path d="M.75,0A.75.75,0,0,0,0,.75V12.212a.75.75,0,0,0,1.5,0V.75A.75.75,0,0,0,.75,0" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-section-watching'
  }
</script>
