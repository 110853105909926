<template>
  <svg id="Settings_Icon" data-name="Settings Icon" xmlns="http://www.w3.org/2000/svg" width="21.714" height="21.946" viewBox="0 0 21.714 21.946">
    <path id="Path_864" data-name="Path 864" d="M1470,192.51h-1.527v-2.481a2.828,2.828,0,0,0-2.828-2.828h-11.843a2.828,2.828,0,0,0-2.828,2.828V202.1a2.828,2.828,0,0,0,2.828,2.828h2.466v1.527a2.687,2.687,0,0,0,2.688,2.688H1470a2.687,2.687,0,0,0,2.688-2.688V195.2A2.688,2.688,0,0,0,1470,192.51Zm-17.527,9.594V190.029a1.332,1.332,0,0,1,1.329-1.329h11.843a1.332,1.332,0,0,1,1.329,1.329V202.1a1.332,1.332,0,0,1-1.329,1.329h-11.843A1.333,1.333,0,0,1,1452.477,202.1Zm18.716,4.355a1.192,1.192,0,0,1-1.189,1.189H1458.96a1.193,1.193,0,0,1-1.189-1.189v-1.527h7.878a2.828,2.828,0,0,0,2.828-2.828V194.01H1470a1.191,1.191,0,0,1,1.189,1.189Z" transform="translate(-1450.978 -187.201)" fill="#939393" fill-rule="evenodd"/>
    <path id="Path_865" data-name="Path 865" d="M1463.441,195.537h-7.426a.75.75,0,0,0,0,1.5h7.426a.75.75,0,0,0,0-1.5Z" transform="translate(-1450.978 -187.201)" fill="#939393" fill-rule="evenodd"/>
    <path id="Path_866" data-name="Path 866" d="M1461.941,198.307h-4.426a.75.75,0,0,0,0,1.5h4.426a.75.75,0,0,0,0-1.5Z" transform="translate(-1450.978 -187.201)" fill="#939393" fill-rule="evenodd"/>
    <path id="Path_867" data-name="Path 867" d="M1459.725,194.267h0a1.4,1.4,0,1,0,0-2.806h0v0a1.4,1.4,0,0,0,0,2.808Z" transform="translate(-1450.978 -187.201)" fill="#939393" fill-rule="evenodd"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-settings-info'
  }
</script>
