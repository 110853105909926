<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="155.3" height="120.08" viewBox="0 0 155.3 120.08">
    <g transform="translate(1.5 1.5)">
      <path d="M1107.738,233.86a33.128,33.128,0,0,0-47.434-42.575,40.309,40.309,0,0,0-80.2,5.8h0a40.513,40.513,0,0,0,.839,8.216,23.663,23.663,0,0,0,1.539,47.279h46.868" transform="translate(-958.75 -156.749)" fill="none" stroke="#293ad3" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <g transform="translate(93.469 69.469)">
        <line y1="46.057" transform="translate(15.791 1.553)" fill="none" stroke="#293ad3" stroke-linecap="round" stroke-width="3"/>
        <g>
          <path d="M971.447,181.969l15.784-15.784,15.785,15.784" transform="translate(-971.447 -166.185)" fill="none" stroke="#293ad3" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
        </g>
      </g>
    </g>
  </svg>

</template>
<script>
  export default {
    name: 'icon-upload-big',
  };
</script>
