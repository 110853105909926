var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-proofread-done",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "23.458",
        height: "15.386",
        viewBox: "0 0 23.458 15.386"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(-2253.009 -746.926)" } }, [
        _c("path", {
          attrs: {
            d:
              "M2258.771,753.718a3.391,3.391,0,0,0,.1-6.781c-.013,0-.027-.008-.04-.009a.235.235,0,0,0-.026,0c-.01,0-.019,0-.029,0v.005h0v-.005h0v.005a.894.894,0,0,0-.114.009.6.6,0,0,1,.113-.015,3.4,3.4,0,0,0,0,6.792Zm0-5.292h.008a1.9,1.9,0,1,1-.008,0Z",
            fill: "#939393"
          }
        }),
        _vm._v(" "),
        _c("g", [
          _c("path", {
            attrs: {
              d:
                "M2258.773,754.457a5.764,5.764,0,0,0-5.764,5.764v.812a1.288,1.288,0,0,0,1.282,1.279h8.966a1.287,1.287,0,0,0,1.279-1.281v-.809A5.764,5.764,0,0,0,2258.773,754.457Zm4.263,6.566v0Zm0-.21h-8.527v-.592a4.264,4.264,0,1,1,8.528,0Z",
              fill: "#939393"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M2265.707,748.427h6.64a.75.75,0,0,0,0-1.5h-6.64a.75.75,0,0,0,0,1.5Z",
              fill: "#939393"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M2273.1,751.938a.75.75,0,0,0-.75-.75h-6.972a.75.75,0,0,0,0,1.5h6.972A.75.75,0,0,0,2273.1,751.938Z",
              fill: "#939393"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M2276.248,753.355a.752.752,0,0,0-1.061,0l-5.454,5.454-1.954-1.954a.75.75,0,1,0-1.06,1.062h0l2.485,2.484a.749.749,0,0,0,1.06,0l5.985-5.984A.752.752,0,0,0,2276.248,753.355Z",
              fill: "#939393"
            }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }