var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "10.421",
        height: "10.421",
        viewBox: "0 0 10.421 10.421"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M585.265,413.2l3.615-3.615a.749.749,0,0,0-1.06-1.06l-3.616,3.616-3.615-3.616a.749.749,0,0,0-1.06,1.06l3.616,3.615-3.616,3.616a.749.749,0,0,0,1.06,1.06l3.615-3.615,3.616,3.615a.749.749,0,0,0,1.06-1.06Z",
          transform: "translate(-579.006 -407.956)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }