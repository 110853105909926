<template>
  <svg class="media-snapshot-plays" xmlns="http://www.w3.org/2000/svg" width="16.252" height="16.251" viewBox="0 0 16.252 16.251">
    <path d="M12.433 10.681h-.005zM8.126 0a8.126 8.126 0 108.126 8.126A8.125 8.125 0 008.126 0zm4.684 12.81a6.626 6.626 0 111.941-4.685 6.6 6.6 0 01-1.941 4.685z" fill="#939393"/>
    <path d="M11.01 6.397L7.836 4.564a1.994 1.994 0 00-2.994 1.729v3.665a1.979 1.979 0 00.611 1.438 1.993 1.993 0 002.379.293l3.174-1.832a2 2 0 000-3.458zm-.5 1.728a.483.483 0 01-.248.431l-3.171 1.83a.5.5 0 01-.249.07.517.517 0 01-.35-.143.478.478 0 01-.149-.355V6.293a.477.477 0 01.15-.356.513.513 0 01.349-.143l.246.069 3.174 1.833a.478.478 0 01.248.429z" fill="#939393"/>
  </svg>
</template>

<script>
  export default {
    name: 'MediaSnapshotPlays'
  }
</script>
