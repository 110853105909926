<template>
  <b-modal id="audience-delete-modal" ref="modal" size="md" body-class="modal-fig-left" centered hide-header hide-footer>
    <b-button @click="$refs.modal.hide()" variant="icon close">
      <icon-close-modal class="icon-btn-close"/>
    </b-button>
    <figure>
      <svg xmlns="http://www.w3.org/2000/svg" width="39.821" height="45.94" viewBox="0 0 39.821 45.94">
        <path d="M324.5,383.226h-7.707a9,9,0,0,0-9-8.929H305.7a9,9,0,0,0-8.992,8.929H289a2.161,2.161,0,0,0,0,4.323h1.265v23.691a9,9,0,0,0,8.986,9h14.985a9,9,0,0,0,9-9V387.549H324.5a2.161,2.161,0,0,0,0-4.323Zm-18.8-4.606h2.095a4.681,4.681,0,0,1,4.679,4.606H301.026A4.683,4.683,0,0,1,305.7,378.62Zm8.54,37.294H299.254a4.674,4.674,0,0,1-4.667-4.675v-23.6h24.326v23.6A4.685,4.685,0,0,1,314.236,415.914Z" transform="translate(-286.839 -374.297)" fill="#c24141"/>
        <path d="M292.151,381.138a.75.75,0,0,0-.75.75v3.885a.75.75,0,1,0,1.5,0v-3.885A.75.75,0,0,0,292.151,381.138Z" transform="translate(-277.212 -354.853)" fill="#c24141"/>
        <path d="M295.348,381.138a.75.75,0,0,0-.75.75v3.885a.75.75,0,1,0,1.5,0v-3.885A.75.75,0,0,0,295.348,381.138Z" transform="translate(-270.466 -354.853)" fill="#c24141"/>
      </svg>

      <figcaption>
        <h4>Delete this Audience Member?</h4>
        <p v-if="audience" class="my-3">Are you sure you want to delete <br/><b>"{{ audience.name }}"</b> ?</p>
      </figcaption>

    </figure>

    <div class="modal-cta">
      <div class="col">
        <b-btn :disabled="isDeleting"  size="sm" variant="gray" block @click="$refs.modal.hide()">
            Cancel
        </b-btn>
      </div>
      <div class="col">
        <b-btn :disabled="isDeleting"  size="sm" variant="warning-fill no-shadow" block @click="removeAudience">
            Delete
        </b-btn>
      </div>
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: 'ConfirmDeleteModal',
    data() {
      return {
        isDeleting: false
      }
    },
    computed: {
      audience() {
        return this.$store.getters['v2/audience/selectedRowData']
      }
    },
    methods: {
      removeAudience() {
        this.isDeleting = true
        axios.delete(`/api/audience/${this.audience.hash}`).then(() => {
          this.$toasted.show('User Removed from Audience.');
          this.$router.push({name: 'audience'})
          this.isDeleting = false
        }).catch(() => {
          this.$toasted.show('Error while saving changes', { type: 'danger' });
        })
      }
    }
  };
</script>
