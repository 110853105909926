var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18.768",
        height: "17.303",
        viewBox: "0 0 18.768 17.303"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M3251.888,653.23a1.6,1.6,0,0,0,1.083-.669,2,2,0,0,0,.34-1.148,2.754,2.754,0,0,0-.375-1.355l0,0-3.7-6.4v0a2.414,2.414,0,0,0-2-1.291,1.6,1.6,0,0,0-1.272.613l-4.5,5.7-2.031,1.173h0a3.235,3.235,0,0,0,2.543,5.9l1.2,2.084a1.983,1.983,0,0,0,2.682.747l0,0h0l.035-.02h0l0,0a1.994,1.994,0,0,0,.728-2.722l-.979-1.7,6.234-.912m-11.706-2.087,1.506-.87,1.738,3.011-1.505.87a1.739,1.739,0,1,1-1.739-3.011m4.3,5.939-1.157-2,.857-.495,1.156,2,.066.246a.5.5,0,0,1-.248.43l-.246.066A.493.493,0,0,1,3244.476,657.082Zm7.266-5.382-.075.047h0l-6.822,1-1.981-3.431,4.277-5.41.095-.042a.976.976,0,0,1,.7.546v0l3.7,6.4a1.256,1.256,0,0,1,.178.612A.506.506,0,0,1,3251.742,651.7Z",
          transform: "translate(-3237.81 -641.525)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M3252.127,646.433a.749.749,0,0,0,1.024.275l2.306-1.331a.75.75,0,0,0-.749-1.3l-2.307,1.331A.749.749,0,0,0,3252.127,646.433Z",
          transform: "translate(-3237.81 -641.525)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M3251.933,644.023l.989-1.292a.75.75,0,0,0-1.191-.912l-.989,1.292a.75.75,0,1,0,1.191.912Z",
          transform: "translate(-3237.81 -641.525)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M3255.731,647.6l-1.614.211a.75.75,0,1,0,.2,1.487l1.614-.211a.75.75,0,1,0-.2-1.487Z",
          transform: "translate(-3237.81 -641.525)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }