<template>
  <audience-slide-in-template
    :back-route="{name: 'ImportIndex'}"
    back-label="Back to Import Audience"
    title="Recent Imports"
  >
    <h6 class="txt-body txt-muted mb-0">Here you can see all your imports to Audience.</h6>
    <hr class="my-3" />
    <TransitionGroup tag="div" name="fade-list" class="mb-4">
      <side-panel-collapse v-for="(data, key) in imports" :key="`collapse-${key}`" :data="data"/>
      <div class="skeleton-item side-panel-collapse no-shadow no-events" v-if="isLoading" v-for="(elem,index) in 3" :key="index">
        <div class="slot-top pt-2">
          <svg width="130" height="10" viewBox="0 0 130 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.996094" width="130" height="10" rx="2" fill="#bbbbbb"/>
          </svg>
        </div>
        <div class="slot-bottom pb-2">
          <svg width="50" height="9" viewBox="0 0 50 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.996094" width="50" height="8" rx="2" fill="#bbbbbb"/>
          </svg>
        </div>
      </div>
    </TransitionGroup>
    <div class="side-panel-group-empty" v-if="!isLoading && !imports.length">
      <illustration-dog-standing-front class="mb-4"/>
      <p class="txt-body txt-muted txt-center mb-4">You have no recent imports. Upload a CSV file to <br /> start importing your contacts.</p>
      <b-btn variant="primary" :to="{name: 'ImportIndex'}">
        <svg viewBox="0 0 24 24" class="btn-left"><use xlink:href="#icon-upload"></use></svg>
        Upload CSV
      </b-btn>
    </div>
    <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId" spinner="waveDots" style="min-height: 50px">
      <div slot="spinner"></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </audience-slide-in-template>
</template>

<script>
  import moment from '@app2/utils/moment';
  import { numberFormat } from '@app2/utils/helpers'
  import get from 'lodash/get';
  import AudienceSlideInTemplate from "@app2/modules/Audience/Components/Template";
  import FigureImports from "@app2/modules/Audience/Import/Components/FigureImports";
  import SidePanelCollapse from "@app2/core/Components/Sidepanel/Collapse";
  import IllustrationDogStandingFront from "@illustrations/DogStandingFront";

  export default {
    name: 'RecentImports',
    components: {
      AudienceSlideInTemplate,
      FigureImports,
      SidePanelCollapse,
      IllustrationDogStandingFront
    },
    computed: {
      imports() {
        return this.$store.getters['v2/audience/recentImports']
      }
    },
    data() {
      return {
        infiniteId: new Date(),
        total: 0,
        page: 1,
        isLoading: false,
      }
    },
    methods: {
      moment,
      numberFormat,
      async infiniteHandler($state) {
        this.isLoading = true
        try {
          if (this.page === 1) {
            this.$store.commit('v2/audience/RESET_RECENT_IMPORTS')
          }

          const { data } = await axios.get(`/api/audience/imports?page=${this.page}&limit=10`)

          if (data.data.length) {
            this.$store.commit('v2/audience/SET_RECENT_IMPORTS', data.data)
            this.page += 1;
            $state.loaded();
            this.total = get(data,'meta.total', 0);
          }

          if (!data.links?.next || !data.data.length) {
            $state.complete();
          }
        } catch (e) {
          console.log(e)
          $state.error(e);
          throw e;
        } finally {
          this.isLoading = false
        }
      },
    }
  }
</script>
