import Base from '@app2/models/Base';

export default class Webhook extends Base {
  static entity = 'App\\Webhook';

  static apiConfig = {
    baseURL: '/api/internal/v2/webhooks',
    actions: {
      fetch: {
        url: '/',
        dataKey: 'data'
      },
    }
  };

  static primaryKey = 'id';

  watchFields = ['url', 'secret', 'events', 'headers', 'enabled'];

  requiredFields = ['url', 'events', 'headers', 'enabled']

  static fields() {
    return {
      id: this.attr(null),
      url: this.attr(null),
      secret: this.attr(null),
      events: this.attr([]),
      headers: this.attr([]),
      enabled: this.attr(true),
      saving: this.attr(false),
      ...super.fields(),
    };
  }
  static mutators() {
    return {
      events(value) {
        if ( value && value.length && typeof value[0] === 'object' ) {
          return value.map(ev => ev.id)
        }
        return value;
      },
    }
  }
}
