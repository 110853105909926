<template>
  <svg class="icon-tag" xmlns="http://www.w3.org/2000/svg" width="16.118" height="16.12" viewBox="0 0 16.118 16.12">
    <g fill="#939393">
      <path d="M15.46 6.985l-.061-1.968a.75.75 0 00-1.5.047l.061 1.964v.036a1.516 1.516 0 01-.447 1.072L7.47 14.18a1.527 1.527 0 01-2.153 0l-3.374-3.378a1.522 1.522 0 010-2.149l6.044-6.044a1.515 1.515 0 011.073-.447h.033l2.113.06a.75.75 0 00.043-1.5L9.136.662H9.06a3.013 3.013 0 00-2.135.887L.883 7.592a3.021 3.021 0 000 4.27l3.378 3.378a3.03 3.03 0 004.266 0l6.044-6.044a3.019 3.019 0 00.885-2.133v-.078z"/>
      <path d="M15.899.224a.75.75 0 00-1.061 0l-4.141 4.141a.75.75 0 001.06 1.061l4.142-4.141a.752.752 0 000-1.061z"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'IconTags'
  }
</script>
