var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "modal",
      staticClass: "segment-slide-in",
      attrs: {
        id: "segment-editor-dialog",
        "modal-class": "modal-right",
        static: "",
        "hide-footer": "",
        "hide-header": ""
      },
      on: { show: _vm.onShow, hide: _vm.onHide },
      model: {
        value: _vm.isModalOpen,
        callback: function($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen"
      }
    },
    [_c("div", { attrs: { id: "segment-app" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }