var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-month",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.764",
        height: "17.654",
        viewBox: "0 0 17.764 17.654"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M4.759 17.154a4.261 4.261 0 01-4.26-4.26V6.685a4.261 4.261 0 014.26-4.26h.376V1.251a.75.75 0 111.5 0v1.174h4.49V1.251a.75.75 0 111.5 0v1.174h.377a4.257 4.257 0 014.259 4.26v6.209a4.26 4.26 0 01-4.259 4.26zM2 6.685v6.209a2.759 2.759 0 002.759 2.758h8.245a2.759 2.759 0 002.759-2.758V6.685a2.761 2.761 0 00-2.759-2.76h-.377v1.18a.751.751 0 01-1.5 0v-1.18h-4.49v1.18a.751.751 0 01-1.5 0v-1.18h-.376A2.761 2.761 0 002 6.686zm9.643 5.584v-1.021a.75.75 0 011.5 0v1.021a.75.75 0 11-1.5 0zm-3.512 0v-1.021a.75.75 0 011.5 0v1.021a.75.75 0 11-1.5 0zm-3.512 0v-1.021a.75.75 0 011.5 0v1.021a.75.75 0 11-1.5 0zm7.023-3.445V7.808a.75.75 0 111.5 0v1.016a.75.75 0 11-1.5 0zm-3.512 0V7.808a.75.75 0 111.5 0v1.016a.75.75 0 11-1.5 0zm-3.512 0V7.808a.75.75 0 111.5 0v1.016a.75.75 0 11-1.5 0z",
          fill: "currentColor",
          stroke: "rgba(0,0,0,0)",
          "stroke-miterlimit": "10"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }