<template>
  <svg class="icon-playlist-edit" xmlns="http://www.w3.org/2000/svg" width="16.799" height="15.091" viewBox="0 0 16.799 15.091">
    <g fill="#939393">
      <path d="M16.079 2.507a2.48 2.48 0 00-3.508 0L5.428 9.648a.734.734 0 00-.163.245l-1.721 4.164a.75.75 0 00.98.979l4.163-1.723a.74.74 0 00.243-.162l7.143-7.143a2.48 2.48 0 00.005-3.5zm-1.066 2.438l-7.037 7.037-2.354.974.972-2.353 7.039-7.037a.976.976 0 111.379 1.381zM.75 1.496h9.653a.75.75 0 000-1.5H.75a.75.75 0 000 1.5zM.75 4.91h6.277a.75.75 0 000-1.5H.75a.75.75 0 000 1.5zM4.926 7.57a.75.75 0 00-.75-.75H.75a.75.75 0 000 1.5h3.426a.75.75 0 00.75-.75z"/>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconPlaylistEdit'
  }
</script>
