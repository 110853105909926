var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-hubs-warning",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 19.817 17.896",
        width: "19.817",
        height: "17.896"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M7.915 11.456zM8.583 10.789a1.624 1.624 0 00-.656-2.185l-.009-.005-1.928-1.07a1.67 1.67 0 00-.664-.2v-.005l-.079-.007H5.174a1.661 1.661 0 00-1.66 1.6v.026l-.007 2.151v.005a1.6 1.6 0 00.222.814l.006.011a1.679 1.679 0 002.23.6l1.938-1.063a1.664 1.664 0 00.628-.6l.007-.012.009-.014zm-1.39-.648l-1.941 1.066-.086.022-.148-.082-.014-.052.008-2.12.161-.155h.008l.074.021 1.937 1.075.009.005.064.109-.015.053-.06.058zM13.954 9.271h-3.391a.75.75 0 100 1.5h3.391a.75.75 0 000-1.5z",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M18.972 13.216V2.784A2.782 2.782 0 0016.19 0H2.784A2.784 2.784 0 000 2.784v10.432a2.784 2.784 0 002.783 2.783h7.744a.75.75 0 100-1.5H2.785a1.286 1.286 0 01-1.283-1.283v-7.84h13.182a.75.75 0 000-1.5H1.5V2.784A1.286 1.286 0 012.784 1.5H16.19a1.284 1.284 0 011.282 1.284v10.434a1.27 1.27 0 01-.114.528.75.75 0 001.367.619 2.778 2.778 0 00.247-1.145z",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M9.851 14.956zM19.513 14.956l-3.226-5.015a1.908 1.908 0 00-3.21 0l-3.223 5.012a1.886 1.886 0 00-.308 1.033 1.919 1.919 0 00.533 1.324 1.892 1.892 0 001.378.587h6.451a1.893 1.893 0 001.377-.587 1.925 1.925 0 00.532-1.325 1.894 1.894 0 00-.304-1.029zm-1.314 1.318a.385.385 0 01-.291.123h-6.451a.39.39 0 01-.293-.123.428.428 0 01-.118-.289l.065-.215 3.225-5.016a.409.409 0 01.688 0l3.225 5.015.067.217a.427.427 0 01-.117.288z",
          fill: "#e34c4c"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M14.682 12.731a.75.75 0 00-.75.75v1.247a.75.75 0 001.5 0v-1.247a.75.75 0 00-.75-.75z",
          fill: "#e34c4c"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }