var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon",
      attrs: { xmlns: "http://www.w3.org/2000/svg", width: "21", height: "21" }
    },
    [
      _c("path", {
        staticClass: "cl-2",
        attrs: {
          d:
            "M20.23 18.468l-2.691-2.528a9.81 9.81 0 1 0-1.393 1.435l2.714 2.551a1 1 0 0 0 1.37-1.458zM4.3 15.376a7.834 7.834 0 1 1 5.541 2.3 7.806 7.806 0 0 1-5.541-2.3z",
          fill: "#939393"
        }
      }),
      _c("path", {
        staticClass: "cl-3",
        attrs: {
          d:
            "M12.035 8.775a1.21 1.21 0 0 1 0 2.1l-2.59 1.468a1.21 1.21 0 0 1-1.807-1.053V8.359a1.209 1.209 0 0 1 1.807-1.052z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }