import Page from "@app2/models/Page";
import eventBus from "@app2/core/eventBus";
const namespaced = true;

const state = {
  revisions: [],
}

const mutations = {
  SET_REVISIONS(state, payload) {
    state.revisions = payload;
  },
  RESET_REVISIONS(state) {
    state.revisions = [];
  },
}

const actions = {
  async fetchRevisions({ commit }, payload) {
    commit('RESET_REVISIONS');
    let { response: { data } } = await Page.api().getHistory(payload.hub, payload.page);
    commit('SET_REVISIONS', data.reverse());
  },
  async setRevision({ commit }, payload) {
    await Page.api().setHistory(payload.hub, payload.page, {
      "revision_id": payload.revision
    });
    eventBus.$emit('page-version-changed');
  }
}

const getters = {
  getEntries: state => () => state.revisions,
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
