var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-plus",
      attrs: {
        height: "21.082",
        viewBox: "0 0 21.082 21.082",
        width: "21.082",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(2 10.541) rotate(-45)" } }, [
        _c("line", {
          attrs: {
            fill: "none",
            stroke: "#fff",
            "stroke-linecap": "round",
            "stroke-width": "2",
            transform: "translate(0 0)",
            x2: "12.079",
            y1: "12.079"
          }
        }),
        _vm._v(" "),
        _c("line", {
          attrs: {
            fill: "none",
            stroke: "#fff",
            "stroke-linecap": "round",
            "stroke-width": "2",
            transform: "translate(0 0)",
            x2: "12.079",
            y2: "12.079"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }