var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        id: "audience-delete-modal",
        size: "md",
        "body-class": "modal-fig-left",
        centered: "",
        "hide-header": "",
        "hide-footer": ""
      }
    },
    [
      _c(
        "b-button",
        {
          attrs: { variant: "icon close" },
          on: {
            click: function($event) {
              return _vm.$refs.modal.hide()
            }
          }
        },
        [_c("icon-close-modal", { staticClass: "icon-btn-close" })],
        1
      ),
      _vm._v(" "),
      _c("figure", [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "39.821",
              height: "45.94",
              viewBox: "0 0 39.821 45.94"
            }
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M324.5,383.226h-7.707a9,9,0,0,0-9-8.929H305.7a9,9,0,0,0-8.992,8.929H289a2.161,2.161,0,0,0,0,4.323h1.265v23.691a9,9,0,0,0,8.986,9h14.985a9,9,0,0,0,9-9V387.549H324.5a2.161,2.161,0,0,0,0-4.323Zm-18.8-4.606h2.095a4.681,4.681,0,0,1,4.679,4.606H301.026A4.683,4.683,0,0,1,305.7,378.62Zm8.54,37.294H299.254a4.674,4.674,0,0,1-4.667-4.675v-23.6h24.326v23.6A4.685,4.685,0,0,1,314.236,415.914Z",
                transform: "translate(-286.839 -374.297)",
                fill: "#c24141"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M292.151,381.138a.75.75,0,0,0-.75.75v3.885a.75.75,0,1,0,1.5,0v-3.885A.75.75,0,0,0,292.151,381.138Z",
                transform: "translate(-277.212 -354.853)",
                fill: "#c24141"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M295.348,381.138a.75.75,0,0,0-.75.75v3.885a.75.75,0,1,0,1.5,0v-3.885A.75.75,0,0,0,295.348,381.138Z",
                transform: "translate(-270.466 -354.853)",
                fill: "#c24141"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c("figcaption", [
          _c("h4", [_vm._v("Delete this Audience Member?")]),
          _vm._v(" "),
          _vm.audience
            ? _c("p", { staticClass: "my-3" }, [
                _vm._v("Are you sure you want to delete "),
                _c("br"),
                _c("b", [_vm._v('"' + _vm._s(_vm.audience.name) + '"')]),
                _vm._v(" ?")
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-cta" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _c(
              "b-btn",
              {
                attrs: {
                  disabled: _vm.isDeleting,
                  size: "sm",
                  variant: "gray",
                  block: ""
                },
                on: {
                  click: function($event) {
                    return _vm.$refs.modal.hide()
                  }
                }
              },
              [_vm._v("\n          Cancel\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col" },
          [
            _c(
              "b-btn",
              {
                attrs: {
                  disabled: _vm.isDeleting,
                  size: "sm",
                  variant: "warning-fill no-shadow",
                  block: ""
                },
                on: { click: _vm.removeAudience }
              },
              [_vm._v("\n          Delete\n      ")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }