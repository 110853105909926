<template>
  <svg class="icon-import" xmlns="http://www.w3.org/2000/svg" width="17.965" height="15.946" viewBox="0 0 17.965 15.946">
    <path d="M17.215 4.264a.75.75 0 00-.75.75v7.9a1.535 1.535 0 01-1.533 1.532h-11.9A1.535 1.535 0 011.5 12.914v-7.9a.75.75 0 00-1.5 0v7.9a3.033 3.033 0 003.032 3.032h11.9a3.033 3.033 0 003.033-3.032v-7.9a.75.75 0 00-.75-.75z" fill="#939393"/>
    <path d="M5.033 7.626l3.418 3.419a.752.752 0 001.061 0l3.419-3.419a.75.75 0 00-1.06-1.061L9.732 8.703V.75a.75.75 0 00-1.5 0v7.953L6.093 6.565a.75.75 0 10-1.06 1.061z" fill="#939393"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconImport'
  }
</script>
