var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "80",
        height: "80",
        viewBox: "0 0 80 80",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M26.667 40.3333V26.1667C26.667 24.8333 28.0003 24 29.167 24.6667L41.0003 31.6667C42.0003 32.3333 42.0003 33.8333 41.0003 34.5L29.167 41.5C28.0003 42.5 26.667 41.6667 26.667 40.3333Z",
          stroke: "white",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M61.667 48.3333V58.3333",
          stroke: "#D75050",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M61.667 65V63.3333",
          stroke: "#D75050",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M61.6663 73.3333C52.4616 73.3333 44.9997 65.8714 44.9997 56.6667C44.9997 47.4619 52.4616 40 61.6663 40C70.8711 40 78.333 47.4619 78.333 56.6667C78.333 65.8714 70.8711 73.3333 61.6663 73.3333Z",
          stroke: "#D75050",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M61.6663 31.6667V15C61.6663 11.3333 58.6663 8.33333 54.9997 8.33333H9.99967C6.33301 8.33333 3.33301 11.3333 3.33301 15V50C3.33301 53.6667 6.33301 56.6667 9.99967 56.6667H38.333",
          stroke: "white",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }