var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.252",
        height: "16.251",
        viewBox: "0 0 16.252 16.251"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M751.933,731.961l0,0-2.6-1.4h0a1.973,1.973,0,0,0-.828-.221h0l-.071-.007c-.013,0-.025-.005-.038-.005h-.05a1.936,1.936,0,0,0-1.934,1.877v2.812a1.852,1.852,0,0,0,.274.965l.006.01a2.008,2.008,0,0,0,2.646.682l0,0,2.6-1.392-.005,0a1.931,1.931,0,0,0,.755-.713l.011-.018.02-.036.008-.016.017-.032.011-.02a1.862,1.862,0,0,0-.817-2.484Zm-.708,1.994-2.6,1.391,0,0-.242.062a.506.506,0,0,1-.421-.228l-.049-.174v-2.766a.436.436,0,0,1,.434-.422v-.005l.05,0v0l.228.056,2.6,1.4.021.011a.363.363,0,0,1,.2.326l-.04.165-.009.015-.166.156Z",
          transform: "translate(-741.372 -725.49)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M749.5,725.49a8.126,8.126,0,1,0,8.126,8.126A8.125,8.125,0,0,0,749.5,725.49Zm4.685,12.811a6.626,6.626,0,1,1,1.941-4.685A6.6,6.6,0,0,1,754.183,738.3Z",
          transform: "translate(-741.372 -725.49)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }