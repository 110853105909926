<template>
  <svg class="media-add" xmlns="http://www.w3.org/2000/svg" width="147" height="37" viewBox="0 0 147 37">
    <rect width="147" height="37" rx="18.5" fill="#fff"/>
    <g class="media-add_cont">
      <g transform="translate(27.222)" class="media-add_txt">
        <text transform="translate(0 15)" fill="#293AD3" font-size="15" font-family="TitilliumWeb-Bold, Titillium Web" font-weight="700" letter-spacing="0.02em"><tspan x="0" y="0">Add Media</tspan></text>
        <text v-if="duration" transform="translate(0 45)" font-size="16" font-family="TitilliumWeb, Titillium Web" font-weight="600" letter-spacing="">
          <tspan v-if="duration == '00:00:00'" x="0" y="0">Add Media</tspan>
          <tspan v-else x="0" y="0">{{duration}}</tspan>
        </text>
        <text v-else transform="translate(0 15)" font-size="15" font-family="TitilliumWeb-Bold, Titillium Web" font-weight="700" letter-spacing="0.02em">
          <tspan x="0" y="0">Add Media</tspan>
        </text>
      </g>
      <g transform="translate(0 10.821) rotate(-45)" class="media-add_plus">
        <line id="Line_1" data-name="Line 1" y1="8.829" x2="8.829" fill="none" stroke="#293ad3" stroke-linecap="round" stroke-width="2"/>
        <line id="Line_2" data-name="Line 2" x2="8.829" y2="8.829" fill="none" stroke="#293ad3" stroke-linecap="round" stroke-width="2"/>
        <line id="Line_3" transform="translate(-20 20)" data-name="Line 1" y1="8.829" x2="8.829" fill="none" stroke="#293ad3" stroke-linecap="round" stroke-width="2"/>
        <line id="Line_4" transform="translate(-20 20)" data-name="Line 2" x2="8.829" y2="8.829" fill="none" stroke="#293ad3" stroke-linecap="round" stroke-width="2"/>
      </g>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-media-add',
    props: {
      duration: {
        type: String
      },
    }
  };
</script>
