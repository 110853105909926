var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-section-compact",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "23.972",
        height: "23.972",
        viewBox: "0 0 23.972 23.972"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M13.854 7.914h-3.721a2.211 2.211 0 00-2.211 2.211v3.721a2.21 2.21 0 002.211 2.211h3.721a2.21 2.21 0 002.21-2.211v-3.721a2.211 2.211 0 00-2.21-2.211zm.71 5.932a.711.711 0 01-.71.711h-3.721a.712.712 0 01-.711-.711v-3.721a.712.712 0 01.711-.711h3.721a.712.712 0 01.71.711zM23.222 11.236h-3.909l1.106-1.107a.75.75 0 10-1.06-1.06l-2.387 2.387a.75.75 0 000 1.061l2.387 2.387a.75.75 0 001.061-1.061l-1.107-1.106h3.909a.75.75 0 000-1.5zM4.627 9.068a.75.75 0 10-1.06 1.062l1.107 1.107H.75a.75.75 0 000 1.5h3.923l-1.106 1.106a.75.75 0 101.061 1.061l2.387-2.387a.752.752 0 000-1.061zM14.903 19.358l-2.387-2.386a.75.75 0 00-1.061 0l-2.387 2.386a.751.751 0 001.061 1.062l1.107-1.107v3.909a.75.75 0 001.5 0v-3.909l1.106 1.106a.75.75 0 001.061-1.06zM11.455 7.015a.752.752 0 001.061 0l2.387-2.387a.75.75 0 00-1.061-1.061l-1.106 1.106V.75a.75.75 0 00-1.5 0v3.923l-1.107-1.106a.75.75 0 00-1.061 1.061z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }