var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19",
        height: "19",
        viewBox: "0 0 19 19"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M14.374,25.8H5.226A4.934,4.934,0,0,0,.3,30.726v9.148A4.934,4.934,0,0,0,5.226,44.8h9.148A4.934,4.934,0,0,0,19.3,39.874V30.726A4.934,4.934,0,0,0,14.374,25.8Zm3.418,14.074a3.406,3.406,0,0,1-1.206,2.614V41.08a3.116,3.116,0,0,0-6.233,0v2.312H5.226a3.39,3.39,0,0,1-3.418-3.418V30.826a3.39,3.39,0,0,1,3.418-3.418h9.148a3.39,3.39,0,0,1,3.418,3.418Z",
          transform: "translate(-0.3 -25.8)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }