<template>
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="16" cy="16" r="16" fill="url(#paint0_linear_1845_6368)"/>
		<path d="M23.9484 14.389C23.6641 13.8997 23.2567 13.4933 22.7667 13.2102L17.7514 10.3279L14.5031 8.43128C14.013 8.14869 13.4573 7.99995 12.8916 8C12.3259 8.00005 11.7702 8.14887 11.2802 8.43154C10.7902 8.71421 10.3831 9.12078 10.0999 9.61046C9.81667 10.1001 9.66721 10.6557 9.66651 11.2214V20.7792C9.66558 21.1391 9.72501 21.4967 9.84234 21.837C9.9984 22.2853 10.2516 22.6936 10.5838 23.0327C10.916 23.3718 11.319 23.6333 11.764 23.7985C12.209 23.9637 12.6851 24.0286 13.1581 23.9885C13.6311 23.9483 14.0894 23.8042 14.5002 23.5664L22.7782 18.7875C23.5186 18.3605 24.059 17.657 24.2806 16.8316C24.5022 16.0062 24.3869 15.1265 23.96 14.3862L23.9484 14.389ZM10.5168 11.2185C10.5168 10.804 10.6259 10.3968 10.8333 10.0378C11.0406 9.67885 11.3388 9.38082 11.6978 9.17368C12.0569 8.96655 12.4641 8.85761 12.8787 8.85783C13.2932 8.85804 13.7003 8.96741 14.0592 9.17492L16.8925 10.8121L12.9697 13.1179C12.6308 13.3196 12.385 13.6468 12.2857 14.0284C12.1864 14.4101 12.2415 14.8155 12.4391 15.1568C12.6367 15.4981 12.9608 15.7478 13.3412 15.8517C13.7216 15.9556 14.1277 15.9055 14.4713 15.712L16.244 14.6715C16.5885 14.4728 16.9977 14.4189 17.3819 14.5216C17.7661 14.6243 18.0938 14.8751 18.2933 15.2192C18.3921 15.3896 18.4562 15.5779 18.4822 15.7732C18.5081 15.9685 18.4952 16.167 18.4444 16.3574C18.3935 16.5477 18.3056 16.7261 18.1857 16.8825C18.0658 17.0388 17.9163 17.17 17.7457 17.2685L10.6033 21.3931C10.5487 21.1929 10.5197 20.9866 10.5168 20.7792V11.2185Z" fill="white"/><defs>
		<linearGradient id="paint0_linear_1845_6368" x1="23.6764" y1="22.4689" x2="2.15927" y2="22.3208" gradientUnits="userSpaceOnUse">
		<stop stop-color="#88A2FF"/>
		<stop offset="1" stop-color="#E68FEE"/>
		</linearGradient>
		</defs>
	</svg>

</template>
<script>
  export default {
    name: 'IconLogoSearchieCopilot',
  };
</script>
