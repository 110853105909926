<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <circle cx="14" cy="14" r="14" fill="#ef4e4e" />
    <circle cx="5" cy="5" r="5" transform="translate(9 9)" fill="#fff" />
  </svg>
</template>
<script>
export default {
  name: 'IconRecord',
};
</script>
