<template>
  <svg class="bars" xmlns="http://www.w3.org/2000/svg" width="41" height="22" viewBox="0 0 41 22">
    <rect id="Rectangle_1550" data-name="Rectangle 1550" width="3" height="22" rx="1.5" transform="translate(29)" fill="#cc7e00" opacity="0.1"/>
    <rect id="Rectangle_1552" data-name="Rectangle 1552" width="3" height="14" rx="1.5" transform="translate(10 8)" fill="#cc7e00" opacity="0.1"/>
    <rect id="Rectangle_1551" data-name="Rectangle 1551" width="3" height="13" rx="1.5" transform="translate(38 9)" fill="#cc7e00"/>
    <rect id="Rectangle_1553" data-name="Rectangle 1553" width="3" height="13" rx="1.5" transform="translate(19 9)" fill="#cc7e00" opacity="0.1"/>
    <rect id="Rectangle_1555" data-name="Rectangle 1555" width="3" height="6" rx="1.5" transform="translate(0 16)" fill="#cc7e00" opacity="0.1"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconStatsAveragePlay'
  }
</script>
