<template>
  <div class="settings audience__full-details" v-if="audience">
    <b-breadcrumb>
      <b-breadcrumb-item @click="$router.push({name: 'audience'})" class="px-3">
        <icon-back class="back" />
      </b-breadcrumb-item>
    </b-breadcrumb>
    <div class="container">
      <div class="settings-left">
        <profile-image :user="audience" />
        <div class="settings-meta audience-meta">
          <h3>{{ audience ? audience.name : '' }}</h3>
          <p>{{ audience ? audience.email : '' }}</p>
          <span v-if="audience" v-html="audienceTypeRenderer(audience.audience_type)"></span>
        </div>
        <div class="settings-nav audience__settings">
          <b-nav>
            <b-nav-item
              v-for="route in routes"
              :active="route.name === $route.name"
              :key="route.fullPath"
              :to="`/audience/${audience.hash}/${route.path}`"
            >
              <component :is="`icon-${route.path}`" />
              {{ route.name }}
              <transition name="right">
                <icon-arrow-right
                  class="icon-primary ml-auto"
                  width="17"
                  v-if="route.name === $route.name"
                />
              </transition>
            </b-nav-item>
          </b-nav>
          <div class="col profile-btn">
            <b-btn size="sm" class="editor-save" variant="warning-fill no-shadow" @click="confirmDelete" block>
              Remove Person
            </b-btn>
          </div>
        </div>
        <confirm-delete-modal />
      </div>
      <div class="settings-right audience__settings--right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
  import ProfileImage from '@app2/modules/Audience/Listing/Components/ProfileImage';
  import ConfirmDeleteModal from '@app2/modules/Audience/Components/Modals/ConfirmDeleteModal'
  import Audience from "@app2/models/Audience";

  export default {
    name: 'AudienceFullDetails',
    components: {
      ProfileImage,
      ConfirmDeleteModal
    },
    data() {
      return {
        profileComponentKey: 1
      };
    },
    methods: {
      audienceTypeRenderer(type) {
        switch(type) {
          case 'Visitor':
            return `<div class="type-visitor">${type}</div>`
            break;
          case 'Person':
            return `<div class="type-person">${type}</div>`
            break;
        }
      },
      confirmDelete() {
        this.$bvModal.show('audience-delete-modal');
      }
    },
    computed: {
      routes() {
        return this.$router.options.routes.find(element => element.name === 'audienceDetail').children.filter(r => {
          if (this.$cantView('hubs') && r.name === 'Access') {
            return false;
          }
          return true;
        });
      },
      audience() {
        return Audience.find(this.$route.params.hash);
      },
      isFromHome() {
        return this.$store.getters['v2/audience/isFromAudienceHome']
      }
    },
    async mounted() {
      await Audience.api().get(this.$route.params.hash, {
        params: {
          include: ['tags']
        }
      });
      if(!this.isFromHome) {
        if(this.$route.query.page) {
          this.$store.commit('v2/audience/SET_ACTIVITY_PAGE', this.$route.query.page)
        }
        await this.$store.dispatch('v2/audience/getAudienceAnalytics', this.$route.params.hash)
        await this.$store.dispatch('v2/audience/getAudienceActivity', this.$route.params.hash)
        await this.$store.dispatch('v2/audience/getAudienceHubAccess', this.$route.params.hash)
        await this.$store.commit('v2/audience/SET_SELECTED_ROW_DATA', Audience.find(this.$route.params.hash))
      }

    },
    created() {
      this.profileComponentKey += 1
    }
  };
</script>
