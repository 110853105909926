var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-menu-folders",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "28",
        height: "23",
        viewBox: "0 0 28 23"
      }
    },
    [
      _c("rect", {
        attrs: { width: "28", height: "23", fill: "#fff", opacity: "0" }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M56.413,96.144H47.421v-1.06a2.65,2.65,0,0,0-2.642-2.613h-.008c-.008,0-.023,0-.057,0H38.421a2.649,2.649,0,0,0-2.649,2.607v1.795c0,.007,0,.014,0,.022s0,.014,0,.022v3.322l0,.01v9.873a3.345,3.345,0,0,0,3.344,3.344H56.413a3.344,3.344,0,0,0,3.343-3.344V99.488h0A3.343,3.343,0,0,0,56.413,96.144ZM44.779,93.967v0Zm13.477,5.521v10.633a1.846,1.846,0,0,1-1.843,1.844H39.118a1.847,1.847,0,0,1-1.844-1.844V96.894l0-.01V95.1a1.149,1.149,0,0,1,1.147-1.131h6.352a1.151,1.151,0,0,1,1.15,1.136v1.039H40.286a.75.75,0,0,0,0,1.5H56.413a1.846,1.846,0,0,1,1.843,1.844Z",
          transform: "translate(-33.768 -91.47)",
          fill: "#293ad3"
        }
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cl-3",
        attrs: {
          d:
            "M49.96,103.152h0l-2.509-1.383a1.908,1.908,0,0,0-.795-.226v0l-.161-.017h-.026v0a1.915,1.915,0,0,0-1.9,1.871V106.2a1.9,1.9,0,0,0,.259.939l.008.012a1.953,1.953,0,0,0,2.6.691l0,0,2.513-1.389h0a1.9,1.9,0,0,0,.736-.707l.02-.035.007-.012.01-.018a1.876,1.876,0,0,0-.77-2.53Zm-.717,1.984-2.521,1.394-.216.055a.451.451,0,0,1-.379-.209l-.051-.184v-2.763a.416.416,0,0,1,.412-.407H46.5l.215.055.005,0,2.514,1.385.011.006a.375.375,0,0,1,.2.334l-.045.175,0,.008Z",
          transform: "translate(-33.768 -91.47)",
          fill: "#7b88ff"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }