<template>
  <svg class="media-snapshot-searches" xmlns="http://www.w3.org/2000/svg" width="15.311" height="15.311" viewBox="0 0 15.311 15.311">
    <path d="M14.965 13.931l-2.779-2.779a6.8 6.8 0 10-1.068 1.068l2.779 2.779a.755.755 0 001.068-1.068zM6.88 12.199a5.287 5.287 0 113.737-1.549 5.269 5.269 0 01-3.733 1.549z" fill="#939393"/>
  </svg>
</template>

<script>
  export default {
    name: 'MediaSnapshotSearches'
  }
</script>
