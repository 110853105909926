var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-control-textarea" },
    [
      _vm.label
        ? _c("label", { staticClass: "form-control-label txt-body" }, [
            _vm._v(_vm._s(_vm.label))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.description
        ? _c("span", { staticClass: "form-control-description txt-body" }, [
            _vm._v(_vm._s(_vm.description))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-form-group",
        { ref: "inputGroup", attrs: { "label-for": "textarea" } },
        [
          _vm.maxlength
            ? _c(
                "b-input-group-prepend",
                { staticClass: "form-control-count txt-muted-2 txt-body" },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.model.length) +
                      " / " +
                      _vm._s(_vm.maxlength) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("b-textarea", {
            class: [{ "is-invalid": !!_vm.error }, _vm.inputClass],
            attrs: {
              id: "textarea",
              placeholder: _vm.placeholder,
              readonly: _vm.readonly,
              rows: _vm.rows,
              "max-rows": _vm.maxRows,
              maxlength: _vm.maxlength
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            },
            model: {
              value: _vm.model,
              callback: function($$v) {
                _vm.model = $$v
              },
              expression: "model"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasManyErrors
        ? _vm._l(_vm.errorMessage, function(err, key) {
            return _c(
              "b-alert",
              {
                key: key,
                staticClass: "mb-0 mt-3",
                attrs: { variant: "error", show: !!_vm.error }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "alert-icon icon-sm",
                    attrs: { width: "24", viewBox: "0 0 24 24" }
                  },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "#icon-status-error-color" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "alert-content" }, [
                  _c("span", { staticClass: "txt-body" }, [_vm._v(_vm._s(err))])
                ])
              ]
            )
          })
        : _c(
            "b-alert",
            {
              staticClass: "mb-0 mt-3",
              attrs: { variant: "error", show: !!_vm.error }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "alert-icon icon-sm",
                  attrs: { width: "24", viewBox: "0 0 24 24" }
                },
                [
                  _c("use", {
                    attrs: { "xlink:href": "#icon-status-error-color" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "alert-content" }, [
                _c("span", { staticClass: "txt-body" }, [
                  _vm._v(_vm._s(_vm.errorMessage))
                ])
              ])
            ]
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }