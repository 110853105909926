var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-text-underline",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "12.17",
        height: "13.994",
        viewBox: "0 0 12.17 13.994"
      }
    },
    [
      _c("rect", {
        attrs: {
          width: "12.17",
          height: "1.5",
          transform: "translate(0 12.494)",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M6.076,12a4.824,4.824,0,0,0,3.259-.957A3.84,3.84,0,0,0,10.43,8V1H8.5V8.035q0,2.292-2.413,2.293T3.671,8.035V1H1.74V8a3.858,3.858,0,0,0,1.086,3.043A4.787,4.787,0,0,0,6.076,12",
          transform: "translate(0 -1)",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }