var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { role: "form" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.stepNext($event)
          }
        }
      },
      [
        _c("form-control-text", {
          staticClass: "mb-3 mt-4",
          attrs: {
            id: "phone",
            name: "phone",
            placeholder: "+0 (000) 000-0000",
            label: "Phone Number",
            type: "tel",
            icon: "phone"
          },
          on: { input: _vm.handleInput },
          model: {
            value: _vm.phone,
            callback: function($$v) {
              _vm.phone = $$v
            },
            expression: "phone"
          }
        }),
        _vm._v(" "),
        _c(
          "b-alert",
          {
            attrs: { show: _vm.invalidPhone, fade: "", variant: "warning mb-3" }
          },
          [
            _c(
              "svg",
              {
                staticClass: "alert-icon icon-sm",
                attrs: { viewBox: "0 0 24 24" }
              },
              [
                _c("use", {
                  attrs: { "xlink:href": "#icon-warning-triangle-color" }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "alert-content" }, [
              _c("span", { staticClass: "txt-body" }, [
                _vm._v("Please enter a valid international phone number.")
              ])
            ])
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "onboarding-cta pt-4 my-4" },
      [_vm._t("back"), _vm._v(" "), _vm._t("action")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }