var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-group",
      attrs: {
        id: "Group_Icon",
        "data-name": "Group Icon",
        xmlns: "http://www.w3.org/2000/svg",
        width: "22.645",
        height: "17.54",
        viewBox: "0 0 22.645 17.54"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Path_1143",
          "data-name": "Path 1143",
          d:
            "M1531.946,355.94a2.565,2.565,0,1,0,2.565,2.566,2.567,2.567,0,0,0-2.565-2.566m0,1.5a1.066,1.066,0,1,1-1.067,1.067A1.067,1.067,0,0,1,1531.948,357.439Z",
          transform: "translate(-1527.545 -353.58)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_1144",
          "data-name": "Path 1144",
          d:
            "M1545.788,355.94a2.565,2.565,0,1,0,2.565,2.566,2.568,2.568,0,0,0-2.565-2.566m0,1.5a1.066,1.066,0,1,1-1.066,1.066A1.068,1.068,0,0,1,1545.789,357.439Z",
          transform: "translate(-1527.545 -353.58)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_1145",
          "data-name": "Path 1145",
          d:
            "M1545.788,361.935a4.341,4.341,0,0,0-1.33.227,8.327,8.327,0,0,1,1.138,1.292c.065,0,.126-.019.192-.019a2.906,2.906,0,0,1,2.9,2.9v.228h-1.738a8.309,8.309,0,0,1,.159,1.5h1.942a1.139,1.139,0,0,0,1.136-1.135v-.592A4.4,4.4,0,0,0,1545.788,361.935Z",
          transform: "translate(-1527.545 -353.58)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_1146",
          "data-name": "Path 1146",
          d:
            "M1531.947,363.435c.066,0,.129.015.194.02a8.243,8.243,0,0,1,1.139-1.292,4.345,4.345,0,0,0-5.735,4.174v.594a1.14,1.14,0,0,0,1.138,1.133h1.943a8.309,8.309,0,0,1,.159-1.5h-1.741v-.228A2.906,2.906,0,0,1,1531.947,363.435Z",
          transform: "translate(-1527.545 -353.58)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_1147",
          "data-name": "Path 1147",
          d:
            "M1538.869,361.463a6.758,6.758,0,0,0-6.75,6.75v.847a2.07,2.07,0,0,0,2.056,2.06h9.384a2.071,2.071,0,0,0,2.06-2.053v-.854A6.758,6.758,0,0,0,1538.869,361.463Zm5.25,7.6a.564.564,0,0,1-.56.56h-9.379a.562.562,0,0,1-.561-.56v-.847a5.25,5.25,0,0,1,10.5,0Z",
          transform: "translate(-1527.545 -353.58)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_1148",
          "data-name": "Path 1148",
          d:
            "M1538.867,360.689a3.555,3.555,0,1,0,0-7.109h0a3.555,3.555,0,0,0,0,7.109Zm0-5.609a2.055,2.055,0,0,1,0,4.109h0a2.055,2.055,0,1,1,0-4.109Z",
          transform: "translate(-1527.545 -353.58)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }