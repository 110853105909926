var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-btn",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "26",
        height: "26",
        viewBox: "0 0 26 26"
      }
    },
    [
      _c("circle", {
        staticClass: "cl-1",
        attrs: { cx: "13", cy: "13", r: "13", fill: "rgba(199,203,255,0.5)" }
      }),
      _vm._v(" "),
      _c("g", { attrs: { id: "Close", transform: "translate(8 8)" } }, [
        _c("path", {
          staticClass: "cl-3",
          attrs: {
            d:
              "M585.265,413.2l3.615-3.615a.749.749,0,0,0-1.06-1.06l-3.616,3.616-3.615-3.616a.749.749,0,0,0-1.06,1.06l3.616,3.615-3.616,3.616a.749.749,0,0,0,1.06,1.06l3.615-3.615,3.616,3.615a.749.749,0,0,0,1.06-1.06Z",
            transform: "translate(-579.006 -407.956)",
            fill: "#293ad3"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }