var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "148",
        height: "148",
        viewBox: "0 0 148 148",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M90.205 140.501C94.575 140.161 97.865 132.781 97.425 128.951C96.535 121.191 92.475 113.951 89.795 106.681C86.846 98.6515 84.4143 90.4413 82.515 82.1009C79.635 69.3509 77.095 56.9109 70.125 45.5909C69.485 44.5909 68.245 43.081 67.025 43.211C63.375 43.591 65.175 56.751 65.025 56.751L61.735 56.8211C60.855 52.0611 58.815 44.0709 56.245 44.3709C50.395 45.0009 51.425 61.0209 51.575 65.3709C49.9765 64.867 48.2849 64.7303 46.6261 64.971C44.9674 65.2117 43.3844 65.8236 41.995 66.761C38.395 69.621 38.385 74.891 40.055 78.761C42.155 83.601 47.185 85.591 49.835 89.951C52.915 95.071 50.095 103.781 48.935 110.951C48.0641 115.7 47.5958 120.513 47.535 125.341C47.615 129.851 48.775 134.061 49.155 138.551C46.0132 138.514 42.9509 139.539 40.465 141.461",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M51.0134 94.6862C56.0778 95.9328 61.6982 95.3143 66.9969 93.383M76.8888 87.9461C75.807 88.775 74.6725 89.5487 73.4976 90.2596",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M69.4053 144.7C59.5353 145.7 61.5352 138.21 70.3052 138.21C70.2252 128.5 69.6253 121.21 69.4053 111.5",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M57.2052 144.57C48.2052 144.93 48.4553 138.57 57.7953 138.22C57.3253 128.52 56.3852 121.36 57.1452 111.16",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M82.445 107.898C78.365 117.688 80.715 134.898 83.965 137.688C82.5668 137.517 81.1537 137.857 79.9878 138.648C78.8219 139.439 77.9824 140.626 77.625 141.989",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M47.9349 68.7921C48.9955 68.7992 50.0099 69.2272 50.7549 69.9821C51.1064 70.3611 51.3725 70.8114 51.535 71.3021C51.7027 71.7937 51.7674 72.3143 51.725 72.8319C51.6784 73.3465 51.5288 73.8466 51.285 74.3021C51.037 74.7605 50.6965 75.1623 50.285 75.4821C49.6733 75.8275 48.9871 76.0197 48.285 76.0421C47.5941 76.0661 46.9078 75.9218 46.285 75.622C45.6546 75.319 45.107 74.8678 44.6891 74.307C44.2711 73.7463 43.9952 73.0926 43.8849 72.402C43.8799 71.8845 43.9893 71.3722 44.2054 70.902C44.4215 70.4317 44.7389 70.0152 45.1349 69.682C45.9364 69.0688 46.9265 68.7542 47.9349 68.7921Z",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M57.5352 69.1797C57.9465 67.5461 58.1316 65.8637 58.0851 64.1797",
          stroke: "currentColor",
          "stroke-width": "3.4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M65.1455 69.1797C65.2795 67.5163 65.2995 65.8458 65.2055 64.1797",
          stroke: "currentColor",
          "stroke-width": "3.4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M102.575 108.121C103.387 110.125 103.794 112.269 103.775 114.431C103.757 116.599 103.304 118.741 102.445 120.731",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M94.2051 138.47C100.875 136.95 106.025 129.88 108.205 123.82C111.505 114.61 109.615 102.04 101.205 96.1797",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M109.205 95.7031C112.168 99.8217 114.182 104.545 115.105 109.533",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M60.385 96.4409C60.8193 96.2798 61.2826 96.2117 61.745 96.241C62.4543 96.277 63.1321 96.545 63.6742 97.0039C64.2162 97.4628 64.5924 98.0874 64.745 98.781C64.8595 99.4829 64.7292 100.203 64.376 100.82C64.0228 101.437 63.4681 101.914 62.805 102.171C62.1436 102.43 61.4132 102.455 60.736 102.24C60.0588 102.025 59.476 101.584 59.085 100.991C58.7119 100.385 58.5498 99.6734 58.624 98.9661C58.6982 98.2587 59.0044 97.5958 59.495 97.0808C59.7371 96.7826 60.0599 96.5605 60.425 96.4409H60.385Z",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M35.5756 140.582C34.2862 138.064 33.7055 135.244 33.8956 132.422",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M27.8857 140.453C28.99 141.567 30.2377 142.529 31.5958 143.313",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M103.535 139.471C106.414 138.071 108.741 135.748 110.145 132.871",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M113.955 139.11C114.955 138.03 118.535 136.41 120.015 137.16C122.235 138.33 120.415 140.71 122.075 142.5",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M109.346 143.571L111.056 143.121",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M47.8152 86.1526C53.7465 86.8495 56.9569 86.1177 62.2652 89.3228C64.3852 90.6028 65.8352 92.6726 68.0452 93.6426C70.2552 94.6126 73.6652 93.4427 73.4552 91.0427C73.2252 88.4027 69.0152 87.3927 69.0552 84.7527C69.0952 82.1127 73.5352 81.4525 74.4552 78.8525C74.5718 78.3952 74.5739 77.9163 74.4612 77.458C74.3485 76.9997 74.1244 76.5762 73.809 76.2251C73.4935 75.874 73.0964 75.6062 72.6526 75.4453C72.2089 75.2844 71.7324 75.2355 71.2652 75.3027C69.7052 75.6627 68.8052 77.3027 67.5752 78.3027C64.3746 81.041 58.6764 80.6851 54.7352 80.3027C53.5168 81.7263 51.9929 82.8567 50.2772 83.6099C48.5614 84.363 46.6978 84.7193 44.8252 84.6526",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M39.2054 78.7533C37.5154 77.9733 33.9454 74.2032 32.4754 74.6732C29.4754 75.6732 33.5454 80.5133 32.8454 82.7433C32.4954 83.8233 29.4854 86.1533 29.0954 87.2133C28.7054 88.2733 28.7454 89.6931 29.6054 90.3531C29.9774 90.6016 30.4088 90.7467 30.8554 90.7733C32.6545 90.9339 34.446 90.3929 35.8554 89.2633C37.2554 88.1333 38.2954 86.4432 39.8554 85.6632",
          stroke: "currentColor",
          "stroke-width": "3.8",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }