var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "40",
        height: "40",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("circle", {
        attrs: {
          cx: "20",
          cy: "20",
          r: "20",
          fill: "#000",
          "fill-opacity": ".75"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M27.5 11.25c0-.688.563-1.25 1.25-1.25.688 0 1.25.563 1.25 1.25v17.5c0 .688-.563 1.25-1.25 1.25-.688 0-1.25-.563-1.25-1.25v-17.5zM24.625 19.113c.125.125.212.262.275.412.125.3.125.65.013.95-.063.163-.163.3-.276.413l-6.25 6.25a1.28 1.28 0 01-.887.362c-.325 0-.637-.125-.875-.375a1.245 1.245 0 010-1.762l4.113-4.113H11.25c-.688 0-1.25-.562-1.25-1.25 0-.687.563-1.25 1.25-1.25h9.488l-4.125-4.125a1.245 1.245 0 010-1.762 1.245 1.245 0 011.762 0l6.25 6.25z",
          fill: "#fff"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }