var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.792",
        height: "17.792",
        viewBox: "0 0 17.792 17.792"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Group_9683",
            "data-name": "Group 9683",
            transform: "translate(0.75 0.75)"
          }
        },
        [
          _c("g", { attrs: { id: "Group_9682", "data-name": "Group 9682" } }, [
            _c("circle", {
              attrs: {
                id: "Ellipse_372",
                "data-name": "Ellipse 372",
                cx: "8.146",
                cy: "8.146",
                r: "8.146",
                transform: "translate(0 0)",
                fill: "none",
                stroke: "#939393",
                "stroke-linecap": "round",
                "stroke-width": "1.5"
              }
            }),
            _vm._v(" "),
            _c("circle", {
              attrs: {
                id: "Ellipse_373",
                "data-name": "Ellipse 373",
                cx: "8.146",
                cy: "8.146",
                r: "8.146",
                transform: "translate(0 0)",
                fill: "none",
                stroke: "#939393",
                "stroke-linecap": "round",
                "stroke-width": "1.5"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_4185",
                "data-name": "Path 4185",
                d:
                  "M291.117,1061.424c.088-.793-.847-1.022-1.382-1.171-.462-1.111-1.782-1.811-2.795-.792h0c-.409.282-.684.455-1.034.294-.071-.474-.426-.9-.46-1.4a1.606,1.606,0,0,0,1.864-.486c1.247-.26,4.286-.637,2.7-2.656-.983-.831-.644-2.527-1.43-3.252-2.453-.082-5.77.345-7.406,2.172a4.267,4.267,0,0,0,.344,1.766c1,.245,2.528-.655,1.6,1.292l0,0c-1.057,1.842,2.292,3.2,2.4,3.292h0c.151.311.208,1.031.664,1.34.525.368,1.107.19,1.027.91h0c.04,1.429-.6,5.631,1.931,4.518.563-1.056.194-2.339.631-3.312C290.158,1063.148,291.123,1062.49,291.117,1061.424Z",
                transform: "translate(-278.934 -1051.949)",
                fill: "#939393"
              }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }