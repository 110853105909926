<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18.758" height="17.229" viewBox="0 0 18.758 17.229">
    <path d="M18.52 15.932l-3.058-2.873A7.99 7.99 0 102.037 4.627L1.42 3.393a.75.75 0 00-1.341.671l1.416 2.832a.747.747 0 001.005.336l2.832-1.416a.7493.7493 0 10-.669-1.341l-1.152.575a6.495 6.495 0 0112.28 2.953 6.6 6.6 0 01-.2 1.614 6.5 6.5 0 01-12.56.168.7502.7502 0 00-1.443.411 7.975 7.975 0 0012.827 3.934l3.083 2.9a.75.75 0 101.026-1.094z" fill="#2e2ed3"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-replace'
  }
</script>
