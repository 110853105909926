var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 20 20"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(1 1)" } }, [
        _c("path", {
          attrs: {
            d: "M773,344a9,9,0,1,1-9-9,9,9,0,0,1,9,9Zm0,0",
            transform: "translate(-755 -335)",
            fill: "#f0f0f0"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M783.427,335a9,9,0,0,0-8.107,5.087h16.214A9,9,0,0,0,783.427,335Zm0,0",
            transform: "translate(-774.427 -335)",
            fill: "#ff9811"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M783.427,633.931a9,9,0,0,0,8.107-5.087H775.32a9,9,0,0,0,8.107,5.087Zm0,0",
            transform: "translate(-774.427 -615.931)",
            fill: "#6da544"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M894.827,471.7a3.13,3.13,0,1,1-3.13-3.13,3.13,3.13,0,0,1,3.13,3.13Zm0,0",
            transform: "translate(-882.696 -462.696)",
            fill: "#0052b4"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M919.19,497.234a1.957,1.957,0,1,1-1.956-1.957,1.956,1.956,0,0,1,1.956,1.957Zm0,0",
            transform: "translate(-908.234 -488.234)",
            fill: "#f0f0f0"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M914.314,484.859l.6,1.369,1.487-.162-.884,1.207.884,1.207-1.487-.162-.6,1.369-.6-1.369-1.487.162.884-1.207-.884-1.207,1.487.162Zm0,0",
            transform: "translate(-905.314 -478.273)",
            fill: "#0052b4"
          }
        }),
        _c(
          "g",
          { attrs: { fill: "none", stroke: "#e4e4e4", "stroke-width": "1" } },
          [
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9", stroke: "none" }
            }),
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9.5", fill: "none" }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }