<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" class="social icon-podcast" viewBox="0 0 12.323 13.674">
      <g transform="translate(-1891.278 177.166)">
        <path d="M1895.318-169.1a2.835,2.835,0,0,1-.725-1.9,2.851,2.851,0,0,1,2.847-2.847,2.85,2.85,0,0,1,2.846,2.847,2.831,2.831,0,0,1-.726,1.9.5.5,0,0,0,.039.706.5.5,0,0,0,.706-.039,3.838,3.838,0,0,0,.981-2.566,3.846,3.846,0,0,0-3.846-3.846,3.846,3.846,0,0,0-3.847,3.846,3.835,3.835,0,0,0,.981,2.566.5.5,0,0,0,.706.039.5.5,0,0,0,.039-.706Z" fill="#993bc9"/>
        <path d="M1894.6-166.69a5.158,5.158,0,0,1-2.326-4.314,5.147,5.147,0,0,1,1.512-3.65,5.145,5.145,0,0,1,3.651-1.512,5.143,5.143,0,0,1,3.65,1.512A5.147,5.147,0,0,1,1902.6-171a5.16,5.16,0,0,1-2.326,4.314.5.5,0,0,0-.143.693.5.5,0,0,0,.693.142A6.158,6.158,0,0,0,1903.6-171a6.161,6.161,0,0,0-6.161-6.162,6.161,6.161,0,0,0-6.162,6.162,6.158,6.158,0,0,0,2.775,5.149.5.5,0,0,0,.693-.142.5.5,0,0,0-.142-.693Z" fill="#993bc9"/>
        <path d="M1897.44-163.493h0a1.711,1.711,0,0,1-1.711-1.711v-1.529a1.71,1.71,0,0,1,1.711-1.71,1.71,1.71,0,0,1,1.71,1.71v1.529A1.711,1.711,0,0,1,1897.44-163.493Z" fill="#993bc9"/>
        <circle cx="1.659" cy="1.659" r="1.659" transform="translate(1895.781 -172.663)" fill="#993bc9"/>
      </g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-podcast'
  }
</script>
