var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-check-small",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "12.192",
        height: "9.197",
        viewBox: "0 0 12.192 9.197"
      }
    },
    [
      _c("path", {
        attrs: {
          "data-name": "Path 1131",
          d:
            "M12.192 1a1 1 0 00-1-1 .988.988 0 00-.7.282L10.474.3 3.991 6.781 1.714 4.504a1 1 0 00-1.41-.021 1 1 0 00-.022 1.413h.005l.018.018 2.982 2.982.177-.176-.177.177a1 1 0 001.413 0l7.191-7.19A1 1 0 0012.192 1z",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }