var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-element-video",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18.756",
        height: "13.72",
        viewBox: "0 0 18.756 13.72"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: "#939393",
          d:
            "M15.506 0H3.25A3.251 3.251 0 0 0 0 3.25v7.22a3.252 3.252 0 0 0 3.25 3.25h12.256a3.251 3.251 0 0 0 3.25-3.25V3.25A3.249 3.249 0 0 0 15.506 0Zm1.75 10.47a1.753 1.753 0 0 1-1.75 1.75H3.25a1.753 1.753 0 0 1-1.75-1.75V3.25a1.754 1.754 0 0 1 1.75-1.751h12.256a1.754 1.754 0 0 1 1.75 1.751Z"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          fill: "#939393",
          d:
            "M11.288 5.347 9.179 4.154a1.735 1.735 0 0 0-2.59 1.51v2.392a1.737 1.737 0 0 0 2.589 1.51l2.109-1.2a1.725 1.725 0 0 0 .651-.651 1.737 1.737 0 0 0-.654-2.363m-.654 1.626-.086.087-2.11 1.2-.117.031-.206-.119-.03-.115V5.67a.237.237 0 0 1 .235-.236l.117.031 2.108 1.193.118.2Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }