<template>
  <svg class="icon-text-italic" xmlns="http://www.w3.org/2000/svg" width="8.994" height="12.002" viewBox="0 0 8.994 12.002">
    <path d="M2.63,0V1.5H4.851L2.6,10.5H0V12H6.364V10.5H4.143L6.4,1.5h2.6V0Z" transform="translate(0 0)" fill="currentColor"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconTextItalic'
  }
</script>
