var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Library_Icon",
        "data-name": "Library Icon",
        xmlns: "http://www.w3.org/2000/svg",
        width: "19.484",
        height: "15.734",
        viewBox: "0 0 19.484 15.734"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Path_7006",
          "data-name": "Path 7006",
          d:
            "M151.529,126.315H154.6a2.433,2.433,0,0,1,1.194.369.75.75,0,1,0,.8-1.27,3.916,3.916,0,0,0-1.939-.6h-3.122a.75.75,0,0,0,0,1.5Z",
          transform: "translate(-147.768 -120.767)",
          fill: "#293ad3"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_7007",
          "data-name": "Path 7007",
          d:
            "M156.59,128.243a3.9,3.9,0,0,0-1.941-.6h-3.12a.75.75,0,1,0,0,1.5H154.6a2.39,2.39,0,0,1,1.194.367.75.75,0,1,0,.8-1.27Z",
          transform: "translate(-147.768 -120.767)",
          fill: "#293ad3"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_7008",
          "data-name": "Path 7008",
          d:
            "M156.589,131.068a3.912,3.912,0,0,0-1.938-.6h-3.122a.75.75,0,0,0,0,1.5H154.6a2.439,2.439,0,0,1,1.195.368.749.749,0,0,0,.8-1.27Z",
          transform: "translate(-147.768 -120.767)",
          fill: "#293ad3"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_7009",
          "data-name": "Path 7009",
          d:
            "M163.485,124.815h-3.128a3.91,3.91,0,0,0-1.938.6.75.75,0,1,0,.8,1.27,2.423,2.423,0,0,1,1.192-.369h3.076a.75.75,0,0,0,0-1.5Z",
          transform: "translate(-147.768 -120.767)",
          fill: "#293ad3"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_7010",
          "data-name": "Path 7010",
          d:
            "M163.485,127.646h-3.127a3.889,3.889,0,0,0-1.939.6.75.75,0,1,0,.8,1.27,2.39,2.39,0,0,1,1.194-.367h3.074a.75.75,0,0,0,0-1.5Z",
          transform: "translate(-147.768 -120.767)",
          fill: "#293ad3"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_7011",
          "data-name": "Path 7011",
          d:
            "M163.485,130.471h-3.129a3.93,3.93,0,0,0-1.937.6.751.751,0,0,0,.8,1.272,2.42,2.42,0,0,1,1.194-.369h3.075a.75.75,0,0,0,0-1.5Z",
          transform: "translate(-147.768 -120.767)",
          fill: "#293ad3"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_7012",
          "data-name": "Path 7012",
          d:
            "M164.41,120.767a.232.232,0,0,0-.028.005H159.6a2.829,2.829,0,0,0-1.991.815h0l-.1.04-.1-.04h0a2.834,2.834,0,0,0-1.99-.815h-4.808a2.84,2.84,0,0,0-2.839,2.835v10.052a2.84,2.84,0,0,0,2.838,2.841h13.8a2.841,2.841,0,0,0,2.842-2.841v-10.05A2.842,2.842,0,0,0,164.41,120.767Zm1.342,12.892A1.344,1.344,0,0,1,164.41,135h-13.8a1.342,1.342,0,0,1-1.34-1.342v-10.05a1.342,1.342,0,0,1,1.339-1.338h4.808a1.34,1.34,0,0,1,.945.39l.011.01a1.643,1.643,0,0,0,2.27,0l.013-.011a1.334,1.334,0,0,1,.944-.389H164.4l.016,0a1.343,1.343,0,0,1,1.332,1.34Z",
          transform: "translate(-147.768 -120.767)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }