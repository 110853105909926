<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="icon-view-grid" width="12.199" height="12.199" viewBox="0 0 12.199 12.199">
    <g transform="translate(-11.522 -11.158)">
      <rect width="5.199" height="5.199" rx="2" transform="translate(11.522 11.158)" fill="currentColor"/>
      <rect width="5.199" height="5.199" rx="2" transform="translate(18.522 11.158)" fill="currentColor"/>
      <rect width="5.199" height="5.199" rx="2" transform="translate(18.522 18.158)" fill="currentColor"/>
      <rect width="5.199" height="5.199" rx="2" transform="translate(11.522 18.158)" fill="currentColor"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-view-grid'
  }
</script>
