import axios from "@app2/api/axios";
import {set} from "@app2/utils/helpers";
import Widget from "@app2/models/Widget";

const namespaced = true;

const state = {
  widget: null,
  previewLogo: null
};

const mutations = {
  SET_WIDGET(state, payload) {
    state.widget = payload;
  },
  SET_PREVIEW_LOGO(state, payload) {
    state.previewLogo = payload
  },
};

const actions = {
  async addLogo({state, commit}, widget) {
    if ( !state.previewLogo ) return false;
    const formData = new FormData();
    formData.append('logo', state.previewLogo);
    let {data: logo} = await axios.post(`/api/widgets/${widget.hash}/upload/logo`, formData, {
      headers: { 'content-type': 'multipart/form-data'},
    });
    if ( logo ) {
      set(widget, 'settings.logo_url', logo.settings.logo_url);
      await Widget.insertOrUpdate({
        data: widget,
      });
      commit('SET_PREVIEW_LOGO', false);
    }
  },
  getSnapshot({commit}, hash) {
    axios.get(`/api/widgets/${hash}/analytics/snapshot`).then(({data}) => {
      commit('SET_SNAPSHOT', data)
    })
  },
  getActivity({commit}, hash) {
    axios.get(`/api/widgets/${hash}/analytics/activity`, {params: {page: state.activityCurrentPage}}).then(({data}) => {
      commit('SET_ACTIVITY', data)
    })
  },
};

const getters = {

};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
