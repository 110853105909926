const namespaced = true;

const state = {
  attribute: null,
  isAttributeModalOpen: {
    visibility: false,
    edit: false
  }
};

const mutations = {
  SET_ATTRIBUTE(state, payload) {
    state.attribute = payload;
  },
  SET_ATTRIBUTE_MODAL_STATE(state, payload) {
    state.isAttributeModalOpen[payload[0]] = payload[1];
  }
};

const actions = {};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
