var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-arrow-both",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "7.806",
        height: "12.329",
        viewBox: "0 0 7.806 12.329"
      }
    },
    [
      _c(
        "g",
        { attrs: { transform: "translate(413.957 456.792) rotate(180)" } },
        [
          _c("path", {
            attrs: {
              d:
                "M412.671,452.362l-2.62,2.619-2.62-2.619a.75.75,0,1,0-1.06,1.061l3.149,3.15a.752.752,0,0,0,1.061,0l3.151-3.15a.75.75,0,1,0-1.061-1.061Z",
              fill: "#939393"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M407.437,448.894l2.619-2.62,2.62,2.62a.75.75,0,1,0,1.061-1.061l-3.15-3.15a.749.749,0,0,0-1.061,0l-3.15,3.15a.751.751,0,0,0,1.061,1.062Z",
              fill: "#939393"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }