var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-loop",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "15.102",
        height: "15.103",
        viewBox: "0 0 15.102 15.103"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M257.881,442.814l-2.779-2.779a6.8,6.8,0,1,0-1.068,1.068l2.779,2.779a.755.755,0,0,0,1.068-1.068Zm-8.085-1.732a5.287,5.287,0,1,1,3.737-1.549A5.269,5.269,0,0,1,249.8,441.082Z",
          transform: "translate(-243 -429)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }