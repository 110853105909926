<template>
  <audience-slide-in-template
    :back-route="{name: 'ExportIndex'}"
    back-label="Back to Export Audience"
    title="Recent Exports"
  >
    <transition name="fade" mode="out-in">
      <exports-list :exports="exports" v-if="exports.length" />
      <div class="side-panel-group-empty" v-else-if="exports.length === 0 && !fetching">
        <illustration-dog-left-bone class="mb-4"/>
        <p class="txt-body txt-center txt-muted mb-4">You have no recent exports.<br /> Export audience profiles to download them here.</p>
        <b-btn variant="primary" @click="goToAudienceList">
          <svg viewBox="0 0 24 24" class="btn-left"><use xlink:href="#icon-user-multiple"></use></svg>
          Audience
        </b-btn>
      </div>
    </transition>

  </audience-slide-in-template>
</template>

<script>
  import AudienceSlideInTemplate from "@app2/modules/Audience/Components/Template";
  import ExportsList from "@app2/modules/Audience/Export/Components/List";
  import IllustrationDogLeftBone from "@illustrations/DogLeftBone";

  import { debounce, get } from 'lodash';
  import sortMixin from '@app2/utils/sort-mixin';
  import { manualSort } from '@app2/utils/helpers'
  import eventBus from "@app2/core/eventBus";

  export default {
    name: 'RecentExports',
    mixins: [sortMixin],
    components: {
      AudienceSlideInTemplate,
      IllustrationDogLeftBone,
      ExportsList
    },
    data() {
      return {
        exports: [],
        fetching: true,
        page: 1,
        perPage: 15,
        lastPage: 1,
        total: null,
      }
    },
    methods: {
      debouncer: debounce((cb, args) => cb(args), 500),
      async getExports() {
        try {
          this.fetching = true;
          const page = this.page
          const {data} = await axios.get(`/api/audience/exports`, {
            params: {
              page,
              sort: this.orderObj.symbol + this.orderBy,
            },
          });
          if (data.data.length) {
            this.perPage = data.meta.per_page;
            this.lastPage = data.meta.last_page;
            this.exports = manualSort(this.exports.concat(data.data), `${this.orderObj.symbol}${this.orderBy}`)
          }
          this.total = get(data,'meta.total', null);
        } catch (e) {
          throw e;
        } finally {
          this.fetching = false;
        }
      },
      getDataOfPage(page) {
        this.page = page
        this.debouncer(() => {
          this.getExports()
        });
      },
      goToAudienceList() {
        this.$router.push({name: 'ExportIndex'})
      }
    },
    mounted() {
      if (this.$route.query.page)
        this.page = parseInt(this.$route.query.page);
      this.setOrders('export');
      this.debouncer(() => {
        this.getExports()
      });
      eventBus.$on('delete-export-success', item => {
        this.exports = this.exports.filter(ex => ex.hash !== item.hash)
      });
      eventBus.$on('audienceExportGenerated', item => {
        this.exports.forEach(exprt => {
          if (exprt.id !== item.id) return;
          exprt.status = item.status;
          return false;
        })
      })
    }
  }
</script>
