<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       viewBox="0 0 18 12.9" width="18">
    <path
        d="M14.13 0H3.437A3.437 3.437 0 0 0 0 3.437v6.071a3.436 3.436 0 0 0 3.437 3.436H14.13a3.436 3.436 0 0 0 3.436-3.436V3.437A3.436 3.436 0 0 0 14.13 0zm0 1.5a1.922 1.922 0 0 1 1.151.389.725.725 0 0 0-.372.187L9.356 7.591a.8.8 0 0 1-.571.236.8.8 0 0 1-.571-.236L2.66 2.076a.718.718 0 0 0-.373-.188 1.923 1.923 0 0 1 1.15-.388zm1.937 8.009a1.939 1.939 0 0 1-1.937 1.937H3.437A1.939 1.939 0 0 1 1.5 9.509V3.438a1.955 1.955 0 0 1 .04-.393.747.747 0 0 0 .064.095l5.552 5.515a2.3 2.3 0 0 0 1.629.672 2.3 2.3 0 0 0 1.628-.672l5.553-5.515a.74.74 0 0 0 .061-.091 1.916 1.916 0 0 1 .04.389z"
        fill="#939393"></path>
  </svg>
</template>
<script>
  export default {
    name: 'IconEmail',
  };
</script>
