import posthog from 'posthog-js';
import eventBus from '@app2/core/eventBus';
import router from '@app2/router';
import debounce from 'lodash/debounce';

const debouncer = debounce(cb => cb(), 100);

export const registerPosthog = (user) => {
    posthog.init(process.env.MIX_POSTHOG_KEY, { 
        api_host: 'https://app.posthog.com',  
        autocapture: {
            dom_event_allowlist: ['click','submit'], // DOM events from this list ['click', 'change', 'submit']
            element_allowlist: ['button', 'a', 'form', 'label', 'select'], // DOM elements from this list ['a', 'button', 'form', 'input', 'select', 'textarea', 'label']
        },
        session_recording: {
            maskAllInputs: true,
        }
    });

    // Initialize user properties with basic information
    const userProperties = {
        email: String(user.email),
        name: String(user.name),
        created_at: String(user.created_at),
        team_id: user.current_team_id,
    };
    
    // Fetch additional fields if chargebee_current_plan isn't null
    if (user.chargebee_current_plan) {
        userProperties.chargebee_item_id = String(user.chargebee_current_plan.item_id);
        userProperties.chargebee_item_price_id = String(user.chargebee_current_plan.item_price_id);
        userProperties.chargebee_status = String(user.chargebee_current_plan.status);
    }

    // Identify the user with the specified properties
    posthog.identify(String(user.id), userProperties);

    if ( !router.currentRoute.meta.ph ) {
        posthog.stopSessionRecording();
    }
    
    eventBus.$on('posthog:stop-recording', () => {
        posthog.stopSessionRecording();
    });

    eventBus.$on('posthog:start-recording', () => {
        posthog.startSessionRecording();
    });

    eventBus.$on('posthog:page-view', () => {
        debouncer(() => {
            posthog.capture('$pageview');
        });
    });
};