<template>
  <div class="user-avatar" :class="{ [`size-${size}`]: true }" :style="{ height: avatarSize + 'px', width: avatarSize + 'px' }">
    <img v-if="model && !error" :src="model" @error="error = true">
    <b-avatar v-else :variant="variant" :src="model" @img-error="error = true" rounded :style="{ height: avatarSize + 'px', width: avatarSize + 'px' }">
      <svg class="m-auto w-100" width="148" height="148" viewBox="0 0 148 148" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M74.6178 81.4C88.922 81.4 100.518 69.8042 100.518 55.5C100.518 41.1958 88.922 29.6 74.6178 29.6C60.3137 29.6 48.7178 41.1958 48.7178 55.5C48.7178 69.8042 60.3137 81.4 74.6178 81.4Z" stroke="currentColor" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M74.6178 210.9C107.313 210.9 133.818 184.395 133.818 151.7C133.818 119.005 107.313 92.5 74.6178 92.5C41.9226 92.5 15.4178 119.005 15.4178 151.7C15.4178 184.395 41.9226 210.9 74.6178 210.9Z" stroke="currentColor" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </b-avatar>
    <div class="user-avatar-edit" v-if="editable">
      <input type="file" :multiple="false" ref="file" @input="update">
      <span class="user-avatar-edit-icon">
        <svg class="icon-xs" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-pencil"></use></svg>
      </span>
    </div>
  </div>
</template>
<script>
  import axios from "@app2/api/axios";

  export default {
    name: 'UserAvatar',
    data() {
      return {
        error: false,
      }
    },
    props: {
      value: {},
      size: {
        type: String,
        default: 'md'
      },
      variant: {
        type: String,
        default: 'primary'
      },
      editable: Boolean,
      url: {
        type: String,
        default: '/settings/photo'
      },
      name: {
        type: String,
        default: 'photo'
      },
      scroll: {
        type: Number,
        default: 1
      }
    },
    computed: {
      model: {
        get() { return (this.value && !this.error) ? this.value : '' },
        set(val) { this.$emit('input') }
      },
      avatarSize() {
        return 148 - Math.round(this.scroll * 0.81);
      },
    },
    methods: {
      update(e) {
        e.preventDefault();
        if ( ! this.$refs.file.files.length) {
          return;
        }
        let self = this;
        axios.post(this.url, this.gatherFormData())
          .then(
            async () => {
              this.$emit('upload')
            },
            (error) => {
              self.form.setErrors(error.response.data.errors);
            }
          );
      },
      gatherFormData() {
        const data = new FormData();
        data.append(this.name, this.$refs.file.files[0]);
        return data;
      }
    }
  }
</script>
