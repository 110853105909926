const authProps = {
  props: true,
  meta: {
    auth: true,
    ph: true,
  }
};
export default [
  {
    path: '/login',
    name: 'login',
    component: require('@app2/modules/Auth/Pages/Login').default,
    ...authProps
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: require('@app2/modules/Auth/Pages/Register').default,
  //   ...authProps
  // },
  // {
  //   path: '/register/:hash',
  //   name: 'registerHash',
  //   component: require('@app2/modules/Auth/Pages/Register').default,
  //   ...authProps
  // },
  // {
  //   path: '/register/YVIgxOWV/:email',
  //   name: 'registerCoupon',
  //   component: require('@app2/modules/Auth/Pages/Register').default,
  //   ...authProps
  // },
  {
    path: '/login/forgot',
    name: 'forgotPassword',
    component: require('@app2/modules/Auth/Pages/ForgotPassword').default,
    ...authProps
  },
  {
    path: '/login/reset',
    name: 'resetPassword',
    component: require('@app2/modules/Auth/Pages/ResetPassword').default,
    ...authProps
  },
  {
    path: '/login/popup/callback',
    name: 'authPopup',
    component: require('@app2/modules/Auth/Components/AuthPopup').default,
    ...authProps
  }
];
