import Vue from 'vue';
import Page from "@app2/models/Page";
import Hub from "@app2/models/Hub";
const namespaced = true;

const state = {
  revisions: [],
  flags: {
    skip: false,
    switch: false,
  },
  bus: new Vue()
}

const mutations = {
  ADD_TO_HISTORY(state, revision) {
    if (state.revisions.find(r => r.active)) state.revisions.find(r => r.active).active = false
    if ( state.flags.switch ) state.revisions.pop();
    state.revisions.push({
      active: true,
      ...revision
    })
  },
  SET_FLAG(state, flag) {
    state.flags[flag.id] = flag.value
  },
  RESET_FLAGS(state) {
    state.flags = {
      skip: false,
      switch: false,
    }
  },
  SET_HISTORY_LENGTH(state, length) {
    state.revisions.length = length
  },
  RESET_HISTORY(state, type) {
    state.revisions = state.revisions.filter(r => r.type !== type);
  },
  SET_ACTIVE_REVISION(state, index) {
    if (state.revisions.find(r => r.active)) state.revisions.find(r => r.active).active = false
    state.revisions[index].active = true
  },
}

const actions = {
  addToHistory({ commit, state, getters }, revision) {
    if ( state.flags.skip ) {
      commit('RESET_FLAGS')
      return;
    }
    let latestRevision = getters['getLatestRevision']();
    if ( latestRevision && revision.content === latestRevision.content ) return

    if (state.revisions.length > 1) commit('SET_HISTORY_LENGTH', state.revisions.findIndex(r => r.active) + 1)
    commit('ADD_TO_HISTORY', revision)
    commit('RESET_FLAGS')
  },
  resetHistory({ commit }, type) {
    commit('RESET_HISTORY', type)
  },
  undo({ commit, state, getters, dispatch }) {
    let index = state.revisions.findIndex(r => r.active) - 1
    commit('SET_ACTIVE_REVISION', index)
    commit('SET_FLAG', {id: 'skip', value: true})
    dispatch('set', getters['getRevision'](index));
  },
  redo({ commit, state, getters, dispatch }) {
    let index = state.revisions.findIndex(r => r.active) + 1
    commit('SET_ACTIVE_REVISION', index)
    commit('SET_FLAG', {id: 'skip', value: true})
    dispatch('set', getters['getRevision'](index));
  },
  set({ state }, revision) {
    let model = revision.type === 'page' ? Page : Hub;
    model.insertOrUpdate({
      data: {
        id: revision.id,
        hash: revision.hash,
        ...JSON.parse(revision.content)
      }
    }).then(() => {
      state.bus.$emit('switch')
    })
  }
}

const getters = {
  getHistory: state => () => state.revisions,
  getRevisionCount: state => state.revisions.length,
  getLatestRevision: state => () => state.revisions[state.revisions.length - 1],
  getCurrentRevision: state => () => state.revisions.find(r => r.active),
  getRevision: state => index => state.revisions[index],
  isLast: state => state.revisions.findIndex(r => r.active) === state.revisions.length - 1,
  isFirst: state => state.revisions.findIndex(r => r.active) === 0,
  canSwitch: state => {
    let pageRevisions = state.revisions.filter(r => r.type === 'page').length
    let hubRevisions = state.revisions.filter(r => r.type === 'hub').length
    return pageRevisions > 1 || hubRevisions > 1
  },
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
