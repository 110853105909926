var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-location",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.5",
        height: "20",
        viewBox: "0 0 16.5 20"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Path_3521",
          "data-name": "Path 3521",
          d:
            "M525.924,1078.946v-.75a6.651,6.651,0,0,0-6.65,6.65c.01,2.97,1.591,5.622,3.112,7.555a22.129,22.129,0,0,0,3.067,3.176.751.751,0,0,0,.943,0,22.025,22.025,0,0,0,3.068-3.176c1.523-1.933,3.1-4.585,3.115-7.555a6.651,6.651,0,0,0-6.65-6.65h-.005v1.5h.005a5.152,5.152,0,0,1,5.15,5.15c.01,2.406-1.361,4.827-2.79,6.624a21.568,21.568,0,0,1-1.962,2.153c-.267.257-.489.457-.642.59l-.177.151-.055.047.471.583.472-.583-.056-.047a21.075,21.075,0,0,1-3.044-3.237,10.955,10.955,0,0,1-2.522-6.281,5.152,5.152,0,0,1,5.15-5.15Z",
          transform: "translate(-517.677 -1078.196)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          id: "Ellipse_333",
          "data-name": "Ellipse 333",
          cx: "2",
          cy: "2",
          r: "2",
          transform: "translate(6.243 4.353)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_3522",
          "data-name": "Path 3522",
          d: "M518.427,1098.2h15a.75.75,0,0,0,0-1.5h-15a.75.75,0,0,0,0,1.5",
          transform: "translate(-517.677 -1078.196)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }