<template>
  <svg width="148" height="149" viewBox="0 0 148 149" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M71.4921 123.549C72.631 123.541 73.7437 123.892 74.6729 124.55C75.0468 124.828 75.341 125.199 75.5261 125.627C75.7084 126.05 75.773 126.515 75.7131 126.973C75.6529 127.432 75.4745 127.867 75.1952 128.237C74.9113 128.608 74.5381 128.902 74.1103 129.09C71.5511 130.526 66.9275 128.8 66.9117 126.547C66.9386 126.079 67.0817 125.625 67.3281 125.226C67.5806 124.829 67.9307 124.504 68.3448 124.281C69.3119 123.767 70.397 123.514 71.4921 123.549Z" fill="currentColor"/>
    <path d="M84.4051 110.617C84.1273 108.787 82.9403 104.901 80.3576 105.29C77.7749 105.679 76.7631 108.692 76.7259 110.753C76.7404 109.664 76.5373 108.583 76.1286 107.574C75.7199 106.564 75.1138 105.646 74.3457 104.874C73.5751 104.095 72.6559 103.479 71.6427 103.062C70.6294 102.645 69.5428 102.436 68.4471 102.447C64.3687 102.506 60.398 106.542 59.3429 110.042C59.1632 109.229 58.7405 108.49 58.1312 107.923C57.5209 107.361 56.7576 106.993 55.938 106.866C53.3363 106.518 51.787 109.439 51.3822 111.564" stroke="currentColor" stroke-width="3.8" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M90.168 113.454C90.2542 113.045 90.2481 112.622 90.1501 112.215C90.068 111.807 89.8996 111.421 89.6563 111.083C89.4098 110.743 89.091 110.461 88.7229 110.259C88.3607 110.052 87.9578 109.926 87.5422 109.89C87.1267 109.854 86.7082 109.909 86.3159 110.051C85.9215 110.193 85.5624 110.418 85.2631 110.711C84.9638 111.005 84.7313 111.359 84.5814 111.751C84.4272 112.14 84.3589 112.558 84.3814 112.977" stroke="currentColor" stroke-width="3.8" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M37.5626 133.975C37.6506 133.569 37.6506 133.149 37.5626 132.743C37.4849 132.332 37.3224 131.941 37.0853 131.596C36.8419 131.254 36.5276 130.968 36.1639 130.758C35.8064 130.545 35.4059 130.413 34.9914 130.373C34.5755 130.333 34.1558 130.381 33.7602 130.516C33.3645 130.65 33.0019 130.867 32.6965 131.152C32.3965 131.442 32.1604 131.791 32.0032 132.178C31.846 132.565 31.7712 132.979 31.7835 133.397" stroke="currentColor" stroke-width="3.8" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M32.169 133.996C32.1281 133.552 31.9565 133.13 31.6756 132.783C31.3948 132.437 31.0175 132.182 30.5915 132.05C30.1655 131.918 29.71 131.916 29.2826 132.043C28.8552 132.17 28.4751 132.421 28.1906 132.765C27.9995 132.997 27.8561 133.264 27.7684 133.551C27.6808 133.839 27.6506 134.141 27.6797 134.44" stroke="currentColor" stroke-width="3.8" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22.4024 134.031L17.084 133.922" stroke="currentColor" stroke-width="3.8" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M133.516 134.406L124.772 135.009" stroke="currentColor" stroke-width="3.8" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M113.717 132.43C114.422 130.167 114.494 127.754 113.926 125.453" stroke="currentColor" stroke-width="3.8" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M120.04 131.234C119.285 132.307 118.392 133.276 117.385 134.116" stroke="currentColor" stroke-width="3.8" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M90.1699 107.777C90.9943 106.861 93.9726 105.452 95.2207 106.029C97.094 106.982 95.5986 108.989 97.0106 110.476" stroke="currentColor" stroke-width="3.8" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M48.3055 128.758C50.2106 124.574 51.4436 120.115 51.9592 115.546" stroke="currentColor" stroke-width="3.8" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M50.4141 112.312C67.1222 115.631 84.2988 115.843 101.084 112.936" stroke="currentColor" stroke-width="3.8" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M46.7422 130.059C66.0662 133.583 85.8747 133.512 105.173 129.85" stroke="currentColor" stroke-width="3.8" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M104.939 129.927C105.774 129.756 107.843 129.726 108.416 131.711C109.06 133.963 106.328 135.041 104.54 135.377C85.2447 139.039 65.4391 139.11 46.1178 135.586C46.1178 135.586 42.4926 135.127 42.9863 131.619C43.2978 129.411 46.7415 130.056 46.7415 130.056" stroke="currentColor" stroke-width="3.8" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M103.219 128.046C100.811 124.212 99.4238 119.826 99.1904 115.305" stroke="currentColor" stroke-width="3.8" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M67.681 122.979C67.7387 122.947 67.7917 122.907 67.8384 122.86C68.1276 122.561 68.3028 122.17 68.3334 121.755C68.5206 120.219 66.8743 120.486 66.2067 121.241C66.0155 121.447 65.9064 121.716 65.9 121.997C65.8936 122.277 65.9905 122.551 66.1722 122.765C66.3539 122.979 66.6078 123.119 66.8858 123.159C67.1639 123.198 67.4468 123.134 67.681 122.979Z" fill="currentColor"/>
    <path d="M70.2845 118.831C70.6553 117.828 72.0347 118.219 72.6494 118.445C73.2642 118.67 73.5063 119.772 73.5097 120.007C73.5907 120.977 72.3745 123.181 71.1551 122.269C70.7027 121.835 70.3813 121.282 70.2274 120.675C70.0735 120.067 70.0933 119.428 70.2845 118.831Z" fill="currentColor"/>
    <path d="M78.6197 120.076C78.9531 120.337 79.2248 120.668 79.4156 121.046C79.6064 121.424 79.7115 121.839 79.7235 122.263C79.7644 122.565 79.7461 122.873 79.6696 123.168C79.5526 123.467 79.3551 123.727 79.0991 123.92C78.8431 124.113 78.5387 124.231 78.2195 124.261C77.032 124.396 75.0319 123.369 75.2295 121.968C75.427 120.566 77.1652 119.125 78.6197 120.076Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
  name: 'IllustrationDogBowl'
}
</script>