<template>
  <svg class="icon-list-bullet" xmlns="http://www.w3.org/2000/svg" width="17.748" height="13.078" viewBox="0 0 17.748 13.078">
    <path d="M6.746 2.343h10.248a.75.75 0 000-1.5H6.746a.75.75 0 000 1.5zM16.998 8.084H6.746a.75.75 0 000 1.5h10.248a.75.75 0 000-1.5zM6.746 5.863h5.28a.75.75 0 000-1.5h-5.28a.75.75 0 000 1.5zM12.03 11.578H6.746a.75.75 0 000 1.5h5.28a.75.75 0 000-1.5zM2.471 0a2.47 2.47 0 102.47 2.471A2.471 2.471 0 002.471 0zm0 3.442a.971.971 0 11.971-.971.973.973 0 01-.971.971zM2.471 7a2.47 2.47 0 102.47 2.471A2.471 2.471 0 002.471 7zm0 3.442a.971.971 0 11.971-.971.973.973 0 01-.971.971z" fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconListBullet'
  }
</script>
