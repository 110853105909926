var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "15.481",
        height: "14.15",
        viewBox: "0 0 15.481 14.15"
      }
    },
    [
      _c("path", { attrs: { d: "M9.777.136z", fill: "#939393" } }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M9.777.136a7.024 7.024 0 00-7.761 3.962l-.6-1.192a.75.75 0 10-1.341.671l1.42 2.841a.747.747 0 001.006.335l2.842-1.42a.75.75 0 10-.671-1.341l-1.25.625A5.567 5.567 0 018.395 1.5a5.5 5.5 0 011.085.107 5.573 5.573 0 014.494 5.46 5.658 5.658 0 01-.107 1.089 5.576 5.576 0 01-5.461 4.494 5.7 5.7 0 01-1.087-.107 5.574 5.574 0 01-3.383-2.129.75.75 0 10-1.2.9A7.074 7.074 0 109.771.137z",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M8.237 3.604a.749.749 0 00-.749.75v2.73a.755.755 0 00.254.562l1.65 1.455a.75.75 0 10.992-1.124l-1.4-1.231V4.354a.75.75 0 00-.747-.75z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }