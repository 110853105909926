<template>
  <div class="form-control-text" ref="scroll">
    <p v-if="label" class="form-control-label txt-body mb-1" :class="{'txt-muted': disabled}">{{ label }}</p>
    <span class="form-control-description txt-body mb-3" v-if="description">{{ description }}</span>
    <slot name="description"></slot>
    <b-tooltip v-if="disabled && disabledTooltip" :target="() => $refs.inputGroup">{{ disabledTooltip }}</b-tooltip>
    <b-input-group ref="inputGroup" v-if="!viewMode">
      <b-input-group-prepend v-if="icon">
        <svg viewBox="0 0 24 24" :class="{[`txt-${iconVariant}`]: iconVariant}" class="icon-regular ml-1"><use :xlink:href="`#icon-${icon}`"></use></svg>
      </b-input-group-prepend>
      <b-input-group-prepend v-if="avatarIcon">
        <img :src="avatarIcon">
      </b-input-group-prepend>
      <b-input-group-prepend v-if="$slots.left">
        <slot name="left"></slot>
      </b-input-group-prepend>
      <template v-if="showCounter || showPasswordCheck">
        <b-input-group-append v-if="showCounter">
          <span class="form-control-ghost visible">{{ model ? model.length : '0' }} / {{ maxlength }}</span>
        </b-input-group-append>
        <b-input-group-append v-if="showPasswordCheck">
          <b-btn variant="platform-primary ml-auto mr-2" :disabled="!model" size="sm" @click="passwordVisible = !passwordVisible">
            <transition name="fade" appear mode="out-in">
              <svg class="icon-sm" viewBox="0 0 24 24"><use :xlink:href="`#icon-${passwordVisible ? 'eye-off' : 'eye'}`"></use></svg>
            </transition>
          </b-btn>
        </b-input-group-append>
      </template>
      <b-input-group-append v-if="clearable">
        <transition name="fade" appear mode="out-in">
          <b-btn v-if="model.length" variant="platform-primary ml-auto mr-2" @click="model = ''">
            <svg class="icon-sm" viewBox="0 0 24 24"><use xlink:href="#icon-cross"></use></svg>
          </b-btn>
        </transition>
      </b-input-group-append>
      <b-input-group-append v-else-if="!showPasswordCheck">
        <slot name="append"></slot>
      </b-input-group-append>
      <b-input-group-append v-if="$slots.right">
        <slot name="right"></slot>
      </b-input-group-append>
      <b-form-input
        :class="{'is-invalid':!!error, [inputClass]: !!inputClass}"
        ref="input"
        :formatter="formatter"
        :lazy-formatter="lazyFormatter"
        :disabled="disabled"
        :type="inputType"
        :state="state"
        :placeholder="placeholder"
        :readonly="readonly"
        @change="$emit('change')"
        @blur="$emit('blur')"
        :size="size"
        :step="step"
        :maxlength="maxlength"
        :min="min"
        :max="max"
        v-model="model"
        @keyup.enter="onEnter"
      />
    </b-input-group>
    <div v-else>
      <p class="my-3 txt-bold">{{ model }} {{ viewModeText }}</p>
    </div>
    <template v-if="hasManyErrors">
      <b-alert v-for="(err, key) in error" :key="key" class="mb-0" variant="error" :show="!!error && showError">
        <svg class="alert-icon icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-status-error-color"></use></svg>
        <div class="alert-content">
          <span class="txt-body">{{ err }}</span>
        </div>
      </b-alert>
    </template>
    <b-alert v-else class="mb-0" variant="error" :show="!!error && showError">
      <svg class="alert-icon icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-status-error-color"></use></svg>
      <div class="alert-content">
        <span class="txt-body">{{ error }}</span>
      </div>
    </b-alert>
    <slot name="error"></slot>
  </div>
</template>
<script>
  export default {
    name: 'FormControlText',
    data() {
      return {
        passwordVisible: false
      }
    },
    props: {
      iconVariant: {
        type: String
      },
      label: {
        type: String
      },
      size: {
        type: String,
        default: ''
      },
      description: {
        type: String
      },
      value: {
        type: [String, Number]
      },
      disabled: {
        type: Boolean,
        default: false
      },
      readonly: Boolean,
      viewMode: Boolean,
      viewModeText: String,
      placeholder: {
        type: String,
      },
      icon: {
        type: String,
      },
      avatarIcon: {
        type: String,
      },
      type: {
        type: String,
        default: 'text'
      },
      state: {},
      error: {},
      showError: {
        type: Boolean,
        default: true
      },
      showPasswordCheck: {
        type: Boolean,
        default: false
      },
      formatter: {},
      lazyFormatter: Boolean,
      disabledTooltip: {},
      step: {
        type: Number,
        default: 1
      },
      maxlength: {
        type: Number
      },
      hasEnter: Boolean,
      showCounter: Boolean,
      showPassword: Boolean,
      min: Number,
      max: Number,
      clearable: Boolean,
      inputClass: String,
    },
    computed: {
      model: {
        get() { return this.value },
        set(value) { this.$emit('input', value) },
      },
      inputType() {
        return this.passwordVisible ? 'text' : this.type;
      },
      hasManyErrors() {
        return this.error && Array.isArray(JSON.parse(JSON.stringify(this.error)))
      }
    },
    methods: {
      focus() {
        if (this.$refs.input) this.$refs.input.focus();
      },
      scrollIntoView() {
        if (this.$refs.scroll) this.$refs.scroll.scrollIntoView({
          behavior: 'smooth'
        });
      },
      onEnter() {
        if(!this.hasEnter) return

        this.$refs.input.blur()
      },
      togglePassword() {
        if(this.inputType === 'password') {
          this.inputType = 'text'
        } else {
          this.inputType = 'password'
        }
      }
    }
  }
</script>
