import prerequisites from "@app2/modules/AccountSettings/Pages/Routes/Subscription/Helpers/subscription.prerequisites";
export const filterAddons = ({ plan = null, permissions = [], addons = [], plans = [], }) => {
    plan = plan ? plan.toLowerCase() : '';
    return addons.filter(addon => {
        const prerequisite = prerequisites.find(prerequisite => prerequisite.product === addon.type);
        if ( !prerequisite ) return true;
        const user = {
            plan,
            permissions,
        }
        const addonPrerequisites = prerequisite.prerequisites || {};
        const addonExclude = prerequisite.exclude || {};

        // Check if the user meets any exclude conditions
        if (meetsExcludeCondition(addonExclude, plans, user)) {
            return false;
        }

        const addonPlans = addonPrerequisites.plans || [];
        const addonEntitlements = addonPrerequisites.entitlements || [];
        const relation = addonPrerequisites.relation || 'AND';

        if (relation === 'OR') {
            // Check if the user meets any of the OR conditions
            if (meetsAnyCondition(addonPlans.map(pl => pl.toLowerCase()), plan) || meetsAnyCondition(addonEntitlements, permissions)) {
                return true;
            }
            return false;
        } else {
            // Check if the user meets all of the AND conditions
            let meetsAllPrerequisites = true;

            if (!meetsAllCondition(addonPlans.map(pl => pl.toLowerCase()), plan)) {
                meetsAllPrerequisites = false;
            }

            if (!meetsAllCondition(addonEntitlements, permissions)) {
                meetsAllPrerequisites = false;
            }

            if (!meetsAllPrerequisites) {
                return false;
            }
        }

        // If the addon meets all prerequisites, return true
        return true;
    });
}

function meetsAnyCondition(conditionArray, userArray) {
    return conditionArray.some(condition => userArray.includes(condition));
}

function meetsAllCondition(conditionArray, userArray) {
    return conditionArray.every(condition => userArray.includes(condition));
}

function meetsExcludeCondition(excludeObject, plans, user) {
    if (excludeObject.plans && meetsAnyCondition(excludeObject.plans.map(pl => pl.toLowerCase()), user.plan)) {
        return true;
    }

    if (excludeObject.entitlements && meetsAnyCondition(excludeObject.entitlements, user.permissions)) {
        return true;
    }

    return false;
}

export const getDependencies = (entitlement, addons) => {
    const dependencies = [];
  
    // Iterate over the prerequisites array
    for (const item of prerequisites) {
      if (item.type === "addon" && item.prerequisites) {
        // Check if the entitlement is a prerequisite for the current item
        if (item.prerequisites.entitlements.includes(entitlement)) {
          // Add the product to the dependencies array
          let addon = addons.find(addon => addon.item_id === item.product && addon.searchie_is_subscribed);
          if (addon) dependencies.push(addon.item_price_id);
        }
      }
    }
  
    return dependencies;
}

export const mappedEntitlements = {
    "WisdomAI-Launch": ["wisdomai-launch"],
}

export const getAddonsToExclude = (planSubstring, activeAddons) => {
    planSubstring = planSubstring.toLowerCase();
    const excludedAddons = [];

    // Iterate over the prerequisites array
    for (const item of prerequisites) {
        const activeAddon = activeAddons.find((activeAddon) =>
            activeAddon.item_id === item.product
        );
        if (item.type === "addon" && item.exclude && item.exclude.plans) {
            // Check if any excluded plan contains the planSubstring
            const isExcludedByPlan = item.exclude.plans.some((excludedPlan) =>
                planSubstring.includes(excludedPlan)
            );

            // Check if the addon has a plan prerequisite
            const hasPlanPrerequisite = item.prerequisites && item.prerequisites.plans;

            // Check if the plan prerequisite exists and does not contain the planSubstring
            const isPrerequisiteExcluded = hasPlanPrerequisite && !item.prerequisites.plans.some((prerequisitePlan) =>
                planSubstring.includes(prerequisitePlan)
            );

            if ((isExcludedByPlan || isPrerequisiteExcluded) && activeAddon) {
                excludedAddons.push(activeAddon.item_price_id);
            }
        } else if (item.type === "addon" && item.prerequisites && item.prerequisites.plans) {
            const isPrerequisiteExcluded = !item.prerequisites.plans.some((prerequisitePlan) =>
                planSubstring.includes(prerequisitePlan)
            );

            const entitlemenPrerequisites = item.prerequisites.entitlements || [];

            const hasPrerequisiteEntitlement = entitlemenPrerequisites.some((prerequisiteEntitlement) => {
                const entitlements = activeAddons.map((addon) => mappedEntitlements[addon.item_id]).reduce((result, item) => {
                    if (Array.isArray(item)) {
                      return result.concat(item);
                    }
                    return result;
                }, [])
                return entitlements.includes(prerequisiteEntitlement);
            });

            if (isPrerequisiteExcluded && !hasPrerequisiteEntitlement && activeAddon) {
                excludedAddons.push(activeAddon.item_price_id);
            }
        }
    }

    return excludedAddons;
}