import Base from '@app2/models/Base';

export default class Comment extends Base {
  static primaryKey = 'hash';
  static entity = 'App\\Comment';

  static apiConfig = {
    baseURL: "/api/internal/v2/hubs/",
    actions: {
      fetch(url, config) {
        return this.get(`${url}`, {
          dataKey: 'data',
          ...config,
        })
      },
      fetchSingle(url, config) {
        return this.get(`${url}`, {
          dataTransformer: ({ data, headers }) => {
            if (data[0].parent_id) {
              const parent = JSON.parse(JSON.stringify(data[0].parent));
              delete data[0].parent;
              parent.replies = [data[0]];
              return [parent]
            }
            return data
          },
          ...config,
        })
      }
    }
  };

  static fields() {
    return {
      id: this.attr(null),
      hash: this.attr(null),
      audience: this.attr(null),
      audience_id: this.attr(null),
      attachments: this.attr([]),
      commentable_type: this.attr(null),
      commentable: this.attr(null),
      commentable_id: this.attr(null),
      comments_count: this.attr(null),
      parent_id: this.attr(null),
      body: this.attr(null),
      is_edited: this.attr(null),
      user: this.attr(null),
      meta: this.attr(null),
      mentioned_audiences: this.attr([]),
      user_id: this.attr(null),
      created_at: this.attr(null),
      read: this.attr(false),
      updated_at: this.attr(null),
      like_count: this.attr(null),
      liked_by_me: this.attr(null),
      replies: this.hasMany(Comment, 'parent_id', 'id'),
      ...super.fields(),
    };
  }
}
