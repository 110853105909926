<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18.241" height="16.646" viewBox="0 0 18.241 16.646">
    <path d="M630.634,161.166a8.235,8.235,0,0,0-1.619-.16,8.323,8.323,0,0,0-7.649,5.082l-.841-1.68a.75.75,0,1,0-1.341.671h0l1.7,3.4a.75.75,0,0,0,1.006.335l3.4-1.7a.75.75,0,1,0-.67-1.341l-1.888.944a6.817,6.817,0,0,1,13.115,2.6,7.077,7.077,0,0,1-.13,1.33,6.827,6.827,0,0,1-6.685,5.5,7.041,7.041,0,0,1-1.33-.13,6.819,6.819,0,0,1-4.141-2.607.75.75,0,0,0-1.2.9,8.317,8.317,0,0,0,5.054,3.181,8.414,8.414,0,0,0,1.618.157,8.321,8.321,0,0,0,8.156-6.712v0a8.423,8.423,0,0,0,.157-1.617A8.321,8.321,0,0,0,630.634,161.166Z" transform="translate(-619.104 -161.006)" fill="#f4a321"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconRetry',
  };
</script>
