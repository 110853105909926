var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-edit-transcript",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.926",
        height: "15",
        viewBox: "0 0 17.926 14.99"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(-626.632 -1070.797)" } }, [
        _c("path", {
          attrs: {
            d:
              "M644.557,1073.4v-.055a2.548,2.548,0,0,0-2.487-2.546H629.119a2.548,2.548,0,0,0-2.487,2.545l0,.044h0v9.853a2.552,2.552,0,0,0,2.488,2.548h4.2a.75.75,0,0,0,0-1.5h-4.158a1.052,1.052,0,0,1-1.026-1.048v-9.9a1.045,1.045,0,0,1,1.019-1.044H642.04a1.046,1.046,0,0,1,1.018,1.045v1.985a.75.75,0,0,0,1.5,0h0Z",
            fill: "currentColor"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M643.808,1082.448a.75.75,0,0,0-.75.75v.043a1.047,1.047,0,0,1-1.023,1.046v0a.742.742,0,0,0-.514.217.75.75,0,0,0,0,1.061h0a.755.755,0,0,0,.531.22h.019a2.549,2.549,0,0,0,2.486-2.546v-.043A.749.749,0,0,0,643.808,1082.448Z",
            fill: "currentColor"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M630.772,1075.564H640.4a.75.75,0,0,0,0-1.5h-9.626a.75.75,0,0,0,0,1.5Z",
            fill: "currentColor"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M630.772,1078.752h6.24a.75.75,0,0,0,0-1.5h-6.24a.75.75,0,0,0,0,1.5Z",
            fill: "currentColor"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M643.9,1080.475a2.258,2.258,0,0,0-3.194-3.192l-3.571,3.57a.742.742,0,0,0-.162.244l-1.5,3.631a.751.751,0,0,0,.979.98l3.633-1.5a.746.746,0,0,0,.245-.163l3.57-3.57m-4.527,2.405-1.825.752.754-1.823,3.465-3.466a.758.758,0,1,1,1.071,1.072Z",
            fill: "currentColor"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }