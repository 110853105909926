import axios from '@app2/api/axios';
import router from '@app2/router';
import Bus from '@app2/core/eventBus';
import { set } from '@app2/utils/helpers'
import {addProfitWellScript, timeBetween} from "@app2/utils/helpers";
import get from 'lodash/get';
// import { initIntercom } from '@app2/core/register-intercom';
import { loadHubspot, unloadHubspot } from '@app2/core/register-hubspot';
// import LDClient from "@app2/core/register-launch-darkly";
import moment from 'moment';
import { registerPosthog } from '@app2/core/register-posthog';
import store from '@app2/store';

const namespaced = true;

const csrfToken = document.head.querySelector('meta[name="csrf-token"]');
if (!csrfToken) {
  throw 'CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token';
}

const user = get(window, 'Spark.state.user', null);
if ( user ) user.team = get(window, 'Spark.state.currentTeam', null);

const state = {
  ongoingRequest: false,
  user,
  token: csrfToken.content,
  subscribed: false,
  ai_subscribed: false,
  client: null,
  plans: get(window, 'Searchie.checkout') ? [get(window, 'Searchie.checkout')] : [],
  addons: [],
  isNewSubscription: false,
  newUser: {},
  hasPaymentMethod: false,
};

const mutations = {
  STORE_USER(state, user) {
    state.user = user;
    // if ( !state.client && !!user ) new LDClient({
    //   key: user.id,
    //   email: user.email,
    //   name: user.name
    // }).then(e => {
    //   state.client = e;
    // })
    if (user) store.dispatch('fetchShortcuts');
    Bus.$emit('userUpdated', user);
    loadHubspot(user);
  },
  STORE_TEAM(state, team) {
    state.user.team = team;
  },
  STORE_IS_NEW_SUBSCRIPTION(state, isNewSubscription) {
    state.isNewSubscription = isNewSubscription;
  },
  STORE_TOKEN(state, token) {
    state.token = token;
  },
  STORE_PLANS(state, plans) {
    state.plans = plans;
  },
  STORE_ADDONS(state, addons) {
    state.addons = addons;
  },
  STORE_SUBSCRIBED(state, subscribed) {
    state.subscribed = subscribed;
  },
  STORE_AI_SUBSCRIBED(state, ai_subscribed) {
    state.ai_subscribed = ai_subscribed;
  },
  CLEAR_USER(state) {
    if ( state.user ) {
      state.user = null;
      state.client = null;
      unloadHubspot();
    }
  },
  SET_NEW_USER(state, payload) {
    state.newUser = payload
  },
  SET_NEW_USER_EMAIL(state, payload) {
    state.newUser.email = payload
  },
  SET_NEW_USER_SOURCES(state, payload) {
    set(state, 'newUser.meta.content_sources', payload)
  },
  SET_HAS_PAYMENT_METHOD(state, payload) {
    state.hasPaymentMethod = payload
  },
  SET_USER_COPILOT_LIMIT(state) {
    set(state.user, 'chargebee_permissions.copilot-interactions.used', get(state.user, 'chargebee_permissions.copilot-interactions.quota'))
  }
};

const actions = {
  async fetchUser({ state, commit }, sync = false) {
    let route = '/user/current';
    if ( state.isNewSubscription || sync ) route = '/user/current?subscription_success=true';
    commit('STORE_IS_NEW_SUBSCRIPTION', false);
    const { data: user } = await axios.get(route);
    if ( !user ) return;
    commit('STORE_USER', user);
    if (user && user.hasOwnProperty('subscribed')) commit('STORE_SUBSCRIBED', get(user, 'subscribed', true));
    if (user && user.hasOwnProperty('ai_subscribed')) commit('STORE_AI_SUBSCRIBED', get(user, 'ai_subscribed', true));
    if (process.env.NODE_ENV === 'production') {
      addProfitWellScript(user.email);
    }

    // logUser(user);
    // initIntercom(user);
    registerPosthog(user);
    if (!window.onUsersnapCXLoad) {
      window.onUsersnapCXLoad = function(api) {
        api.init({
          user: {
            userId: user.id,
            name: user.name,
            email: user.email,
          }
        });
        window.Usersnap = api;
        window.dispatchEvent(new Event('Usersnap_loaded'));
      }
      const script = document.createElement('script');
      script.defer = 1;
      script.src = 'https://widget.usersnap.com/global/load/271988ea-76cd-4d50-a5b1-95a6a0bf8194?onload=onUsersnapCXLoad';
      document.getElementsByTagName('head')[0].appendChild(script);
    }
    return user;
  },
  async fetchPlans({ state, commit }) {
    const { data: {data} } = await axios.get('/api/internal/v2/chargebee/plans?include=item.features');
    commit('STORE_PLANS', data);
  },
  async fetchPlan({ state, commit }, hash) {
    const { data } = await axios.get(`/api/internal/v2/chargebee/plans/${hash}?include=item.features`);
    commit('STORE_PLANS', [data]);
  },
  async fetchAddons({ state, commit }) {
    const { data } = await axios.get('/api/internal/v2/chargebee/team/addons?include=item.features');
    commit('STORE_ADDONS', data);
  },
  async login({ commit, dispatch, getters }, { email, password }) {
    try {
      const response = await axios.post('/auth/login', { email, password });
      if (response && response.data) {
       await dispatch('fetchUser');
        // commit('STORE_USER', response.data.state.user);
        if ( getters['GET_PLAN_ID']?.includes('trial') && getters['GET_USER']?.meta?.trial_hash  ) {
          dispatch('fetchPlan', getters['GET_USER'].meta.trial_hash);
        }
        commit('STORE_TOKEN', response.data.token);
        window.Spark.state = response.data.state || window.Spark.state;
        Bus.$emit('loggedIn');
        return response.data.state.user;
      }
    } catch (e) {
      throw e;
    }
    return false;
  },
  async logout({ commit }, appRouter) {
    window.postMessage({ action: 'searchie_logout' })
    if (!appRouter) appRouter = router;
    appRouter.push({ name: 'login', params: { logout: true }});
    const response = await axios.post('/auth/logout');
    if (response && response.data) {
      commit('STORE_TOKEN', response.data.token);
      window.Spark.state = response.data.state || window.Spark.state;
    }
    Bus.$emit('loggedOut');
    commit('CLEAR_USER');
  },
  async unauthenticated({ commit, dispatch }) {
    commit('CLEAR_USER');
    try {
      await dispatch('logout');
    }
    catch (e) {}
    finally {
      location = '/login';
    }
  },
  async register({ commit, dispatch }, form) {
    try {
      const response = await axios.post('/auth/register', form);
      if (response && response.data) {
        await dispatch('fetchUser');
        // commit('STORE_USER', response.data.state.user);
        commit('STORE_TOKEN', response.data.token);
        window.Spark.state = response.data.state || window.Spark.state;
        Bus.$emit('loggedIn');

        if(window.$FPROM) {
          window.$FPROM.trackSignup(
            {
              email: response.data.state.user.email
            },
            // () => console.log('Callback received!')
          );
        }

        return response.data.state.user;
      }
    } catch (e) {
      throw e;
    }
    return false;
  },
  forgotPassword({ commit }, email) {
    return axios.post('/auth/forgot', { email });
  },
  async resetPassword({ commit }, { email, token, password, password_confirmation }) {
    try {
      const response = await axios.post('/auth/reset', { email, token, password_confirmation, password });
      if (response && response.data) {
        commit('STORE_USER', response.data.state.user);
        commit('STORE_TOKEN', response.data.token);
        window.Spark.state = response.data.state || window.Spark.state;
        Bus.$emit('loggedIn');
        return response.data.state.user;
      }
    } catch (e) {
      throw e;
    }
    return false;
  },
  async updateOnboarding({ commit }, data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post('/api/auth/onboarding', data);
        if (response) {
          commit('STORE_USER', response.data);
  
          Bus.$emit('register-complete')
  
          res(response.data);
        }
      } catch (e) {
        rej(e)
        throw e;
      }
    })
  },
};

const getters = {
  GET_TRIAL: state => {
    const subscription = get(state, 'user.chargebee_active_subscription', null);
    const plan = get(state, 'user.chargebee_current_plan', null);
    if ( ['non_renewing', 'cancelled'].includes(subscription?.status) ) return false;
    let trialEndDate = get(subscription, 'searchie_trial_plan_ends_at')
    if ( !trialEndDate ) trialEndDate = get(subscription, 'trial_end', null);
    let isOnboarding = router.currentRoute.name === 'onboarding';
    if ( !trialEndDate && !isOnboarding ) return false;
    let timeLeft = timeBetween(trialEndDate);
    if ( timeLeft.isDone && !isOnboarding ) return false;
    return {
      trial_ends_at: trialEndDate,
      fromNow: timeLeft.fromNow,
      days_left: Math.round(timeLeft.days),
      total_trial_days: Math.round(timeBetween(trialEndDate, get(subscription, 'created_at', null)).days),
      is_paid: !!get(subscription, 'trial_end', null) || get(plan, 'item_id', '').includes('trial') && !get(plan, 'item_id', '').includes('new-user')
    }
  },
  GET_PLAN: state => {
    return get(state, 'user.chargebee_current_plan', null);
  },
  GET_PLAN_ID: state => {
    return get(state, 'user.chargebee_current_plan.item_price_id', null);
  },
  GET_PLAN_FEATURES: state => {
    return get(state, 'user.chargebee_current_plan.item.features', []);
  },
  GET_PLAN_FEATURE: state => id => {
    let feature = get(state, 'user.chargebee_current_plan.item.features', []).find(f => f.feature_id === id);
    if ( !feature ) return null;
    feature = JSON.parse(JSON.stringify(feature));
    if ( get(state, 'user.chargebee_active_subscription.status', null) === 'in_trial' ) {
      const featureOverride = get(state, `user.chargebee_current_plan.metadata.trial_information.entitlement_overrides.${id}`, null);
      if ( featureOverride ) {
        set(feature, 'pivot.name', feature?.pivot?.name.replace(feature?.pivot?.value, featureOverride));
        set(feature, 'pivot.value', featureOverride);
      }
    }
    return feature;
  },
  GET_SUBSCRIPTION: state => {
    return get(state, 'user.chargebee_active_subscription', null);
  },
  GET_PAYMENT_SOURCE: state => {
    let paymentSource = get(state, 'user.chargebee_primary_payment_source', null);
    return paymentSource ? paymentSource : state.hasPaymentMethod;
  },
  GET_PLANS: state => {
    return state.plans;
  },
  GET_PERMISSIONS: state => {
    return get(state, 'user.chargebee_permissions', []);
  },
  GET_ADDONS: state => {
    return state.addons;
  },
  GET_TEAM: state => {
    let team = get(state, 'user.team', null);
    let teamSettings = get(team, 'meta.settings.embed');
    if ( teamSettings && teamSettings.enable && Array.isArray(teamSettings.enable) && !teamSettings.enable.length ) {
      teamSettings.enable = {};
    }
    return team;
  },
  GET_USER: state => {
    return state.user;
  },
  USER_FORM: state => state.newUser
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
