<template>
  <svg class="icon-audience-avg-plays" xmlns="http://www.w3.org/2000/svg" width="16.252" height="16.251" viewBox="0 0 16.252 16.251">
    <path d="M11.358 5.107a.752.752 0 00-1.061 0l-5.008 5.008a.75.75 0 001.061 1.061l5.008-5.008a.752.752 0 000-1.061z" fill="#939393"/>
    <path d="M8.126 0a8.126 8.126 0 108.126 8.126A8.126 8.126 0 008.126 0zm4.686 12.811a6.626 6.626 0 111.941-4.685 6.609 6.609 0 01-1.941 4.685z" fill="#939393"/>
    <path d="M7.748 5.798A1.872 1.872 0 105.875 7.67a1.873 1.873 0 001.873-1.872zm-2.246 0a.373.373 0 11.373.373.374.374 0 01-.373-.373zM10.496 8.546a1.872 1.872 0 101.873 1.873 1.872 1.872 0 00-1.873-1.873zm0 2.246a.374.374 0 11.373-.373.376.376 0 01-.373.373z" fill="#939393"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconAudienceAvgPlays'
  }
</script>
