var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19",
        height: "19",
        viewBox: "0 0 19 19"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M23704.924,23653a4.94,4.94,0,0,1-4.926-4.93v-9.143a4.96,4.96,0,0,1,5.027-4.928h9.146a4.787,4.787,0,0,1,4.822,4.928v9.143a4.933,4.933,0,0,1-4.924,4.93Zm-3.316-14.072v9.242a3.391,3.391,0,0,0,3.418,3.418h9.146a3.389,3.389,0,0,0,3.416-3.418v-9.242a3.391,3.391,0,0,0-3.416-3.42h-9.146A3.392,3.392,0,0,0,23701.607,23638.93Zm8.543,8.34a3.115,3.115,0,1,1,3.119,3.117A3.137,3.137,0,0,1,23710.15,23647.27Z",
          transform: "translate(-23699.998 -23634.002)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }