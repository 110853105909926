var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-move-bottom",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18.993",
        height: "15.967",
        viewBox: "0 0 18.993 15.967"
      }
    },
    [
      _c(
        "g",
        { attrs: { transform: "translate(159.703 849.522) rotate(180)" } },
        [
          _c("path", {
            attrs: {
              d:
                "M154.2,841.024l-3.186-3.186a.75.75,0,0,0-1.061,0l-3.186,3.186a.75.75,0,0,0,1.061,1.061l1.9-1.9v8.591a.75.75,0,0,0,1.5,0v-8.593l1.907,1.906a.75.75,0,1,0,1.061-1.061Z",
              fill: "currentColor"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M158.953,833.555h-2.3a.75.75,0,0,0,0,1.5h2.3a.75.75,0,0,0,0-1.5Z",
              fill: "currentColor"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M153.889,833.555h-2.3a.75.75,0,1,0,0,1.5h2.3a.75.75,0,0,0,0-1.5Z",
              fill: "currentColor"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M149.575,834.3a.75.75,0,0,0-.75-.75h-2.3a.75.75,0,1,0,0,1.5h2.3A.75.75,0,0,0,149.575,834.3Z",
              fill: "currentColor"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M143.76,833.555h-2.3a.75.75,0,0,0,0,1.5h2.3a.75.75,0,1,0,0-1.5Z",
              fill: "currentColor"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }