var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "side-panel-title",
      class: _vm.classes,
      on: {
        click: function($event) {
          _vm.editable && _vm.$refs.editable && _vm.$refs.editable.focus()
        }
      }
    },
    [
      _c("transition", { attrs: { name: "slide-down", appear: "" } }, [
        _vm.inFocus && _vm.suggestion
          ? _c(
              "div",
              {
                staticClass: "rte-suggestion",
                on: {
                  click: function($event) {
                    return _vm.$emit("set-suggestion")
                  }
                }
              },
              [
                _vm._t("suggestions"),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    staticClass: "icon-regular",
                    attrs: { width: "24", viewBox: "0 0 24 24" }
                  },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "#icon-arrow-left-top" }
                    })
                  ]
                )
              ],
              2
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "inner" },
        [
          (!_vm.title || _vm.title === "") && _vm.editable
            ? _c(
                "span",
                { staticClass: "txt-title-small side-panel-title-placeholder" },
                [_vm._v(_vm._s(_vm.placeholder))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "h3",
            {
              key: _vm.resetKey,
              ref: "editable",
              staticClass: "txt-title-small mb-0 txt-bold",
              class: {
                "in-focus": _vm.inFocus,
                "with-extension": _vm.extension
              },
              attrs: { contenteditable: _vm.editable, spellcheck: "false" },
              on: {
                input: _vm.onInput,
                focus: _vm.setFocus,
                blur: _vm.onBlur,
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.onEnter($event)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [
              _vm.inFocus
                ? [
                    _vm.showActions
                      ? _c(
                          "div",
                          { staticClass: "side-panel-title-actions" },
                          [
                            _vm._t("actions"),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "icon", size: "sm" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.reset($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "icon-sm",
                                    attrs: { width: "24", viewBox: "0 0 24 24" }
                                  },
                                  [
                                    _c("use", {
                                      attrs: { "xlink:href": "#icon-cross" }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  disabled: _vm.error,
                                  variant: "icon",
                                  size: "sm ml-1"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.onBlur($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "icon-sm",
                                    attrs: { width: "24", viewBox: "0 0 24 24" }
                                  },
                                  [
                                    _c("use", {
                                      attrs: { "xlink:href": "#icon-tick" }
                                    })
                                  ]
                                )
                              ]
                            )
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                : _vm.buttons
                ? _c(
                    "div",
                    { staticClass: "side-panel-title-buttons ml-auto" },
                    [_vm._t("default")],
                    2
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.error && _vm.errorMessage
        ? _c("p", { staticClass: "txt-body mt-2" }, [
            _vm._v(_vm._s(_vm.errorMessage))
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }