var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "audience-segment-dialogs" },
    [
      _c("create-dialog", {
        key: "create-" + _vm.create.key,
        ref: "create",
        attrs: {
          id: "segment-create-dialog",
          title: "Name your Segment",
          placeholder: "Enter Segment Name",
          "button-label": "Save Segment",
          processing: _vm.create.processing
        },
        on: { accept: _vm.createSegment },
        model: {
          value: _vm.create.title,
          callback: function($$v) {
            _vm.$set(_vm.create, "title", $$v)
          },
          expression: "create.title"
        }
      }),
      _vm._v(" "),
      _c("rename-dialog", {
        key: "rename-" + _vm.rename.key,
        ref: "rename",
        attrs: {
          id: "segment-rename-dialog",
          title: "Rename your Segment",
          processing: _vm.rename.processing,
          placeholder: "Enter Segment Name"
        },
        on: { accept: _vm.renameSegment },
        model: {
          value: _vm.rename.title,
          callback: function($$v) {
            _vm.$set(_vm.rename, "title", $$v)
          },
          expression: "rename.title"
        }
      }),
      _vm._v(" "),
      _c("duplicate-dialog", {
        key: "duplicate-" + _vm.duplicate.key,
        ref: "duplicate",
        attrs: {
          id: "segment-duplicate-dialog",
          title: "Duplicate Segment",
          processing: _vm.duplicate.processing,
          placeholder: "Enter Segment Name",
          alert: false
        },
        on: { accept: _vm.duplicateSegment },
        model: {
          value: _vm.duplicate.title,
          callback: function($$v) {
            _vm.$set(_vm.duplicate, "title", $$v)
          },
          expression: "duplicate.title"
        }
      }),
      _vm._v(" "),
      _vm.destroy.segment
        ? _c(
            "delete-dialog",
            {
              key: "destroy-" + _vm.destroy.key,
              attrs: {
                id: "segment-delete-dialog",
                title: "Delete segment?",
                disabled: !!_vm.hubs.length,
                processing: _vm.destroy.processing
              },
              on: { accept: _vm.deleteSegment }
            },
            [
              _vm.canDelete
                ? _c("p", { staticClass: "mt-3 mb-3 txt-heading-small" }, [
                    _vm._v("You are about to permanently delete the "),
                    _c("b", [_vm._v(_vm._s(_vm.destroy.segment.title))]),
                    _vm._v(" segment. Are you sure?")
                  ])
                : _c("p", { staticClass: "mb-3 txt-heading-small" }, [
                    _c("b", [_vm._v(_vm._s(_vm.destroy.segment.title))]),
                    _vm._v(
                      " segment is currently used in the Hubs below. You will need to remove it from these Hubs before deleting."
                    )
                  ]),
              _vm._v(" "),
              _vm.hubs
                ? _c(
                    "b-btn-group",
                    _vm._l(_vm.hubs, function(hub, key) {
                      return _c(
                        "b-btn",
                        {
                          key: key,
                          attrs: {
                            variant: "tag variant-red",
                            size: "sm",
                            block: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.openHub(hub.hash)
                            }
                          }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "btn-left mr-2",
                              attrs: { viewBox: "0 0 24 24" }
                            },
                            [
                              _c("use", {
                                attrs: { "xlink:href": "#icon-hub" }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass: "btn-tag-label mr-auto",
                              attrs: {
                                title: hub.title.length > 34 ? hub.title : ""
                              }
                            },
                            [_vm._v(_vm._s(hub.title))]
                          ),
                          _vm._v(" "),
                          _c(
                            "svg",
                            {
                              staticClass: "btn-right only-hover ml-2",
                              attrs: { viewBox: "0 0 24 24" }
                            },
                            [
                              _c("use", {
                                attrs: { "xlink:href": "#icon-share-external" }
                              })
                            ]
                          )
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("exit-dialog", {
        attrs: {
          id: "close-segment-dialog",
          subtitle: "Save changes?",
          title: "Save your changes before closing?",
          description: "Unsaved data will be lost.",
          "accept-label": "Save",
          "reject-label": "Don't Save",
          processing: _vm.exitProcessing
        },
        on: { reject: _vm.rejectSegmentClose, accept: _vm.acceptSegmentClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }