var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" } },
    [
      _c("path", {
        attrs: {
          fill: "#00732F",
          d:
            "M10 1a8.948 8.948 0 00-3.668.787v5.2h12.14C17.231 3.5 13.912 1 10 1z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d:
            "M6.332 13.014h12.14C18.808 12.07 19 11.059 19 10s-.192-2.07-.528-3.014H6.332v6.028z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M6.332 18.213A8.948 8.948 0 0010 19c3.912 0 7.231-2.5 8.472-5.986H6.332v5.199z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "red",
          d:
            "M6.332 6.986v-5.2C3.192 3.192 1 6.337 1 10s2.192 6.808 5.332 8.213V6.986z"
        }
      }),
      _c("g", { attrs: { fill: "none" } }, [
        _c("circle", { attrs: { cx: "10", cy: "10", r: "9" } }),
        _c("circle", {
          attrs: { cx: "10", cy: "10", r: "9.5", stroke: "#E4E4E4" }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }