<template>
  <svg class="icon-chapter-small" xmlns="http://www.w3.org/2000/svg" width="15.898" height="6.49" viewBox="0 0 15.898 6.49">
    <path d="M15.148 4.596h-6.3a.75.75 0 000 1.5h6.3a.75.75 0 100-1.5zM8.85 2.159h6.3a.75.75 0 100-1.5h-6.3a.75.75 0 000 1.5zM5.324 1.61L2.844.242a1.892 1.892 0 00-.777-.226V.01c-.031 0-.05-.005-.073-.006H1.911A1.916 1.916 0 00-.004 1.846v2.788a1.9 1.9 0 00.255.922l.01.016a1.934 1.934 0 002.576.685l2.478-1.37a1.862 1.862 0 00.73-.7l.021-.035.006-.012.011-.019a1.859 1.859 0 00-.764-2.5zm-.715 1.951L2.12 4.937l-.208.053a.433.433 0 01-.362-.2l-.051-.183V1.898a.418.418 0 01.412-.4h.024l.183.052 2.48 1.368.01.006a.361.361 0 01.191.32l-.042.167v.006z" fill="#fff"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconChapterSmall'
  }
</script>
