<template>
  <div>
    <form role="form" @submit.prevent="stepNext">
      <!-- Name -->
      <form-control-text
        class="mb-3 mt-4"
        :class="{ 'is-invalid': $v.$error }"
        @change="$v.name.$touch()"
        id="name"
        name="name"
        placeholder="Type here..."
        label="Your name"
        type="text"
        icon="user-single"
        v-model="name" />
      <b-alert :show="$v.$error" fade variant="warning mb-3">
        <svg viewBox="0 0 24 24" class="alert-icon icon-sm"><use xlink:href="#icon-warning-triangle-color"></use></svg>
        <div class="alert-content">
          <span class="txt-body" v-if="!$v.name.required">{{error}}</span>
        </div>
      </b-alert>
    </form>
    <div class="onboarding-cta pt-4 my-4">
      <slot name="back"></slot>
      <slot name="action" ></slot>
    </div>
  </div>
</template>

<script>
  import { required, minLength } from 'vuelidate/lib/validators'
  import store from "@app2/store";
  import axios from '@app2/api/axios';
  import FormControlText from "@app2/core/Components/Common/Forms/Text";

  export default {
    name: 'NameStep',
    components: {
      FormControlText
    },
    data() {
      return {
        name: this.user.name,
      }
    },
    validations: {
      name: {
        required
      },
    },
    props: {
      user: {
        type: Object,
      },
    },
    mounted() {
      if(this.$v.$invalid) {
        this.$emit('loading', true);
      } else {
        this.$emit('loading', false);
      }
      this.$emit('animation', false);

      this.$parent.$on('step-next', this.stepNext.bind(this))
    },
    beforeDestroy() {
      this.$parent.$off('step-next');
    },
    methods: {
      async stepNext(done) {
        try {
          this.$emit('loading', true);
          await this.submit();
          this.$emit('next',1);
          this.$emit('nameUpdate',this.name);
        } catch(e) {
          done(e);
          throw e;
        }
      },
      submit() {
        return this.$store.dispatch('auth/updateOnboarding', {
          name: this.name,
          onboard_step: 1,
        })
      },
    },
    watch: {
      name: function () {
        if(this.$v.$invalid) {
          this.$emit('loading', true);
        } else {
          this.$emit('loading', false);
        }
      }
    }
  };
</script>
