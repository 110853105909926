<template>
	<svg class="icon-pending" xmlns="http://www.w3.org/2000/svg" width="17.449" height="17.449" viewBox="0 0 17.449 17.449">
	  <path d="M16.785 5.386a8.664 8.664 0 00-1.891-2.831A8.66 8.66 0 0012.063.663 8.655 8.655 0 008.724 0a8.648 8.648 0 00-3.338.663 8.663 8.663 0 00-2.832 1.892 8.684 8.684 0 00-1.891 2.83A8.643 8.643 0 000 8.724a8.64 8.64 0 00.663 3.339 8.688 8.688 0 001.891 2.831 8.653 8.653 0 002.832 1.892 8.666 8.666 0 003.338.663 8.67 8.67 0 003.339-.663 8.637 8.637 0 002.831-1.893 8.659 8.659 0 001.891-2.83 8.656 8.656 0 00.664-3.339 8.651 8.651 0 00-.664-3.338zm-1.386 6.1a7.152 7.152 0 01-1.566 2.344 7.133 7.133 0 01-2.344 1.566 7.151 7.151 0 01-2.765.551 7.151 7.151 0 01-2.765-.551 7.132 7.132 0 01-2.343-1.566 7.152 7.152 0 01-1.567-2.344 7.163 7.163 0 01-.55-2.765 7.167 7.167 0 01.55-2.765 7.162 7.162 0 011.567-2.344 7.134 7.134 0 012.343-1.565 7.151 7.151 0 012.765-.551 7.164 7.164 0 012.765.55 7.151 7.151 0 012.344 1.567A7.159 7.159 0 0115.4 5.957a7.16 7.16 0 01.549 2.764 7.147 7.147 0 01-.55 2.768z" fill="currentColor"/>
	  <path d="M9.406 8.401V4.57a.75.75 0 00-.75-.75.749.749 0 00-.749.75v4.508l2.773 2.445a.75.75 0 001.058-.066.751.751 0 00-.066-1.059z" fill="currentColor"/>
	</svg>
</template>
<script>
  export default {
    name: 'IconPending',
  };
</script>
