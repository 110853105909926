<template>
  <svg class="icon-open-lg" xmlns="http://www.w3.org/2000/svg" width="16.002" height="15.994" viewBox="0 0 16.002 15.994">
    <path d="M158.907,798.071a.749.749,0,0,0-.749-.75h-4.316a.75.75,0,0,0,0,1.5h2.505l-6.132,6.133a.75.75,0,0,0,1.06,1.061l6.132-6.133v2.505a.75.75,0,0,0,1.5,0Z" transform="translate(-142.906 -797.321)" fill="#939393"/><path d="M155.577,806.156a.75.75,0,0,0-.75.75h0v2.13a2.783,2.783,0,0,1-2.78,2.779h-4.861a2.783,2.783,0,0,1-2.781-2.778h0v-4.86a2.783,2.783,0,0,1,2.765-2.781h2.145a.749.749,0,0,0,0-1.5h-2.13v.005h0v-.006a4.278,4.278,0,0,0-4.278,4.282v4.862a4.279,4.279,0,0,0,4.28,4.277h4.861a4.279,4.279,0,0,0,4.28-4.279v-2.13A.75.75,0,0,0,155.577,806.156Zm-8.132-4.809h0l.008,0Z" transform="translate(-142.906 -797.321)" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconOpenLg',
  };
</script>
