var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        "data-name": "Pause Icon",
        xmlns: "http://www.w3.org/2000/svg",
        width: "10",
        height: "11",
        viewBox: "0 0 10 11"
      }
    },
    [
      _c("path", {
        attrs: {
          "data-name": "Pause Icon",
          d:
            "M5.996 9.002v-7a2 2 0 014 0v7a2 2 0 01-4 0zm-6 0v-7a2 2 0 014 0v7a2 2 0 01-4 0z",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }