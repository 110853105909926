<template>
  <svg class="icon-video" xmlns="http://www.w3.org/2000/svg" width="19" height="13.72" viewBox="0 0 18.756 13.72">
    <path d="M755.694,885.278H743.438a3.251,3.251,0,0,0-3.25,3.25v7.22a3.252,3.252,0,0,0,3.25,3.25h12.256a3.251,3.251,0,0,0,3.25-3.25v-7.22A3.249,3.249,0,0,0,755.694,885.278Zm1.75,10.47a1.753,1.753,0,0,1-1.75,1.75H743.438a1.753,1.753,0,0,1-1.75-1.75v-7.22a1.754,1.754,0,0,1,1.75-1.751h12.256a1.754,1.754,0,0,1,1.75,1.751Z" transform="translate(-740.188 -885.278)" fill="currentColor"/>
    <path d="M751.476,890.625l-2.109-1.193a1.735,1.735,0,0,0-2.59,1.51v2.392a1.737,1.737,0,0,0,2.589,1.51l0,0,2.109-1.2a1.725,1.725,0,0,0,.651-.651l0,0a1.737,1.737,0,0,0-.654-2.363m-.654,1.626-.086.087-2.11,1.2,0,0-.117.031-.206-.119-.03-.115v-2.392a.237.237,0,0,1,.235-.236l.117.031,2.108,1.193h0l.118.2Z" transform="translate(-740.188 -885.278)" fill="currentColor"/>
  </svg>

  </svg>
</template>
<script>
  export default {
    name: 'IconVideo',
  };
</script>
