var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-hubs-time",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20.48",
        height: "18.986",
        viewBox: "0 0 20.48 18.986"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M7.915 11.456zM8.583 10.791a1.624 1.624 0 00-.656-2.185l-.009-.005-1.928-1.07a1.67 1.67 0 00-.664-.2l-.079-.007H5.174a1.663 1.663 0 00-1.66 1.6v.026l-.007 2.151v.005a1.6 1.6 0 00.222.814l.006.011a1.68 1.68 0 002.23.6l1.938-1.064a1.661 1.661 0 00.628-.6l.007-.012.009-.014zm-1.39-.648l-1.941 1.066-.086.022-.148-.082-.014-.052.008-2.12.161-.156h.008l.074.021 1.937 1.075.009.005.064.109-.015.053-.06.058z",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M18.972 9.591V2.784A2.782 2.782 0 0016.19 0H2.784A2.784 2.784 0 000 2.784v10.432a2.784 2.784 0 002.783 2.783h7.29a.75.75 0 000-1.5h-7.29A1.286 1.286 0 011.5 13.216v-7.84h13.182a.75.75 0 000-1.5H1.5V2.784a1.288 1.288 0 011.284-1.285H16.19a1.284 1.284 0 011.282 1.284v6.808a.75.75 0 001.5 0z",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M14.714 7.391h-.048a5.8 5.8 0 00-5.782 5.787 5.8 5.8 0 105.83-5.786zm-.041 10.1a4.3 4.3 0 01-1.629-8.272 4.243 4.243 0 011.629-.325h.017a4.3 4.3 0 11-.017 8.6z",
          fill: "#e34c4c"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M15.398 12.863v-2.3a.75.75 0 00-1.5 0v2.64a.752.752 0 00.254.562l1.594 1.407a.75.75 0 00.993-1.124z",
          fill: "#e34c4c"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }