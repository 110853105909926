<template>
  <svg class="icon-section-footer" xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16">
    <path d="M13.779,1.5H15.75l.23.015A.75.75,0,0,0,16.175.027,3.31,3.31,0,0,0,15.75,0H13.779a.75.75,0,0,0,0,1.5" fill="#939393"/><path d="M17.5,3.25v1.7a.75.75,0,0,0,1.5,0V3.25a3.283,3.283,0,0,0-.092-.772.75.75,0,1,0-1.457.355,1.777,1.777,0,0,1,.049.417" fill="#939393"/><path d="M8.479,1.5h2.3a.75.75,0,0,0,0-1.5h-2.3a.75.75,0,0,0,0,1.5" fill="#939393"/><path d="M3.2,1.5H5.479a.75.75,0,0,0,0-1.5H3.158A.75.75,0,1,0,3.2,1.5" fill="#939393"/><path d="M18.25,7.2a.749.749,0,0,0-.75.75V9.5H1.5V7.693a.75.75,0,0,0-1.5,0v2.3a.692.692,0,0,0,.026.128A.719.719,0,0,0,0,10.25v2.5A3.251,3.251,0,0,0,3.25,16h12.5A3.25,3.25,0,0,0,19,12.75v-2.5h0V7.95a.75.75,0,0,0-.75-.75m-2.5,7.3H3.25A1.753,1.753,0,0,1,1.5,12.75V11h16v1.75a1.753,1.753,0,0,1-1.75,1.75" fill="#939393"/><path d="M.75,5.443a.75.75,0,0,0,.75-.75V3.25a1.75,1.75,0,0,1,.1-.588.75.75,0,1,0-1.413-.5A3.245,3.245,0,0,0,0,3.25V4.693a.75.75,0,0,0,.75.75" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-section-footer'
  }
</script>
