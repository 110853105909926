import Vue from 'vue';
import {
  AlertPlugin,
  AvatarPlugin,
  ButtonPlugin,
  ButtonGroupPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormTextareaPlugin,
  FormSelectPlugin,
  FormPlugin,
  InputGroupPlugin,
  FormCheckboxPlugin,
  FormRadioPlugin,
  LayoutPlugin,
  ModalPlugin,
  NavPlugin,
  SpinnerPlugin,
  TabsPlugin,
  DropdownPlugin,
  PopoverPlugin,
  TooltipPlugin,
  FormFilePlugin,
  CarouselPlugin,
  CollapsePlugin,
  BreadcrumbPlugin,
  ProgressPlugin,
  TablePlugin,
  PaginationPlugin
} from 'bootstrap-vue';

Vue.use(ButtonPlugin);
Vue.use(ButtonGroupPlugin);

Vue.use(FormPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormRadioPlugin)

Vue.use(InputGroupPlugin);

Vue.use(TabsPlugin);
Vue.use(AlertPlugin);
Vue.use(AvatarPlugin);

Vue.use(SpinnerPlugin);
Vue.use(LayoutPlugin);

Vue.use(NavPlugin);
Vue.use(ModalPlugin);
Vue.use(DropdownPlugin);
Vue.use(PopoverPlugin);
Vue.use(TooltipPlugin);
Vue.use(FormFilePlugin);

Vue.use(CarouselPlugin);
Vue.use(CollapsePlugin);
Vue.use(BreadcrumbPlugin);
Vue.use(ProgressPlugin);

Vue.use(TablePlugin);
Vue.use(PaginationPlugin);
