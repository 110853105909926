import Vue from 'vue';
import Vuelidate from 'vuelidate';
import router from '@app2/modules/Audience/Bulk/bulk.router';
import store from "@app2/store";

Vue.use(Vuelidate);
export default () => {
  return new Vue({
    el: '#bulk-app',
    store,
    router,
    template: `<transition name="fade" mode="out-in"><router-view></router-view></transition>`
  });
};
