var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "side-panel-collapse",
      class: { "can-hover": !_vm.visible }
    },
    [
      _c(
        "div",
        {
          staticClass: "slot-top",
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.visible = !_vm.visible
            }
          }
        },
        [
          _c("h4", { staticClass: "txt-heading-small mb-1" }, [
            _vm._v(_vm._s(_vm.data.title))
          ]),
          _vm._v(" "),
          _c("b-btn", { attrs: { variant: "icon primary", size: "sm" } }, [
            _c(
              "svg",
              {
                staticClass: "icon-sm",
                attrs: { width: "24", viewBox: "0 0 24 24" }
              },
              [_c("use", { attrs: { "xlink:href": "#icon-chevron-down" } })]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.data.completed || _vm.data.failed
        ? _c(
            "b-collapse",
            {
              staticClass: "slot-middle pr-1",
              model: {
                value: _vm.visible,
                callback: function($$v) {
                  _vm.visible = $$v
                },
                expression: "visible"
              }
            },
            [
              _c(
                "div",
                { staticClass: "pt-3 px-2 pb-3" },
                [
                  _vm.data.completed
                    ? _c(
                        "b-alert",
                        { attrs: { show: "", fade: "", variant: "success" } },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "alert-icon icon-sm",
                              attrs: { viewBox: "0 0 24 24" }
                            },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href": "#icon-status-success-color"
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "alert-content" }, [
                            _c("h6", { staticClass: "txt-body" }, [
                              _c("b", [_vm._v("Successful rows")])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "alert-count" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.numberFormat(_vm.data.completed)) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.failed
                    ? _c(
                        "b-alert",
                        { attrs: { show: "", variant: "warning" } },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "alert-icon icon-sm",
                              attrs: { viewBox: "0 0 24 24" }
                            },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href": "#icon-warning-triangle-color"
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "alert-content" }, [
                            _c("h6", { staticClass: "txt-body" }, [
                              _c("b", [_vm._v("Failed rows")])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "alert-count" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.numberFormat(_vm.data.failed)) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "slot-bottom",
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.visible = !_vm.visible
            }
          }
        },
        [
          _c("div", { staticClass: "txt-body txt-muted" }, [
            _vm.data.failed
              ? _c(
                  "svg",
                  {
                    staticClass: "mr-2 icon-sm",
                    attrs: { width: "24", viewBox: "0 0 24 24" }
                  },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "#icon-warning-triangle-color" }
                    })
                  ]
                )
              : _vm._e(),
            _vm._v(
              "\n      " +
                _vm._s(_vm.numberFormat(_vm.data.count)) +
                " people\n    "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "ml-auto txt-body-small txt-muted-2" }, [
            _vm._v(_vm._s(_vm._f("datetime")(_vm.data.created_at)))
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }