var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "side-panel-link-row" }, [
    _vm.draggable
      ? _c("div", { staticClass: "handle px-2" }, [
          _c("svg", { attrs: { width: "24", viewBox: "0 0 24 24" } }, [
            _c("use", { attrs: { "xlink:href": "#icon-drag-horizontal" } })
          ])
        ])
      : _vm.$slots.left
      ? _c("div", [_vm._t("left")], 2)
      : _vm._e(),
    _vm._v(" "),
    _c(
      "p",
      {
        ref: "link",
        staticClass: "txt-heading-small w-100",
        class: { "pl-0": _vm.draggable },
        attrs: { contenteditable: _vm.editable },
        on: {
          input: function($event) {
            return _vm.$emit("input")
          }
        }
      },
      [_vm._v(_vm._s(_vm.model))]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "fx-row-center" },
      [
        _vm.$slots.right ? _c("div", [_vm._t("right")], 2) : _vm._e(),
        _vm._v(" "),
        _c(
          "b-btn",
          {
            attrs: {
              disabled: _vm.disabled,
              variant: "icon ml-auto mr-2 " + _vm.variant,
              size: "sm"
            },
            on: {
              click: function($event) {
                return _vm.$emit("delete")
              }
            }
          },
          [
            _c(
              "svg",
              {
                staticClass: "icon-sm txt-red",
                attrs: { width: "24", height: "24", viewBox: "0 0 24 24" }
              },
              [_c("use", { attrs: { "xlink:href": "#icon-trash" } })]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }