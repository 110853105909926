import Base from '@app/models/Base';
import { timestamps } from '@app/utils/model-fields';
import moment from '@app/utils/moment';
import Folder from '@app/models/Folder';
import Tag from '@app/models/Tag';
import playerEmbedSettings from "@app/utils/playerEmbedSettings";
import playerLogo from "@app/utils/playerLogo";
import Playlist from "@app/models/Playlist";
import FilePlaylist from "@app/models/FilePlaylist";
import get from "lodash/get";

export default class File extends Base {
  static entity = 'App\\File';

  static apiConfig = {
    baseURL: '/api/files/',
    actions: {
      fetch: {
        url: '/',
        // append connection_type from the file to the nested folder
        dataTransformer: response => {
          response.data.data.forEach(d => d.folder && (d.folder.connection_type = d.connection_type));
          return response.data.data;
        }
      },
      fetchForFolder(folderId, config) {
        return this.get(`${folderId}/files`, {
          dataKey: 'data',
          baseURL: Folder.apiConfig.baseURL,
          ...config
        })
      },
      fetchForPlaylist(playlistId, config) {
        return this.get(`${playlistId}/files`, {
          baseURL: Playlist.apiConfig.baseURL,
          ...config,
        })
      },
      search(config) {
        return this.get(`/`, {
          dataKey: 'data',
          baseURL: '/api/search/files/',
          ...config
        })
      },
      bulkDelete(config) {
        return this.delete('bulk', {...config})
      }
    }
  };

  static primaryKey = 'hash';

  watchFields = ['title', 'description', 'meta', 'chapters', 'privacy'];
  requiredFields = ['title', 'folder_id'];

  static fields() {
    return {
      id: this.attr(null),
      hash: this.attr(null),
      user_id: this.attr(null),
      connection_type: this.attr(null),
      collection_name: this.attr(null),
      folder_id: this.attr(null),
      folder: this.belongsTo(Folder, 'folder_id'),
      chapters: this.attr([]),
      origin_folder_id: this.attr(null),
      origin_folder: this.belongsTo(Folder, 'origin_folder_id'),
      source_id: this.attr(null),
      title: this.attr(null),
      description: this.attr(null),
      highlight: this.attr(null),
      source_url: this.attr(null),
      thumbnail: this.attr(null),
      thumb_url: this.attr(null),
      custom_logo_url: this.attr(null),
      meta: this.attr(null),
      privacy: this.attr(null),
      status: this.attr(null),
      status_upload: this.attr(null),
      status_transcription: this.attr(null),
      status_transcode: this.attr(null),
      status_proofread: this.attr(null),
      transcriptions_count: this.attr(null),
      permissions: this.attr({}),
      player_settings: this.attr(null),
      player_logo: this.attr(null),
      posted_at: this.attr(null),
      posted_at_local: this.attr(null),
      thumbnail_previews: this.attr(null),
      tags: this.attr(null),
      new_upload: this.attr(null),
      playlists: this.belongsToMany(Playlist, FilePlaylist, 'file_id', 'playlist_id'),
      file_playlists: this.hasMany(FilePlaylist, 'file_id', 'id'),
      index_playlists: this.attr(null),
      index_tags: this.attr(null),
      page: this.attr(null),
      attachments: this.attr([]),
      showAttachments: this.attr(null),
      is_selected: this.boolean(false),
      ...super.fields(),
    };
  }

  static beforeCreate(model) {
    if (!model.thumbnail && model.meta && model.meta.thumbnail) {
      model.thumbnail = model.meta.thumbnail;
      delete model.meta.thumbnail;
    }
    File.updateTime(model);
  }
  static beforeUpdate(model) {
    File.updateTime(model);
  }
  static updateTime(model) {
    if (!model) return;
    if (model.posted_at_local) return;
    model.posted_at_local = moment(model.posted_at || model.created_at).fromNow();
  }

  get lowerTitle() {
    return (this.title) ? this.title.toLowerCase() : '';
  }

  isInPlaylist(playlistId) {
    if (!this.file_playlists) return false;
    return this.file_playlists.map(p => p.playlist_id).includes(playlistId);
  }

  get isAudio() {
    return this.source_url && this.source_url.substring(this.source_url.lastIndexOf('.')+1, this.source_url.length) === 'mp3' ||
      this.collection_name === 'audio';
  }

  get noAudio () {
    return this.isPlayable && !this.status_transcription
  }

  get noAudioWarning () {
    return `File does not have any audio.`
  }

  get isNotProcessed() {
    return !this.status
      && !this.status_upload
      && !this.status_transcode
      && !this.status_transcription;
  }

  get isProcessed() {
    return (this.status_upload || '').includes('processed')
      && (this.status_transcode || '').includes('processed')
      && (this.status_transcription || '').includes('processed');
  }

  get isPlayable() {
    return (this.status_upload || '').includes('processed')
      && (this.status_transcode || '').includes('processed');
  }

  get isPending() {
    return (this.status || '').includes('pending')
      || (this.status_upload || '').includes('pending')
      || (this.status_transcode || '').includes('pending')
      || (this.status_transcription || '').includes('pending');
  }

  get isFailed() {
    return (this.status || '').includes('failed')
      || (this.status_upload || '').includes('failed')
      || (this.status_transcode || '').includes('failed')
      || (this.status_transcription || '').includes('failed');
  }

  get isLocal() {
    return this.folder && this.folder.connection_type === 'local' || this.connection_type === 'local';
  }

  get isMP4() {
    return this.source_url && this.source_url.substring(this.source_url.lastIndexOf('.')+1, this.source_url.length) === 'mp4';
  }

  get isM3U8() {
    return this.source_url && this.source_url.substring(this.source_url.lastIndexOf('.')+1, this.source_url.length) === 'm3u8';
  }

  get playerEmbed() {
    return playerEmbedSettings(this.player_settings);
  }

  get logo() {
    return playerLogo(this.player_logo);
  }

  static mutators() {
    return {
      player_settings(value) {
        if ( !value ) return {};
        Object.entries(value).forEach(level => {
          if ( ( Array.isArray(level[1]) || !level[1] ) ) {
            value[level[0]] = {};
          }
        });
        return value;
      }
    };
  }

  get thumb() {
    if (!this.thumbnail) return {};
    return {
      src: this.thumbnail.small || this.thumbnail.big,
      srcset: `${this.thumbnail.small} 400w, ${this.thumbnail.big} 1200w`,
      sizes: `(max-width: 400px) 400px, 1200px`
    }
  }

  get canSaveChapters() {
    if ( !this.chapters ) return;
    return this.chapters.filter((ch, index) => {
      if ( !ch.title ) return false;
      if ( get(this, 'meta.length') && ch.start / 1000 >= get(this, 'meta.length') ) return false;
      return !(index !== 0 && (ch.start === 0 || ch.start === this.chapters[index - 1].start));
    }).length === this.chapters.length;
  }

  get chapterErrors() {
    let errors = {};
    this.chapters.forEach((ch, index) => {
      if ( !ch.title ) {
        errors.title = 'Chapter titles are required.';
      }
      if ( index !== 0 && (ch.start === 0 || ch.start === this.chapters[index - 1].start) ) {
        errors.start = 'Chapter start cannot be equal to another chapter start.';
      }
      if ( get(this, 'meta.length') && ch.start / 1000 >= get(this, 'meta.length') ) {
        errors.over = 'Chapter start cannot be bigger than the file length.';
      }
    });
    return Object.values(errors);
  }
}
