<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18.014" height="18.013" viewBox="0 0 18.014 18.013">
    <path d="M10.553 5.128h-.37l-2.539 5.677h2.662z" fill="currentColor"/>
    <path d="M16 0H2.014A2.014 2.014 0 000 2.013V16a2.014 2.014 0 002.014 2.013H16A2.014 2.014 0 0018.014 16V2.013A2.014 2.014 0 0016 0zm-5.857 15l.071-2.081H6.723L5.788 15H3.353l5.4-11.99h4.2L12.583 15z" fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconTextBackground'
  }
</script>