<template>
  <svg class="icon-text-center" xmlns="http://www.w3.org/2000/svg" width="14.748" height="12.234" viewBox="0 0 14.748 12.234">
    <path d="M.746 1.5h13.248a.75.75 0 000-1.5H.746a.75.75 0 000 1.5zM13.998 7.241H.746a.75.75 0 000 1.5h13.248a.75.75 0 000-1.5zM3.234 3.519a.75.75 0 000 1.5h8.28a.75.75 0 000-1.5zM11.514 10.734h-8.28a.75.75 0 000 1.5h8.28a.75.75 0 000-1.5z" fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconTextCenter'
  }
</script>
