var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: { id: "domain-settings-delete-modal", centered: "", size: "sm" },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("h5", { staticClass: "txt-body txt-primary" }, [
                _vm._v("Unpublish Domain?")
              ]),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "close",
                  attrs: { variant: "icon primary", size: "sm" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [
                  _c("svg", { attrs: { width: "24", viewBox: "0 0 24 24" } }, [
                    _c("use", { attrs: { "xlink:href": "#icon-cross" } })
                  ])
                ]
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "txt-heading-small txt-break-word" }, [
        _vm._v("Are you sure you want to unpublish "),
        _c("strong", [_vm._v("all your content")]),
        _vm._v(' from"' + _vm._s(_vm.domain) + '"?')
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c(
            "b-btn",
            {
              attrs: { variant: "secondary" },
              on: {
                click: function($event) {
                  return _vm.$refs.modal.hide()
                }
              }
            },
            [_vm._v("\n      Cancel\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-btn",
            { attrs: { variant: "primary" }, on: { click: _vm.unpublish } },
            [_vm._v("\n      Unpublish\n    ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }