<template>
  <svg id="Audio_Player" data-name="Audio Player" xmlns="http://www.w3.org/2000/svg" width="17.854" height="10.98" viewBox="0 0 17.854 10.98">
    <path id="Path_2505" data-name="Path 2505" d="M1365.041,345.7l-1.975-1.093a.928.928,0,0,0-.46-.119.915.915,0,0,0-.925.9v2.189a.871.871,0,0,0,.123.448.938.938,0,0,0,1.262.335l1.977-1.093a.9.9,0,0,0,.351-.34l.015-.027A.887.887,0,0,0,1365.041,345.7Z" transform="translate(-1354.67 -340.99)" fill="#293ad3"/>
    <path id="Path_2506" data-name="Path 2506" d="M1372.524,346.48v-2.145a3.343,3.343,0,0,0-3.344-3.345h-11.164a3.344,3.344,0,0,0-3.345,3.345v4.29a3.345,3.345,0,0,0,3.345,3.345h11.164a3.345,3.345,0,0,0,3.344-3.345V346.48Zm-3.345,3.99h-11.164a1.847,1.847,0,0,1-1.845-1.845V347.23h3.384a.75.75,0,0,0,0-1.5h-3.384v-1.4a1.848,1.848,0,0,1,1.845-1.846h11.164a1.849,1.849,0,0,1,1.845,1.846v1.4h-3.351a.75.75,0,0,0,0,1.5h3.351v1.395A1.848,1.848,0,0,1,1369.179,350.47Z" transform="translate(-1354.67 -340.99)" fill="#293ad3"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-embed-audio',
  };
</script>
