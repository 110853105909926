import Base from '@app2/models/Base';

export default class Prompt extends Base {
  static primaryKey = 'id';
  static entity = 'App\\Prompt';

  static apiConfig = {
    baseURL: '/api/internal/v2/prompts/',
    actions: {
      fetch: {
        url: '/',
        dataKey: 'data',
      },
      create(data) {
        return this.post(``, data);
      },
      destroy(hash, data) {
        return this.delete(`/${hash}`, data);
      },
      update(hash, data) {
        return this.put(`/${hash}`, data);
      },
    }
  };

  watchFields = [
    'title',
    'description',
    'prompt'
  ];

  static fields() {
    return {
      id: this.attr(null),
      hash: this.attr(null),
      title: this.attr(null),
      type: this.attr(null),
      connection_type: this.attr(null),
      description: this.attr(null),
      prompt: this.attr(null),
      ...super.fields(),
    };
  }
}
