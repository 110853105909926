var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19",
        height: "19",
        viewBox: "0 0 19 19"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M23704.924,23653a4.93,4.93,0,0,1-4.922-4.926v-9.152a4.93,4.93,0,0,1,4.922-4.922h9.152a4.935,4.935,0,0,1,4.926,4.922v9.152a4.934,4.934,0,0,1-4.926,4.926Zm-3.414-13.971v9.145a3.389,3.389,0,0,0,3.414,3.42h9.152a3.391,3.391,0,0,0,3.418-3.42v-9.145a3.394,3.394,0,0,0-3.418-3.426h-9.152A3.392,3.392,0,0,0,23701.51,23639.031Zm8.545.9a3.115,3.115,0,1,1,3.119,3.119A3.128,3.128,0,0,1,23710.055,23639.93Z",
          transform: "translate(-23700.002 -23634.002)",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }