var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 20 20"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(1 1)" } }, [
        _c("ellipse", {
          attrs: {
            cx: "9",
            cy: "9",
            rx: "9",
            ry: "9",
            transform: "translate(0 0)",
            fill: "#f0f0f0"
          }
        }),
        _c("g", { attrs: { transform: "translate(0.31 0.31)" } }, [
          _c("path", {
            attrs: {
              d: "M59.8,131.313a8.967,8.967,0,0,0-1.55,3.132h4.682Z",
              transform: "translate(-58.254 -128.102)",
              fill: "#0052b4"
            }
          }),
          _c("path", {
            attrs: {
              d: "M351.886,134.446a8.968,8.968,0,0,0-1.55-3.132l-3.132,3.132Z",
              transform: "translate(-334.506 -128.103)",
              fill: "#0052b4"
            }
          }),
          _c("path", {
            attrs: {
              d: "M58.254,309.427a8.968,8.968,0,0,0,1.55,3.132l3.132-3.132Z",
              transform: "translate(-58.254 -298.389)",
              fill: "#0052b4"
            }
          }),
          _c("path", {
            attrs: {
              d: "M312.558,59.8a8.967,8.967,0,0,0-3.132-1.55v4.682Z",
              transform: "translate(-298.388 -58.254)",
              fill: "#0052b4"
            }
          }),
          _c("path", {
            attrs: {
              d: "M131.313,350.336a8.968,8.968,0,0,0,3.132,1.55V347.2Z",
              transform: "translate(-128.102 -334.506)",
              fill: "#0052b4"
            }
          }),
          _c("path", {
            attrs: {
              d: "M134.445,58.254a8.969,8.969,0,0,0-3.132,1.55l3.132,3.132Z",
              transform: "translate(-128.102 -58.254)",
              fill: "#0052b4"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M309.427,351.886a8.968,8.968,0,0,0,3.132-1.55l-3.132-3.132v4.682Z",
              transform: "translate(-298.389 -334.506)",
              fill: "#0052b4"
            }
          }),
          _c("path", {
            attrs: {
              d: "M347.2,309.427l3.132,3.132a8.968,8.968,0,0,0,1.55-3.132Z",
              transform: "translate(-334.506 -298.389)",
              fill: "#0052b4"
            }
          })
        ]),
        _c("g", { attrs: { transform: "translate(0 0)" } }, [
          _c("path", {
            attrs: {
              d:
                "M69.123,59.025h-7.75v-7.75a9.081,9.081,0,0,0-2.348,0v7.75h-7.75a9.081,9.081,0,0,0,0,2.348h7.75v7.75a9.081,9.081,0,0,0,2.348,0v-7.75h7.75a9.081,9.081,0,0,0,0-2.348Z",
              transform: "translate(-51.199 -51.199)",
              fill: "#d80027"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M309.427,309.427h0l4.016,4.016q.277-.277.529-.578l-3.438-3.438Z",
              transform: "translate(-298.079 -298.079)",
              fill: "#d80027"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M115.2,309.427h0l-4.016,4.016q.277.277.578.529l3.438-3.438v-1.107Z",
              transform: "translate(-108.547 -298.079)",
              fill: "#d80027"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M103.691,115.2h0l-4.016-4.016q-.277.277-.529.578l3.438,3.438h1.107Z",
              transform: "translate(-97.039 -108.547)",
              fill: "#d80027"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M309.427,103.691h0l4.016-4.016q-.277-.277-.578-.529l-3.438,3.438Z",
              transform: "translate(-298.079 -97.039)",
              fill: "#d80027"
            }
          })
        ]),
        _c(
          "g",
          { attrs: { fill: "none", stroke: "#e4e4e4", "stroke-width": "1" } },
          [
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9", stroke: "none" }
            }),
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9.5", fill: "none" }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }