var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "audience-edit-row pr-3 py-3" }, [
    _c(
      "div",
      { staticClass: "slot-left fluid" },
      [
        _c("span", { staticClass: "handle" }, [
          _c("svg", { attrs: { width: "24", viewBox: "0 0 24 24" } }, [
            _c("use", { attrs: { "xlink:href": "#icon-drag-horizontal" } })
          ])
        ]),
        _vm._v(" "),
        _c("form-control-select", {
          attrs: {
            options: _vm.fields,
            identifier: false,
            "track-by": "title",
            "select-label": "label",
            full: "",
            small: ""
          },
          on: {
            select: function($event) {
              return _vm.state.title.$touch()
            }
          },
          model: {
            value: _vm.title,
            callback: function($$v) {
              _vm.title = $$v
            },
            expression: "title"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "slot-right" },
      [
        _c("form-control-text", {
          attrs: {
            icon: "",
            state: _vm.state.label.$error ? false : null,
            error: _vm.error,
            disabled: !_vm.title || _vm.disabled,
            size: "sm"
          },
          on: {
            change: function($event) {
              return _vm.state.label.$touch()
            }
          },
          model: {
            value: _vm.field.label,
            callback: function($$v) {
              _vm.$set(_vm.field, "label", $$v)
            },
            expression: "field.label"
          }
        }),
        _vm._v(" "),
        _c(
          "b-button",
          {
            attrs: { variant: "icon ml-2", size: "sm", disabled: _vm.disabled },
            on: {
              click: function($event) {
                return _vm.$emit("delete")
              }
            }
          },
          [
            _c(
              "svg",
              {
                staticClass: "icon-sm",
                attrs: { width: "24", viewBox: "0 0 24 24" }
              },
              [_c("use", { attrs: { "xlink:href": "#icon-cross" } })]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }