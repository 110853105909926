<template>
  <div>
    <b-btn ref="upgrade-btn" class="all-events" variant="primary" size="sm" @click.stop.prevent="redirect">
      <svg class="icon-sm" viewBox="0 0 24 24"><use xlink:href="#icon-lock-locked"></use></svg>
    </b-btn>
    <b-tooltip
      :target="() => $refs['upgrade-btn']"
      placement="top"
      :boundary="customBoundary ? customBoundary : 'window'"
      triggers="hover"
      custom-class="tooltip-no-wrap"
      :container="container"
    >
      <button class="tooltip-cta txt-white" @click.stop="redirect">
        <span class="tooltip-cta-inner">{{ tooltip }}</span>
        <span class="btn-icon ml-2 p-0">
          <svg class="icon-regular" viewBox="0 0 24 24"><use xlink:href="#icon-arrow-right"></use></svg>
        </span>
      </button>
    </b-tooltip>
  </div>
</template>

<script>
import rootRouter from "@app2/router";
import rootVue from "@app2/app";

export default {
  name: 'UpgradeButton',
  props: {
    tooltip: {
      type: String,
      default: 'Upgrade your plan to use this feature.'
    },
    customBoundary: {
      type: String,
      default: ''
    },
    container: {
      default: 'body'
    }
  },
  methods: {
    async redirect() {
      this.$emit('close')
      await rootRouter.push({name: 'AccountSubscription'});
    }
  }
}
</script>