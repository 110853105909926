<template>
  <svg class="icon-plus" height="21.082" viewBox="0 0 21.082 21.082" width="21.082" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(2 10.541) rotate(-45)">
      <line fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" transform="translate(0 0)" x2="12.079" y1="12.079"/>
      <line fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" transform="translate(0 0)" x2="12.079" y2="12.079"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'IconPlus',
  };
</script>
