<template>
  <svg class="icon-text-font" xmlns="http://www.w3.org/2000/svg" width="16.983" height="16.944" viewBox="0 0 16.983 16.944">
    <path d="M16.264,10.206a2.461,2.461,0,0,0-3.481-3.479L7.966,11.545a.734.734,0,0,0-.164.244L6.1,15.907a.75.75,0,0,0,.979.98l4.121-1.7a.751.751,0,0,0,.245-.163l4.818-4.818M10.49,13.858l-2.314.954.955-2.31,4.713-4.714A.96.96,0,0,1,15.2,9.146Z" fill="currentColor"/><path d="M10.363,7.391,10.591,0H5.957L0,13.237H2.686l1.032-2.3h3.1ZM7.534,2.335h.409L7.67,8.6H4.731Z" fill="currentColor"/>
  </svg>
</template>
<script>
  export default {
    name: 'IconTextFont'
  }
</script>
