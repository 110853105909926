<template>
  <form-control-select
    v-model="header"
    :options="columnOptions"
    track-by="key"
    select-label="header"
    :identifier="false"
    small
    full
  />
</template>

<script>
  import FormControlSelect from "@app2/core/Components/Common/Forms/Select";
  import {set} from "@app2/utils/helpers";

  export default {
    name: 'ImportColumnField',
    props: {
      colHeader: Object,
      colIndex: Number
    },
    components: {
      FormControlSelect
    },
    computed: {
      header: {
        get() {
          const matchedColumn = this.columnOptions.find(co => co.header === this.colHeader.name)

          if(matchedColumn) this.$store.commit('v2/audience/SET_MAP_COLUMN', {column: matchedColumn.header, index: this.colIndex})

          return matchedColumn ? matchedColumn : {key: 'default', header: 'Do not import'}
        },
        set(val) {
          // set(this.colHeader, 'key', val.key)
          set(this.colHeader, 'name', val.header)
          this.$store.commit('v2/audience/SET_MAP_COLUMN', {column: val.header, index: this.colIndex, key: val.key})
        }
      },
      columnOptions() {
        return this.$store.getters['v2/audience/importColumns']
      },
      columnHeaders() {
        return this.$store.getters['v2/audience/columnHeaders']
      }
    }
  }
</script>
