var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "audience-header top-sticky-blur",
      class: { "no-bg": _vm.noAudience }
    },
    [
      _c(
        "div",
        { staticClass: "wrapper-lg" },
        [
          _c("div", { staticClass: "audience-header-inner py-3" }, [
            _c("h1", { staticClass: "txt-title txt-bold mb-0" }, [
              _vm._v("Audience")
            ]),
            _vm._v(" "),
            !_vm.noAudience
              ? _c(
                  "div",
                  { staticClass: "slot-right" },
                  [
                    _c(
                      "div",
                      { attrs: { id: "save-as-segment" } },
                      [
                        _vm.isTemporary && _vm.showFilters
                          ? _c(
                              "b-btn",
                              {
                                staticClass: "mx-3",
                                attrs: {
                                  variant: "icon",
                                  disabled: !_vm.$can("segmenting")
                                },
                                on: { click: _vm.saveSegment }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "mr-2",
                                    attrs: { width: "24", viewBox: "0 0 24 24" }
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href": "#icon-user-multiple-add"
                                      }
                                    })
                                  ]
                                ),
                                _vm._v("Save as segment")
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.$can("segmenting")
                      ? _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: "save-as-segment",
                              placement: "top",
                              triggers: "hover"
                            }
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "tooltip-cta txt-white",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.redirect($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "tooltip-cta-inner" },
                                  [
                                    _vm._v(
                                      "Upgrade your plan to use this feature."
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "btn-icon ml-2 p-0" },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "icon-regular",
                                        attrs: { viewBox: "0 0 24 24" }
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href": "#icon-arrow-right"
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-btn",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.bottom",
                            value: _vm.hoverText,
                            expression: "hoverText",
                            modifiers: { hover: true, bottom: true }
                          }
                        ],
                        attrs: {
                          variant: "filter px-2",
                          disabled: _vm.loading && false
                        },
                        on: { click: _vm.openSegmentDialog }
                      },
                      [
                        _vm.showFilters
                          ? [
                              _vm.isTemporary
                                ? [
                                    _c("span", { staticClass: "count mr-2" }, [
                                      _vm._v(
                                        _vm._s(_vm.segment.conditionsCount)
                                      )
                                    ]),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.segment.conditionsCount === 1
                                            ? "Filter"
                                            : "Filters"
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                : [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "btn-left ml-1",
                                        attrs: {
                                          width: "24",
                                          viewBox: "0 0 24 24"
                                        }
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href": "#icon-filter"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("b", { staticClass: "ml-1" }, [
                                      _vm._v(_vm._s(_vm.segment.title))
                                    ]),
                                    _vm._v(" "),
                                    _vm.segment.audiences.length
                                      ? _c(
                                          "b-avatar-group",
                                          {
                                            staticClass: "ml-2",
                                            attrs: { size: "32px", rounded: "" }
                                          },
                                          _vm._l(
                                            _vm.segment.audiences,
                                            function(a, index) {
                                              return _c("b-avatar", {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover",
                                                    modifiers: { hover: true }
                                                  }
                                                ],
                                                key: index,
                                                staticStyle: {
                                                  "min-width": "32px"
                                                },
                                                attrs: {
                                                  title: a.name,
                                                  src: _vm._f("avatar")(a)
                                                }
                                              })
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e()
                                  ],
                              _vm._v(" "),
                              _c(
                                "b-btn",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    variant: "icon primary",
                                    size: "sm"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.$emit("reset")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "icon-sm",
                                      attrs: {
                                        width: "24",
                                        viewBox: "0 0 24 24"
                                      }
                                    },
                                    [
                                      _c("use", {
                                        attrs: { "xlink:href": "#icon-cross" }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          : _c(
                              "svg",
                              { attrs: { width: "24", viewBox: "0 0 24 24" } },
                              [
                                _c("use", {
                                  attrs: { "xlink:href": "#icon-filter" }
                                })
                              ]
                            )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("hr", { staticClass: "vertical" }),
                    _vm._v(" "),
                    _c(
                      "b-btn",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        attrs: {
                          id: "add-audience",
                          variant: "primary",
                          title: "Add Person"
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          { attrs: { width: "24", viewBox: "0 0 24 24" } },
                          [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-user-add" }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-popover",
                      {
                        ref: "dd",
                        attrs: {
                          placement: "leftbottom",
                          target: "add-audience",
                          triggers: "click blur"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "dropdown-cont sm" },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.openPersonDialog($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "btn-left icon-sm",
                                    attrs: { width: "24", viewBox: "0 0 24 24" }
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href": "#icon-user-single"
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(
                                  "\n              Add Person\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("b-dropdown-divider"),
                            _vm._v(" "),
                            _c(
                              "b-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.openImportDialog($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "btn-left icon-sm",
                                    attrs: { width: "24", viewBox: "0 0 24 24" }
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href": "#icon-user-multiple"
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(
                                  "\n              Import Audience\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }