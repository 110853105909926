var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-audience-finishes",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.044",
        height: "16.252",
        viewBox: "0 0 16.044 16.252"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M8.022 0a8.127 8.127 0 108.022 8.126A8.074 8.074 0 008.022 0zm4.625 12.811a6.482 6.482 0 01-9.251 0 6.688 6.688 0 010-9.37 6.482 6.482 0 019.251 0 6.685 6.685 0 010 9.37z",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M10.443 5.613L7.007 9.048 5.601 7.641A.751.751 0 004.54 8.703l1.937 1.937a.75.75 0 001.061 0l3.966-3.966a.75.75 0 00-1.061-1.06z",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }