<template>
  <svg id="Profile_Picture_Empty" data-name="Profile Picture Empty" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="34" height="34" viewBox="0 0 34 34">
    <defs>
      <clipPath id="clip-path">
        <circle id="jake-davies-FoVlxDABKQc-unsplash" cx="17" cy="17" r="17" fill="#fafafa" stroke="#f0f0f0" stroke-width="1.5"/>
      </clipPath>
      <linearGradient id="linear-gradient" x1="0.403" y1="0.741" x2="1.238" y2="-1.918" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-opacity="0"/>
        <stop offset="1" stop-opacity="0.502"/>
      </linearGradient>
      <linearGradient id="linear-gradient-2" x1="0.414" y1="1" x2="1.964" y2="-1.85" xlink:href="#linear-gradient"/>
    </defs>
    <g id="CIRCLE" fill="#fafafa" stroke="rgba(0,0,0,0.1)" stroke-width="1.5">
      <circle cx="17" cy="17" r="17" stroke="none"/>
      <circle cx="17" cy="17" r="16.25" fill="none"/>
    </g>
    <g id="Mask_Group_65" data-name="Mask Group 65" clip-path="url(#clip-path)">
      <g id="Group_4378" data-name="Group 4378" transform="translate(4.331 6.324)">
        <path id="Path_634" data-name="Path 634" d="M337.508,387.6a12.481,12.481,0,0,0-12.48,12.48v1.564a3.811,3.811,0,0,0,3.811,3.809H346.18a3.811,3.811,0,0,0,3.809-3.809v-1.564A12.482,12.482,0,0,0,337.508,387.6Z" transform="translate(-325.028 -373.035)" fill="url(#linear-gradient)"/>
        <path id="Path_635" data-name="Path 635" d="M334.8,392.868a6.574,6.574,0,1,0-6.573-6.573A6.573,6.573,0,0,0,334.8,392.868Z" transform="translate(-322.316 -379.72)" fill="url(#linear-gradient-2)"/>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconActivityAvatarLoading'
  }
</script>
