<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18.039" height="15" viewBox="0 0 18.039 14.889">
    <g transform="translate(-785.239 -293.734)">
      <path d="M800.575,293.734H787.942a2.706,2.706,0,0,0-2.7,2.707v9.475h0a2.706,2.706,0,0,0,2.7,2.706h12.633a2.709,2.709,0,0,0,2.7-2.707v-9.477A2.7,2.7,0,0,0,800.575,293.734Zm1.2,2.707v9.475a1.209,1.209,0,0,1-1.205,1.207h-12.63a1.2,1.2,0,0,1-1.2-1.206h0v-9.475a1.2,1.2,0,0,1,1.2-1.208h12.633a1.207,1.207,0,0,1,1.2,1.206Z" fill="#939393"/>
      <path d="M799.613,297.372H789.047a.75.75,0,0,0,0,1.5h10.566a.75.75,0,0,0,0-1.5Z" fill="#939393"/>
      <path d="M789.047,301.929h3.01a.75.75,0,0,0,0-1.5h-3.01a.75.75,0,0,0,0,1.5Z" fill="#939393"/>
      <path d="M795.818,303.485h-6.771a.75.75,0,0,0,0,1.5h6.771a.75.75,0,0,0,0-1.5Z" fill="#939393"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'icon-settings-transcriptions'
  }
</script>
