<template>
  <svg class="icon-people" xmlns="http://www.w3.org/2000/svg" width="17.576" height="17.229" viewBox="0 0 17.576 17.229">
    <g id="Group_9115" data-name="Group 9115" transform="translate(-1777.741 -885.353)">
      <path id="Path_3514" data-name="Path 3514" d="M1783.741,893.986c-.194,0-.39.01-.587.028a5.836,5.836,0,0,0-3.89,2.055,6.4,6.4,0,0,0-1.523,4.143v.872a1.5,1.5,0,0,0,1.5,1.5h9a1.5,1.5,0,0,0,1.5-1.5v-1.1A6,6,0,0,0,1783.741,893.986Zm4.5,7.1h-9v-.871a4.9,4.9,0,0,1,1.164-3.17,4.333,4.333,0,0,1,2.892-1.534c.15-.015.3-.022.444-.022a4.5,4.5,0,0,1,4.5,4.5Z" fill="#939393"/>
      <path id="Path_3515" data-name="Path 3515" d="M1783.739,892.462a3.554,3.554,0,1,0,0-7.109h0a3.554,3.554,0,0,0,0,7.109Zm0-5.609a2.054,2.054,0,0,1,0,4.109h0a2.054,2.054,0,1,1,0-4.109Z" fill="#939393"/>
      <path id="Path_3516" data-name="Path 3516" d="M1794.128,894.475a4.284,4.284,0,0,0-2.986-1.451v0h-.024c-.078,0-.149-.006-.2-.006a4.386,4.386,0,0,0-2.517.791.75.75,0,1,0,.859,1.23h0a2.882,2.882,0,0,1,1.659-.521l.111,0h.024a2.785,2.785,0,0,1,1.958.955,3.2,3.2,0,0,1,.8,2.022h-2.7a.75.75,0,0,0,0,1.5h2.811a1.4,1.4,0,0,0,1.4-1.4A4.7,4.7,0,0,0,1794.128,894.475Z" fill="#939393"/>
      <path id="Path_3517" data-name="Path 3517" d="M1790.915,887.317a2.394,2.394,0,1,0,2.394,2.395,2.4,2.4,0,0,0-2.394-2.395m0,1.5a.895.895,0,1,1-.9.895A.9.9,0,0,1,1790.916,888.817Z" fill="#939393"/>
    </g>
  </svg>

</template>
<script>
  export default {
    name: 'IconPeople'
  }
</script>
