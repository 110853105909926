<template>
  <div class="audience-segment-dialogs">
    <create-dialog
      id="segment-create-dialog"
      ref="create"
      title="Name your Segment"
      placeholder="Enter Segment Name"
      button-label="Save Segment"
      @accept="createSegment"
      v-model="create.title"
      :key="`create-${create.key}`"
      :processing="create.processing" />
    <rename-dialog
      id="segment-rename-dialog"
      ref="rename"
      title="Rename your Segment"
      v-model="rename.title"
      @accept="renameSegment"
      :key="`rename-${rename.key}`"
      :processing="rename.processing"
      placeholder="Enter Segment Name"
    />
    <duplicate-dialog
      id="segment-duplicate-dialog"
      ref="duplicate"
      title="Duplicate Segment"
      v-model="duplicate.title"
      @accept="duplicateSegment"
      :key="`duplicate-${duplicate.key}`"
      :processing="duplicate.processing"
      placeholder="Enter Segment Name"
      :alert="false"
    />
    <delete-dialog
      v-if="destroy.segment"
      id="segment-delete-dialog"
      title="Delete segment?"
      @accept="deleteSegment"
      :disabled="!!hubs.length"
      :processing="destroy.processing"
      :key="`destroy-${destroy.key}`">
      <p v-if="canDelete" class="mt-3 mb-3 txt-heading-small">You are about to permanently delete the <b>{{ destroy.segment.title }}</b> segment. Are you sure?</p>
      <p v-else class="mb-3 txt-heading-small"> <b>{{ destroy.segment.title }}</b> segment is currently used in the Hubs below. You will need to remove it from these Hubs before deleting.</p>
      <b-btn-group v-if="hubs">
        <b-btn @click="openHub(hub.hash)" v-for="(hub, key) in hubs" :key="key" variant="tag variant-red" size="sm" block>
          <svg viewBox="0 0 24 24" class="btn-left mr-2"><use xlink:href="#icon-hub"></use></svg>
          <span class="btn-tag-label mr-auto" v-b-tooltip.hover :title="hub.title.length > 34 ? hub.title : ''">{{ hub.title }}</span>
          <svg viewBox="0 0 24 24" class="btn-right only-hover ml-2"><use xlink:href="#icon-share-external"></use></svg>
        </b-btn>
      </b-btn-group>
    </delete-dialog>
    <exit-dialog
      id="close-segment-dialog"
      subtitle="Save changes?"
      title="Save your changes before closing?"
      description="Unsaved data will be lost."
      accept-label="Save"
      reject-label="Don't Save"
      @reject="rejectSegmentClose"
      @accept="acceptSegmentClose"
      :processing="exitProcessing" />
  </div>
</template>
<script>
  import DeleteDialog from "@app2/core/Components/Common/Modals/DeleteDialog";
  import CreateDialog from "@app2/core/Components/Common/Modals/CreateDialog";
  import RenameDialog from "@app2/core/Components/Common/Modals/RenameDialog";
  import DuplicateDialog from "@app2/core/Components/Common/Modals/DuplicateDialog";
  import eventBus from "@app2/core/eventBus";
  import Segment from "@app2/models/Segment";
  import ExitDialog from "@app2/core/Components/Common/Modals/ExitDialog";
  import {set} from "@app2/utils/helpers";
  import get from "lodash/get";
  import {createNamespacedHelpers} from "vuex";
  import axios from "@app2/api/axios";
  import router from "@app2/modules/Audience/Segment/segment.router";
  const { mapActions: mapSegmentActions } = createNamespacedHelpers('v2/audience/segment');

  export default {
    name: 'AudienceSegmentDialogs',
    data() {
      return {
        loading: true,
        create: {
          segment: false,
          processing: false,
          key: 0,
          title: '',
        },
        rename: {
          segment: null,
          processing: false,
          title: '',
          key: 0
        },
        duplicate: {
          segment: null,
          processing: false,
          title: '',
          key: 0
        },
        destroy: {
          segment: null,
          processing: false,
          key: 0
        },
        exitProcessing: false,
        exitAction: null,
      }
    },
    computed: {
      exitRoute() {
        return this.$store.getters['v2/hubs/editor/exitRoute'];
      },
      canDelete() {
        return get(this.destroy, 'segment.permissions.delete', true);
      },
      hubs() {
        let hubs = get(this.destroy, 'segment.hubs', [])
        return hubs.filter((hub, index) => hubs.map(h => h.id).indexOf(hub.id) === index);
      },
      segment() {
        return Segment.find(router.currentRoute.params.hash);
      },
    },
    components: {
      ExitDialog,
      DeleteDialog,
      CreateDialog,
      RenameDialog,
      DuplicateDialog,
    },
    mounted() {
      eventBus.$on('segmentToRename', segment => {
        this.rename.segment = segment;
        this.rename.title = segment.title;
        this.$nextTick(() => {
          this.$bvModal.show('segment-rename-dialog');
        });
      });
      eventBus.$on('segmentToCreate', segment => {
        this.create.segment = segment;
        this.$nextTick(() => {
          this.$bvModal.show('segment-create-dialog');
        });
      });
      eventBus.$on('segmentToDuplicate', segment => {
        this.duplicate.segment = JSON.parse(JSON.stringify(segment));
        this.duplicate.title = segment.title + ' - Copy';
        this.$nextTick(() => {
          this.$bvModal.show('segment-duplicate-dialog');
        });
      });
      eventBus.$on('segmentToDelete', segment => {
        this.destroy.segment = JSON.parse(JSON.stringify(segment));
        this.$nextTick(() => {
          this.$bvModal.show('segment-delete-dialog');
        });
      });
      eventBus.$on('segmentToExit', action => {
        this.exitAction = action;
        this.$nextTick(() => {
          this.$bvModal.show('close-segment-dialog');
        });
      });
    },
    methods: {
      ...mapSegmentActions({
        save: 'save',
      }),
      async createSegment() {
        this.create.processing = true;
        set(this.create.segment, 'title', this.create.title);
        await this.save('create-00');
        this.create.processing = false;
        this.$bvModal.hide('segment-create-dialog');
        this.create.key++;
        this.create.title = '';
      },
      async renameSegment(title) {
        try {
          this.rename.processing = true;
          set(this.rename.segment, 'title', title);
          const { data } = await axios.put(`/api/segments/${this.rename.segment.hash}`, this.rename.segment.toSaveProperties);
          await Segment.insertOrUpdate({data});
        } catch {

        } finally {
          this.rename.processing = false;
          this.$bvModal.hide('segment-rename-dialog');
          this.rename.key++;
          this.rename.title = '';
        }
      },
      async duplicateSegment(title) {
        try {
          this.duplicate.processing = true;

        } catch {

        } finally {
          this.duplicate.processing = false;
          this.$bvModal.hide('segment-duplicate-dialog');
          this.duplicate.key++;
          this.duplicate.title = '';
        }
      },
      async deleteSegment() {
        try {
          this.destroy.processing = true;
          let hash = this.destroy.segment.hash;
          await Segment.api().delete(hash);
          await Segment.delete(this.destroy.segment.hash)
          this.$toasted.show(`<p class="txt-body mb-1">Segment deleted!</p>`, {
            duration: 6000,
            action: {
              text: 'Undo',
              onClick: async (e, toastObject) => {
                toastObject.goAway(0);
                await Segment.api().delete(hash, { params: { restore: true } });
                await Segment.insert({ data: this.destroy.segment });
                this.$toasted.show(`Segment restored`, { type: 'info' });
              },
            },
          });
        } catch (e) {
          this.$toasted.show('Could not delete segment, something went wrong. :(', { type: 'error' });
          throw e;
        } finally {
          this.destroy.processing = false;
          this.$bvModal.hide('segment-delete-dialog');
          this.destroy.key++;
        }
      },
      openHub(hash) {
        const routeData = this.$router.resolve({name: 'hub', params: { hash }});
        window.open(routeData.href, '_blank');
      },
      rejectSegmentClose() {
        this.segment.resetToInitial();
        if ( this.exitAction ) this.exitAction();
        this.$nextTick(() => {
          this.$bvModal.hide('close-segment-dialog');
          this.exitAction = null;
        });
      },
      async acceptSegmentClose() {
        this.exitProcessing = true;
        await this.save();
        if ( this.exitAction ) this.exitAction();
        this.$nextTick(() => {
          this.$bvModal.hide('close-segment-dialog');
          this.exitAction = null;
        });
      },
    }
  }
</script>
