var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "audience-slide-in-template",
    {
      attrs: {
        "back-label": "Bulk Actions",
        "back-route": { name: "BulkIndex" },
        title: _vm.$route.params.title
      }
    },
    [
      _c("selected-audience"),
      _vm._v(" "),
      _c("hr", { staticClass: "mt-0 mb-3" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-widget p-4" },
        [
          _c("form-control-select", {
            attrs: {
              placeholder: "Search audience tags...",
              "option-icon": "tag",
              "custom-class": "txt-muted",
              "track-by": "name",
              "select-label": "name",
              "tag-icon": "tag",
              "selected-title":
                _vm.$route.name === "TagsAttach"
                  ? "Add the following"
                  : "Remove the following",
              label:
                _vm.$route.name === "TagsAttach"
                  ? "Add Audience Tags"
                  : "Remove Audience Tags",
              options: _vm.tagOptions,
              type: "tag" + (_vm.tagList.length > 1 ? "s" : ""),
              "close-on-select": false,
              "search-visible": true,
              taggable: _vm.$route.name === "TagsAttach" ? true : false,
              multiple: "",
              infinite: "",
              "hide-selected": "",
              blockKeys: ["Delete"]
            },
            on: {
              "remove-item": _vm.removeTag,
              select: _vm.selectTag,
              tag: _vm.addTag,
              infinite: _vm.tagsInfiniteHandler
            },
            model: {
              value: _vm.tagList,
              callback: function($$v) {
                _vm.tagList = $$v
              },
              expression: "tagList"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-library-cta p-3" },
        [
          _c(
            "b-btn",
            {
              attrs: {
                block: "",
                variant: "primary",
                size: "lg",
                disabled: _vm.isSaving
              },
              on: { click: _vm.confirmTags }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "btn-left",
                  attrs: { width: "24", viewBox: "0 0 24 24" }
                },
                [_c("use", { attrs: { "xlink:href": "#icon-status-success" } })]
              ),
              _vm._v("\n      Apply Edits\n    ")
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }