var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-segment-save",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19.921",
        height: "16.983",
        viewBox: "0 0 19.921 16.983"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M224.649,300.7a3.168,3.168,0,1,0-3.168-3.168A3.168,3.168,0,0,0,224.649,300.7Zm0-4.837a1.669,1.669,0,1,1-1.668,1.669A1.671,1.671,0,0,1,224.649,295.866Z",
          transform: "translate(-218.388 -294.367)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M224.706,301.579l-.008,0a.091.091,0,0,1-.016,0h-.034v0a.758.758,0,0,0-.113.008.628.628,0,0,1,.112-.013,6.259,6.259,0,0,0-6.259,6.262v1.24h0a2.271,2.271,0,0,0,2.272,2.271h7.979a2.275,2.275,0,0,0,2.271-2.273v-1.242A6.259,6.259,0,0,0,224.706,301.579Zm.147,1.465.019-.005-.019.006Zm-.463-1.424a.858.858,0,0,1,.083-.024c-.042.01-.076.022-.084.024Zm5.02,6.217v1.24a.776.776,0,0,1-.773.773H220.66a.774.774,0,0,1-.772-.772h0v-1.24a4.759,4.759,0,0,1,4.753-4.762h.008a4.761,4.761,0,0,1,4.761,4.76Z",
          transform: "translate(-218.388 -294.367)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M232.593,299.315a2.474,2.474,0,1,0-2.474-2.474A2.475,2.475,0,0,0,232.593,299.315Zm0-3.449a.975.975,0,1,1-.975.975A.977.977,0,0,1,232.593,295.866Z",
          transform: "translate(-218.388 -294.367)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M230.654,302.279h0a3.263,3.263,0,0,1,1.938-.634h0a3.26,3.26,0,0,1,2.022.7.75.75,0,1,0,.925-1.18,4.76,4.76,0,0,0-2.947-1.015h0a4.765,4.765,0,0,0-2.826.923.75.75,0,1,0,.888,1.21Z",
          transform: "translate(-218.388 -294.367)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M237.559,305.729h-1.733V304a.75.75,0,0,0-1.5,0v1.733h-1.733a.75.75,0,0,0,0,1.5h1.733v1.732a.75.75,0,1,0,1.5,0v-1.732h1.733a.75.75,0,0,0,0-1.5Z",
          transform: "translate(-218.388 -294.367)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }