var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-google",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "19.395",
        height: "20",
        viewBox: "0 0 19.395 20"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M21.372,11.869A11.892,11.892,0,0,0,21.2,9.837H11.87v3.851h5.344a4.632,4.632,0,0,1-1.977,3.039v2.5h3.188A9.819,9.819,0,0,0,21.372,11.869Z",
          transform: "translate(-1.977 -1.639)",
          fill: "#4285f4"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M10.1,22.383a9.411,9.411,0,0,0,6.558-2.414l-3.188-2.5A5.971,5.971,0,0,1,4.551,14.3H1.261v2.576A9.883,9.883,0,0,0,10.1,22.383Z",
          transform: "translate(-0.209 -2.383)",
          fill: "#34a853"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M4.337,13.018a6.051,6.051,0,0,1,0-3.829V6.614H1.05a10.1,10.1,0,0,0,0,8.98Z",
          transform: "translate(0.002 -1.102)",
          fill: "#fbbc04"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M10.1,3.957a5.345,5.345,0,0,1,3.8,1.5L16.721,2.6A9.452,9.452,0,0,0,10.1,0,9.88,9.88,0,0,0,1.261,5.511L4.547,8.087A5.925,5.925,0,0,1,10.1,3.957Z",
          transform: "translate(-0.209 0.001)",
          fill: "#ea4335"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }