<template>
  <svg class="icon-back" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <path  d="M20 0A20 20 0 1 1 0 20 20 20 0 0 1 20 0z" class="cl-1 no-fill" fill="#FAFAFA"/>
    <g transform="translate(10.978 12.784)">
      <path d="M16.739 6.481H2.585l5.2-5.2A.75.75 0 0 0 6.724.221L.239 6.705a.75.75 0 0 0 0 1.061l6.485 6.485a.75.75 0 1 0 1.061-1.06L2.579 7.985h14.157a.75.75 0 0 0 0-1.5z" class="cl-2" fill="#000000"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'IconBack',
  };
</script>
