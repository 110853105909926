var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "1041",
        height: "584",
        viewBox: "0 0 1041 584"
      }
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "a",
                x1: ".439",
                y1: ".311",
                x2: "1.999",
                y2: "-.267",
                gradientUnits: "objectBoundingBox"
              }
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-opacity": "0" } }),
              _c("stop", { attrs: { offset: "1", "stop-opacity": ".502" } })
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "c",
                x1: ".295",
                y1: ".874",
                x2: "1.677",
                y2: "-1.649",
                gradientUnits: "objectBoundingBox"
              }
            },
            [
              _c("stop", { attrs: { offset: ".076", "stop-opacity": "0" } }),
              _c("stop", { attrs: { offset: "1", "stop-opacity": ".502" } })
            ],
            1
          ),
          _c("linearGradient", {
            attrs: {
              id: "d",
              x1: ".622",
              y1: ".181",
              x2: "2.258",
              y2: "-.163",
              "xlink:href": "#a"
            }
          }),
          _c("clipPath", { attrs: { id: "b" } }, [
            _c("path", {
              attrs: {
                "data-name": "Rectangle 1035",
                d: "M10 0h1031v584H10a10 10 0 01-10-10V10A10 10 0 0110 0z",
                transform: "translate(.194)",
                fill: "#e4e4e4"
              }
            })
          ])
        ],
        1
      ),
      _c("g", { attrs: { "data-name": "Group 5177" } }, [
        _c("path", { attrs: { fill: "#e4e4e4", d: "M0 1h1041v583H0z" } }),
        _c(
          "g",
          {
            attrs: {
              "data-name": "Mask Group 70",
              transform: "translate(-.194)",
              opacity: ".3",
              "clip-path": "url(#b)"
            }
          },
          [
            _c("rect", {
              attrs: {
                "data-name": "Rectangle 1033",
                width: "534.299",
                height: "911.737",
                rx: "133",
                transform: "rotate(-45 492.57975718 414.94478403)",
                fill: "url(#a)"
              }
            }),
            _c("path", {
              attrs: {
                "data-name": "Path 1331",
                d:
                  "M887.369 1047.132a44.784 44.784 0 1144.784 44.784 44.785 44.785 0 01-44.784-44.784z",
                transform: "translate(-60.677 -927.699)",
                fill: "url(#c)"
              }
            }),
            _c("rect", {
              attrs: {
                "data-name": "Rectangle 1034",
                width: "534.299",
                height: "860.73",
                rx: "108",
                transform: "rotate(-45 915.92770353 -52.44467696)",
                fill: "url(#d)"
              }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }