var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-embed",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20.025",
        height: "17.91",
        viewBox: "0 0 20.025 17.91"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M481.791,437.036l-4.51,4.309a.75.75,0,0,0-.023,1.061l4.313,4.512a.75.75,0,0,0,1.084-1.036l-3.8-3.971,3.968-3.791a.75.75,0,1,0-1.037-1.084Z",
          transform: "translate(-477.05 -433.379)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M491.293,438.354l3.971,3.79-3.794,3.97a.75.75,0,1,0,1.084,1.037l4.313-4.513a.751.751,0,0,0-.025-1.061l-4.513-4.308a.75.75,0,1,0-1.036,1.085Z",
          transform: "translate(-477.05 -433.379)",
          fill: "#939393"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M486.868,450.624l1.875-16.409a.75.75,0,0,0-1.491-.171l-1.875,16.409a.75.75,0,1,0,1.491.171",
          transform: "translate(-477.05 -433.379)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }