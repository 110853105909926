var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-appearance",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "25",
        height: "25",
        viewBox: "0 0 25 25"
      }
    },
    [
      _c("g", { attrs: { id: "Apperance_Icon" } }, [
        _c(
          "g",
          {
            attrs: {
              id: "Group_1636",
              "data-name": "Group 1636",
              transform: "translate(1 1)"
            }
          },
          [
            _c("path", {
              attrs: {
                id: "Path_720",
                "data-name": "Path 720",
                d:
                  "M595.067,410.314a.744.744,0,0,0-.217-.526l-7.261-7.261a.744.744,0,0,0-1.052,0l-7.88,7.881a3.7,3.7,0,0,0-.216,5.019l-4.679,4.679h0a2.478,2.478,0,0,0,3.5,3.507l4.679-4.678a3.7,3.7,0,0,0,5.023-.213l2.9-2.9.006,0,0-.006,4.969-4.968A.745.745,0,0,0,595.067,410.314ZM576.215,422.56a.991.991,0,1,1-1.4-1.4l0,0,4.633-4.632,1.4,1.4Zm9.7-4.892a2.25,2.25,0,0,1-3.18,0l-.122.122h0l.122-.121-3.029-3.029a2.246,2.246,0,0,1,0-3.18l2.38-2.381,6.209,6.209Zm3.432-3.433-6.21-6.209,3.922-3.921,2.665,2.665-2.784,2.783A.744.744,0,0,0,588,410.6l2.784-2.783.639.639-1.634,1.634a.744.744,0,0,0,1.053,1.052l1.633-1.634.8.8Z",
                transform: "translate(-572.518 -401.793)",
                fill: "#939393"
              }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }