var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-filters",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "15",
        height: "15",
        viewBox: "0 0 14.92 14.97"
      }
    },
    [
      _c("g", [
        _c("g", [
          _c("line", {
            staticClass: "cls-line",
            attrs: { x1: "14.17", y1: "7.49", x2: "0.75", y2: "7.49" }
          }),
          _c("line", {
            staticClass: "cls-line",
            attrs: { x1: "0.75", y1: "12.19", x2: "14.17", y2: "12.19" }
          }),
          _c("line", {
            staticClass: "cls-line",
            attrs: { x1: "0.75", y1: "2.79", x2: "14.17", y2: "2.79" }
          }),
          _c("circle", {
            staticClass: "cls-1",
            attrs: { cx: "12.14", cy: "7.49", r: "2.04" }
          }),
          _c("circle", {
            staticClass: "cls-1",
            attrs: { cx: "2.79", cy: "12.19", r: "2.04" }
          }),
          _c("circle", {
            staticClass: "cls-1",
            attrs: { cx: "2.79", cy: "2.79", r: "2.04" }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }