import curatePlans from "@app2/modules/AccountSettings/Pages/Routes/Subscription/Helpers/curatePlans"
const namespaced = true;
import { get } from "lodash"

const state = {
  campaign: null,
};

const mutations = {
  SET_CAMPAIGN(state, payload) {
    state.campaign = payload;
  },
};

const actions = {

};

const getters = {
    plans: state => {
        if ( !state.campaign?.campaign_items ) return [];
        return curatePlans(state.campaign.campaign_items.map(item => {
          const itemPrice = JSON.parse(JSON.stringify(item.chargebee_item_price));
          const planInformation = get(itemPrice, 'metadata.plan_information', {});
          if ( planInformation.color ) delete planInformation.color;
          return itemPrice;
        }), {custom: true});
    },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
