export default [
  {
    path: '/apps',
    name: 'apps',
    component: () => import(/* webpackChunkName: "apps-v2" */ '@app2/modules/Apps/Pages/Index'),
  },
  {
    path: '/apps/explore',
    name: 'exploreApps',
    component: () => import(/* webpackChunkName: "apps-v2" */ '@app2/modules/Apps/Pages/Index'),
  }
];
