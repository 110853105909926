var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-access",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.576",
        height: "17.576",
        viewBox: "0 0 17.576 17.576"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(-436.13 -752.704)" } }, [
        _c("path", {
          attrs: {
            d:
              "M447.176,756.791a2.443,2.443,0,1,0,1.728,4.171v0a2.442,2.442,0,0,0-1.729-4.169Zm.667,3.111a.943.943,0,1,1,.277-.669A.944.944,0,0,1,447.843,759.9Z",
            fill: "#939393"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M451.793,754.617h0a6.511,6.511,0,0,0-11.128,4.586,6.6,6.6,0,0,0,.226,1.63l-4.541,4.54a.755.755,0,0,0-.22.531v3.627a.75.75,0,0,0,.75.749h3.627a.75.75,0,0,0,.531-.219l.7-.7a.748.748,0,0,0,.219-.53v-1.315h1.3a.749.749,0,0,0,.75-.75v-1.313l1.258-.012a6.619,6.619,0,0,0,1.941.3,6.516,6.516,0,0,0,4.585-11.128Zm-1.06,1.061a5.011,5.011,0,0,1-3.525,8.568,5.138,5.138,0,0,1-1.626-.265l-.245-.039-2.085.021a.749.749,0,0,0-.742.75v1.3H441.2a.749.749,0,0,0-.75.75v1.753l-.259.259H437.63v-2.565l4.618-4.618a.749.749,0,0,0,.181-.768,5.15,5.15,0,0,1-.264-1.626,4.951,4.951,0,0,1,1.455-3.525,5.029,5.029,0,0,1,7.113,0",
            fill: "#939393"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }