var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-column-right",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.858",
        height: "20.474",
        viewBox: "0 0 16.858 20.474"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M16.108-.001a.75.75 0 0 1 .75.75v18.974a.75.75 0 0 1-1.5 0V.749a.749.749 0 0 1 .75-.75ZM1.75 10.987h10a1.749 1.749 0 0 1 1.75 1.75v4a1.75 1.75 0 0 1-1.75 1.749h-10A1.749 1.749 0 0 1 0 16.737v-4a1.749 1.749 0 0 1 1.75-1.75Zm-.25 5.75a.251.251 0 0 0 .25.25h10a.253.253 0 0 0 .25-.25v-4a.253.253 0 0 0-.25-.251h-10a.252.252 0 0 0-.25.251ZM11.75 9.486h-4A1.751 1.751 0 0 1 6 7.736v-4a1.749 1.749 0 0 1 1.75-1.75h4a1.749 1.749 0 0 1 1.75 1.75v4a1.75 1.75 0 0 1-1.75 1.75ZM12 3.737a.253.253 0 0 0-.25-.251h-4a.25.25 0 0 0-.25.25v4a.251.251 0 0 0 .25.25h4a.253.253 0 0 0 .25-.25Z",
          fill: "#fff"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }