var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "audience-slide-in-template",
    {
      attrs: {
        "back-route": { name: "UploadStep1" },
        "back-label": "Back to Step 1",
        hidePanelTitle: ""
      }
    },
    [
      _c("h6", { staticClass: "txt-title-small" }, [
        _c("b", [_vm._v("Step 2")]),
        _vm._v(" of 3 "),
        _c("br"),
        _c("span", { staticClass: "txt-muted" }, [
          _vm._v("Select fields to import")
        ])
      ]),
      _vm._v(" "),
      _c("hr", { staticClass: "mb-3" }),
      _vm._v(" "),
      _c("div", { staticClass: "tag-list mb-3" }, [
        _c("span", { staticClass: "tag-basic tag-variant-dark" }, [
          _c(
            "svg",
            { staticClass: "icon-sm mr-2", attrs: { viewBox: "0 0 24 24" } },
            [_c("use", { attrs: { "xlink:href": "#icon-page-single" } })]
          ),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.file.name))])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-widget pt-2" },
        [
          _c("div", { staticClass: "audience-edit-row px-4 py-3" }, [
            _c("div", { staticClass: "slot-left" }, [
              _c("p", { staticClass: "txt-body txt-muted" }, [
                _vm._v("Supported Fields")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "slot-right" }, [
              _c("p", { staticClass: "txt-body" }, [
                _c("span", { staticClass: "txt-muted" }, [
                  _vm._v("Fields from ")
                ]),
                _c("b", [_vm._v(_vm._s(_vm.file.name))]),
                _c("span", { staticClass: "txt-muted" }, [_vm._v(" file")])
              ])
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.columnHeaders, function(ch, index) {
            return _c(
              "div",
              { key: index, staticClass: "audience-edit-row px-4 py-3" },
              [
                _c("div", { staticClass: "slot-left" }, [
                  _c("span", { staticClass: "txt-body-small" }, [
                    _vm._v(_vm._s(ch.supported_field))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "slot-right" },
                  [
                    _c("import-column-field", {
                      staticClass: "w-100",
                      attrs: { "col-header": ch, "col-index": index }
                    })
                  ],
                  1
                )
              ]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-library-cta p-3" },
        [
          _c(
            "b-btn",
            {
              attrs: {
                block: "",
                variant: "primary",
                size: "lg",
                to: { name: "UploadStep3" }
              }
            },
            [
              _vm._v("\n      Next Step\n      "),
              _c(
                "svg",
                {
                  staticClass: "btn-right",
                  attrs: { width: "24", viewBox: "0 0 24 24" }
                },
                [_c("use", { attrs: { "xlink:href": "#icon-arrow-right" } })]
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }