var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-page" }, [
    _c("div", { staticClass: "auth-page-content" }, [
      _vm.user && _vm.currentStep
        ? _c(
            "div",
            { staticClass: "onboarding" },
            [
              _c("transition", { attrs: { name: "fade", appear: "" } }, [
                !_vm.finished
                  ? _c(
                      "div",
                      { staticClass: "onboarding-header" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "onboarding-steps",
                            class: [
                              "active-" + _vm.currentStep.id,
                              { finished: _vm.stepsDone }
                            ]
                          },
                          [
                            _vm._l(_vm.steps, function(step) {
                              return _c(
                                "div",
                                { staticClass: "onboarding-steps-item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "onboarding-steps-num",
                                      class: {
                                        active: step === _vm.currentStep,
                                        completed:
                                          step.id <= _vm.currentStep.id - 1
                                      }
                                    },
                                    [
                                      _c(
                                        "transition",
                                        {
                                          attrs: {
                                            name: "fade",
                                            mode: "out-in"
                                          }
                                        },
                                        [
                                          step.id <= _vm.currentStep.id - 1
                                            ? _c(
                                                "svg",
                                                {
                                                  staticClass: "icon-regular",
                                                  attrs: {
                                                    viewBox: "0 0 24 24"
                                                  }
                                                },
                                                [
                                                  _c("use", {
                                                    attrs: {
                                                      "xlink:href": "#icon-tick"
                                                    }
                                                  })
                                                ]
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(step.number) +
                                                    "\n                  "
                                                )
                                              ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "txt-body onboarding-steps-label"
                                        },
                                        [_c("b", [_vm._v(_vm._s(step.label))])]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "onboarding-steps-progress",
                              style: _vm.progressWidth
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "transition",
                          { attrs: { name: "fade", mode: "out-in" } },
                          [
                            _vm.currentStep.id == 1
                              ? _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "txt-title txt-center mb-4 pb-4 mx-auto"
                                  },
                                  [
                                    _c("b", [
                                      _vm._v("Please tell us your name")
                                    ])
                                  ]
                                )
                              : _vm.currentStep.id == 2
                              ? _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "txt-title txt-center mb-4 pb-4 mx-auto"
                                  },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        "What phone number can we reach you at for extra support?"
                                      )
                                    ])
                                  ]
                                )
                              : _vm.currentStep.id == 3
                              ? _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "txt-title txt-center mb-4 pb-4 mx-auto"
                                  },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          this.user.name +
                                            "," +
                                            _vm.currentStep.title
                                        )
                                      )
                                    ])
                                  ]
                                )
                              : _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "txt-title txt-center mb-4 pb-4 mx-auto"
                                  },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          this.user.name +
                                            "," +
                                            _vm.currentStep.title
                                        )
                                      )
                                    ])
                                  ]
                                )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [
                  !_vm.finished
                    ? _c(
                        "div",
                        { staticClass: "inner" },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "fade", mode: "out-in" } },
                            [
                              _c(_vm.currentStep.component, {
                                tag: "component",
                                staticClass: "onboarding-top onboarding-wrap",
                                class: { loaded: !_vm.animation },
                                attrs: {
                                  loading: _vm.loading,
                                  user: this.user
                                },
                                on: {
                                  back: function($event) {
                                    return _vm.back()
                                  },
                                  next: _vm.next,
                                  loading: function($event) {
                                    _vm.loading = $event
                                  },
                                  animation: function($event) {
                                    _vm.animation = $event
                                  },
                                  finish: _vm.finish
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "back",
                                      fn: function() {
                                        return [
                                          _vm.currentStep.id !== 1
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.currentStepIndex.id ==
                                                      0,
                                                    block: "",
                                                    variant: "secondary"
                                                  },
                                                  on: { click: _vm.back }
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass: "btn-left",
                                                      attrs: {
                                                        viewBox: "0 0 24 24"
                                                      }
                                                    },
                                                    [
                                                      _c("use", {
                                                        attrs: {
                                                          "xlink:href":
                                                            "#icon-arrow-left"
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                  Go back\n                "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "action",
                                      fn: function() {
                                        return [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                disabled: _vm.loading,
                                                block: "",
                                                variant: "primary"
                                              },
                                              on: { click: _vm.stepNext }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(_vm.currentStepBtn) +
                                                  "\n                  "
                                              ),
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "btn-right",
                                                  attrs: {
                                                    viewBox: "0 0 24 24"
                                                  }
                                                },
                                                [
                                                  _c("use", {
                                                    attrs: {
                                                      "xlink:href":
                                                        "#icon-arrow-right"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "finish",
                                      fn: function() {
                                        return [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                disabled: _vm.loading,
                                                block: "",
                                                variant: "primary"
                                              },
                                              on: { click: _vm.finish }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(_vm.currentStepBtn) +
                                                  "\n                  "
                                              ),
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "btn-right",
                                                  attrs: {
                                                    viewBox: "0 0 24 24"
                                                  }
                                                },
                                                [
                                                  _c("use", {
                                                    attrs: {
                                                      "xlink:href": "#icon-tick"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          false
                                            ? _c(
                                                "b-button",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.next()
                                                    }
                                                  }
                                                },
                                                [_vm._v("Skip")]
                                              )
                                            : _vm._e()
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  490394281
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c("finish", { staticClass: "onboarding-finish" })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }