var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18.039",
        height: "14.889",
        viewBox: "0 0 18.039 14.889"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Path_6092",
          "data-name": "Path 6092",
          d:
            "M2205.456,792.619a5.765,5.765,0,0,0-5.764,5.764v.813a1.29,1.29,0,0,0,1.282,1.279h8.967a1.289,1.289,0,0,0,1.278-1.281v-.81A5.765,5.765,0,0,0,2205.456,792.619Zm4.263,6.567v0Zm0-.211h-8.527v-.592a4.264,4.264,0,0,1,8.528,0Z",
          transform: "translate(-2199.692 -785.088)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_6093",
          "data-name": "Path 6093",
          d:
            "M2205.452,791.881a3.392,3.392,0,0,0,.1-6.782c-.013,0-.027-.008-.04-.009a.217.217,0,0,1-.026,0c-.01,0-.019,0-.029,0v.006h0v-.006h0v.006a.747.747,0,0,0-.114.009.677.677,0,0,1,.113-.016,3.4,3.4,0,0,0,0,6.793Zm-.65-5.692h0l-.009,0Zm.912.353.008,0-.008,0Zm-.257-.7Zm-.2-.724-.057.017A.407.407,0,0,1,2205.259,785.115Zm.19,1.473h.008a1.7,1.7,0,1,1-.008,0Z",
          transform: "translate(-2199.692 -785.088)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_6094",
          "data-name": "Path 6094",
          d:
            "M2212.39,786.589h6.64a.75.75,0,0,0,0-1.5h-6.64a.75.75,0,0,0,0,1.5Z",
          transform: "translate(-2199.692 -785.088)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_6095",
          "data-name": "Path 6095",
          d:
            "M2219.78,790.1a.75.75,0,0,0-.75-.75h-7.561a.75.75,0,0,0,0,1.5h7.561A.75.75,0,0,0,2219.78,790.1Z",
          transform: "translate(-2199.692 -785.088)",
          fill: "#939393"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_6096",
          "data-name": "Path 6096",
          d:
            "M2219.03,793.611h-4.775a.75.75,0,0,0,0,1.5h4.775a.75.75,0,0,0,0-1.5Z",
          transform: "translate(-2199.692 -785.088)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }