var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        "data-name": "Domain Icon Small",
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.82",
        height: "17.82",
        viewBox: "0 0 17.82 17.83"
      }
    },
    [
      _c("path", {
        attrs: {
          "data-name": "Domain Icon Small",
          d:
            "M17.75 10.01l.02-.14c.03-.29.05-.58.05-.88v-.08-.08c0-.3-.02-.6-.05-.88l-.02-.14q-.06-.43-.15-.85l-.07-.3v-.02A8.91 8.91 0 0011.17.3l-.3-.08h-.02A8.68 8.68 0 0010 .08L9.88.06A8.95 8.95 0 008.92 0a8.85 8.85 0 00-.97.06l-.1.01a8.8 8.8 0 00-.89.16l-.3.06-.01.01A8.91 8.91 0 00.31 6.65H.3l-.07.3v.02c-.06.27-.11.55-.15.83l-.02.15a9.1 9.1 0 00-.05.88V9c0 .3.02.59.05.87l.02.14c.04.3.09.57.15.85v.01l.07.3v.01a8.91 8.91 0 006.35 6.34v.01l.3.07.04.01c.25.05.5.1.74.13l.24.03c.22.03.45.03.67.04l.28.01.26-.01c.23 0 .46-.01.69-.04l.22-.03a9 9 0 00.75-.13h.04l.3-.08a8.91 8.91 0 006.34-6.35h.01l.07-.3v-.02c.06-.28.11-.57.15-.85zm-7.53 6.18l-.36.06-.16.03c-.18.02-.36.02-.55.03l-.24.01-.24-.01c-.18 0-.37-.01-.55-.03l-.15-.02-.37-.07a10.54 10.54 0 01-.99-2.8l-.06-.34a24.62 24.62 0 004.72 0l-.06.34a10.5 10.5 0 01-.99 2.8zm-1.3-4.53a22.9 22.9 0 01-2.6-.15 22.64 22.64 0 010-5.2 22.63 22.63 0 015.2 0 22.63 22.63 0 010 5.2 22.68 22.68 0 01-2.6.15zm-7.29-1.44l-.07-.42-.01-.09A7.17 7.17 0 011.5 9V8.9v-.07c0-.25.02-.49.05-.73v-.08l.08-.43a10.54 10.54 0 012.8-.99l.35-.06a24.65 24.65 0 000 4.72l-.35-.06a10.51 10.51 0 01-2.8-.99zM7.6 1.63l.45-.08h.06a7.2 7.2 0 01.8-.05 7.13 7.13 0 01.8.04l.07.01.44.08a10.48 10.48 0 01.98 2.8l.07.34a24.64 24.64 0 00-4.73 0l.07-.34a10.55 10.55 0 011-2.8zm5.45 4.92l.34.06a10.54 10.54 0 012.8 1c.03.13.06.27.07.41l.02.09a6.93 6.93 0 01.04.72V9a6.98 6.98 0 01-.04.72l-.02.09c-.01.14-.04.28-.07.42a10.54 10.54 0 01-2.8.99l-.34.06a24.64 24.64 0 000-4.72zm2.53-.87a14.85 14.85 0 00-1.87-.53l-.87-.17c-.05-.3-.1-.59-.17-.87a14.85 14.85 0 00-.53-1.86 7.3 7.3 0 013.44 3.43zm-11.9-2a7.43 7.43 0 012-1.43 14.7 14.7 0 00-.53 1.86l-.17.87c-.3.05-.59.1-.87.17a14.87 14.87 0 00-1.86.53 7.42 7.42 0 011.42-2zm-1.43 8.46a14.62 14.62 0 001.86.53l.87.17c.06.3.1.6.17.87a14.78 14.78 0 00.53 1.86 7.28 7.28 0 01-3.43-3.43zm11.9 2.01a7.42 7.42 0 01-2.01 1.42 14.8 14.8 0 00.53-1.86l.17-.87c.3-.05.59-.1.87-.17a14.69 14.69 0 001.86-.53 7.44 7.44 0 01-1.42 2.01z",
          fill: "#293ad3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }