var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-list-order",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.887",
        height: "13.781",
        viewBox: "0 0 16.887 13.781"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M1.737 1.955V5.78h1.6V0H2.004l-.062.042-1.944 1.293.761 1.248zM2.285 12.32l.44-.4a5.263 5.263 0 00.975-1.106 2.212 2.212 0 00.291-1.151 1.69 1.69 0 00-.5-1.32 2.072 2.072 0 00-1.426-.442 5.747 5.747 0 00-1.7.251l-.19.057.07 1.054.016.264.456-.056a7.679 7.679 0 011.138-.095.654.654 0 01.4.1c.046.035.1.117.1.327a.931.931 0 01-.189.554 9.423 9.423 0 01-.812.915l-1.2 1.216v1.289h3.931v-1.46h-1.8zM5.885 2.28h10.248a.75.75 0 000-1.5H5.885a.75.75 0 000 1.5zM16.137 8.021H5.885a.75.75 0 000 1.5h10.248a.75.75 0 000-1.5zM5.885 5.8h5.28a.75.75 0 000-1.5h-5.28a.75.75 0 000 1.5zM11.169 11.515H5.885a.75.75 0 000 1.5h5.28a.75.75 0 000-1.5z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }