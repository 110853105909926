
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="10.753" height="11.499" viewBox="0 0 10.753 11.499">
    <path data-name="Play Icon" d="M9.438 3.491a2.6 2.6 0 0 1 0 4.517L3.879 11.16A2.6 2.6 0 0 1 .001 8.902v-6.3A2.6 2.6 0 0 1 3.879.344z" />
  </svg>
</template>
<script>
  export default {
    name: 'IconPlay',
  };
</script>
