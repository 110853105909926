var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 20 20"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(1 1)" } }, [
        _c("path", {
          attrs: {
            d:
              "M755,454.671a8.983,8.983,0,0,0,.56,3.13l8.44.783,8.44-.783a9.032,9.032,0,0,0,0-6.261l-8.44-.783-8.44.783a8.984,8.984,0,0,0-.56,3.13Zm0,0",
            transform: "translate(-755 -445.671)",
            fill: "#ffda44"
          }
        }),
        _c("path", {
          attrs: {
            d: "M784.618,340.87a9,9,0,0,0-16.88,0Zm0,0",
            transform: "translate(-767.178 -335)",
            fill: "#d80027"
          }
        }),
        _c("path", {
          attrs: {
            d: "M767.738,611.035a9,9,0,0,0,16.88,0Zm0,0",
            transform: "translate(-767.178 -598.905)",
            fill: "#d80027"
          }
        }),
        _c(
          "g",
          { attrs: { fill: "none", stroke: "#e4e4e4", "stroke-width": "1" } },
          [
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9", stroke: "none" }
            }),
            _c("circle", {
              attrs: { cx: "9", cy: "9", r: "9.5", fill: "none" }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }