var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "audience-slide-in-template",
    {
      attrs: {
        "back-route": { name: "ExportIndex" },
        "back-label": "Back to Export Audience",
        title: "Recent Exports"
      }
    },
    [
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.exports.length
            ? _c("exports-list", { attrs: { exports: _vm.exports } })
            : _vm.exports.length === 0 && !_vm.fetching
            ? _c(
                "div",
                { staticClass: "side-panel-group-empty" },
                [
                  _c("illustration-dog-left-bone", { staticClass: "mb-4" }),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "txt-body txt-center txt-muted mb-4" },
                    [
                      _vm._v("You have no recent exports."),
                      _c("br"),
                      _vm._v(" Export audience profiles to download them here.")
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-btn",
                    {
                      attrs: { variant: "primary" },
                      on: { click: _vm.goToAudienceList }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "btn-left",
                          attrs: { viewBox: "0 0 24 24" }
                        },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "#icon-user-multiple" }
                          })
                        ]
                      ),
                      _vm._v("\n        Audience\n      ")
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }