var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c("h6", { staticClass: "txt-heading-small txt-muted mb-3" }, [
        _vm._v("Here you can see all your exports of Audience.")
      ]),
      _vm._v(" "),
      _c("hr", { staticClass: "mb-3" }),
      _vm._v(" "),
      _vm._l(_vm.exports, function(item) {
        return _c("exports-item", { key: item.id, attrs: { item: item } })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }