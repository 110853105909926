var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16.43",
        height: "17.081"
      }
    },
    [
      _c("g", { attrs: { "data-name": "Undo Icon" } }, [
        _c("path", {
          attrs: {
            "data-name": "Path 656",
            d:
              "M9.88 3.991H2.586L5.297 1.28A.75.75 0 004.237.22L.221 4.236a.75.75 0 000 1.061l4.016 4.016a.751.751 0 001.062-1.061L2.537 5.491h7.344a5.048 5.048 0 015.05 5.04v.006a5.05 5.05 0 01-5.05 5.045H1.75a.75.75 0 000 1.5h8.131a6.551 6.551 0 006.55-6.544v-.008A6.547 6.547 0 009.88 3.991z",
            fill: "#939393"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }