<template>
  <svg class="icon-column-left" xmlns="http://www.w3.org/2000/svg" width="16.858" height="20.474" viewBox="0 0 16.858 20.474">
    <path d="M.75-.001a.75.75 0 0 0-.75.75v18.974a.75.75 0 0 0 1.5 0V.749a.749.749 0 0 0-.75-.75ZM15.108 10.987h-10a1.749 1.749 0 0 0-1.75 1.75v4a1.75 1.75 0 0 0 1.75 1.749h10a1.749 1.749 0 0 0 1.75-1.749v-4a1.749 1.749 0 0 0-1.75-1.75Zm.25 5.75a.251.251 0 0 1-.25.25h-10a.253.253 0 0 1-.25-.25v-4a.253.253 0 0 1 .25-.251h10a.252.252 0 0 1 .25.251ZM5.108 9.486h4a1.751 1.751 0 0 0 1.75-1.75v-4a1.749 1.749 0 0 0-1.75-1.75h-4a1.749 1.749 0 0 0-1.75 1.75v4a1.75 1.75 0 0 0 1.75 1.75Zm-.25-5.749a.253.253 0 0 1 .25-.251h4a.25.25 0 0 1 .25.25v4a.251.251 0 0 1-.25.25h-4a.253.253 0 0 1-.25-.25Z" fill="#939393"/>
  </svg>
</template>
<script>
  export default {
    name: 'icon-column-left'
  }
</script>
