var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Mail_Icon",
        "data-name": "Mail Icon",
        xmlns: "http://www.w3.org/2000/svg",
        width: "15.523",
        height: "15.522",
        viewBox: "0 0 15.523 15.522"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Phone_Icon",
          "data-name": "Phone Icon",
          d:
            "M3252.792,771.21a2.077,2.077,0,0,0-1.236-.683h-.008l-2.492-.4v0a2.272,2.272,0,0,0-.449-.048,2.171,2.171,0,0,0-.676.1,1.661,1.661,0,0,0-.552.322l0-.006-1.181.892a7.671,7.671,0,0,1-2.965-2.962l.858-1.142,0,0a1.876,1.876,0,0,0,.486-1.251,1.9,1.9,0,0,0-.072-.464h.009l-.4-2.505h0a2.1,2.1,0,0,0-.654-1.2,1.979,1.979,0,0,0-1.344-.557h-2.442a1.875,1.875,0,0,0-1.875,1.875,13.661,13.661,0,0,0,13.647,13.647,1.875,1.875,0,0,0,1.876-1.875v-2.414A2.019,2.019,0,0,0,3252.792,771.21Zm-.978,3.75a.381.381,0,0,1-.376.376,12.163,12.163,0,0,1-12.147-12.148.378.378,0,0,1,.375-.375h2.442a.5.5,0,0,1,.32.153.623.623,0,0,1,.2.329l0,.011.4,2.5.026.107.018.121a.384.384,0,0,1-.113.272l-.069.079-1.155,1.536a.746.746,0,0,0-.063.8,9.484,9.484,0,0,0,4.227,4.227.753.753,0,0,0,.8-.063l1.576-1.19.079-.07h0l.013-.007a.621.621,0,0,1,.237-.042l.172.019.042.007,2.5.4.008,0a.589.589,0,0,1,.341.2.532.532,0,0,1,.144.341Z",
          transform: "translate(-3237.791 -761.313)",
          fill: "#979797"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }