var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-customize",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18",
        height: "18",
        viewBox: "0 0 18 18"
      }
    },
    [
      _c("rect", {
        attrs: { width: "18", height: "18", fill: "#fff", opacity: "0" }
      }),
      _c("path", {
        attrs: {
          d:
            "M1267.613,1098.168l1.618-1.618a.752.752,0,0,1,.725-.2l1.91.513,3.176-3.176-2.56-2.561-.18.18a.741.741,0,0,1-.53.22.75.75,0,0,1-.531-.219l-2.709-2.71a.75.75,0,0,1,0-1.061l1.417-1.417a.749.749,0,0,1,1.061,0l2.709,2.709a.749.749,0,0,1,0,1.061l-.176.176,2.56,2.561,3.173-3.173-.511-1.909a.751.751,0,0,1,.194-.724l1.617-1.618a.75.75,0,0,1,1.061,1.061l-1.311,1.31.4,1.492,1.492.4,1.311-1.311a.75.75,0,1,1,1.061,1.061l-1.617,1.617a.753.753,0,0,1-.726.195l-1.909-.513-3.173,3.173.9.9.639-.639a.749.749,0,0,1,1.061,0l3.941,3.94h0a2.326,2.326,0,0,1,0,3.291l-.115.114a2.326,2.326,0,0,1-3.289,0l-3.941-3.94a.753.753,0,0,1,0-1.062l.643-.643-.9-.9-3.176,3.176.511,1.909a.75.75,0,0,1-.193.725l-1.618,1.617a.75.75,0,1,1-1.061-1.059h0l1.311-1.311-.4-1.493-1.492-.4-1.311,1.312a.75.75,0,1,1-1.062-1.06Zm2.867-10.453-.357.357,1.613,1.613a.734.734,0,0,1,.144-.217.717.717,0,0,1,.212-.14Zm10.879,12.518a.824.824,0,0,0,1.167,0l.114-.114a.822.822,0,0,0,.244-.584.813.813,0,0,0-.242-.582l0,0-3.409-3.41-1.282,1.282Z",
          transform: "translate(-1266.894 -1084.487)",
          fill: "#939393"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }