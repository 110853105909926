const getOperatorLabel = (op, type) => {
  if ( op === 'lt' ) {
    switch (type) {
      case 'number':
        return 'Is Less Than';
      case 'registration':
        return 'Less Than';
      case 'searched':
        return 'Less Than';
      default:
        return 'Is Before';
    }
  }
  if ( op === 'gt' ) {
    switch (type) {
      case 'number':
        return 'Is Greater Than';
      case 'registration':
        return 'More Than';
      case 'searched':
        return 'More Than';
      default:
        return 'Is After';
    }
  }
}
const operators = (type) => [
  {
    id: 'equals',
    label: type === 'registration' || type === 'searched' ? 'Exactly' : 'Is'
  },
  {
    id: 'not_equals',
    label: 'Is Not'
  },
  {
    id: 'is_true',
    label: 'Is True'
  },
  {
    id: 'is_not_true',
    label: 'Is Not True'
  },
  {
    id: 'contains',
    label: 'Contains'
  },
  {
    id: 'not_contains',
    label: 'Does Not Contain'
  },
  {
    id: 'starts_with',
    label: 'Starts With'
  },
  {
    id: 'ends_with',
    label: 'Ends With'
  },
  {
    id: 'gt',
    label: getOperatorLabel('gt', type)
  },
  {
    id: 'gte',
    label: type === 'number' ? 'Is Greater Or Equal To' :  'Is On Or After'
  },
  {
    id: 'lt',
    label: getOperatorLabel('lt', type)
  },
  {
    id: 'lte',
    label: type === 'number' ? 'Is Less Or Equal To' :  'Is On Or Before'
  },
  {
    id: 'not_null',
    label: 'Exists'
  },
  {
    id: 'null',
    label: 'Does Not Exist'
  },
  {
    id: 'started',
    label: 'Has Started'
  },
  {
    id: 'not_started',
    label: 'Has Not Started'
  },
  {
    id: 'completed',
    label: 'Has Completed'
  },
  {
    id: 'registration',
    label: 'Registered'
  },
  {
    id: 'searched',
    label: 'Has Searched'
  },
  {
    id: 'more_than',
    label: 'More Than'
  },
  {
    id: 'exactly',
    label: 'Exactly'
  },
  {
    id: 'less_than',
    label: 'Less Than'
  },
  {
    id: 'received',
    label: 'Has Received'
  },
  {
    id: 'not_received',
    label: 'Has Not Received'
  },
  {
    id: 'on',
    label: 'On'
  },
  {
    id: 'not_on',
    label: 'Not On'
  },
  {
    id: 'before',
    label: 'Before'
  },
  {
    id: 'on_or_before',
    label: 'On or Before'
  },
  {
    id: 'after',
    label: 'After'
  },
  {
    id: 'on_or_after',
    label: 'On or After'
  },
];

export const getSearchedOperators = () => {
  return ['lt', 'equals', 'gt'].map(op => operators('searched').find(o => o.id === op))
}

export const getRegisteredOperators = () => {
  return ['on', 'not_on', 'before', 'on_or_before', 'after', 'on_or_after','lt', 'equals', 'gt'].map(op => operators('registration').find(o => o.id === op))
}
export const getAttributeOperators = attribute => {
  if ( !attribute ) return [];
  let ops;
  switch (attribute.type) {
    case 'text':
      ops = ['equals', 'not_equals', 'contains', 'not_contains', 'starts_with', 'ends_with'];
      break;
    case 'number':
      ops = ['equals', 'not_equals', 'lt', 'gt'];
      break;
    case 'date':
      ops = ['equals', 'not_equals', 'lt', 'lte', 'gte', 'gt', 'more_than', 'exactly', 'less_than'];
      break;
    case 'boolean':
      ops = ['is_true', 'is_not_true'];
      break;
    default:
      ops = ['equals', 'not_equals'];
  }
  return ops.map(op => operators(attribute.type).find(o => o.id === op))
}

export const getHubActivityOperators = () => {
  return ['started', 'not_started', 'completed', 'searched', 'registration'].map(op => operators().find(o => o.id === op))
}

const profileFields = [
  {
    condition: 'name',
    input: 'text',
    type: 'standard',
    label: 'Name',
    icon: 'user-details',
    operators: ['equals', 'not_equals', 'contains', 'not_contains', 'starts_with', 'ends_with']
      .map(op => operators().find(o => o.id === op))
  },
  {
    condition: 'email',
    input: 'text',
    type: 'standard',
    label: 'Email',
    icon: 'email',
    operators: ['equals', 'not_equals', 'contains', 'not_contains', 'starts_with', 'ends_with']
      .map(op => operators().find(o => o.id === op))
  },
  {
    condition: 'city',
    input: 'text',
    type: 'standard',
    label: 'City',
    icon: 'location',
    operators: ['equals', 'not_equals', 'contains', 'not_contains', 'starts_with', 'ends_with']
      .map(op => operators().find(o => o.id === op))
  },
  {
    condition: 'state',
    input: 'text',
    type: 'standard',
    label: 'State',
    icon: 'location',
    operators: ['equals', 'not_equals', 'contains', 'not_contains', 'starts_with', 'ends_with']
      .map(op => operators().find(o => o.id === op))
  },
  {
    condition: 'country',
    input: 'text',
    type: 'standard',
    label: 'Country',
    icon: 'location',
    operators: ['equals', 'not_equals', 'contains', 'not_contains', 'starts_with', 'ends_with']
      .map(op => operators().find(o => o.id === op))
  },
  {
    condition: 'date_registered',
    input: 'date',
    type: 'standard',
    label: 'Registration Date',
    icon: 'calendar-today',
    operators: ['equals', 'not_equals', 'lt', 'lte', 'gte', 'gt', 'more_than', 'exactly', 'less_than']
      .map(op => operators().find(o => o.id === op))
  },
  {
    condition: 'date_last_seen',
    input: 'date',
    type: 'standard',
    label: 'Last Seen Date',
    icon: 'calendar-month',
    operators: ['equals', 'not_equals', 'lt', 'lte', 'gte', 'gt', 'more_than', 'exactly', 'less_than']
      .map(op => operators().find(o => o.id === op))
  },
  {
    condition: 'achievements',
    input: 'multiselect',
    type: 'standard',
    label: 'Achievement',
    icon: 'trophy',
    operators: ['equals', 'not_equals']
      .map(op => operators().find(o => o.id === op)),
  },
  {
    id: 'attributes',
    label: 'Attributes',
    icon: 'arrows-both-directions',
    group: true,
    options: []
  },
];

const hubActivityField = {
  id: 'hub_activity',
  label: 'Hub Activity',
  options: []
};

export const hubActivityValueFields = [
  {
    id: 'files',
    label: 'File',
    icon: 'media-play',
    group: true,
    options: []
  },
  {
    id: 'playlists',
    label: 'Playlist',
    icon: 'playlist',
    group: true,
    options: []
  },
];

const tagField = {
  condition: 'tags',
  input: 'multiselect',
  type: 'standard',
  label: 'Audience Tag',
  operators: ['equals', 'not_equals']
    .map(op => operators().find(o => o.id === op)),
}

const billingStatusField = {
  condition: 'billing_status',
  input: 'select',
  type: 'standard',
  label: 'Billing Status',
  operators: ['equals', 'not_equals']
    .map(op => operators().find(o => o.id === op)),
  options: [
    {
      id: 'active',
      label: 'Active/Subscriber',
      default: true,
    },
    {
      id: 'cancelled',
      label: 'Canceled',
    },
    {
      id: 'delinquent',
      label: 'Delinquent',
    },
    {
      id: 'incomplete',
      label: 'Incomplete',
    },
  ]
}

const billingDetailsFields = [
  {
    condition: 'products',
    input: 'multiselect',
    type: 'standard',
    label: 'Products',
    icon: 'money',
    operators: ['equals', 'not_equals']
      .map(op => operators().find(o => o.id === op)),
  },
  {
    condition: 'plans',
    input: 'multiselect',
    type: 'standard',
    label: 'Plans',
    icon: 'tick-circle',
    operators: ['equals', 'not_equals']
      .map(op => operators().find(o => o.id === op)),
  }
]

const hubField = {
  condition: 'hubs',
  input: 'multiselect',
  type: 'standard',
  label: 'Hubs',
  operators: ['equals', 'not_equals']
    .map(op => operators().find(o => o.id === op))
};

export const profileOptions = {
  id: 'profile',
  label: 'Personal Details',
  icon: 'user-single',
  options: profileFields
}

export const tagOptions = {
  id: 'tags',
  label: 'Audience Tag',
  icon: 'tag',
  single: true,
  option: tagField,
}

export const hubActivityOptions = {
  id: 'hubActivity',
  label: 'Hub Audience',
  icon: 'users-activity',
  option: hubActivityField,
}

export const billingStatusOptions = {
  id: 'billingStatus',
  label: 'Billing Status',
  single: true,
  icon: 'tick-circle',
  option: billingStatusField,
}

export const billingDetailsOptions = {
  id: 'billingDetails',
  label: 'Billing Details',
  icon: 'money',
  options: billingDetailsFields,
}

export const hubOptions = {
  id: 'hubs',
  label: 'Hubs',
  icon: 'hub',
  single: true,
  option: hubField,
}

export const allConditions = [
  ...profileFields,
  tagField,
  hubField,
  billingStatusField,
  ...billingDetailsFields
];

export const allConditionGroups = [
  profileOptions,
  billingDetailsOptions,
  billingStatusOptions,
  hubOptions,
  tagOptions,
  hubActivityOptions,
];

export const sortedConditionGroups = [
  [
    profileOptions,
    billingDetailsOptions,
    billingStatusOptions,
  ],
  [
    hubOptions,
    hubActivityOptions,
  ],
  [
    tagOptions,
  ],
];